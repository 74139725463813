import { ApiType } from "@gymflow/common/lib/api/ApiType";
import { InvoiceDTO } from "@gymflow/types";
import { useMutation } from "@tanstack/react-query";

export function useMutationGetInvoiceAsMember({ api }: { api: ApiType }) {
  return useMutation({
    mutationFn: async ({ invoiceNumber }: { invoiceNumber: string }) => {
      return (
        await api.profileApi.findInvoices("", {
          extraParams: {
            number: invoiceNumber,
          },
          limit: 1,
        })
      ).data.content[0] as InvoiceDTO;
    },
  });
}
