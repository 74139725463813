import { useAutoAnimate } from "@formkit/auto-animate/react";
import { faAngleRight } from "@fortawesome/free-solid-svg-icons";
import { FontAwesomeIcon } from "@fortawesome/react-fontawesome";
import {
  useClubFeatureFlags,
  useQueryAppointableAsPublic,
  useQueryFacilityAvailabilityCalculateAsMemberNew,
  useQueryHostAvailabilityCalculateAsMemberNew,
} from "@gymflow/api";
import { cn, generateAvailableTimeSlots } from "@gymflow/helpers";
import { usePortalRoutes } from "apps/portal/src/hooks";
import { useClubSettings } from "apps/portal/src/providers";
import { RouteFeature } from "apps/portal/src/routes";
import { DateTime } from "luxon";
import React, { useMemo, useState } from "react";
import { useTranslation } from "react-i18next";
import { useParams } from "react-router";

import useGymflowModels from "../../../store";
import { Button, Spinner } from "../../atoms";
import { EmptyPage } from "../../atoms/EmptyPage";
import { HostedPagesBackHeader } from "../components/HostedPagesBackHeader";
import useRedirectIfNotAuthenticated from "../useRedirectIfNotAuthenticated";
import HostedPagesAppointableCalendarPicker from "./components/HostedPagesAppointableCalendarPicker";

export type HostedPagesAppointmentSelectTimeProps = {};

export const HostedPagesAppointmentSelectTime: React.FC<
  HostedPagesAppointmentSelectTimeProps
> = ({}) => {
  const { appointableId, staffOrFacilityId } = useParams<{
    appointableId: string;
    staffOrFacilityId: string;
  }>();
  const { createSiteOrEmbedLink, isQuickPurchase, isEmbed } = usePortalRoutes();
  useRedirectIfNotAuthenticated(
    createSiteOrEmbedLink(RouteFeature.Appointables),
  );
  const { t } = useTranslation();
  const { api } = useGymflowModels();
  const settings = useClubSettings();
  const { data: featureFlags } = useClubFeatureFlags({
    clubId: settings.clubId,
    api,
  });
  const [currentDate, setCurrentDate] = useState(
    DateTime.now().setZone(settings.timezone).startOf("day"),
  );
  const { data: appointable } = useQueryAppointableAsPublic({
    api,
    id: parseInt(appointableId),
  });
  const hostQuery = useQueryHostAvailabilityCalculateAsMemberNew({
    api,
    opts: {
      appointableId: parseInt(appointableId),
      appointableHostsIdList:
        staffOrFacilityId === "no-preference" ? undefined : [staffOrFacilityId],
      dateFrom:
        DateTime.max(currentDate, DateTime.now().startOf("minute"))
          .toUTC()
          .toISO({ suppressMilliseconds: true }) ?? "",
      dateTo:
        currentDate
          .plus({
            days: 1,
            seconds: -1,
            minutes: featureFlags?.featureFlags.FE_AVAILABILITY_FOR_WHOLE_DAY
              ? (appointable?.duration ?? 0) + (appointable?.blockedTime ?? 0)
              : 0,
          })
          .toUTC()
          .toISO({ suppressMilliseconds: true }) ?? "",
    },
    enabled: appointable?.availabilityType === "STAFF",
  });

  const facilityQuery = useQueryFacilityAvailabilityCalculateAsMemberNew({
    api,
    opts: {
      appointableId: parseInt(appointableId),
      appointableFacilityIdList:
        staffOrFacilityId === "no-preference"
          ? undefined
          : [Number(staffOrFacilityId)],
      dateFrom:
        DateTime.max(currentDate, DateTime.now().startOf("minute"))
          .toUTC()
          .toISO({ suppressMilliseconds: true }) ?? "",
      dateTo:
        currentDate
          .plus({
            days: 1,
            seconds: -1,
            minutes: featureFlags?.featureFlags.FE_AVAILABILITY_FOR_WHOLE_DAY
              ? (appointable?.duration ?? 0) + (appointable?.blockedTime ?? 0)
              : 0,
          })
          .toUTC()
          .toISO({ suppressMilliseconds: true }) ?? "",
    },
    enabled: appointable?.availabilityType === "FACILITY",
  });
  const { data: availability, isLoading } =
    appointable?.availabilityType === "STAFF" ? hostQuery : facilityQuery;

  const slots = useMemo(
    () =>
      generateAvailableTimeSlots(availability, settings.timezone, appointable),
    [appointable, availability, settings.timezone],
  );
  const [parent] = useAutoAnimate();
  const [innerParent] = useAutoAnimate();
  return (
    <div
      className={cn(
        "track-height flex w-full flex-col overflow-y-auto items-center bg-gray-50 dark:bg-gray-800",
        {
          "track-height": !isQuickPurchase,
          "h-fit": isEmbed,
          "h-full": !isEmbed,
        },
      )}
    >
      <HostedPagesBackHeader
        label={t("pages.hostedPagesAppointableSelectTime.header")}
      />
      <HostedPagesAppointableCalendarPicker
        currentDate={currentDate}
        setCurrentDate={setCurrentDate}
      />
      <div
        ref={parent}
        className="my-4 flex w-[min(32rem,100vw)] flex-col items-center overflow-y-auto overflow-x-hidden"
      >
        {isLoading && <Spinner />}
        {!isLoading && availability && slots && (
          <div
            ref={innerParent}
            className="flex max-h-full w-[min(32rem,100vw)] flex-col items-center gap-2 px-6"
          >
            {slots.morning.length === 0 && slots.afternoon.length === 0 && (
              <EmptyPage
                textOverride={t(
                  "pages.hostedPagesAppointableSelectTime.noAvailability",
                )}
              />
            )}
            {slots.morning.length > 0 && (
              <div className="w-full text-xs font-semibold uppercase text-gray-500">
                {t("common.morning")}
              </div>
            )}

            {slots.morning.map((e) => {
              return (
                <Button
                  key={e.start.toISO()}
                  className="shadow-xs flex h-fit w-full flex-row items-center justify-between border-gray-200 px-3 py-2 dark:border-gray-800"
                  link={createSiteOrEmbedLink(
                    RouteFeature.AppointableIdReview.replace(
                      ":appointableId",
                      appointableId.toString(),
                    )
                      .replace(":staffOrFacilityId", staffOrFacilityId)
                      .replace(":timestamp", e.start.toISO() ?? ""),
                  )}
                >
                  <div className="items-start text-sm font-semibold text-gray-950 dark:text-gray-0">
                    {e.start
                      .setZone(settings.timezone)
                      .toLocaleString(DateTime.TIME_SIMPLE)}
                  </div>
                  <FontAwesomeIcon
                    icon={faAngleRight}
                    className="h-4 w-4 text-gray-500"
                  />
                </Button>
              );
            })}
            {slots.afternoon.length > 0 && (
              <div className="w-full pt-4 text-xs font-semibold uppercase text-gray-500">
                {t("common.afternoon")}
              </div>
            )}
            {slots.afternoon.map((e) => {
              return (
                <Button
                  key={e.start.toISO()}
                  className="shadow-xs flex h-fit w-full flex-row items-center justify-between border-gray-200 px-3 py-2 dark:border-gray-800"
                  link={createSiteOrEmbedLink(
                    RouteFeature.AppointableIdReview.replace(
                      ":appointableId",
                      appointableId.toString(),
                    )
                      .replace(":staffOrFacilityId", staffOrFacilityId)
                      .replace(":timestamp", e.start.toISO() ?? ""),
                  )}
                >
                  <div className="items-start text-sm font-semibold text-gray-950 dark:text-gray-0">
                    {e.start
                      .setZone(settings.timezone)
                      .toLocaleString(DateTime.TIME_SIMPLE)}
                  </div>
                  <FontAwesomeIcon
                    icon={faAngleRight}
                    className="h-4 w-4 text-gray-500"
                  />
                </Button>
              );
            })}
          </div>
        )}
      </div>
    </div>
  );
};
