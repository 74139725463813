import {
  CardElement,
  Elements,
  useElements,
  useStripe,
} from "@stripe/react-stripe-js";
import { loadStripe } from "@stripe/stripe-js";
import classNames from "classnames";
import { useFormik } from "formik";
import noop from "lodash/noop";
import moment from "moment-timezone";
import PropTypes from "prop-types";
import { useEffect, useMemo, useState } from "react";
import { Col, FormGroup, Input, Label, Row } from "reactstrap";
import * as Yup from "yup";

import { PARAMETER_DATE_ONLY_FORMAT } from "../../api/eventApi";
import { SERVICE_START_DATE_LIMIT } from "../../api/validations";
import { PaymentMethod } from "../../constants/PaymentMethod";
import { DATE_FORMAT } from "../../helpers/form";
import FormMapper from "../../helpers/form/FormMapper/FormMapper";
import formikHelpers from "../../helpers/formik";
import { ERROR_MESSAGES } from "../../helpers/validations";
import useRecordForm from "../../hooks/useRecordForm";
import AsyncButton from "../atoms/AsyncButton";
import FieldError from "../atoms/FieldError";
import MediumText from "../atoms/MediumText";
import StripeCard from "../atoms/StripeCard";
import CardBodyWithSpinner from "../molecules/CardBodyWithSpinner";
import FormikDateTime from "../molecules/FormikDateTime";
import FormikInput from "../molecules/FormikInput";
import PaymentMethodSelect from "../molecules/PaymentMethodSelect";
import PromoCodeInput from "../molecules/PromoCodeInput";
import ServicePaymentFormFields from "./ServicePaymentForm/constants";

export const PaymentFormField = {
  StartDate: "start-date",
  ProRata: "pro-rata",
  CardholdersName: "card-holders-name",
  IsNew: "new-payment-method",
  PaymentMethod: "payment-method",
};

const {
  CARD_HOLDERS_NAME,
  ACCEPT_TOC,
  NEW_PAYMENT_METHOD,
  PAYMENT_METHOD,
  START_DATE,
  PAYMENT_METHOD_TYPE,
  PROMO_CODE,
} = ServicePaymentFormFields;

const mapper = new FormMapper({
  outValue: [
    {
      key: "start-date",
      transform: (v) =>
        moment(v, DATE_FORMAT, true).format(PARAMETER_DATE_ONLY_FORMAT),
    },
  ],
});

function Checkout({
  userEmail,
  lineItems,
  total,
  totalDescription,
  discount,
  fetchPaymentMethods,
  dateFormat,
  showStartDate,
  allowChangingStartDate,
  validateDate,
  onTakePaymentClick,
  onUpdateSummary,
  termsAndConditionsUrl,
  skipPaymentMethod,
  cardHoldersName,
  stripeStyle,
  startDate,
  paymentMethodOptional,
  timezone,
}) {
  const stripe = useStripe();
  const stripeElements = useElements();
  const todayInGym = moment().tz(timezone).format("YYYY-MM-DD");

  const validationSchema = useMemo(() => {
    const today = moment(todayInGym, "YYYY-MM-DD").format(DATE_FORMAT);
    const maxStartDate = moment(todayInGym, "YYYY-MM-DD").add(
      SERVICE_START_DATE_LIMIT,
    );
    const createSchema = ({
      skipPaymentMethod,
      startDate = today,
      skipTermsAndConditions = false,
    } = {}) => {
      const commonFields = {
        [START_DATE]: Yup.date()
          .format(DATE_FORMAT, true)
          .required()
          .min(
            moment(todayInGym, "YYYY-MM-DD").toDate(),
            ERROR_MESSAGES.dateTooSmall,
          )
          .max(maxStartDate.toDate(), ERROR_MESSAGES.dateTooBig)
          .default(startDate),
        [PROMO_CODE]: Yup.string(),
      };

      if (!skipTermsAndConditions) {
        commonFields[ACCEPT_TOC] = Yup.boolean()
          .oneOf([true], "You must accept the terms and conditions.")
          .default(false);
      }

      if (skipPaymentMethod) {
        return Yup.object().shape({ ...commonFields });
      }
      return Yup.object().shape({
        ...commonFields,
        [PAYMENT_METHOD]: Yup.object().nullable(true),
        [NEW_PAYMENT_METHOD]: Yup.boolean().default(true),
        [CARD_HOLDERS_NAME]: Yup.string()
          .test(
            "cardholders-name-is-required",
            "Card holders name is required",
            function (value) {
              const isNewCard =
                this.parent[NEW_PAYMENT_METHOD] &&
                this.parent[PAYMENT_METHOD_TYPE] === PaymentMethod.Card;
              if (isNewCard && !value) {
                return false;
              }

              return true;
            },
          )
          .min(3),
        [PAYMENT_METHOD_TYPE]: Yup.string()
          .oneOf(Object.values(PaymentMethod))
          .default(PaymentMethod.Card),
      });
    };

    return createSchema({
      skipPaymentMethod,
      startDate,
      skipTermsAndConditions: !termsAndConditionsUrl,
    });
  }, [
    skipPaymentMethod,
    startDate,
    termsAndConditionsUrl,
    timezone,
    todayInGym,
  ]);

  const { initialValues, getValues } = useRecordForm({
    record: null,
    fields: validationSchema.default(),
    mapper,
  });
  const formikProps = useFormik({
    initialValues,
    validationSchema,
    onSubmit: noop,
  });
  const {
    setFieldValue,
    values,
    touched,
    errors,
    setFieldError,
    submitForm,
    validateForm,
  } = formikProps;
  const { errorClass } = formikHelpers(formikProps);

  const [isLoading, setIsLoading] = useState(true);

  useEffect(() => {
    const processPaymentMethods = async () => {
      setIsLoading(true);
      if (!fetchPaymentMethods || skipPaymentMethod || paymentMethodOptional) {
        if (skipPaymentMethod) {
          setFieldValue(PaymentFormField.IsNew, true);
        }
        setIsLoading(false);
        return;
      }
      const paymentMethods = await fetchPaymentMethods();
      const defaultMethod = paymentMethods.find(
        (method) => method.defaultPaymentMethod,
      );

      if (defaultMethod) {
        setFieldValue(PaymentFormField.IsNew, false);
        setFieldValue(PaymentFormField.PaymentMethod, defaultMethod);
      } else {
        setFieldValue(PaymentFormField.IsNew, true);
      }
      setIsLoading(false);
    };
    processPaymentMethods();
  }, [fetchPaymentMethods, skipPaymentMethod]);

  useEffect(() => {
    const runOnUpdateSummary = async () => {
      const isValid = await onUpdateSummary({
        promoCode: values[PROMO_CODE] || undefined,
        startDate: values[START_DATE],
      });
      if (!isValid) {
        setFieldValue(PROMO_CODE, "");
      }
    };
    runOnUpdateSummary();
  }, [values[PROMO_CODE], values[START_DATE]]);

  useEffect(() => {
    setFieldValue(CARD_HOLDERS_NAME, cardHoldersName);
  }, [cardHoldersName]);

  const isNewPaymentMethodOption =
    values[PaymentFormField.IsNew] && !values[PaymentFormField];
  return (
    <CardBodyWithSpinner isLoading={isLoading} className="mt-0 pt-0">
      {lineItems.map(({ name, price }, idx) => (
        <Row
          key={name + price}
          className={classNames("text-muted", { "mt-3": idx === 0 })}
        >
          <Col>
            {name}: {price}
          </Col>
        </Row>
      ))}
      <Row
        className={classNames("text-warning", "pb-2", { "d-none": !discount })}
      >
        <Col>Promotional Discount: {discount}</Col>
      </Row>
      <Row className="font-weight-bold">
        <Col>
          <MediumText>Total: {total}</MediumText>
        </Col>
      </Row>
      <Row className={classNames("text-muted", { hidden: !totalDescription })}>
        <Col>{totalDescription}</Col>
      </Row>
      <Row className="pt-3">
        <Col sm="6">
          <Row>
            <Col xs="12" className="text-uppercase">
              <Label>Promotional Code</Label>
            </Col>
            <Col xs="12">
              <FormGroup>
                <PromoCodeInput
                  onChange={(v) => {
                    setFieldValue(PROMO_CODE, v);
                  }}
                  value={values[PROMO_CODE]}
                />
              </FormGroup>
            </Col>
          </Row>
        </Col>
        <Col sm="6" className={classNames({ "d-none": skipPaymentMethod })}>
          <Row>
            <Col xs="12" className="text-uppercase">
              <Label>Payment Method *</Label>
            </Col>
            <Col xs="12">
              <FormGroup>
                <PaymentMethodSelect
                  classNamePrefix="react-select"
                  onChange={({ value: newValue }) => {
                    if (newValue === "new-payment-method") {
                      setFieldValue(PaymentFormField.IsNew, true);
                      setFieldValue(PaymentFormField.PaymentMethod, null);
                    } else if (newValue == "none") {
                      setFieldValue(PaymentFormField.IsNew, false);
                      setFieldValue(PaymentFormField.PaymentMethod, null);
                    } else {
                      setFieldValue(PaymentFormField.IsNew, false);
                      setFieldValue(PaymentFormField.PaymentMethod, newValue);
                    }
                  }}
                  value={
                    isNewPaymentMethodOption
                      ? { new: true }
                      : values[PaymentFormField.PaymentMethod]
                  }
                  showNew
                  fetchPaymentMethods={fetchPaymentMethods}
                  optional={paymentMethodOptional}
                />
              </FormGroup>
            </Col>
            <Col xs="12">
              <FormGroup className={errorClass(PaymentFormField.PaymentMethod)}>
                <FieldError
                  visible={touched[PaymentFormField.PaymentMethod]}
                  error={errors[PaymentFormField.PaymentMethod]}
                />
              </FormGroup>
            </Col>
          </Row>
        </Col>

        <Col
          sm="6"
          className={classNames({
            "d-none": skipPaymentMethod || !values[PaymentFormField.IsNew],
          })}
        >
          <Row>
            <Col xs="12" className="text-uppercase">
              <Label>Cardholder&apos;s name *</Label>
            </Col>
            <Col xs="12">
              <FormGroup>
                <Input
                  type="text"
                  id={PaymentFormField.CardholdersName}
                  autoComplete="off"
                  maxLength="128"
                  placeholder="Cardholder's name"
                  value={values[PaymentFormField.CardholdersName]}
                  onChange={({ target: { value } }) =>
                    setFieldValue(PaymentFormField.CardholdersName, value)
                  }
                />
              </FormGroup>
            </Col>
            <Col xs="12">
              <FormGroup
                className={errorClass(PaymentFormField.CardholdersName)}
              >
                <FieldError
                  visible={touched[PaymentFormField.CardholdersName]}
                  error={errors[PaymentFormField.CardholdersName]}
                />
              </FormGroup>
            </Col>
          </Row>
        </Col>
        <Col
          sm="6"
          className={classNames({
            "d-none": skipPaymentMethod || !values[PaymentFormField.IsNew],
          })}
        >
          <Row>
            <Col xs="12" className="text-uppercase">
              <Label>Card Details *</Label>
            </Col>
            <Col xs="12">
              <StripeCard
                onChange={(e) => {
                  if (e.error) {
                    setFieldError(PAYMENT_METHOD, e.error.message);
                  } else {
                    setFieldError(PAYMENT_METHOD, undefined);
                  }
                }}
                style={stripeStyle}
              />
            </Col>
            <Col className={classNames({ "d-none": !errors[PAYMENT_METHOD] })}>
              <p style={{ color: "#ec250d" }}>{errors[PAYMENT_METHOD]}</p>
            </Col>
          </Row>
        </Col>

        <Col sm="6" className={classNames({ "d-none": !showStartDate })}>
          <Row>
            <Col xs="12" className="text-uppercase">
              <Label>Start date *</Label>
            </Col>
            <Col xs="12">
              <FormGroup>
                <FormikInput
                  component={FormikDateTime}
                  name={START_DATE}
                  inputProps={{
                    className: "form-control",
                    name: START_DATE,
                    maxLength: 10,
                    id: START_DATE,
                    value: moment(values[START_DATE], DATE_FORMAT).isValid()
                      ? moment(values[START_DATE], DATE_FORMAT).format(
                          dateFormat,
                        )
                      : undefined,
                    disabled: !allowChangingStartDate,
                  }}
                  timeFormat={false}
                  dateFormat={DATE_FORMAT}
                  isValidDate={validateDate}
                  formikProps={formikProps}
                />
              </FormGroup>
            </Col>
          </Row>
        </Col>
      </Row>
      <Row className={classNames("mt-2", { "d-none": !termsAndConditionsUrl })}>
        <Col className="checkbox-radios">
          <FormGroup check className={errorClass(ACCEPT_TOC)}>
            <Label check>
              <Input
                name={ACCEPT_TOC}
                type="checkbox"
                data-testid={ACCEPT_TOC}
                checked={values[ACCEPT_TOC]}
                onChange={({ target: { checked } }) => {
                  setFieldValue(ACCEPT_TOC, checked);
                }}
              />
              <span className="form-check-sign" />
              <div style={{ marginTop: "-2px" }}>
                I Agree to the&nbsp;
                <a
                  href={termsAndConditionsUrl}
                  target="_blank"
                  rel="noopener noreferrer"
                >
                  Terms & Conditions
                </a>
                .
              </div>
            </Label>
          </FormGroup>
        </Col>
        <Col xs="12">
          <FormGroup
            className={errorClass(ACCEPT_TOC)}
            style={{ color: "#ec250d !important" }}
          >
            <FieldError
              visible={!!touched[ACCEPT_TOC]}
              error={errors[ACCEPT_TOC]}
            />
          </FormGroup>
        </Col>
      </Row>
      <Row>
        <Col>
          <AsyncButton
            className="font-weight-bold"
            color="primary"
            size="sm"
            style={{ minWidth: "140px" }}
            onClick={async () => {
              setIsLoading(true);
              await submitForm();
              const errors = await validateForm();
              if (Object.keys(errors).length > 0) {
                setIsLoading(false);
                return;
              }

              const checkoutValues = getValues(values);

              let paymentMethodId;
              if (!skipPaymentMethod) {
                if (checkoutValues.newPaymentMethod) {
                  const cardElement = stripeElements.getElement(CardElement);
                  const stripeResponse = await stripe.createPaymentMethod({
                    type: "card",
                    card: cardElement,
                    billing_details: {
                      name: checkoutValues.cardHoldersName,
                      email: userEmail,
                    },
                  });

                  if (stripeResponse.error) {
                    setFieldError(PAYMENT_METHOD, stripeResponse.error.message);
                    setIsLoading(false);
                    return;
                  }
                  paymentMethodId = stripeResponse.paymentMethod.id;
                } else if (checkoutValues?.paymentMethod?.id) {
                  paymentMethodId = checkoutValues.paymentMethod.id;
                }
              }

              await onTakePaymentClick({
                ...checkoutValues,
                paymentMethod: paymentMethodId,
                promoCode: checkoutValues.promoCode || undefined,
              });
              setIsLoading(false);
            }}
          >
            Take Payment
          </AsyncButton>
        </Col>
      </Row>
    </CardBodyWithSpinner>
  );
}

Checkout.defaultProps = {
  lineItems: [],
  discount: null,
  showStartDate: false,
  allowChangingStartDate: false,
  validateDate: () => true,
  skipPaymentMethod: false,
  fetchPaymentMethods: null,
  cardHoldersName: "",
  stripeStyle: {},
  startDate: undefined,
  paymentMethodOptional: false,
};

Checkout.propTypes = {
  lineItems: PropTypes.arrayOf(
    PropTypes.shape({
      name: PropTypes.string.isRequired,
      quantity: PropTypes.number,
      price: PropTypes.string.isRequired,
    }),
  ),
  cardHoldersName: PropTypes.string,
  total: PropTypes.string.isRequired,
  discount: PropTypes.string,
  fetchPaymentMethods: PropTypes.func,
  dateFormat: PropTypes.string.isRequired,
  showStartDate: PropTypes.bool,
  allowChangingStartDate: PropTypes.bool,
  validateDate: PropTypes.func,
  onTakePaymentClick: PropTypes.func.isRequired,
  userEmail: PropTypes.string.isRequired,
  onUpdateSummary: PropTypes.func.isRequired,
  termsAndConditionsUrl: PropTypes.string,
  skipPaymentMethod: PropTypes.bool,
  stripeStyle: PropTypes.object,
  startDate: PropTypes.string,
  paymentMethodOptional: PropTypes.bool,
};

function CheckoutStripe({ stripeApplicationId, stripePublicKey, ...props }) {
  const [stripePromise] = useState(
    loadStripe(stripePublicKey, {
      stripeAccount: stripeApplicationId,
    }),
  );

  return (
    <Elements stripe={stripePromise}>
      <Checkout {...props} />
    </Elements>
  );
}

CheckoutStripe.propTypes = {
  stripeApplicationId: PropTypes.string.isRequired,
  stripePublicKey: PropTypes.string.isRequired,
};

export default CheckoutStripe;
