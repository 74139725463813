import {
  useInfiniteQueryEventOccurrencesAsMember,
  useInfiniteQueryEventOccurrencesAsPublic,
} from "@gymflow/api";
import { cn } from "@gymflow/helpers";
import scrollEndReached from "apps/portal/src/helpers/scrollEndReached";
import { usePortalRoutes } from "apps/portal/src/hooks";
import {
  useAuthenticatedUser,
  useClubSettings,
} from "apps/portal/src/providers";
import useGymflowModels from "apps/portal/src/store";
import { DateTime } from "luxon";
import { AnimatePresence } from "motion/react";
import * as motion from "motion/react-client";
import React from "react";
import { useTranslation } from "react-i18next";

import { Button, Spinner } from "../../atoms";
import { EmptyPage } from "../../atoms/EmptyPage";
import HostedPagesClassCard from "./components/HostedPagesClassCard";
import { HostedPagesClassesFilterPopoverProps } from "./components/HostedPagesClassesFilterPopover";

export type HostedPagesClassesDayListProps = {
  filter: HostedPagesClassesFilterPopoverProps["filter"];
  setFilter: (filter: HostedPagesClassesFilterPopoverProps["filter"]) => void;
};

const HostedPagesClassesDayList = ({
  filter,
  setFilter,
}: HostedPagesClassesDayListProps) => {
  const { isEmbed } = usePortalRoutes();
  const { t } = useTranslation();
  const settings = useClubSettings();
  const { api } = useGymflowModels();
  const auth = useAuthenticatedUser();
  const queryParams: Parameters<
    typeof useInfiniteQueryEventOccurrencesAsMember
  >[0]["filters"] = {
    dateFrom: DateTime.now()
      .startOf("hour")
      .toUTC()
      .toISO({ suppressMilliseconds: true })!,
    dateTo: DateTime.now()
      .plus({ years: 1 })
      .startOf("day")
      .toUTC()
      .toISO({ suppressMilliseconds: true })!,
    includeWaitingCounts: true,
    includeBookedCounts: true,
    ...filter,
  };
  const publicQuery = useInfiniteQueryEventOccurrencesAsPublic({
    api,
    filters: queryParams,
    enabled: !auth.id,
  });
  const memberQuery = useInfiniteQueryEventOccurrencesAsMember({
    api,
    filters: queryParams,
    enabled: !!auth.id,
  });
  const {
    data: listClasses,
    isLoading,
    fetchNextPage,
    isFetching,
    hasNextPage,
  } = auth.id ? memberQuery : publicQuery;
  const classes = listClasses?.pages.flatMap((e) => e.content);
  const emptyClasses =
    listClasses?.pages.reduce((acc, next) => acc + next.content.length, 0) ===
    0;
  const hasFiltersApplied =
    filter.activityCategoryId.length > 0 ||
    filter.activityId.length > 0 ||
    filter.eventHostId.length > 0 ||
    filter.facilityId.length > 0;
  const todayInClubTimezone = DateTime.now().setZone(settings.timezone);
  return (
    <div
      className={cn(
        "track-height flex w-full flex-col items-center self-center overflow-y-auto p-4 max-h-full",
        {
          "h-full": !isEmbed,
        },
      )}
      onScroll={scrollEndReached(100, () => {
        if (!isFetching) {
          fetchNextPage();
        }
      })}
    >
      <div
        className={cn(
          "flex h-fit w-full max-w-[min(calc(100vw-2rem),40rem)] flex-col gap-4 overflow-y-visible",
          {
            "h-full items-center justify-center":
              (!isLoading && emptyClasses) || isLoading,
          },
        )}
      >
        {!isLoading && emptyClasses && (
          <EmptyPage
            textOverride={t("pages.hostedPagesClasses.noClasses")}
            action={
              hasFiltersApplied ? (
                <Button
                  onClick={() => {
                    setFilter({
                      activityCategoryId: [],
                      activityId: [],
                      eventHostId: [],
                      facilityId: [],
                    });
                  }}
                >
                  Clear Filters
                </Button>
              ) : undefined
            }
          />
        )}

        <AnimatePresence mode="popLayout">
          {!isLoading &&
            classes?.map((eventOccurrence, i) => {
              const eventStartDate = DateTime.fromISO(
                eventOccurrence.startDate,
              ).setZone(settings.timezone);
              const hasDateGap =
                i === 0 ||
                !eventStartDate.hasSame(
                  DateTime.fromISO(classes[i - 1].startDate).setZone(
                    settings.timezone,
                  ),
                  "day",
                );

              return (
                <React.Fragment key={i}>
                  {hasDateGap && (
                    <div className="text-sm font-semibold text-gray-500">
                      {todayInClubTimezone.hasSame(eventStartDate, "day")
                        ? t("common.today")
                        : todayInClubTimezone
                            .plus({ days: 1 })
                            .hasSame(eventStartDate, "day")
                        ? t("common.tomorrow")
                        : eventStartDate.toLocaleString(
                            DateTime.DATE_MED_WITH_WEEKDAY,
                          )}
                    </div>
                  )}
                  <motion.div
                    initial={{ opacity: 0, x: 50 }}
                    animate={{
                      opacity: 1,
                      x: 0,
                      transition: {
                        delay: 0.2,
                      },
                    }}
                  >
                    <HostedPagesClassCard
                      key={eventOccurrence.eventOccurrenceId}
                      calendarEventOccurrence={eventOccurrence}
                    />
                  </motion.div>
                </React.Fragment>
              );
            })}
        </AnimatePresence>
        {!isEmbed && !isLoading && isFetching && <Spinner />}
        {!isLoading && isEmbed && hasNextPage && (
          <Button
            showSpinner={isFetching}
            disabled={isFetching}
            onClick={() => {
              fetchNextPage();
            }}
          >
            {t("pages.hostedPagesClasses.loadMore")}
          </Button>
        )}
      </div>
    </div>
  );
};

export default HostedPagesClassesDayList;
