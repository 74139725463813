import { useAutoAnimate } from "@formkit/auto-animate/react";
import { faAngleRight } from "@fortawesome/free-solid-svg-icons";
import { FontAwesomeIcon } from "@fortawesome/react-fontawesome";
import {
  useMutationAddMemberToOccurrenceAsMember,
  useMutationUpdateRsvpAsMember,
  useQueryEventOccurrenceAsMember,
  useQueryRulesAsPublic,
  useQuerySessionPackUsageAsMember,
} from "@gymflow/api";
import {
  formatCurrency,
  ordinal,
  pluralize,
  rulesHelper,
} from "@gymflow/helpers";
import { EventRsvpStatus } from "@gymflow/types";
import {
  evaluateBookingWindow,
  isOccurrenceInsideCancelationWindow,
} from "@gymflow/validation";
import { usePortalRoutes } from "apps/portal/src/hooks";
import {
  useAuthenticatedUser,
  useClubSettings,
} from "apps/portal/src/providers";
import { ToastContext } from "apps/portal/src/providers/ToastProvider/context";
import { RouteFeature } from "apps/portal/src/routes";
import { isAxiosError } from "axios";
import { DateTime, Interval } from "luxon";
import React, { useCallback, useContext } from "react";
import { useTranslation } from "react-i18next";
import { useHistory, useParams } from "react-router";

import useGymflowModels from "../../../store";
import {
  Button,
  CalendarIcon,
  ClockIcon,
  CreditsIcon,
  LayeredCircles,
  ShopBagIcon,
  Spinner,
} from "../../atoms";
import { EmptyDotBadge } from "../../atoms/EmptyDotBadge";
import { ExclamationCircleIcon } from "../../atoms/icons/ExclamationCircleIcon";
import { LocationMarkerIcon } from "../../atoms/icons/LocationMarkerIcon";
import { StaffAvatar } from "../../molecules/StaffAvatar";
import { HostedPagesBackHeader } from "../components/HostedPagesBackHeader";
import HostedPagesQuickPurchaseOverlay from "../HostedPagesQuickPurchase";
import useRedirectIfNotAuthenticated from "../useRedirectIfNotAuthenticated";
import { getTimetableQueryParams } from "./components/useTimetableQueryParamsStore";

export type HostedPagesClassOverviewProps = {};

export const HostedPagesClassOverview: React.FC<
  HostedPagesClassOverviewProps
> = ({}) => {
  const parseRuleError = rulesHelper.useParseRuleError();
  const { eventOccurrenceId, viewType } = useParams<{
    eventOccurrenceId: string;
    viewType: "calendar" | "list";
  }>();
  const history = useHistory();
  const { createSiteOrEmbedLink } = usePortalRoutes();
  const auth = useAuthenticatedUser();

  useRedirectIfNotAuthenticated(
    createSiteOrEmbedLink(
      `${RouteFeature.SiteTimetableType.replace(
        ":viewType",
        viewType,
      )}?${getTimetableQueryParams()}`,
    ),
  );
  const { notifyDanger } = useContext(ToastContext);
  const { t } = useTranslation();
  const { api } = useGymflowModels();
  const settings = useClubSettings();

  const { data: eventOccurrence, isLoading } = useQueryEventOccurrenceAsMember({
    api,
    id: parseInt(eventOccurrenceId),
  });
  const { data: sessionPackUsage } = useQuerySessionPackUsageAsMember({
    api,
    params: eventOccurrence
      ? {
          sessionCost: eventOccurrence.event.sessionCost,
          activityCategoryId:
            eventOccurrence.event.activity.activityCategory.id,
        }
      : undefined,
  });
  const updateRsvpMutation = useMutationUpdateRsvpAsMember({ api });
  const addMemberToOccurrenceMutation =
    useMutationAddMemberToOccurrenceAsMember({
      api,
    });
  const currentRsvp = eventOccurrence?.eventRsvpList.find(
    (e) => e.userMember.id === auth.id,
  );
  const slotsAvailable =
    (eventOccurrence?.event.capacity ?? 0) -
    (eventOccurrence?.bookedCount ?? 0);
  const waitlistSlotsAvailable =
    (eventOccurrence?.event.waitListCapacity ?? 0) -
    (eventOccurrence?.waitingCount ?? 0);
  const bookActionType =
    slotsAvailable > 0
      ? "BOOK"
      : waitlistSlotsAvailable
      ? "JOIN_WAITLIST"
      : "CLASS_FULL";
  const { data: activityRules } = useQueryRulesAsPublic({
    api,
    params: eventOccurrence
      ? {
          activityId: eventOccurrence?.event.activity.id,
        }
      : undefined,
  });
  const bookingCancelationRule = activityRules?.ruleClubList.find(
    (e) => e.bookingCancellationRule,
  );

  const isEventInsideBookingWindow =
    eventOccurrence?.startDate && activityRules
      ? evaluateBookingWindow(eventOccurrence.startDate, activityRules).canBook
      : undefined;
  const isInsideCancelationWindow = isOccurrenceInsideCancelationWindow(
    eventOccurrence?.startDate,
    bookingCancelationRule,
  );
  const lateCancelationFee =
    bookingCancelationRule?.bookingCancellationRule?.cancellationFee;
  const closeOverlay = useCallback(() => {
    if (!eventOccurrence) {
      return;
    }
    history.replace(
      createSiteOrEmbedLink(
        RouteFeature.SiteTimetableId.replace(":viewType", viewType).replace(
          ":eventOccurrenceId",
          eventOccurrenceId,
        ),
      ),
    );
  }, [
    createSiteOrEmbedLink,
    eventOccurrence,
    eventOccurrenceId,
    history,
    viewType,
  ]);
  const { toast } = useContext(ToastContext);
  const [parent] = useAutoAnimate();
  const [innerParent] = useAutoAnimate();
  return (
    <div className="track-height flex max-h-fit w-full flex-col overflow-y-auto">
      <HostedPagesBackHeader
        link={createSiteOrEmbedLink(
          `${RouteFeature.SiteTimetableType.replace(
            ":viewType",
            viewType,
          )}?${getTimetableQueryParams()}`,
        )}
        label={
          !currentRsvp ||
          currentRsvp.status === "LATE_CANCELLED" ||
          currentRsvp.status === "CANCELLED"
            ? t("pages.hostedPagesClassOverView.confirmBooking")
            : t("pages.hostedPagesClassOverView.cancelBooking")
        }
      />
      <div ref={parent} className="flex h-full flex-col items-center">
        {isLoading && <Spinner />}
        {!isLoading && eventOccurrence && (
          <div
            ref={innerParent}
            className="flex h-fit w-[32rem] max-w-full flex-col items-center gap-8 overflow-y-auto overflow-x-hidden p-6 max-lg:justify-between "
          >
            <div className="flex w-full flex-col items-center gap-6">
              <div className="flex w-full flex-col gap-1">
                <div className="wrap flex flex-row items-center justify-between gap-2">
                  <div className="dark:text-gray-0 flex w-full text-xl font-bold text-gray-950">
                    {eventOccurrence.event.activity.name}
                  </div>
                  <div className="flex flex-row items-center gap-1">
                    <ClockIcon
                      pathClassName="stroke-gray-500 stroke-1"
                      className="h-5 w-5"
                    />
                    <div className="dark:text-gray-0 font-semibold text-gray-950">
                      {Interval.fromDateTimes(
                        DateTime.fromISO(eventOccurrence.startDate),
                        DateTime.fromISO(eventOccurrence.endDate),
                      )
                        .toDuration()
                        .rescale()
                        .toHuman({
                          unitDisplay: "narrow",
                          listStyle: "short",
                        })}
                    </div>
                  </div>
                </div>
                {eventOccurrence.event.activity.description && (
                  <div className="flex w-full text-sm text-gray-600 dark:text-gray-400">
                    {eventOccurrence.event.activity.description}
                  </div>
                )}
              </div>
              {currentRsvp?.status && (
                <div className="flex w-full">
                  <EmptyDotBadge
                    intent={
                      (
                        {
                          WAITING: "warning",
                          BOOKED: "lightblue",
                          ATTENDED: "success",
                          CANCELLED: "warning",
                          LATE_CANCELLED: "error",
                          NO_SHOW: "error",
                        } as const
                      )[currentRsvp.status]
                    }
                  >
                    {currentRsvp.status === "WAITING"
                      ? t(`pages.hostedPagesClasses.positionOnWaitlist`, {
                          position: ordinal(
                            eventOccurrence?.waitlistPosition ?? 0,
                          ),
                        })
                      : t(`common.rsvpStatus.${currentRsvp.status}`)}
                  </EmptyDotBadge>
                </div>
              )}
              <div className="flex w-full flex-col gap-1">
                <StaffAvatar userStaff={eventOccurrence.event.userEventHost} />
                <div className="flex flex-row items-center gap-2">
                  <CalendarIcon
                    pathClassName="stroke-gray-500"
                    className="h-5 w-5"
                  />
                  <div className="dark:text-gray-0 line-clamp-1 text-sm font-semibold text-gray-950">
                    {DateTime.fromISO(eventOccurrence.startDate)
                      .setZone(settings.timezone)
                      .toLocaleString({
                        weekday: "long",
                        month: "long",
                        day: "numeric",
                        year: "numeric",
                      })}
                  </div>
                </div>
                <div className="flex flex-row items-center gap-2">
                  <ClockIcon
                    pathClassName="stroke-gray-500"
                    className="h-5 w-5"
                  />
                  <div className="dark:text-gray-0 line-clamp-1 text-sm font-semibold text-gray-950">
                    {Interval.fromDateTimes(
                      DateTime.fromISO(eventOccurrence.startDate),
                      DateTime.fromISO(eventOccurrence.endDate),
                    ).toLocaleString(DateTime.TIME_SIMPLE)}
                  </div>
                </div>
                <div className="flex flex-row items-center gap-2">
                  <LocationMarkerIcon
                    pathClassName="stroke-gray-500"
                    className="h-5 w-5"
                  />
                  <div className="dark:text-gray-0 line-clamp-1 text-sm font-semibold text-gray-950">
                    {eventOccurrence.event.facility.name}
                  </div>
                </div>
              </div>
            </div>
            {currentRsvp &&
              (["BOOKED", "WAITING"] as EventRsvpStatus[]).includes(
                currentRsvp.status,
              ) && (
                <>
                  {isInsideCancelationWindow && (
                    <Button
                      className="w-full"
                      intent="danger"
                      onClick={async () => {
                        await updateRsvpMutation.mutateAsync({
                          rsvpId: currentRsvp.id,
                          status: "CANCELLED",
                        });
                        toast({
                          message: t(
                            "pages.hostedPagesClassesOverview.classCancelled",
                          ),
                          type: "small",
                        });
                        history.push(
                          createSiteOrEmbedLink(
                            `${RouteFeature.SiteTimetableType.replace(
                              ":viewType",
                              viewType,
                            )}?${getTimetableQueryParams()}`,
                          ),
                        );
                      }}
                    >
                      {t("pages.hostedPagesClassesOverview.cancelBooking")}
                    </Button>
                  )}
                  {!isInsideCancelationWindow && (
                    <>
                      <div className="flex w-full flex-col gap-2">
                        <div className="flex flex-row items-center gap-2">
                          <LayeredCircles intent="warning" className="h-8 w-8">
                            <ExclamationCircleIcon pathClassName="stroke-warning-600" />
                          </LayeredCircles>
                          <div className="text-warning-600 font-semibold">
                            {t(
                              "pages.hostedPagesClassesOverview.lateCancelationFeeTitle",
                            )}
                          </div>
                        </div>
                        <div className="text-sm text-gray-500">
                          {lateCancelationFee
                            ? t(
                                "pages.hostedPagesClassesOverview.lateCancelationFeeDescription",
                                {
                                  fee: formatCurrency(
                                    lateCancelationFee,
                                    settings?.defaultCurrency,
                                  ),
                                },
                              )
                            : t(
                                "pages.hostedPagesClassesOverview.lateCancelationFeeDescriptionNoFee",
                              )}
                        </div>
                      </div>
                      <Button
                        className="w-full"
                        intent="danger"
                        {...(lateCancelationFee
                          ? {
                              link: createSiteOrEmbedLink(
                                RouteFeature.SiteTimeTableLateCancelId.replace(
                                  ":viewType",
                                  viewType,
                                ).replace(
                                  ":eventOccurrenceId",
                                  eventOccurrenceId,
                                ),
                              ),
                            }
                          : {
                              onClick: async () => {
                                await updateRsvpMutation.mutateAsync({
                                  rsvpId: currentRsvp.id,
                                  status: "LATE_CANCELLED",
                                });
                                toast({
                                  message: t(
                                    "pages.hostedPagesClassesOverview.classCancelled",
                                  ),
                                  type: "small",
                                });
                                history.push(
                                  createSiteOrEmbedLink(
                                    `${RouteFeature.SiteTimetableType.replace(
                                      ":viewType",
                                      viewType,
                                    )}?${getTimetableQueryParams()}`,
                                  ),
                                );
                              },
                            })}
                      >
                        {!lateCancelationFee
                          ? t("pages.hostedPagesClassesOverview.cancelBooking")
                          : t(
                              "pages.hostedPagesClassesOverview.cancelBookingAndPay",
                            )}
                      </Button>
                    </>
                  )}
                </>
              )}
            {(!currentRsvp ||
              (["LATE_CANCELLED", "CANCELLED"] as EventRsvpStatus[]).includes(
                currentRsvp.status,
              )) && (
              <>
                <div className="flex w-full border-b border-gray-300 dark:border-gray-700" />
                <div className="flex w-full flex-col gap-4">
                  <div className="flex w-full flex-col gap-2">
                    <div className="flex flex-row items-center gap-2">
                      <LayeredCircles className="h-8 w-8" intent="default">
                        <ExclamationCircleIcon pathClassName="stroke-gray-500" />
                      </LayeredCircles>
                      <div className="dark:text-gray-0 font-semibold text-gray-950">
                        {t("page.hostedPagesClasses.credit")}
                      </div>
                    </div>
                    <div className="text-sm text-gray-500">
                      {t("page.hostedPagesClasses.creditDescription")}
                    </div>
                  </div>
                  <div className="flex flex-col gap-4">
                    {sessionPackUsage &&
                      sessionPackUsage?.result !== "NOT_ENOUGH_SESSIONS" &&
                      sessionPackUsage?.sessionPackUsageCalculations
                        .filter((e) => e.numberOfSessionsUsed > 0)
                        .map((usageEntry, i) => (
                          <div
                            key={i}
                            className="flex flex-row items-center justify-between rounded-xl border border-gray-300 p-3 dark:border-gray-700 dark:bg-gray-900"
                          >
                            <div className="flex flex-col">
                              <div className="dark:text-gray-0 text-sm font-bold text-gray-950">
                                {usageEntry.sessionPack.name}
                              </div>
                              <div className="text-sm text-gray-500">
                                {t("page.hostedPagesClasses.creditExpiry", {
                                  date: usageEntry.sessionPack.endDate
                                    ? DateTime.fromISO(
                                        usageEntry.sessionPack.endDate,
                                      )
                                        .setZone(settings.timezone)
                                        .toLocaleString(DateTime.DATE_FULL)
                                    : t("common.unlimited"),
                                })}
                              </div>
                            </div>
                            <div className="flex flex-row gap-2">
                              <div className="dark:text-gray-0 text-xl font-bold text-gray-950">
                                {usageEntry.numberOfSessionsUsed}
                              </div>
                              <CreditsIcon className="stroke-secondary-500 h-6 w-6" />
                            </div>
                          </div>
                        ))}
                  </div>
                  {sessionPackUsage &&
                    sessionPackUsage?.result === "NOT_ENOUGH_SESSIONS" && (
                      <div className="flex flex-col gap-2">
                        <div className="flex flex-row items-center justify-between rounded-xl border border-gray-300 p-3 dark:border-gray-700 dark:bg-gray-900">
                          <div className="flex flex-col">
                            <div className="dark:text-gray-0 text-sm font-bold text-gray-950">
                              {t("page.hostedPagesClasses.noCreditPack")}
                            </div>
                          </div>
                          <div className="flex flex-row gap-2">
                            <div className="dark:text-gray-0 text-xl font-bold text-gray-950">
                              0
                            </div>
                            <CreditsIcon className="stroke-secondary-500 h-6 w-6" />
                          </div>
                        </div>

                        <Button
                          link={createSiteOrEmbedLink(
                            RouteFeature.SiteTimeTableQuickPurchase.replace(
                              ":viewType",
                              viewType,
                            )
                              .replace(":eventOccurrenceId", eventOccurrenceId)
                              .replace(":type", "session-pack"),
                          )}
                          className="h-12 w-full justify-between rounded-lg border-gray-300 py-3 dark:border-gray-700"
                        >
                          <div className="text-md dark:text-gray-0 flex flex-row items-center gap-2 font-medium text-gray-950">
                            <ShopBagIcon pathClassName="stroke-gray-500" />
                            {t("page.hostedPagesClasses.buyCreditPack")}
                          </div>
                          <FontAwesomeIcon
                            className="text-gray-500"
                            icon={faAngleRight}
                          />
                        </Button>
                      </div>
                    )}
                </div>

                <div className="flex w-full border-b-2 border-dashed border-gray-300 dark:border-gray-700" />
                <div className="flex w-full flex-col gap-2">
                  <div className="flex w-full flex-row items-center justify-between">
                    <div className="text-gray-500">
                      {eventOccurrence.event.activity.name}
                    </div>
                    <div className="text-gray-500">
                      {pluralize(
                        t("page.hostedPagesClasses.creditCostAmount", {
                          amount: eventOccurrence.event.sessionCost,
                        }),
                        t("page.hostedPagesClasses.creditCostAmounts", {
                          amount: eventOccurrence.event.sessionCost,
                        }),
                        eventOccurrence.event.sessionCost,
                      )}
                    </div>
                  </div>
                  <div className="dark:text-gray-0 flex w-full flex-row items-center justify-between text-gray-950">
                    <div className="text-xl font-bold">Amount Due</div>
                    <div className="text-xl font-bold">
                      {pluralize(
                        t("page.hostedPagesClasses.creditCostAmount", {
                          amount: eventOccurrence.event.sessionCost,
                        }),
                        t("page.hostedPagesClasses.creditCostAmounts", {
                          amount: eventOccurrence.event.sessionCost,
                        }),
                        eventOccurrence.event.sessionCost,
                      )}
                    </div>
                  </div>
                </div>

                <div className="flex flex-col items-center gap-1">
                  {slotsAvailable > 0 && slotsAvailable < 3 && (
                    <div className="text-warning-500 text-xm font-semibold">
                      {pluralize(
                        t("page.hostedPagesClasses.amountSpotLeft", {
                          amount: slotsAvailable,
                        }),
                        t("page.hostedPagesClasses.amountSpotsLeft", {
                          amount: slotsAvailable,
                        }),
                        slotsAvailable,
                      )}
                    </div>
                  )}
                  {slotsAvailable === 0 && eventOccurrence.waitingCount > 0 && (
                    <div className="text-xm font-semibold text-gray-500">
                      {t("page.hostedPagesClasses.amountWaiting", {
                        amount: eventOccurrence.waitingCount,
                      })}
                    </div>
                  )}
                  {!isEventInsideBookingWindow && (
                    <div className="text-warning-500 text-sm font-semibold">
                      {t("addPaymentMethodModal.outsideBookingWindow")}
                    </div>
                  )}
                  <Button
                    disabled={
                      !isEventInsideBookingWindow ||
                      !sessionPackUsage ||
                      sessionPackUsage.result === "NOT_ENOUGH_SESSIONS"
                    }
                    intent="secondary"
                    className="w-fit px-16"
                    onClick={async () => {
                      if (!auth.id) return;
                      try {
                        if (currentRsvp) {
                          await updateRsvpMutation.mutateAsync({
                            rsvpId: currentRsvp.id,
                            status: "BOOKED",
                          });
                        } else {
                          await addMemberToOccurrenceMutation.mutateAsync({
                            userMemberId: auth.id,
                            occurrenceId: eventOccurrence.id,
                          });
                        }
                        toast({
                          intent: "success",
                          type: "small",
                          message: t(
                            "pages.hostedPagesClassesOverview.bookingSuccess",
                          ),
                        });
                        history.push(
                          createSiteOrEmbedLink(
                            `${RouteFeature.SiteTimetableType.replace(
                              ":viewType",
                              viewType,
                            )}?${getTimetableQueryParams()}`,
                          ),
                        );
                      } catch (e) {
                        if (isAxiosError(e)) {
                          const rulesError = parseRuleError(e, activityRules!);
                          if (rulesError) {
                            toast({
                              message: t(`errorCodes.generic`),
                              description: rulesError,
                              intent: "error",
                            });
                          } else {
                            notifyDanger(e);
                          }
                        }
                      }
                    }}
                  >
                    {bookActionType === "BOOK"
                      ? pluralize(
                          t(
                            "pages.hostedPagesClassesOverview.bookForCreditAmount",
                            {
                              amount: eventOccurrence.event.sessionCost,
                            },
                          ),
                          t(
                            "pages.hostedPagesClassesOverview.bookForCreditsAmount",
                            {
                              amount: eventOccurrence.event.sessionCost,
                            },
                          ),
                          eventOccurrence.event.sessionCost,
                        )
                      : bookActionType === "JOIN_WAITLIST"
                      ? t("pages.hostedPagesClassesOverview.joinWaitlist", {
                          amount: eventOccurrence.event.sessionCost,
                        })
                      : t("pages.hostedPagesClassesOverview.classFull")}
                  </Button>
                </div>
              </>
            )}
          </div>
        )}
      </div>
      <HostedPagesQuickPurchaseOverlay
        closeOverlay={closeOverlay}
        purchaseFinishedButton={
          <Button
            link={createSiteOrEmbedLink(
              RouteFeature.SiteTimetableId.replace(
                ":viewType",
                viewType,
              ).replace(":eventOccurrenceId", eventOccurrenceId!),
            )}
          >
            {t("pages.hostedPagesCheckout.proceedToBooking")}
          </Button>
        }
      />
    </div>
  );
};
