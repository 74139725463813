import {
  membershipChangeOptionsQueryFn,
  membershipQueryKeys,
} from "@gymflow/api";
import { formatCurrency, membershipHelper } from "@gymflow/helpers";
import {
  ApiTokenListResponse,
  MembershipBean,
  UserMemberSubscriptionBeanWithMembership,
} from "@gymflow/types";
import { InfiniteData, UseInfiniteQueryOptions } from "@tanstack/react-query";
import {
  FormSelectInput,
  SelectInputOptionType,
} from "apps/portal/src/components/atoms";
import { useClubSettings } from "apps/portal/src/providers";
import useGymflowModels from "apps/portal/src/store";
import { useCallback } from "react";
import { useTranslation } from "react-i18next";

import { ChangeMembershipFormValues } from "../types";

export type FormNewMembershipSelectProps = {
  activeMembership: UserMemberSubscriptionBeanWithMembership;
};

export const FormNewMembershipSelect = ({
  activeMembership,
}: FormNewMembershipSelectProps) => {
  const { t } = useTranslation();

  const { defaultCurrency } = useClubSettings();
  const { api } = useGymflowModels();

  const dataFetchingQuery = useCallback(
    (): UseInfiniteQueryOptions<
      unknown,
      unknown,
      SelectInputOptionType<MembershipBean>[]
    > => ({
      queryKey: membershipQueryKeys.list(),
      queryFn: async ({ pageParam }) =>
        await membershipChangeOptionsQueryFn({
          api,
          subscriptionId: activeMembership.id,
          nextPageToken: pageParam,
        }),
      select: (data) => {
        const { pageParams, pages } = data as InfiniteData<
          ApiTokenListResponse<MembershipBean>
        >;

        const transformedPages = pages.map((page) =>
          page.content
            .filter((e) => e.id !== activeMembership?.membershipBean.id)
            ?.map((e) => ({
              id: e.id,
              label: e.name,
              subLabel: `${formatCurrency(
                e.defaultPrice,
                defaultCurrency,
              )} ${membershipHelper.getBillingRecurrence({
                ...e,
                type: "RECURRING",
              })}`,
              value: e,
            })),
        );

        return {
          pageParams,
          pages: transformedPages,
        };
      },
      getNextPageParam: (lastPage: any) =>
        (lastPage as ApiTokenListResponse<MembershipBean>).nextPageToken,
    }),
    [
      api,
      activeMembership.id,
      activeMembership?.membershipBean.id,
      defaultCurrency,
    ],
  );

  return (
    <FormSelectInput<ChangeMembershipFormValues, MembershipBean>
      name="newMembership"
      label={t(
        "page.userMember.tab.userMemberAccount.userMemberMembership.membershipCard.modal.changeMembershipModal.formNewMembershipSelect.label",
      )}
      isMulti={false}
      dataFetchingQuery={dataFetchingQuery}
    />
  );
};
