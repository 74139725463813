import {
  faCreditCard,
  faShoppingCart,
  faUserPlus,
} from "@fortawesome/free-solid-svg-icons";
import { FontAwesomeIcon } from "@fortawesome/react-fontawesome";
import { clubStaleTime, useClub } from "@gymflow/api";
import {
  AccordionWizard,
  Checkout,
  PARAMETER_DATE_ONLY_FORMAT,
  SaleType,
  SERVICE_START_DATE_LIMIT,
  SessionPackCarousel,
  useSaleStepTitleBuilder,
} from "@gymflow/common";
import { formatCurrency } from "@gymflow/helpers";
import { useStoreState } from "easy-peasy";
import moment from "moment-timezone";
import PropTypes from "prop-types";
import { useEffect, useMemo, useState } from "react";

import usePaymentSummary from "../../hooks/usePaymentSummary";
import { usePortalRoutes } from "../../hooks/usePortalRoutes";
import { useClubSettings } from "../../providers";
import { RouteFeature } from "../../routes/feature";
import { RouteLayout } from "../../routes/layout";
import useGymflowModels from "../../store";
import SalesRoute from "./SalesRoute";
import UserDetails from "./UserDetails";

function SellSessionPackAccordion({
  sessionPacks,
  onUserSubmit,
  searchUsers,
  currency,
  fetchPaymentMethods,
  fetchLeadSources,
  dateFormat,
  purchase,
  summary,
  requiredFields,
}) {
  const { createClubLink } = usePortalRoutes();
  const { api, settingsStore } = useGymflowModels();
  const { stripeApplicationId } = useStoreState(settingsStore);
  const settings = useClubSettings();
  const stripePublicKey = settings.stripe_public_key;
  const defaultNationality = settings.default_nationality;
  const postCodeFormat = settings.postal_code_country;
  const telephoneFormat = useMemo(
    () => JSON.parse(settings.phone_number_country),
    [settings.phone_number_country],
  );
  const { data: club } = useClub(
    { api, clubId: settings.clubId },
    { staleTime: clubStaleTime },
  );

  const [activeStep, setActiveStep] = useState(0);

  const [cart, setCart] = useState([]);
  const [user, setUser] = useState(null);

  const {
    summary: paymentSummary,
    onUpdateSessionPackSummary: onUpdateSummary,
  } = usePaymentSummary({
    fetchSummary: summary,
    currency,
  });

  const { step1: step1Title, step2: step2Title } = useSaleStepTitleBuilder({
    saleType: SaleType.SessionPack,
    activeStep,
    user,
    cart,
    recurringMembershipLink: createClubLink(
      RouteLayout.Staff,
      RouteFeature.Sales,
      SalesRoute.RecurringMembership,
    ),
    prepaidMembershipLink: createClubLink(
      RouteLayout.Staff,
      RouteFeature.Sales,
      SalesRoute.PrepaidMembership,
    ),
    sessionPackLink: createClubLink(
      RouteLayout.Staff,
      RouteFeature.Sales,
      SalesRoute.SessionsPack,
    ),
    productLink: createClubLink(
      RouteLayout.Staff,
      RouteFeature.Sales,
      SalesRoute.Product,
    ),
  });

  useEffect(() => {
    if (!user) {
      return;
    }
    onUpdateSummary({
      sessionPackId: cart[0].id,
      userMemberId: user.id,
      sessionPackName: cart[0].name,
    });
  }, [cart, user]);

  const steps = [
    {
      title: step1Title,
      icon: <FontAwesomeIcon icon={faShoppingCart} />,
      component: SessionPackCarousel,
      props: {
        sessionPacks,
        onSelectClick: (sessionPackId) => {
          setCart([sessionPacks.find(({ id }) => id === sessionPackId)]);
          setActiveStep(1);
        },
      },
    },
    {
      title: step2Title,
      icon: <FontAwesomeIcon icon={faUserPlus} />,
      component: UserDetails,
      contentInsideTitleCard: true,
      props: {
        initialValues: user,
        searchUsers,
        fetchLeadSources,
        onUserSelectClick: async (userSelected) => {
          const updatedUser = await onUserSubmit(userSelected);
          if (!updatedUser) {
            return;
          }
          setUser(updatedUser);
          setActiveStep(2);
        },
        postCodeFormat,
        telephoneFormat,
        defaultNationality,
        dateFormat,
        requiredFields,
        clubWaiverLink: club?.termsConditions,
      },
    },
    {
      title: "3. Checkout",
      icon: <FontAwesomeIcon icon={faCreditCard} />,
      component: Checkout,
      contentInsideTitleCard: true,
      props: {
        stripeApplicationId,
        stripePublicKey,
        userEmail: user?.email,
        cardHoldersName: user ? `${user.firstName} ${user.lastName}` : "",
        lineItems: paymentSummary.lineItems,
        total: paymentSummary.total,
        discount: paymentSummary.discount,
        fetchPaymentMethods: () =>
          fetchPaymentMethods({ userMemberId: user.id }),
        dateFormat,
        validateDate: (date) => {
          const todayInGym = moment()
            .tz(settings.timezone)
            .format("YYYY-MM-DD");
          const maxDate = moment(todayInGym, "YYYY-MM-DD")
            .add(SERVICE_START_DATE_LIMIT)
            .format(PARAMETER_DATE_ONLY_FORMAT);
          const minDate = moment(todayInGym, "YYYY-MM-DD")
            .subtract(1, "day")
            .endOf("day")
            .format(PARAMETER_DATE_ONLY_FORMAT);

          return date.isAfter(minDate) && date.isBefore(maxDate);
        },
        onTakePaymentClick: (paymentDetails) => {
          return purchase({ ...user, ...paymentDetails, cart });
        },
        onUpdateSummary: (rest) =>
          onUpdateSummary({
            sessionPackId: cart[0].id,
            userMemberId: user.id,
            sessionPackName: cart[0].name,
            ...rest,
          }),
        termsAndConditionsUrl: cart[0] && cart[0].termsAndConditionsLink,
        allowChangingStartDate: true,
        showStartDate: true,
        skipPaymentMethod: paymentSummary.total === formatCurrency(0, currency),
        paymentMethodOptional:
          paymentSummary.total === formatCurrency(0, currency),
        timezone: settings.timezone,
      },
    },
  ];
  return (
    <AccordionWizard
      steps={steps}
      activeStep={activeStep}
      onStepTitleClick={(stepClicked) => setActiveStep(stepClicked)}
    />
  );
}

SellSessionPackAccordion.defaultProps = {
  sessionPacks: [],
};

SellSessionPackAccordion.propTypes = {
  sessionPacks: PropTypes.arrayOf(
    PropTypes.shape({
      id: PropTypes.number.isRequired,
      name: PropTypes.string.isRequired,
      description: PropTypes.string,
      price: PropTypes.string.isRequired,
      sessionsIncluded: PropTypes.string.isRequired,
      expiry: PropTypes.string,
      termsAndConditionsLink: PropTypes.string.isRequired,
    }),
  ),
  onUserSubmit: PropTypes.func.isRequired,
  purchase: PropTypes.func.isRequired,
  searchUsers: PropTypes.func.isRequired,
  summary: PropTypes.func.isRequired,
  currency: PropTypes.string.isRequired,
  fetchPaymentMethods: PropTypes.func.isRequired,
  fetchLeadSources: PropTypes.func.isRequired,
  dateFormat: PropTypes.string.isRequired,
};

export default SellSessionPackAccordion;
