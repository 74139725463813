import { ApiType } from "@gymflow/common/lib/api/ApiType";
import { useQuery } from "@tanstack/react-query";

import { membershipAsMemberQueryKeys } from "./membershipAsMemberQueryKeys";

export function useQueryMembershipListAsMember(
  {
    api,
    opts,
  }: {
    api: ApiType;
    opts: Parameters<ApiType["customerMembershipApi"]["getList"]>[0];
  },
  {
    enabled,
  }: {
    enabled?: boolean;
  } = {
    enabled: true,
  },
) {
  return useQuery({
    queryKey: membershipAsMemberQueryKeys.list(opts),
    queryFn: async () => {
      return (await api.customerMembershipApi.getList({ ...opts })).data;
    },
    enabled,
  });
}
