import { ApiType } from "@gymflow/common/lib/api/ApiType";
import { QueryKey } from "@tanstack/react-query";

export const memberAsMemberQueryKeys = {
  all: () => ["memberAsMember"] as QueryKey,
  details: (memberId?: string) =>
    [...memberAsMemberQueryKeys.all(), memberId, "details"] as QueryKey,
  paymentMethods: (clubId: number, memberId?: string) =>
    [
      ...memberAsMemberQueryKeys.all(),
      memberId,
      "paymentMethods",
      clubId,
    ] as QueryKey,
  invoice: (invoiceNumber: string) =>
    [...memberAsMemberQueryKeys.all(), "invoice", invoiceNumber] as QueryKey,
  invoices: (filters?: Readonly<{ [k: string]: any }>) =>
    [...memberAsMemberQueryKeys.all(), "invoices", filters] as QueryKey,
  invoiceFile: (clubId: number, invoiceNumber: string) =>
    [
      ...memberAsMemberQueryKeys.all(),
      "invoiceFile",
      clubId,
      invoiceNumber,
    ] as QueryKey,
  paymentMethodsNew: (clubId: number, memberId: string) =>
    [
      ...memberAsMemberQueryKeys.all(),
      "paymentMethodsNew",
      clubId,
      memberId,
    ] as QueryKey,
  invoicesNew: (memberId: string, filters?: { [k: string]: any }) => {
    const result = [...memberAsMemberQueryKeys.all(), memberId, "invoicesNew"];
    if (filters) result.push(filters);
    return result as QueryKey;
  },
  highlightedInvoice: (memberId: string) => [
    ...memberAsMemberQueryKeys.all(),
    memberId,
    "invoicesNew",
    "highlightedInvoice",
  ],
  paymentMethodSetupIntent: (memberId?: string) =>
    [
      ...memberAsMemberQueryKeys.details(memberId),
      "paymentMethodSetupIntent",
    ] as QueryKey,
  sessionPackUsage: (
    params?: Parameters<ApiType["profileApi"]["sessionPackUsage"]>[0],
  ) =>
    [...memberAsMemberQueryKeys.all(), "sessionPackUsage", params] as QueryKey,
};
