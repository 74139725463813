import { emailTemplateListQueryFn, emailTemplateQueryKeys } from "@gymflow/api";
import { ApiListResponse, TemplateDTO } from "@gymflow/types";
import { InfiniteData, UseInfiniteQueryOptions } from "@tanstack/react-query";
import {
  SelectInput,
  SelectInputOptionType,
} from "apps/portal/src/components/atoms";
import useGymflowModels from "apps/portal/src/store";
import { useCallback } from "react";
import { useTranslation } from "react-i18next";

type TemplateSelectInputProps = {
  onSelect: (template?: TemplateDTO) => void;
  className?: string;
};

export const TemplateSelectInput = ({
  onSelect,
  className,
}: TemplateSelectInputProps) => {
  const { api } = useGymflowModels();
  const { t } = useTranslation();

  const dataFetchingQuery = useCallback(
    (): UseInfiniteQueryOptions<
      unknown,
      unknown,
      SelectInputOptionType<TemplateDTO>[]
    > => ({
      queryKey: emailTemplateQueryKeys.listInfinityQuery(),
      queryFn: async ({ pageParam }) =>
        await emailTemplateListQueryFn({
          api,
          opts: {
            extraParams: {
              isAutomationTemplate: false,
            },
            page: pageParam,
            limit: 30,
          },
        }),
      select: (data) => {
        const { pageParams, pages } = data as InfiniteData<
          ApiListResponse<TemplateDTO>
        >;

        const transformedPages = pages.map((page) =>
          page.content.map((template) => ({
            id: template.id,
            label: template.templateName,
            value: template,
          })),
        );

        return {
          pageParams,
          pages: transformedPages,
        };
      },
      getNextPageParam: (lastPage) => {
        const typedLastPage = lastPage as ApiListResponse<TemplateDTO>;
        return typedLastPage.last ? undefined : typedLastPage.number + 1;
      },
    }),
    [api],
  );

  return (
    <div className={className}>
      <SelectInput
        placeholder={t(
          "components.sendEmailSidebarProvider.sendEmailForm.templateSelectInput.placeholder",
        )}
        label={t(
          "components.sendEmailSidebarProvider.sendEmailForm.templateSelectInput.label",
        )}
        variant="small"
        dataFetchingQuery={dataFetchingQuery}
        onChange={(selected) => onSelect(selected?.value)}
        isClearable
      />
    </div>
  );
};
