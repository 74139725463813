import {
  clubStaleTime,
  useClub,
  useMutationNoteDelete,
  useQueryNoteSearch,
} from "@gymflow/api";
import { PARAMETER_DATE_FORMAT_WITHOUT_TZ } from "@gymflow/common";
import { NoteDTO } from "@gymflow/types";
import { createColumnHelper } from "@tanstack/react-table";
import moment from "moment-timezone";
import qs from "qs";
import { useContext, useMemo, useRef } from "react";
import { useHistory, useLocation } from "react-router";

import { usePageSize } from "../../../hooks/usePageSize";
import { ModalContext, useClubSettings } from "../../../providers";
import useGymflowModels from "../../../store";
import { Badge, PlusCircleIcon, PrimaryButton, TextButton } from "../../atoms";
import { Pagination } from "../../molecules";
import { Table } from "../../organisms";
import { ConfirmModal } from "../../templates";
import { NoteFormSideBarProviderContext } from "./NoteFormSideBarProvider";

export function UserMemberNotes({
  userMemberId,
  leadId,
  name,
}: {
  userMemberId?: string;
  leadId?: number;
  name: string;
}) {
  const { setModal, hide } = useContext(ModalContext);
  const settings = useClubSettings();
  const clubId = settings.clubId!;
  const { api } = useGymflowModels();
  const { data: club } = useClub({ api, clubId }, { staleTime: clubStaleTime });
  const history = useHistory();
  const tableContainerRef = useRef<HTMLDivElement>(null);
  const pageSize = usePageSize({
    tableContainerRef,
    rowHeight: 56,
  });
  const location = useLocation();

  const queryParams = useMemo(() => {
    const {
      page,
      pageSize: pageSizeParam,
      action,
      noteId,
    } = qs.parse(location.search.slice(1));
    return {
      page: page ? +page : 0,
      pageSize: pageSizeParam ? +pageSizeParam : pageSize,
      action: action as ActionType,
      noteId: noteId ? +noteId : undefined,
    };
  }, [location.search, pageSize]);

  const { data: notes, isFetching } = useQueryNoteSearch({
    api,
    tz: club?.timezone || "",
    opts: {
      page: queryParams.page,
      size: queryParams.pageSize || 10,
      userMemberId: userMemberId,
      leadId: leadId,
    },
  });
  const deleteNote = useMutationNoteDelete({ api });

  const { triggerNewNote, triggerEditNote } = useContext(
    NoteFormSideBarProviderContext,
  );
  const columnHelper = createColumnHelper<NoteDTO>();
  const columns = [
    columnHelper.accessor("createdDate", {
      header: "Date",
      cell: (column) => {
        return moment(
          column.row.original.createdDate,
          PARAMETER_DATE_FORMAT_WITHOUT_TZ,
        ).format(settings.date_format);
      },
    }),
    columnHelper.display({
      header: "Time",
      cell: (column) => {
        return (
          <div className="text-gray-600">
            {moment(
              column.row.original.createdDate,
              PARAMETER_DATE_FORMAT_WITHOUT_TZ,
            ).format("HH:mm")}
          </div>
        );
      },
    }),
    columnHelper.display({
      header: "Note",
      cell: (column) => {
        return column.row.original.content;
      },
    }),
    columnHelper.display({
      header: "Added By",
      cell: (column) => {
        return `${column.row.original.createdByFirstName} ${column.row.original.createdByLastName}`;
      },
    }),
    columnHelper.display({
      header: "Files Attached",
      cell: (column) => {
        return column.row.original.attachment ? (
          <Badge>
            <a href={column.row.original.attachment}>
              {column.row.original.attachmentFilename}
            </a>
          </Badge>
        ) : null;
      },
    }),
    columnHelper.display({
      id: "link",
      cell: (column) => {
        const id = column.row.original.id;

        return (
          <div className="flex justify-end">
            <TextButton
              disabled={!!queryParams.noteId}
              onClick={() => {
                triggerEditNote(
                  {
                    name: name,
                    userMemberId: userMemberId,
                    leadId: leadId,
                  },
                  id,
                );
              }}
            >
              Edit
            </TextButton>
            <TextButton
              onClick={() => {
                setModal(
                  <ConfirmModal
                    type="danger"
                    title="Delete Note"
                    onConfirm={async () => {
                      await deleteNote.mutateAsync({ noteId: id });
                      hide();
                    }}
                    onCancel={hide}
                  >
                    Are you sure you want to delete this note? This action
                    cannot be undone.
                  </ConfirmModal>,
                );
              }}
            >
              Delete
            </TextButton>
          </div>
        );
      },
    }),
  ];
  return (
    <div className="relative flex h-full w-full">
      <div className="bg-gray-0 absolute inset-0 m-3 flex flex-1 flex-col rounded-xl border border-gray-200 shadow">
        <div className="flex items-center justify-between p-5">
          <div>
            <div className="text-lg font-bold">Notes</div>
          </div>
          <div>
            <div className="flex items-center">
              <PrimaryButton
                onClick={() => {
                  triggerNewNote({
                    name: name,
                    userMemberId: userMemberId,
                    leadId: leadId,
                  });
                }}
              >
                <div className="flex">
                  <PlusCircleIcon
                    className="mr-2 self-center"
                    pathClassName="stroke-white"
                  />
                  Add Note
                </div>
              </PrimaryButton>
            </div>
          </div>
        </div>

        <Table
          data={notes!.content}
          columns={columns}
          pageCount={notes!.totalPages}
          pageIndex={notes!.number}
          isFetching={isFetching}
          pageSize={queryParams.pageSize}
          rowClassName="h-14"
          tableContainerRef={tableContainerRef}
        />
        <Pagination
          className="min-h-fit p-4"
          hasNextPage={!!notes && notes?.number < notes?.totalPages - 1}
          hasPreviousPage={!!notes && notes?.number > 0}
          goToNextPage={() => {
            history.push({
              search: qs.stringify({
                ...queryParams,
                page: queryParams.page + 1,
              }),
            });
          }}
          goToPreviousPage={() => {
            history.push({
              search: qs.stringify({
                ...queryParams,
                page: queryParams.page - 1,
              }),
            });
          }}
        />
      </div>
    </div>
  );
}

type ActionType = "create" | "edit";
