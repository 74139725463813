import { useAutoAnimate } from "@formkit/auto-animate/react";
import { useQueryCreditPackAsPublic } from "@gymflow/api";
import { cn, formatCurrency, pluralize } from "@gymflow/helpers";
import { usePortalRoutes } from "apps/portal/src/hooks";
import {
  useAuthenticatedUser,
  useClubSettings,
} from "apps/portal/src/providers";
import { LoginOverlayContext } from "apps/portal/src/providers/authentication/loginOverlay/context";
import React, { useContext, useState } from "react";
import { useTranslation } from "react-i18next";
import { useHistory, useParams } from "react-router";

import useGymflowModels from "../../../store";
import { Button, CheckboxInput, ClockIcon, Spinner } from "../../atoms";
import { HostedPagesBackHeader } from "../components/HostedPagesBackHeader";
import { HostedPagesCard } from "../components/HostedPagesCard";
import HostedPagesOverviewCard from "../components/HostedPagesOverviewCard";

export type HostedPagesCreditPackOverviewProps = {
  onClose?: () => void;
};

export const HostedPagesCreditPackOverview: React.FC<
  HostedPagesCreditPackOverviewProps
> = ({ onClose }) => {
  const { t } = useTranslation();
  const { isEmbed, isQuickPurchase } = usePortalRoutes();
  const history = useHistory();
  const auth = useAuthenticatedUser();
  const { showSignUpOverlay } = useContext(LoginOverlayContext);
  const { api } = useGymflowModels();
  const settings = useClubSettings();
  const { sessionPackId } = useParams<{ sessionPackId: string }>();
  const { data: creditPack, isLoading } = useQueryCreditPackAsPublic({
    api,
    creditPackId: parseInt(sessionPackId),
  });

  const [termsAgreed, setTermsAgreed] = useState(false);
  const [parent] = useAutoAnimate();
  return (
    <div
      className={cn("track-height flex w-full flex-col overflow-y-auto", {
        "track-height": !isQuickPurchase,
        "h-fit": isEmbed,
        "h-full": !isEmbed,
      })}
    >
      <HostedPagesBackHeader
        label={t("page.hostedPagesCreditPackOverview.header")}
        onClose={onClose}
      />
      <div
        ref={parent}
        className={cn(
          "flex flex-col items-center bg-gray-50 dark:bg-gray-800",
          {
            "h-fit": isEmbed,
            "h-full": !isEmbed,
          },
        )}
      >
        {isLoading && <Spinner />}
        {!isLoading && creditPack && (
          <HostedPagesOverviewCard.Container>
            <div className="flex w-full flex-col gap-4">
              <HostedPagesOverviewCard.Header>
                <HostedPagesOverviewCard.HeaderTitle>
                  {creditPack.name}
                </HostedPagesOverviewCard.HeaderTitle>
                {creditPack.description && (
                  <HostedPagesOverviewCard.HeaderDescription>
                    {creditPack.description}
                  </HostedPagesOverviewCard.HeaderDescription>
                )}
              </HostedPagesOverviewCard.Header>
              <HostedPagesCard.Price
                price={
                  creditPack.price === 0
                    ? t("common.free")
                    : formatCurrency(creditPack.price, settings.defaultCurrency)
                }
                description={
                  <>
                    <ClockIcon />
                    {creditPack.expiryType === "NA"
                      ? "No expiry"
                      : creditPack.expiryType === "WEEKLY"
                      ? `${creditPack.expiryValue} ${pluralize(
                          "Week",
                          "Weeks",
                          1,
                        )} Expiry`
                      : `${creditPack.expiryValue} ${pluralize(
                          "Month",
                          "Months",
                          1,
                        )} Expiry`}
                  </>
                }
              />
            </div>
            <div className="flex w-full flex-col items-center gap-4">
              <div className="flex w-full">
                <CheckboxInput
                  containerClassName="flex w-full gap-2"
                  onChange={setTermsAgreed}
                  checked={termsAgreed}
                  label={
                    <div className="text-sm font-semibold">
                      {t(
                        "page.HostedPagesCreditPackOverview.termsAndConditionsP1",
                      )}
                      <a
                        href={creditPack.termsConditions}
                        target="_blank"
                        rel="noreferrer"
                        className="text-secondary-400 hover:text-secondary-300 underline hover:no-underline"
                      >
                        {t(
                          "page.HostedPagesCreditPackOverview.termsAndConditionsP2",
                        )}
                      </a>
                    </div>
                  }
                />
              </div>
              <Button
                {...(auth.id
                  ? {
                      link: `${document.location.pathname}/checkout`,
                    }
                  : {
                      onClick: async () => {
                        const { isLoggedIn } = await showSignUpOverlay();
                        if (isLoggedIn) {
                          history.push(
                            `${document.location.pathname}/checkout`,
                          );
                        }
                      },
                    })}
                disabled={!termsAgreed}
                intent="secondary"
                className="w-fit px-16"
              >
                {t("common.goToCheckout")}
              </Button>
            </div>
          </HostedPagesOverviewCard.Container>
        )}
      </div>
    </div>
  );
};
