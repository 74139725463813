import { ApiType } from "@gymflow/common/lib/api/ApiType";
import { RuleDTO } from "@gymflow/types";
import { useQuery } from "@tanstack/react-query";

import { defaultPage } from "../defaultPage";
import { ruleQueryKeys } from "./ruleQueryKeys";

export function useQueryRuleList({
  api,
  filter,
}: {
  api: ApiType;
  filter?: Parameters<ApiType["ruleApi"]["find"]>[0];
}) {
  return useQuery({
    queryKey: ruleQueryKeys.list(filter ?? {}),
    queryFn: async () => {
      return (await api.ruleApi.find({ ...filter })).data;
    },
    initialData: defaultPage<RuleDTO>,
  });
}
