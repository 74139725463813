import { VariantProps } from "class-variance-authority";
import { AnimatePresence } from "motion/react";
import * as motion from "motion/react-client";
import { MouseEvent, ReactNode, useState } from "react";

import { Button, buttonVariants } from "./Button";

export interface ReactiveButtonProps
  extends VariantProps<typeof buttonVariants> {
  className?: string;
  children: ReactNode;
  onClick?: (e: MouseEvent<HTMLButtonElement, MouseEvent>) => void;
  reactiveContent: ReactNode;
  disabled?: boolean;
  tooltip?: string;
}

export function ReactiveButton({
  children,
  className,
  intent,
  size,
  reactiveContent,
  onClick,
  disabled,
  tooltip,
}: ReactiveButtonProps) {
  const [showReactiveContent, setShowReactiveContent] = useState(false);
  return (
    <Button
      className={className}
      intent={intent}
      size={size}
      onClick={(e) => {
        if (showReactiveContent) {
          return;
        }
        setShowReactiveContent(true);
        setTimeout(() => {
          setShowReactiveContent(false);
        }, 2000);
        if (!onClick) {
          return;
        }
        onClick(e);
      }}
      disabled={disabled}
      tooltip={tooltip}
    >
      <div className="relative flex h-full w-full">
        <AnimatePresence>
          {!showReactiveContent && (
            <motion.div
              className="absolute inset-0 flex items-center justify-center"
              initial={{ opacity: 1, scale: 1 }}
              animate={{ opacity: 1, scale: 1 }}
              exit={{ opacity: 0, scale: 0 }}
            >
              {children}
            </motion.div>
          )}
        </AnimatePresence>
        <AnimatePresence>
          {showReactiveContent && (
            <motion.div
              className="absolute inset-0 flex items-center justify-center"
              initial={{ opacity: 0, scale: 0 }}
              animate={{ opacity: 1, scale: 1 }}
              exit={{ opacity: 0, scale: 0 }}
            >
              {reactiveContent}
            </motion.div>
          )}
        </AnimatePresence>
      </div>
    </Button>
  );
}
