import {
  memberCalculateScheduleDatesForChangingSubscriptionQueryFn,
  memberQueryKeys,
} from "@gymflow/api";
import {
  CalculateScheduleDates,
  UserMemberSubscriptionBeanWithMembership,
} from "@gymflow/types";
import { InfiniteData, UseInfiniteQueryOptions } from "@tanstack/react-query";
import {
  FormSelectInput,
  SelectInputOptionType,
} from "apps/portal/src/components/atoms";
import { useClubSettings } from "apps/portal/src/providers";
import useGymflowModels from "apps/portal/src/store";
import moment from "moment";
import { useCallback } from "react";
import { useTranslation } from "react-i18next";

import { ChangeMembershipFormValues } from "../types";

export type FormScheduledDateSelectProps = {
  activeMembership: UserMemberSubscriptionBeanWithMembership;
};

const SCHEDULED_DATE_FORMAT = "Do MMMM YYYY";

export const FormScheduledDateSelect = ({
  activeMembership,
}: FormScheduledDateSelectProps) => {
  const { t } = useTranslation();
  const { api } = useGymflowModels();
  const { timezone } = useClubSettings();

  const dataFetchingQuery = useCallback(
    (): UseInfiniteQueryOptions<
      unknown,
      unknown,
      SelectInputOptionType<string>[]
    > => ({
      queryKey: memberQueryKeys.calculateScheduleDates(activeMembership.id),
      queryFn: async () =>
        await memberCalculateScheduleDatesForChangingSubscriptionQueryFn({
          api,
          subscriptionId: activeMembership.id,
        }),
      select: (data) => {
        const { pageParams, pages } =
          data as InfiniteData<CalculateScheduleDates>;
        const transformedPages = pages.map(({ changeDates }) =>
          changeDates.map((item, index) => ({
            id: `${item}-${index}`,
            label: moment(item).tz(timezone).format(SCHEDULED_DATE_FORMAT),
            value: item,
          })),
        );

        return {
          pageParams,
          pages: transformedPages,
        };
      },
      getNextPageParam: () => undefined,
    }),
    [activeMembership.id, api, timezone],
  );

  return (
    <FormSelectInput<ChangeMembershipFormValues, string>
      name="date"
      label={t(
        "page.userMember.tab.userMemberAccount.userMemberMembership.membershipCard.modal.changeMembershipModal.formScheduledDateSelect.label",
      )}
      isMulti={false}
      dataFetchingQuery={dataFetchingQuery}
    />
  );
};
