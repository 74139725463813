/* eslint-disable react/prop-types */
import { useMemberSubscription } from "@gymflow/api";
import { DATE_FORMAT, DurationType, useParseErrors } from "@gymflow/common";
import {
  DATE_ONLY_FORMAT,
  iso8601WithoutTz,
  zonedTime,
} from "@gymflow/helpers";
import { UserMemberSubscriptionBeanWithMembership } from "@gymflow/types";
import { generateDates } from "apps/portal/src/helpers/date";
import { ModalContext, useClubSettings } from "apps/portal/src/providers";
import useGymflowModels from "apps/portal/src/store";
import { format, parse } from "date-fns";
import { cn } from "libs/common/src/lib/helpers/cn";
import { DateTime } from "luxon";
import moment, { Moment } from "moment";
import React, { useCallback, useContext, useMemo, useState } from "react";
import Datetime from "react-datetime";

import { ConfirmModal } from "../../../templates";
import { NotPrimaryMembershipWarning } from "../NotPrimaryMembershipWarning";

export interface PauseMembershipModalProps {
  memberId: string;
  membership: UserMemberSubscriptionBeanWithMembership;
}

export const PauseMembershipModal: React.FC<PauseMembershipModalProps> = ({
  memberId,
  membership,
}) => {
  const todayInDateFormat = moment().format(DATE_FORMAT);
  const { timezone } = useClubSettings();
  const { api } = useGymflowModels();

  const parseError = useParseErrors();
  const { pauseSubscriptionMutation } = useMemberSubscription(
    { api, tz: timezone },
    {
      onError: (error) => {
        parseError((error as any)?.response);
      },
    },
  );
  const { hide } = useContext(ModalContext);
  const [pauseDate, setPauseDate] = useState<string | null>(null);

  const cancellingDates = useMemo(
    () =>
      generateDates({
        intervalType: (DurationType as any).format(
          membership.billingType,
          true,
        ),
        intervalValue: membership.billingPeriod ?? 0,
        start: parse(membership.endDate, iso8601WithoutTz, new Date()),
        dateFormat: DATE_ONLY_FORMAT,
      }).map((e) => DateTime.fromISO(e).plus({ days: 1 })),
    [membership.billingPeriod, membership.billingType, membership.endDate],
  );
  const isValidPauseDate = useCallback(
    (date: Moment) => date.isAfter(moment().add(-1, "day")),
    [],
  );

  const calculateResumeDates = (pauseDate?: string | null) =>
    generateDates({
      intervalType: (DurationType as any).format(membership.billingType, true),
      intervalValue: membership.billingPeriod ?? 0,
      start: pauseDate
        ? moment(pauseDate, DATE_FORMAT).toDate()
        : parse(zonedTime(timezone), iso8601WithoutTz, new Date()),
      dateFormat: DATE_ONLY_FORMAT,
    }).slice(1);
  const resumeDates = calculateResumeDates(pauseDate);
  const [resumeDate, setResumeDate] = useState<string>(
    moment(parse(resumeDates[0], DATE_ONLY_FORMAT, new Date())).format(
      DATE_FORMAT,
    ),
  );
  const isValidResumeDate = useCallback(
    (date: Moment) =>
      resumeDates.includes(
        format(new Date(date.toISOString()), DATE_ONLY_FORMAT),
      ),
    [resumeDates],
  );
  return (
    <ConfirmModal
      onConfirm={async () => {
        await pauseSubscriptionMutation.mutateAsync({
          subscriptionId: membership.id,
          memberId: memberId,
          resumeDate: format(
            parse(resumeDate, "dd/MM/yyyy", new Date()),
            iso8601WithoutTz,
          ),
          ...(pauseDate
            ? {
                immediately: false,
                pauseDate: format(
                  parse(pauseDate, "dd/MM/yyyy", new Date()),
                  iso8601WithoutTz,
                ),
              }
            : {
                immediately: true,
              }),
        });
        hide();
      }}
      title="Pause Membership"
      onCancel={() => {
        hide();
      }}
    >
      <div className="flex flex-col gap-4">
        <div>
          Memberships will not be billed while paused. Members can resume at
          anytime in the members app.
        </div>
        <div className="flex flex-col gap-y-1">
          <div className="text-sm font-semibold">Pause Date</div>
          <div
            className={cn(
              "relative flex h-11 w-full cursor-pointer flex-row items-center rounded-lg border border-gray-300 bg-gray-0 text-center text-gray-600 hover:bg-gray-100",
            )}
          >
            <Datetime
              dateFormat={DATE_FORMAT}
              timeFormat={false}
              onChange={(v: Moment | string) => {
                if (typeof v !== "string") {
                  const newPauseDate = v.format(DATE_FORMAT);
                  if (newPauseDate === todayInDateFormat) {
                    setPauseDate(null);
                  } else {
                    setPauseDate(newPauseDate);
                  }
                  setResumeDate(
                    moment(calculateResumeDates(newPauseDate)[0]).format(
                      DATE_FORMAT,
                    ),
                  );
                }
              }}
              value={pauseDate ?? undefined}
              closeOnSelect
              isValidDate={isValidPauseDate}
              renderDay={(props, currentDate: Moment, selectedDate: Moment) => {
                return (
                  <td
                    {...props}
                    className={cn(props?.className, "relative", {
                      "opacity-20": !isValidPauseDate(currentDate),
                      "after:bg-error-500 after:absolute after:right-1 after:top-1 after:h-2 after:w-2 after:rounded-full after:content-['']":
                        cancellingDates.some((d) =>
                          d.hasSame(
                            DateTime.fromISO(currentDate.toISOString()),
                            "day",
                          ),
                        ),
                      "!bg-secondary-500 hover:!bg-secondary-600 text-gray-0":
                        currentDate?.format(DATE_FORMAT) ===
                        selectedDate?.format(DATE_FORMAT),
                    })}
                  >
                    {currentDate.date()}
                  </td>
                );
              }}
              className={cn("h-full w-full px-4")}
              inputProps={{
                readOnly: true,
                placeholder: "Today",
                value: pauseDate ?? "Today",
                className: cn(
                  "h-full w-full bg-gray-0 text-gray-600 font-medium border-0 focus:outline-none cursor-pointer text-gray-600 placeholder:text-gray-600",
                ),
              }}
            />
          </div>
        </div>
        <div>Next billing dates are highlighted in red.</div>

        <div className="flex flex-col gap-y-1">
          <div className="text-sm font-semibold">Automatic Resume Date</div>
          <div
            className={cn(
              "relative flex h-11 w-full cursor-pointer flex-row items-center rounded-lg border border-gray-300 bg-gray-0 text-center text-gray-600 hover:bg-gray-100",
            )}
          >
            <Datetime
              dateFormat={DATE_FORMAT}
              timeFormat={false}
              onChange={(v: Moment | string) => {
                if (typeof v !== "string") {
                  setResumeDate(v.format(DATE_FORMAT));
                }
              }}
              value={resumeDate ?? undefined}
              closeOnSelect
              isValidDate={isValidResumeDate}
              renderDay={(props, currentDate: Moment, selectedDate: Moment) => {
                return (
                  <td
                    {...props}
                    className={cn(props?.className, "relative", {
                      "opacity-20": !isValidResumeDate(currentDate),
                      "after:bg-error-500 after:absolute after:right-1 after:top-1 after:h-2 after:w-2 after:rounded-full after:content-['']":
                        cancellingDates.some((d) =>
                          d.hasSame(
                            DateTime.fromISO(currentDate.toISOString()),
                            "day",
                          ),
                        ),
                      "!bg-secondary-500 hover:!bg-secondary-600 text-gray-0":
                        currentDate?.format(DATE_FORMAT) ===
                        selectedDate?.format(DATE_FORMAT),
                    })}
                  >
                    {currentDate.date()}
                  </td>
                );
              }}
              className={cn("h-full w-full px-4")}
              inputProps={{
                readOnly: true,
                placeholder: "Today",
                value: resumeDate ?? "Today",
                className: cn(
                  "h-full w-full bg-gray-0 font-medium border-0 focus:outline-none cursor-pointer text-gray-600 placeholder:text-gray-600",
                ),
              }}
            />
          </div>
        </div>
        <NotPrimaryMembershipWarning membership={membership} />
      </div>
    </ConfirmModal>
  );
};
