import {
  clubStaleTime,
  useAccessReport,
  useAccessReportCsv,
  useCheckOutMutation,
  useClub,
} from "@gymflow/api";
import { DATE_FORMAT, PARAMETER_DATE_FORMAT_WITHOUT_TZ } from "@gymflow/common";
import { downloadCsv } from "@gymflow/helpers";
import {
  AccessReportItemDTO,
  UserMemberSubscriptionStatus,
} from "@gymflow/types";
import { createColumnHelper, SortingState } from "@tanstack/react-table";
import { capitalize } from "lodash";
import moment from "moment-timezone";
import { useCallback, useEffect, useMemo, useState } from "react";

import { Badge, Button, UserCheckInIcon } from "../components/atoms";
import { ReportPagination } from "../components/molecules";
import { UserMemberAvatar } from "../components/molecules/UserMemberAvatar";
import { Report, Table } from "../components/organisms";
import { getFilterAppliedCount } from "../helpers";
import useQueryParam from "../hooks/useQueryParam";
import { useClubSettings } from "../providers";
import useGymflowModels from "../store";
import { AccessFilters, AccessFilterSidebar } from "./AccessFilterSidebar";

const accessFiltersStorageKey = "access-menu-filters";

export const Access: React.FC = () => {
  const { api } = useGymflowModels();

  const settings = useClubSettings();
  const clubId = settings.clubId;
  const { data: club } = useClub({ api, clubId }, { staleTime: clubStaleTime });

  const [currentPage, setCurrentPage] = useState<number>(0);
  const [pageSize, setPageSize] = useState(100);
  const [currentSort, setCurrentSort] = useState<SortingState>([]);

  const [dates, setDates] = useQueryParam("dates", "string") as any;
  const defaultDates = useMemo(
    () => ({
      startDate: moment()
        .startOf("day")
        .format(PARAMETER_DATE_FORMAT_WITHOUT_TZ),
      endDate: moment().endOf("day").format(PARAMETER_DATE_FORMAT_WITHOUT_TZ),
    }),
    [],
  );
  useEffect(() => {
    if (!dates) {
      setDates(defaultDates);
    }
  }, []);

  const { mutateAsync: checkOut } = useCheckOutMutation({ api });
  const columnHelper = createColumnHelper<AccessReportItemDTO>();
  const columnsDefinition = [
    columnHelper.accessor(
      (e) => ({
        firstName: e.firstName,
        lastName: e.lastName,
        email: e.email,
        initials: `${e.firstName[0]}${e.lastName[0]}`,
        id: e.userMemberId,
        picture: e.picture,
      }),
      {
        id: "firstName",
        cell: (info) => (
          <div className="text-gray-600">
            <UserMemberAvatar {...info.cell.getValue()} />
          </div>
        ),
        header: "User",
        enableSorting: true,
      },
    ),
    columnHelper.accessor(
      (e) => ({ name: e.membershipName, status: e.membershipStatus }),
      {
        cell: (info) => {
          return (
            <div className="flex flex-col items-start text-gray-600">
              {info.cell.getValue()?.name ?? ""}
              {info.cell.getValue().status && (
                <Badge
                  className={
                    (
                      {
                        ACTIVE:
                          "bg-success-600/20 text-success-600 ring-success-500/10",
                        CANCELLED:
                          "bg-error-600/20 text-error-600 ring-error-500/10",
                        EXPIRED:
                          "bg-error-600/20 text-error-600 ring-error-500/10",
                        PENDING:
                          "bg-warning-600/20 text-warning-600 ring-warning-500/10",
                        DELETED:
                          "bg-error-600/20 text-error-600 ring-error-500/10",
                        OVERDUE:
                          "bg-error-600/20 text-error-600 ring-error-500/10",
                        PAUSED:
                          "bg-warning-600/20 text-warning-600 ring-warning-500/10",
                      } as Record<UserMemberSubscriptionStatus, string>
                    )[info.cell.getValue()?.status ?? "ACTIVE"]
                  }
                >
                  {capitalize(info.cell.getValue()?.status ?? "")}
                </Badge>
              )}
            </div>
          );
        },
        header: "Membership",
        id: "membership",
        enableSorting: true,
      },
    ),
    columnHelper.accessor("guest", {
      cell: (info) => {
        if (info.getValue()) {
          return (
            <Badge className="bg-accent/20 text-secondary-500 ring-accent/10">
              Guest
            </Badge>
          );
        }
        return <div />;
      },
      header: "Guest",
      enableSorting: true,
    }),

    columnHelper.accessor("onSite", {
      cell: (info) => {
        if (info.getValue()) {
          return <Badge>Yes</Badge>;
        }
        return <div />;
      },
      header: "On Site",
      enableSorting: true,
    }),
    columnHelper.accessor("checkInDate", {
      id: "checkInDay",
      cell: (info) => {
        return (
          <div className="text-gray-600">
            {moment(info.getValue(), PARAMETER_DATE_FORMAT_WITHOUT_TZ).format(
              `${settings.date_format}`,
            )}
          </div>
        );
      },
      header: "Check In Date",
      enableSorting: false,
    }),

    columnHelper.accessor("checkInDate", {
      id: "checkInDate",
      cell: (info) => {
        return (
          <div className="text-gray-600">
            {info.getValue()
              ? moment(info.getValue(), PARAMETER_DATE_FORMAT_WITHOUT_TZ)
                  .format(`h:mm a`)
                  .toUpperCase()
              : ""}
          </div>
        );
      },
      header: "Check In Time",
      enableSorting: true,
    }),
    columnHelper.accessor("checkOutDate", {
      cell: (info) => {
        return (
          <div className="text-gray-600">
            {info.getValue()
              ? moment(info.getValue(), PARAMETER_DATE_FORMAT_WITHOUT_TZ)
                  .format(`h:mm a`)
                  .toUpperCase()
              : ""}
          </div>
        );
      },
      header: "Check Out Time",
      enableSorting: true,
    }),
    columnHelper.accessor((e) => e, {
      cell: (info) => {
        // if the user is already checked out, hide the button
        if (!!info.getValue().checkOutDate) return;
        return (
          <Button
            onClick={() => {
              checkOut({ userMemberId: info.getValue().userMemberId });
            }}
          >
            <UserCheckInIcon className="h-6" />
          </Button>
        );
      },
      header: "Actions",
      id: "actions",
      enableSorting: false,
    }),
  ];

  const handleDateRangeChange = useCallback(
    (newDate: any) => {
      if (newDate) {
        setDates({
          startDate: moment(newDate.startDate, DATE_FORMAT).format(
            PARAMETER_DATE_FORMAT_WITHOUT_TZ,
          ),
          endDate: moment(newDate.endDate, DATE_FORMAT)
            .endOf("day")
            .format(PARAMETER_DATE_FORMAT_WITHOUT_TZ),
        });
      }
    },
    [setDates],
  );

  const [filtersAreVisible, setFiltersAreVisible] = useState(false);
  const [filters, _setFilters] = useState<AccessFilters>(
    JSON.parse(localStorage.getItem(accessFiltersStorageKey) ?? "{}"),
  );
  const setFilters = (newFilters: AccessFilters) => {
    _setFilters(newFilters);
    localStorage.setItem(accessFiltersStorageKey, JSON.stringify(newFilters));
  };
  const { data, isFetching } = useAccessReport({
    api,
    filter: {
      dateFrom: dates?.startDate,
      dateTo: dates?.endDate,
      guest: filters.guest,
      onSite: filters.onSite,
      membershipIncluded: filters.memberships?.map((e) => e?.id.toString()),
    },
    tz: club?.timezone!,
    paginationOptions: {
      size: pageSize,
      page: currentPage,
      sort: currentSort[0]
        ? { field: currentSort[0].id, desc: currentSort[0].desc }
        : undefined,
    },
  });
  const downloadCsvMutation = useAccessReportCsv({ api, tz: club?.timezone! });

  return (
    <>
      <AccessFilterSidebar
        isVisible={filtersAreVisible}
        onClose={() => setFiltersAreVisible(false)}
        value={filters}
        onChange={setFilters}
      />
      <div className="flex h-full max-h-full w-full max-w-full overflow-hidden p-8">
        <div className="bg-gray-0 flex h-full max-h-full w-full flex-col overflow-hidden rounded-xl border border-gray-200 shadow">
          <Report
            title={"Access Logs"}
            description={
              "List of all users that have accessed the club for the chosen date."
            }
            onDownloadClick={async () => {
              const data = await downloadCsvMutation.mutateAsync({
                dateFrom: dates?.startDate,
                dateTo: dates?.endDate,
                guest: filters.guest,
                onSite: filters.onSite,
                membershipIncluded: filters.memberships?.map((e) =>
                  e.id.toString(),
                ),
              });
              downloadCsv(data, `access.csv`);
            }}
            handleDateRangeChange={handleDateRangeChange}
            showFilters={() => {
              setFiltersAreVisible(true);
            }}
            filtersAppliedCount={getFilterAppliedCount(filters)}
            dateRange={
              dates?.startDate && dates?.endDate
                ? {
                    startDate: moment(
                      dates.startDate,
                      PARAMETER_DATE_FORMAT_WITHOUT_TZ,
                    ).format(DATE_FORMAT),
                    endDate: moment(
                      dates.endDate,
                      PARAMETER_DATE_FORMAT_WITHOUT_TZ,
                    )
                      .endOf("day")
                      .format(DATE_FORMAT),
                  }
                : undefined
            }
            table={
              <>
                <Table
                  data={data?.content ?? []}
                  columns={columnsDefinition}
                  pageCount={data?.totalPages}
                  pageIndex={data?.number}
                  onSortingChange={setCurrentSort}
                  sort={currentSort}
                  isFetching={isFetching}
                  pageSize={pageSize}
                  rowClassName="h-14"
                />
                <div className="border-t border-gray-300">
                  <ReportPagination
                    pageCount={data?.totalPages as number}
                    currentPage={data?.number as number}
                    onPageChange={(newPage) => {
                      setCurrentPage(newPage);
                    }}
                    pageSize={pageSize}
                    setPageSize={(newValue) => {
                      setPageSize(newValue);
                      setCurrentPage(0);
                    }}
                  />
                </div>
              </>
            }
          />
        </div>
      </div>
    </>
  );
};
