import { createContext } from "react";

interface EditMemberSidebarProviderContextType {
  openEditMemberSidebar: () => void;
}

const defaultContext: EditMemberSidebarProviderContextType = {
  openEditMemberSidebar: () => {
    throw new Error(
      "openEditMemberSidebar function must be used within EditMemberSidebarProvider",
    );
  },
};

export const EditMemberSidebarProviderContext =
  createContext<EditMemberSidebarProviderContextType>(defaultContext);
