import { cn } from "@gymflow/helpers";
import range from "lodash/range";
import moment from "moment-timezone";

import { PaginatedSelect, PaginatedSelectOption } from "../../atoms";
import { MenuPlacement } from "react-select";

type TimeLimitSelectProps = {
  value: string;
  minTime?: string;
  onChange: (newTime: string) => void;
  isDisabled?: boolean;
  className?: string;
  menuPortalTargetDocumentBody?: boolean;
  maxMenuHeight?: number;
  menuPlacement?: MenuPlacement;
};

export function TimeLimitSelect({
  value,
  minTime = "00:00",
  onChange,
  isDisabled = false,
  className,
  menuPortalTargetDocumentBody,
  maxMenuHeight,
  menuPlacement,
}: TimeLimitSelectProps) {
  const hours = range(0, 24);
  const minutes = range(0, 60, 15);
  return (
    <PaginatedSelect
      className={cn("min-w-[8.25rem]", className)}
      menuPortalTargetDocumentBody={menuPortalTargetDocumentBody}
      maxMenuHeight={maxMenuHeight}
      menuPlacement={menuPlacement}
      loadOptions={() => {
        const values: PaginatedSelectOption[] = [];
        hours.forEach((h) => {
          minutes.forEach((m) => {
            const hourString = h.toString().padStart(2, "0");
            const minuteString = m.toString().padStart(2, "0");
            const option = `${hourString}:${minuteString}`;
            if (moment(option, "HH:mm").isBefore(moment(minTime, "HH:mm"))) {
              return;
            }
            values.push({
              label: moment(option, "HH:mm").format("h:mm A"),
              value: option,
            });
          });
        });
        return Promise.resolve({ options: values });
      }}
      value={{ label: moment(value, "HH:mm").format("h:mm A"), value }}
      onChange={(newValue) => {
        onChange(newValue.value);
      }}
      isDisabled={isDisabled}
    />
  );
}
