import { ApiType } from "@gymflow/common/lib/api/ApiType";
import { useQuery } from "@tanstack/react-query";

import { appointableAsCustomerQueryKeys } from "./appointableAsCustomerQueryKeys";

export function useQueryAppointableListAsCustomer(
  {
    api,
    opts,
  }: {
    api: ApiType;
    opts: Parameters<ApiType["customerAppointableApi"]["getList"]>[0];
  },
  {
    enabled,
  }: {
    enabled?: boolean;
  } = { enabled: true },
) {
  return useQuery({
    queryKey: appointableAsCustomerQueryKeys.list(opts),
    queryFn: async () => (await api.customerAppointableApi.getList(opts)).data,
    enabled,
  });
}
