import { cn } from "@gymflow/helpers";
import { parsePhoneNumber, phoneUtil } from "@gymflow/validation";
import { useClubSettings } from "apps/portal/src/providers";
import { FocusEventHandler, forwardRef, ReactNode, useState } from "react";

import { Input } from "./Input/Input";
import Label from "./Label";
import { SelectInput } from "./SelectInput";

const phoneNumberOptions = phoneUtil.getSupportedRegions().map((region) => ({
  value: `${region}(+${phoneUtil.getCountryCodeForRegion(region)})`,
  label: `${region}(+${phoneUtil.getCountryCodeForRegion(region)})`,
  id: `${region}(+${phoneUtil.getCountryCodeForRegion(region)})`,
}));

export type PhoneNumberInputProps = {
  value: string;
  onChange: (e?: string) => void;
  placeholder?: string;
  className?: string;
  label?: string | ReactNode;
  onFocus?: FocusEventHandler;
  onBlur?: FocusEventHandler;
  disabled?: boolean;
  isRequired?: boolean;
};

export const PhoneNumberInput = forwardRef<
  HTMLInputElement,
  PhoneNumberInputProps
>(({ className, label, value, isRequired = false, disabled, ...rest }, ref) => {
  const settings = useClubSettings();
  const phoneNumber = parsePhoneNumber(value ?? "");
  const phoneNumberCountries = JSON.parse(settings?.phone_number_country);

  const [prefix, setPrefix] = useState<string | undefined>(
    `+${
      phoneNumber?.getCountryCode() ??
      phoneUtil.getCountryCodeForRegion(phoneNumberCountries[0])
    }`,
  );
  const currentNumberISO2Country = phoneNumber
    ? phoneUtil.getRegionCodeForNumber(phoneNumber)
    : undefined;
  const [labelCountry, setLabelCountry] = useState<string>(
    `${currentNumberISO2Country ?? phoneNumberCountries[0]}(${prefix})`,
  );
  const [number, setNumber] = useState(
    `${phoneNumber?.getNationalNumber() ?? ""}`,
  );
  return (
    <div className="relative flex flex-col gap-1.5">
      <Label label={label} isRequired={isRequired} />
      <div
        className={cn("flex flex-row gap-2", {
          "cursor-not-allowed": disabled,
        })}
      >
        <SelectInput
          className="w-36"
          onChange={(value) => {
            if (!value) return;
            setLabelCountry(value?.value);
            const newPrefix = value?.value.match(/\((.*)\)/)?.[1];
            setPrefix(newPrefix);
            if (number) {
              rest.onChange((newPrefix + number.trim()).trim());
            } else {
              rest.onChange(undefined);
            }
          }}
          value={phoneNumberOptions.find((e) => labelCountry === e.value)}
          options={phoneNumberOptions}
          disabled={disabled}
        />
        <div className="flex w-full">
          <Input
            {...rest}
            placeholder={rest.placeholder}
            className="w-full"
            value={number}
            onChange={(e) => {
              const newText = e.currentTarget.value;
              setNumber(newText);
              if (prefix) {
                rest.onChange((prefix + newText.trim()).trim());
              } else {
                rest.onChange("");
              }
            }}
            type="tel"
            disabled={disabled}
          />
        </div>
      </div>
    </div>
  );
});

PhoneNumberInput.displayName = "PhoneNumberInput";
