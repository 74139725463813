import { faClose } from "@fortawesome/free-solid-svg-icons";
import { FontAwesomeIcon } from "@fortawesome/react-fontawesome";
import { Route, Switch, useHistory, useRouteMatch } from "react-router";

import { usePortalRoutes } from "../../hooks/usePortalRoutes";
import { RouteFeature } from "../../routes/feature";
import { RouteLayout } from "../../routes/layout";
import { KisiIntegrationUpdate } from "../organisms";
import { SettingsRoute } from "../Settings/SettingsRoute";
import { KisiIntegrationWizard } from "./KisiIntegrationWizard";

export function KisiIntegration() {
  const { push } = useHistory();
  const match = useRouteMatch();
  const { createClubLink } = usePortalRoutes();

  return (
    <div className="bg-gray-0 flex h-full max-h-full flex-col p-5">
      <div className="flex max-h-full flex-col">
        <div className="flex justify-between">
          <div className="flex">
            <div className="bg-kisi-logo h-12 w-12 rounded-sm bg-contain bg-center bg-no-repeat"></div>
            <div className="ml-4 flex items-center font-semibold">
              <div className="text-2xl">Kisi</div>
            </div>
          </div>
          <div className="mr-5 flex items-center">
            <FontAwesomeIcon
              onClick={() => {
                push(
                  createClubLink(
                    RouteLayout.Staff,
                    RouteFeature.Settings,
                    SettingsRoute.Integrations,
                  ),
                );
              }}
              className="cursor-pointer text-xl text-gray-600"
              icon={faClose}
            />
          </div>
        </div>
        <div className="py-5 text-gray-600">
          Use the Gymflow members mobile app to allow access to your facility,
          whether it be for check ins or unlocking doors at a 24 hour facility.
          Set different access permissions per membership.
        </div>
      </div>

      <Switch>
        <Route path={match.path + kisiIntegrationRoutes.Enable}>
          <KisiIntegrationWizard />
        </Route>
        <Route path={match.path + kisiIntegrationRoutes.Edit}>
          <KisiIntegrationUpdate />
        </Route>
      </Switch>
    </div>
  );
}

export const kisiIntegrationRoutes = {
  Enable: "/enable",
  Edit: "/edit",
};
