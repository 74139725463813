import { faClock, faQuestionCircle } from "@fortawesome/free-regular-svg-icons";
import { FontAwesomeIcon } from "@fortawesome/react-fontawesome";
import { useMutationRulesAsPublic } from "@gymflow/api";
import { ordinal, pluralize } from "@gymflow/helpers";
import {
  CalendarEventOccurrenceAsMember,
  EventRsvpStatus,
} from "@gymflow/types";
import { evaluateBookingWindow } from "@gymflow/validation";
import { usePortalRoutes } from "apps/portal/src/hooks";
import {
  ModalContext,
  useAuthenticatedUser,
  useClubSettings,
} from "apps/portal/src/providers";
import { LoginOverlayContext } from "apps/portal/src/providers/authentication/loginOverlay/context";
import { ToastContext } from "apps/portal/src/providers/ToastProvider/context";
import { RouteFeature } from "apps/portal/src/routes";
import useGymflowModels from "apps/portal/src/store";
import { isAxiosError } from "axios";
import { DateTime, Interval } from "luxon";
import { useContext } from "react";
import { useTranslation } from "react-i18next";
import { useHistory, useParams } from "react-router";
import { Link } from "react-router-dom";

import { Button, CalendarIcon, CreditsIcon } from "../../../atoms";
import CustomTooltip from "../../../atoms/CustomTooltip";
import { EmptyDotBadge } from "../../../atoms/EmptyDotBadge";
import { LocationMarkerIcon } from "../../../atoms/icons/LocationMarkerIcon";
import { StaffAvatar } from "../../../molecules/StaffAvatar";
import { ConfirmModal } from "../../../templates";
import { HostedPagesCard } from "../../components/HostedPagesCard";

export type HostedPagesClassCardProps = {
  calendarEventOccurrence: CalendarEventOccurrenceAsMember;
};

const HostedPagesClassCard = ({
  calendarEventOccurrence,
}: HostedPagesClassCardProps) => {
  const { viewType } = useParams<{ viewType: "calendar" | "list" }>();
  const settings = useClubSettings();
  const { api } = useGymflowModels();
  const getRules = useMutationRulesAsPublic({ api });
  const { setModal, hide } = useContext(ModalContext);
  const { notifyDanger } = useContext(ToastContext);
  const history = useHistory();
  const { createSiteOrEmbedLink } = usePortalRoutes();
  const { t } = useTranslation();
  const { showSignUpOverlay } = useContext(LoginOverlayContext);
  const auth = useAuthenticatedUser();
  const slotsAvailable =
    (calendarEventOccurrence.capacity ?? 0) -
    (calendarEventOccurrence.bookedCount ?? 0);
  const waitlistSlotsAvailable =
    (calendarEventOccurrence.waitListCapacity ?? 0) -
    (calendarEventOccurrence.waitingCount ?? 0);

  const nextPageLink = createSiteOrEmbedLink(
    RouteFeature.SiteTimetableId.replace(":viewType", viewType).replace(
      ":eventOccurrenceId",
      calendarEventOccurrence.eventOccurrenceId.toString(),
    ),
  );
  if (
    !calendarEventOccurrence.eventRsvpId &&
    slotsAvailable === 0 &&
    waitlistSlotsAvailable === 0
  ) {
    return null;
  }

  const result = (
    <HostedPagesCard.Container>
      <HostedPagesCard.Header>
        <div className="flex flex-row justify-between gap-4">
          <div className="flex min-w-fit flex-row items-center gap-1">
            <div className="dark:text-gray-0 min-w-fit text-lg font-bold text-gray-950">
              {DateTime.fromISO(calendarEventOccurrence.startDate)
                .setZone(settings.timezone)
                .toLocaleString(DateTime.TIME_SIMPLE)}
            </div>
            <FontAwesomeIcon className="h-4 w-4 text-gray-500" icon={faClock} />
            <div className="dark:text-gray-0 text-xs font-medium text-gray-950">
              {Interval.fromDateTimes(
                DateTime.fromISO(calendarEventOccurrence.startDate),
                DateTime.fromISO(calendarEventOccurrence.endDate),
              )
                .toDuration()
                .rescale()
                .toHuman({
                  unitDisplay: "narrow",
                  listStyle: "short",
                })}
            </div>
          </div>
          <div className="flex w-fit flex-1 flex-row items-center justify-end gap-2">
            <div className="dark:text-gray-0 line-clamp-1 font-semibold text-gray-950">
              {calendarEventOccurrence.activityName}
            </div>
            <CustomTooltip
              message={calendarEventOccurrence.activityDescription}
              hideIfEmpty
            >
              <FontAwesomeIcon
                icon={faQuestionCircle}
                className="h-4 w-4 text-gray-500"
              />
            </CustomTooltip>
          </div>
        </div>
        <div className="flex flex-row justify-between">
          <div className="flex flex-1 flex-row items-center gap-1">
            <div className="dark:text-gray-0 text-lg font-bold text-gray-950">
              <StaffAvatar
                userStaff={{
                  firstName: calendarEventOccurrence.hostName.split(" ")[0],
                  lastName: calendarEventOccurrence.hostName
                    .split(" ")
                    .slice(1)
                    .join(" "),
                  picture: calendarEventOccurrence.hostPicture,
                }}
              />
            </div>
          </div>
          <div className="flex flex-1 flex-row items-center justify-end gap-1">
            <LocationMarkerIcon pathClassName="stroke-gray-500 " />
            <div className="dark:text-gray-0 line-clamp-1 text-xs font-medium text-gray-950">
              {calendarEventOccurrence.facilityName}
            </div>
          </div>
        </div>
      </HostedPagesCard.Header>
      <HostedPagesCard.Separator />
      <HostedPagesCard.Footer>
        <div className="flex flex-row items-center gap-1">
          <div className="dark:text-gray-0 text-lg font-bold text-gray-950">
            {calendarEventOccurrence.sessionCost}
          </div>
          <CreditsIcon pathClassName="stroke-secondary-500" />
        </div>
        <div className="flex flex-row items-center gap-2">
          {!(["WAITLIST", "BOOKED"] as EventRsvpStatus[]).includes(
            calendarEventOccurrence.eventRsvpStatus ?? "CANCELLED",
          ) &&
            0 < slotsAvailable &&
            slotsAvailable < 4 && (
              <div className="text-warning-600 flex flex-col items-center justify-center text-xs font-semibold">
                <div>{slotsAvailable}</div>
                <div>
                  {pluralize(
                    t("pages.hostedPagesClasses.slotsAvailable"),
                    t("pages.hostedPagesClasses.slotAvailable"),
                    slotsAvailable,
                  )}
                </div>
              </div>
            )}
          {!(["WAITLIST", "BOOKED"] as EventRsvpStatus[]).includes(
            calendarEventOccurrence.eventRsvpStatus ?? "CANCELLED",
          ) &&
            slotsAvailable === 0 &&
            (calendarEventOccurrence.waitingCount ?? 0) > 0 && (
              <div className="flex flex-col items-center justify-center text-xs font-semibold text-gray-600">
                <div>{calendarEventOccurrence.waitingCount}</div>
                <div>{t("pages.hostedPagesClasses.waiting")}</div>
              </div>
            )}
          {!auth.id ? (
            <Button
              className="min-w-[8rem]"
              onClick={async () => {
                const { isLoggedIn } = await showSignUpOverlay();

                if (isLoggedIn) {
                  const activityRules = await getRules.mutateAsync({
                    activityId: calendarEventOccurrence.activityId,
                  });
                  const result = evaluateBookingWindow(
                    calendarEventOccurrence.startDate,
                    activityRules,
                  );
                  if (result.canBook) {
                    history.push(nextPageLink);
                  } else {
                    setModal(
                      <ConfirmModal
                        type="danger"
                        title={t("pages.hostedPagesClasses.bookingTimeExpired")}
                        onCancel={hide}
                        cancelText={t("common.gotIt")}
                        icon={<CalendarIcon className="stroke-error-600" />}
                      >
                        {t(
                          `errorCodes.rule.class.${result.errorCode}`,
                          result.interpolation,
                        )}
                      </ConfirmModal>,
                    );
                  }
                }
              }}
              intent="secondary"
            >
              {slotsAvailable === 0
                ? t("pages.hostedPagesClasses.joinWaitlist")
                : t("common.book")}
            </Button>
          ) : calendarEventOccurrence.eventRsvpStatus === "BOOKED" ? (
            <EmptyDotBadge intent="lightblue">
              {t("pages.hostedPagesClasses.booked")}
            </EmptyDotBadge>
          ) : calendarEventOccurrence.eventRsvpStatus === "WAITING" ? (
            <EmptyDotBadge intent="warning">
              {t("pages.hostedPagesClasses.positionOnWaitlist", {
                position: ordinal(
                  calendarEventOccurrence?.waitlistQueuePosition ?? 0,
                ),
              })}
            </EmptyDotBadge>
          ) : (
            <Button
              className="min-w-[8rem]"
              onClick={async () => {
                try {
                  const activityRules = await getRules.mutateAsync({
                    activityId: calendarEventOccurrence.activityId,
                  });
                  const result = evaluateBookingWindow(
                    calendarEventOccurrence.startDate,
                    activityRules,
                  );

                  if (result.canBook) {
                    history.push(nextPageLink);
                  } else {
                    setModal(
                      <ConfirmModal
                        type="danger"
                        title={t("pages.hostedPagesClasses.bookingTimeExpired")}
                        onCancel={hide}
                        cancelText={t("common.gotIt")}
                        icon={<CalendarIcon className="stroke-error-600" />}
                      >
                        {t(
                          `errorCodes.rule.class.${result.errorCode}`,
                          result.interpolation,
                        )}
                      </ConfirmModal>,
                    );
                  }
                } catch (e) {
                  if (isAxiosError(e)) {
                    notifyDanger(e);
                  }
                }
              }}
              intent="secondary"
            >
              {slotsAvailable === 0
                ? t("pages.hostedPagesClasses.joinWaitlist")
                : t("common.book")}
            </Button>
          )}
        </div>
      </HostedPagesCard.Footer>
    </HostedPagesCard.Container>
  );
  if (
    calendarEventOccurrence.eventRsvpId &&
    !(["LATE_CANCELLED", "CANCELLED"] as EventRsvpStatus[]).includes(
      calendarEventOccurrence.eventRsvpStatus ?? "BOOKED",
    )
  ) {
    return (
      <Link to={nextPageLink} className="hover:opacity-70">
        {result}
      </Link>
    );
  }
  return result;
};
export default HostedPagesClassCard;
