import {
  useQueryCreditPackListAsMember,
  useQueryCreditPackListAsPublic,
} from "@gymflow/api";
import useGymflowModels from "apps/portal/src/store";
import { useMemo } from "react";

import { useAuthenticatedUser } from "../../../../providers";
import { CreditPackType } from "./HostedPagesCreditPackFilterPopover";

export const useCreditPackAvailableTabs = () => {
  const auth = useAuthenticatedUser();
  const { api } = useGymflowModels();
  const classPackQueryOptions: Parameters<
    typeof useQueryCreditPackListAsPublic
  >[0] = {
    api,
    opts: {
      hasActivityCategory: true,
      status: "ACTIVE",
      size: 1,
      page: 0,
    },
  };
  const classPackAsPublic = useQueryCreditPackListAsPublic(
    classPackQueryOptions,
    { enabled: !auth.id },
  );
  const classPackAsMember = useQueryCreditPackListAsMember(
    classPackQueryOptions,
    { enabled: !!auth.id },
  );
  const { data: classPack } = !!auth.id ? classPackAsMember : classPackAsPublic;
  const appointablePackQueryOptions: Parameters<
    typeof useQueryCreditPackListAsPublic
  >[0] = {
    api,
    opts: {
      hasAppointableCategory: true,
      status: "ACTIVE",
      size: 1,
      page: 0,
    },
  };
  const appointablePackAsPublic = useQueryCreditPackListAsPublic(
    appointablePackQueryOptions,
    { enabled: !auth.id },
  );
  const appointablePackAsMember = useQueryCreditPackListAsMember(
    appointablePackQueryOptions,
    { enabled: !!auth.id },
  );
  const { data: appointablePack } = !!auth.id
    ? appointablePackAsMember
    : appointablePackAsPublic;
  const tabs = useMemo(() => {
    const result = [] as CreditPackType[];
    if (classPack && classPack?.content.length > 0) {
      result.push("CLASSES");
    }
    if (appointablePack && appointablePack?.content.length > 0) {
      result.push("APPOINTMENTS");
    }
    return result;
  }, [appointablePack, classPack]);
  return tabs;
};
