import { ApiType } from "@gymflow/common/lib/api/ApiType";
import { InvoiceDTO } from "@gymflow/types";
import { useQuery, UseQueryOptions } from "@tanstack/react-query";

import { memberQueryKeys } from "./memberQueryKeys";

export function useQueryInvoice({
  api,
  invoiceNumber,
  userMemberId,
}: {
  api: ApiType;
  userMemberId: string;
  invoiceNumber: string;
}, opts?: UseQueryOptions<InvoiceDTO>) {
  return useQuery({
    queryKey: memberQueryKeys.invoice(invoiceNumber),
    queryFn: async () => {
      return (
        await api.memberApi.findInvoices(userMemberId, {
          extraParams: {
            number: invoiceNumber,
          },
          limit: 1,
        })
      ).data.content[0] as InvoiceDTO;
    },
    ...opts
  });
}
