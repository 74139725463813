import { AvailabilityCalculationResult } from "@gymflow/types";
import { AxiosInstance } from "axios";

export type CustomerAvailabilityApiType = ReturnType<
  typeof customerAvailabilityApi
>;

const customerAvailabilityApi = (
  axiosInstance: AxiosInstance,
  clubId: number,
) => {
  const clubPrefix = `club/${clubId}/`;

  return {
    hostCalculateAvailability(params: {
      appointableId: number;
      dateFrom: string;
      dateTo: string;
      appointableHostsIdList?: string[];
    }) {
      return axiosInstance.post<AvailabilityCalculationResult[]>(
        `${clubPrefix}customer/availability/host/calculate`,
        params,
      );
    },
    facilityCalculateAvailability(params: {
      appointableId: number;
      dateFrom: string;
      dateTo: string;
      appointableFacilityIdList?: number[];
    }) {
      return axiosInstance.post<AvailabilityCalculationResult[]>(
        `${clubPrefix}customer/availability/facility/calculate`,
        params,
      );
    },
  };
};

export default customerAvailabilityApi;
