import { QueryKey } from "@tanstack/react-query";

export const eventOccurrenceAsMemberQueryKeys = {
  all: () => ["eventOccurrenceAsMember"] as QueryKey,
  list: (filters: Readonly<{ [k: string]: any }>) =>
    [...eventOccurrenceAsMemberQueryKeys.all(), { filters }] as QueryKey,
  findById: (id?: number) =>
    [...eventOccurrenceAsMemberQueryKeys.all(), id] as QueryKey,
  eventOccurrences: (filters?: Readonly<{ [k: string]: any }>) => {
    if (filters) {
      return [
        ...eventOccurrenceAsMemberQueryKeys.all(),
        "eventOccurrences",
        { filters },
      ] as QueryKey;
    } else {
      return [
        ...eventOccurrenceAsMemberQueryKeys.all(),
        "eventOccurrences",
      ] as QueryKey;
    }
  },
  eventOccurrencesInfiniteQuery: (filters?: Readonly<{ [k: string]: any }>) => {
    if (filters) {
      return [
        ...eventOccurrenceAsMemberQueryKeys.all(),
        "eventOccurrencesInfiniteQuery",
        { filters },
      ] as QueryKey;
    } else {
      return [
        ...eventOccurrenceAsMemberQueryKeys.all(),
        "eventOccurrencesInfiniteQuery",
      ] as QueryKey;
    }
  },
};
