import { useClubFeatureFlags } from "@gymflow/api";
import { cn } from "@gymflow/helpers";
import { LeadDTO } from "@gymflow/types";
import { useContext, useState } from "react";

import { Can, Subject, Verb } from "../../permissions";
import { useClubSettings } from "../../providers";
import useGymflowModels from "../../store";
import {
  buttonVariants,
  CheckDoneIcon,
  DotsVerticalIcon,
  MailIcon,
  Popover,
  PopoverContent,
  PopoverTrigger,
  ShopBagIcon,
  SMSIcon,
  StickerSquareIcon,
} from "../atoms";
import {
  CreateEditTaskSidebarProviderContext,
  SendEmailSidebarProviderContext,
  SendSMSToLeadSidebarContext,
} from "../organisms";
import { ShopSidebarContext } from "../Shop/ShopSidebar/ShopSidebarContext";
import { NoteFormSideBarProviderContext } from "../UserMember/Notes/NoteFormSideBarProvider";

type LeadActionsDropdownProps = {
  className?: string;
  lead: LeadDTO;
  leadName: string;
};

export function LeadActionsDropdown({
  className,
  lead,
  lead: { id: leadId },
  leadName,
}: LeadActionsDropdownProps) {
  const { api } = useGymflowModels();

  const { clubId } = useClubSettings();
  const [isPopoverOpen, setIsPopoverOpen] = useState(false);
  const { showShop } = useContext(ShopSidebarContext);

  const { open: openSendSMSSidebar } = useContext(SendSMSToLeadSidebarContext);
  const { openSendEmailSidebar } = useContext(SendEmailSidebarProviderContext);
  const { triggerNewNote } = useContext(NoteFormSideBarProviderContext);
  const { data: featureFlags } = useClubFeatureFlags({ clubId, api });

  const { openCreateTaskSidebar } = useContext(
    CreateEditTaskSidebarProviderContext,
  );

  return (
    <Popover
      open={isPopoverOpen}
      onOpenChange={(e) => {
        setIsPopoverOpen(e);
      }}
    >
      <PopoverTrigger
        className={cn(
          buttonVariants({ size: "small", intent: "default" }),
          "mt-0 h-11 w-11 bg-gray-0 text-gray-700 hover:bg-gray-50",
          className,
        )}
      >
        <div className="flex items-center justify-center">
          <DotsVerticalIcon
            className="h-6 w-6"
            pathClassName="stroke-gray-700"
          />
        </div>
      </PopoverTrigger>
      <PopoverContent
        align="start"
        side="bottom"
        asChild
        className="flex w-fit flex-col overflow-hidden rounded-lg"
        hideWhenDetached
      >
        <div>
          <Can I={Verb.View} a={Subject.Sales}>
            <div
              className="flex cursor-pointer items-center gap-2 px-4 py-2 text-sm text-gray-700 hover:bg-gray-50"
              onClick={() => {
                setIsPopoverOpen(false);
                showShop(lead);
              }}
            >
              <ShopBagIcon
                className="h-4 w-4"
                pathClassName="stroke-gray-700"
              />
              <div>Sell Something</div>
            </div>
          </Can>
          <div className="border-t border-t-gray-300" />
          <Can I={Verb.Create} a={Subject.Email}>
            <div
              className="flex cursor-pointer items-center gap-2 px-4 py-2 text-sm text-gray-700 hover:bg-gray-50"
              onClick={async () => {
                setIsPopoverOpen(false);
                openSendEmailSidebar({
                  recipient: leadName,
                  allowMarketing: lead.emailCommunication,
                  requestType: "LEAD",
                  listOfIds: [leadId],
                });
              }}
            >
              <MailIcon className="h-4 w-4" pathClassName="stroke-gray-700" />
              <div>Send Email</div>
            </div>
          </Can>
          {featureFlags?.featureFlags.PORTAL_SEND_SMS && (
            <Can I={Verb.Create} a={Subject.SMS}>
              <div
                className="flex cursor-pointer items-center gap-2 px-4 py-2 text-sm text-gray-700 hover:bg-gray-50"
                onClick={async () => {
                  setIsPopoverOpen(false);
                  openSendSMSSidebar({
                    leadId,
                    leadName,
                  });
                }}
              >
                <SMSIcon className="h-4 w-4" pathClassName="stroke-gray-700" />
                <div>Send SMS</div>
              </div>
            </Can>
          )}
          <div className="border-t border-t-gray-300" />
          <div
            className="flex cursor-pointer items-center gap-2 px-4 py-2 text-sm text-gray-700 hover:bg-gray-50"
            onClick={async () => {
              setIsPopoverOpen(false);
              triggerNewNote({
                leadId,
                name: leadName,
              });
            }}
          >
            <StickerSquareIcon
              className="h-4 w-4"
              pathClassName="stroke-gray-700"
            />
            <div>Add Note</div>
          </div>
          <Can I={Verb.View} a={Subject.Task}>
            <div
              className="flex cursor-pointer items-center gap-2 px-4 py-2 text-sm text-gray-700 hover:bg-gray-50"
              onClick={async () => {
                setIsPopoverOpen(false);
                openCreateTaskSidebar({
                  initialValues: {
                    relatedUsers: [
                      {
                        id: leadId,
                        label: leadName,
                        value: lead,
                      },
                    ],
                  },
                });
              }}
            >
              <CheckDoneIcon
                className="h-4 w-4"
                pathClassName="stroke-gray-700"
              />
              <div>Create Task</div>
            </div>
          </Can>
        </div>
      </PopoverContent>
    </Popover>
  );
}
