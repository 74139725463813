import { useMemberInvoice } from "@gymflow/api";
import { useParseErrors } from "@gymflow/common";
import { currencies, formatCurrency } from "@gymflow/helpers";
import {
  HighlightedInvoiceDTO,
  InvoiceNewDTO,
  UserMemberBean,
} from "@gymflow/types";
import { ModalContext, useClubSettings } from "apps/portal/src/providers";
import { ToastContext } from "apps/portal/src/providers/ToastProvider/context";
import useGymflowModels from "apps/portal/src/store";
import { useFormik } from "formik";
import { LabeledFormikInput } from "libs/common/src/lib/components/molecules/FormikInput";
import { useContext } from "react";
import { z } from "zod";
import { toFormikValidationSchema } from "zod-formik-adapter";

import { ConfirmModal } from "../../templates";

export interface UserMemberAdjustInvoiceModalProps {
  userMember: UserMemberBean;
  invoice: InvoiceNewDTO | HighlightedInvoiceDTO;
}

export const UserMemberAdjustInvoiceModal = ({
  invoice,
  userMember,
}: UserMemberAdjustInvoiceModalProps) => {
  const { api } = useGymflowModels();
  const toast = useContext(ToastContext);

  const parseError = useParseErrors();
  const { adjustInvoiceMutation } = useMemberInvoice({
    api,
    memberId: userMember.id,
  });

  const { hide } = useContext(ModalContext);

  const settings = useClubSettings();
  const formik = useFormik<{ targetPrice: number }>({
    initialValues: {
      targetPrice: invoice.dueAmount,
    },
    validationSchema: toFormikValidationSchema(
      z.object({
        targetPrice: z
          .number({
            coerce: true,
          })
          .max(
            invoice.totalAmount,
            `Price adjustment cannot be greater than the initial price (${formatCurrency(
              invoice.totalAmount,
              settings.defaultCurrency,
            )})`,
          ),
      }),
    ),
    onSubmit: () => {},
  });
  return (
    <ConfirmModal
      isConfirmDisabled={!formik.isValid}
      onConfirm={async () => {
        if (formik.values.targetPrice === undefined) return;
        const targetPrice = Number(formik.values.targetPrice);
        try {
          await adjustInvoiceMutation.mutateAsync({
            invoiceNumber: invoice.number,
            amount: targetPrice,
          });
          toast.toast({
            message: "Invoice Adjusted Successfully.",
          });
        } catch (e: any) {
          parseError(e.response);
        }
        hide();
      }}
      title="Adjust Invoice"
      onCancel={() => {
        hide();
      }}
    >
      <div className="flex flex-col gap-4">
        <div>Enter the final amount you would like to be billed</div>
        <LabeledFormikInput
          label={`New Amount (${currencies[settings.defaultCurrency].symbol})`}
          name="targetPrice"
          formikProps={formik as any}
          value={formik.values.targetPrice}
          onChange={(e) => {
            formik.setFieldValue("targetPrice", e.target.value);
          }}
          error={formik.errors.targetPrice}
        />
      </div>
    </ConfirmModal>
  );
};
