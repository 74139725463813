import { ApiType } from "@gymflow/common/lib/api/ApiType";
import { AppointmentBookingConsumeSessionsProps } from "@gymflow/types";
import { useMutation, UseMutationOptions } from "@tanstack/react-query";

export const useMutationAppointmentBookingConsumeSessions = (
  {
    api,
  }: {
    api: ApiType;
  },
  mutationOpts?: UseMutationOptions<
    null,
    unknown,
    AppointmentBookingConsumeSessionsProps
  >,
) =>
  useMutation({
    mutationFn: async (params: AppointmentBookingConsumeSessionsProps) =>
      (await api.appointmentApi.appointmentBookingConsumeSessions(params)).data,
    ...mutationOpts,
  });
