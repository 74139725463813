import { cn } from "@gymflow/helpers";
import { cva, VariantProps } from "class-variance-authority";
import omit from "lodash/omit";
import React, {
  forwardRef,
  MouseEvent,
  ReactNode,
  useMemo,
  useState,
} from "react";
import { Link } from "react-router-dom";

import CustomTooltip from "./CustomTooltip";
import { Spinner } from "./Spinner";

export const buttonVariants = cva(
  [
    "flex min-w-[2.375rem] cursor-pointer items-center justify-center !rounded-xl px-4 py-2 font-semibold capitalize transition-shadow duration-100 ease-in-out focus:!outline-none active:!outline-none",
    "ring-secondary-500 transition-opacity duration-200 focus:ring-2 focus:ring-offset-2 active:ring-2 active:ring-offset-1 dark:ring-offset-gray-950",
  ],
  {
    variants: {
      size: {
        small: "h-[2.375rem] !text-sm",
        medium: "h-11",
      },
      intent: {
        default: [
          "border border-gray-300",
          "bg-gray-0 border-gray-300 !text-gray-950 hover:!bg-gray-50",
          "dark:!text-gray-0 dark:border-gray-700 dark:bg-gray-950 dark:hover:!bg-gray-900",
        ],
        transparent: [
          "bg-gray-0 !outline-none dark:bg-gray-950",
          "!text-gray-500 hover:!bg-gray-50",
          "dark:!text-gray-400 dark:hover:!bg-gray-900",
        ],
        primary: [
          "hover:bg-primary-300 bg-primary-600 !text-gray-25 ring-primary-500",
        ],
        warning:
          "hover:bg-warning-500 bg-warning-600 ring-warning-500 !text-gray-0",
        danger: "hover:bg-error-500 bg-error-600 ring-error-500 !text-gray-0",
        secondary:
          "bg-secondary-500 hover:bg-secondary-600 !text-gray-0 hover:!text-gray-0",
        // bg-gray-0 here is a template workaround
        "secondary-outline":
          "!text-secondary-500 border-secondary-500 bg-gray-0 border hover:bg-gray-50 dark:bg-transparent",
        "error-outline":
          "!text-error-600 border-error-600 ring-error-600 bg-gray-0 border hover:bg-gray-50 dark:bg-transparent",
        link: "hover:!text-primary-300 !text-primary-600 ring-primary-500 overflow-hidden bg-[#0000] p-0 hover:underline dark:!text-[#8DA0FF] dark:ring-[#8DA0FF]",
        "link-warning":
          "hover:!text-warning-500 !text-warning-600 ring-warning-500 bg-[#0000] p-0 hover:underline",
      },
    },
    defaultVariants: {
      size: "medium",
      intent: "default",
    },
  },
);

const disabledButtonVariants = cva(
  [
    "cursor-not-allowed opacity-50 !ring-0 !ring-offset-0 focus:!ring-0 focus:!ring-offset-0 active:!ring-0 active:!ring-offset-0",
  ],
  {
    variants: {
      intent: {
        default: "hover:bg-gray-25",
        primary: "hover:bg-primary-600",
        warning: "hover:bg-warning-600",
        danger: "hover:bg-error-600",
        secondary: "hover:bg-secondary-500",
        "secondary-outline": "hover:bg-gray-0",
        "error-outline": "hover:bg-gray-0",
        transparent: "hover:bg-transparent",
        link: "hover:!text-primary-200 !text-primary-200 hover:bg-transparent hover:no-underline",
        "link-warning":
          "hover:!text-warning-200 !text-warning-200 hover:bg-transparent hover:no-underline",
      },
    },
    defaultVariants: {
      intent: "default",
    },
  },
);

const buttonSpinnerVariants = cva("", {
  variants: {
    intent: {
      default: "dark:!fill-gray-0 !fill-gray-500",
      transparent: "!fill-gray-500 dark:!fill-gray-400",
      primary: "!fill-gray-25",
      warning: "!fill-gray-0",
      danger: "!fill-gray-0",
      secondary: "!fill-gray-0 hover:!fill-gray-0",
      // bg-gray-0 here is a template workaround
      "secondary-outline": "!fill-secondary-500",
      "error-outline": "!fill-error-600",
      link: "hover:!fill-primary-300 !fill-primary-600 dark:!fill-[#8DA0FF]",
      "link-warning": "hover:!fill-warning-500 !fill-warning-600 ",
    },
  },
  defaultVariants: {
    intent: "default",
  },
});

interface ButtonProps
  extends Omit<React.HTMLProps<HTMLButtonElement>, "type" | "size" | "onClick">,
    VariantProps<typeof buttonVariants> {
  showSpinner?: boolean;
  badgeContent?: ReactNode;
  type?: "button" | "submit";
  onClick?: (
    e: MouseEvent<HTMLButtonElement, MouseEvent>,
  ) => Promise<void> | void;
  link?: string;
  tooltip?: string;
}

export const Button = forwardRef<React.ElementRef<"button">, ButtonProps>(
  function Button(
    {
      children,
      className,
      showSpinner,
      size,
      intent,
      type = "button",
      tooltip,
      badgeContent,
      ...props
    }: ButtonProps,
    ref,
  ) {
    const [buttonId] = useState(Math.random().toString(36).slice(2));
    const [isLoading, setIsLoading] = useState(false);
    const buttonProps = useMemo(
      () => omit(props, ["disabled", "onClick"]),
      [props],
    );
    const Component = props.link ? Link : ("button" as React.ElementType);
    const result = [
      <Component
        ref={ref}
        type={type}
        before={badgeContent}
        key={`button-${buttonId}`}
        {...(props.link ? { to: props.link } : {})}
        className={cn(
          buttonVariants({ size, intent }),
          {
            [disabledButtonVariants({ intent })]: props.disabled || isLoading,
          },
          {
            "after:content-[attr(before)] after:font-medium after:text-secondary-700 after:bg-secondary-25 after:border-secondary-200 relative after:absolute after:right-0 after:top-0 after:flex after:aspect-square after:h-6 after:-translate-y-1/2 after:translate-x-1/2 after:items-center after:justify-center after:rounded-full after:border after:p-1 after:text-xs":
              !!badgeContent,
          },
          className,
        )}
        onClick={async (e: MouseEvent<HTMLButtonElement, MouseEvent>) => {
          const result = props.onClick?.(e);
          if (result instanceof Promise) {
            setIsLoading(true);
            try {
              await result;
            } catch (e) {
              setIsLoading(false);
              throw e;
            }
            setIsLoading(false);
          }
        }}
        disabled={props.disabled || isLoading}
        {...buttonProps}
      >
        <Spinner
          className={cn({
            hidden: !(isLoading || showSpinner),
          })}
          pathClassName={buttonSpinnerVariants({ intent })}
        />
        {!(isLoading || showSpinner) && children}
      </Component>,
    ];

    if (tooltip)
      return <CustomTooltip message={tooltip}>{result}</CustomTooltip>;
    return result;
  },
);
