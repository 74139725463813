import { useClubFeatureFlags } from "@gymflow/api";
import { AvailabilityDTO, AvailabilitySlot, DayOfWeek } from "@gymflow/types";
import { TIME_FORMAT } from "@gymflow/validation";
import { createColumnHelper } from "@tanstack/react-table";
import { useClubSettings } from "apps/portal/src/providers";
import useGymflowModels from "apps/portal/src/store";
import classNames from "classnames";
import areIntervalsOverlapping from "date-fns/areIntervalsOverlapping";
import { capitalize } from "lodash";
import cloneDeep from "lodash/cloneDeep";
import moment from "moment-timezone";
import { useEffect, useState } from "react";
import { Mutable } from "utility-types";

import {
  Button,
  LabeledSwitch,
  PlusCircleIcon,
  Switch,
  TrashIcon,
} from "../../atoms";
import { Table } from "..";
import { TimeInterval } from "./TimeInterval";

type AvailabilityTableProps = {
  availabilityType: "STAFF" | "FACILITY";
  value: AvailabilityDTO | null;
  isLoading: boolean;
  isDisabled: boolean;
  onChange: (slots: AvailabilitySlot[]) => Promise<void>;
};

export function AvailabilityTable({
  availabilityType,
  value,
  onChange,
  isLoading = false,
  isDisabled = false,
}: AvailabilityTableProps) {
  const [editing, setEditing] = useState<AvailabilityDTO | null>(null);
  const { api } = useGymflowModels();
  const { clubId } = useClubSettings();
  const { data: featureFlags } = useClubFeatureFlags({ clubId, api });

  useEffect(() => {
    if (!value) {
      return;
    }
    return setEditing({ ...value });
  }, [value]);

  const weekdays: DayOfWeek[] = [
    "MONDAY",
    "TUESDAY",
    "WEDNESDAY",
    "THURSDAY",
    "FRIDAY",
    "SATURDAY",
    "SUNDAY",
  ];
  const slots = weekdays.map((weekday) => {
    return {
      weekday,
      slots: editing
        ? editing.availabilitySlotList.reduce((acc, slot, idx) => {
            if (slot.weekDay === weekday) {
              acc.push({
                ...slot,
                editingIdx: idx,
              });
            }
            return acc;
          }, [] as AvailabilitySlotColumn[])
        : [],
    };
  });

  const overlapMap = weekdays.reduce(
    (prev, weekday) => {
      prev[weekday] = false;

      const weekdaySlots = slots.find((slot) => slot.weekday === weekday);
      if (!weekdaySlots) {
        return prev;
      }

      const hasOverlap = weekdaySlots.slots.some((leftSlot, leftIdx) => {
        return weekdaySlots.slots.some((rightSlot, rightIdx) => {
          if (leftIdx === rightIdx) {
            return false;
          }

          const leftStart = moment(leftSlot.startTime, TIME_FORMAT);
          const leftEnd = moment(leftSlot.endTime, TIME_FORMAT);
          const rightStart = moment(rightSlot.startTime, TIME_FORMAT);
          const rightEnd = moment(rightSlot.endTime, TIME_FORMAT);
          const doesCurrentOverlap = areIntervalsOverlapping(
            { start: leftStart.toDate(), end: leftEnd.toDate() },
            { start: rightStart.toDate(), end: rightEnd.toDate() },
          );
          return doesCurrentOverlap;
        });
      });

      prev[weekday] = hasOverlap;

      return prev;
    },
    {} as Record<DayOfWeek, boolean>,
  );

  const columnHelper = createColumnHelper<{
    weekday: DayOfWeek;
    slots: AvailabilitySlotColumn[];
  }>();
  const columns = [
    columnHelper.accessor("weekday", {
      header: "Day",
      cell: (column) => {
        return capitalize(column.row.original.weekday);
      },
    }),
    columnHelper.display({
      header: "Available?",
      cell: (column) => {
        const weekday = column.row.original.weekday;
        const isWeekdayEnabled = column.row.original.slots.some(
          (w) => w.status === "ACTIVE",
        );
        return (
          <Switch
            value={isWeekdayEnabled}
            disabled={isDisabled}
            onChange={(checked) => {
              if (checked) {
                const defaultSlotValue: AvailabilitySlot = {
                  startTime: "09:00:00",
                  endTime: "17:00:00",
                  availabilityType: availabilityType,
                  status: "ACTIVE",
                  weekDay: weekday,
                };
                setEditing((prev) => {
                  const newValue = cloneDeep(prev);
                  newValue!.availabilitySlotList.push(defaultSlotValue);
                  return newValue;
                });
              } else {
                setEditing((prev) => {
                  const newValue: Mutable<AvailabilityDTO | null> =
                    cloneDeep(prev);
                  newValue!.availabilitySlotList =
                    prev!.availabilitySlotList.filter(
                      (slot) => slot.weekDay !== weekday,
                    );
                  return newValue;
                });
              }
            }}
          />
        );
      },
    }),
    columnHelper.accessor("slots", {
      header: "Available Shifts",
      cell: (column) => {
        if (column.row.original.slots.length === 0) {
          return (
            <div className="flex min-h-[2.75rem] min-w-[33rem] items-center text-gray-600">
              Not Available
            </div>
          );
        }

        // Refactor this logic after refactoring on BE side.
        // More in info in the comment from BE:
        // https://linear.app/gymflow/issue/GYM-1131/[be]-improve-availability-on-staff-portalstaff-app#comment-f0ae42fa
        const isAvailableAllDay = featureFlags?.featureFlags
          .FE_AVAILABILITY_FOR_WHOLE_DAY
          ? column.row.original.slots.some(
              (slot) => slot.startTime === "00:00:00" && slot.endTime === null,
            )
          : false;

        return (
          <div className="flex min-w-[33rem] flex-row">
            {!isAvailableAllDay && (
              <div className="flex flex-col gap-y-2">
                {column.row.original.slots.map((slot, idx) => {
                  const isLastRow =
                    idx + 1 === column.row.original.slots.length;
                  return (
                    <div
                      className="flex items-center gap-1"
                      key={slot.startTime + slot.endTime}
                    >
                      <TimeInterval
                        key={slot.startTime}
                        startTime={moment(slot.startTime, TIME_FORMAT).format(
                          "HH:mm",
                        )}
                        endTime={moment(slot.endTime, TIME_FORMAT).format(
                          "HH:mm",
                        )}
                        onChange={(editedValue) => {
                          setEditing((prev) => {
                            const newEditing = cloneDeep(prev)!;
                            newEditing.availabilitySlotList[slot.editingIdx] = {
                              ...newEditing.availabilitySlotList[
                                slot.editingIdx
                              ],
                              startTime: editedValue.startTime + ":00",
                              endTime: editedValue.endTime + ":00",
                            };
                            return newEditing;
                          });
                        }}
                      />
                      <div
                        className={classNames("ml-2 cursor-pointer", {
                          hidden: !isLastRow || slot.endTime === "23:45",
                        })}
                        onClick={() => {
                          const endMoment = moment(slot.endTime, "HH:mm");
                          setEditing((prev) => {
                            return {
                              availableForAppointments: true,
                              availabilitySlotList: [
                                ...prev!.availabilitySlotList,
                                {
                                  weekDay: column.row.original.weekday,
                                  startTime: endMoment
                                    .clone()
                                    .add(15, "minutes")
                                    .format("HH:mm:ss"),
                                  endTime: endMoment
                                    .clone()
                                    .add(30, "minutes")
                                    .format("HH:mm:ss"),
                                  status: "ACTIVE",
                                  availabilityType: availabilityType,
                                },
                              ],
                            };
                          });
                        }}
                      >
                        <PlusCircleIcon
                          className="mr-2 self-center"
                          pathClassName="stroke-primary"
                        />
                      </div>
                      <div
                        className="cursor-pointer"
                        onClick={() => {
                          setEditing((prev) => {
                            const newSlots = prev!.availabilitySlotList.filter(
                              (s) =>
                                s.weekDay !== slot.weekDay ||
                                s.startTime !== slot.startTime ||
                                s.endTime !== slot.endTime,
                            );
                            return {
                              availableForAppointments: true,
                              availabilitySlotList: newSlots,
                            };
                          });
                        }}
                      >
                        <TrashIcon
                          className="mr-2 self-center"
                          pathClassName="stroke-primary"
                        />
                      </div>
                    </div>
                  );
                })}
                {overlapMap[column.row.original.weekday] && (
                  <div className="text-error-700 my-2">
                    Some intervals overlap.
                  </div>
                )}
              </div>
            )}
            {featureFlags?.featureFlags.FE_AVAILABILITY_FOR_WHOLE_DAY && (
              <LabeledSwitch
                value={isAvailableAllDay}
                disabled={isDisabled}
                label="Available All Day"
                onChange={(checked) => {
                  // Refactor this logic after refactoring on BE side.
                  setEditing((prev) => {
                    if (!prev) return prev;

                    const weekday = column.row.original.weekday;
                    const newEditing: Mutable<AvailabilityDTO | null> =
                      cloneDeep(prev);

                    // More in info in the comment from BE:
                    // https://linear.app/gymflow/issue/GYM-1131/[be]-improve-availability-on-staff-portalstaff-app#comment-f0ae42fa
                    if (checked) {
                      newEditing.availabilitySlotList =
                        newEditing.availabilitySlotList
                          .filter((slot) => slot.weekDay !== weekday)
                          .concat([
                            {
                              weekDay: weekday,
                              startTime: "00:00:00",
                              endTime: null,
                              status: "ACTIVE",
                              availabilityType,
                            },
                          ]);
                    } else {
                      newEditing.availabilitySlotList =
                        newEditing.availabilitySlotList.map((slot) =>
                          slot.weekDay === weekday &&
                          slot.startTime === "00:00:00" &&
                          slot.endTime === null
                            ? { ...slot, endTime: "14:00:00" }
                            : slot,
                        );
                    }

                    return newEditing;
                  });
                }}
              />
            )}
          </div>
        );
      },
    }),
  ];

  return (
    <>
      {value && (
        <Table
          rowClassName="h-14"
          data={slots}
          columns={columns}
          isFetching={isLoading}
          pageCount={1}
          pageSize={10}
        />
      )}
      <div className="m-4 flex flex-row-reverse">
        <Button
          intent="primary"
          className="mt-2 w-32"
          disabled={Object.values(overlapMap).some((v) => v)}
          onClick={async () => {
            if (!value) {
              return;
            }
            await onChange(editing!.availabilitySlotList);
          }}
        >
          Save
        </Button>
      </div>
    </>
  );
}

interface AvailabilitySlotColumn extends AvailabilitySlot {
  editingIdx: number;
}
