import { faAngleLeft, faClose } from "@fortawesome/free-solid-svg-icons";
import { FontAwesomeIcon } from "@fortawesome/react-fontawesome";
import { useHistory } from "react-router";

import { usePortalRoutes } from "../../../hooks";
import { useAuthenticatedUser } from "../../../providers";
import { Button } from "../../atoms";
import HostedPagesProfileAvatar from "./HostedPagesProfileAvatar";

export type HostedPagesBackHeaderProps = {
  label: string;
  link?: string;
  onClose?: () => void;
  rightSideOptions?: React.ReactNode;
};

export const HostedPagesBackHeader = ({
  label,
  onClose,
  link,
  rightSideOptions
}: HostedPagesBackHeaderProps) => {
  const { isEmbed } = usePortalRoutes();
  const auth = useAuthenticatedUser();
  const history = useHistory();
  return (
    <div className="bg-gray-0 flex h-fit w-full flex-row items-center justify-between gap-4 border-b border-gray-300 p-4 dark:border-gray-700 dark:bg-gray-950 lg:px-28">
      <div className="flex flex-row items-center justify-center gap-4">
        <Button
          intent="transparent"
          className="min-h-0 min-w-0 p-1"
          link={link}
          onClick={
            link
              ? undefined
              : () => {
                  history.goBack();
                }
          }
        >
          <FontAwesomeIcon
            icon={faAngleLeft}
            className="h-5 w-5 text-gray-500"
          />
        </Button>
        <div className="dark:text-gray-0 text-xl font-medium text-gray-950">
          {label}
        </div>
      </div>
      {rightSideOptions}
      {!onClose && auth.id && isEmbed && <HostedPagesProfileAvatar />}
      {onClose && (
        <Button
          onClick={() => {
            onClose();
          }}
          className="aspect-square h-10 w-10"
          intent="transparent"
        >
          <FontAwesomeIcon
            className="cursor-pointer text-xl text-gray-600"
            icon={faClose}
          />
        </Button>
      )}
    </div>
  );
};
