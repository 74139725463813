import {
  faArrowDown,
  faArrowUp,
  faCheck,
} from "@fortawesome/free-solid-svg-icons";
import { FontAwesomeIcon } from "@fortawesome/react-fontawesome";
import { cn } from "@gymflow/helpers";
import React from "react";

import {
  Button,
  Popover,
  PopoverContent,
  PopoverTrigger,
  SortIcon,
} from "../atoms";

export type SortType = "ASC" | "DESC";

export type SortPopoverProps = {
  value?: SortType;
  onChange: (newValue?: SortType) => void;
};

export const SortPopover: React.FC<SortPopoverProps> = ({
  value,
  onChange,
}) => {
  return (
    <Popover>
      <PopoverTrigger asChild>
        <Button className="relative">
          <div className="flex flex-row items-center gap-1">
            <SortIcon pathClassName="fill-gray-950 dark:fill-white" />
            <div className="dark:text-gray-0 hidden font-medium text-gray-950 lg:flex">
              Sort
            </div>
          </div>
          <div
            className={cn(
              "bg-secondary-500 absolute right-1 top-1 h-2 w-2 rounded-full transition-all duration-200",
              {
                "opacity-0": value === undefined,
              },
            )}
          />
        </Button>
      </PopoverTrigger>
      <PopoverContent className="flex flex-col p-1" align="end">
        <div className="dark:text-gray-0 px-3 py-2 font-medium text-gray-950">
          Sort By:
        </div>
        <div
          onClick={() => onChange(value === "ASC" ? undefined : "ASC")}
          className={cn(
            "flex cursor-pointer select-none flex-row items-center justify-between rounded-xl p-3 hover:bg-gray-50 active:bg-gray-100 dark:hover:bg-gray-800 dark:active:bg-gray-700",
            {
              "bg-gray-50 dark:bg-gray-800": value === "ASC",
            },
          )}
        >
          <div className="dark:text-gray-0 flex flex-row items-center gap-2 text-gray-950">
            <FontAwesomeIcon
              icon={faArrowUp}
              className="h-5 w-5 text-gray-500"
            />
            Price: Low to high
          </div>
          <FontAwesomeIcon
            icon={faCheck}
            className={cn(
              "text-secondary-500 h-5 w-5 opacity-0 transition-all duration-100",
              {
                "opacity-100": value === "ASC",
              },
            )}
          />
        </div>
        <div
          onClick={() => onChange(value === "DESC" ? undefined : "DESC")}
          className={cn(
            "flex cursor-pointer select-none flex-row items-center justify-between rounded-xl p-3 hover:bg-gray-50 active:bg-gray-100 dark:hover:bg-gray-800 dark:active:bg-gray-700",
            {
              "bg-gray-50 dark:bg-gray-800": value === "DESC",
            },
          )}
        >
          <div className="dark:text-gray-0 flex flex-row items-center gap-2 text-gray-950">
            <FontAwesomeIcon
              icon={faArrowDown}
              className="h-5 w-5 text-gray-500"
            />
            Price: High to low
          </div>
          <FontAwesomeIcon
            icon={faCheck}
            className={cn(
              "text-secondary-500 h-5 w-5 opacity-0 transition-all duration-100",
              {
                "opacity-100": value === "DESC",
              },
            )}
          />
        </div>
      </PopoverContent>
    </Popover>
  );
};
