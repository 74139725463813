import { faClock } from "@fortawesome/free-regular-svg-icons";
import { FontAwesomeIcon } from "@fortawesome/react-fontawesome";
import { AppointableDTO } from "@gymflow/types";
import { usePortalRoutes } from "apps/portal/src/hooks";
import {
  useAuthenticatedUser
} from "apps/portal/src/providers";
import { LoginOverlayContext } from "apps/portal/src/providers/authentication/loginOverlay/context";
import { RouteFeature } from "apps/portal/src/routes";
import { Duration } from "luxon";
import { useContext } from "react";
import { useTranslation } from "react-i18next";
import { useHistory } from "react-router";

import { Button } from "../../../atoms";
import { HostedPagesCard } from "../../components/HostedPagesCard";

export type HostedPagesAppointableCardProps = {
  appointable: AppointableDTO;
};

const HostedPagesAppointableCard = ({
  appointable,
}: HostedPagesAppointableCardProps) => {
  const history = useHistory();
  const { createSiteOrEmbedLink } = usePortalRoutes();
  const { t } = useTranslation();
  const { showSignUpOverlay } = useContext(LoginOverlayContext);
  const auth = useAuthenticatedUser();
  const singleHost =
    appointable.availabilityType === "FACILITY" &&
    appointable.appointableFacilities.length === 1
      ? appointable.appointableFacilities[0].id
      : appointable.availabilityType === "STAFF" &&
        appointable.appointableHosts.length === 1
      ? appointable.appointableHosts[0].id
      : undefined;

  const nextStepLink = createSiteOrEmbedLink(
    (appointable.description || appointable.termsAndConditions
      ? RouteFeature.AppointableId
      : !singleHost
      ? RouteFeature.AppointableIdSelect
      : RouteFeature.AppointableIdSelectTime
    )
      .replace(":appointableId", appointable.id.toString())
      .replace(":staffOrFacilityId", singleHost?.toString() ?? ""),
  );
  return (
    <HostedPagesCard.Container>
      <HostedPagesCard.Header>
        <div className="flex flex-row justify-between gap-4">
          <div className="flex w-full flex-row items-center justify-between gap-1">
            <div className="min-w-fit text-lg font-bold text-gray-950 dark:text-gray-0">
              {appointable.name}
            </div>
            <div className="flex items-center gap-1 font-semibold text-gray-950 dark:text-gray-0">
              <FontAwesomeIcon
                className="h-4 w-4 text-gray-500"
                icon={faClock}
              />
              {Duration.fromObject({
                minutes: appointable.duration,
              })
                .rescale()
                .toHuman({
                  unitDisplay: "narrow",
                  listStyle: "short",
                })}
            </div>
          </div>
        </div>
      </HostedPagesCard.Header>
      <HostedPagesCard.Separator />
      <HostedPagesCard.Footer>
        <HostedPagesCard.AppointablePrice appointable={appointable} />
        {!auth.id ? (
          <Button
            intent="secondary-outline"
            className="min-w-[8rem]"
            onClick={async () => {
              const { isLoggedIn } = await showSignUpOverlay();

              if (isLoggedIn) {
                history.push(nextStepLink);
              }
            }}
          >
            {t("common.viewMore")}
          </Button>
        ) : (
          <Button
            intent="secondary-outline"
            className="min-w-[8rem]"
            link={nextStepLink}
          >
            {t("common.viewMore")}
          </Button>
        )}
      </HostedPagesCard.Footer>
    </HostedPagesCard.Container>
  );
};
export default HostedPagesAppointableCard;
