import { ApiType } from "@gymflow/common/lib/api/ApiType";
import { ApiListResponse, Membership } from "@gymflow/types";
import { useQuery, UseQueryOptions } from "@tanstack/react-query";

import { defaultPage } from "../defaultPage";
import { membershipQueryKeys } from "./membershipQueryKeys";

export function useMembershipList(
  {
    api,
    opts,
  }: {
    api: ApiType;
    opts?: Parameters<ApiType["serviceApi"]["find"]>[0];
  },
  queryOptions?: UseQueryOptions<ApiListResponse<Membership>>,
) {
  const result = useQuery({
    queryKey: membershipQueryKeys.list(opts),
    queryFn: () => {
      return membershipListQueryFn({ api, filter: opts });
    },
    initialData: defaultPage<Membership>,
    ...queryOptions,
  });
  return result;
}

export async function membershipListQueryFn({
  api,
  filter,
}: {
  api: ApiType;
  filter?: Parameters<ApiType["serviceApi"]["find"]>[0];
}) {
  const result = await api.serviceApi.find({ ...filter });
  return result.data as ApiListResponse<Membership>;
}
