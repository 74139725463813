import {
  ApiListResponse,
  RuleClubDTO,
  RuleDTO,
  RuleGroup,
  RuleGroupPatch,
  RuleGroupPost,
  RuleGroupType,
} from "@gymflow/types";
import { AxiosInstance } from "axios";

import { createSortParam } from "../createSortParam";

export type RuleApiType = ReturnType<typeof ruleApi>;

const ruleApi = (axiosInstance: AxiosInstance, clubId: number) => {
  const clubPrefix = `club/${clubId}/`;
  return {
    ruleGroup(ruleGroupId: number) {
      return axiosInstance.get<RuleGroup>(
        `${clubPrefix}rule-group/${ruleGroupId}`,
      );
    },
    newRuleGroup(fields: RuleGroupPost) {
      return axiosInstance.post(`${clubPrefix}rule-group/`, fields);
    },
    editRuleGroup(ruleGroupId: number, patchedFields: RuleGroupPatch) {
      return axiosInstance.put(
        `${clubPrefix}rule-group/${ruleGroupId}`,
        patchedFields,
      );
    },
    ruleGroupSearch({
      isActive,
      isDefault,
      groupType,
      page,
      size,
    }: {
      isActive?: boolean;
      isDefault?: boolean;
      groupType?: RuleGroupType;
      page: number;
      size: number;
    }) {
      return axiosInstance.put<ApiListResponse<RuleGroup>>(
        `${clubPrefix}rule-group/search`,
        {
          isActive,
          isDefault,
          groupType,
          page,
          size,
        },
      );
    },
    ruleGroupDelete(ruleGroupId: number) {
      return axiosInstance.delete(`${clubPrefix}rule-group/${ruleGroupId}`);
    },
    getAllRules() {
      return axiosInstance.get<RuleClubDTO[]>(`${clubPrefix}rules`);
    },

    /** @deprecated - should be removed once redux is removed */
    find({
      page = 0,
      limit = 10,
      sort,
      description,
      id,
      type,
      rulesAssociation,
      valueSetup,
    }: any) {
      const config = {
        params: {
          page,
          size: limit,
          description,
          id,
          type,
          rulesAssociation,
          valueSetup,
        },
      } as any;
      if (sort) {
        config.params.sort = createSortParam(sort);
      }

      return axiosInstance.get<ApiListResponse<RuleDTO>>(
        `${clubPrefix}rules/templates`,
        config,
      );
    },

    /*** @default - should be removed once redux is removed */
    getValuesByClubId(clubId: number /* unused */) {
      return axiosInstance.get(`${clubPrefix}rules`);
    },
    /*** @default - should be removed once redux is removed */
    updateValues(clubId: number, rules: any) {
      return axiosInstance.put(`${clubPrefix}rules`, rules);
    },
  };
};

export default ruleApi;
