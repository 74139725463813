import { ApiType } from "@gymflow/common/lib/api/ApiType";
import { useQuery } from "@tanstack/react-query";

import { membershipAsPublicQueryKeys } from "./membershipAsPublicQueryKeys";

export function useQueryMembershipListAsPublic(
  {
    api,
    opts,
  }: {
    api: ApiType;
    opts: Parameters<ApiType["public"]["membershipApi"]["getList"]>[0];
  },
  {
    enabled,
  }: {
    enabled?: boolean;
  } = {
    enabled: true,
  },
) {
  return useQuery({
    queryKey: membershipAsPublicQueryKeys.list(opts),
    queryFn: async () => {
      return (await api.public.membershipApi.getList({ ...opts })).data;
    },
    enabled,
  });
}
