import { ApiType } from "@gymflow/common/lib/api/ApiType";
import { InvoiceDTO } from "@gymflow/types";
import { useMutation } from "@tanstack/react-query";

export function useMutationGetInvoice({ api }: { api: ApiType }) {
  return useMutation({
    mutationFn: async ({
      invoiceNumber,
      userMemberId,
    }: {
      userMemberId: string;
      invoiceNumber: string;
    }) => {
      return (
        await api.memberApi.findInvoices(userMemberId, {
          extraParams: {
            number: invoiceNumber,
          },
          limit: 1,
        })
      ).data.content[0] as InvoiceDTO;
    },
  });
}
