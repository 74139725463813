import { cn } from "@gymflow/helpers";
import { Field, FieldProps, FormikProps } from "formik";

import { PasswordInput, PasswordInputProps } from "../base/PasswordInput";
import { ErrorMessage } from "../ErrorMessage";

type FormPasswordInputProps<T> = {
  name: keyof T;
  containerClassName?: string;
} & Pick<
  PasswordInputProps,
  "placeholder" | "label" | "className" | "isRequired"
>;

export const FormPasswordInput = <T,>({
  name,
  className,
  containerClassName,
  ...props
}: FormPasswordInputProps<T>) => {
  return (
    <Field name={name}>
      {({
        field,
        meta: { touched, error },
      }: FieldProps<string, FormikProps<T>>) => {
        const isError = touched && error;
        return (
          <div className={cn("flex flex-col gap-1.5", containerClassName)}>
            <PasswordInput
              {...field}
              {...props}
              className={cn(className, {
                "outline-error-300 border-error-200 dark:!outline-none focus:dark:ring-1 dark:ring-error-600":
                  isError,
              })}
            />
            {isError && <ErrorMessage text={error} />}
          </div>
        );
      }}
    </Field>
  );
};
