import { z } from "zod";

export const ActivitySchema = z.object({
  name: z.string().min(3),
  "activity-category-id": z.object({
    label: z.string(),
    value: z.number(),
  }),
  description: z.string().max(2000).optional(),
  'forMembersOnly': z.boolean().default(false),
});
