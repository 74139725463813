import { AlertCircleIcon } from "apps/portal/src/components/atoms";

type InfoBlockProps = {
  text: string;
};

export const InfoBlock = ({ text }: InfoBlockProps) => (
  <div className="flex items-start gap-2 rounded-lg bg-gray-100 px-3 py-1.5">
    <div className="my-1">
      <AlertCircleIcon className="h-4 w-4" pathClassName="stroke-gray-500" />
    </div>
    <div className="text-sm font-medium text-gray-950">{text}</div>
  </div>
);
