import { ApiType } from "@gymflow/common/lib/api/ApiType";
import { useMutation, useQueryClient } from "@tanstack/react-query";

import { ruleQueryKeys } from "./ruleQueryKeys";

export function useMutationRuleGroupDelete({ api }: { api: ApiType }) {
  const queryClient = useQueryClient();

  return useMutation({
    mutationFn: ({ ruleGroupId }: { ruleGroupId: number }) =>
      api.ruleApi.ruleGroupDelete(ruleGroupId),
    onSuccess: async () => {
      await queryClient.invalidateQueries({ queryKey: ruleQueryKeys.all() });
    },
  });
}
