import {
  useQueryMembershipListAsMember,
  useQueryMembershipListAsPublic,
} from "@gymflow/api";
import useGymflowModels from "apps/portal/src/store";
import { useMemo } from "react";

import { useAuthenticatedUser } from "../../../../providers";
import { membershipFilters, MembershipTabType } from "./constants";

export const useMembershipAvailableTabs = () => {
  const { api } = useGymflowModels();
  const auth = useAuthenticatedUser();
  const prepaidMembershipQueryOptions = {
    api,
    opts: {
      status: "ACTIVE",
      size: 1,
      page: 0,
      ...membershipFilters.prepaid,
    },
  } as const;
  const recurringMembershipQueryOptions = {
    api,
    opts: {
      status: "ACTIVE",
      size: 1,
      page: 0,
      ...membershipFilters.recurring,
    },
  } as const;
  const trialMembershipQueryOptions = {
    api,
    opts: {
      status: "ACTIVE",
      size: 1,
      page: 0,
      ...membershipFilters.trial,
    },
  } as const;

  const prepaidMembershipPublic = useQueryMembershipListAsPublic(
    prepaidMembershipQueryOptions,
    {
      enabled: !auth.id,
    },
  );
  const prepaidMembershipMember = useQueryMembershipListAsMember(
    prepaidMembershipQueryOptions,
    {
      enabled: !!auth.id,
    },
  );
  const { data: prepaidMembership } = !!auth.id
    ? prepaidMembershipMember
    : prepaidMembershipPublic;

  const recurringMembershipPublic = useQueryMembershipListAsPublic(
    recurringMembershipQueryOptions,
    {
      enabled: !auth.id,
    },
  );
  const recurringMembershipMember = useQueryMembershipListAsMember(
    recurringMembershipQueryOptions,
    {
      enabled: !!auth.id,
    },
  );
  const { data: recurringMembership } = !!auth.id
    ? recurringMembershipMember
    : recurringMembershipPublic;

  const trialMembershipPublic = useQueryMembershipListAsPublic(
    trialMembershipQueryOptions,
    {
      enabled: !auth.id,
    },
  );
  const trialMembershipMember = useQueryMembershipListAsMember(
    trialMembershipQueryOptions,
    {
      enabled: !!auth.id,
    },
  );
  const { data: trialMembership } = !!auth.id
    ? trialMembershipMember
    : trialMembershipPublic;
  const tabs = useMemo(() => {
    const result = [] as MembershipTabType[];
    if (recurringMembership && recurringMembership?.content.length > 0)
      result.push("recurring");
    if (prepaidMembership && prepaidMembership?.content.length > 0)
      result.push("prepaid");
    if (trialMembership && trialMembership?.content.length > 0)
      result.push("trial");
    return result;
  }, [prepaidMembership, recurringMembership, trialMembership]);
  return tabs;
};
