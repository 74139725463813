export const AlertTriangleIcon = ({
  className,
  pathClassName,
}: {
  className?: string;
  pathClassName?: string;
}) => (
  <svg
    width="16"
    height="16"
    viewBox="0 0 16 16"
    fill="none"
    xmlns="http://www.w3.org/2000/svg"
    className={className}
  >
    <path
      d="M7.99951 6.00015V8.66682M7.99951 11.3335H8.00618M7.07639 2.59464L1.59313 12.0657C1.289 12.591 1.13693 12.8537 1.1594 13.0693C1.17901 13.2573 1.27752 13.4282 1.43043 13.5394C1.60573 13.6668 1.90924 13.6668 2.51625 13.6668H13.4828C14.0898 13.6668 14.3933 13.6668 14.5686 13.5394C14.7215 13.4282 14.82 13.2573 14.8396 13.0693C14.8621 12.8537 14.71 12.591 14.4059 12.0657L8.92263 2.59463C8.61959 2.07119 8.46806 1.80947 8.27038 1.72157C8.09794 1.64489 7.90108 1.64489 7.72865 1.72157C7.53096 1.80947 7.37944 2.07119 7.07639 2.59464Z"
      stroke="#DC6803"
      strokeWidth="1.33333"
      strokeLinecap="round"
      strokeLinejoin="round"
      className={pathClassName}
    />
  </svg>
);
