import { usePortalRoutes } from "apps/portal/src/hooks";
import { RouteFeature } from "apps/portal/src/routes";
import { useTranslation } from "react-i18next";

import { Button } from "../../../atoms";

export const HostedPagesProfileGetMembership = () => {
  const { t } = useTranslation();
  const { createSiteOrEmbedLink } = usePortalRoutes();

  return (
    <div className="border-secondary-500 shadow-xs flex flex-col gap-3 rounded-2xl border bg-gray-0 p-4 dark:bg-gray-950 lg:flex-row lg:items-center lg:justify-between lg:gap-0">
      <div className="flex flex-col gap-0.5">
        <div className="dark:text-gray-25 text-base font-bold text-gray-950">
          {t("pages.hostedPagesProfile.hostedPagesProfileGetMembership.title")}
        </div>
        <div className="text-xs font-medium text-gray-500 dark:text-gray-400">
          {t(
            "pages.hostedPagesProfile.hostedPagesProfileGetMembership.subTitle",
          )}
        </div>
      </div>
      <Button
        className="w-fit px-6 text-sm font-semibold"
        intent="secondary"
        link={createSiteOrEmbedLink(
          RouteFeature.SiteBuyMembershipType.replace(":membershipType", "all"),
        )}
      >
        {t("pages.hostedPagesProfile.hostedPagesProfileGetMembership.button")}
      </Button>
    </div>
  );
};
