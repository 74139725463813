import { useAutoAnimate } from "@formkit/auto-animate/react";
import {
  leadSourceListAsPublicQueryOptions,
  useMutationLeadCreateAsPublic,
} from "@gymflow/api";
import { cn } from "@gymflow/helpers";
import { LeadSourceDTO } from "@gymflow/types";
import { validatePhoneNumber } from "@gymflow/validation";
import { InfiniteData } from "@tanstack/react-query";
import { isAxiosError } from "axios";
import { FormikProvider, useFormik } from "formik";
import { useContext } from "react";
import { useTranslation } from "react-i18next";
import { z } from "zod";
import { toFormikValidationSchema } from "zod-formik-adapter";

import { usePortalRoutes } from "../../hooks";
import { ToastContext } from "../../providers/ToastProvider/context";
import useGymflowModels from "../../store";
import { Button, FormInput, FormSelectInput, LayeredCircles } from "../atoms";
import { FormNotificationPreferences } from "../atoms/form/FormNotificationPreferences";
import { FormPhoneNumberInput } from "../atoms/form/FormPhoneNumberInput";
import CheckmarkIcon from "../atoms/icons/CheckmarkIcon";

export type HostedPagesLeadCaptureProps = {};

const contactUsSchema = z.object({
  email: z.string().trim().toLowerCase().email(),
  firstName: z.string().min(1, "First name is required"),
  lastName: z.string().min(1, "Last name is required"),
  mobileNumber: z
    .string()
    .refine((e) => validatePhoneNumber(e), "Invalid Phone Number"),
  // notes: z.string(),
  leadSource: z.custom<LeadSourceDTO>(
    (e) => !!(e as LeadSourceDTO)?.id,
    "Required",
  ),
  emailCommunication: z.boolean(),
  smsCommunication: z.boolean(),
});
type ContactUsType = z.infer<typeof contactUsSchema>;
const HostedPagesLeadCapture = ({}: HostedPagesLeadCaptureProps) => {
  const { isEmbed } = usePortalRoutes();
  const { t } = useTranslation();
  const { api } = useGymflowModels();
  const { notifyDanger } = useContext(ToastContext);
  const createLeadMutation = useMutationLeadCreateAsPublic({ api });
  const formik = useFormik<ContactUsType>({
    initialValues: {
      email: "",
      firstName: "",
      lastName: "",
      mobileNumber: "",
      // notes: "",
      leadSource: undefined as any,
      emailCommunication: false,
      smsCommunication: false,
    },
    validationSchema: toFormikValidationSchema<ContactUsType>(contactUsSchema),
    onSubmit: async () => {
      try {
        if (!formik.values.leadSource?.id) return;
        await createLeadMutation.mutateAsync({
          email: formik.values.email.trim().toLowerCase(),
          firstName: formik.values.firstName,
          lastName: formik.values.lastName,
          mobileNumber: formik.values.mobileNumber,
          sourceId: formik.values.leadSource?.id,
          emailCommunication: formik.values.emailCommunication,
          smsCommunication: formik.values.smsCommunication,
        });
      } catch (e) {
        if (isAxiosError(e)) {
          notifyDanger(e);
        }
      }
    },
  });
  const [parent] = useAutoAnimate();
  return (
    <div
      className={cn(
        "track-height flex w-full flex-col overflow-y-auto items-center",
        {
          "h-fit": isEmbed,
          "h-full": !isEmbed,
        },
      )}
    >
      <div
        ref={parent}
        className="flex w-[calc(min(28rem,100vw-2rem))] flex-col gap-4 p-4"
      >
        {createLeadMutation.isSuccess && (
          <div className="flex flex-col items-center justify-center gap-1 py-6 pt-24">
            <LayeredCircles intent="success">
              <CheckmarkIcon pathClassName="stroke-success-600" />
            </LayeredCircles>
            <div className="text-center text-xl font-bold text-gray-950 dark:text-gray-0">
              {t("pages.hostedPagesLeadCapture.successTitle")}
            </div>
            <div className="text-md text-center text-gray-500">
              {t("pages.hostedPagesLeadCapture.successDescription")}
            </div>
          </div>
        )}
        {!createLeadMutation.isSuccess && (
          <>
            <div className="flex flex-col items-center justify-center gap-1">
              <div className="text-center text-xl font-bold text-gray-950 dark:text-gray-0">
                {t("pages.hostedPagesLeadCapture.title")}
              </div>
              <div className="text-md text-center text-gray-500">
                {t("pages.hostedPagesLeadCapture.description")}
              </div>
            </div>
            <FormikProvider value={formik}>
              <FormInput<ContactUsType>
                name="email"
                label={t("common.userMemberForm.email")}
                placeholder={t("common.userMemberForm.email")}
                isRequired
              />
              <div className="flex flex-row gap-2 ">
                <FormInput<ContactUsType>
                  name="firstName"
                  label={t("common.userMemberForm.firstName")}
                  placeholder={t("common.userMemberForm.firstName")}
                  isRequired
                />
                <FormInput<ContactUsType>
                  name="lastName"
                  label={t("common.userMemberForm.lastName")}
                  placeholder={t("common.userMemberForm.lastName")}
                  isRequired
                />
              </div>
              <FormPhoneNumberInput<ContactUsType>
                name="mobileNumber"
                placeholder={t("common.userMemberForm.mobileNumber")}
                label={t("common.userMemberForm.mobileNumber")}
                isRequired
              />
              {/* <FormTextarea<ContactUsType>
                      name="notes"
                      label={t("common.userMemberForm.notes")}
                      rows={5}
                      isRequired
                    /> */}
              <FormSelectInput<ContactUsType, LeadSourceDTO>
                name="leadSource"
                label={t("common.userMemberForm.leadSource")}
                placeholder={t("common.userMemberForm.leadSource")}
                dataFetchingQuery={() => {
                  return {
                    ...leadSourceListAsPublicQueryOptions(api),
                    select: (data) => {
                      const { pageParams, pages } = data as InfiniteData<
                        LeadSourceDTO[]
                      >;

                      const transformedPages = pages.map((page) =>
                        page.map((leadSource) => ({
                          id: leadSource.id,
                          label: leadSource.name,
                          value: leadSource,
                        })),
                      );
                      return {
                        pageParams,
                        pages: transformedPages,
                      };
                    },

                    getNextPageParam: () => undefined,
                  };
                }}
                isRequired
              />
              <FormNotificationPreferences
                emailCommunicationFieldName={"emailCommunication"}
                smsCommunicationFieldName={"smsCommunication"}
              />
              <Button
                intent="secondary"
                className="w-full"
                onClick={async () => await formik.submitForm()}
              >
                {t("pages.hostedPagesLeadCapture.submit")}
              </Button>
            </FormikProvider>
          </>
        )}
      </div>
    </div>
  );
};

export default HostedPagesLeadCapture;
