export const Coins03Icon = ({
  className,
  pathClassName,
}: {
  className?: string;
  pathClassName?: string;
}) => (
  <svg
    className={className}
    width="20"
    height="20"
    viewBox="0 0 20 20"
    fill="none"
    xmlns="http://www.w3.org/2000/svg"
  >
    <g clipPath="url(#clip0_7491_921)">
      <path
        d="M8.41747 3.33335C9.46959 2.30236 10.9105 1.66669 12.5 1.66669C15.7216 1.66669 18.3333 4.27836 18.3333 7.50002C18.3333 9.08947 17.6976 10.5304 16.6666 11.5826M13.3333 12.5C13.3333 15.7217 10.7216 18.3334 7.49996 18.3334C4.2783 18.3334 1.66663 15.7217 1.66663 12.5C1.66663 9.27836 4.2783 6.66669 7.49996 6.66669C10.7216 6.66669 13.3333 9.27836 13.3333 12.5Z"
        stroke="#8746EC"
        strokeWidth="2"
        strokeLinecap="round"
        strokeLinejoin="round"
        className={pathClassName}
      />
    </g>
    <defs>
      <clipPath id="clip0_7491_921">
        <rect width="20" height="20" fill="white" />
      </clipPath>
    </defs>
  </svg>
);
