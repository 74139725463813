import { cva, VariantProps } from "class-variance-authority";
import React from "react";

import { cn } from "../../../../../libs/helpers/src";

const emptyDotVariants = cva(
  "flex h-4 w-4 items-center justify-center rounded-full",
  {
    variants: {
      intent: {
        primary: "bg-primary-600 dark:bg-primary-500",
        secondary: "bg-secondary-600 dark:bg-secondary-500",
        error: "bg-error-600 dark:bg-error-500",
        warning: "bg-warning-600 dark:bg-warning-500",
        success: "bg-success-600 dark:bg-success-500",
        lightblue: "bg-lightblue-600 dark:bg-lightblue-500",
        default: "bg-gray-600 dark:bg-gray-500",
      },
    },
    defaultVariants: {
      intent: "default",
    },
  },
);

const innerEmptyDotVariants = cva(
  "bg-gray-0 flex h-2 w-2 items-center justify-center rounded-full",
  {
    variants: {
      intent: {
        primary: "dark:bg-primary-900",
        secondary: "dark:bg-secondary-900",
        error: "dark:bg-error-900",
        warning: "dark:bg-warning-900",
        success: "dark:bg-success-900",
        lightblue: "dark:bg-lightblue-900",
        default: "dark:bg-gray-900",
      },
    },
    defaultVariants: {
      intent: "default",
    },
  },
);
export type EmptyDotProps = {
  classNameOverride?: string;
} & VariantProps<typeof emptyDotVariants>;

export const EmptyDot: React.FC<EmptyDotProps> = ({
  intent,
  classNameOverride,
}) => {
  return (
    <div className={cn(emptyDotVariants({ intent }), classNameOverride)}>
      <div className={cn(innerEmptyDotVariants({ intent }))}></div>
    </div>
  );
};
