import { cn } from "@gymflow/helpers";
import { Field, FieldProps, FormikProps } from "formik";

import { InputProps } from "../base/Input/Input";
import { PhoneNumberInput } from "../base/PhoneNumberInput";
import { ErrorMessage } from "../ErrorMessage";

type FormPhoneNumberInputProps<T> = {
  name: keyof T;
} & Pick<
  InputProps,
  "placeholder" | "label" | "className" | "variant" | "isRequired" | "disabled"
>;

export const FormPhoneNumberInput = <T,>({
  name,
  className,
  ...props
}: FormPhoneNumberInputProps<T>) => {
  return (
    <Field name={name}>
      {({
        field,
        form: { setFieldValue },
        meta: { touched, error },
      }: FieldProps<string, FormikProps<T>>) => {
        const isError = touched && error;
        return (
          <div className="flex flex-col gap-1.5">
            <PhoneNumberInput
              {...field}
              {...props}
              onChange={(e) => {
                setFieldValue(String(name), e);
              }}
              className={cn(className, {
                "outline-error-300 border-error-200 dark:!outline-none focus:dark:ring-1 dark:ring-error-600":
                  isError && props.variant !== "unstyled",
              })}
            />
            {isError && <ErrorMessage text={error} />}
          </div>
        );
      }}
    </Field>
  );
};
