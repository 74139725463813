import {
  DurationType,
  MembershipBillingTypeSchema,
  MembershipProrataChoiceZodSchema,
  WeekdayZodSchema,
} from "@gymflow/common";
import z from "zod";

import { AddonSchema } from "./AddonSchema";

export const RecurringMembershipSchema = z
  .object({
    name: z
      .string()
      .min(3, "Must be at least 3 characters")
      .max(100, "Must be shorter than 100 characters")
      .default(""),
    description: z
      .string()
      .max(500, "Must be shorter than 500 characters")
      .optional(),
    "upfront-addons": z.array(AddonSchema).default([]),
    "recurring-addons": z.array(AddonSchema).default([]),
    "default-price": z.coerce.number({
      invalid_type_error: "Must include price",
      required_error: "Must include price",
    }),
    "billing-period": z.number({
      invalid_type_error: "Must include billing period",
      required_error: "Must include billing period",
    }),
    "billing-type": MembershipBillingTypeSchema,
    "calculate-prorata": MembershipProrataChoiceZodSchema,
    "monthly-billing-day": z.number().default(1),
    "weekly-billing-day": WeekdayZodSchema,
    "is-public": z.boolean().default(false),
    "duration-type": z
      .enum([
        DurationType.Daily,
        DurationType.Weekly,
        DurationType.Monthly,
        DurationType.NoContract,
      ])
      .default(DurationType.NoContract),
    duration: z.number().default(0),
    "is-trial": z.boolean().default(false),
    "terms-conditions": z.object(
      { name: z.string() },
      {
        invalid_type_error: "Terms and Conditions are required",
        required_error: "Terms and Conditions are required",
      },
    ),
    "fixed-start-date": z.string().optional(),
    "fixed-end-date": z.string().optional(),
    "for-members-only": z.boolean().default(false),
  })
  .refine(
    (data) => {
      if (data["fixed-start-date"]) {
        return !!data["fixed-end-date"];
      }
      if (data["fixed-end-date"]) {
        return !!data["fixed-start-date"];
      }
      return true;
    },
    {
      message: "Fixed start date and end date are mandatory if either is used.",
      path: ["fixed-start-date"],
    },
  )
  .refine(
    (data) => {
      if (!data["calculate-prorata"] || data["billing-type"] !== "MONTHLY") {
        return true;
      }
      return !!data["monthly-billing-day"];
    },
    {
      message: "Billing day is required.",
      path: ["monthly-billing-day"],
    },
  )
  .refine(
    (data) => {
      if (!data["calculate-prorata"] || data["billing-type"] !== "WEEKLY") {
        return true;
      }
      return !!data["weekly-billing-day"];
    },
    {
      message: "Billing day is required.",
      path: ["weekly-billing-day"],
    },
  );
export type RecurringMembershipType = z.infer<typeof RecurringMembershipSchema>;
export type RecurringMembershipSchemaKeys = keyof z.infer<
  typeof RecurringMembershipSchema
>;
