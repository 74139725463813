import { useClubFeatureFlags, useLead, useMember } from "@gymflow/api";
import { cn } from "@gymflow/helpers";
import { LeadDTO, UserMemberBean } from "@gymflow/types";
import React, { useCallback, useContext } from "react";
import { Tooltip } from "react-tooltip";

import { useAccessToggleButton } from "../../hooks";
import { Can, Subject, Verb } from "../../permissions";
import { useClubSettings } from "../../providers";
import useGymflowModels from "../../store";
import {
  Button,
  CheckDoneIcon,
  CheckInIcon,
  CheckOutIcon,
  MailIcon,
  ShopBagIcon,
  SMSIcon,
  Spinner,
  StickerSquareIcon,
} from "../atoms";
import {
  CreateEditTaskSidebarProviderContext,
  SendEmailSidebarProviderContext,
  SendSMSToLeadSidebarContext,
  SendSMSToUserSidebarContext,
} from "../organisms";
import { NoteFormSideBarProviderContext } from "../UserMember/Notes/NoteFormSideBarProvider";

export interface ActionMenuButtonsProps {
  userMemberId?: string;
  leadId?: number;
  showShop: (user?: UserMemberBean | LeadDTO) => Promise<void>;
  className?: string;
}

export const ActionMenuButtons: React.FC<ActionMenuButtonsProps> = ({
  userMemberId,
  leadId,
  showShop,
  className,
}) => {
  const { api } = useGymflowModels();
  const { clubId, timezone: tz } = useClubSettings();
  const { triggerNewNote } = useContext(NoteFormSideBarProviderContext);
  const { data: memberContainer } = useMember({
    api,
    memberId: userMemberId,
    tz,
  });
  const { openCreateTaskSidebar } = useContext(
    CreateEditTaskSidebarProviderContext,
  );
  const { openSendEmailSidebar } = useContext(SendEmailSidebarProviderContext);
  const { data: featureFlags } = useClubFeatureFlags({ clubId, api });
  const { data: lead } = useLead({ api, leadId: leadId });
  const user = memberContainer?.user || lead;

  const { toggle, isCheckedIn } = useAccessToggleButton({ userMemberId });

  const onClickCreateTask = useCallback(() => {
    if (!user) {
      return;
    }

    const { id, firstName, lastName } = user;

    const imageUrl = "picture" in user ? user.picture : undefined;

    openCreateTaskSidebar({
      initialValues: {
        relatedUsers: [
          {
            id,
            label: `${firstName} ${lastName}`,
            value: user,
            imageUrl,
          },
        ],
      },
    });
  }, [openCreateTaskSidebar, user]);

  const { open: openSendSMSToUserSidebar } = useContext(
    SendSMSToUserSidebarContext,
  );
  const { open: openSendSMSToLeadSidebar } = useContext(
    SendSMSToLeadSidebarContext,
  );
  return (
    <div className={cn("flex flex-row items-center gap-x-2", className)}>
      {!user && <Spinner />}
      {user && (
        <>
          {featureFlags?.featureFlags.PORTAL_SEND_SMS && (
            <Can I={Verb.Create} a={Subject.SMS}>
              <Button
                onClick={() => {
                  if (userMemberId) {
                    openSendSMSToUserSidebar({
                      memberId: userMemberId,
                      memberName: `${user.firstName} ${user.lastName}`,
                    });
                  }
                  if (leadId) {
                    openSendSMSToLeadSidebar({
                      leadId,
                      leadName: `${user.firstName} ${user.lastName}`,
                    });
                  }
                }}
                className="flex h-10 w-10 min-w-0 px-0 shadow"
                data-tooltip-id="sms-tooltip"
                data-tooltip-content="Send SMS"
              >
                <SMSIcon
                  className="h-[1.125rem] w-[1.125rem]"
                  pathClassName="stroke-gray-700"
                />
              </Button>
              <Tooltip
                className="rounded-lg !bg-black !text-xs !font-semibold"
                id="sms-tooltip"
              />
            </Can>
          )}
          <Can I={Verb.Create} a={Subject.Email}>
            <Button
              onClick={() => {
                const recipient = `${user.firstName} ${user.lastName}`;
                openSendEmailSidebar({
                  recipient,
                  allowMarketing: user.emailCommunication,
                  requestType: user.profileType,
                  listOfIds:
                    user.profileType === "LEAD"
                      ? [user.id as number]
                      : [user.id as string],
                });
              }}
              className="flex h-10 w-10 min-w-0 px-0 shadow"
              data-tooltip-id="email-tooltip"
              data-tooltip-content="Send Email"
            >
              <MailIcon
                className="h-[1.125rem] w-[1.125rem]"
                pathClassName="stroke-gray-700"
              />
            </Button>
            <Tooltip
              className="rounded-lg !bg-black !text-xs !font-semibold"
              id="email-tooltip"
            />
          </Can>
          <Button
            onClick={() => {
              triggerNewNote({
                ...(typeof user.id === "number"
                  ? {
                      leadId: user.id,
                    }
                  : {
                      userMemberId: user.id,
                    }),
                name: `${user.firstName} ${user.lastName}`,
              });
            }}
            className="flex h-10 w-10 min-w-0 px-0 shadow"
            data-tooltip-id="note-tooltip"
            data-tooltip-content="Create Note"
          >
            <StickerSquareIcon
              className="h-[1.125rem] w-[1.125rem]"
              pathClassName="stroke-gray-700"
            />
          </Button>
          <Tooltip
            className="rounded-lg !bg-black !text-xs !font-semibold"
            id="note-tooltip"
          />
          <Button
            onClick={onClickCreateTask}
            className="flex h-10 w-10 min-w-0 px-0 shadow"
            data-tooltip-id="task-tooltip"
            data-tooltip-content="Create Task"
          >
            <CheckDoneIcon
              className="h-[1.125rem] w-[1.125rem]"
              pathClassName="stroke-gray-700"
            />
          </Button>
          <Tooltip
            className="rounded-lg !bg-black !text-xs !font-semibold"
            id="task-tooltip"
          />
          <Button
            className="flex h-10 w-10 min-w-0 px-0 shadow"
            onClick={() => {
              showShop(user);
            }}
            data-tooltip-id="sell-tooltip"
            data-tooltip-content="Sell Something"
          >
            <ShopBagIcon
              className="h-[1.125rem] w-[1.125rem]"
              pathClassName="stroke-gray-700"
            />
          </Button>
          <Tooltip
            className="rounded-lg !bg-black !text-xs !font-semibold"
            id="sell-tooltip"
          />
          <Button
            className="flex h-10 w-10 min-w-0 px-0 shadow"
            onClick={toggle}
            data-tooltip-id="access-tooltip"
            data-tooltip-content={isCheckedIn ? "Check out" : "Check In"}
          >
            {isCheckedIn ? (
              <CheckOutIcon
                className="h-[1.125rem] w-[1.125rem]"
                pathClassName="stroke-gray-700"
              />
            ) : (
              <CheckInIcon
                className="h-[1.125rem] w-[1.125rem]"
                pathClassName="stroke-gray-700"
              />
            )}
          </Button>
          <Tooltip
            className="rounded-lg !bg-black !text-xs !font-semibold"
            id="access-tooltip"
          />
        </>
      )}
    </div>
  );
};
