import {
  clubStaleTime,
  useClubHostedPagesSettingsAsPublic,
} from "@gymflow/api";
import { Route, Switch, useRouteMatch } from "react-router-dom";

import { HostedPagesFooter } from "../../components/site/components/HostedPagesFooter";
import {
  useHtmlNodeDarkMode,
  useInjectWebsiteCustomizations,
} from "../../hooks";
import {
  ApiResolverProvider,
  ClubSettingsAsPublicProvider,
  ModalProvider,
  useClubSettings,
} from "../../providers";
import { migrationRoutes, RouteDefinition } from "../../routes";
import useGymflowModels from "../../store";

function Migration() {
  useHtmlNodeDarkMode({ supportDarkMode: true });
  useInjectWebsiteCustomizations();

  const { api } = useGymflowModels();
  const settings = useClubSettings();
  const { data: hostedPagesSettings } = useClubHostedPagesSettingsAsPublic(
    { api, clubId: settings.clubId },
    { staleTime: clubStaleTime },
  );

  const match = useRouteMatch();
  const getRoutes = (routes: RouteDefinition[]) => {
    const nodes = routes.map((prop) => {
      return (
        <Route path={match.path + prop.path} key={prop.path}>
          {(props) => <prop.component {...props} />}
        </Route>
      );
    });
    return nodes;
  };

  return (
    <div className="w-100 bg-gray-0 flex h-screen flex-col dark:bg-gray-950">
      <div className="flex flex-col gap-5 border-b border-b-gray-300 px-2 py-6 dark:border-b-gray-700">
        <div className="flex items-center justify-center">
          {hostedPagesSettings?.settings?.logo ? (
            <div className="flex h-14  w-48 justify-center">
              {hostedPagesSettings?.settings?.yourWebsiteUrl ? (
                <a
                  className="flex justify-center"
                  href={hostedPagesSettings.settings.yourWebsiteUrl}
                >
                  <img
                    src={hostedPagesSettings.settings.logo}
                    alt="logo"
                    className="h-14"
                  />
                </a>
              ) : (
                <img
                  src={hostedPagesSettings.settings.logo}
                  alt="logo"
                  className="h-14"
                />
              )}
            </div>
          ) : (
            <div className="bg-gymflow-logo h-14 w-48 bg-contain bg-center bg-no-repeat"></div>
          )}
        </div>
        <div className="flex flex-col items-center justify-center text-center">
          <div className="dark:text-gray-25 text-xl font-bold text-gray-950">
            We&apos;re switching our software
          </div>
          <div className="text-base  font-medium text-gray-500 dark:text-gray-400">
            We&apos;re transferring your account information to our new software
            system.
          </div>
        </div>
      </div>
      <div className="bg-gray-0 flex-1 overflow-y-scroll bg-gray-50 pb-8 dark:bg-gray-950">
        <Switch>{getRoutes(migrationRoutes)}</Switch>
      </div>
      <HostedPagesFooter className="dark:border-t-gray-700 dark:text-gray-400" />
    </div>
  );
}

function MigrationWithClub() {
  return (
    <ApiResolverProvider userType="PUBLIC">
      {({ clubId }) => {
        return (
          <ClubSettingsAsPublicProvider clubId={clubId}>
            <ModalProvider>
              <Migration />
            </ModalProvider>
          </ClubSettingsAsPublicProvider>
        );
      }}
    </ApiResolverProvider>
  );
}

export { MigrationWithClub as Migration };
