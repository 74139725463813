import { useStatistic } from "@gymflow/api";
import { PARAMETER_DATE_ONLY_FORMAT } from "@gymflow/common";
import { cn } from "@gymflow/helpers";
import moment from "moment";
import { useEffect, useState } from "react";
import { Bar } from "react-chartjs-2";

import { useGetPreviousElements } from "../../hooks/useGetPreviousElements";
import { useClubSettings } from "../../providers";
import useGymflowModels from "../../store";
import { CalendarIcon, Spinner } from "../atoms";

export function TotalVisitsChart({ className }: { className?: string }) {
  const { api } = useGymflowModels();
  const { clubId } = useClubSettings();

  const getPreviousElements = useGetPreviousElements();
  const [visitData, setVisitData] = useState<number[]>([]);
  const currentMonth = new Date().getMonth(); // getMonth return [0, 11]
  const labels = getPreviousElements(MonthLabels, 12, currentMonth, false);

  const { data, isFetching } = useStatistic({
    api,
    clubId,
    start: moment()
      .subtract(12, "M")
      .startOf("month")
      .format(PARAMETER_DATE_ONLY_FORMAT),
    end: moment()
      .subtract(1, "M")
      .endOf("month")
      .format(PARAMETER_DATE_ONLY_FORMAT),
    categories: ["visit"],
    visitFields: ["total"],
  });

  useEffect(() => {
    if (!data?.visit?.total?.values) {
      return;
    }

    setVisitData(
      data.visit.total.values.map((v) => {
        return v.amount || 0;
      }),
    );
  }, [data]);

  const plugins = [
    {
      afterDatasetsDraw: function (chart: any, args: any) {
        const ctx = chart.ctx;
        const datasets = chart.data.datasets;
        const roundedBars = chart.config.options.roundedBars;
        if (!roundedBars) return;

        ctx.save();
        ctx.beginPath();
        datasets.forEach(function (dataset: any, datasetIndex: any) {
          const meta = chart.getDatasetMeta(datasetIndex);
          if (!meta.hidden) {
            const dataIndexHovered = chart?.tooltip?._active?.[0]?._index;
            meta.data.forEach(function (bar: any, index: any) {
              if (dataset.data[index] === 0) return;
              const radius = roundedBars;
              const x = bar._model.x - bar._model.width / 2 + 0.05;
              const y = bar._model.y - radius + 1;
              const width = bar._model.width - 0.1;
              const height = bar._model.height;

              ctx.beginPath();
              if (dataIndexHovered === index && dataset?.hoverBackgroundColor) {
                ctx.fillStyle = dataset.hoverBackgroundColor;
              } else {
                ctx.fillStyle = dataset.backgroundColor;
              }
              ctx.strokeWidth = 0;
              ctx.moveTo(x + radius, y);
              ctx.lineTo(x + width - radius, y);
              ctx.quadraticCurveTo(x + width, y, x + width, y + radius);
              ctx.lineTo(x + width, y + height - radius);
              ctx.quadraticCurveTo(
                x + width,
                y + height,
                x + width - radius,
                y + height,
              );
              ctx.lineTo(x + radius, y + height);
              ctx.quadraticCurveTo(x, y + height, x, y + height - radius);
              ctx.lineTo(x, y + radius);
              ctx.quadraticCurveTo(x, y, x + radius, y);
              ctx.closePath();
              ctx.fill();
            });
          }
        });
        ctx.restore();
      },
    },
  ];

  return (
    <div className={cn("relative flex w-full flex-col gap-4", className)}>
      <div className="flex justify-between">
        <div className="text-lg font-bold text-black">Total Visits</div>
        <div className="flex items-center justify-center gap-2">
          <CalendarIcon pathClassName="stroke-gray-500" />
          <div className="text-lg font-medium text-gray-500">
            Last 12 Months
          </div>
        </div>
      </div>
      <div className="bg-gray-0 relative rounded-xl border border-gray-200 p-6 !shadow">
        <div
          className={cn("absolute w-full pt-28", {
            hidden: !isFetching,
          })}
        >
          <Spinner className="!h-12 !w-12" />
        </div>
        <div
          className={cn("relative h-[20.5rem] w-full", {
            "opacity-25": isFetching,
          })}
        >
          <Bar
            data={{
              labels,
              datasets: [
                {
                  data: visitData,
                  backgroundColor: "#C7CDFF",
                  hoverBackgroundColor: "#333A78",
                },
              ],
            }}
            options={{
              responsive: true,
              maintainAspectRatio: false,
              roundedBars: 8,
              animation: false,
              legend: { display: false },
              scales: {
                xAxes: [
                  {
                    gridLines: { display: false },
                    ticks: {
                      fontColor: "#475467",
                      fontFamily: "Manrope",
                      fontSize: 12,
                      padding: 9,
                    },
                  },
                ],
                yAxes: [
                  {
                    gridLines: {
                      color: "#F2F4F7",
                      zeroLineColor: "#F2F4F7",
                      drawBorder: false,
                    },
                    ticks: {
                      fontColor: "#344054",
                      fontFamily: "Manrope",
                      fontSize: 12,
                      padding: 8,
                    },
                  },
                ],
              },
              tooltips: {
                fontFamily: "Manrope",
                titleAlign: "center",
                bodyAlign: "center",
                displayColors: false,
                callbacks: {
                  title: function (tooltipItem: any) {
                    return tooltipItem[0].xLabel;
                  },
                  label: (tooltipItem: any) => {
                    return `${tooltipItem.yLabel} Visits`;
                  },
                },
              },
            }}
            plugins={plugins}
          />
        </div>
      </div>
    </div>
  );
}

const MonthLabels = [
  "Jan",
  "Feb",
  "Mar",
  "Apr",
  "May",
  "Jun",
  "Jul",
  "Aug",
  "Sep",
  "Oct",
  "Nov",
  "Dec",
];
