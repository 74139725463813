import { ApiType } from "@gymflow/common/lib/api/ApiType";
import { useQuery } from "@tanstack/react-query";

import { memberQueryKeys } from "./memberQueryKeys";

type MemberCalculateScheduleDatesForChangingSubscriptionProps = {
  api: ApiType;
  subscriptionId: number;
};

export const memberCalculateScheduleDatesForChangingSubscriptionQueryFn =
  async ({
    api,
    subscriptionId,
  }: MemberCalculateScheduleDatesForChangingSubscriptionProps) => {
    const result = await api.memberApi.calculateScheduleDates(subscriptionId);

    return result.data;
  };

export const useQueryMemberCalculateScheduleDatesForChangingSubscription = ({
  api,
  subscriptionId,
  enabled,
}: MemberCalculateScheduleDatesForChangingSubscriptionProps & {
  enabled: boolean;
}) =>
  useQuery({
    queryKey: memberQueryKeys.calculateScheduleDates(subscriptionId),
    queryFn: async () => {
      const result = await api.memberApi.calculateScheduleDates(subscriptionId);
      return result.data;
    },
    enabled,
  });
