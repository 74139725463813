import { useFormikContext } from "formik";

import NotificationPreferences from "../base/NotificationPreferences";


type FormNotificationPreferencesProps<T> = {
  emailCommunicationFieldName: keyof T;
  smsCommunicationFieldName: keyof T;
};

export const FormNotificationPreferences = <T,>({
  emailCommunicationFieldName,
  smsCommunicationFieldName,
}: FormNotificationPreferencesProps<T>) => {
  const formik = useFormikContext<T>();
  return (
    <NotificationPreferences
      onChange={(checked) => {
        if (!checked) {
          formik.setFieldValue(emailCommunicationFieldName as string, false);
          formik.setFieldValue(smsCommunicationFieldName as string, false);
        }
      }}
      emailCommunicationValue={
        formik.values[emailCommunicationFieldName] as boolean
      }
      smsCommunicationValue={
        formik.values[smsCommunicationFieldName] as boolean
      }
      onEmailCommunicationChange={(checked) => {
        formik.setFieldValue(emailCommunicationFieldName as string, checked);
      }}
      onSmsCommunicationChange={(checked) => {
        formik.setFieldValue(smsCommunicationFieldName as string, checked);
      }}
    />
  );
};
