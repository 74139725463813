import { Iso2Country, LeadSourceDTO, UserFormRule } from "@gymflow/types";
import { validatePhoneNumber, validatePostCode } from "@gymflow/validation";
import { DateTime } from "luxon";
import { z, ZodType } from "zod";

function option<T extends ZodType>(isOptional: boolean, source: T) {
  if (isOptional) return source.optional();
  return source;
}

export const generateSchema = ({
  rules,
  postCodeFormat,
}: {
  rules?: UserFormRule;
  postCodeFormat: Iso2Country;
}) => {
  return z
    .object({
      email: z.string().trim().toLowerCase().email().max(50),
      password: z.string().min(4, "Password must have at least 4 characters."),
      confirmPassword: z
        .string()
        .min(4, "Password must have at least 4 characters."),
      firstName: z
        .string()
        .trim()
        .min(2, "First Name has to have at least 2 characters.")
        .max(50, "First Name cannot have more than 50 characters."),
      lastName: z
        .string()
        .trim()
        .min(2, "Last Name has to have at least 2 characters.")
        .max(50, "Last Name cannot have more than 50 characters."),
      mobileNumber: option(
        !rules?.mobileNumber.isRequired,
        z.string().min(1, {
          message: `Mobile number is invalid.`,
        }),
      ).refine(
        (e) => !rules?.mobileNumber.isRequired || validatePhoneNumber(e),
        {
          message: `Mobile number is invalid`,
        },
      ),

      dateBirth: option(!rules?.dateOfBirth.isRequired, z.date()).refine(
        (e) => {
          return e === undefined || DateTime.fromJSDate(e) < DateTime.now();
        },
        {
          message: "Date of birth can't be after today.",
        },
      ),
      postCode: option(!rules?.postCode.isRequired, z.string()).refine(
        (e) => e === undefined || validatePostCode(e, postCodeFormat),
        {
          message: `Invalid Post code`,
        },
      ),
      addressLine1: option(
        !rules?.addressLine.isRequired,
        z.string().trim().min(3).max(250),
      ),
      addressLine2: z.string().max(250).trim().optional(),
      city: option(!rules?.city.isRequired, z.string().trim().min(3)),
      gender: option(
        !rules?.gender.isRequired,
        z.object({
          value: z.enum(["MALE", "FEMALE", "PREFER_NOT_TO_SAY"]),
          id: z.enum(["MALE", "FEMALE", "PREFER_NOT_TO_SAY"]),
          label: z.string(),
        }),
      ),
      personalNumber: option(
        !rules?.personalNumber.isRequired,
        z.string().max(250).trim(),
      ),
      leadSource: z.custom<LeadSourceDTO>((e) => !!(e as LeadSourceDTO)?.id),
      emailCommunication: z.boolean().default(false),
      smsCommunication: z.boolean().default(false),
      pushCommunication: z.boolean().default(false),
      emergencyContactName: option(
        !rules?.emergencyContact.isRequired,
        z.string().min(3),
      ),
      emergencyContact: option(
        !rules?.emergencyContact.isRequired,
        z.string().max(100),
      ).refine(
        (e) => !rules?.emergencyContact.isRequired || validatePhoneNumber(e),
        {
          message: `Emergency Contact is invalid.`,
        },
      ),
      isClubWaiverAccepted: z
        .literal<boolean>(true, {
          errorMap: () => ({
            message: "You must accept the club waiver.",
          }),
        })
        .default(false),
    })
    .refine(
      (user) => {
        if (!rules?.mobileNumber.isRequired) return true;
        if (!rules?.emergencyContact.isRequired) return true;
        if (user["mobileNumber"] === user["emergencyContact"]) return false;
        return true;
      },
      {
        path: ["emergencyContact"],
        message:
          "Emergency contact cannot be the same as your regular contact.",
      },
    )
    .refine(
      (user) => {
        if (!rules?.emergencyContact.isRequired) return true;
        if (!user["emergencyContactName"] && !user["emergencyContact"])
          return true;
        if (!user["emergencyContactName"] && !!user["emergencyContact"])
          return false;
        return true;
      },
      {
        path: ["emergencyContactName"],
        message: "Emergency Contact Name is required.",
      },
    )
    .refine(
      (user) => {
        if (!rules?.emergencyContact.isRequired) return true;
        if (!user["emergencyContactName"] && !user["emergencyContact"])
          return true;
        if (!!user["emergencyContactName"] && !user["emergencyContact"])
          return false;
        return true;
      },
      {
        path: ["emergencyContact"],
        message: "Emergency contact is required.",
      },
    )
    .refine(
      (values) => {
        return values.password === values["confirmPassword"];
      },
      {
        path: ["password"],
        message: "Passwords must match!",
      },
    );
};

export type SignUpSchema = z.infer<ReturnType<typeof generateSchema>>;
export type SignUpSchemaKey = keyof SignUpSchema;
