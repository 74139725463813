import { RuleGroup, RuleGroupType } from "@gymflow/types";
import type { AxiosError } from "axios";
import { useTranslation } from "react-i18next";

import { pluralize } from "./string";

export type ClassRules = ReturnType<
  typeof rulesHelper.mapRuleGroupToClassRules
>;
export type AppointmentRules = ReturnType<
  typeof rulesHelper.mapRuleGroupToAppointmentRules
>;
export type MembershipRules = ReturnType<
  typeof rulesHelper.mapRuleGroupToMembershipRules
>;
export type AccessRules = ReturnType<
  typeof rulesHelper.mapRuleGroupToAccessRules
>;

export const rulesHelper = {
  mapRuleGroupToClassRules: (ruleGroup?: RuleGroup) => {
    const advancedBookingsRule = ruleGroup?.ruleClubList.find(
      (r) => r.ruleType === "BOOKING_LIMIT_WINDOW",
    )?.bookingRule;
    const bookingNoticeRule = ruleGroup?.ruleClubList.find(
      (r) => r.ruleType === "BOOKING_NOTICE_PERIOD",
    )?.bookingNoticePeriodRule;
    const concurrentBookingRule = ruleGroup?.ruleClubList.find(
      (r) => r.ruleType === "CONCURRENT_BOOKING",
    )?.concurrentBookingRule;
    const bookingCancellationRule = ruleGroup?.ruleClubList.find(
      (r) => r.ruleType === "BOOKING_CANCELLATION_WINDOW",
    )?.bookingCancellationRule;
    const bookingLimitRule = ruleGroup?.ruleClubList.find(
      (r) => r.ruleType === "BOOKING_LIMIT_PER_CUSTOMER",
    )?.bookingLimitRule;
    const noShowRule = ruleGroup?.ruleClubList.find(
      (r) => r.ruleType === "NO_SHOW_FEE",
    )?.noShowFeeRule;
    return {
      advancedBookingsRule,
      bookingNoticeRule,
      concurrentBookingRule,
      bookingCancellationRule,
      bookingLimitRule,
      noShowRule,
    } as const;
  },
  mapRuleGroupToAppointmentRules: (ruleGroup?: RuleGroup) => {
    const advancedBookingsRule = ruleGroup?.ruleClubList.find(
      (r) => r.ruleType === "BOOKING_LIMIT_WINDOW",
    )?.bookingRule;
    const bookingNoticeRule = ruleGroup?.ruleClubList.find(
      (r) => r.ruleType === "BOOKING_NOTICE_PERIOD",
    )?.bookingNoticePeriodRule;
    const rescheduleNoticeRule = ruleGroup?.ruleClubList.find(
      (r) => r.ruleType === "RESCHEDULE_NOTICE_PERIOD",
    )?.rescheduleNoticePeriodRule;
    const concurrentBookingRule = ruleGroup?.ruleClubList.find(
      (r) => r.ruleType === "CONCURRENT_BOOKING",
    )?.concurrentBookingRule;
    const bookingCancellationRule = ruleGroup?.ruleClubList.find(
      (r) => r.ruleType === "BOOKING_CANCELLATION_WINDOW",
    )?.bookingCancellationRule;
    const bookingLimitRule = ruleGroup?.ruleClubList.find(
      (r) => r.ruleType === "BOOKING_LIMIT_PER_CUSTOMER",
    )?.bookingLimitRule;
    const noShowRule = ruleGroup?.ruleClubList.find(
      (r) => r.ruleType === "NO_SHOW_FEE",
    )?.noShowFeeRule;
    return {
      advancedBookingsRule,
      bookingNoticeRule,
      concurrentBookingRule,
      bookingCancellationRule,
      bookingLimitRule,
      noShowRule,
      rescheduleNoticeRule,
    } as const;
  },
  mapRuleGroupToMembershipRules: (
    ruleGroup: RuleGroup,
    PORTAL_NEW_MEMBERSHIP_CHANGE_RULE?: boolean,
  ) => {
    const subscriptionCancellationRule = ruleGroup.ruleClubList.find(
      (r) => r.ruleType === "SUBSCRIPTION_CANCELLATION",
    )!.subscriptionCancellationRule!;

    if (PORTAL_NEW_MEMBERSHIP_CHANGE_RULE) {
      const subscriptionMembershipChangeRule = ruleGroup.ruleClubList.find(
        (r) => r.ruleType === "SUBSCRIPTION_MEMBERSHIP_CHANGE",
      )!.subscriptionMembershipChangeRule!;
      return {
        subscriptionCancellationRule,
        subscriptionMembershipChangeRule,
      } as const;
    }

    return { subscriptionCancellationRule } as const;
  },
  mapRuleGroupToAccessRules: (ruleGroup: RuleGroup) => {
    const checkoutRule = ruleGroup.ruleClubList.find(
      (r) => r.ruleType === "AUTOMATIC_CHECK_OUT",
    )!.checkOutRule!;
    return { checkoutRule } as const;
  },
  useParseRuleError: () => {
    const { t, i18n } = useTranslation();
    return (error: AxiosError, ruleGroup?: RuleGroup) => {
      if (!ruleGroup) return;
      if (
        (error.response?.data as any).error_message ===
          "User already has appointments at this time." ||
        (error.response?.data as any).error_message ===
          "User already has classes at this time."
      ) {
        return t("errorCodes.rule.class.concurrent-booking");
      }
      const errors = (error.response?.data as any).errors;
      if (!errors) return;
      const ruleError = errors
        ? Object.keys(errors).find((e) => i18n.exists(`errorCodes.${e}`))
        : undefined;
      if (!ruleError) return;
      const ruleErrorParts = ruleError?.split(".");
      const ruleErrorLastPart = ruleErrorParts?.[ruleErrorParts?.length - 1];
      const rules = ruleTypeToMapper[ruleGroup.groupType](ruleGroup!);
      const windowFrameTextFromRule = (rule?: {
        windowType: string;
        windowValue: string;
      }) => {
        return t(
          `errorCodes.helper.ruleWindows.${pluralize(
            (rule?.windowType ?? " ").slice(0, -1),
            rule?.windowType ?? "",
            Number(rule?.windowValue),
          )}`,
          { windowValue: rule?.windowValue },
        );
      };
      const interpolationMap = {
        "booking-limit-per-customer": {
          limit: rules.bookingLimitRule?.limitValue,
        },
        "booking-notice-period": {
          windowFrame: windowFrameTextFromRule(rules.bookingNoticeRule),
        },
        "booking-cancellation-window": {
          windowFrame: windowFrameTextFromRule(rules.bookingCancellationRule),
        },
        "booking-limit": {
          windowFrame: windowFrameTextFromRule(rules.advancedBookingsRule),
        },
        "reschedule-notice-period": {
          windowFrame: windowFrameTextFromRule(rules.rescheduleNoticeRule),
        },
        "cancellation-minimum-notice-period": {
          billingPeriod: t(
            `errorCodes.rule.subscription.helper.${pluralize(
              "billingPeriod",
              "billingPeriods",
              rules.subscriptionCancellationRule?.minimumPeriodNotice ?? 0,
            )}`,
            {
              billingPeriod:
                rules.subscriptionCancellationRule?.minimumPeriodNotice,
            },
          ),
        },
      };
      return t(`errorCodes.${ruleError}`, {
        ...interpolationMap[
          ruleErrorLastPart! as keyof typeof interpolationMap
        ],
      });
    };
  },
};

const ruleTypeToMapper = {
  ACCESS_GROUP: rulesHelper.mapRuleGroupToAccessRules,
  APPOINTABLE_GROUP: rulesHelper.mapRuleGroupToAppointmentRules,
  CLASS_GROUP: rulesHelper.mapRuleGroupToClassRules,
  MEMBERSHIP_GROUP: rulesHelper.mapRuleGroupToMembershipRules,
} as Record<
  RuleGroupType,
  (
    ruleGroup: RuleGroup,
  ) => Partial<
    ReturnType<typeof rulesHelper.mapRuleGroupToClassRules> &
      ReturnType<typeof rulesHelper.mapRuleGroupToAppointmentRules> &
      ReturnType<typeof rulesHelper.mapRuleGroupToAccessRules> &
      ReturnType<typeof rulesHelper.mapRuleGroupToMembershipRules>
  >
>;
