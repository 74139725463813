type PMGooglePayIconProps = {
  className?: string;
};

const PMGooglePayIcon = ({ className }: PMGooglePayIconProps) => (
  <svg
    width="34"
    height="24"
    viewBox="0 0 34 24"
    fill="none"
    xmlns="http://www.w3.org/2000/svg"
    className={className}
  >
    <path
      d="M0.5 4C0.5 2.067 2.067 0.5 4 0.5H30C31.933 0.5 33.5 2.067 33.5 4V20C33.5 21.933 31.933 23.5 30 23.5H4C2.067 23.5 0.5 21.933 0.5 20V4Z"
      fill="white"
    />
    <path
      d="M0.5 4C0.5 2.067 2.067 0.5 4 0.5H30C31.933 0.5 33.5 2.067 33.5 4V20C33.5 21.933 31.933 23.5 30 23.5H4C2.067 23.5 0.5 21.933 0.5 20V4Z"
      stroke="#E4E7EC"
    />
    <path
      fillRule="evenodd"
      clipRule="evenodd"
      d="M16.0579 15.5283V12.5937H17.5725C18.1931 12.5937 18.717 12.3857 19.1441 11.9755L19.2466 11.8716C20.0266 11.0224 19.9754 9.69945 19.1441 8.91379C18.7284 8.49786 18.159 8.27256 17.5725 8.28411H15.1411V15.5283H16.0579ZM16.058 11.704V9.1737H17.5956C17.9259 9.1737 18.2391 9.30079 18.4726 9.53186C18.9681 10.0171 18.9795 10.8259 18.5011 11.3285C18.2676 11.5769 17.9373 11.7155 17.5956 11.704H16.058ZM23.5228 10.9588C23.1299 10.5948 22.5947 10.41 21.9171 10.41C21.0459 10.41 20.3911 10.7335 19.9583 11.3747L20.7669 11.8889C21.063 11.4498 21.4673 11.2303 21.9797 11.2303C22.3043 11.2303 22.6175 11.3516 22.8623 11.5711C23.1015 11.7791 23.2381 12.0795 23.2381 12.3972V12.611C22.8851 12.4146 22.441 12.3106 21.8943 12.3106C21.2566 12.3106 20.7441 12.4608 20.3626 12.7669C19.9811 13.0731 19.7875 13.4775 19.7875 13.9916C19.7761 14.4596 19.9754 14.9044 20.3285 15.2048C20.6872 15.5283 21.1427 15.69 21.6779 15.69C22.31 15.69 22.8111 15.407 23.1926 14.8408H23.2324V15.5283H24.1093V12.4723C24.1093 11.8311 23.9157 11.3227 23.5228 10.9588ZM21.0347 14.6156C20.8467 14.4769 20.7328 14.2516 20.7328 14.009C20.7328 13.7375 20.8581 13.5122 21.103 13.3331C21.3536 13.154 21.6668 13.0616 22.037 13.0616C22.5495 13.0558 22.9482 13.1713 23.2329 13.4024C23.2329 13.7952 23.0792 14.1361 22.7773 14.4249C22.504 14.7022 22.1338 14.8582 21.7465 14.8582C21.4903 14.864 21.2397 14.7773 21.0347 14.6156ZM26.0795 17.7062L29.1429 10.5717H28.1464L26.7286 14.1303H26.7115L25.2595 10.5717H24.2631L26.2731 15.2106L25.1343 17.7062H26.0795Z"
      fill="#3C4043"
    />
    <path
      d="M12.8929 11.9582C12.8929 11.6751 12.8701 11.392 12.8245 11.1147H8.95825V12.7149H11.1733C11.0821 13.2291 10.7861 13.6912 10.3533 13.9801V15.0199H11.6743C12.4487 14.2978 12.8929 13.2291 12.8929 11.9582Z"
      fill="#4285F4"
    />
    <path
      d="M8.95842 16.0252C10.0631 16.0252 10.9969 15.6555 11.6745 15.0201L10.3535 13.9802C9.98335 14.2344 9.51074 14.3788 8.95842 14.3788C7.88793 14.3788 6.98257 13.6452 6.65801 12.6631H5.29712V13.7376C5.9918 15.1414 7.40962 16.0252 8.95842 16.0252Z"
      fill="#34A853"
    />
    <path
      d="M6.65822 12.6626C6.48737 12.1485 6.48737 11.5882 6.65822 11.0682V9.99951H5.29712C4.71053 11.1722 4.71053 12.5587 5.29712 13.7314L6.65822 12.6626Z"
      fill="#FBBC04"
    />
    <path
      d="M8.95842 9.35271C9.54491 9.34116 10.1086 9.56646 10.53 9.97662L11.703 8.78658C10.957 8.0818 9.97766 7.69475 8.95842 7.70631C7.40962 7.70631 5.9918 8.59594 5.29712 9.99972L6.65801 11.0742C6.98257 10.0864 7.88793 9.35271 8.95842 9.35271Z"
      fill="#EA4335"
    />
  </svg>
);

export default PMGooglePayIcon;
