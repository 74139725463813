import { usePortalRoutes } from "apps/portal/src/hooks";
import { RouteFeature } from "apps/portal/src/routes";

import useRedirectIfNotAuthenticated from "../useRedirectIfNotAuthenticated";
import { HostedPagesAppsBanner } from "./components/HostedPagesAppsBanner";
import { EditMemberSidebarProvider } from "./components/HostedPagesEditProfile/EditMemberSidebarProvider";
import { HostedPagesProfileGetMembership } from "./components/HostedPagesProfileGetMembership";
import { HostedPagesProfileMenu } from "./components/HostedPagesProfileMenu";
import { HostedPagesProfileUserBlock } from "./components/HostedPagesProfileUserBlock";

export const HostedPagesProfile = () => {
  const { createSiteOrEmbedLink } = usePortalRoutes();
  useRedirectIfNotAuthenticated(
    createSiteOrEmbedLink(
      RouteFeature.SiteBuyMembershipType.replace(":membershipType", "all"),
    ),
  );

  return (
    <EditMemberSidebarProvider>
      <div className="flex h-full max-h-full flex-col overflow-auto bg-gray-50 dark:bg-gray-800">
        <div className="track-height flex flex-col justify-center gap-3  bg-gray-50 p-4 dark:bg-gray-800 lg:flex-row lg:gap-4 lg:p-6 lg:px-28">
          <div className="flex h-fit flex-col gap-2 lg:max-w-lg lg:flex-1">
            <HostedPagesProfileUserBlock />
            {/* add additional logic for showing HostedPagesProfileGetMembership (in the next task) */}
            <HostedPagesProfileGetMembership />
            <HostedPagesAppsBanner />
          </div>
          <HostedPagesProfileMenu />
        </div>
      </div>
    </EditMemberSidebarProvider>
  );
};
