import * as Yup from "yup";

import { YupNumberBuilder } from "../../../helpers/yupBuilders";

export const NAME = "name";
export const DESCRIPTION = "description";
export const PRICE = "price";
export const CREDITS = "sessions-included";
export const UNLIMITED_CREDITS = "sessions-unlimited";
export const CREDIT_EXPIRY_INTERVAL = "expiry-type";
export const CREDIT_EXPIRY_VALUE = "expiry-value";
export const CREDIT_EXPIRE = "credit-expire";
export const TERMS_CONDITIONS = "terms-conditions";
export const IS_PUBLIC = "is-public";
export const MEMBERSHIP_INCLUSION_ONLY = "membership-inclusion-only";
export const CLASS_CATEGORIES = "activity-category-id-list";
export const APPOINTABLE_CATEGORIES = "appointable-category-id-list";

export const createSessionPackSchema = () =>
  Yup.object().shape({
    [NAME]: Yup.string().required().min(3),
    [DESCRIPTION]: Yup.string(),
    [PRICE]: Yup.number().required(),
    [CREDITS]: new YupNumberBuilder()
      .isRequiredIf(
        Yup.ref(UNLIMITED_CREDITS),
        "Credits is required if it is not unlimited.",
        false,
      )
      .build(),
    [UNLIMITED_CREDITS]: Yup.boolean().required().default(false),
    [CREDIT_EXPIRY_INTERVAL]: Yup.string().required(),
    [CREDIT_EXPIRY_VALUE]: Yup.string().nullable(),
    [CREDIT_EXPIRE]: Yup.bool().default(false).required(),
    [IS_PUBLIC]: Yup.bool().required().default(false),
    [MEMBERSHIP_INCLUSION_ONLY]: Yup.bool().required().default(false),
    [CLASS_CATEGORIES]: Yup.array()
      .default([])
      .min(0, "At least one activity category must be selected.")
      .required(),
    [APPOINTABLE_CATEGORIES]: Yup.array()
      .default([])
      .min(0, "At least one activity category must be selected.")
      .required()
      .test(
        "missing-category",
        "At least one activity/appointment category must be selected.",
        (value, { parent }) =>
          !(value.length === 0 && parent[CLASS_CATEGORIES].length === 0),
      ),

    [TERMS_CONDITIONS]: Yup.mixed()
      .test("is-file", "File is required.", (value) => value && value.name)
      .required()
      .default(null),
    "for-members-only": Yup.bool().default(false),
  });
