import { ApiType } from "@gymflow/common/lib/api/ApiType";
import { zonedTimeToUtc } from "@gymflow/helpers";
import { useMutation, useQueryClient } from "@tanstack/react-query";

import { appointmentAsMemberQueryKeys } from "./appointmentAsMemberQueryKeys";

/*** @deprecated - use useMutationAppointmentCreateAsMember instead */
export function useAppointmentCreateAsMember({
  api,
  tz,
}: {
  api: ApiType;
  tz?: string;
}) {
  const queryClient = useQueryClient();
  const mutation = useMutation({
    mutationFn: async (
      newAppointment: Parameters<
        ApiType["customerAppointmentApi"]["create"]
      >[0],
    ) => {
      const response = await api.customerAppointmentApi.create({
        ...newAppointment,
        startDate: zonedTimeToUtc(newAppointment.startDate, tz!),
      });
      return response.data;
    },
    onSuccess: () => {
      queryClient.invalidateQueries({
        queryKey: appointmentAsMemberQueryKeys.all(),
      });
    },
  });

  return mutation;
}
