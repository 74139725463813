import { useAutoAnimate } from "@formkit/auto-animate/react";
import {
  useQueryCreditPackListAsMember,
  useQueryCreditPackListAsPublic,
} from "@gymflow/api";
import { cn } from "@gymflow/helpers";
import { usePortalRoutes } from "apps/portal/src/hooks";
import { useAuthenticatedUser } from "apps/portal/src/providers";
import mapTorTArrayToTArray from "libs/helpers/src/lib/mapTorTArrayToTArray";
import { pick } from "lodash";
import QueryString from "qs";
import React, { useEffect, useMemo, useState } from "react";
import { useTranslation } from "react-i18next";
import { useParams } from "react-router";

import useGymflowModels from "../../../store";
import { Button, Spinner } from "../../atoms";
import { EmptyPage } from "../../atoms/EmptyPage";
import HostedPagesProfileAvatar from "../components/HostedPagesProfileAvatar";
import { SortPopover, SortType } from "../SortPopover";
import { HostedPagesCreditPackCard } from "./components/HostedPagesCreditPackCard";
import {
  CreditPackType,
  HostedPagesCreditPackFilterPopover,
  HostedPagesCreditPackFilterPopoverProps,
} from "./components/HostedPagesCreditPackFilterPopover";
import { useCreditPackAvailableTabs } from "./components/useCreditPackAvailableTabs";

export type HostedPagesCreditPackListProps = {};

export const HostedPagesCreditPackList: React.FC<
  HostedPagesCreditPackListProps
> = ({}) => {
  const { api } = useGymflowModels();
  const { isEmbed } = usePortalRoutes();
  const auth = useAuthenticatedUser();
  const { t } = useTranslation();

  // pick is used because some links will have custom tracking query params like fbclid
  const urlFilters = pick(
    QueryString.parse(window.location.search, {
      ignoreQueryPrefix: true,
    }) as {
      sessionPacks?: string[] | string;
      type?: string[] | string;
    },
    ["sessionPacks", "type"],
  );
  const hasCustomFilters = Object.keys(urlFilters).length > 0;
  const [filter, setFilter] = useState<
    HostedPagesCreditPackFilterPopoverProps["filter"]
  >({
    appointableCategories: [],
    classCategories: [],
  });
  const { sessionPackType } = useParams<{
    sessionPackType: "classes" | "appointments" | "all";
  }>();
  const [sort, setSort] = useState<SortType | undefined>("ASC");
  const [currentPacks, setCurrentPacks] = useState<CreditPackType>(
    (
      {
        classes: "CLASSES",
        appointments: "APPOINTMENTS",
        all: "CLASSES",
      } as const
    )[sessionPackType],
  );
  const creditPackListQueryOptions = {
    api,
    opts: {
      ...(currentPacks === "CLASSES"
        ? {
            hasActivityCategory: true,
          }
        : {}),
      ...(currentPacks === "APPOINTMENTS"
        ? {
            hasAppointableCategory: true,
          }
        : {}),
      status: "ACTIVE",
      size: 200,
      page: 0,
    },
  } as const;
  const queryAsPublic = useQueryCreditPackListAsPublic(
    creditPackListQueryOptions,
    { enabled: !auth.id },
  );
  const queryAsMember = useQueryCreditPackListAsMember(
    creditPackListQueryOptions,
    { enabled: !!auth.id },
  );
  const { data } = auth.id ? queryAsMember : queryAsPublic;
  const filteredSortedPacks = useMemo(() => {
    return data?.content
      .filter((e) => {
        if (!hasCustomFilters) {
          if (currentPacks === "APPOINTMENTS") {
            return (
              filter.appointableCategories.length === 0 ||
              e.appointableCategoryIdList.some((id) =>
                filter.appointableCategories.includes(id),
              )
            );
          } else {
            return (
              filter.classCategories.length === 0 ||
              e.activityCategoryIdList.some((id) =>
                filter.classCategories.includes(id),
              )
            );
          }
        } else if (currentPacks === "CLASSES") {
          const localFilter = {
            activityCategories: mapTorTArrayToTArray(urlFilters?.type)?.map(
              Number,
            ),
            ids: mapTorTArrayToTArray(urlFilters?.sessionPacks)?.map(Number),
          };
          return (
            e.activityCategoryIdList.some((id) =>
              localFilter.activityCategories?.includes(id),
            ) || localFilter.ids?.includes(e.id)
          );
        }
        return true;
      })
      .sort((a, b) => {
        if (!sort) return 0;
        if (sort === "ASC") {
          return a.price - b.price;
        } else {
          return b.price - a.price;
        }
      });
  }, [currentPacks, data?.content, filter, hasCustomFilters, sort, urlFilters]);

  const tabs = useCreditPackAvailableTabs();
  useEffect(() => {
    if (tabs.length === 1 && sessionPackType === "all") {
      setCurrentPacks(tabs[0]);
    }
  }, [sessionPackType, tabs]);
  const [parent] = useAutoAnimate();
  return (
    <div className="flex h-full max-h-full flex-col bg-gray-50 dark:bg-gray-800">
      <div className="track-height bg-gray-0 pb-18 flex flex-col gap-4 border-b border-gray-300 p-4 dark:border-gray-700 dark:bg-gray-950 lg:px-28">
        <div className="flex flex-row flex-wrap items-center justify-between gap-2">
          <div className="flex flex-row items-center gap-4">
            {isEmbed && auth.id ? (
              <HostedPagesProfileAvatar />
            ) : (
              <div className="dark:text-gray-0 text-xl font-medium text-gray-950">
                {t("page.hostedPagesCredits.title")}
              </div>
            )}

            {!hasCustomFilters &&
              sessionPackType === "all" &&
              tabs.length > 1 && (
                <div className="hidden flex-row items-center gap-2 lg:flex">
                  <Button
                    size="small"
                    className={cn("text-sm transition-all", {
                      "!border-secondary-500 !text-secondary-500":
                        currentPacks === "CLASSES",
                    })}
                    onClick={() => setCurrentPacks("CLASSES")}
                  >
                    Classes
                  </Button>
                  <Button
                    size="small"
                    className={cn("text-sm transition-all", {
                      "!border-secondary-500 !text-secondary-500":
                        currentPacks === "APPOINTMENTS",
                    })}
                    onClick={() => setCurrentPacks("APPOINTMENTS")}
                  >
                    Appointments
                  </Button>
                </div>
              )}
          </div>
          <div className="flex flex-row gap-2">
            <SortPopover onChange={setSort} value={sort} />
            {!hasCustomFilters && (
              <HostedPagesCreditPackFilterPopover
                filter={filter}
                onChange={setFilter}
                type={currentPacks}
              />
            )}
          </div>
        </div>
        {!hasCustomFilters && sessionPackType === "all" && tabs.length > 1 && (
          <div className="flex flex-row gap-2 lg:hidden">
            <Button
              size="small"
              className={cn("text-sm transition-all flex-1", {
                "!border-secondary-500 !text-secondary-500":
                  currentPacks === "CLASSES",
              })}
              onClick={() => setCurrentPacks("CLASSES")}
            >
              Classes
            </Button>
            <Button
              size="small"
              className={cn("text-sm transition-all flex-1", {
                "!border-secondary-500 !text-secondary-500":
                  currentPacks === "APPOINTMENTS",
              })}
              onClick={() => setCurrentPacks("APPOINTMENTS")}
            >
              Appointments
            </Button>
          </div>
        )}
      </div>

      <div
        ref={parent}
        className="track-height flex min-h-[16rem] flex-col gap-4 overflow-y-auto overflow-x-hidden bg-gray-50 p-4 dark:bg-gray-800 lg:px-28 lg:pt-6"
      >
        {filteredSortedPacks === undefined && (
          <div className="absolute inset-0 flex items-center justify-center">
            <Spinner className="h-12 w-12" />
          </div>
        )}
        {filteredSortedPacks?.length === 0 && (
          <div className="absolute inset-0 flex items-center justify-center">
            <EmptyPage
              action={
                (currentPacks === "APPOINTMENTS" &&
                  filter.appointableCategories.length > 0) ||
                (currentPacks === "CLASSES" &&
                  filter.classCategories.length > 0) ? (
                  <Button
                    onClick={() => {
                      setFilter({
                        appointableCategories: [],
                        classCategories: [],
                      });
                    }}
                  >
                    Clear Filters
                  </Button>
                ) : undefined
              }
            />
          </div>
        )}
        {filteredSortedPacks && (
          <div
            className="relative grid content-start gap-3 pb-12 lg:grid-cols-3 lg:gap-x-6 lg:gap-y-8"
            ref={parent}
          >
            {filteredSortedPacks?.map((e) => (
              <HostedPagesCreditPackCard key={e.id} creditPack={e} />
            ))}
          </div>
        )}
      </div>
    </div>
  );
};
