import { useStatistic } from "@gymflow/api";
import { PARAMETER_DATE_ONLY_FORMAT } from "@gymflow/common";
import classNames from "classnames";
import moment from "moment";
import { useEffect, useState } from "react";

import { useGetPreviousElements } from "../../hooks/useGetPreviousElements";
import { useClubSettings } from "../../providers";
import useGymflowModels from "../../store";
import { Spinner } from "../atoms";
import MultiBarChart from "../MultiBarChart";

export function UserSummaryChart() {
  const { api } = useGymflowModels();
  const settings = useClubSettings();

  const getPreviousElements = useGetPreviousElements();
  const [userSummaryData, setUserSummaryData] = useState<number[][]>([]);
  const currentMonth = new Date().getMonth(); // getMonth return [0, 11]
  const userSummaryLabels = getPreviousElements(
    MonthLabels,
    12,
    currentMonth,
    false,
  );

  const parseDataValues = (data: any = []) => data.map((d: any) => d.amount);

  const { data, isFetching } = useStatistic({
    api,
    clubId: settings.clubId,
    start: moment()
      .subtract(12, "M")
      .startOf("month")
      .format(PARAMETER_DATE_ONLY_FORMAT),
    end: moment()
      .subtract(1, "M")
      .endOf("month")
      .format(PARAMETER_DATE_ONLY_FORMAT),
    categories: ["membership"],
    membershipFields: ["active", "paused", "overdue", "pending", "total"],
  });

  useEffect(() => {
    if (!data?.membership) {
      return;
    }
    setUserSummaryData([
      parseDataValues(data.membership.pending!.values),
      parseDataValues(data.membership.paused!.values),
      parseDataValues(data.membership.overdue!.values),
      parseDataValues(data.membership.active!.values),
      parseDataValues(data.membership.total!.values),
    ]);
  }, [data]);

  return (
    <>
      <div className="flex flex-col">
        <div className="text-lg font-semibold text-gray-900">User Summary</div>
        <div className="text-sm font-normal text-gray-600">
          Last 12 months&apos; membership trends by membership status.
        </div>
      </div>
      <div className="bg-gray-0 relative mt-4 rounded-xl border border-gray-200 p-6 !shadow">
        <div
          className={classNames("absolute w-full pt-28", {
            hidden: !isFetching,
          })}
        >
          <Spinner className="!h-12 !w-12" />
        </div>
        <div className={classNames({ "opacity-25": isFetching })}>
          <MultiBarChart
            xLabel={"Month"}
            yLabel={"Total Memberships"}
            xAxisLabels={userSummaryLabels}
            data={{
              labels: ["Pending", "Paused", "Overdue", "Active", "Total"],
              values: userSummaryData,
            }}
            height={300}
          />
        </div>
      </div>
    </>
  );
}

const MonthLabels = [
  "Jan",
  "Feb",
  "Mar",
  "Apr",
  "May",
  "Jun",
  "Jul",
  "Aug",
  "Sep",
  "Oct",
  "Nov",
  "Dec",
];
