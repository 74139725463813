import { faClose } from "@fortawesome/free-solid-svg-icons";
import { FontAwesomeIcon } from "@fortawesome/react-fontawesome";
import { hasStaffRole } from "@gymflow/api";
import { cn, promiseState } from "@gymflow/helpers";
import { Button } from "apps/portal/src/components/atoms";
import HostedPagesOverlay from "apps/portal/src/components/site/components/HostedPagesOverlay";
import { ReactNode, useCallback, useEffect, useRef, useState } from "react";

import { useAuthenticatedUser } from "../AuthenticatedProvider";
import {
  LoginOverlayContext,
  OverlayContextType,
  OverlayState,
} from "./context";
import { LoginForm } from "./LoginForm";
import { TrySignUpOverlay } from "./TrySignUpOverlay";

export function LoginOverlayProvider({
  children,
}: {
  children: ((overlayContext: OverlayContextType) => ReactNode) | ReactNode;
}) {
  const auth = useAuthenticatedUser();
  const openPromiseRef = useRef(Promise.resolve({ isLoggedIn: false }));
  const openPromiseResolveRef = useRef<any>();

  const getOpenPromiseResolve = useCallback(async () => {
    const state = await promiseState(openPromiseRef.current);

    if (state !== "pending") {
      openPromiseRef.current = new Promise((resolve) => {
        openPromiseResolveRef.current = resolve;
      });
    }

    return openPromiseResolveRef.current;
  }, []);

  const [defaultEmail, setDefaultEmail] = useState("");
  const [overlayState, setOverlayState] = useState<OverlayState>("CLOSED");
  const showLoginOverlay = useCallback(
    async (email?: string) => {
      await getOpenPromiseResolve();
      if (email) {
        setDefaultEmail(email);
      }
      setOverlayState("LOGIN");
      return openPromiseRef.current;
    },
    [getOpenPromiseResolve],
  );
  const showSignUpOverlay = useCallback(
    async (skipEmailCheck?: boolean) => {
      await getOpenPromiseResolve();
      setOverlayState(skipEmailCheck ? "SIGNUP_FORM" : "SIGNUP_CHECK_EMAIL");
      return openPromiseRef.current;
    },
    [getOpenPromiseResolve],
  );
  const closeOverlay = useCallback(
    ({ isLoggedIn }: { isLoggedIn: boolean }) => {
      setOverlayState("CLOSED");
      openPromiseResolveRef.current({ isLoggedIn });
    },
    [],
  );

  useEffect(() => {
    if (hasStaffRole(auth.roles)) {
      auth.logout();
    }
  }, []);

  return (
    <LoginOverlayContext.Provider
      value={{
        showLoginOverlay,
        showSignUpOverlay,
        closeOverlay,
      }}
    >
      <Overlay
        isOpen={overlayState !== "CLOSED"}
        hide={() => {
          closeOverlay({ isLoggedIn: false });
        }}
        overlayState={overlayState}
        defaultEmail={defaultEmail}
      />
      {typeof children === "function"
        ? children({ showLoginOverlay, showSignUpOverlay, closeOverlay })
        : children}
    </LoginOverlayContext.Provider>
  );
}

function Overlay({
  isOpen,
  hide,
  overlayState,
  defaultEmail,
}: {
  isOpen?: boolean;
  hide: () => void;
  overlayState: OverlayState;
  defaultEmail: string;
}) {
  return (
    <HostedPagesOverlay isOpen={!!isOpen}>
      <div className="flex w-full items-center justify-end p-2">
        <Button
          onClick={() => {
            hide();
          }}
          className="aspect-square h-10 w-10"
          intent="transparent"
        >
          <FontAwesomeIcon
            className="cursor-pointer text-xl text-gray-600"
            icon={faClose}
          />
        </Button>
      </div>
      <div
        className={cn(
          "flex h-full w-full max-w-[calc(100vw-2rem)] justify-center overflow-y-auto",
          {
            "track-height-overlay": isOpen,
          },
        )}
      >
        {overlayState === "SIGNUP_CHECK_EMAIL" && (
          <TrySignUpOverlay defaultEmail={defaultEmail} />
        )}
        {overlayState === "SIGNUP_FORM" && (
          <TrySignUpOverlay
            defaultEmail={defaultEmail}
            defaultScreen="SIGN_UP_FORM"
          />
        )}
        {overlayState === "LOGIN" && <LoginForm defaultEmail={defaultEmail} />}
      </div>
    </HostedPagesOverlay>
  );
}
