import {
  ActivityStatus,
  ApiListResponse,
  AppointableCategoryDTO,
  AppointableDTO,
  AppointableDtoStatus,
  PaginationOptions,
} from "@gymflow/types";
import { AxiosInstance } from "axios";

import { createSortParam } from "../createSortParam";

export type CustomerAppointableApiType = ReturnType<
  typeof customerAppointableApi
>;
const customerAppointableApi = (
  axiosInstance: AxiosInstance,
  clubId: number,
) => {
  const clubPrefix = `club/${clubId}/`;

  return {
    getList({
      page = 0,
      limit = 10,
      extraParams = {},
      sort,
    }: {
      extraParams?: {
        id?: number[];
        status?: AppointableDtoStatus;
        "appointableCategory.id"?: number[];
        allowOnlineBookings?: boolean;
        allowAppBookings?: boolean;
      };
      page?: number;
      limit?: number;
      sort?: {
        field: string;
        desc?: boolean;
      };
    }) {
      const config = {
        params: {
          page,
          size: limit,
          ...extraParams,
        },
      };
      if (sort) {
        (config.params as any).sort = createSortParam(sort);
      }

      return axiosInstance.get<ApiListResponse<AppointableDTO>>(
        `${clubPrefix}customer/appointable`,
        config,
      );
    },
    fetchCategories(
      params: PaginationOptions & { statusList?: ActivityStatus[] },
    ) {
      return axiosInstance.put<ApiListResponse<AppointableCategoryDTO>>(
        `${clubPrefix}customer/appointable/category/search`,
        params,
      );
    },
  };
};

export default customerAppointableApi;
