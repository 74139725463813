export function CodeIcon({
  className,
  pathClassName,
}: {
  className?: string;
  pathClassName?: string;
}) {
  return (
    <svg
      xmlns="http://www.w3.org/2000/svg"
      width="20"
      height="20"
      viewBox="0 0 20 20"
      fill="none"
    >
      <path
        d="M14.1667 14.1667L18.3334 10L14.1667 5.83333M5.83341 5.83333L1.66675 10L5.83341 14.1667M11.6667 2.5L8.33342 17.5"
        stroke="#0C111D"
        strokeWidth="1.66667"
        strokeLinecap="round"
        strokeLinejoin="round"
      />
    </svg>
  );
}
