import {
  useMemberSubscription,
  useQueryMemberCalculateProRataForChangingSubscriptionNew,
  useQueryMemberPaymentMethodListNew,
} from "@gymflow/api";
import { formatCurrency } from "@gymflow/helpers";
import useScaModal from "apps/portal/src/hooks/useScaModal";
import { useClubSettings } from "apps/portal/src/providers";
import { ToastContext } from "apps/portal/src/providers/ToastProvider/context";
import useGymflowModels from "apps/portal/src/store";
import { isAxiosError } from "axios";
import { FormikProvider, useFormik } from "formik";
import { useContext } from "react";
import { useTranslation } from "react-i18next";

import { FormCheckboxInput, FormLabeledSwitch } from "../../../../atoms";
import { ConfirmModal } from "../../../../templates";
import { BillingDateChangeWarning } from "./components/BillingDateChangeWarning";
import { DescriptionBlock } from "./components/DescriptionBlock";
import { FormNewMembershipSelect } from "./components/FormNewMembershipSelect";
import { FormScheduledDateSelect } from "./components/FormScheduledDateSelect";
import { InfoBlock } from "./components/InfoBlock";
import { WarningBlock } from "./components/WarningBlock";
import {
  changeMembershipFormSchema,
  INITIAL_VALUES_CHANGE_MEMBERSHIP_FORM,
} from "./constants";
import {
  ChangeMembershipFormValues,
  ChangeMembershipModalNewProps,
} from "./types";

export const ChangeMembershipModalNew = ({
  memberId,
  activeMembership,
  linkedMembers,
  hide,
}: ChangeMembershipModalNewProps) => {
  const { t } = useTranslation();
  const { timezone, clubId, defaultCurrency } = useClubSettings();
  const { api } = useGymflowModels();
  const { notifyDanger } = useContext(ToastContext);
  const showScaModal = useScaModal({
    asMember: false,
  });

  const { data: paymentMethods } = useQueryMemberPaymentMethodListNew({
    api,
    clubId,
    memberId,
    enabled: true,
  });

  const {
    changeSubscriptionPaymentProRataMutation,
    changeSubscriptionPaymentWaivedMutation,
    changeSubscriptionScheduleMutation,
  } = useMemberSubscription(
    {
      api,
      tz: timezone,
    },
    {
      onError: (error) => {
        if (isAxiosError(error)) {
          notifyDanger(error);
        }
      },
    },
  );

  const subscriptionId = activeMembership.id;

  const formik = useFormik<ChangeMembershipFormValues>({
    initialValues: INITIAL_VALUES_CHANGE_MEMBERSHIP_FORM,
    validationSchema: changeMembershipFormSchema,
    onSubmit: async ({
      newMembership,
      isChangeImmediately,
      isCollectUpgradeCost,
      date,
    }) => {
      const defaultPaymentMethod = paymentMethods?.find(
        (pm) => pm.defaultPaymentMethod,
      );

      if (!defaultPaymentMethod) {
        notifyDanger(
          t(
            "page.userMember.tab.userMemberAccount.userMemberMembership.membershipCard.modal.changeMembershipModal.toast.notFoundDefaultPayment",
          ),
        );
        return;
      }

      const newMembershipId = newMembership?.value.id;

      if (!newMembershipId) return;

      if (isChangeImmediately) {
        // FYI: in case if subscription downgraded -> it's not necessary send request from pro-rata or waived
        if (isCollectUpgradeCost) {
          const { data } =
            await changeSubscriptionPaymentProRataMutation.mutateAsync({
              subscriptionId,
              newMembershipId,
              memberId,
            });
          await showScaModal({
            invoiceNumber: data?.invoiceNumber,
            userMemberId: memberId,
          });
        } else {
          await changeSubscriptionPaymentWaivedMutation.mutateAsync({
            subscriptionId,
            newMembershipId,
            memberId,
          });
        }
      } else if (!isChangeImmediately && date) {
        await changeSubscriptionScheduleMutation.mutateAsync({
          subscriptionId,
          newMembershipId,
          changeDate: date.value,
          memberId,
        });
      }
      hide();
    },
  });

  const {
    values: { isChangeImmediately, newMembership },
  } = formik;

  const newMembershipId = newMembership?.value.id;

  const { data: calculateProRataData } =
    useQueryMemberCalculateProRataForChangingSubscriptionNew(
      {
        api,
        membershipId: newMembershipId ?? 0,
        subscriptionId,
      },
      { enabled: !!newMembershipId && !!subscriptionId && isChangeImmediately },
    );

  const prorata = calculateProRataData?.price;

  const isProRataForChangingSubscription =
    prorata !== null && prorata !== undefined && prorata > 0;

  return (
    <ConfirmModal
      title={t(
        "page.userMember.tab.userMemberAccount.userMemberMembership.membershipCard.modal.changeMembershipModal.title",
      )}
      wrapperClassName="!max-w-[28.75rem]"
      showCloseButton
      onHide={() => hide()}
      onConfirm={async () => await formik.submitForm()}
      onCancel={() => hide()}
    >
      <div className="flex flex-col gap-4">
        <DescriptionBlock isChangeImmediately={isChangeImmediately} />
        {subscriptionId !== activeMembership.primarySubscriptionId && (
          <WarningBlock
            text={t(
              "page.userMember.tab.userMemberAccount.userMemberMembership.membershipCard.modal.changeMembershipModal.warningBlock.partOfShared",
            )}
          />
        )}
        {subscriptionId === activeMembership.primarySubscriptionId &&
          linkedMembers &&
          linkedMembers?.length > 1 && (
            <WarningBlock
              text={t(
                "page.userMember.tab.userMemberAccount.userMemberMembership.membershipCard.modal.changeMembershipModal.warningBlock.removedForAllLinkedMembers",
              )}
            />
          )}
        <FormikProvider value={formik}>
          <div className="flex flex-col gap-2">
            <FormNewMembershipSelect activeMembership={activeMembership} />
            {isProRataForChangingSubscription && isChangeImmediately && (
              <div className="flex flex-col gap-2 rounded-xl border border-gray-200 p-3">
                <FormCheckboxInput<ChangeMembershipFormValues>
                  name="isCollectUpgradeCost"
                  label={t(
                    "page.userMember.tab.userMemberAccount.userMemberMembership.membershipCard.modal.changeMembershipModal.checkbox.label",
                  )}
                />
                <WarningBlock
                  text={t(
                    "page.userMember.tab.userMemberAccount.userMemberMembership.membershipCard.modal.changeMembershipModal.warningBlock.upgradeCost",
                    { upgradeCost: formatCurrency(prorata, defaultCurrency) },
                  )}
                />
              </div>
            )}
          </div>
          <FormLabeledSwitch<ChangeMembershipFormValues>
            name="isChangeImmediately"
            label={t(
              "page.userMember.tab.userMemberAccount.userMemberMembership.membershipCard.modal.changeMembershipModal.switch.label",
            )}
            className="-ml-1 -mr-2 scale-75"
            labelClassName="font-medium text-sm text-gray-950"
          />
          {!isChangeImmediately && (
            <div className="flex flex-col gap-2 rounded-xl border border-gray-200 p-3">
              <FormScheduledDateSelect activeMembership={activeMembership} />
              <InfoBlock
                text={t(
                  "page.userMember.tab.userMemberAccount.userMemberMembership.membershipCard.modal.changeMembershipModal.infoBlock.billingPeriod",
                )}
              />
            </div>
          )}
        </FormikProvider>
        {isChangeImmediately &&
          newMembership &&
          newMembership.value.billingType !== activeMembership.billingType && (
            <BillingDateChangeWarning />
          )}
      </div>
    </ConfirmModal>
  );
};
