import { cva, VariantProps } from "class-variance-authority";
import React, { ReactNode, useState } from "react";
import { Tooltip } from "react-tooltip";

import { cn } from "../../../../../libs/helpers/src";
import { EmptyDot } from "./EmptyDot";

const emptyDotBadgeVariants = cva(
  "flex w-fit flex-row items-center gap-1 rounded-full px-2 py-1",
  {
    variants: {
      intent: {
        primary: "bg-primary-100 dark:bg-primary-900",
        secondary: "bg-secondary-100 dark:bg-secondary-900",
        error: "bg-error-100 dark:bg-error-900",
        warning: "bg-warning-100 dark:bg-warning-900",
        success: "bg-success-100 dark:bg-success-900",
        lightblue: "bg-lightblue-100 dark:bg-lightblue-900",
        default: "bg-gray-100 dark:bg-gray-900",
      },
    },
    defaultVariants: {
      intent: "default",
    },
  },
);
export type EmptyDotBadgeProps = {
  tooltip?: string;
  classNameOverride?: string;
  children: ReactNode;
} & VariantProps<typeof emptyDotBadgeVariants>;

export const EmptyDotBadge: React.FC<EmptyDotBadgeProps> = ({
  intent,
  classNameOverride,
  children,
  tooltip,
}) => {
  const [tooltipId] = useState(Math.random().toString(36).slice(2));
  return (
    <>
      <div
        data-tooltip-id={tooltipId}
        data-tooltip-content={tooltip}
        className={cn(emptyDotBadgeVariants({ intent }), classNameOverride)}
      >
        <EmptyDot intent={intent} />
        {typeof children === "string" && (
          <div className="flex font-semibold text-gray-950 dark:text-gray-0">
            {children}
          </div>
        )}
        {typeof children !== "string" && children}
      </div>
      {tooltip && (
        <Tooltip
          id={tooltipId}
          className="!z-50 !max-w-sm !rounded-xl !bg-black !px-3 !py-2 !text-center !text-sm !font-semibold !text-gray-0 !opacity-100"
          content={tooltip}
        />
      )}
    </>
  );
};
