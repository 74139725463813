import { Field, FieldProps } from "formik";

import {
  PaymentMethodPicker,
  PaymentMethodPickerProps,
} from "../../molecules/PaymentMethodPicker";
import { ErrorMessage } from "../ErrorMessage";

type FormPaymentMethodPickerProps<TForm> = {
  name: keyof TForm;
} & Omit<PaymentMethodPickerProps, "onChange" | "currentPaymentMethodId">;

export const FormPaymentMethodPicker = <TForm,>({
  name,
  ...props
}: FormPaymentMethodPickerProps<TForm>) => {
  return (
    <Field name={name}>
      {({ field, form, meta: { touched, error } }: FieldProps) => {
        const isError = touched && error;
        return (
          <div className="flex flex-col gap-1.5">
            <PaymentMethodPicker
              {...props}
              currentPaymentMethodId={field.value}
              onChange={(newValue) => {
                form.setFieldValue(name as string, newValue);
              }}
            />
            {isError && <ErrorMessage text={error} />}
          </div>
        );
      }}
    </Field>
  );
};
