const DashedIcon = ({
  className,
  pathClassName,
}: {
  className?: string;
  pathClassName?: string;
}) => (
  <svg
    xmlns="http://www.w3.org/2000/svg"
    width="100%"
    height="2"
    viewBox="0 0 100 2"
    fill="none"
    className={className}
    preserveAspectRatio="none"
  >
    <path
      d="M0 1 H100"
      stroke="#D0D5DD"
      strokeWidth="2"
      strokeLinecap="round"
      strokeDasharray="0.6 2.5"
      className={pathClassName}
    />
  </svg>
);

export default DashedIcon;
