import { formatCurrency } from "@gymflow/helpers";
import { Currency } from "@gymflow/types";
import qs from "qs";
import { useCallback, useContext, useEffect } from "react";
import { useTranslation } from "react-i18next";
import { matchPath, useHistory, useRouteMatch } from "react-router";

import { LayeredCircles } from "../components/atoms";
import CheckmarkIcon from "../components/atoms/icons/CheckmarkIcon";
import { usePortalRoutes } from "../hooks";
import { ModalContext, ModalProvider, ModalWrapper } from "../providers";
import { RouteFeature } from "../routes";

function GenericSecurePayment() {
  const match = useRouteMatch();
  const { createSiteOrEmbedLink } = usePortalRoutes();
  const history = useHistory();
  const { t } = useTranslation();
  const search = qs.parse(history?.location?.search, {
    ignoreQueryPrefix: true,
  }) as {
    invoiceAmount: string;
    currency: Currency;
    result: string;
  };
  const { stackModal, hide } = useContext(ModalContext);
  const redirect = useCallback(() => {
    hide();
    if (matchPath(match.url, RouteFeature.SiteSecurePayment)) {
      history.push("/");
    } else {
      history.push(
        createSiteOrEmbedLink(
          RouteFeature.SiteTimetableType.replace(":viewType", "list"),
        ),
      );
    }
  }, []);
  useEffect(() => {
    stackModal(
      <ModalWrapper
        className="flex flex-col items-center gap-4 rounded-2xl px-6 py-8"
        onCancel={redirect}
      >
        {
          {
            SUCCEEDED: (
              <>
                <LayeredCircles intent="success" className="h-14 w-14">
                  <CheckmarkIcon pathClassName="stroke-success-500" />
                </LayeredCircles>
                <div className="flex w-full flex-col items-center gap-2">
                  <div className="flex text-center text-xl font-bold">
                    {t("pages.securePayment.success.title")}
                  </div>
                  <div className="flex text-center font-medium text-gray-500">
                    {t("pages.securePayment.success.description", {
                      amount: formatCurrency(
                        parseFloat(search.invoiceAmount),
                        search.currency,
                      ),
                    })}
                  </div>
                </div>
              </>
            ),
            PAYMENT_AUTHORIZATION_NOT_REQUIRED: (
              <>
                <LayeredCircles intent="success" className="h-14 w-14">
                  <CheckmarkIcon pathClassName="stroke-success-500" />
                </LayeredCircles>
                <div className="flex w-full flex-col items-center gap-2">
                  <div className="flex text-center text-xl font-bold">
                    {t("pages.securePayment.authorizationNotNeeded.title")}
                  </div>
                  <div className="flex text-center font-medium text-gray-500">
                    {t(
                      "pages.securePayment.authorizationNotNeeded.description",
                    )}
                  </div>
                </div>
              </>
            ),
            FAILED: (
              <>
                <LayeredCircles intent="error" className="h-14 w-14">
                  <CheckmarkIcon pathClassName="stroke-error-500" />
                </LayeredCircles>
                <div className="flex w-full flex-col items-center gap-2">
                  <div className="flex text-center text-xl font-bold">
                    {t("pages.securePayment.failed.title")}
                  </div>
                  <div className="flex text-center font-medium text-gray-500">
                    {t("pages.securePayment.failed.description")}
                  </div>
                </div>
              </>
            ),
          }[search.result]
        }
      </ModalWrapper>,
    );
  }, [
    redirect,
    search.currency,
    search.invoiceAmount,
    search.result,
    stackModal,
    t,
  ]);

  return <div />;
}

function GenericSecurePaymentLayout() {
  return (
    <ModalProvider>
      <GenericSecurePayment />
    </ModalProvider>
  );
}

export { GenericSecurePaymentLayout as GenericSecurePayment };
