import { useAbility } from "@casl/react";
import {
  useClubFeatureFlags,
  useMutationLeadBulkStatusUpdate,
  useMutationLeadBulkStatusUpdateFilter,
  useMutationLeadListCsv,
  useQueryLeadListCount,
} from "@gymflow/api";
import { isMobile } from "@gymflow/common";
import { cn, pluralize } from "@gymflow/helpers";
import { usePortalRoutes, useSelected } from "apps/portal/src/hooks";
import { AbilityContext, Subject, Verb } from "apps/portal/src/permissions";
import {
  ModalContext,
  useAuthenticatedUser,
  useClubSettings,
} from "apps/portal/src/providers";
import { RouteFeature, RouteLayout } from "apps/portal/src/routes";
import useGymflowModels from "apps/portal/src/store";
import omit from "lodash/omit";
import React, { useCallback, useContext, useMemo, useState } from "react";
import {
  Redirect,
  Route,
  Switch,
  useHistory,
  useRouteMatch,
} from "react-router";
import { useLocalStorage } from "usehooks-ts";

import { getFilterAppliedCount } from "../../../helpers";
import {
  BarChartIcon,
  Button,
  DotPointsIcon,
  DownloadIcon,
  FilterIcon,
  MailIcon,
  PlusIcon,
  SMSIcon,
  Spinner,
} from "../../atoms";
import { SwitchHorizontalIcon } from "../../atoms/icons/SwitchHorizontalIcon";
import { NewUserSideBarProviderContext } from "../../molecules";
import { SendEmailSidebarProviderContext } from "../../organisms";
import { SettingsContainer } from "../../Settings/SettingsContainer";
import { buildLeadsFilterLocalStorageKey } from "./buildLeadsFilterLocalStorageKey";
import {
  generateLeadFilters,
  generateLeadFiltersForEmailAndBulkStatus,
} from "./leadFilterHelper";
import { BulkStatusChangeModal } from "./LeadList/BulkStatusChangeModal";
import { LeadList } from "./LeadList/LeadList";
import { LeadPipeline } from "./LeadPipeline/LeadPipeline";
import { LeadStatusFormModal } from "./LeadPipeline/LeadStatusFormModal";
import { LeadFilters, LeadsFilterSidebar } from "./LeadPipelineFilterSidebar";
import { SendSMSToLeadsSidebar } from "./SendSMSToLeadsSidebar";
import { ToggleModeButton } from "./ToggleModeButton";

export type LeadsProps = {};

export const Leads: React.FC<LeadsProps> = () => {
  const history = useHistory();
  const { api } = useGymflowModels();
  const { timezone, clubId } = useClubSettings();
  const { data: featureFlags } = useClubFeatureFlags({ clubId, api });
  const { id: loggedInId } = useAuthenticatedUser();
  const { routeId } = usePortalRoutes();
  const { openSendEmailSidebar } = useContext(SendEmailSidebarProviderContext);
  const { open: openNewUserSideBar } = useContext(
    NewUserSideBarProviderContext,
  );
  const [isSendSMSToLeadsSidebarVisible, setIsSendSMSToLeadsSidebarVisible] =
    useState<boolean>(false);
  const { createClubLink } = usePortalRoutes();
  const match = useRouteMatch({
    path: createClubLink(RouteLayout.Staff, RouteFeature.LeadList),
  });
  const view = !!match ? ("LIST" as const) : ("PIPELINE" as const);
  const [isFilterSideBarOpen, setIsFilterSideBarOpen] = useState(false);
  const [listFilters, setListFilters] = useLocalStorage<LeadFilters>(
    buildLeadsFilterLocalStorageKey("LIST", loggedInId as string, routeId),
    {},
  );
  const [pipelineFilters, setPipelineFilters] = useLocalStorage<LeadFilters>(
    buildLeadsFilterLocalStorageKey("PIPELINE", loggedInId as string, routeId),
    {},
  );

  const { data: dataCount, isLoading: isLoadingCount } = useQueryLeadListCount({
    api,
    tz: timezone,
    filters: generateLeadFilters({
      filters: view === "LIST" ? listFilters : pipelineFilters,
    }),
  });

  const { mutateAsync: downloadCsv } = useMutationLeadListCsv({
    api,
    tz: timezone,
  });
  const selection = useSelected<number>();
  const selectionCount = selection.areAllSelected
    ? (dataCount?.count ?? 0) - selection.excluded.length
    : selection.selected.length;

  const ability = useAbility(AbilityContext);

  const sendEmailHandler = useCallback(() => {
    const recipient = `${selectionCount} ${pluralize(
      "lead",
      "leads",
      selectionCount,
    )}`;

    openSendEmailSidebar({
      recipient,
      allowMarketing: true,
      requestType: "LEAD",
      ...(selection.areAllSelected
        ? {
            filter: {
              leadIdsToExclude: selection.excluded,
              ...generateLeadFiltersForEmailAndBulkStatus({
                filters: listFilters,
              }),
            },
          }
        : { listOfIds: selection.selected }),
      onSuccess: () => selection.reset(),
    });
  }, [selectionCount, openSendEmailSidebar, selection, listFilters]);

  const { setModal, hide: hideModal } = useContext(ModalContext);

  const { mutateAsync: bulkStatusUpdate } = useMutationLeadBulkStatusUpdate({
    api,
  });
  const { mutateAsync: bulkStatusUpdateByFilter } =
    useMutationLeadBulkStatusUpdateFilter({ api, tz: timezone });

  const bulkSwitchLaneHandler = useCallback(() => {
    setModal(
      <BulkStatusChangeModal
        hide={hideModal}
        onChange={async (newStatusId) => {
          if (selection.areAllSelected) {
            await bulkStatusUpdateByFilter({
              newLeadStatusId: newStatusId,
              leadIdsToExclude: selection.excluded,
              ...generateLeadFiltersForEmailAndBulkStatus({
                filters: listFilters,
              }),
            });
          } else {
            await bulkStatusUpdate({
              newLeadStatusId: newStatusId,
              leadIds: selection.selected,
            });
          }
          selection.reset();
        }}
      />,
    );
  }, [
    bulkStatusUpdate,
    bulkStatusUpdateByFilter,
    listFilters,
    hideModal,
    selection,
    setModal,
  ]);

  const isNotSelected = useMemo(() => selectionCount === 0, [selectionCount]);

  const isHiddenEmail = useMemo(
    () => isNotSelected || !ability.can(Verb.Create, Subject.Email),
    [ability, isNotSelected],
  );

  const isHiddenSMS = useMemo(
    () =>
      !featureFlags?.featureFlags.PORTAL_SEND_SMS ||
      isNotSelected ||
      !ability.can(Verb.Create, Subject.SMS),
    [ability, featureFlags?.featureFlags.PORTAL_SEND_SMS, isNotSelected],
  );

  return (
    <div className="flex h-full max-h-full w-full p-4 lg:p-8">
      <LeadsFilterSidebar
        isVisible={isFilterSideBarOpen}
        onVisibilityChange={(newVisibility) => {
          setIsFilterSideBarOpen(newVisibility);
        }}
        value={view === "LIST" ? listFilters : pipelineFilters}
        onChange={(newFilters) => {
          selection.reset();
          if (view === "LIST") {
            setListFilters(newFilters);
          } else {
            setPipelineFilters(newFilters);
          }
        }}
        includeDealClosedAndList={view === "LIST"}
      />
      <SendSMSToLeadsSidebar
        isVisible={isSendSMSToLeadsSidebarVisible}
        onClose={() => setIsSendSMSToLeadsSidebarVisible(false)}
        recipients={selectionCount}
        areAllSelected={selection.areAllSelected}
        selected={selection.selected}
        excluded={selection.excluded}
        listFilters={listFilters}
      />
      <SettingsContainer
        title={
          isLoadingCount ? (
            <Spinner />
          ) : view === "LIST" ? (
            `Leads ${
              selectionCount > 0 ? "(" + selectionCount + " selected)" : ""
            }`
          ) : (
            `Leads`
          )
        }
        subTitle="All prospective customers of the business."
        actions={
          <div className="flex items-center gap-3">
            {view === "PIPELINE" && (
              <Button
                className="hidden lg:flex"
                onClick={() => {
                  setModal(<LeadStatusFormModal onClose={hideModal} />);
                }}
              >
                Add Column
              </Button>
            )}
            {view === "LIST" && (
              <>
                <Button
                  className={cn("hidden w-[2.75rem] min-w-[2.75rem] lg:flex", {
                    "lg:hidden": isHiddenEmail,
                  })}
                  onClick={sendEmailHandler}
                >
                  <div className="flex items-center gap-2">
                    <MailIcon
                      className="h-[1.125rem] w-[1.125rem]"
                      pathClassName="stroke-gray-500"
                    />
                  </div>
                </Button>
                <Button
                  className={cn("hidden w-[2.75rem] min-w-[2.75rem] lg:flex", {
                    "lg:hidden": isHiddenSMS,
                  })}
                  onClick={() => setIsSendSMSToLeadsSidebarVisible(true)}
                >
                  <div className="flex items-center gap-2">
                    <SMSIcon
                      className="h-[1.125rem] w-[1.125rem]"
                      pathClassName="stroke-gray-500"
                    />
                  </div>
                </Button>
                <Button
                  className={cn("hidden w-[2.75rem] min-w-[2.75rem] lg:flex", {
                    "lg:hidden": isNotSelected,
                  })}
                  onClick={bulkSwitchLaneHandler}
                >
                  <div className="flex items-center gap-2">
                    <SwitchHorizontalIcon
                      className="h-[1.125rem] w-[1.125rem]"
                      pathClassName="stroke-gray-500"
                    />
                  </div>
                </Button>
                <Button
                  className="hidden w-11 min-w-[2.75rem] lg:flex"
                  onClick={async () =>
                    await downloadCsv(
                      generateLeadFilters({
                        filters: listFilters,
                      }),
                    )
                  }
                >
                  <div className="flex items-center gap-2">
                    <DownloadIcon
                      className="h-[1.125rem] w-[1.125rem]"
                      pathClassName="stroke-gray-500"
                    />
                  </div>
                </Button>
              </>
            )}
            <Button
              className="mt-0 hidden lg:flex"
              onClick={() => {
                setIsFilterSideBarOpen(true);
              }}
              badgeContent={getFilterAppliedCount(
                omit(view === "LIST" ? listFilters : pipelineFilters, [
                  "createdTo",
                ]),
              )}
            >
              <div className="flex items-center gap-2">
                <FilterIcon
                  className="h-[1.125rem] w-[1.125rem]"
                  pathClassName="stroke-gray-500"
                />
                <div>Filters</div>
              </div>
            </Button>
            <ToggleModeButton
              className="hidden w-fit lg:flex"
              value={view === "LIST" ? "RIGHT" : "LEFT"}
              onChange={() => {
                history.push(
                  createClubLink(
                    RouteLayout.Staff,
                    view === "PIPELINE"
                      ? RouteFeature.LeadList
                      : RouteFeature.LeadPipeline,
                  ),
                );
              }}
              left={
                <div className="flex h-11 w-11 items-center justify-center">
                  <BarChartIcon pathClassName="stroke-gray-700 dark:stroke-gray-300" />
                </div>
              }
              right={
                <div className="flex h-11 w-11 items-center justify-center">
                  <DotPointsIcon pathClassName="stroke-gray-700 dark:stroke-gray-300" />
                </div>
              }
            />
            <Button
              className="w-11 min-w-[2.75rem] lg:w-auto lg:min-w-fit"
              intent="secondary"
              onClick={() => {
                openNewUserSideBar({ creationMode: "LEAD" });
              }}
            >
              <div className="flex items-center gap-2">
                <PlusIcon
                  className="h-[1.125rem] w-[1.125rem]"
                  pathClassName="stroke-white"
                />
                <div className="hidden lg:flex">Add Lead</div>
              </div>
            </Button>
          </div>
        }
      >
        <div className="flex items-center justify-between lg:hidden">
          <div className="flex items-center gap-3">
            <Button
              className="w-11 min-w-[2.75rem] lg:hidden"
              onClick={() => {
                setIsFilterSideBarOpen(true);
              }}
              badgeContent={getFilterAppliedCount(
                omit(view === "LIST" ? listFilters : pipelineFilters, [
                  "createdTo",
                ]),
              )}
            >
              <div className="flex items-center gap-2">
                <FilterIcon
                  className="h-[1.125rem] w-[1.125rem]"
                  pathClassName="stroke-gray-500"
                />
              </div>
            </Button>
            {view === "PIPELINE" && (
              <Button
                className="flex lg:hidden"
                onClick={() => {
                  setModal(<LeadStatusFormModal onClose={hideModal} />);
                }}
              >
                Add Column
              </Button>
            )}
            {view === "LIST" && (
              <>
                <Button
                  className="w-11 min-w-[2.75rem] lg:hidden"
                  onClick={async () =>
                    await downloadCsv(
                      generateLeadFilters({
                        filters: listFilters,
                      }),
                    )
                  }
                >
                  <div className="flex items-center gap-2">
                    <DownloadIcon
                      className="h-[1.125rem] w-[1.125rem]"
                      pathClassName="stroke-gray-500"
                    />
                  </div>
                </Button>
                <Button
                  className={cn("w-[2.75rem] min-w-[2.75rem] lg:hidden", {
                    hidden: isHiddenEmail,
                  })}
                  onClick={sendEmailHandler}
                >
                  <div className="flex items-center gap-2">
                    <MailIcon
                      className="h-[1.125rem] w-[1.125rem]"
                      pathClassName="stroke-gray-500"
                    />
                  </div>
                </Button>
                <Button
                  className={cn("w-[2.75rem] min-w-[2.75rem] lg:hidden", {
                    hidden: isHiddenSMS,
                  })}
                  onClick={() => setIsSendSMSToLeadsSidebarVisible(true)}
                >
                  <div className="flex items-center gap-2">
                    <SMSIcon
                      className="h-[1.125rem] w-[1.125rem]"
                      pathClassName="stroke-gray-500"
                    />
                  </div>
                </Button>
                <Button
                  className={cn("w-[2.75rem] min-w-[2.75rem] lg:hidden", {
                    hidden: isNotSelected,
                  })}
                  onClick={bulkSwitchLaneHandler}
                >
                  <div className="flex items-center gap-2">
                    <SwitchHorizontalIcon
                      className="h-[1.125rem] w-[1.125rem]"
                      pathClassName="stroke-gray-500"
                    />
                  </div>
                </Button>
              </>
            )}
          </div>

          <div className="flex items-center gap-3 lg:hidden">
            <ToggleModeButton
              className="w-fit lg:hidden"
              value={view === "LIST" ? "RIGHT" : "LEFT"}
              onChange={() => {
                history.push(
                  createClubLink(
                    RouteLayout.Staff,
                    view === "PIPELINE"
                      ? RouteFeature.LeadList
                      : RouteFeature.LeadPipeline,
                  ),
                );
              }}
              left={
                <div className="flex h-11 w-11 items-center justify-center">
                  <BarChartIcon pathClassName="stroke-gray-700 dark:stroke-gray-300" />
                </div>
              }
              right={
                <div className="flex h-11 w-11 items-center justify-center">
                  <DotPointsIcon pathClassName="stroke-gray-700 dark:stroke-gray-300" />
                </div>
              }
            />
          </div>
        </div>
        <Switch>
          <Route
            path={createClubLink(RouteLayout.Staff, RouteFeature.LeadList)}
          >
            <LeadList filters={listFilters} selection={selection} />
          </Route>
          <Route
            path={createClubLink(RouteLayout.Staff, RouteFeature.LeadPipeline)}
          >
            <LeadPipeline filters={pipelineFilters} />
          </Route>
          {!isMobile() && (
            <Redirect
              to={createClubLink(RouteLayout.Staff, RouteFeature.LeadPipeline)}
            />
          )}
          {isMobile() && (
            <Redirect
              to={createClubLink(RouteLayout.Staff, RouteFeature.LeadList)}
            />
          )}
        </Switch>
      </SettingsContainer>
    </div>
  );
};
