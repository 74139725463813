import { cn } from "@gymflow/helpers";
import { ReactNode } from "react";

export type TabType<T extends string | number | undefined> = {
  id: T;
  label: ((isActive: boolean) => ReactNode) | ReactNode;
};

export type TabsProps<T extends string | number | undefined> = {
  tabs: TabType<T>[];
  activeTabId: T;
  setActiveTabId: (id: T) => void;
  className?: string;
  tabClassName?: string;
  activeTabClassName?: string;
  activeTabLabelClassName?: string;
};

export const Tabs = <T extends string | number | undefined>({
  tabs,
  activeTabId,
  setActiveTabId,
  className,
  tabClassName,
  activeTabClassName,
  activeTabLabelClassName,
}: TabsProps<T>) => (
  <div
    className={cn(
      "flex min-h-[2.75rem] flex-row gap-x-2 overflow-x-auto overflow-y-hidden border-b border-gray-200",
      className,
    )}
  >
    {tabs.map(({ id, label }) => (
      <div
        key={id}
        onClick={() => setActiveTabId(id)}
        className={cn(
          "max-w-min cursor-pointer flex-nowrap whitespace-nowrap px-1 flex pt-3 border-b-2 border-transparent",
          tabClassName,
          {
            [cn("border-secondary-500", activeTabClassName)]:
              activeTabId === id,
          },
        )}
      >
        <div
          className={cn(
            "flex items-center gap-2 text-sm font-semibold text-gray-500",
            {
              [cn("text-secondary-500", activeTabLabelClassName)]:
                activeTabId === id,
            },
          )}
        >
          {typeof label === "function" ? label(activeTabId === id) : label}
        </div>
      </div>
    ))}
  </div>
);
