import { cn } from "@gymflow/helpers";
import { Field, FieldProps, FormikProps } from "formik";

import { Textarea, TextareaProps } from "../base";
import { ErrorMessage } from "../ErrorMessage";

type FormTextareaProps<T> = {
  name: keyof T;
  isRequired?: boolean;
} & Pick<TextareaProps, "placeholder" | "label" | "className" | "rows">;

export const FormTextarea = <T,>({
  name,
  className,
  ...props
}: FormTextareaProps<T>) => {
  return (
    <Field name={name}>
      {({
        field,
        meta: { touched, error },
      }: FieldProps<string, FormikProps<T>>) => {
        const isError = touched && error;
        return (
          <div className="flex flex-col gap-1.5">
            <Textarea
              {...field}
              {...props}
              className={cn(className, {
                "outline-error-300 border-error-200 dark:!outline-none focus:dark:ring-1 dark:ring-error-600":
                  isError,
              })}
            />
            {isError && <ErrorMessage text={error} />}
          </div>
        );
      }}
    </Field>
  );
};
