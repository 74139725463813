import { ApiType } from "@gymflow/common/lib/api/ApiType";
import { ActivityStatus, PaginationOptions } from "@gymflow/types";
import { useQuery } from "@tanstack/react-query";

import { appointableAsPublicQueryKeys } from "./appointableAsPublicQueryKeys";

export function useQueryAppointableCategoriesAsPublic(
  {
    api,
    paginationOption,
    filter,
  }: {
    api: ApiType;
    paginationOption: PaginationOptions;
    filter?: {
      statusList?: ActivityStatus[];
    };
  },
  {
    enabled,
  }: {
    enabled?: boolean;
  } = {
    enabled: true,
  },
) {
  return useQuery({
    queryKey: appointableAsPublicQueryKeys.categories({
      ...paginationOption,
      ...filter,
    }),
    queryFn: async () =>
      (
        await api.public.appointableApi.fetchCategories({
          ...paginationOption,
          ...filter,
        })
      ).data,
    enabled,
  });
}
