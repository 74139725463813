/**
 * @summary ISO-3166-2 Alpha 3
 */
export type Iso3Country =
  | "GBR"
  | "USA"
  | "AUS"
  | "NOR"
  | "NZL"
  | "ESP"
  | "GIB"
  | "CAN"
  | "IRL"
  | "CYP"
  | "FRA"
  | "NLD";
/**
 * @summary ISO-3166-2 Alpha 2
 */
export type Iso2Country =
  | "GB"
  | "US"
  | "AU"
  | "NO"
  | "NZ"
  | "ES"
  | "GI"
  | "CA"
  | "IE"
  | "CY"
  | "FR"
  | "NL";

export type Currency = "GBP" | "USD" | "AUD" | "NZD" | "NOK" | "CAD" | "EUR";

export type KisiStatus =
  | "ENABLED"
  | "DISABLED"
  | "PROCESSING_ENABLE"
  | "PROCESSING_DISABLE"
  | "PROCESSING_MODIFY";

export interface ClubBean {
  readonly id: number;
  readonly createdDate: string;
  readonly closeDate: string;
  readonly name: string;
  readonly postCode: string;
  readonly addressLine1: string;
  readonly addressLine2?: string;
  readonly city: string;
  readonly defaultCurrency: Currency;
  readonly defaultTaxRate: number;
  readonly company: CompanyDTO;
  readonly stripeApplicationId?: string;
  readonly stripeAccountType: "CONNECTED" | "NOT_CONNECTED" | "STANDARD";
  readonly timezone: string;
  readonly termsConditions: string;
  readonly termsConditionsFilename: string;
  readonly email: string;
  readonly zoneId: string;
  readonly kisiStatus: KisiStatus;
  readonly kisiPlaceId?: string;
  readonly messagingCreditsLeft: number;
  readonly customPaymentsEnabled: boolean;
}

export interface LeadHistoryDTO extends TimelineDTO {
  readonly leadStatus: LeadStatusDTO;
}

export interface LeadStatusDTO {
  readonly id: number;
  readonly name: string;
  readonly presetType: PresetLeadStatusType;
  readonly statusOrder: number;
  readonly deletable: boolean;
  readonly deleted: boolean;
  readonly editableOrder: boolean;
}

export type NoteDTO = {
  readonly id: number;
  readonly nodeType: "NOTE";
  readonly createdDate: string;
  readonly content: string;
  readonly attachment?: string;
  readonly attachmentFilename?: string;
  readonly updatedDate?: string;
  readonly userMemberId?: string;
  readonly createdBy: string;
  readonly createdByFirstName: string;
  readonly createdByLastName: string;
  readonly leadId?: string;
  readonly appointmentId?: number;
};

export interface PromotionRestrictedItem {
  readonly id: number;
  readonly name: string;
}

export type PromotionLimitCategory =
  | "PRODUCT"
  | "MEMBERSHIP"
  | "SESSION_PACK"
  | "APPOINTABLE";

export interface PromotionDTO {
  readonly id: number;
  readonly name: string;
  readonly code: string;
  readonly upfrontDiscount: boolean;
  readonly upfrontDiscountProrata?: boolean;
  readonly upfrontDiscountAmount?: number;
  readonly upfrontDiscountType?: DiscountType;
  readonly recurringDiscount: boolean;
  readonly recurringDiscountAmount?: number;
  readonly recurringDiscountType?: DiscountType;
  readonly recurringDiscountDuration?: number;
  readonly limitProductId?: number;
  readonly limitMembershipId?: number;
  readonly limitSessionPackId?: number;
  readonly limitUsagesPerMember?: number;
  readonly limitUsages?: number;
  readonly trial: boolean;
  readonly active: boolean;
  readonly expiry?: string;
  readonly timesUsed: number;
  readonly limitProductList?: PromotionRestrictedItem[];
  readonly limitMembershipList?: PromotionRestrictedItem[];
  readonly limitSessionPackList?: PromotionRestrictedItem[];
  readonly limitAppointableList?: PromotionRestrictedItem[];
  readonly limitProductIdList?: number[];
  readonly limitMembershipIdList?: number[];
  readonly limitSessionPackIdList?: number[];
  readonly limitAppointableIdList?: number[];
  readonly limitCategories?: PromotionLimitCategory[];
}

export interface PromotionPostDTO
  extends Omit<
    PromotionDTO,
    | "id"
    | "timesUsed"
    | "limitProductIdList"
    | "limitMembershipIdList"
    | "limitSessionPackIdList"
    | "limitAppointableIdList"
  > {
  readonly limitProductIdList: number[];
  readonly limitMembershipIdList: number[];
  readonly limitSessionPackIdList: number[];
  readonly limitAppointableIdList: number[];
}

export interface MembershipBean {
  readonly id: number;
  readonly createdDate: string;
  readonly updatedDate: string;
  readonly endDate: string;
  readonly name: string;
  readonly description: string;
  readonly type: MembershipType;
  readonly durationType: MembershipDurationType;
  readonly duration: number;
  readonly currency: string;
  readonly defaultPrice: number;
  readonly taxRate: number;
  readonly status: MembershipStatus;
  readonly termsConditions: string;
  readonly termsConditionsFilename: string;
  readonly billingType?: BillingType;
  readonly monthlyBillingDay?: number;
  readonly weeklyBillingDay?: DayOfWeek;
  readonly billingPeriod: number;
  readonly calculateProrata: ProrataCalculation;
  readonly clubBeanList: ClubBean[];
  readonly membershipAddonList?: MembershipAddonDTO[];
  readonly proRataPayment: number;
  readonly addonsProRataPayment: number;
  readonly membershipProRataPayment: number;
  readonly recurringAddonsProRataPayment: number;
  readonly prepaidMembershipPayment: number;
  readonly totalPayableToday: number;
  readonly totalAmount: number;
  readonly nextMonthlyPayment: number;
  readonly addonsProratedList?: MembershipAddonPurchaseDTO[];
  readonly isPublic: boolean;
  readonly isTrial: boolean;
  readonly fixedStartDate?: string;
  readonly fixedEndDate?: string;
}

export interface MembershipPatchBean extends MembershipBean {
  readonly termsConditionsFile: MultipartFile;
}

export interface MembershipPostBean extends MembershipBean {
  readonly clubIdList: number[];
  readonly productIdRecurringList: ProductAddonDTO[];
  readonly sessionPackIdRecurringList: number[];
  readonly productIdOnceOffList: ProductAddonDTO[];
  readonly sessionPackIdOnceOffList: number[];
  readonly termsConditionsFile: MultipartFile;
}

export interface UserMemberBean extends Searchable {
  readonly id: string;
  readonly createdDate: string;
  readonly updatedDate: string;
  readonly mobileNumber: string;
  readonly dateBirth: string;
  readonly postCode: string;
  readonly addressLine1: string;
  readonly addressLine2?: string;
  readonly city: string;
  readonly gender: Gender;
  readonly country: Iso3Country;
  readonly emergencyContactName: string;
  readonly emergencyContact: string;
  readonly emailCommunication: boolean;
  readonly smsCommunication: boolean;
  readonly pushCommunication: boolean;
  readonly picture?: string;
  readonly currentBalance: number;
  readonly balanceDate: string;
  readonly isFullUser: boolean;
  readonly subscriptions: UserMemberSubscriptionBean[];
  readonly checkoutSessionId: string;
  readonly eventsAttended: number;
  readonly lead: LeadDTO;
  readonly userMember: UserType;
  readonly kisiLoginId?: string;
  readonly kisiUserId?: string;
  readonly isClubWaiverAccepted: boolean;
  readonly clubWaiverAcceptanceDate: string;
  readonly lastKisiPrimaryDeviceChangeDate?: string;
  readonly assignedStaffMembers: UserStaffBean[];
  readonly homeClub?: Club;
  readonly personalNumber: string;
}

export interface UserMemberPost {
  readonly email: string;
  readonly password?: string;
  readonly firstName: string;
  readonly lastName: string;
  readonly mobileNumber?: string;
  readonly dateBirth?: string;
  readonly postCode?: string;
  readonly addressLine1?: string;
  readonly addressLine2?: string;
  readonly city?: string;
  readonly gender?: Gender;
  readonly country: Iso3Country;
  readonly emergencyContactName?: string;
  readonly emergencyContact?: string;
  readonly emailCommunication: boolean;
  readonly smsCommunication: boolean;
  readonly pushCommunication: boolean;
  readonly isClubWaiverAccepted: boolean;
  readonly sourceId: number;
}

export interface UserMemberCreated {
  readonly id: string;
  readonly email: string;
  readonly firstName: string;
  readonly lastName: string;
  readonly createdDate: string;
  readonly updatedDate: string;
  readonly mobileNumber?: string;
  readonly dateBirth?: string;
  readonly postCode?: string;
  readonly addressLine1?: string;
  readonly addressLine2?: string;
  readonly city?: string;
  readonly gender?: Gender;
  readonly country: string;
  readonly emergencyContactName?: string;
  readonly emergencyContact?: string;
  readonly emailCommunication: boolean;
  readonly smsCommunication: boolean;
  readonly pushCommunication: boolean;
  readonly isFullUser: boolean;
  readonly lead: LeadDTO;
  readonly userMember: UserType;
  readonly kisiLoginId?: string;
  readonly isClubWaiverAccepted: boolean;
  readonly clubWaiverAcceptanceDate: string;
}

export interface UserMemberSearchByFullNameResult {
  readonly id: string;
  readonly email: string;
  readonly createdDate: string;
  readonly updatedDate: string;
  readonly firstName: string;
  readonly lastName: string;
  readonly userType: UserType;
  readonly profileType: UserProfileType;
  readonly eventsAttended: number;
  readonly homeClub: ClubBean;
  readonly kisiUserId: string;
  readonly kisiLoginId: string;
  readonly isClubWaiverAccepted: boolean;
}

export interface UserMemberRsvp {
  readonly id: string;
  readonly firstName: string;
  readonly lastName: string;
  readonly email?: string;
  readonly userType: UserType;
  readonly profileType: UserProfileType;
  readonly createdDate: string;
  readonly updatedDate: string;
  readonly mobileNumber?: string;
  readonly dateBirth?: string;
  readonly postCode?: string;
  readonly addressLine1?: string;
  readonly addressLine2?: string;
  readonly city?: string;
  readonly gender?: Gender;
  readonly country: string;
  readonly emergencyContactName?: string;
  readonly emergencyContact?: string;
  readonly emailCommunication: boolean;
  readonly smsCommunication: boolean;
  readonly pushCommunication: boolean;
  readonly picture?: string;
  readonly isFullUser: boolean;
  readonly subscriptions: UserMemberSubscriptionBean[];
  readonly eventsAttended: number;
  readonly lead: LeadFieldDTO;
  readonly kisiLoginId: string;
  readonly isClubWaiverAccepted: boolean;
  readonly clubWaiverAcceptanceDate: string;
}
export interface UserMemberPurchaseDTO {
  readonly userMember: UserMemberBean;
  readonly invoice: InvoiceDTO;
}

export type UserMemberSubscriptionBean = {
  readonly id: number;
  readonly kisiRoleAssignmentId?: string;
  readonly membershipBean?: MembershipBean;
  readonly sessionPackBean?: SessionPackDTO;
  readonly productBean?: ProductDTO;
  readonly sessionsLeft?: number;
  readonly startDate: string;
  readonly endDate: string;
  readonly endContractDate: string;
  readonly cancellationDate: string;
  readonly pauseStartDate: string;
  readonly pauseEndDate: string;
  readonly cycle: number;
  readonly status: UserMemberSubscriptionStatus;
  readonly active: boolean;
  readonly name: string;
  readonly price: number;
  readonly customerCancellable: boolean;
  readonly scheduledChangeDate?: string;
  readonly scheduledChangeToMembershipName?: string;
  readonly staffCancellable: boolean;
  readonly addon: boolean;
  readonly mainSubscription: boolean;
  // @deprecated
  readonly membershipMonthlyPayment: number;
  readonly createdDate: string;
  readonly updatedDate: string;
  readonly billingType?: BillingType;
  readonly monthlyBillingDay?: number;
  readonly weeklyBillingDay?: DayOfWeek;
  readonly billingPeriod?: number;
  readonly primarySubscriptionId: number;
} & (
  | {}
  | ({
      readonly promotionCode: string;
    } & (
      | {
          readonly promotionIsExpired: false;
          readonly discountedPaymentsUnlimited: boolean;
          readonly discountedAmount: number;
          readonly discountedPaymentsLeft: number;
        }
      | {
          readonly promotionIsExpired: true;
        }
    ))
);

export type UserMemberSubscriptionBeanWithMembership =
  UserMemberSubscriptionBean & {
    readonly membershipBean: MembershipBean;
  };

export interface UserMemberSubscriptionSignUpBean {
  readonly membershipId?: number;
  readonly sessionPackId?: number;
  readonly clubId: number;
  readonly startDate: string;
  readonly isWaived?: boolean;
  readonly promotionCode?: string;
}

export type UserMemberSubscriptionSignUpBeanAsMember = {
  readonly startDate: string;
  readonly promotionCode?: string;
} & (
  | {
      readonly membershipId?: number;
    }
  | {
      readonly sessionPackId?: number;
    }
);

export interface AppointableUserStaffDTO {
  /** @format int64 */
  readonly id: number;
  readonly name: string;
  readonly description: string;
  readonly status: AppointableDtoStatus;
  /** @format int32 */
  readonly duration: number;
  readonly pricingModel: AppointableDtoPricingModel;
  readonly price: number;
  readonly allowOnlineBookings: boolean;
  readonly allowAppBookings: boolean;
  readonly appointableCategory?: AppointableCategoryDTO;
}

export interface UserStaffBean {
  readonly id: string;
  readonly email: string;
  readonly firstName: string;
  readonly lastName: string;
  readonly mobileNumber: string;
  readonly picture?: string;
  readonly activeUser: boolean;
  readonly createdDate: string;
  readonly updatedDate: string;
  readonly appointableList: AppointableUserStaffDTO[];
  readonly homeClub: Club;
  readonly clubAccessibleList: Club[];
  readonly role: string;
}

export type ActivityCategoryStatus = "ACTIVE" | "DISABLED";

export interface ActivityCategoryDTO {
  readonly id: number;
  readonly name: string;
  readonly color: string;
  readonly status: ActivityCategoryStatus;
}

export interface ActivityCategoryPostDTO {
  readonly name: string;
  readonly color: string;
}

export type ActivityCategoryPatchDTO = Partial<ActivityCategoryPostDTO>;

export interface ActivityDTO {
  readonly id: number;
  readonly name: string;
  readonly description: string;
  readonly activityCategoryId: number;
  readonly activityCategory: ActivityCategoryDTO;
  readonly clubId: number;
  readonly status: ActivityStatus;
  readonly ruleGroup: RuleGroup;
}

export interface ActivityCreateDTO {
  readonly activityCategoryId: number;
  readonly clubId: number;
  readonly description: string;
  readonly name: string;
}

export interface AutomationDTO {
  readonly id: number;
  readonly name: string;
  readonly description: string;
  readonly isActive: boolean;
  readonly isPublicAvailable: boolean;
  readonly audience: AutomationAudience;
  readonly purpose: AutomationPurpose;
  readonly trigger: AutomationTrigger;
  readonly template: TemplateDTO;
  readonly bccList: string[];
}

export interface CompanyDTO {
  readonly id: number;
  readonly companyNumber: string;
  readonly vatNumber: string;
  readonly name: string;
  readonly postCode: string;
  readonly addressLine1: string;
  readonly addressLine2?: string;
  readonly city: string;
  readonly timezone: string;
}

export interface SessionPackDTO {
  readonly id: number;
  readonly name: string;
  readonly description: string;
  readonly status: MembershipStatus;
  readonly purchasePrice: number;
  readonly price: number;
  readonly taxRate: number;
  readonly expiryType: ExpiryType;
  readonly expiryValue: number;
  readonly sessionsIncluded: number;
  readonly sessionsUnlimited: boolean;
  readonly termsConditions: string;
  readonly termsConditionsFilename: string;
  readonly termsConditionsFile: MultipartFile;
  readonly clubIdList: number[];
  readonly activityCategoryIdList: number[];
  readonly appointableCategoryIdList: number[];
  readonly isPublic: boolean;
}

export interface SessionPackSummaryDTO {
  readonly sessionPackId: number;
  readonly upfrontPriceDetails: PromotionPriceDetailDTO;
  readonly promotionCode: string;
  readonly promotionUpfrontAmount: number;
  readonly promotionUpfrontDiscountType: DiscountType;
  readonly validPromotionCode: ValidPromotionCode;
}
export interface EmailRecipientDTO {
  readonly leadId: number;
  readonly userMemberId: string;
}

export interface EmailSendingDTO {
  readonly emailRecipientList: EmailRecipientDTO[];
  readonly clubId: number;
  readonly isMarketing: boolean;
  readonly body: string;
  readonly subject: string;
  readonly fromEmail: string;
  readonly bccList: string[];
  readonly emailSendingFilters: EmailSendingFiltersDTO;
  readonly emailsToExclude: EmailsToExcludeDTO;
}

export interface EmailSendingFiltersDTO {
  readonly userType: UserType;
  readonly leadStatus: string;
  readonly leadSourceId: number;
  readonly membershipStatus: UserMemberSubscriptionStatus;
  readonly membershipId: number;
  readonly promoCodeId: number;
  readonly sessionPackId: number;
  readonly dateFrom: string;
  readonly dateTo: string;
  readonly smsCommunication: boolean;
  readonly emailCommunication: boolean;
}

export interface EmailSendingHistoryDTO extends TimelineDTO {
  readonly body: string;
  readonly subject: string;
  readonly toEmail: string;
  readonly createdBy: UserStaffBean;
  readonly sendTimestamp: string;
}
export interface EmailsToExcludeDTO {
  readonly leadIdList: number[];
  readonly userMemberIdList: string[];
}

export interface EmailSendingResult {
  readonly sentEmails: number;
  readonly sentAttempts: number;
  readonly failedEmails: string[];
  readonly failedAttemptsWithNoEmail: [
    { readonly firstName: string; readonly lastName: string },
  ];
}

export interface EventDTO {
  readonly id: number;
  readonly facilityId: number;
  readonly facility: FacilityDTO;
  readonly activityId: number;
  readonly activity: ActivityDTO;
  readonly clubId: number;
  readonly club: ClubBean;
  readonly userEventHostId: string;
  readonly userEventHost: UserStaffBean;
  readonly description: string;
  readonly status: EventStatus;
  readonly isPublic: boolean;
  readonly isBookable: boolean;
  readonly sessionCost: number;
  readonly capacity: number;
  readonly waitListCapacity: number;
  readonly isFullDayEvent: boolean;
  readonly startDate: string;
  readonly endDate: string;
  readonly startTime: string;
  readonly endTime: string;
  readonly isRecurring: boolean;
  readonly startRecurringDate: string;
  readonly endRecurringDate: string;
  readonly recurrenceType: EventRecurrenceType;
  readonly recurrencePeriod: number;
  readonly weekDays: DayOfWeek[];
  readonly createdBy: UserStaffBean;
  readonly createdDate: string;
  readonly updatedBy: UserStaffBean;
  readonly updatedDate: string;
}

export interface EventOccurrenceDTO {
  readonly id: number;
  readonly event: EventDTO;
  readonly startDate: string;
  readonly endDate: string;
  readonly status: EventOccurrenceStatus;
  readonly bookedCount: number;
  readonly waitingCount: number;
  readonly isAll: boolean;
  readonly eventRsvpList: EventRsvpDTO[];
  readonly waitlistPosition?: number;
}

export interface EventOccurrenceListItemDTO {
  readonly id: number;
  readonly event: EventDTO;
  readonly startDate: string;
  readonly endDate: string;
  readonly status: EventOccurrenceStatus;
  readonly bookedCount: number;
  readonly waitingCount: number;
  readonly isAll: boolean;
}

export interface EventRsvpDTO {
  readonly id: number;
  readonly userMember: UserMemberRsvp;
  readonly status: EventRsvpStatus;
}

export interface EventRsvpHistoryDTO extends TimelineDTO {
  readonly activityName: string;
  readonly eventDescription: string;
  readonly eventStartDate: string;
  readonly eventEndDate: string;
  readonly status: EventRsvpStatus;
  readonly eventHost: UserStaffBean;
}

export interface CalendarEventOccurrence {
  readonly eventOccurrenceId: number;
  readonly startDate: string;
  readonly endDate: string;
  readonly activityName: string;
  readonly activityDescription: string;
  readonly activityId: number;
  readonly hostName: string;
  readonly hostStaffId: string;
  readonly facilityName: string;
  readonly facilityId: number;
  readonly sessionCost: number;
  readonly capacity?: number;
  readonly waitListCapacity?: number;
  readonly bookedCount?: number;
  readonly waitingCount?: number;
  readonly bookable: boolean;
}

export interface CalendarEventOccurrenceAsMember
  extends CalendarEventOccurrence {
  readonly eventRsvpId?: number;
  readonly eventRsvpStatus?: EventRsvpStatus;
  readonly hostPicture?: string;
  readonly waitlistQueuePosition?: number;
}

export interface BookingAgendaResult {
  readonly eventRsvpId: number;
  readonly eventOccurrenceId: number;
  readonly startDate: string;
  readonly endDate: string;
  readonly bookingName: string;
  readonly bookingDescription: string;
  readonly bookable: boolean;
  readonly facilityName: string;
  readonly facilityId: number;
  readonly hostName: string;
  readonly hostPicture?: string;
  readonly hostStaffId: string;
  readonly eventRsvpStatus: EventRsvpStatus;
  readonly sessionCost: number;
  readonly capacity: number;
  readonly waitListCapacity: number;
  readonly bookedCount?: number;
  readonly waitingCount?: number;
}

export interface CalendarAppointment {
  readonly appointmentId: number;
  readonly recurringAppointmentId?: number;
  readonly startDate: string;
  readonly endDate: string;
  readonly appointableName: string;
  readonly participantName: string;
  readonly participantMemberId: string;
  readonly hostName?: string;
  readonly hostStaffId?: string;
  readonly facilityName?: string;
  readonly facilityId?: number;
  readonly facilityPicture?: string;
  readonly status: AppointmentStatus;
  readonly paymentStatus: AppointmentPaymentStatus;
}

export interface FacilityCategoryDTO {
  readonly id: number;
  readonly name: string;
}

export interface FacilityDTO {
  readonly id: number;
  readonly name: string;
  readonly description: string;
  readonly facilityCategoryId: number;
  readonly clubId: number;
  readonly status: FacilityStatus;
  readonly picture?: string;
}

export interface FacilityCreateDTO {
  readonly name: string;
  readonly facilityCategoryId: number;
  readonly description: string;
}

export interface InvoiceDTO {
  readonly number: string;
  readonly totalAmount: number;
  readonly chargeableAmount: number;
  readonly status: InvoiceStatus;
  readonly issueDate: string;
  readonly paymentIntentIdForAuthorization?: string;
}

export type InvoiceNewDTO = {
  readonly id: number;
  readonly number: string;
  readonly totalAmount: number;
  readonly status: InvoiceStatus;
  readonly issueDate: string;
  readonly lastPaymentErrorCode: string;
  readonly lastPaymentAttemptDate: string;
  readonly promotionCode: string;
  readonly dueAmount: number;
};

export type HighlightedInvoiceDTO = {
  readonly id: number;
  readonly number: string;
  readonly totalAmount: number;
  readonly status: InvoiceStatus;
  readonly issueDate: string;
  readonly lastPaymentErrorCode?: string;
  readonly lastPaymentAttemptDate?: string;
  readonly paymentAttemptCount: number;
  readonly paymentAutoRetryCount: number;
  readonly paymentAutoRetryLimit: number;
  readonly nextAutoRetryDate: string;
  readonly dueAmount: number;
};

export interface InvoiceRevenueReportItemDTO {
  readonly invoiceDate: string;
  readonly userMemberId: string;
  readonly name: string;
  /** @description price after discounts */
  readonly amount: number;
  readonly subscriptionName: string;
  /** @description price before discounts */
  readonly subscriptionPrice: number;
  readonly endContractDate?: string;
  readonly promoCode?: string;
  readonly promoDiscount?: string;
  readonly promoType?: DiscountType;
  readonly cyclesLeft?: string;
  readonly recurringDiscountDuration?: string;
}

export interface InvoiceRevenueReportDTO {
  readonly total: number;
  readonly data: ApiListResponse<InvoiceRevenueReportItemDTO>;
}

export interface LeadDTO extends Searchable {
  readonly id: number;
  readonly mobileNumber: string;
  readonly source: LeadSourceDTO;
  readonly emailCommunication: boolean;
  readonly smsCommunication: boolean;
  readonly createdDate: string;
  readonly leadStatus: LeadStatusDTO;
  readonly userMember: UserMemberBean;
  readonly userMemberId?: string;
}

export interface LeadPipelineItem {
  readonly leadId: number;
  readonly createdDate: string;
  readonly firstName: string;
  readonly lastName: string;
  readonly email: string;
  readonly mobileNumber: string;
  readonly emailCommunication: boolean;
  readonly smsCommunication: boolean;
  readonly userMemberId?: string;
  readonly leadStatusId: number;
  readonly leadStatusName: string;
  readonly leadSourceId: number;
  readonly leadSourceName: string;
  readonly leadTasks?: { taskId: number; taskName: string }[];
}

export interface LeadDTOItem {
  readonly id: number;
  readonly firstName: string;
  readonly lastName: string;
  readonly mobileNumber?: string;
  readonly email: string;
  readonly source: LeadSourceDTO;
  readonly emailCommunication: boolean;
  readonly smsCommunication: boolean;
  readonly createdDate: string;
  readonly leadStatus: LeadStatusDTO;
  readonly userType: UserType;
  readonly profileType: UserProfileType;
}

export interface LeadFieldDTO {
  readonly id: number;
  readonly firstName: string;
  readonly lastName: string;
  readonly mobileNumber: string;
  readonly email: string;
  readonly source: LeadSourceDTO;
  readonly emailCommunication: boolean;
  readonly smsCommunication: boolean;
  readonly createdDate: string;
  readonly leadStatus: LeadStatusDTO;
}

export interface LeadPostDTO {
  readonly firstName: string;
  readonly lastName: string;
  readonly mobileNumber: string;
  readonly email: string;
  readonly sourceId: number;
  readonly emailCommunication: boolean;
  readonly smsCommunication: boolean;
}

export interface LeadSourceDTO {
  readonly id: number;
  readonly name: string;
  readonly activeSource: boolean;
  readonly createdBy: UserStaffBean;
  readonly createdDate: string;
}

export interface LeadSourceCreateDTO {
  readonly name: string;
}

export interface LeadSourcePostDTO {
  readonly sourceName: string;
}

export interface LeadTaskDTO {
  readonly id: string;
  readonly userMemberId: string;
  readonly email: string;
  readonly phoneNumber: string;
  readonly firstName: string;
  readonly lastName: string;
  readonly source: LeadSourceDTO;
  readonly userType: UserType;
  readonly profileType: UserProfileType;
}

export interface NoteDashboardDTO extends TimelineDTO {
  readonly content: string;
  readonly attachment: string;
  readonly attachmentFilename: string;
  readonly updatedDate: string;
  readonly userMemberId: string;
  readonly createdBy: string;
  readonly createdByFirstName: string;
  readonly createdByLastName: string;
  readonly leadId: string;
}

export type CardBrandType =
  | "amex"
  | "diners"
  | "discover"
  | "eftpos_au"
  | "jcb"
  | "mastercard"
  | "unionpay"
  | "visa"
  | "unknown";

export type PaymentMethodWalletType =
  | "LINK"
  | "GOOGLE_PAY"
  | "APPLE_PAY"
  | "UNSUPPORTED";

export interface PaymentMethodDTO {
  /*** @deprecated - use paymentMethodId instead, on the portal this is mapped from paymentMethodId */
  readonly id: string;
  readonly last4Digits?: string;
  readonly expMonth?: number;
  readonly expYear?: number;

  readonly linkEmail?: string;

  readonly createdDate: string;
  readonly defaultPaymentMethod: boolean;

  readonly type: PaymentMethodWalletType;
  readonly cardBrand: CardBrandType;

  readonly paymentMethodStatus: "Active" | "Removed" | "Invalid";
  readonly paymentMethodId: string;
}

export interface ProductAddonDTO {
  readonly id: number;
  readonly quantity: number;
}

export interface ProductCategoryDTO {
  readonly id: number;
  readonly name: string;
}

export interface ProductDTO {
  readonly id: number;
  readonly name: string;
  readonly description: string;
  readonly productCategoryId: number;
  readonly currency: string;
  readonly purchasePrice: number;
  readonly price: number;
  readonly taxRate: number;
  readonly stockQuantity: number;
  readonly clubIdList: number[];
  readonly status: ProductStatus;
  readonly stockType: ProductStockType;
  readonly availableToAllClubs: boolean;
  readonly allowAppPurchases: boolean;
  readonly allowWebPurchases: boolean;
  readonly picture?: string;
}

export interface ProductOrderDetailDTO {
  readonly productId: number;
  readonly quantity: number;
}

export interface ProductOrderPostDTO {
  readonly productOrderList: ProductOrderDetailDTO[];
  readonly userMemberId: string;
  readonly paymentMethodId: string;
  readonly clubId: number;
  readonly promotionCode: string;
}
export type MemberProductOrderPostDTO = Omit<
  Omit<ProductOrderPostDTO, "clubId">,
  "userMemberId"
>;

export interface ProductOrderPriceDetailDTO {
  readonly productId: number;
  readonly quantity: number;
  readonly totalAmount: number;
  readonly discountedTotalAmount: number;
}

export interface ProductOrderSummaryDTO {
  readonly productOrderPriceDetails: ProductOrderPriceDetailDTO[];
  readonly upfrontPriceDetails: PromotionPriceDetailDTO;
  readonly promotionCode: string;
  readonly promotionUpfrontAmount: number;
  readonly promotionUpfrontDiscountType: DiscountType;
  readonly validPromotionCode: ValidPromotionCode;
}

export interface ProductOrderSummaryPostDTO {
  readonly productOrderList: ProductOrderDetailDTO[];
  readonly userMemberId: string;
  readonly promotionCode: string;
}
export type MemberProductOrderSummaryPostDTO = Omit<
  ProductOrderSummaryPostDTO,
  "userMemberId"
>;

export interface PromotionPriceDetailDTO {
  readonly totalAmountBeforeDiscount: number;
  readonly totalAmountToPay: number;
  readonly totalAmountDifference: number;
}

export interface ResumeEarlierSubscriptionDTO {
  readonly earlyPauseEndDate: string;
  readonly paymentMethodId: string;
  readonly isWaived: boolean;
}

export interface RevenueByTransactionTypeDTO {
  readonly date: string;
  readonly amount: number;
  readonly name: string;
  readonly staffMember: string;
}

export interface RevenueDTO {
  readonly id: string;
  readonly date: string;
  readonly item: string;
  readonly quantitySold: number;
  readonly amountIncTax: number;
  readonly taxRate: number;
  readonly category: RevenueCategory;
  readonly type: RevenueItemType;
  readonly soldTo: string;
  readonly soldBy: string;
}
export const WindowTypeValues = ["HOURS", "DAYS", "MONTHS", "MINUTES"] as const;
export type WindowType = (typeof WindowTypeValues)[number];

export type BookingNoticeWindowType = WindowType;
export type WindowRule = { windowType: WindowType; windowValue: string };
export type UserFormRule = {
  readonly mobileNumber: FormRule;
  readonly dateOfBirth: FormRule;
  readonly addressLine: FormRule;
  readonly postCode: FormRule;
  readonly city: FormRule;
  readonly gender: FormRule;
  readonly emergencyContact: FormRule;
  readonly personalNumber: FormRule;
};
export type SubscriptionCancellationRule = {
  readonly allowCustomerCancelMemberships: boolean;
  readonly minimumPeriodNotice: number;
};
export type SubscriptionMembershipChangeRule = {
  readonly allowCustomerChangeMemberships: boolean;
  readonly minimumPeriodNotice: number;
};
export interface RuleClubDTO {
  readonly ruleId: number;
  readonly value: string;
  readonly ruleType: RuleType;
  readonly bookingCancellationRule?: WindowRule & { cancellationFee: number };
  readonly noShowFeeRule?: { noShowFeeValue: number };
  readonly bookingRule?: WindowRule;
  readonly bookingNoticePeriodRule?: WindowRule;
  readonly userFormRule?: UserFormRule;
  readonly checkOutRule?: { readonly windowValue: string };
  readonly subscriptionCancellationRule?: SubscriptionCancellationRule;
}

export interface RuleGroupPost {
  readonly name: string;
  readonly groupType: RuleGroupType;
  readonly ruleClubList: {
    readonly ruleType: RuleType;
    readonly bookingCancellationRule?: WindowRule & {
      cancellationFee: number;
    };
    readonly noShowFeeRule?: { noShowFeeValue: number };
    readonly bookingRule?: WindowRule;
    readonly userFormRule?: UserFormRule;
    readonly checkOutRule?: { readonly windowValue: string };
    readonly subscriptionCancellationRule?: SubscriptionCancellationRule;
    readonly subscriptionMembershipChangeRule?: SubscriptionMembershipChangeRule;
    readonly rescheduleNoticePeriodRule?: {
      readonly windowType: BookingNoticeWindowType;
      readonly windowValue: string;
    };
    readonly bookingNoticePeriodRule?: {
      readonly windowType: BookingNoticeWindowType;
      readonly windowValue: string;
    };
    readonly bookingLimitRule?: {
      limitValue: number;
    };
    readonly concurrentBookingRule?: {
      readonly restrictConcurrentBookings: boolean;
    };
  }[];
  readonly appointableIdList?: number[];
  readonly membershipIdList?: number[];
  readonly classIdList?: number[];
}

export interface RuleGroupPatch {
  readonly name?: string;
  readonly ruleClubList: {
    readonly ruleClubId: number;
    readonly bookingCancellationRule?: WindowRule & {
      cancellationFee: number;
    };
    readonly noShowFeeRule?: { noShowFeeValue: number };
    readonly bookingRule?: WindowRule;
    readonly userFormRule?: UserFormRule;
    readonly checkOutRule?: { readonly windowValue: string };
    readonly subscriptionCancellationRule?: SubscriptionCancellationRule;
    readonly rescheduleNoticePeriodRule?: {
      readonly windowType: BookingNoticeWindowType;
      readonly windowValue: string;
    };
    readonly bookingNoticePeriodRule?: {
      readonly windowType: BookingNoticeWindowType;
      readonly windowValue: string;
    };
    readonly concurrentBookingRule?: {
      readonly restrictConcurrentBookings: boolean;
    };
    readonly bookingLimitRule?: {
      readonly limitValue: number;
    };
  }[];
  readonly appointableIdList?: number[];
  readonly membershipIdList?: number[];
  readonly classIdList?: number[];
}

export type SubscriptionCancelationRule = {
  allowCustomerCancelMemberships: boolean;
  /*** In months */
  minimumPeriodNotice: number;
};

export interface FormRule {
  readonly isRequired: boolean;
}

export type RuleType =
  | "BOOKING_CANCELLATION_WINDOW"
  | "BOOKING_LIMIT_WINDOW"
  | "NO_SHOW_FEE"
  | "USER_FORM_REQUIREMENT_CONFIG"
  | "AUTOMATIC_CHECK_OUT"
  | "SUBSCRIPTION_CANCELLATION"
  | "SUBSCRIPTION_MEMBERSHIP_CHANGE"
  | "BOOKING_NOTICE_PERIOD"
  | "BOOKING_LIMIT_PER_CUSTOMER"
  | "CONCURRENT_BOOKING"
  | "RESCHEDULE_NOTICE_PERIOD";

export interface RuleDTO {
  readonly id: number;
  readonly ruleType: RuleType;
  readonly ruleName: string;
  readonly ruleDefaultValue: string;
  readonly ruleDescription: string;
}

export type RuleGroupType =
  | "APPOINTABLE_GROUP"
  | "CLASS_GROUP"
  | "MEMBERSHIP_GROUP"
  | "ACCESS_GROUP";

export interface RuleGroupItemDTO {
  readonly ruleId: number;
  readonly clubRuleId: number;
  readonly ruleType: RuleType;
  readonly bookingCancellationRule?: {
    readonly windowType: WindowType;
    readonly windowValue: string;
    readonly cancellationFee: number;
  };
  readonly bookingRule?: WindowRule;
  readonly noShowFeeRule?: {
    readonly noShowFeeValue: number;
  };
  readonly bookingLimitRule?: {
    readonly limitValue?: number;
  };
  readonly subscriptionCancellationRule?: {
    readonly allowCustomerCancelMemberships: boolean;
    readonly minimumPeriodNotice: number;
  };
  readonly subscriptionMembershipChangeRule?: {
    readonly allowCustomerChangeMemberships: boolean;
    readonly minimumPeriodNotice: number;
  };
  readonly checkOutRule?: {
    readonly windowValue: string;
  };
  readonly rescheduleNoticePeriodRule?: WindowRule;
  readonly bookingNoticePeriodRule?: WindowRule;
  readonly concurrentBookingRule?: {
    readonly restrictConcurrentBookings: boolean;
  };
}

export type RuleGroup = {
  readonly id: number;
  readonly name: string;
  readonly isDefault: boolean;
  readonly groupType: RuleGroupType;
  readonly isActive: boolean;
  readonly createdDate: string;
  readonly ruleClubList: RuleGroupItemDTO[];
  readonly appointableList?: AppointableDTO[];
  readonly membershipList?: Membership[];
  readonly classList?: { readonly id: number; readonly name: string }[];
};

export interface ScaConfirmationDTO {
  readonly invoiceNumber: string;
  readonly invoiceAmount: string;
  readonly currency: string;
  readonly result: ScaResponse;
  readonly message: string;
}

export interface MembershipAddonDTO {
  readonly id: number;
  readonly sessionPackId: number;
  readonly productId: number;
  readonly name: string;
  readonly activityCategoryIdList: number[];
  readonly appointableCategoryIdList: number[];
  readonly sessionsUnlimited: boolean;
  readonly quantity: number;
  readonly recurring: boolean;
  readonly price: number;
  readonly currency: string;
  readonly credits: number;
}

export interface MembershipSummaryDTO {
  readonly membershipId: number;
  readonly recurringMembershipAndAddonsProratedAmount: number;
  readonly prepaidMembershipAmount: number;
  readonly addonsProratedList?: MembershipAddonPurchaseDTO[];
  readonly upfrontPriceDetails: PromotionPriceDetailDTO;
  readonly prorataPriceDetails: PromotionPriceDetailDTO;
  readonly recurringPriceDetails: PromotionPriceDetailDTO;
  readonly promotionCode: string;
  readonly promotionUpfrontAmount: number;
  readonly promotionUpfrontDiscountType: DiscountType;
  readonly promotionRecurringAmount: number;
  readonly promotionRecurringDiscountType: DiscountType;
  readonly promotionRecurringDiscountDuration: number;
  readonly validPromotionCode: ValidPromotionCode;
}

export interface SubscriptionPromotion {
  readonly subscriptionId: number;
  readonly promotionId: number;
  readonly promotionCode: string;
  readonly recurringPriceDetails: {
    readonly totalAmountBeforeDiscount: number;
    readonly totalAmountToPay: number;
    readonly totalAmountDifference: number;
  };
  readonly promotionRecurringAmount: number;
  readonly promotionRecurringDiscountType: DiscountType;
  readonly promotionRecurringDiscountDuration: number;
}

export interface SubscriptionPromotionSummary extends SubscriptionPromotion {
  readonly validPromotionCode: ValidPromotionCode;
}

export interface MembershipSubscriptionHistoryTimelineDTO extends TimelineDTO {
  readonly name: string;
  readonly status: MembershipSubscriptionHistoryStatus;
}

export interface TaskDTO extends TimelineDTO {
  readonly name: string;
  readonly complete: boolean;
  readonly deadlineDate: string;
  readonly deadlineTime: string;
  readonly notes: string;
  readonly taskRelatedLeads: LeadTaskDTO[];
  readonly taskRelatedUsers: UserMemberTaskDTO[];
  readonly taskOwners: UserStaffTaskDTO[];
  readonly createdBy: UserStaffBean;
}

export interface TaskPatchDTO {
  readonly name: string;
  readonly complete: boolean;
  readonly notes: string;
  readonly ownerIdList: string[];
  readonly relatedUserIdList: string[];
  readonly relatedLeadIdList: number[];
  readonly deadlineDate: string;
  readonly deadlineTime: string;
}

export interface TaskPostDTO {
  readonly name: string;
  readonly deadlineDate: string;
  readonly deadlineTime: string;
  readonly notes: string;
  readonly ownerIdList: string[];
  readonly relatedUserIdList: string[];
  readonly relatedLeadIdList: number[];
}

export interface TemplateDTO {
  readonly id: number;
  readonly templateName: string;
  readonly templateType: TemplateType;
  readonly subject: string;
  readonly isEditable: boolean;
  readonly isAutomationTemplate: boolean;
  readonly body: string;
  readonly createdBy: UserStaffBean;
  readonly templatePlaceholderType: AutomationType;
  readonly createdDate: string;
  readonly updatedDate: string;
  readonly updatedBy: UserStaffBean;
  readonly club: ClubBean;
}

export interface TemplatePatchDTO {
  readonly templateName: string;
  readonly body: string;
  readonly subject: string;
}

export interface TemplatePostDTO {
  readonly templateName: string;
  readonly templateType: TemplateType;
  readonly subject: string;
  readonly body: string;
  readonly clubId: number;
}

export interface TimelineDTO {
  readonly id: number;
  readonly nodeType: TimelineNodeType;
  readonly createdDate: string;
}

export interface TotalEventAttendanceDTO {
  readonly total: number;
}

export interface TotalOverdueDTO {
  readonly total: number;
}

export interface TotalRevenueDTO {
  readonly total: number;
}
export interface EventAttendanceDTO {
  readonly userMemberEventAttendanceDTOPage: ApiListResponse<UserMemberEventAttendanceDTO>;
  readonly attendanceTotal: number;
}

export interface UserMemberEventAttendanceDTO
  extends UserMemberEventAttendanceView {
  readonly email: string;
  readonly lastAttended: string;
}

export interface ClassesRunItemDTO {
  readonly startTime: string;
  readonly className: string;
  readonly capacity: number;
  readonly trainer: string;
  readonly occurrenceId: number;
  readonly occupancy: number;
  readonly occupancyPercentage: number;
}

export interface ClassesRunDTO {
  readonly classesRunReportDTO: ApiListResponse<ClassesRunItemDTO>;
  readonly classesRunTotal: number;
}

export interface NoShowItemDTO {
  readonly startTime: string;
  readonly className: string;
  readonly trainerFirstName: string;
  readonly trainerLastName: string;
  readonly memberFirstName: string;
  readonly memberLastName: string;
  readonly feeCollected: number;
  readonly cancellationTime: string;
}

export interface ReportDTO<T> {
  readonly items: ApiListResponse<T>;
  readonly totalCount: number;
}

export interface LateCancellationItemDTO {
  readonly startTime: string;
  readonly className: string;
  readonly trainerFirstName: string;
  readonly trainerLastName: string;
  readonly memberFirstName: string;
  readonly memberLastName: string;
  readonly cancellationTime: string;
  readonly feeCollected: number;
}

export interface MembershipSoldDTO {
  readonly firstName: string;
  readonly lastName: string;
  readonly userMemberId: string;
  readonly membershipName: string;
  readonly createdDate: string;
}

export interface MembershipPausingDTO {
  readonly subscriptionId: number;
  readonly userMemberId: string;
  readonly firstName: string;
  readonly lastName: string;
  readonly membershipName: string;
  readonly membershipType: string;
  readonly pauseDate: string;
  readonly resumeDate: string;
}

export interface MembershipResumingDTO {
  readonly subscriptionId: number;
  readonly userMemberId: string;
  readonly firstName: string;
  readonly lastName: string;
  readonly membershipName: string;
  readonly membershipType: string;
  readonly pauseDate: string;
  readonly resumeDate: string;
}

export interface MembershipSoldReportDTO {
  readonly membershipSoldDTO: ApiListResponse<MembershipSoldDTO>;
  readonly membershipSoldTotal: number;
}

export type ReportMemberDTO = {
  readonly email: string;
  readonly firstName: string;
  readonly lastName: string;
  readonly mobileNumber: string;
  readonly userMemberId: string;
  readonly cancellationDate?: string;
  readonly expiryDate?: string;
  readonly membershipType?: string;
  readonly pausedDate?: string;
  readonly resumeDate?: string;
  readonly startDate?: string;
};

export type MembershipReportDTO = ApiListResponse<ReportMemberDTO>;

export type ReportMembershipEndingLineDTO = {
  readonly subscriptionId: number;
  readonly userMemberId: string;
  readonly firstName: string;
  readonly lastName: string;
  readonly email: string;
  readonly membershipName: string;
  readonly membershipType: string;
  readonly endDate: string;
};

export type MembershipEndingReportDTO =
  ApiListResponse<ReportMembershipEndingLineDTO>;

export interface LeadReportDTO {
  readonly leadReportByPresetStatusViewDTO: ApiListResponse<LeadReportItemDTO>;
  readonly leadReportTotal: number;
}

export interface OpenLeadReportDTO {
  readonly leadReportByOpenStatusViewDTO: ApiListResponse<LeadReportItemDTO>;
  readonly leadReportTotal: number;
}

export interface RevenueReportItemDTO {
  readonly amountIncTax: number;
  readonly category: string;
  readonly date: string;
  readonly id: string;
  readonly item: string;
  readonly quantitySold: number;
  readonly soldBy: string;
  readonly soldTo: string;
  readonly taxRate: number;
  readonly type: string;
  readonly latePayment: boolean;
  readonly paymentType: "CARD" | "LINK" | "CUSTOM";
  readonly linkEmail?: string;
  readonly last4Digits?: string;
  readonly cardBrand?: CardBrandType;
  readonly walletType?: PaymentMethodWalletType;
  readonly customPaymentCategoryName?: string;
}

export interface RevenueReportDTO {
  readonly data: ApiListResponse<RevenueReportItemDTO>;
  readonly total: number;
}

export interface TransactionReportItemDTO {
  readonly amount: number;
  readonly invoiceDate?: string;
  readonly date?: string;
  readonly name: string;
}

export interface TransactionReportDTO {
  readonly data: ApiListResponse<TransactionReportItemDTO>;
  readonly total: number;
}

export interface UserMemberTaskDTO {
  readonly id: string;
  readonly email: string;
  readonly phoneNumber: string;
  readonly leadDTO: LeadDTO;
  readonly firstName: string;
  readonly lastName: string;
  readonly picture?: string;
  readonly profileType: UserProfileType;
  readonly userType: UserType;
  readonly source: LeadSourceDTO;
}

export interface UserStaffTaskDTO {
  readonly id: string;
  readonly firstName: string;
  readonly lastName: string;
  readonly picture?: string;
}

export interface MultipartFile extends InputStreamSource {
  readonly name: string;
  readonly bytes: any;
  readonly empty: boolean;
  readonly resource: Resource;
  readonly size: number;
  readonly contentType: string;
  readonly originalFilename: string;
}

export interface MembershipAddonPurchaseDTO {
  readonly id: number;
  readonly name: string;
  readonly amount: number;
  readonly taxRate: number;
  readonly quantity: number;
  readonly recurring: boolean;
  readonly sessionsUnlimited: boolean;
  readonly proratedSessions: number;
}

export interface Searchable {
  readonly email: string;
  readonly firstName: string;
  readonly lastName: string;
  readonly userType: UserType;
  readonly profileType: UserProfileType;
}

export interface UserMember {
  readonly id: string;
  readonly firstName: string;
  readonly lastName: string;
  readonly customerId: string;
  readonly createdDate: string;
  readonly updatedDate: string;
  readonly userType: UserType;
  readonly eventsAttended: number;
  readonly lead: Lead;
  readonly createdBy: UserStaff;
  readonly updatedBy: UserStaff;
  readonly userMemberSubscriptionList: UserMemberSubscription[];
  readonly userMemberDetails: UserMemberDetails;
  readonly accountBalance: AccountBalance;
  readonly paymentMethodList: PaymentMethod[];
  readonly invoiceList: Invoice[];
  readonly noteList: Note[];
  readonly checkoutSessionId: string;
  readonly setupIntentId: string;
  readonly paymentMethodToUse: PaymentMethod;
}

export interface Invoice {
  readonly id: number;
  readonly number: string;
  readonly totalAmount: number;
  readonly status: InvoiceStatus;
  readonly invoiceUrl: string;
  readonly userMember: UserMember;
  readonly issueDate: string;
  readonly createdDate: string;
  readonly updatedDate: string;
  readonly productOrder: ProductOrder;
  readonly subscriptionPaymentList: SubscriptionPayment[];
  readonly transactionList: Transaction[];
  readonly bacsPaymentList: BacsPayment[];
  readonly refundableAmount: number;
  readonly paymentIntentIdForAuthorization: string;
  readonly amountDue: number;
  readonly chargeableAmountDue: number;
}

export interface UserMemberAndLeadStatusView {
  readonly email: string;
  readonly userMemberId: string;
  readonly leadId: number;
  readonly membershipLength: number;
  readonly lifetimeValue: number;
  readonly sessionsRemaining: number;
  readonly membershipName: string;
  readonly sessionPackName: string;
  readonly membershipPrice: number;
  readonly leadSource: string;
  readonly postCode: string;
  readonly leadStatus: string;
  readonly createdDate: string;
  readonly firstName: string;
  readonly lastName: string;
  readonly userType: UserType;
  readonly emailCommunication: boolean;
  readonly smsCommunication: boolean;
  readonly mobileNumber: string;
  readonly dateBirth: string;
  readonly gender: Gender;
  readonly picture?: string;
  readonly membershipStatus: UserMemberSubscriptionStatus;
}

export interface LeadReportItemDTO {
  readonly createdDate: string;
  readonly email: string;
  readonly firstName: string;
  readonly lastName: string;
  readonly leadId: number;
  readonly mobileNumber: string;
  readonly status: string;
  readonly source: string;
  readonly userMemberId: string;
  readonly profileType: UserProfileType;
}

export interface UserMemberEventAttendanceView {
  readonly userMemberId: string;
  readonly attendance: number;
  readonly firstName: string;
  readonly lastName: string;
  readonly mobileNumber: string;
}

export interface Resource extends InputStreamSource {
  open: boolean;
  file: any;
  readable: boolean;
  url: URL;
  description: string;
  uri: {};
  filename: string;
}

export interface InputStreamSource {
  inputStream: any;
}

export interface Lead {
  readonly id: number;
  readonly firstName: string;
  readonly lastName: string;
  readonly email: string;
  readonly mobileNumber: string;
  readonly source: LeadSource;
  readonly emailCommunication: boolean;
  readonly smsCommunication: boolean;
  readonly createdDate: string;
  readonly updatedDate: string;
  readonly userMember: UserMember;
  readonly leadStatus: LeadStatus;
  readonly leadHistories: LeadHistory[];
  readonly dateFrom: string;
  readonly dateTo: string;
}

export interface UserStaff {
  readonly id: string;
  readonly firstName: string;
  readonly lastName: string;
  readonly mobileNumber: string;
  readonly picture?: string;
  readonly activeUser: boolean;
  readonly createdDate: string;
  readonly createdBy: UserStaff;
  readonly updatedDate: string;
  readonly updatedBy: UserStaff;
  readonly noteList: Note[];
}

export interface UserMemberSubscription extends ChargeableMembership {
  readonly id: number;
  readonly membershipAddonSubscriptionList: UserMemberSubscription[];
  readonly userMember: UserMember;
  readonly product: Product;
  readonly membershipAddon: MembershipAddon;
  readonly sessionsUnlimited: boolean;
  readonly name: string;
  readonly price: number;
  readonly taxRate: number;
  readonly isTrial: boolean;
  readonly status: UserMemberSubscriptionStatus;
  readonly sessionsLeft: number;
  readonly sessionsIncluded: number;
  readonly startDate: string;
  readonly endDate: string;
  readonly endContractDate: string;
  readonly cancellationDate: string;
  readonly cycle: number;
  readonly createdDate: string;
  readonly updatedDate: string;
  readonly pauseStartDate: string;
  readonly pauseEndDate: string;
  readonly createdBy: UserStaff;
  readonly updatedBy: UserStaff;
  readonly subscriptionPaymentList: SubscriptionPayment[];
  readonly recurringPromotion: Promotion;
  readonly recurringPromotionUsage: RecurringPromotionUsage;
  readonly sessionPackUsageList: RsvpSessionPackUsage[];
}

export interface UserMemberDetails {
  readonly userMemberId: string;
  readonly userMember: UserMember;
  readonly mobileNumber: string;
  readonly dateBirth: string;
  readonly postCode: string;
  readonly addressLine1: string;
  readonly addressLine2?: string;
  readonly city: string;
  readonly gender: Gender;
  readonly country: string;
  readonly emergencyContactName: string;
  readonly emergencyContact: string;
  readonly emailCommunication: boolean;
  readonly smsCommunication: boolean;
  readonly pushCommunication: boolean;
  readonly picture?: string;
}

export interface AccountBalance {
  readonly userMemberId: string;
  readonly userMember: UserMember;
  readonly balance: number;
  readonly totalCredit: number;
  readonly totalDebit: number;
  readonly balanceDate: string;
}

export interface PaymentMethod {
  readonly paymentMethodId: string;
  readonly defaultPaymentMethod: boolean;
  readonly userMember: UserMember;
  readonly type: PaymentMethodType;
  readonly status: PaymentMethodStatus;
  readonly createdDate: string;
  readonly paymentLogList: PaymentLog[];
  readonly bacsPaymentList: BacsPayment[];
  readonly bacsMandate: BacsMandate;
}

export interface Note {
  readonly id: number;
  readonly content: string;
  readonly attachment: string;
  readonly attachmentFilename: string;
  readonly createdDate: string;
  readonly updatedDate: string;
  readonly dateFrom: string;
  readonly dateTo: string;
  readonly userMember: UserMember;
  readonly lead: Lead;
  readonly createdBy: UserStaff;
  readonly timeline: Timeline;
}

export interface ProductOrder {
  readonly id: number;
  readonly userMember: UserMember;
  readonly soldBy: UserStaff;
  readonly orderDate: string;
  readonly invoice: Invoice;
  readonly productOrderDetailList: ProductOrderDetail[];
}

export interface SubscriptionPayment {
  readonly id: number;
  readonly userMemberSubscription: UserMemberSubscription;
  readonly invoice: Invoice;
  readonly paymentDate: string;
}

export interface Transaction {
  readonly id: number;
  readonly amount: number;
  readonly transactionDate: string;
  readonly type: TransactionType;
  readonly refundId: string;
  readonly userMember: UserMember;
  readonly paymentLog: PaymentLog;
  readonly invoice: Invoice;
}

export interface BacsPayment {
  readonly id: number;
  readonly stripePaymentId: string;
  readonly status: BacsPaymentStatus;
  readonly amount: number;
  readonly checkoutSessionId: string;
  readonly setupIntentId: string;
  readonly createdDate: string;
  readonly updatedDate: string;
  readonly invoice: Invoice;
  readonly paymentMethod: PaymentMethod;
}

export interface LeadSource {
  readonly id: number;
  readonly sourceName: string;
  readonly activeSource: boolean;
  readonly createdDate: string;
  readonly updatedDate: string;
  readonly createdBy: UserStaff;
  readonly updatedBy: UserStaff;
}

export interface LeadStatus {
  readonly id: number;
  readonly name: string;
  readonly presetType: PresetLeadStatusType;
  readonly statusOrder: number;
  readonly deletable: boolean;
  readonly deleted: boolean;
  readonly editableOrder: boolean;
  readonly createdDate: string;
  readonly updatedDate: string;
  readonly lead: Lead[];
  readonly leadHistories: LeadHistory[];
}

export interface LeadHistory {
  readonly id: number;
  readonly createdDate: string;
  readonly lead: Lead;
  readonly leadStatus: LeadStatus;
  readonly statusColumnName: string;
  readonly userStaff: UserStaff;
  readonly timeline: Timeline;
}

export interface Product {
  readonly id: number;
  readonly name: string;
  readonly description: string;
  readonly productCategory: ProductCategory;
  readonly currency: string;
  readonly purchasePrice: number;
  readonly price: number;
  readonly taxRate: number;
  readonly stockQuantity: number;
  readonly status: ProductStatus;
  readonly stockType: ProductStockType;
  readonly createdDate: string;
  readonly updatedDate: string;
  readonly createdBy: UserStaff;
  readonly updatedBy: UserStaff;
  readonly clubList: Club[];
}

export interface MembershipAddon {
  readonly id: number;
  readonly membership: Membership;
  readonly product: Product;
  readonly sessionPack: SessionPack;
  readonly recurring: boolean;
  readonly quantity: number;
}

export interface Promotion {
  readonly id: number;
  readonly name: string;
  readonly code: string;
  readonly upfrontDiscount: boolean;
  readonly upfrontDiscountProrata: boolean;
  readonly upfrontDiscountAmount: number;
  readonly upfrontDiscountType: DiscountType;
  readonly recurringDiscount: boolean;
  readonly recurringDiscountAmount: number;
  readonly recurringDiscountType: DiscountType;
  readonly recurringDiscountDuration: number;
  readonly limitProduct: Product;
  readonly limitMembership: Membership;
  readonly limitSessionPack: SessionPack;
  readonly limitUsages: number;
  readonly limitUsagesPerMember: number;
  readonly trial: boolean;
  readonly active: boolean;
  readonly expiry: string;
  readonly createdDate: string;
  readonly createdBy: UserStaff;
  readonly updatedDate: string;
  readonly updatedBy: UserStaff;
  readonly promotionHistory: PromotionHistory[];
  readonly timesUsed: number;
  readonly recurringSubscriptions: UserMemberSubscription[];
}

export interface RecurringPromotionUsage {
  readonly id: number;
  readonly cyclesLeft: number;
  readonly promotion: Promotion;
  readonly subscription: UserMemberSubscription;
}

export interface RsvpSessionPackUsage {
  readonly id: number;
  readonly eventRsvp: EventRsvp;
  readonly sessionPackSubscription: UserMemberSubscription;
  readonly sessionsUsed: number;
}

export interface ChargeableMembership {
  readonly billingType: BillingType;
  readonly monthlyBillingDay: number;
  readonly weeklyBillingDay: DayOfWeek;
  readonly billingPeriod: number;
  readonly fixedBillingDay: boolean;
}

export interface PaymentLog {
  readonly id: number;
  readonly stripePaymentId: string;
  readonly paymentMethod: PaymentMethod;
  readonly paymentDate: string;
  readonly amount: number;
  readonly transaction: Transaction;
}

export interface BacsMandate {
  readonly id: number;
  readonly stripeMandateId: string;
  readonly status: BacsMandateStatus;
  readonly createdDate: string;
  readonly updatedDate: string;
  readonly paymentMethod: PaymentMethod;
}

export interface Timeline {
  readonly id: string;
  readonly nodeType: TimelineNodeType;
  readonly createdDate: string;
  readonly dateFrom: string;
  readonly dateTo: string;
  readonly userMember: UserMember;
  readonly lead: Lead;
  readonly note: Note;
  readonly eventRsvpHistory: EventRsvpHistory;
  readonly leadStatusHistory: LeadHistory;
  readonly emailHistory: EmailSendingHistory;
  readonly membershipSubscriptionHistory: membershipSubscriptionHistory;
  readonly task: Task;
}

export interface ProductOrderDetail {
  readonly id: number;
  readonly productOrder: ProductOrder;
  readonly product: Product;
  readonly quantity: number;
  readonly amount: number;
  readonly taxRate: number;
}

export interface ProductCategory {
  readonly id: number;
  readonly name: string;
}

export interface Club {
  readonly id: number;
  readonly name: string;
  readonly postCode: string;
  readonly addressLine1: string;
  readonly addressLine2?: string;
  readonly city: string;
  readonly defaultCurrency: string;
  readonly defaultTaxRate: number;
  readonly stripeApplicationId: string;
  readonly stripeApplicationFeePercentage: number;
  readonly createdDate: string;
  readonly closeDate: string;
  readonly updatedDate: string;
  readonly termsConditions: string;
  readonly termsConditionsFilename: string;
  readonly timezone: string;
  readonly email: string;
  readonly createdBy: UserStaff;
  readonly updatedBy: UserStaff;
  readonly membershipList: Membership[];
  readonly productList: Product[];
  readonly company: Company;
}

export interface Membership extends ChargeableMembership {
  readonly id: number;
  readonly name: string;
  readonly description: string;
  readonly type: MembershipType;
  readonly durationType: MembershipDurationType;
  readonly duration: number;
  readonly currency: string;
  readonly defaultPrice: number;
  readonly taxRate: number;
  readonly endDate: string;
  readonly status: MembershipStatus;
  readonly termsConditions: string;
  readonly termsConditionsFilename: string;
  readonly isPublic: boolean;
  readonly isTrial: boolean;
  readonly calculateProrata: ProrataCalculation;
  readonly createdDate: string;
  readonly updatedDate: string;
  readonly createdBy: UserStaff;
  readonly updatedBy: UserStaff;
  readonly userMemberSubscriptionList: UserMemberSubscription[];
  readonly clubList: Club[];
  readonly membershipAddonList?: MembershipAddon[];
  readonly hasSubscriptions: boolean;
  readonly fixedStartDate?: string;
  readonly fixedEndDate?: string;
}
export interface ClubPropertiesDTO {
  readonly allowCustomerCancelMemberships: boolean;
  readonly allowCustomerSelectingStartDate: boolean;
  readonly allowPausingMembershipsCustomer: boolean;
  readonly allowPhoneCountrySelection: boolean;
  readonly clubId: number;
  readonly dateFormat: string;
  readonly defaultNationality: Iso3Country;
  readonly gymOpeningDate: string;
  readonly longDateFormat: string;
  readonly maxImgUploadHeight: number;
  readonly maxImgUploadWidth: number;
  readonly minimumCharge: number;
  readonly phoneNumberCountry: Iso2Country[];
  readonly postalCodeCountry: Iso2Country;
  readonly shortDateFormat: string;
  readonly stripePublicKey: string;
  readonly stripeOnboardPublicKey: string;
}

export interface ClubPropertiesDTOWithRawPhoneNumberCountry
  extends Omit<ClubPropertiesDTO, "phoneNumberCountry"> {
  readonly phoneNumberCountry: string;
}

export interface SessionPack {
  readonly id: number;
  readonly name: string;
  readonly description: string;
  readonly status: MembershipStatus;
  readonly currency: string;
  readonly purchasePrice: number;
  readonly price: number;
  readonly taxRate: number;
  readonly sessionsIncluded: number;
  readonly sessionsUnlimited: boolean;
  readonly expiryType: ExpiryType;
  readonly expiryValue: number;
  readonly termsConditions: string;
  readonly termsConditionsFilename: string;
  readonly endDate: string;
  readonly isPublic: boolean;
  readonly createdDate: string;
  readonly updatedDate: string;
  readonly createdBy: UserStaff;
  readonly updatedBy: UserStaff;
  readonly clubList: Club[];
  readonly activityCategoryList: ActivityCategory[];
  readonly userMemberSubscriptionList: UserMemberSubscription[];
}

export interface PromotionHistory {
  readonly id: number;
  readonly promotion: Promotion;
  readonly usedBy: UserMember;
  readonly usedDate: string;
}

export interface EventRsvp {
  readonly id: number;
  readonly eventOccurrence: EventOccurrence;
  readonly userMember: UserMember;
  readonly status: EventRsvpStatus;
  readonly sessionPackUsageList: RsvpSessionPackUsage[];
  readonly eventRsvpHistoryList: EventRsvpHistory[];
}

export interface ChangedEventRsvp {
  readonly id: number;
  readonly userMember: UserMember;
  readonly status: EventRsvpStatus;
}

export interface EventRsvpHistory {
  readonly id: number;
  readonly eventRsvp: EventRsvp;
  readonly status: EventRsvpStatus;
  readonly createdDate: string;
  readonly dateFrom: string;
  readonly dateTo: string;
  readonly timeline: Timeline;
}

export interface EmailSendingHistory {
  readonly id: number;
  readonly body: string;
  readonly subject: string;
  readonly toEmail: string;
  readonly sendTimestamp: string;
  readonly lead: Lead;
  readonly userMember: UserMember;
  readonly createdBy: UserStaff;
  readonly createdDate: string;
  readonly timeline: Timeline;
}

export interface membershipSubscriptionHistory {
  readonly id: number;
  readonly userMemberSubscription: UserMemberSubscription;
  readonly status: MembershipSubscriptionHistoryStatus;
  readonly createdDate: string;
  readonly timeline: Timeline;
}

export interface ApiListResponse<T> {
  readonly content: T[];
  readonly pageable: Pageable | "INSTANCE";
  readonly last: boolean;
  readonly totalPages: number;
  readonly totalElements: number;
  readonly size: number;
  /** @descrition page number */
  readonly number: number;
  readonly sort: Sort;
  readonly first: boolean;
  readonly numberOfElements: number;
  readonly empty: boolean;
}

export interface ApiTokenListResponse<T> {
  readonly content: T[];
  readonly nextPageToken?: string;
}
interface Pageable {
  readonly offset: number;
  readonly pageNumber: number;
  readonly pageSize: number;
  readonly paged: boolean;
  readonly unpaged: boolean;
  readonly sort: Sort;
}

export type PaginationOptions = {
  page: number;
  size: number;
  sort?: { field: string; desc: boolean };
};

interface Sort {
  readonly empty: boolean;
  readonly sorted: boolean;
  readonly unsorted: boolean;
}

export interface Task {
  readonly id: number;
  readonly name: string;
  readonly complete: boolean;
  readonly deadlineDate: string;
  readonly deadlineTime: string;
  readonly notes: string;
  readonly createdDate: string;
  readonly updatedDate: string;
  readonly createdBy: UserStaff;
  readonly updatedBy: UserStaff;
  readonly taskRelatedUsers: UserMember[];
  readonly taskRelatedLeads: Lead[];
  readonly taskOwners: UserStaff[];
  readonly timeline: Timeline[];
}

export interface Company {
  readonly id: number;
  readonly companyNumber: string;
  readonly vatNumber: string;
  readonly name: string;
  readonly postCode: string;
  readonly addressLine1: string;
  readonly addressLine2?: string;
  readonly city: string;
  readonly stripeApplicationId: string;
  readonly stripeApplicationFeePercentage: number;
}

export interface ActivityCategory {
  readonly id: number;
  readonly name: string;
  readonly color: string;
}

export interface EventOccurrence {
  readonly id: number;
  readonly clubId: number;
  readonly event: Event;
  readonly startDate: string;
  readonly endDate: string;
  readonly dateFrom: string;
  readonly dateTo: string;
  readonly status: EventOccurrenceStatus;
  readonly eventRsvpList: EventRsvp[];
}

export interface Event {
  readonly id: number;
  readonly facility: Facility;
  readonly activity: Activity;
  readonly club: Club;
  readonly userEventHost: UserStaff;
  readonly description: string;
  readonly status: EventStatus;
  readonly isPublic: boolean;
  readonly isBookable: boolean;
  readonly sessionCost: number;
  readonly capacity: number;
  readonly waitListCapacity: number;
  readonly isFullDayEvent: boolean;
  readonly startDate: string;
  readonly endDate: string;
  readonly startTime: string;
  readonly endTime: string;
  readonly isRecurring: boolean;
  readonly startRecurringDate: string;
  readonly endRecurringDate: string;
  readonly recurrenceType: EventRecurrenceType;
  readonly recurrencePeriod: number;
  readonly weekDays: DayOfWeek[];
  readonly parentEvent: Event;
  readonly createdBy: UserStaff;
  readonly createdDate: string;
  readonly updatedBy: UserStaff;
  readonly updatedDate: string;
}

export interface Facility {
  readonly id: number;
  readonly name: string;
  readonly facilityCategory: FacilityCategory;
  readonly club: Club;
  readonly description: string;
  readonly status: FacilityStatus;
  readonly createdDate: string;
  readonly updatedDate: string;
  readonly createdBy: UserStaff;
  readonly updatedBy: UserStaff;
  readonly picture?: string;
}

export interface Activity {
  readonly id: number;
  readonly name: string;
  readonly activityCategory: ActivityCategory;
  readonly club: Club;
  readonly description: string;
  readonly status: ActivityStatus;
  readonly createdDate: string;
  readonly updatedDate: string;
  readonly createdBy: UserStaff;
  readonly updatedBy: UserStaff;
}

export interface FacilityCategory {
  readonly id: number;
  readonly name: string;
}

export interface ClubSettings {
  readonly accentColor: string;
  readonly backgroundColor: string;
  readonly cardColor: string;
  readonly font: string;
  readonly fontColor: string;
  readonly footerColor: string;
  readonly logo: string;
  readonly navbarColor: string;
  readonly primaryColor: string;
  readonly shadowColor: string;
  // New Hosted pages fields
  readonly darkMode: boolean;
  readonly membershipsNavEnabled: boolean;
  readonly creditPacksNavEnabled: boolean;
  readonly productNavEnabled: boolean;
  readonly appointmentsNavEnabled: boolean;
  readonly timetableNavEnabled: boolean;
  readonly leadCaptureNavEnabled: boolean;
  readonly yourWebsiteUrl: string;
  readonly redirectUrl: string;
  readonly grayShades: {
    [key in
      | "0"
      | "25"
      | "50"
      | "100"
      | "200"
      | "300"
      | "400"
      | "500"
      | "600"
      | "700"
      | "800"
      | "900"
      | "950"]: string;
  };
}

export type KisiLock = {
  readonly id: string;
  readonly name: string;
  readonly description: string;
  readonly latitude: number;
  readonly longitude: number;
  readonly configured: boolean;
  readonly online: boolean;
  readonly unlocked: boolean;
  readonly lockedDown: boolean;
  readonly open: boolean;
  readonly placeId: string;
};

export type GrowMetricsApiKey = {
  algorithm: string;
  apiToken?: string;
  createdDate: string;
  hash: string;
  id: string;
  leadSourceId?: string;
  name: string;
  type: string;
};

export type TimelineNodeType =
  | "NOTE"
  | "RSVP"
  | "EMAIL"
  | "LEADSTATUS"
  | "SUBSCRIPTION"
  | "TASK";

export type PresetLeadStatusType =
  | "NEW_LEAD"
  | "DEAL_CLOSED"
  | "DEAL_LOST"
  | "TRIAL";

export type DiscountType = "CURRENCY" | "PERCENTAGE";

export type MembershipType = "RECURRING" | "PREPAID";

export type MembershipDurationType =
  | "DAILY"
  | "WEEKLY"
  | "MONTHLY"
  | "NO_CONTRACT";

export type MembershipStatus = "ACTIVE" | "INACTIVE";

export type BillingType = "WEEKLY" | "MONTHLY";

export type DayOfWeek =
  | "MONDAY"
  | "TUESDAY"
  | "WEDNESDAY"
  | "THURSDAY"
  | "FRIDAY"
  | "SATURDAY"
  | "SUNDAY";

export type ProrataCalculation = "YES" | "NO" | "WAIVE";

export type Gender = "MALE" | "FEMALE" | "PREFER_NOT_TO_SAY";

export type UserType = "LEAD" | "CUSTOMER" | "MEMBER" | "DELETED";

export type UserProfileType = "USER" | "LEAD";

export type UserMemberSubscriptionStatus =
  | "ACTIVE"
  | "PENDING"
  | "PAUSED"
  | "CANCELLED"
  | "EXPIRED"
  | "DELETED"
  | "OVERDUE";

export type UserMemberSubscriptionHistoryStatus =
  | "ACTIVE"
  | "PENDING"
  | "PAUSED"
  | "CANCELLED"
  | "EXPIRED"
  | "DELETED"
  | "OVERDUE"
  | "UPGRADED"
  | "DOWNGRADED"
  | "CHANGED";

export type ActivityStatus = "ACTIVE" | "DISABLED";

export type AutomationAudience = "STAFF" | "USER";

export type AutomationPurpose = "MARKETING" | "ACCOUNT_RELATED";

export type AutomationTrigger =
  | "NEW_MEMBER"
  | "PAUSE_MEMBERSHIP"
  | "CANCEL_MEMBERSHIP"
  | "RESUME_MEMBERSHIP"
  | "SCHEDULE_PAUSE_MEMBERSHIP"
  | "UNDO_CANCELLATION_MEMBERSHIP"
  | "UNDO_SCHEDULE_PAUSE_MEMBERSHIP"
  | "SCHEDULE_CANCELLATION_MEMBERSHIP"
  | "DELETE_MEMBERSHIP"
  | "RESUME_EARLIER_MEMBERSHIP"
  | "OVERDUE_MEMBERSHIP"
  | "REACTIVATE_MEMBERSHIP"
  | "BOOKING_EVENT_WAITLIST"
  | "BOOKING_EVENT_BOOKED"
  | "BOOKING_EVENT_CANCELLED"
  | "EVENT_CANCELLED"
  | "NEW_PAYMENT_METHOD"
  | "NEW_BACS_PAYMENT_METHOD"
  | "NEW_DEFAULT_PAYMENT_METHOD"
  | "PURCHASE_MEMBERSHIP"
  | "PURCHASE_SESSION_PACK"
  | "NEW_LEAD"
  | "AUTHORIZE_PAYMENT";

export type ExpiryType = "WEEKLY" | "MONTHLY" | "NA";

export type ValidPromotionCode = "YES" | "NO" | "NOT_FOR_THIS_PURCHASE";

export type EventStatus = "CREATED" | "CANCELLED";

export type EventRecurrenceType = "DAILY" | "WEEKLY" | "MONTHLY" | "YEARLY";

export type EventOccurrenceStatus =
  | "CREATED"
  | "IN_PROGRESS"
  | "FINISHED"
  | "CANCELLED";

export type EventRsvpStatus =
  | "WAITING"
  | "BOOKED"
  | "ATTENDED"
  | "CANCELLED"
  | "LATE_CANCELLED"
  | "NO_SHOW";

export type FacilityStatus = "ACTIVE" | "DISABLED";

export type InvoiceStatus =
  | "PAID"
  | "SCHEDULED"
  | "RE_SCHEDULE"
  | "PAST_DUE"
  | "VOID"
  | "REFUNDED"
  | "PROCESSING"
  | "WAITING_CONFIRMATION"
  | "WRITTEN_OFF"
  | "AWAITING_AUTHORIZATION";

export type ProductStatus = "AVAILABLE" | "UNAVAILABLE";

export type ProductStockType = "LIMITED" | "UNLIMITED";

export type RevenueCategory = "PRODUCT" | "MEMBERSHIP" | "CREDIT_PACK";

export type RevenueItemType = "PURCHASE" | "REFUND";

export type ScaResponse =
  | "PAYMENT_AUTHORIZATION_NOT_REQUIRED"
  | "FAILED"
  | "SUCCEEDED"
  | "PAYMENT_AUTHORIZATION_REQUIRED";

export type MembershipSubscriptionHistoryStatus =
  | "ACTIVE"
  | "PENDING"
  | "PAUSED"
  | "CANCELLED"
  | "EXPIRED"
  | "DELETED"
  | "OVERDUE";

export type TemplateType = "SMS" | "EMAIL";

export type AutomationType =
  | "MEMBERSHIP"
  | "EVENT"
  | "PAYMENT"
  | "GENERIC"
  | "LEAD"
  | "FAIL_PAYMENT"
  | "STRONG_CUSTOMER_AUTHENTICATION"
  | "ACCOUNT_DELETE";

export type AutomationPlaceholder =
  | "activityName"
  | "amount"
  | "authorizationUrl"
  | "cancellationDate"
  | "clubAddressLine1"
  | "clubAddressLine2"
  | "clubCity"
  | "clubEmail"
  | "clubName"
  | "clubName"
  | "clubPostCode"
  | "domainUrl"
  | "eventOccurrenceEndDate"
  | "eventOccurrenceStartDate"
  | "expMonth"
  | "expYear"
  | "facilityName"
  | "last4Digits"
  | "pauseEndDate"
  | "pauseStartDate"
  | "purchaseDate"
  | "subscriptionName"
  | "userFirstName"
  | "userLastName"
  | "userLastName"
  | "userStaffFirstName"
  | "userStaffLastName";

export type AutomationPlaceholderLabels = {
  [key in AutomationPlaceholder]: string;
};

export type PaymentMethodType = "CARD" | "BACS_DIRECT_DEBIT";

export type PaymentMethodStatus = "ACTIVE" | "REMOVED" | "INVALID";

export type TransactionType =
  | "DEBIT"
  | "CREDIT"
  | "MANUAL_CREDIT"
  | "REFUND_DEBIT"
  | "WRITE_OFF"
  | "PENDING_AUTHORIZATION";

export type BacsPaymentStatus =
  | "WAITING_CONFIRMATION"
  | "PROCESSING"
  | "SUCCEEDED"
  | "FAILED";

export type BacsMandateStatus = "ACTIVE" | "INACTIVE" | "PENDING";

export interface KisiPlace {
  readonly id: string;
  readonly name: string;
  readonly address: string;
}

export interface KisiGroup {
  readonly id: string;
  readonly name: string;
}

export interface KisiMembershipDTOResult {
  readonly id: number;
  readonly name: string;
  readonly description?: string;
  readonly kisiGroupId?: string;
  readonly kisiGroupName?: string;
}

export type KisiMembershipDTO = ApiListResponse<KisiMembershipDTOResult>;

export type KisiGroupMappingType = "MEMBERSHIP" | "FACILITY";

export interface KisiGroupMapping {
  readonly id: number;
  readonly name: string;
  readonly description: string;
  readonly kisiGroupId: string;
  readonly kisiGroupName: string;
  readonly type: KisiGroupMappingType;
}

export type AccessReportItemDTO = {
  readonly id: number;
  // User Member
  readonly userMemberId: string;
  readonly firstName: string;
  readonly lastName: string;
  readonly email: string;
  readonly picture?: string;
  // Membership
  readonly membershipName?: string;
  readonly membershipStatus?: UserMemberSubscriptionStatus;
  readonly guest: boolean;
  readonly onSite: boolean;
  readonly checkInDate?: string;
  readonly checkOutDate?: string;
  readonly checkInTimeLimit: string;
};

export type VisitTotalReportDTO = {
  readonly userMemberId: string;
  readonly firstName: string;
  readonly lastName: string;
  readonly emailCommunication: boolean;
  readonly membershipName?: string;
  readonly membershipStatus?: UserMemberSubscriptionStatus;
  readonly checkInDate: string;
  readonly checkOutDate?: string;
};

export type NoVisitReportDTO = {
  readonly userMemberId: string;
  readonly firstName: string;
  readonly lastName: string;
  readonly emailCommunication: boolean;
  readonly membershipName?: string;
  readonly membershipStatus?: UserMemberSubscriptionStatus;
};

export type VisitPercentageDTO = {
  readonly visit: number;
  readonly noVisit: number;
};

export type MembershipStatusHistoryItem = {
  id: number;
  status: UserMemberSubscriptionHistoryStatus;
  createdDate: string;
};
export type StaffUserRole =
  | "SUPPORT"
  | "TRAINER"
  | "RECEPTION"
  | "MANAGER"
  | "OWNER";
export type UserRole = "MEMBER" | StaffUserRole;

export type RevenueType =
  | "transaction-type"
  | "scheduled-revenue"
  | "accounts-receivable";
export type StripeConnectStatus = "NOT_CONNECTED" | "PENDING" | "CONNECTED";
export type StripeAccountType =
  | "CONNECTED"
  | "NOT_CONNECTED"
  | "STANDARD"
  | "STANDARD_WHILE_ON_BOARDING"
  | "READY_TO_CONNECT";

export type AppointableCategoryDTO = {
  /** @format int64 */
  readonly id: number;
  readonly name: string;
  readonly color: string;
};

export type UserStaffPublicDTO = {
  readonly id: string;
  readonly firstName: string;
  readonly lastName: string;
  readonly picture?: string;
};

export type AppointableDtoAvailabilityType = "STAFF" | "FACILITY";

export type AppointableDtoStatus = "ACTIVE" | "INACTIVE";

export type AppointableDtoPricingModel =
  | "FIXED"
  | "FREE"
  | "FROM"
  | "PER_PERSON";

export type AppointableDTO = {
  /** @format int64 */
  readonly id: number;
  readonly name: string;
  readonly description: string;
  readonly termsAndConditions?: string;
  readonly availabilityType: AppointableDtoAvailabilityType;
  readonly status?: AppointableDtoStatus;
  /** @format int32 */
  readonly duration: number;
  /** @format int32 */
  readonly blockedTime?: number;
  /** @format int32 */
  readonly capacity: number;
  readonly pricingModel: AppointableDtoPricingModel;
  readonly price?: number;
  /** @format int32 */
  readonly sessionCost?: number;
  /** @format float */
  readonly taxRate?: number;
  readonly paymentUpfront: boolean;
  readonly cardUpfront: boolean;
  readonly allowOnlineBookings: boolean;
  readonly allowAppBookings: boolean;
  readonly appointableCategory: AppointableCategoryDTO;
  readonly appointableHosts: UserStaffPublicDTO[];
  readonly appointableFacilities: FacilityDTO[];
  readonly forMembersOnly: boolean;
  /** @format int64 */
  readonly clubId: number;
};

export type AppointablePostDTO = {
  readonly name: string;
  readonly description: string;
  readonly termsAndConditions?: string;
  readonly availabilityType: AppointableDtoAvailabilityType;
  readonly status: AppointableDtoStatus;
  /** @format int32 */
  readonly duration: number;
  /** @format int32 */
  readonly blockedTime: number;
  /** @format int32 */
  readonly capacity: number;
  readonly pricingModel: AppointableDtoPricingModel;
  readonly price?: number;
  /** @format int32 */
  readonly sessionCost?: number;
  /** @format float */
  readonly taxRate?: number;
  readonly paymentUpfront: boolean;
  readonly cardUpfront: boolean;
  readonly allowOnlineBookings: boolean;
  readonly allowAppBookings: boolean;
  /** @format int64 */
  readonly appointableCategoryId: number;
  readonly appointableHostsIdList: string[];
  /** @format int64 */
  readonly clubId: number;
};

export interface AppointablePatchDTO extends Partial<AppointablePostDTO> {
  readonly removePrice?: boolean;
  readonly removeSessionCost?: boolean;
}

export interface AppointmentPaymentPostDTO {
  /** @format int64 */
  readonly appointmentId?: number;
  readonly paymentMethodId?: string;
  readonly paidWithSessions?: boolean;
  readonly promotionCode?: string;
}

export type AppointmentCollectPaymentResponse = {
  readonly appointment: AppointmentDTO;
  readonly invoice: InvoiceDTO;
};

export type AppointmentStatus =
  | "BOOKED"
  | "CONFIRMED"
  | "ATTENDED"
  | "CANCELLED"
  | "LATE_CANCELLED"
  | "NO_SHOW";

export type AppointmentPaymentStatus = "PAID" | "UNPAID" | "REFUNDED";

export interface UserMemberPublicDTO {
  readonly id: string;
  readonly email: string;
  readonly phoneNumber?: string;
  readonly leadDTO: Lead;
  readonly firstName: string;
  readonly lastName: string;
  readonly picture?: string;
  readonly profileType: UserProfileType;
  readonly userType: UserType;
  readonly source: LeadSource;
}

export interface NoteAppointmentDTO {
  readonly content: string;
  readonly attachment?: string;
  readonly attachmentFilename?: string;
  /** @format date-time */
  readonly createdDate: string;
  /** @format date-time */
  readonly updatedDate?: string;
  readonly createdBy: string;
  readonly createdByFirstName: string;
  readonly createdByLastName: string;
  readonly createdByPicture?: string;
  /** @format int64 */
  readonly appointmentId: number;
  readonly noteId: number;
}

export interface AppointmentDTO {
  /** @format int64 */
  readonly id: number;
  readonly recurringAppointmentId?: number;
  readonly status: AppointmentStatus;
  readonly paymentStatus: AppointmentPaymentStatus;
  /** @format date-time */
  readonly startDate: string;
  /** @format date-time */
  readonly endDate: string;
  readonly appointable: AppointableDTO;
  readonly appointmentUser: UserMemberPublicDTO;
  readonly appointmentHost?: UserStaffPublicDTO;
  readonly appointmentFacility?: FacilityDTO;
  /** @format int32 */
  readonly capacity: number;
  readonly pricingModel: AppointableDtoPricingModel;
  readonly finalPrice?: number;
  /** @format int32 */
  readonly finalSessionCost?: number;
  /** @format float */
  readonly taxRate: number;
  readonly paymentUpfront: boolean;
  readonly cardUpfront: boolean;
  readonly noteList: NoteAppointmentDTO[];
  /** @format int64 */
  readonly clubId: number;
  /*** always set for appointment with kisi */
  readonly kisiGroupId?: string;
  /*** set for appointment with kisi during booking*/
  readonly kisiRoleAssignmentId?: string;
  readonly invoiceNumberAwaitingAuthList?: string[];
}

export interface AppointmentPostDTO {
  readonly status: AppointmentStatus;
  /** @format date-time */
  readonly startDate: string;
  /** @format int64 */
  readonly appointableId: number;
  readonly appointmentUserId: string;
  readonly appointmentHostId?: string;
  readonly appointmentFacilityId?: number;
  readonly availabilityValidationIgnored?: boolean;
  readonly appointmentPayment?:
    | {
        paidWithSessions: true;
      }
    | {
        paidWithSessions: false;
        paymentMethodId?: string;
        promotionCode?: string;
      };
}

export type MemberAppointmentPostDTO = Omit<
  AppointmentPostDTO,
  "appointmentUserId" | "availabilityValidationIgnored"
>;

export type MonthlyDayPosition =
  | "FIRST"
  | "SECOND"
  | "THIRD"
  | "FOURTH"
  | "FIFTH"
  | "LAST";

export type RecurringAppointmentType = "DAILY" | "WEEKLY" | "MONTHLY";

export type RecurringAppointmentDTO = {
  /** @format HH:mm */
  readonly appointmentTime: [number, number];
  readonly recurringType: RecurringAppointmentType;
  readonly recurringInterval: number;
  readonly weeklyDays?: DayOfWeek[];
  readonly monthlyDayOfMonth?: number;
  readonly monthlyDayPosition?: MonthlyDayPosition;
  readonly monthlyDayOfWeek?: DayOfWeek;
  readonly appointableId: number;
  /** @format YYYY-MM-dd */
  readonly startDate: string;
  /** @format YYYY-MM-dd */
  readonly endDate?: string;
  readonly id: number;
  readonly lastGeneratedDate: string;

  readonly appointmentUserId: string;
  readonly appointmentUserFirstName: string;
  readonly appointmentUserLastName: string;
} & (
  | {
      readonly appointmentHostId: string;
      readonly appointmentHostFirstName: string;
      readonly appointmentHostLastName: string;
      readonly appointmentHostPicture?: string;
    }
  | {
      readonly appointmentFacilityId: number;
      readonly appointmentFacilityName: string;
      readonly appointmentFacilityPicture?: string;
    }
);

export type RecurringAppointmentPostDTO = {
  /** @format HH:mm */
  readonly appointmentTime: string;
  readonly recurringType: RecurringAppointmentType;
  readonly recurringInterval: number;
  readonly weeklyDays?: DayOfWeek[];
  readonly monthlyDayOfMonth?: number;
  readonly monthlyDayPosition?: MonthlyDayPosition;
  readonly monthlyDayOfWeek?: DayOfWeek;
  readonly appointableId: number;
  readonly appointmentUserId: string;
  readonly appointmentHostId?: string;
  readonly appointmentFacilityId?: number;
  /** @format YYYY-MM-dd */
  readonly startDate: string;
  /** @format YYYY-MM-dd */
  readonly endDate?: string;
};

export type RecurringAppointmentPutDTO = {
  /** @format HH:mm */
  readonly appointmentTime: string;
  readonly recurringType: RecurringAppointmentType;
  readonly recurringInterval: number;
  readonly weeklyDays?: DayOfWeek[];
  readonly monthlyDayOfMonth?: number;
  readonly monthlyDayPosition?: MonthlyDayPosition;
  readonly monthlyDayOfWeek?: DayOfWeek;
  readonly appointableId: number;
  readonly appointmentUserId: string;
  readonly appointmentHostId?: string;
  readonly appointmentFacilityId?: number;
  /** @format YYYY-MM-dd */
  readonly startDate: string;
  /** @format YYYY-MM-dd */
  readonly endDate?: string;
  readonly fromAppointmentId: number;
};

export type AvailabilityDTO = {
  readonly availableForAppointments: boolean;
  readonly availabilitySlotList: AvailabilitySlot[];
};
export type AvailabilityStatus = "ACTIVE" | "INACTIVE";
export type AvailabilitySlot = {
  readonly availabilityType: "STAFF" | "FACILITY";
  readonly status: AvailabilityStatus;
  /** @format yyyy-MM-dd */
  readonly startTime: string;
  /** @format yyyy-MM-dd */
  readonly endTime: string | null;
  readonly weekDay: DayOfWeek;
};

export type FacilityAvailabilityDTO = {
  readonly availableForAppointments: boolean;
  readonly availabilitySlotList: FacilityAvailabilitySlot[];
};
export type FacilityAvailabilitySlot = {
  readonly availabilityType: "FACILITY";
  readonly status: AvailabilityStatus;
  /** @format HH:mm:ss */
  readonly startTime: string;
  /** @format HH:mm:ss */
  readonly endTime: string;
  readonly weekDay: DayOfWeek;
};

export type HostAvailabilityDTO = {
  readonly availableForAppointments: boolean;
  readonly availabilitySlotList: HostAvailabilitySlot[];
};
export type HostAvailabilitySlot = {
  readonly availabilityType: "STAFF";
  readonly status: AvailabilityStatus;
  /** @format HH:mm:ss */
  readonly startTime: string;
  /** @format HH:mm:ss */
  readonly endTime: string;
  readonly weekDay: DayOfWeek;
};

export type AvailabilityWindow = {
  startTime: string;
  endTime: string;
  availableHostsIdList: string[];
  availableFacilityIdList: number[];
};

export type GlobalStatisticsCategories =
  | "revenue"
  | "membership"
  | "event"
  | "lead"
  | "visit";

export type RevenueStatisticField =
  | "total"
  | "membership"
  | "sessionPack"
  | "product"
  | "credits"
  | "writeOff"
  | "refunds"
  | "awaitingAuth";
export type MembershipStatisticField =
  | "active"
  | "paused"
  | "cancelled"
  | "signUps"
  | "overdue"
  | "ending"
  | "growth"
  | "total"
  | "pending"
  | "pausing"
  | "resuming";
export type EventStatisticField =
  | "attendance"
  | "bookingPercentage"
  | "classesRun"
  | "noShows"
  | "lateCancellations";
export type LeadStatisticField =
  | "created"
  | "opened"
  | "total"
  | "closed"
  | "lost"
  | "closeRatio";
export type VisitStatisticField = "total" | "noVisits";

export type GlobalStatisticTrendPeriod = "DAY" | "WEEK" | "MONTH" | "YEAR";

export type ValueStatisticViewDTO = {
  readonly datePeriod?: string;
  readonly amount?: number;
};

export type GlobalStatisticViewDTO = {
  readonly avg?: number;
  readonly var?: number;
  readonly values?: ValueStatisticViewDTO[];
  readonly total?: number;
  readonly lastYearTotal?: number;
};

export type GlobalLeadStatisticDTO = {
  readonly trendPeriod?: GlobalStatisticTrendPeriod;
  readonly total?: GlobalStatisticViewDTO;
  readonly created?: GlobalStatisticViewDTO;
  readonly opened?: GlobalStatisticViewDTO;
  readonly closed?: GlobalStatisticViewDTO;
  readonly lost?: GlobalStatisticViewDTO;
  readonly closeRatio?: GlobalStatisticViewDTO;
};

export type GlobalEventStatisticDTO = {
  readonly trendPeriod?: GlobalStatisticTrendPeriod;
  readonly attendance?: GlobalStatisticViewDTO;
  readonly bookings?: GlobalStatisticViewDTO;
  readonly cancellations?: GlobalStatisticViewDTO;
  readonly bookingPercentage?: GlobalStatisticViewDTO;
  readonly classesRun?: GlobalStatisticViewDTO;
  readonly noShows?: GlobalStatisticViewDTO;
  readonly lateCancellations?: GlobalStatisticViewDTO;
};

export type GlobalMembershipStatisticDTO = {
  readonly trendPeriod?: GlobalStatisticTrendPeriod;
  readonly pending?: GlobalStatisticViewDTO;
  readonly active?: GlobalStatisticViewDTO;
  readonly paused?: GlobalStatisticViewDTO;
  readonly cancelled?: GlobalStatisticViewDTO;
  readonly signUps?: GlobalStatisticViewDTO;
  readonly ending?: GlobalStatisticViewDTO;
  readonly overdue?: GlobalStatisticViewDTO;
  readonly total?: GlobalStatisticViewDTO;
  readonly growth?: GlobalStatisticViewDTO;
  readonly pausing?: GlobalStatisticViewDTO;
  readonly resuming?: GlobalStatisticViewDTO;
};

export type GlobalRevenueStatisticDTO = {
  readonly trendPeriod?: GlobalStatisticTrendPeriod;
  readonly total?: GlobalStatisticViewDTO;
  readonly membership?: GlobalStatisticViewDTO;
  readonly sessionPack?: GlobalStatisticViewDTO;
  readonly product?: GlobalStatisticViewDTO;
  readonly credits?: GlobalStatisticViewDTO;
  readonly writeOff?: GlobalStatisticViewDTO;
  readonly refunds?: GlobalStatisticViewDTO;
  readonly awaitingAuth?: GlobalStatisticViewDTO;
};

export type GlobalVisitRevenueStatisticDTO = {
  readonly trendPeriod: GlobalStatisticTrendPeriod;
  readonly total?: Omit<GlobalStatisticViewDTO, "var">;
  readonly noVisits?: { total: number };
};

export type GlobalStatisticDTO = {
  readonly years?: number[];
  readonly revenue?: GlobalRevenueStatisticDTO;
  readonly membership?: GlobalMembershipStatisticDTO;
  readonly event?: GlobalEventStatisticDTO;
  readonly lead?: GlobalLeadStatisticDTO;
  readonly visit?: GlobalVisitRevenueStatisticDTO;
};

export type AvailabilityOverridePostDTO = {
  readonly isUnavailableAllDay: boolean;
  readonly startTime: string;
  readonly endTime: string;
  /** @format date */
  readonly overrideDate: string;
};

export type AvailabilityOverrideDTO = {
  /** @format int64 */
  readonly id: number;
  readonly isUnavailableAllDay: boolean;
  /** @format HH:mm:ss */
  readonly startTime: string;
  /** @format HH:mm:ss */
  readonly endTime: string;
  /** @format yyyy-MM-dd */
  readonly overrideDate: string;
};

export type AvailabilityCalculationResult = {
  readonly startTime: string;
  readonly endTime: string;
  readonly availableHostsIdList: string[];
};

export type AvailabilityByHostCalculationResponse = Record<
  string,
  AvailabilityCalculationResult[]
>;

export type CheckInDTO = {
  checkInDate: string;
  clubId: number;
  guest: boolean;
  id: number;
};

export type AppointmentPurchaseSummaryPostDTO = {
  readonly appointableId: number;
  readonly userMemberId: string;
  readonly appointmentPaymentPostDTO: {
    readonly paidWithSessions?: boolean;
    readonly appointmentId?: number;
    readonly promotionCode?: string;
    readonly paymentMethodId?: string;
  };
};
export type AppointmentSummaryAsMemberPostDTO = Omit<
  AppointmentPurchaseSummaryPostDTO,
  "userMemberId"
>;
export type StaffAppointmentPurchaseSummaryPostDTO =
  AppointmentPurchaseSummaryPostDTO & {
    readonly userMemberId: string;
  };
export type SessionPackUsageResult =
  | "UNLIMITED"
  | "USE_SESSIONS"
  | "NOT_ENOUGH_SESSIONS";

export type AppointmentPurchaseSummaryDTO = {
  readonly appointableId: number;
} & (
  | {
      readonly paidWithSessions: false;
      readonly cardUsage: {
        readonly upfrontPriceDetails: {
          readonly totalAmountBeforeDiscount: number;
          readonly totalAmountToPay: number;
          readonly totalAmountDifference: number;
        };
        readonly validPromotionCode: ValidPromotionCode;
      } & (
        | {
            readonly promotionCode: string;
            readonly promotionUpfrontAmount: string;
            readonly promotionUpfrontDiscountType: DiscountType;
          }
        | {}
      );
    }
  | {
      readonly paidWithSessions: true;
      readonly sessionPackUsage: SessionPackUsage;
    }
);
export type SessionPackUsage = {
  readonly sessionPackUsageCalculations: {
    readonly sessionPack: UserMemberSubscriptionBean;
    readonly numberOfSessionsUsed: number;
  }[];
  readonly result: SessionPackUsageResult;
};
// eslint-disable-next-line @typescript-eslint/no-namespace
namespace MemberBooking {
  export type Base = {
    activityId: number;
    startDate: string;
    endDate: string;
    bookingName?: string;
    hostName?: string;
    hostStaffId?: string;
    hostPicture?: string;

    facilityName?: string;
    facilityStaffId?: string;
    facilityPicture?: string;
  };
  export type EventOccurrence = {
    eventOccurrenceId: number;
    eventRsvpStatus?: EventRsvpStatus;
    sessionCost: number;
    capacity: number;
    waitListCapacity: number;
  };

  export type EventOccurrenceAgenda = EventOccurrence & {
    bookedCount: number;
    waitingCount: number;
    eventRsvpId?: number;
    bookingDescription: string;
    bookable?: boolean;
    facilityName: string;
    waitlistQueuePosition?: number;
  };

  export type Appointment = {
    appointmentId: number;
    status?: AppointmentStatus;
    appointmentStatus: AppointmentStatus;
    participantName: string;
    participantPicture?: string;
    paymentStatus: AppointmentPaymentStatus;
    recurringAppointmentId?: number;
  };
}

export type MemberBookingAppointmentDTO = MemberBooking.Base &
  MemberBooking.Appointment;
export type MemberBookingOccurrenceDTO = MemberBooking.Base &
  MemberBooking.EventOccurrence;

export type MemberBookingDTO = MemberBooking.Base &
  (MemberBooking.Appointment | MemberBooking.EventOccurrence);

export type MemberBookingAgendaAppointmentDTO = MemberBooking.Base &
  MemberBooking.Appointment;
export type MemberBookingAgendaOccurrenceDTO = MemberBooking.Base &
  MemberBooking.EventOccurrenceAgenda;
export type MemberBookingAgendaDTO = MemberBooking.Base &
  (MemberBooking.Appointment | MemberBooking.EventOccurrenceAgenda);

export type TokenPageableRequestParams = {
  limit: number;
  nextPageToken?: string;
};

export type UserMemberListItem = {
  userMemberId: string;
  firstName: string;
  lastName: string;
  createdDate: string;
  email: string;
  mobileNumber: string;
  picture?: string;
  membershipName: string;
  membershipStatus: UserMemberSubscriptionStatus;
  membershipPrice: number;
  creditsRemaining: number;
  creditsUnlimited: boolean;
};

export type MembershipCalculateCancelDatesResponse = {
  readonly cancellationDateList: string[];
};

export type InvitationProcessStatusDTO = {
  readonly passwordChanged: boolean;
  readonly paymentMethodCreated: boolean;
};

export type PaymentMethodSetupIntent = {
  readonly setupIntentId: string;
  readonly setupIntentStatus: string;
  readonly setupIntentClientSecret: string;
};

export type CalculateScheduleDates = { readonly changeDates: string[] };

export type SMSPurpose = "MARKETING" | "ACCOUNT_RELATED";

export type SMSPayload = {
  purpose: SMSPurpose;
  body: string;
  schedule?: string;
};

export interface SMSSendingResponse {
  readonly sentSms: number;
  readonly sentAttempts: number;
  readonly failedMobileNumbers: string[];
  readonly failedAttemptsWithNoMobileNumber: [
    { readonly firstName: string; readonly lastName: string },
  ];
}

export interface CreditTopUpItem {
  id: number;
  creditsAmount: number;
  createdDate: string;
}

export interface CreditSpentItem {
  id: number;
  creditsAmount: number;
  createdDate: string;
  messageBody: string;
}

export interface UserMemberAccessLogItem {
  id: string;
  checkInDate: string;
  checkOutDate: string;
}

export interface StripeProductItem {
  id: number;
  creditsAmount: number;
  pricePerCredit: number;
  totalPrice: number;
  currency: Currency;
  url: string;
}

export interface CustomPaymentCategoryDTO {
  id: number;
  name: string;
}

export interface CalculateChangeSubscriptionPriceNewRequestBody {
  subscriptionId: number;
  membershipId: number;
}
export interface CalculateChangeSubscriptionPriceNewResponseBody {
  price: number;
}

export interface ProductOrderCheckoutPaymentMethodRequestBody {
  userMemberId: string;
  orderItems: ProductOrderDetailDTO[];
  orderKey: string;
  paymentMethodId?: string;
  promotionCode?: string;
  priceOverride?: number;
}

export interface ProductOrderCheckoutCustomPaymentRequestBody {
  userMemberId: string;
  orderItems: ProductOrderDetailDTO[];
  orderKey: string;
  customPaymentCategoryId: number;
  promotionCode?: string;
  priceOverride?: number;
}

export interface ProductOrderInvoiceDTO {
  invoiceNumber: string;
  productOrderId: number;
  invoiceStatus: InvoiceStatus;
}

export interface MembershipSignupPaymentMethodRequestBody {
  userMemberId: string;
  membershipId: number;
  startDate: string;
  orderKey: string;
  paymentMethodId?: string;
  promotionCode?: string;
  priceOverride?: number;
}

export interface MembershipSignupCustomPaymentRequestBody {
  userMemberId: string;
  membershipId: number;
  startDate: string;
  orderKey: string;
  customPaymentCategoryId: number;
  promotionCode?: string;
  priceOverride?: number;
}

export interface SessionPackSignupPaymentMethodRequestBody {
  userMemberId: string;
  sessionPackId: number;
  startDate: string;
  orderKey: string;
  paymentMethodId?: string;
  promotionCode?: string;
  priceOverride?: number;
}

export interface SessionPackSignupCustomPaymentRequestBody {
  userMemberId: string;
  sessionPackId: number;
  startDate: string;
  orderKey: string;
  customPaymentCategoryId: number;
  promotionCode?: string;
  priceOverride?: number;
}

export interface SubscriptionInvoiceDTO {
  invoiceNumber: string;
  subscriptionId: number;
  invoiceStatus: InvoiceStatus;
}

export type ClubPropertiesSettings = {
  readonly club: ClubBean;
  readonly properties: ClubPropertiesDTOWithRawPhoneNumberCountry;
  readonly settings?: ClubSettings;
  readonly featureFlags: FeatureFlags["featureFlags"];
};

export type BrandingPatchDTO = Partial<{
  primaryColor: string;
  font: string;
  darkMode: boolean;
  membershipsNavEnabled: boolean;
  creditPacksNavEnabled: boolean;
  appointmentsNavEnabled: boolean;
  timetableNavEnabled: boolean;
  leadCaptureNavEnabled: boolean;
  yourWebsiteUrl: string;
  redirectUrl: string;
  grayShades: { [key: string]: string };
}>;

export type NotePostDTO = {
  content: string;
} & ({ appointmentId: number } | { userMemberId: string } | { leadId: number });

export type InvoiceInfo = {
  id: number;
  number: string;
  totalAmount: number;
  dueAmount: number;
  status: InvoiceStatus;
  invoiceUrl: string;
  promotionId: number;
  issueDate: string;
  lastPaymentErrorCode: string;
  lastPaymentAttemptDate: string;
  paymentAttemptCount: number;
  paymentAutoRetryCount: number;
  paymentAutoRetryLimit: number;
  nextAutoRetryDate: string;
};

export type AppointmentBookingPaymentByPaymentMethodIdProps = {
  appointmentId: number;
  paymentMethodId: string;
  promotionCode?: string;
};

export type AppointmentBookingCustomPaymentByCustomPaymentCategoryIdProps = {
  appointmentId: number;
  customPaymentCategoryId: number;
  promotionCode?: string;
};

export type AppointmentBookingConsumeSessionsProps = {
  appointmentId: number;
  promotionCode?: string;
};

export type AppointmentInvoiceDTO = {
  invoiceNumber: string;
  invoiceStatus: InvoiceStatus;
};

export type FeatureFlags = {
  readonly featureFlags: {
    DISABLED_UNLOCK_WHEN_CHECKED_IN?: boolean;
    HOSTED_PAGES_GUEST_REGISTRATION_REDESIGN?: boolean;
    WEBSITE_SETTINGS_REDESIGN?: boolean;
    GROW_METRICS_INTEGRATION?: boolean;
    PORTAL_SEND_SMS?: boolean;
    HOSTED_CREDIT_REDESIGN?: boolean;
    HOSTED_APPOINTMENT_REDESIGN?: boolean;
    PAYMENT_SETUP_INTENT?: boolean;
    PAYMENT_SETUP_INTENT_MOBILE?: boolean;
    HOSTED_PAGES_CLASSES_REDESIGN?: boolean;
    HOSTED_LEAD_CAPTURE_REDESIGN?: boolean;
    HOSTED_PAGES_PROFILE?: boolean;
    PORTAL_NEW_SCHEDULED_MEMBERSHIP_CHANGE_MODAL?: boolean;
    PORTAL_NEW_MEMBERSHIP_CHANGE_RULE?: boolean;
    FE_AVAILABILITY_FOR_WHOLE_DAY?: boolean;
    PORTAL_NEW_PRODUCT_CHECKOUT_ENDPOINT?: boolean;
    PORTAL_NEW_MEMBERSHIP_CHECKOUT_ENDPOINT?: boolean;
    PORTAL_NEW_CREDIT_PACK_CHECKOUT_ENDPOINT?: boolean;
    FE_MEMBERS_ONLY?: boolean;
    PORTAL_PAST_DUE_INVOICE_COLLECTION_SIDEBAR?: boolean;
    DISABLE_CUSTOMER_PORTAL?: boolean;
    PORTAL_NEW_APPOINTMENT_PAYMENT_ONLINE_ENDPOINT?: boolean;
    PORTAL_NEW_APPOINTMENT_PAYMENT_CREDITS_ENDPOINT?: boolean;
  };
};

export * from "./api-resolver";
