import "./DateTimeSelect.css";

import { Moment } from "moment";
import { useRef, useState } from "react";
import Datetime from "react-datetime";
import { twMerge } from "tailwind-merge";

import calendarIcon from "../../assets/calendar.svg";
import { DATE_FORMAT } from "../../helpers/form/constants";

type DateTimeSelectProps = {
  value: any;
  onChange: (newDate: any) => void;
  placeholder?: string;
  isValidDate?: (date: any) => boolean;
  displayValue?: string;
  className?: string;
  dateTimeClassName?: string;
};

const DateTimeSelect: React.FC<DateTimeSelectProps> = function ({
  value,
  onChange,
  placeholder,
  isValidDate,
  displayValue,
  className,
  dateTimeClassName,
}) {
  const inputRef = useRef<HTMLElement>(null);
  const [, setIsOpen] = useState(false);

  return (
    <div
      className={twMerge(
        "hosted-date-select relative flex h-11 w-fit cursor-pointer flex-row items-center rounded-lg border border-gray-200 bg-gray-0 px-4 text-center text-gray-600 hover:bg-gray-100",
        className,
      )}
    >
      <img
        src={calendarIcon as any}
        alt="Calendar Icon"
        className="max-w hosted-date-select-icon mr-2 text-gray-600"
      />

      <Datetime
        dateFormat={DATE_FORMAT}
        timeFormat={false}
        onChange={(v) => {
          onChange((v as Moment).format());
        }}
        value={value}
        closeOnSelect
        onOpen={() => setIsOpen(true)}
        onClose={() => setIsOpen(false)}
        isValidDate={isValidDate}
        inputProps={{
          readOnly: true,
          placeholder,
          value: displayValue || value,
          className: twMerge(
            "bg-gray-0 text-gray-600 font-semibold border-0 focus:outline-none max-w-[6rem] cursor-pointer hosted-date-select-input text-gray-600 placeholder:text-gray-600",
            dateTimeClassName,
          ),
          ref: inputRef as any,
        }}
      />
    </div>
  );
};

export default DateTimeSelect;
