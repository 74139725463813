import { formatCurrency, pluralize } from "@gymflow/helpers";
import { AppointableDTO } from "@gymflow/types";
import classNames from "classnames";
import addMinutes from "date-fns/addMinutes";
import format from "date-fns/format";
import { useMemo } from "react";

import { useClubSettings } from "../../../providers";
import { ArrowLeftIcon, Button } from "../../atoms";

export function Confirm({
  service,
  onBack,
  onConfirm,
  appointmentStart,
  hostName,
}: {
  service: AppointableDTO;
  appointmentStart: Date;
  hostName?: string;
  onBack: () => void;
  onConfirm: ({
    requiresPayment,
  }: {
    requiresPayment: boolean;
  }) => Promise<void>;
}) {
  const { defaultCurrency } = useClubSettings();
  const appointmentEnd = useMemo(() => {
    return addMinutes(appointmentStart, service.duration);
  }, [appointmentStart, service.duration]);
  const requiresPayment =
    service.pricingModel !== "FREE" &&
    !!(service.price || service.sessionCost) &&
    service.paymentUpfront;

  const renderPrice = () => {
    if (service.pricingModel === "FREE" || service.price === 0) {
      return "FREE";
    }
    if (service.price === undefined) {
      return "";
    }
    return formatCurrency(service.price, defaultCurrency);
  };

  return (
    <div className="flex flex-col gap-4">
      <div className="flex items-center gap-4">
        <div
          className="cursor-pointer rounded-lg border border-gray-300 px-3 py-2 hover:bg-gray-50 dark:border-gray-800 dark:hover:bg-gray-900"
          onClick={onBack}
        >
          <ArrowLeftIcon pathClassName="stroke-gray-500 dark:stroke-gray-400" />
        </div>
        <div className="text-2xl font-bold dark:text-gray-0">
          Review & Confirm
        </div>
      </div>
      <div className="flex flex-col gap-4 rounded-lg border border-gray-300 p-4 dark:border-gray-900">
        <div>
          <div className="flex justify-between gap-1 text-lg font-semibold text-gray-900 dark:text-gray-200">
            <div>{service.name}</div>
            <div>{renderPrice()}</div>
          </div>
          <div className="flex justify-between gap-1 text-sm font-normal text-gray-400 dark:text-gray-400">
            <div>{`${service.duration} ${pluralize(
              "minute",
              "minutes",
              service.duration,
            )}`}</div>
            <div>
              {service.sessionCost !== undefined &&
                service.sessionCost > 0 &&
                `${service.sessionCost} ${pluralize(
                  "credit",
                  "credits",
                  service.sessionCost,
                )}`}
            </div>
          </div>
        </div>
        <div>
          <div className="flex justify-between gap-1 text-lg font-semibold text-gray-900 dark:text-gray-200">
            {format(appointmentStart, "EEEE, do MMMM")}
          </div>
          <div className="flex justify-between gap-1 text-sm font-normal text-gray-400 dark:text-gray-400">
            {`${format(appointmentStart, "h:mm a")} - ${format(
              appointmentEnd,
              "h:mm a",
            )}`}
          </div>
        </div>
        <div className={classNames({ hidden: !hostName })}>
          <div className="flex justify-between gap-1 text-lg font-semibold text-gray-900 dark:text-gray-200">
            {hostName ? hostName : "No Preference"}
          </div>
        </div>
        <div
          className={classNames({
            hidden: !service.description,
          })}
        >
          <div className="flex justify-between gap-1 text-lg font-semibold text-gray-900 dark:text-gray-200">
            Appointment Details
          </div>
          <div className="flex justify-between gap-1 text-sm font-normal text-gray-400 dark:text-gray-400">
            {service.description}
          </div>
        </div>
        <div
          className={classNames({
            hidden: !service.termsAndConditions,
          })}
        >
          <div className="flex justify-between gap-1 text-lg font-semibold text-gray-900 dark:text-gray-200">
            Appointment Terms
          </div>
          <div className="flex justify-between gap-1 text-sm font-normal text-gray-400 dark:text-gray-400">
            {service.termsAndConditions}
          </div>
        </div>
        <div className="flex justify-center">
          <Button
            intent="primary"
            className="mt-2 w-1/2"
            onClick={async () => {
              await onConfirm({ requiresPayment });
            }}
          >
            {requiresPayment ? "Checkout" : "Confirm"}
          </Button>
        </div>
      </div>
    </div>
  );
}
