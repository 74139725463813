import { useAutoAnimate } from "@formkit/auto-animate/react";
import {
  useQueryMembershipListAsMember,
  useQueryMembershipListAsPublic,
} from "@gymflow/api";
import { cn } from "@gymflow/helpers";
import { MembershipType } from "@gymflow/types";
import { usePortalRoutes } from "apps/portal/src/hooks";
import {
  useAuthenticatedUser,
  useClubSettings,
} from "apps/portal/src/providers";
import mapTorTArrayToTArray from "libs/helpers/src/lib/mapTorTArrayToTArray";
import { pick } from "lodash";
import { DateTime } from "luxon";
import QueryString from "qs";
import React, { useMemo, useState } from "react";
import { useTranslation } from "react-i18next";
import { useParams } from "react-router";

import useGymflowModels from "../../../store";
import { Button } from "../../atoms";
import { EmptyPage } from "../../atoms/EmptyPage";
import HostedPagesProfileAvatar from "../components/HostedPagesProfileAvatar";
import { SortPopover, SortType } from "../SortPopover";
import {
  membershipFilters,
  MembershipTabType,
  membershipTypeToString,
} from "./components/constants";
import { HostedPagesMembershipCard } from "./components/HostedPagesMembershipCard";
import {
  HostedPagesMembershipFilterPopover,
  HostedPagesMembershipFilterPopoverProps,
} from "./components/HostedPagesMembershipFilterPopover";
import { useMembershipAvailableTabs } from "./components/useMembershipAvailableTabs";

export type HostedPagesMembershipListProps = {};

export const HostedPagesMembershipList: React.FC<
  HostedPagesMembershipListProps
> = ({}) => {
  const settings = useClubSettings();
  const { isEmbed } = usePortalRoutes();
  const auth = useAuthenticatedUser();
  const { t } = useTranslation();
  const { membershipType } = useParams<{
    membershipType?: "recurring" | "prepaid" | "trial" | "all";
  }>();
  // pick is used because some links will have custom tracking query params like fbclid
  const urlFilters = pick(
    QueryString.parse(window.location.search, {
      ignoreQueryPrefix: true,
    }) as {
      memberships?: string[] | string;
      type?: MembershipType;
    },
    ["memberships", "type"],
  );

  const hasCustomFilters = Object.keys(urlFilters).length > 0;
  const { api } = useGymflowModels();

  const [sort, setSort] = useState<SortType | undefined>("ASC");
  const shouldShowTypePicker =
    (!membershipType || membershipType === "all") && !hasCustomFilters;

  const [currentTab, setCurrentTab] = useState<MembershipTabType>(
    membershipType && membershipType !== "all" ? membershipType : "recurring",
  );
  const [uiFilter, setUiFilter] = useState<
    HostedPagesMembershipFilterPopoverProps["filter"]
  >({
    durationType: [],
  });
  const membershipListQueryOptions = {
    api,
    opts: {
      status: "ACTIVE",
      size: 200,
      page: 0,
      ...(!hasCustomFilters
        ? { ...membershipFilters[currentTab], ...uiFilter }
        : {}),
    },
  } as const;
  const membershipQueryAsPublic = useQueryMembershipListAsPublic(
    membershipListQueryOptions,
    { enabled: !auth.id },
  );
  const membershipQueryAsMember = useQueryMembershipListAsMember(
    membershipListQueryOptions,
    { enabled: !!auth.id },
  );

  const { data } = auth.id ? membershipQueryAsMember : membershipQueryAsPublic;
  const filteredMemberships = useMemo(() => {
    return data?.content
      .filter((e) => {
        if (!hasCustomFilters) return true;
        const localFilter = {
          memberships: mapTorTArrayToTArray(urlFilters?.memberships)?.map(
            Number,
          ),
          type: urlFilters?.type,
        };
        return (
          e.type === localFilter.type || localFilter.memberships?.includes(e.id)
        );
      })
      .filter((e) => {
        if (!e.fixedStartDate) return true;
        return (
          DateTime.now() <
          DateTime.fromISO(e.fixedStartDate)
            .setZone(settings.timezone)
            .endOf("day")
        );
      });
  }, [data?.content, hasCustomFilters, urlFilters, settings]);
  const sortedMemberships = useMemo(() => {
    return filteredMemberships?.sort((a, b) => {
      if (!sort) return 0;
      if (sort === "ASC") {
        return a.defaultPrice - b.defaultPrice;
      } else {
        return b.defaultPrice - a.defaultPrice;
      }
    });
  }, [filteredMemberships, sort]);
  const tabs = useMembershipAvailableTabs();
  const [parent] = useAutoAnimate();
  return (
    <div className="flex h-full flex-col bg-gray-50 pb-12 dark:bg-gray-800">
      <div className="track-height bg-gray-0 flex flex-col gap-4 border-b border-gray-300 p-4 dark:border-gray-700 dark:bg-gray-950 lg:px-28">
        <div className="flex flex-row flex-wrap items-center justify-between gap-2">
          <div className="flex flex-row items-center gap-4">
            {isEmbed && auth.id ? (
              <HostedPagesProfileAvatar />
            ) : (
              <div className="dark:text-gray-0 text-xl font-medium text-gray-950">
                {t("page.hostedPagesMemberships.title")}
              </div>
            )}

            <div className="hidden flex-row items-center gap-2 lg:flex">
              {shouldShowTypePicker &&
                tabs.map((e) => {
                  return (
                    <Button
                      key={e}
                      size="small"
                      className={cn("text-sm transition-all", {
                        "!border-secondary-500 !text-secondary-500":
                          currentTab === e,
                      })}
                      onClick={() => setCurrentTab(e)}
                    >
                      {membershipTypeToString[e]}
                    </Button>
                  );
                })}
            </div>
          </div>
          <div className="flex flex-row gap-2">
            <SortPopover onChange={setSort} value={sort} />
            {!hasCustomFilters && (
              <HostedPagesMembershipFilterPopover
                filter={uiFilter}
                onChange={setUiFilter}
              />
            )}
          </div>
        </div>
        <div className="flex flex-row gap-2 lg:hidden">
          {shouldShowTypePicker &&
            tabs.map((e) => {
              return (
                <Button
                  key={e}
                  size="small"
                  className={cn("text-sm transition-all flex-1", {
                    "!border-secondary-500 !text-secondary-500":
                      currentTab === e,
                  })}
                  onClick={() => setCurrentTab(e)}
                >
                  {membershipTypeToString[e]}
                </Button>
              );
            })}
        </div>
      </div>

      <div
        className="track-height relative grid min-h-[16rem] content-start gap-4 overflow-y-auto overflow-x-hidden bg-gray-50 p-4 pb-16 dark:bg-gray-800 lg:grid-cols-3 lg:gap-x-6 lg:gap-y-8 lg:px-28 lg:pt-6"
        ref={parent}
      >
        {sortedMemberships?.length === 0 && (
          <div className="absolute inset-0 flex items-center justify-center">
            <EmptyPage
              action={
                uiFilter.durationType !== undefined ? (
                  <Button
                    onClick={() => {
                      setUiFilter({
                        durationType: [],
                      });
                    }}
                  >
                    Clear Filters
                  </Button>
                ) : undefined
              }
            />
          </div>
        )}
        {sortedMemberships?.map((e) => (
          <HostedPagesMembershipCard key={e.id} membership={e} />
        ))}
      </div>
    </div>
  );
};
