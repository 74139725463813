import "react-image-crop/dist/ReactCrop.css";
import "./ImageInputWithModal.css";

import { cn } from "@gymflow/helpers";
import { PopoverClose } from "@radix-ui/react-popover";
import { createRef, useCallback, useContext, useMemo, useState } from "react";
import { useTranslation } from "react-i18next";
import ReactCrop, { Crop } from "react-image-crop";

import { ModalContext, ModalWrapper } from "../../providers";
import { Button } from "./Button";
import {
  CloseIcon,
  DangerIcon,
  EditIcon,
  PlusIcon,
  TrashIcon,
  UploadCloudIcon,
} from "./icons";
import { Popover, PopoverContent, PopoverTrigger } from "./Popover";

const MAX_SIZE_IN_BYTES = 2000000; // 2MB
const MAX_VERTICE_SIZE_IN_PX = 400;

export function ImageInputWithModal({
  accept,
  allowedExtensions,
  onChange,
  onDelete,
  value,
  modalTitle,
  placeholder,
  disabled,
}: {
  accept?: string;
  allowedExtensions?: string[];
  onChange: (newValue: Blob) => void;
  onDelete?: () => void;
  value?: { url?: string; blob?: Blob };
  modalTitle: string;
  placeholder: string;
  disabled?: boolean;
}) {
  const { t } = useTranslation();
  const { setModal, hide: hideModal } = useContext(ModalContext);

  const showModal = useCallback(() => {
    setModal(
      <CropModal
        onCancel={hideModal}
        onConfirm={(v) => {
          hideModal();
          onChange(v);
        }}
        title={modalTitle}
        accept={accept}
        allowedExtensions={allowedExtensions}
      />,
    );
  }, [accept, allowedExtensions, hideModal, modalTitle, onChange, setModal]);

  if (value) {
    return (
      <div className="flex items-end gap-3">
        <div className="relative flex h-32 w-32">
          <div className="flex h-32 w-32 overflow-hidden rounded-full border border-gray-200 dark:border-gray-800">
            <img src={value.url} alt="profile" />
          </div>
          {!onDelete && (
            <Button
              disabled={disabled}
              className="absolute bottom-0 right-0 h-8 w-8 min-w-0 !rounded-full p-1"
              onClick={() => {
                showModal();
              }}
            >
              <EditIcon
                className="h-5 w-5"
                pathClassName="stroke-gray-950 dark:stroke-gray-0"
              />
            </Button>
          )}
          {onDelete && (
            <Popover>
              <PopoverTrigger asChild>
                <Button
                  disabled={disabled}
                  className="absolute bottom-0 right-0 h-8 w-8 min-w-0 !rounded-full p-1"
                >
                  <EditIcon
                    className="h-5 w-5"
                    pathClassName="stroke-gray-950 dark:stroke-gray-0"
                  />
                </Button>
              </PopoverTrigger>
              <PopoverContent
                align="start"
                side="bottom"
                className="flex w-fit flex-col overflow-hidden rounded-lg"
                hideWhenDetached
              >
                <PopoverClose asChild>
                  <div
                    className="flex cursor-pointer items-center gap-2 px-4 py-2 text-sm text-gray-500 hover:bg-gray-50"
                    onClick={() => {
                      showModal();
                    }}
                  >
                    <EditIcon
                      className="h-4 w-4"
                      pathClassName="stroke-gray-700"
                    />
                    <div>{t("common.edit")}</div>
                  </div>
                </PopoverClose>
                <PopoverClose asChild>
                  <div
                    className="text-error-500 flex cursor-pointer items-center gap-2 px-4 py-2 text-sm hover:bg-gray-50"
                    onClick={() => {
                      onDelete();
                    }}
                  >
                    <TrashIcon
                      className="h-4 w-4"
                      pathClassName="stroke-error-500"
                    />
                    <div>{t("common.delete")}</div>
                  </div>
                </PopoverClose>
              </PopoverContent>
            </Popover>
          )}
        </div>
      </div>
    );
  }
  return (
    <div className="relative flex h-32 w-32">
      <div className="flex h-32 w-32 items-center justify-center overflow-hidden rounded-full border border-gray-200 bg-gray-100 text-2xl font-semibold dark:border-gray-800 dark:bg-gray-900">
        {placeholder}
      </div>
      <Button
        disabled={disabled}
        className="absolute bottom-0 right-0 h-8 w-8 min-w-0 !rounded-full p-1"
        onClick={() => {
          showModal();
        }}
      >
        <PlusIcon
          className="h-5 w-5"
          pathClassName="stroke-gray-950 dark:stroke-gray-0"
        />
      </Button>
    </div>
  );
}

function CropModal({
  onCancel,
  onConfirm,
  title,
  accept = "image/*",
  allowedExtensions = ["jpg", "jpeg", "png"],
}: {
  onCancel: () => void;
  onConfirm: (blob: Blob) => void;
  title: string;
  accept?: string;
  allowedExtensions?: string[];
}) {
  const { t } = useTranslation();
  const fileInputRef = createRef<HTMLInputElement>();
  const [error, setError] = useState<string>();
  const [isValid, setIsValid] = useState<boolean>(false);

  const [src, setSrc] = useState<{
    blobUrl: string;
    type: string;
    name: string;
  }>();
  const [crop, setCrop] = useState<Crop>({
    unit: "px",
    height: 150,
    width: 150,
    x: 0,
    y: 0,
    aspect: 1,
  });

  const [size, setSize] = useState<{ width: number; height: number }>({
    width: 150,
    height: 150,
  });
  const [imageLoaded, setImageLoaded] = useState<HTMLImageElement>();
  const maxVerticeSizeInPx = useMemo(() => {
    if (!imageLoaded) {
      return MAX_VERTICE_SIZE_IN_PX;
    }
    if (imageLoaded.width < imageLoaded.height) {
      return imageLoaded.width > MAX_VERTICE_SIZE_IN_PX
        ? MAX_VERTICE_SIZE_IN_PX
        : imageLoaded.width;
    } else {
      return imageLoaded.height > MAX_VERTICE_SIZE_IN_PX
        ? MAX_VERTICE_SIZE_IN_PX
        : imageLoaded.height;
    }
  }, [imageLoaded]);

  const onChooseFile = useCallback(
    (file: File) => {
      const isExtensionValid = allowedExtensions.some((ext) =>
        file.name.toLowerCase().endsWith(ext),
      );
      if (!isExtensionValid) {
        setError("The image format is incorrect, choose another image.");
        setIsValid(false);
        return;
      }
      if (file.size > MAX_SIZE_IN_BYTES) {
        setError("Size of image is too big, please select another image.");
        setIsValid(false);
        return;
      }
      setIsValid(true);
      setError("");
      setSrc({
        blobUrl: window.URL.createObjectURL(file),
        type: file.type,
        name: file.name,
      });
    },
    [allowedExtensions],
  );
  return (
    <ModalWrapper
      className="flex w-[25rem] max-w-[25rem] flex-col gap-4 sm:w-[25rem] sm:max-w-[25rem]"
      onCancel={onCancel}
    >
      <div className="flex flex-row items-center justify-between">
        <div className="dark:text-gray-0 text-lg font-semibold text-gray-950">
          {title}
        </div>
        <Button
          intent="transparent"
          className="h-fit min-w-0 p-1"
          onClick={() => {
            onCancel();
          }}
        >
          <CloseIcon />
        </Button>
      </div>
      <div
        className={cn(
          "flex min-h-[14rem] items-center justify-center rounded-xl border border-gray-200 dark:border-gray-800",
          {
            "cursor-pointer": !src,
          },
        )}
        onDrop={(e) => {
          e.preventDefault();
          if (!fileInputRef.current || !!src) {
            return;
          }
          fileInputRef.current.files = e.dataTransfer.files;
          onChooseFile(e.dataTransfer.files[0]);
        }}
        onDragOver={(e) => e.preventDefault()}
        onClick={
          !src
            ? () => {
                fileInputRef.current?.click();
              }
            : undefined
        }
      >
        <div className="flex flex-col items-center justify-center">
          {!src && (
            <>
              <div className="bg-secondary-50 flex items-center justify-center rounded-full p-2">
                <UploadCloudIcon
                  pathClassName="stroke-secondary-700"
                  className="h-4 w-4"
                />
              </div>

              <div className="mt-3 text-sm font-normal text-gray-500">
                <div className="text-secondary-500 inline-block font-semibold">
                  {t("components.imageCropper.upload")}
                </div>
                {t("components.imageCropper.orDragAndDrop")}
              </div>
              <div className="text-sm font-normal text-gray-500">
                {t("components.imageCropper.pngJpgMax2MB")}
              </div>
            </>
          )}
        </div>
        {src && (
          <ReactCrop
            className="max-w-[22rem]"
            src={src.blobUrl}
            onChange={(newCrop) => {
              setCrop(newCrop);
            }}
            onImageLoaded={(img) => {
              setImageLoaded(img);
              setIsValid(true);
            }}
            crop={crop}
            maxHeight={size.height}
            maxWidth={size.width}
            minHeight={size.height}
            minWidth={size.width}
            disabled={false}
            locked={true}
          />
        )}
      </div>
      <div className={cn("flex flex-col gap-2", { hidden: !src })}>
        <div className="flex flex-col gap-4">
          <input
            className="range accent-gray-0 h-2 w-full cursor-pointer appearance-none rounded-lg bg-gray-200 dark:bg-gray-700"
            type="range"
            min="50"
            max={maxVerticeSizeInPx}
            onChange={(e) => {
              const width = Number(e.currentTarget.value);
              const height = Number(e.currentTarget.value);
              setSize({
                width,
                height,
              });
              setCrop((s) => ({ ...s, width, height }));
            }}
          />
          <div className="flex justify-between text-[0.75rem] font-medium text-gray-500">
            <div>{t("common.min")}</div>
            <div>{t("common.max")}</div>
          </div>
        </div>
        <div
          className="flex cursor-pointer items-center justify-center gap-2"
          onClick={() => {
            fileInputRef.current?.click();
          }}
        >
          <UploadCloudIcon
            pathClassName="stroke-secondary-500"
            className="h-5 w-5"
          />
          <div className="text-secondary-500 text-sm font-semibold">
            {t("components.imageCropper.browseAnotherImage")}
          </div>
        </div>
      </div>
      <div
        className={cn(
          "flex items-center gap-2 bg-error-50 dark:bg-error-950 px-3 py-1.5 rounded-lg flex-row",
          {
            hidden: !error,
          },
        )}
      >
        <div className="flex h-full self-start pt-1">
          <DangerIcon
            className="h-4 w-4 self-start"
            pathClassName="stroke-error-500"
          />
        </div>
        <div className="text-error-500 text-sm font-medium ">{error}</div>
      </div>
      <div className="flex flex-row gap-2">
        <Button onClick={onCancel} className="flex-1">
          {t("common.cancel")}
        </Button>
        <Button
          intent="secondary"
          className="flex-1"
          disabled={!isValid}
          onClick={async () => {
            if (imageLoaded) {
              const blob = await getCroppedImg(
                imageLoaded,
                crop,
                src!.name,
                src!.type,
              );
              onConfirm(blob);
            }
          }}
        >
          {t("common.add")}
        </Button>
      </div>
      <input
        type="file"
        className="hidden"
        ref={fileInputRef}
        accept={accept}
        onChange={(e) => {
          if (
            e.target.files &&
            e.target.files !== null &&
            e.target.files?.[0]
          ) {
            const file = e.target.files[0];
            onChooseFile(file);
          }
        }}
      />
    </ModalWrapper>
  );
}

function getCroppedImg(
  image: HTMLImageElement,
  crop: Crop,
  fileName: string,
  type: string,
): Promise<Blob> {
  const canvas = document.createElement("canvas");
  const scaleX = image.naturalWidth / image.width;
  const scaleY = image.naturalHeight / image.height;
  canvas.width = crop.width;
  canvas.height = crop.height;
  const ctx = canvas.getContext("2d");

  ctx!.drawImage(
    image,
    crop.x * scaleX,
    crop.y * scaleY,
    crop.width * scaleX,
    crop.height * scaleY,
    0,
    0,
    crop.width,
    crop.height,
  );

  return new Promise((resolve, reject) => {
    canvas.toBlob(
      (blob) => {
        if (!blob) {
          reject();
          return;
        }
        // @ts-ignore
        blob.name = fileName;
        resolve(blob);
      },
      type,
      1,
    );
  });
}
