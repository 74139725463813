export type CheckmarkIconProps = {
  className?: string;
  pathClassName?: string;
};

const CheckmarkIcon = ({ className, pathClassName }: CheckmarkIconProps) => {
  return (
    <svg
      className={className}
      xmlns="http://www.w3.org/2000/svg"
      width="28"
      height="28"
      viewBox="0 0 28 28"
      fill="none"
    >
      <path
        className={pathClassName}
        d="M25.6668 12.9334V14.0067C25.6654 16.5225 24.8507 18.9705 23.3444 20.9855C21.838 23.0005 19.7207 24.4746 17.3081 25.1879C14.8955 25.9013 12.317 25.8156 9.95704 24.9437C7.59712 24.0719 5.58226 22.4605 4.21295 20.35C2.84364 18.2394 2.19325 15.7428 2.35879 13.2324C2.52432 10.722 3.4969 8.33243 5.13149 6.41997C6.76607 4.50751 8.97508 3.17466 11.429 2.62022C13.883 2.06578 16.4505 2.31944 18.7485 3.34339M25.6668 4.66671L14.0002 16.345L10.5002 12.845"
        stroke="#079455"
        strokeWidth="2"
        strokeLinecap="round"
        strokeLinejoin="round"
      />
    </svg>
  );
};

export default CheckmarkIcon;
