import {
  faCreditCard,
  faShoppingCart,
  faUserPlus,
} from "@fortawesome/free-solid-svg-icons";
import { FontAwesomeIcon } from "@fortawesome/react-fontawesome";
import {
  AccordionWizard,
  Checkout,
  DATE_FORMAT,
  PARAMETER_DATE_FORMAT,
  PARAMETER_DATE_ONLY_FORMAT,
  SaleType,
  SERVICE_START_DATE_LIMIT,
  SessionPackCarousel,
  useSaleStepTitleBuilder,
} from "@gymflow/common";
import { formatCurrency } from "@gymflow/helpers";
import { useStoreState } from "easy-peasy";
import moment from "moment-timezone";
import PropTypes from "prop-types";
import qs from "qs";
import { useEffect, useMemo, useState } from "react";
import { useHistory } from "react-router";

import usePaymentSummary from "../../hooks/usePaymentSummary";
import { usePortalRoutes } from "../../hooks/usePortalRoutes";
import { useValidateMemberDetails } from "../../hooks/useValidateMemberDetails";
import { useClubSettings } from "../../providers";
import { RouteFeature } from "../../routes/feature";
import { RouteLayout } from "../../routes/layout";
import useGymflowModels from "../../store";
import calendarIcon from "./../../../assets/img/calendar.svg";
import BuyRoute from "./BuyRoute";
import MemberUserDetailsUpdate from "./MemberUserDetailsUpdate";

function BuySessionPackAccordion({
  userMemberId,
  userDetails,
  sessionPacks,
  currency,
  fetchPaymentMethods,
  fetchLeadSources,
  dateFormat,
  postCodeFormat,
  defaultNationality,
  telephoneFormat,
  purchase,
  summary,
  findByEmail,
  onUserSubmit,
  cardHoldersName,
  event,
  initialCart,
  requiredFields,
}) {
  const { createClubLink } = usePortalRoutes();
  const { settingsStore } = useGymflowModels();
  const { stripeApplicationId } = useStoreState(settingsStore);
  const settings = useClubSettings();

  const openingGymDateSetting = settings.gym_opening_date;
  const openingGymDate = useMemo(
    () =>
      moment().isBefore(
        moment(openingGymDateSetting, PARAMETER_DATE_ONLY_FORMAT),
      )
        ? moment(openingGymDateSetting, PARAMETER_DATE_ONLY_FORMAT).format(
            DATE_FORMAT,
          )
        : undefined,
    [openingGymDateSetting],
  );

  const stripePublicKey = settings.stripe_public_key;
  const [activeStep, setActiveStep] = useState(0);
  const history = useHistory();

  const [cart, setCart] = useState(initialCart);
  const [user, setUser] = useState(null);

  const {
    summary: paymentSummary,
    onUpdateSessionPackSummary: onUpdateSummary,
  } = usePaymentSummary({
    fetchSummary: summary,
    currency,
  });

  const { validateMemberDetails } = useValidateMemberDetails({
    sellerUserType: "MEMBER",
  });

  useEffect(() => {
    setCart(initialCart);
    const moveToCorrectStep = async () => {
      const isUserDataComplete = await validateMemberDetails({
        ...userDetails,
        source: {
          id: userDetails?.lead?.source.id,
          name: userDetails?.lead?.source.name,
        },
      });

      let stepToSelect = 0;
      if (event) {
        stepToSelect += 1;
      }
      if (initialCart.length > 0) {
        stepToSelect += 1;
        if (isUserDataComplete) {
          stepToSelect += 1;
        }
      }

      setActiveStep(stepToSelect);
    };
    moveToCorrectStep();
  }, [event, initialCart]);

  const { step1: step1Title } = useSaleStepTitleBuilder({
    saleType: SaleType.SessionPack,
    activeStep,
    event,
    cart,
    recurringMembershipLink: createClubLink(
      RouteLayout.Member,
      RouteFeature.CustomerSale,
      BuyRoute.RecurringMembership,
    ),
    prepaidMembershipLink: createClubLink(
      RouteLayout.Member,
      RouteFeature.CustomerSale,
      BuyRoute.PrepaidMembership,
    ),
    sessionPackLink: createClubLink(
      RouteLayout.Member,
      RouteFeature.CustomerSale,
      BuyRoute.SessionsPack,
    ),
  });

  useEffect(() => {
    if (event) {
      if (activeStep === 1) {
        const params = qs.parse(location.search.slice(1));
        delete params.sessionPackId;
        history.push({ search: qs.stringify(params) });
      }
    } else if (activeStep === 0) {
      const params = qs.parse(location.search.slice(1));
      delete params.sessionPackId;
      history.push({ search: qs.stringify(params) });
    }
  }, [activeStep]);

  useEffect(() => {
    if (!cart.length) {
      return;
    }
    onUpdateSummary({
      sessionPackId: cart[0].id,
      userMemberId,
      sessionPackName: cart[0].name,
    });
  }, [cart]);

  const steps = [
    {
      title: step1Title,
      icon: <FontAwesomeIcon icon={faShoppingCart} />,
      component: SessionPackCarousel,
      props: {
        sessionPacks,
        onSelectClick: (sessionPackId) => {
          let params = `?sessionPackId=${sessionPackId}`;
          if (event) {
            params += `&eventId=${event.id}`;
          }
          history.push({
            search: params,
          });
        },
      },
    },
    {
      title: "2. Update your details",
      icon: <FontAwesomeIcon icon={faUserPlus} />,
      component: MemberUserDetailsUpdate,
      contentInsideTitleCard: true,
      props: {
        fetchLeadSources,
        initialValues: userDetails,
        onUserSelectClick: async (userSelected) => {
          const updatedUser = await onUserSubmit(userSelected);
          if (!updatedUser) {
            return;
          }
          setUser(updatedUser);
          setActiveStep(activeStep + 1);
        },
        postCodeFormat,
        telephoneFormat,
        defaultNationality,
        dateFormat,
        findByEmail,
        requiredFields,
      },
    },
    {
      title: "3. Checkout",
      icon: <FontAwesomeIcon icon={faCreditCard} />,
      component: Checkout,
      contentInsideTitleCard: true,
      props: {
        stripeApplicationId,
        stripePublicKey,
        userEmail: userDetails.email,
        cardHoldersName,
        lineItems: paymentSummary.lineItems,
        total: paymentSummary.total,
        discount: paymentSummary.discount,
        fetchPaymentMethods,
        dateFormat,
        validateDate: (date) => {
          const todayInGym = moment()
            .tz(settings.timezone)
            .format("YYYY-MM-DD");
          const maxDate = moment(todayInGym, "YYYY-MM-DD")
            .add(SERVICE_START_DATE_LIMIT)
            .format(PARAMETER_DATE_ONLY_FORMAT);
          const minDate = openingGymDate
            ? moment(openingGymDate, DATE_FORMAT)
                .subtract(1, "day")
                .endOf("day")
                .format(PARAMETER_DATE_ONLY_FORMAT)
            : moment(todayInGym, "YYYY-MM-DD")
                .subtract(1, "day")
                .endOf("day")
                .format(PARAMETER_DATE_ONLY_FORMAT);

          return date.isAfter(minDate) && date.isBefore(maxDate);
        },
        onTakePaymentClick: (paymentDetails) =>
          purchase({ ...paymentDetails, user, cart }),
        onUpdateSummary: (rest) =>
          onUpdateSummary({
            sessionPackId: cart[0].id,
            userMemberId,
            sessionPackName: cart[0].name,
            ...rest,
          }),
        termsAndConditionsUrl: cart[0] && cart[0].termsAndConditionsLink,
        skipPaymentMethod: paymentSummary.total === formatCurrency(0, currency),
        startDate: openingGymDate,
        timezone: settings.timezone,
      },
    },
  ];

  if (event) {
    const dateFormat = settings.date_format;
    steps.unshift({
      title: `Booking: ${moment(event.startDate, PARAMETER_DATE_FORMAT).format(
        `${dateFormat} h:mm a`,
      )} ${event.event.activity.name}`,
      icon: <img src={calendarIcon} alt={"Calendar Icon"} />,
    });
  }

  return (
    <AccordionWizard
      steps={steps}
      activeStep={activeStep}
      onStepTitleClick={(stepClicked) => {
        if (event && stepClicked === 0) {
          return;
        }
        setActiveStep(stepClicked);
      }}
    />
  );
}

BuySessionPackAccordion.defaultProps = {
  sessionPacks: [],
  event: null,
  initialCart: [],
};

BuySessionPackAccordion.propTypes = {
  userMemberId: PropTypes.string.isRequired,
  userDetails: PropTypes.object.isRequired,
  cardHoldersName: PropTypes.string,
  sessionPacks: PropTypes.arrayOf(
    PropTypes.shape({
      id: PropTypes.number.isRequired,
      name: PropTypes.string.isRequired,
      description: PropTypes.string,
      price: PropTypes.string.isRequired,
      sessionsIncluded: PropTypes.string.isRequired,
      expiry: PropTypes.string,
    }),
  ),
  purchase: PropTypes.func.isRequired,
  summary: PropTypes.func.isRequired,
  currency: PropTypes.string.isRequired,
  fetchPaymentMethods: PropTypes.func.isRequired,
  dateFormat: PropTypes.string.isRequired,
  onUserSubmit: PropTypes.func.isRequired,
  event: PropTypes.object,
  initialCart: PropTypes.array,
};

export default BuySessionPackAccordion;
