import { useMemberAsMemberNew } from "@gymflow/api";
import { cn } from "@gymflow/helpers";
import { usePortalRoutes } from "apps/portal/src/hooks";
import { ModalContext, useAuthenticatedUser } from "apps/portal/src/providers";
import useGymflowModels from "apps/portal/src/store";
import { ReactNode, useCallback, useContext, useMemo } from "react";
import { useTranslation } from "react-i18next";
import { Link, LinkProps } from "react-router-dom";

import { RouteFeature } from "../../../../routes";
import { Button, ChevronRightIcon } from "../../../atoms";
import { Bell01Icon } from "../../../atoms/icons/Bell01Icon";
import { CalendarDateIcon } from "../../../atoms/icons/CalendarDateIcon";
import { Coins03Icon } from "../../../atoms/icons/Coins03Icon";
import { CreditCard02Icon } from "../../../atoms/icons/CreditCard02Icon";
import { File02Icon } from "../../../atoms/icons/File02Icon";
import { LogOut01Icon } from "../../../atoms/icons/LogOut01Icon";
import { LogOut02Icon } from "../../../atoms/icons/LogOut02Icon";
import { ReceiptCheckIcon } from "../../../atoms/icons/ReceiptCheckIcon";
import { UserSquareIcon } from "../../../atoms/icons/UserSquareIcon";
import { ConfirmModal } from "../../../templates";

type SectionItem = {
  id: string;
  label: string;
  icon: ReactNode;
  link?: string;
  openLinkInNewTab?: boolean;
};

type MenuSectionType = {
  id: string;
  title: string;
  sections: SectionItem[];
};

export const HostedPagesProfileMenu = () => {
  const { t } = useTranslation();
  const { logout } = useAuthenticatedUser();
  const { api } = useGymflowModels();
  const { createSiteOrEmbedLink } = usePortalRoutes();
  const { setModal, hide: hideModal } = useContext(ModalContext);

  const { data: userMember } = useMemberAsMemberNew({ api });

  const menu: MenuSectionType[] = useMemo(
    () =>
      [
        {
          id: "account",
          title: t(
            "pages.hostedPagesProfile.hostedPagesProfileMenu.menu.account.title",
          ),
          sections: [
            {
              id: "membershipHistory",
              label: t(
                "pages.hostedPagesProfile.hostedPagesProfileMenu.menu.account.options.membershipHistory",
              ),
              icon: (
                <UserSquareIcon
                  className="h-5 w-5"
                  pathClassName="stroke-gray-500 dark:stroke-gray-400"
                />
              ),
            },
            {
              id: "packages",
              label: t(
                "pages.hostedPagesProfile.hostedPagesProfileMenu.menu.account.options.packages",
              ),
              icon: (
                <Coins03Icon
                  className="h-5 w-5"
                  pathClassName="stroke-gray-500 dark:stroke-gray-400"
                />
              ),
            },
            {
              id: "bookings",
              label: t(
                "pages.hostedPagesProfile.hostedPagesProfileMenu.menu.account.options.bookings",
              ),
              icon: (
                <CalendarDateIcon
                  className="h-5 w-5"
                  pathClassName="stroke-gray-500 dark:stroke-gray-400"
                />
              ),
            },
          ],
        },
        {
          id: "payment",
          title: t(
            "pages.hostedPagesProfile.hostedPagesProfileMenu.menu.payment.title",
          ),
          sections: [
            {
              id: "paymentMethods",
              label: t(
                "pages.hostedPagesProfile.hostedPagesProfileMenu.menu.payment.options.paymentMethods",
              ),
              icon: (
                <CreditCard02Icon
                  className="h-5 w-5"
                  pathClassName="stroke-gray-500 dark:stroke-gray-400"
                />
              ),
              link: RouteFeature.SiteProfilePayments,
            },
            {
              id: "receipts",
              label: t(
                "pages.hostedPagesProfile.hostedPagesProfileMenu.menu.payment.options.receipts",
              ),
              icon: (
                <ReceiptCheckIcon
                  className="h-5 w-5"
                  pathClassName="stroke-gray-500 dark:stroke-gray-400"
                />
              ),
            },
          ],
        },
        {
          id: "preferences",
          title: t(
            "pages.hostedPagesProfile.hostedPagesProfileMenu.menu.preferences.title",
          ),
          sections: [
            {
              id: "notifications",
              label: t(
                "pages.hostedPagesProfile.hostedPagesProfileMenu.menu.preferences.options.notifications",
              ),
              icon: (
                <Bell01Icon
                  className="h-5 w-5"
                  pathClassName="stroke-gray-500 dark:stroke-gray-400"
                />
              ),
            },
          ],
        },
        {
          id: "documents",
          title: t(
            "pages.hostedPagesProfile.hostedPagesProfileMenu.menu.documents.title",
          ),
          sections: [
            {
              id: "waiverAndTerms",
              label: t(
                "pages.hostedPagesProfile.hostedPagesProfileMenu.menu.documents.options.waiverAndTerms",
              ),
              icon: (
                <File02Icon
                  className="h-5 w-5"
                  pathClassName="stroke-gray-500 dark:stroke-gray-400"
                />
              ),
              link: userMember?.homeClub?.termsConditions,
              openLinkInNewTab: true,
            },
          ],
        },
      ] satisfies MenuSectionType[],
    [t, userMember?.homeClub?.termsConditions],
  );

  const onClickLogout = useCallback(() => {
    setModal(
      <ConfirmModal
        type="danger"
        icon={
          <LogOut02Icon className="h-6 w-6" pathClassName="stroke-error-600" />
        }
        title={t(
          "pages.hostedPagesProfile.hostedPagesProfileMenu.logoutModal.title",
        )}
        confirmText={t(
          "pages.hostedPagesProfile.hostedPagesProfileMenu.logoutModal.confirmText",
        )}
        onCancel={() => hideModal()}
        onConfirm={async () => {
          await logout();
          hideModal();
        }}
      >
        {t(
          "pages.hostedPagesProfile.hostedPagesProfileMenu.logoutModal.description",
        )}
      </ConfirmModal>,
    );
  }, [hideModal, logout, setModal, t]);

  return (
    <div className="bg-gray-0 flex flex-1 flex-col gap-[1.125rem] overflow-y-auto rounded-[1.25rem] border border-gray-300 p-4 dark:border-gray-700 dark:bg-gray-950 lg:max-w-lg">
      {menu.map(({ id: menuId, title, sections }) => (
        <div key={menuId} className="flex flex-col gap-1.5">
          <div className="text-xs font-semibold uppercase text-gray-500 dark:text-gray-400">
            {title}
          </div>
          <div className="shadow-xs bg-gray-0 rounded-2xl border border-gray-300 dark:border-gray-700 dark:bg-gray-950">
            {sections.map(
              (
                { id: sectionId, label, icon, link, openLinkInNewTab },
                index,
              ) => {
                const Component = !link
                  ? (props: Partial<React.ComponentPropsWithRef<"div">>) => (
                      <div {...props} />
                    )
                  : !openLinkInNewTab
                    ? ({ children, ...props }: Partial<LinkProps>) => (
                        <Link {...props} to={createSiteOrEmbedLink(link ?? "")}>
                          {children}
                        </Link>
                      )
                    : ({
                        children,
                        ...props
                      }: Partial<React.ComponentPropsWithoutRef<"a">>) => (
                        <a
                          {...props}
                          target="_blank"
                          rel="noreferrer"
                          href={link}
                        >
                          {children}
                        </a>
                      );
                return (
                  <Component
                    key={sectionId}
                    className={cn(
                      "flex justify-between py-4 pl-3 pr-2 items-center",
                      {
                        "border-b border-b-gray-300 dark:border-b-gray-700":
                          sections.length > index + 1,
                        "cursor-pointer hover:opacity-80": link,
                        "cursor-not-allowed opacity-30": !link,
                      },
                    )}
                  >
                    <div className="flex items-center gap-3">
                      {icon}
                      <div className="dark:text-gray-25 text-base font-medium text-gray-950">
                        {label}
                      </div>
                    </div>
                    <ChevronRightIcon
                      className="h-6 w-6"
                      pathClassName="dark:stroke-gray-400 stroke-gray-500"
                    />
                  </Component>
                );
              },
            )}
          </div>
        </div>
      ))}
      <Button
        intent="error-outline"
        className="border-error-300 mt-auto w-full gap-2 self-center lg:w-56"
        onClick={() => onClickLogout()}
      >
        <div className="text-error-600 dark:text-error-400 text-base font-semibold">
          {t("pages.hostedPagesProfile.hostedPagesProfileMenu.logout")}
        </div>
        <LogOut01Icon
          className="h-5 w-5"
          pathClassName="stroke-error-600 dark:stroke-error-400"
        />
      </Button>
    </div>
  );
};
