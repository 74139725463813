import "./Input.css";

import { cn } from "@gymflow/helpers";
import React, {
  FocusEventHandler,
  forwardRef,
  HTMLInputTypeAttribute,
  ReactNode,
  useState,
} from "react";

import Label from "../Label";

type InputVariant = "default" | "unstyled" | "small";

export type InputProps = {
  value: string;
  onChange: (e: React.ChangeEvent<HTMLInputElement>) => void;
  placeholder?: string;
  className?: string;
  labelClassName?: string;
  label?: string | ReactNode;
  onFocus?: FocusEventHandler;
  onBlur?: FocusEventHandler;
  startIcon?: ReactNode;
  endIcon?: ReactNode;
  readOnly?: boolean;
  renderOverInput?: ReactNode;
  variant?: InputVariant;
  disabled?: boolean;
  isRequired?: boolean;
  type?: HTMLInputTypeAttribute;
};

export const Input = forwardRef<HTMLInputElement, InputProps>(
  (
    {
      className,
      label,
      value,
      startIcon,
      labelClassName,
      endIcon,
      renderOverInput,
      variant = "default",
      isRequired = false,
      disabled,
      ...rest
    },
    ref,
  ) => {
    const [isFocused, setIsFocused] = useState(false);

    return (
      <div className="relative flex w-full flex-col gap-1.5">
        <Label
          className={labelClassName}
          label={label}
          isRequired={isRequired}
        />
        <div className="relative">
          {startIcon && (
            <div className="absolute left-3.5 top-1/2 z-10 -translate-y-1/2">
              {startIcon}
            </div>
          )}
          <input
            {...rest}
            ref={ref}
            placeholder={rest.placeholder}
            value={value}
            disabled={disabled}
            onFocus={(e) => {
              setIsFocused(true);
              rest.onFocus?.(e);
            }}
            onBlur={(e) => {
              setIsFocused(false);
              rest.onBlur?.(e);
            }}
            className={cn(
              "font-medium text-base dark:bg-gray-800",
              {
                "shadow-xs outline-secondary-200 w-full rounded-[0.625rem] border border-gray-200 px-3.5 py-2.5 disabled:bg-gray-50 h-10 dark:!outline-none focus:dark:ring-1 dark:ring-secondary-600 dark:border-gray-700":
                  variant === "default",
                "shadow-xs outline-secondary-200 w-full rounded-[0.625rem] border border-gray-200 px-3.5 py-1.5 disabled:bg-gray-50 h-9 dark:!outline-none focus:dark:ring-1 dark:ring-secondary-600 dark:border-gray-700":
                  variant === "small",
                "bg-transparent border-none p-0 outline-none":
                  variant === "unstyled",
                "text-gray-950 dark:text-gray-0": value,
                "text-gray-500": !value,
                "pl-10": startIcon,
                "pr-10": endIcon,
                "text-ellipsis overflow-hidden whitespace-nowrap": !isFocused,
                "cursor-not-allowed opacity-70": disabled,
              },
              className,
            )}
          />
          {endIcon && (
            <div className="absolute right-3.5 top-1/2 z-10 -translate-y-1/2">
              {endIcon}
            </div>
          )}
          {renderOverInput}
        </div>
      </div>
    );
  },
);

Input.displayName = "Input";
