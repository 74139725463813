import { ReactNode } from "react";

export type SettingSectionProps = {
  title: ReactNode;
  content: ReactNode;
};

export const SettingsSection: React.FC<SettingSectionProps> = ({
  title,
  content,
}) => {
  return (
    <div className="flex w-full flex-row flex-wrap gap-y-4 border-b border-gray-300 py-4">
      <div className="flex w-full lg:w-2/5">{title}</div>
      <div className="bg-gray-0 flex w-full rounded-xl border border-gray-200 shadow-sm lg:w-3/5">
        {content}
      </div>
    </div>
  );
};
