import { useMutationPayInvoiceAsMember } from "@gymflow/api";
import {
  AlertContext,
  AsyncButton,
  GuestStatus,
  useParseErrors,
} from "@gymflow/common";
import { EventRsvpStatus, InvoiceDTO } from "@gymflow/types";
import classNames from "classnames";
import { useContext } from "react";
import Alert from "react-bootstrap-sweetalert";

import useMemberRules from "../hooks/useMemberRules";
import useScaModal from "../hooks/useScaModal";
import { ModalContext } from "../providers";
import useGymflowModels from "../store/index.js";
import { ConfirmModal } from "./templates";

function RsvpButton({
  activityId,
  eventCapacity,
  onClick,
  startDate,
  bookedCount,
  waitingCount,
  waitListCapacity,
  rsvpStatus,
}: {
  activityId: number;
  onClick: ({
    status,
  }: {
    status: EventRsvpStatus;
  }) => Promise<{ data: { invoiceNumber?: string } }>;
  eventCapacity: number;
  waitListCapacity: number;
  bookedCount: number;
  waitingCount: number;
  startDate: string;
  rsvpStatus?: string;
}) {
  const { setAlert, hide } = useContext(AlertContext);
  let text;
  let className;
  let newStatus: EventRsvpStatus;
  const { setModal, hide: hideModal } = useContext(ModalContext);
  const alert = useContext(AlertContext);
  const showScaModal = useScaModal({
    asMember: true,
  });
  const { api } = useGymflowModels();
  const { validateMemberBooking, validateMemberCancel } = useMemberRules({
    activityId,
  });
  const isFull = bookedCount >= eventCapacity;
  let title = "";
  let onClickButton = (e: MouseEvent) => {
    e.stopPropagation();
    const onClickArguments = {
      status: newStatus,
    };
    onClick(onClickArguments);
  };

  const payInvoiceMutation = useMutationPayInvoiceAsMember({ api });
  const parseError = useParseErrors();
  if (
    !rsvpStatus ||
    rsvpStatus === GuestStatus.Cancelled ||
    rsvpStatus === GuestStatus.LateCanceled
  ) {
    onClickButton = (e: MouseEvent) => {
      e.stopPropagation();
      const bookingError = validateMemberBooking(startDate);
      if (bookingError) {
        setAlert(
          //@ts-ignore
          <Alert
            title="Cannot book"
            type="danger"
            closeOnClickOutside={false}
            style={{
              width: "39em",
            }}
            onConfirm={hide}
          >
            {bookingError}
          </Alert>,
        );
      } else {
        onClick({ status: "BOOKED" });
      }
    };

    if (!isFull) {
      className = "btn-primary";
      text = "Book Now";
      newStatus = GuestStatus.Booked;
    } else if (waitingCount < waitListCapacity) {
      className = "btn-warning";
      text = "Join Waitlist";
      newStatus = GuestStatus.Booked;
    } else {
      className = "btn-danger";
      text = "Event Full";
    }
  } else {
    text = "Cancel";
    if (rsvpStatus === GuestStatus.Waiting) {
      className = "btn-warning";
      text = "Cancel Waitlist";
    }
    onClickButton = (e) => {
      const cancelError = validateMemberCancel(startDate);
      e.stopPropagation();
      if (cancelError) {
        setModal(
          <ConfirmModal
            onCancel={() => {
              hideModal();
            }}
            onConfirm={async () => {
              const { invoiceNumber } = (
                await onClick({
                  status: GuestStatus.LateCanceled,
                })
              ).data;
              if (!invoiceNumber) {
                hideModal();
                return;
              }
              const invoice = (
                await api.profileApi.findInvoices(undefined, {
                  page: 0,
                  limit: 1,
                  extraParams: {
                    number: invoiceNumber,
                  },
                })
              ).data.content[0] as InvoiceDTO;
              try {
                const response = await payInvoiceMutation.mutateAsync({
                  invoiceNumber,
                  amount: invoice.totalAmount,
                });

                showScaModal({
                  invoiceNumber: response.number,
                });
                hideModal();
              } catch (e: any) {
                alert.hide();
                if (e.response) {
                  await parseError(e.response);
                }
              }
            }}
            confirmText="Cancel Booking"
            title="Late Cancellation Fee"
          >
            {cancelError}
          </ConfirmModal>,
        );
      } else {
        onClick({ status: "CANCELLED" });
      }
    };
  }

  return (
    <AsyncButton
      className={classNames(className, "btn-link p-0")}
      size="sm"
      onClick={onClickButton}
      style={{ overflowWrap: "normal" }}
      title={title}
    >
      {text}
    </AsyncButton>
  );
}

export default RsvpButton;
