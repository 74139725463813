/* eslint-disable react/require-default-props */
/* eslint-disable react/function-component-definition */
import classNames from "classnames";
import React from "react";
import Select from "react-select";
import { Input } from "reactstrap";

import gender from "../../constants/gender";

export interface GenderSelect {
  value: string;
  onChange: (newValue: any) => void;
  isDisabled?: boolean;
  allowCustomOption?: boolean;
}
/***
 * @deprecated - use GenderSelect from `portal`
 * 
 * This component is kept to prevent breaking other components on `common` that don't have access to PaginatedSelect
 */
const GenderSelect: React.FC<GenderSelect> = ({
  value,
  onChange,
  isDisabled,
  allowCustomOption,
}) => {
  const customOption = {
    label: "I identify as...",
    value: {
      custom: true,
    },
  };

  const options = [
    {
      label: "Prefer not to say",
      value: gender.PreferNotToSay,
    },
    {
      label: "Female",
      value: gender.Female,
    },
    {
      label: "Male",
      value: gender.Male,
    },
  ];

  if (allowCustomOption) {
    options.push(customOption as any);
  }

  const isCustomOption = value && !Object.values(gender).includes(value as any);
  const selectValue = isCustomOption
    ? customOption
    : options.find((option) => option.value === value);

  return (
    <div style={{ display: "flex", justifyContent: "space-between" }}>
      <Select
        classNamePrefix="react-select"
        options={options}
        isSearchable={false}
        isDisabled={isDisabled}
        classNames={{
          control: () =>
            "!w-full !bg-gray-0 !text-base !font-normal !border-gray-300 !rounded-lg !h-11",
          singleValue: () => "!text-gray-900",
          indicatorsContainer: () =>
            classNames("[&>div]:!text-gray-500", {
              "!hidden": isDisabled,
            }),
          indicatorSeparator: () => "!hidden",
          container: () => "w-full",
        }}
        onChange={(newValue: any) => {
          if (newValue.value?.custom) {
            onChange("");
          } else {
            onChange(newValue.value);
          }
        }}
        value={selectValue}
      />
      <Input
        className={classNames("ml-2", { "d-none": !isCustomOption })}
        type="text"
        onChange={({ target: { value: newValue } }) => {
          onChange(newValue);
        }}
      />
    </div>
  );
};

export default GenderSelect;
