import { useEffect } from "react";
import { useHistory } from "react-router";

import { useAuthenticatedUser } from "../../providers";

export default function useRedirectIfNotAuthenticated(
  link: Parameters<ReturnType<typeof useHistory>["replace"]>[0],
) {
  const history = useHistory();
  const auth = useAuthenticatedUser();
  useEffect(() => {
    if (!auth.id) {
      history.replace(link);
    }
  }, [auth, link, history]);
}
