import { AlertTriangleIcon } from "apps/portal/src/components/atoms";

type WarningBlockProps = {
  text: string;
};

export const WarningBlock = ({ text }: WarningBlockProps) => (
  <div className="bg-warning-100 flex items-start gap-2 rounded-lg px-3 py-1.5">
    <div className="my-1">
      <AlertTriangleIcon
        className="h-4 w-4"
        pathClassName="stroke-warning-700"
      />
    </div>
    <div className="text-warning-700 text-sm font-medium">{text}</div>
  </div>
);
