import { useAutoAnimate } from "@formkit/auto-animate/react";
import { useQueryAppointableAsPublic } from "@gymflow/api";
import { cn } from "@gymflow/helpers";
import { usePortalRoutes } from "apps/portal/src/hooks";
import { RouteFeature } from "apps/portal/src/routes";
import { Duration } from "luxon";
import React from "react";
import { useTranslation } from "react-i18next";
import { useParams } from "react-router";

import useGymflowModels from "../../../store";
import { Button, ClockIcon, LayeredCircles, Spinner } from "../../atoms";
import { ExclamationCircleIcon } from "../../atoms/icons/ExclamationCircleIcon";
import { HostedPagesBackHeader } from "../components/HostedPagesBackHeader";
import { HostedPagesCard } from "../components/HostedPagesCard";
import HostedPagesOverviewCard from "../components/HostedPagesOverviewCard";

export type HostedPagesAppointmentOverviewProps = {};

export const HostedPagesAppointmentOverview: React.FC<
  HostedPagesAppointmentOverviewProps
> = ({}) => {
  const { appointableId } = useParams<{ appointableId: string }>();
  const { createSiteOrEmbedLink, isEmbed } = usePortalRoutes();
  
  const { t } = useTranslation();
  const { api } = useGymflowModels();
  const { data: appointable, isLoading } = useQueryAppointableAsPublic({
    api,
    id: parseInt(appointableId),
  });
  const [parent] = useAutoAnimate();
  const singleHost = appointable
    ? appointable.availabilityType === "FACILITY" &&
      appointable.appointableFacilities.length === 1
      ? appointable.appointableFacilities[0].id
      : appointable.availabilityType === "STAFF" &&
        appointable.appointableHosts.length === 1
      ? appointable.appointableHosts[0].id
      : undefined
    : undefined;

  const nextStepLink = createSiteOrEmbedLink(
    (!singleHost
      ? RouteFeature.AppointableIdSelect
      : RouteFeature.AppointableIdSelectTime
    )
      .replace(":appointableId", appointable?.id?.toString() ?? "")
      .replace(":staffOrFacilityId", singleHost?.toString() ?? ""),
  );
  return (
    <div
      className={cn("track-height flex w-full flex-col overflow-y-auto", {
        "h-fit": isEmbed,
        "h-full": !isEmbed,
      })}
    >
      <HostedPagesBackHeader
        label={t("pages.hostedPagesAppointableOverView.header")}
      />
      <div
        ref={parent}
        className={cn(
          "flex flex-col items-center bg-gray-50 dark:bg-gray-800",
          {
            "h-fit": isEmbed,
            "h-full": !isEmbed,
          },
        )}
      >
        {isLoading && <Spinner />}
        {!isLoading && appointable && (
          <HostedPagesOverviewCard.Container>
            <div className="flex w-full flex-col items-center gap-6">
              <HostedPagesOverviewCard.Header>
                <HostedPagesOverviewCard.HeaderTitle>
                  <div className="flex w-full ">{appointable.name}</div>
                  <div className="flex flex-row items-center gap-1">
                    <ClockIcon
                      pathClassName="stroke-gray-500 stroke-1"
                      className="h-5 w-5"
                    />
                    <div className="text-base font-semibold text-gray-950 dark:text-gray-0">
                      {Duration.fromObject({
                        minutes: appointable.duration,
                      })
                        .rescale()
                        .toHuman({
                          unitDisplay: "narrow",
                          listStyle: "short",
                        })}
                    </div>
                  </div>
                </HostedPagesOverviewCard.HeaderTitle>
                {appointable.description && (
                  <HostedPagesOverviewCard.HeaderDescription>
                    {appointable.description}
                  </HostedPagesOverviewCard.HeaderDescription>
                )}
              </HostedPagesOverviewCard.Header>
              <div className="flex w-full flex-row items-center justify-start gap-2">
                <HostedPagesCard.AppointablePrice appointable={appointable} />
              </div>
              {!!appointable.termsAndConditions && (
                <>
                  <div className="flex w-full flex-row items-center justify-between border-t border-gray-200 dark:border-gray-800" />
                  <div className="flex w-full flex-col gap-2">
                    <div className="flex flex-row items-center gap-2">
                      <LayeredCircles className="h-8 w-8" intent="default">
                        <ExclamationCircleIcon pathClassName="stroke-gray-500" />
                      </LayeredCircles>
                      <div className="font-semibold text-gray-950 dark:text-gray-0">
                        {t("page.hostedPagesAppointableOverView.terms")}
                      </div>
                    </div>
                    <div className="text-sm text-gray-500">
                      {appointable.termsAndConditions}
                    </div>
                  </div>
                </>
              )}
            </div>
            <Button
              className="w-fit px-24"
              link={nextStepLink}
              intent="secondary"
            >
              {t("page.hostedPagesAppointableOverView.book")}
            </Button>
          </HostedPagesOverviewCard.Container>
        )}
      </div>
    </div>
  );
};
