import { cn } from "@gymflow/helpers";
import { ReactNode } from "react";

import { RadioButton } from "./RadioButton";

export type RadioButtonOption<T extends string | number> = {
  label: ReactNode;
  value: T;
};

export type RadioButtonGroupProps<T extends string | number> = {
  options: RadioButtonOption<T>[];
  value: T;
  onChange: (value: React.ChangeEvent<HTMLInputElement>) => void;
  name?: string;
  className?: string;
  label?: ReactNode;
  verticalLayout?: boolean;
  disabled?: boolean;
};

export const RadioButtonGroup = <T extends string | number>({
  options,
  value,
  onChange,
  name,
  className,
  label,
  verticalLayout,
  disabled,
}: RadioButtonGroupProps<T>) => (
  <div className="flex flex-col gap-2 ">
    {label && <div className="dark:text-gray-0 text-sm font-medium text-gray-950">{label}</div>}
    <div
      className={cn(
        "flex gap-4",
        {
          "flex-row": !verticalLayout,
          "flex-col": verticalLayout,
          "opacity-30 cursor-not-allowed": disabled,
        },
        className,
      )}
    >
      {options.map((option, index) => (
        <RadioButton
          key={`${option.value}-${index}`}
          value={option.value}
          label={option.label}
          checked={value === option.value}
          onChange={onChange}
          name={name}
          disabled={disabled}
        />
      ))}
    </div>
  </div>
);
