import { useAutoAnimate } from "@formkit/auto-animate/react";
import {
  memberAsMemberQueryKeys,
  useMemberAsMember,
  useMemberInvoiceListAsMember,
  useMutationMemberSaleAsMember,
  useMutationPayInvoiceAsMember,
  useQueryCreditPackAsMember,
  useQueryCreditPackSummaryAsMember,
  useQueryMembershipAsMember,
  useQueryMembershipSummaryAsMember,
} from "@gymflow/api";
import {
  cn,
  formatCurrency,
  LUXON_DATE_FORMAT,
  membershipHelper,
} from "@gymflow/helpers";
import { InvoiceDTO } from "@gymflow/types";
import { useQueryClient } from "@tanstack/react-query";
import { isAxiosError } from "axios";
import { DateTime } from "luxon";
import React, { useContext, useEffect, useState } from "react";
import { useTranslation } from "react-i18next";
import { useParams } from "react-router";

import { usePortalRoutes, useRedirectUrl } from "../../hooks";
import useScaModal from "../../hooks/useScaModal";
import { useAuthenticatedUser, useClubSettings } from "../../providers";
import { ToastContext } from "../../providers/ToastProvider/context";
import { RouteFeature } from "../../routes";
import useGymflowModels from "../../store";
import { Button, ErrorMessage, LabeledForm, Spinner } from "../atoms";
import { DatePicker } from "../molecules";
import { PaymentMethodPicker } from "../molecules/PaymentMethodPicker";
import { ShopCartType } from "../Shop/ShopTypes";
import { HostedPagesBackHeader } from "./components/HostedPagesBackHeader";
import useRedirectIfNotAuthenticated from "./useRedirectIfNotAuthenticated";

export type HostedPagesCheckoutProps = {
  type: ShopCartType["type"];
  onClose?: () => void;
  quickPurchaseFinishedButton?: React.ReactNode;
};

export const HostedPagesCheckout: React.FC<HostedPagesCheckoutProps> = ({
  type,
  onClose,
  quickPurchaseFinishedButton,
}) => {
  const { isEmbed, isQuickPurchase } = usePortalRoutes();
  const { t } = useTranslation();
  const [currentInvoiceId, setCurrentInvoiceId] = useState<string>();
  const { id: memberId } = useAuthenticatedUser();
  const { createSiteOrEmbedLink } = usePortalRoutes();

  useRedirectIfNotAuthenticated(
    createSiteOrEmbedLink(
      RouteFeature.SiteBuyMembershipType.replace(":membershipType", "all"),
    ),
  );
  const { api } = useGymflowModels();
  const settings = useClubSettings();
  const { data: userMember } = useMemberAsMember({
    api,
    memberId,
    tz: settings.timezone,
  });
  const showScaModal = useScaModal({
    asMember: true,
  });
  const { membershipId, sessionPackId } = useParams<{
    membershipId?: string;
    sessionPackId?: string;
  }>();

  const [promotionCodeInput, setPromotionCodeInput] = useState<string>();
  const [currentPaymentMethodId, setCurrentPaymentMethodId] =
    useState<string>();

  const [cart, setCart] = useState<ShopCartType>();
  const { data: creditPack, isSuccess: isCreditPackSuccess } =
    useQueryCreditPackAsMember({
      api,
      id:
        type === "CREDIT_PACK" && sessionPackId !== undefined
          ? +sessionPackId
          : undefined,
      enabled: !!api.customerCreditPackApi,
    });
  useEffect(() => {
    if (isCreditPackSuccess) {
      setCart({
        type: "CREDIT_PACK",
        payload: creditPack,
      });
    }
  }, [isCreditPackSuccess, creditPack]);
  const { data: membership, isSuccess: isMembershipSuccess } =
    useQueryMembershipAsMember({
      api,
      id:
        type === "MEMBERSHIP" && membershipId !== undefined
          ? +membershipId
          : undefined,
      enabled: !!api.customerMembershipApi,
    });
  useEffect(() => {
    if (isMembershipSuccess) {
      setCart({
        type: "MEMBERSHIP",
        payload: membership,
      });
      if (
        membership &&
        "fixedStartDate" in membership &&
        !!membership.fixedStartDate
      ) {
        setStartDate(
          DateTime.fromISO(membership.fixedStartDate)
            .setZone(settings.timezone)
            .toISODate()!,
        );
      }
    }
  }, [isMembershipSuccess, membership, settings]);
  const gymOpeningDate = DateTime.fromFormat(
    settings.gym_opening_date,
    "yyyy-MM-dd",
    { zone: settings.timezone },
  );
  const isStartDayInTheFuture = gymOpeningDate > DateTime.now();
  const [startDate, setStartDate] = useState<string>(
    (cart && "fixedStartDate" in cart.payload && !!cart.payload.fixedStartDate
      ? DateTime.fromISO(cart.payload.fixedStartDate)
      : isStartDayInTheFuture
      ? gymOpeningDate
      : DateTime.now().setZone(settings.timezone).startOf("day")
    ).toFormat(LUXON_DATE_FORMAT),
  );
  const [promotionCode, setPromotionCode] = useState<string>();
  const { data: creditPackSummary, isFetching: creditPackSummaryIsFetching } =
    useQueryCreditPackSummaryAsMember({
      api,
      opts: {
        sessionPackId: cart?.type === "CREDIT_PACK" ? cart.payload.id : 0,
        promotionCode: promotionCode,
      },
      enabled: cart?.type === "CREDIT_PACK",
    });
  const { data: membershipSummary, isFetching: membershipSummaryIsFetching } =
    useQueryMembershipSummaryAsMember({
      api,
      opts: {
        startDate: DateTime.fromISO(startDate)
          .setZone(settings.timezone)
          .startOf("day")
          .toUTC()
          .toISO({ suppressMilliseconds: true })!,
        membershipId: cart?.type === "MEMBERSHIP" ? cart.payload.id : 0,
        promotionCode: promotionCode,
      },
      enabled: cart?.type === "MEMBERSHIP",
    });

  const isFetchingSummary =
    creditPackSummaryIsFetching || membershipSummaryIsFetching; //|| productSummaryIsFetching;
  const summary = creditPackSummary || membershipSummary; // || productSummary;
  const { data: invoices } = useMemberInvoiceListAsMember({
    api,
    opts: {
      extraParams: {
        number: currentInvoiceId,
      },
    },
    enabled: !!currentInvoiceId,
  });
  const invoice = invoices?.content?.[0];

  const { mutateAsync: payFailed } = useMutationPayInvoiceAsMember({
    api,
  });
  const { mutateAsync: checkout } = useMutationMemberSaleAsMember({
    api,
    tz: settings.timezone,
    clubId: settings.clubId,
  });
  const { notifyDanger, toast } = useContext(ToastContext);
  const onPay = async () => {
    if (!cart) return;
    try {
      let result: { invoice: InvoiceDTO };
      const paymentMethod = shouldCollectPaymentMethod
        ? currentPaymentMethodId
        : undefined;
      if (cart.type === "MEMBERSHIP") {
        result = await checkout({
          paymentMethod,
          promotionCode,
          startDate,
          membershipId: cart.payload.id,
        });
      } else if (cart.type === "CREDIT_PACK") {
        result = await checkout({
          paymentMethod,
          promotionCode,
          startDate,
          sessionPackId: cart.payload.id,
        });
      } else {
        result = await checkout({
          paymentMethod,
          promotionCode,
          products: cart.payload.map((e) => ({
            productId: e.product.id,
            quantity: e.quantity,
          })),
        });
      }
      setCurrentInvoiceId(result.invoice.number);
      showScaModal({ invoiceNumber: result.invoice.number });
    } catch (e) {
      if (isAxiosError(e)) {
        notifyDanger(e);
      }
    }
  };
  const queryClient = useQueryClient();

  const { redirectToCustomizedWebsiteOr } = useRedirectUrl();
  useEffect(() => {
    if (invoice?.status === "PAID" && !isQuickPurchase) {
      toast({
        message: t("pages.hostedPagesCheckout.invoicePaid"),
        description: t("pages.hostedPagesCheckout.invoicePaidDescription"),
        intent: "success",
      });
      setTimeout(() => {
        redirectToCustomizedWebsiteOr();
      }, 1500);
    }
  }, [
    invoice?.status,
    isQuickPurchase,
    redirectToCustomizedWebsiteOr,
    t,
    toast,
  ]);
  const isFree = summary?.upfrontPriceDetails?.totalAmountToPay === 0;
  const shouldCollectPaymentMethod =
    !isFree ||
    (cart?.type === "MEMBERSHIP" && cart?.payload?.type === "RECURRING");
  const [parent] = useAutoAnimate();
  const paymentsLoadingStatus = queryClient.getQueryState(
    memberAsMemberQueryKeys.paymentMethods(settings.clubId, memberId),
  )?.status;
  const arePaymentMethodsLoading =
    !paymentsLoadingStatus || paymentsLoadingStatus === "loading";
  const showFixedStartDateInPastError = !!(
    cart?.type === "MEMBERSHIP" &&
    cart.payload.fixedStartDate &&
    DateTime.fromISO(cart.payload.fixedStartDate)
      .setZone(settings.timezone)
      .endOf("day") < DateTime.now()
  );
  return (
    <div
      className={cn(
        "flex w-full flex-col items-center overflow-y-auto bg-gray-50 dark:bg-gray-800",
        {
          "track-height": !isQuickPurchase,
          "h-fit": !isQuickPurchase && isEmbed,
          "h-full": isQuickPurchase || !isEmbed,
        },
      )}
    >
      <HostedPagesBackHeader label={t("common.checkout")} onClose={onClose} />
      <div
        ref={parent}
        className="flex h-fit w-full max-w-lg flex-col gap-4 px-4 py-6"
      >
        {invoice && (
          <div className="flex w-full flex-col items-center gap-y-3">
            <div className="dark:text-gray-0 text-3xl font-bold text-gray-950">
              {formatCurrency(invoice.totalAmount, settings.defaultCurrency)}
            </div>
            <div className="flex flex-col items-center gap-1">
              {invoice.status === "PAID" && (
                <>
                  <div className="text-success-600 dark:text-success-400 text-center font-bold">
                    {t("pages.hostedPagesCheckout.paymentSuccessful")}
                  </div>
                  <div className="font-medium text-gray-600 dark:text-gray-400">
                    {DateTime.fromISO(
                      invoice.issueDate ?? new Date().toISOString(),
                    ).toLocaleString(DateTime.DATE_MED)}
                  </div>
                </>
              )}
              {invoice.status === "PAST_DUE" && (
                <div className="flex flex-col items-center gap-3">
                  <div className="text-error-600 dark:text-error-400 text-center font-bold">
                    {t("pages.hostedPagesCheckout.paymentFailed")}
                  </div>
                  <div className="font-medium text-gray-600 dark:text-gray-400">
                    <Button
                      onClick={async () => {
                        const newInvoice = await payFailed({
                          invoiceNumber: invoice.number,
                          amount: invoice.totalAmount,
                          paymentMethodId: currentPaymentMethodId,
                        });
                        showScaModal({ invoiceNumber: newInvoice.number });
                      }}
                    >
                      {t("pages.hostedPagesCheckout.retry")}
                    </Button>
                  </div>
                </div>
              )}
              {invoice.status === "AWAITING_AUTHORIZATION" && (
                <div className="text-warning-600 text-center font-bold">
                  {t("pages.hostedPagesCheckout.authorize")}
                </div>
              )}
            </div>
          </div>
        )}
        {shouldCollectPaymentMethod && userMember && (
          <div className="max-w-full">
            <PaymentMethodPicker
              userMember={userMember}
              currentPaymentMethodId={currentPaymentMethodId}
              onChange={setCurrentPaymentMethodId}
              disabled={!!invoice}
              asMember
            />
          </div>
        )}
        {settings.allow_customer_selecting_start_date &&
          !isStartDayInTheFuture &&
          cart?.type === "MEMBERSHIP" &&
          !cart.payload.fixedStartDate && (
            <LabeledForm label={t("pages.hostedPagesCheckout.startDate")}>
              <DatePicker
                inputClassnames="dark:bg-gray-900 dark:hover:!bg-gray-900"
                label={DateTime.fromFormat(startDate, LUXON_DATE_FORMAT)
                  .setZone("local", { keepLocalTime: true })
                  .toLocaleString(DateTime.DATE_MED)}
                mode="single"
                modifiers={{
                  disabled: (d) => {
                    if (!membership?.fixedStartDate) return false;
                    const selected = DateTime.fromFormat(
                      startDate,
                      LUXON_DATE_FORMAT,
                    ).setZone("local", { keepLocalTime: true });
                    return !selected.hasSame(DateTime.fromJSDate(d), "day");
                  },
                }}
                hidden={{
                  before: DateTime.now().toJSDate(),
                }}
                selected={DateTime.fromFormat(startDate, LUXON_DATE_FORMAT)
                  .setZone("local", { keepLocalTime: true })
                  .toJSDate()}
                handleDateSave={(newDate) => {
                  if (!newDate) {
                    return;
                  }
                  setStartDate(
                    DateTime.fromJSDate(newDate).toFormat(LUXON_DATE_FORMAT),
                  );
                }}
              />
            </LabeledForm>
          )}
        {!!invoice && promotionCode && (
          <div className="flex flex-row items-center justify-between">
            <div className="text-base text-gray-500">
              {t("pages.hostedPagesCheckout.promoCode")}
            </div>
            <div className="text-base font-bold text-gray-950 dark:text-gray-50">
              {promotionCode}
            </div>
          </div>
        )}
        {!invoice && (
          <LabeledForm label={t("pages.hostedPagesCheckout.promoCode")}>
            <div className="bg-gray-0 inline-flex h-10 flex-row items-start justify-start rounded-lg border border-gray-300 shadow-sm dark:border-gray-700 dark:bg-gray-800">
              <div className="bg-gray-0 flex h-full flex-1 items-center justify-start gap-2 overflow-hidden rounded-lg rounded-r-none border-r border-gray-300 dark:border-gray-700 dark:bg-gray-950">
                <input
                  placeholder={t("pages.hostedPagesCheckout.enterCode")}
                  className="bg-gray-0 h-full w-full px-3 py-2 text-gray-500 outline-none dark:bg-gray-950"
                  value={promotionCodeInput ?? ""}
                  onChange={(e) => {
                    setPromotionCodeInput(e.target.value);
                  }}
                  disabled={promotionCode !== undefined}
                />
              </div>
              <Button
                onClick={() => {
                  if (!promotionCode) {
                    setPromotionCode(promotionCodeInput);
                  } else {
                    setPromotionCode(undefined);
                    setPromotionCodeInput("");
                  }
                }}
                className="h-full min-h-0 !rounded-lg !rounded-l-none !border-none focus:!outline-none"
              >
                {isFetchingSummary ? (
                  <Spinner />
                ) : promotionCode === undefined ? (
                  t("common.apply")
                ) : (
                  t("common.remove")
                )}
              </Button>
            </div>
          </LabeledForm>
        )}
        <div className="flex w-full border-b-2 border-dashed border-gray-400 dark:border-gray-600" />
        <div className="flex w-full flex-col gap-2">
          {summary &&
            promotionCode &&
            summary?.validPromotionCode !== "YES" && (
              <div className="flex w-full flex-row justify-between font-semibold text-red-500">
                {t("pages.hostedPagesCheckout.promoCodeInvalid")}
              </div>
            )}
          {summary && cart && cart.type === "CREDIT_PACK" && (
            <div className="flex w-full flex-row justify-between">
              <div className="font-medium text-gray-500">
                {cart.payload.name}
              </div>
              <div className="font-medium text-gray-500">
                {formatCurrency(
                  summary.upfrontPriceDetails.totalAmountBeforeDiscount,
                  settings.defaultCurrency,
                )}
              </div>
            </div>
          )}

          {membershipSummary && cart && cart.type === "MEMBERSHIP" && (
            <>
              <div className="flex w-full flex-row justify-between">
                <div className="font-medium text-gray-500">
                  {cart.payload.name}
                </div>
                <div className="font-medium text-gray-500">
                  {formatCurrency(
                    cart.payload.type === "RECURRING"
                      ? membershipSummary.recurringMembershipAndAddonsProratedAmount
                      : membershipSummary.prepaidMembershipAmount,
                    settings.defaultCurrency,
                  )}
                </div>
              </div>
              {membershipSummary.addonsProratedList?.map((e) => (
                <div
                  className="flex w-full flex-row justify-between"
                  key={e.id}
                >
                  <div className="font-medium text-gray-500">{e.name}</div>
                  <div className="font-medium text-gray-500">
                    + {formatCurrency(e.amount, settings.defaultCurrency)}
                    {e.quantity > 1 && (
                      <>
                        {" x "}
                        {e.quantity}
                      </>
                    )}
                  </div>
                </div>
              ))}
            </>
          )}
          {summary &&
            cart &&
            cart.type === "PRODUCT" &&
            cart.payload.map((e) => (
              <div
                key={e.product.id}
                className="dark:text-gray-0 flex w-full flex-row justify-between text-gray-950"
              >
                <div>{e.product.name}</div>
                <div className="flex flex-row gap-1">
                  <div>{e.quantity} x</div>
                  <div className="font-medium">
                    {formatCurrency(e.product.price, settings.defaultCurrency)}
                  </div>
                </div>
              </div>
            ))}
          {!!summary &&
            "promotionUpfrontAmount" in summary &&
            summary.promotionUpfrontAmount !== undefined && (
              <div className="text-success-600 dark:text-success-400 flex w-full flex-row justify-between">
                <div className="font-medium">
                  {t("pages.hostedPagesCheckout.upfrontDiscount")}
                </div>
                <div className="font-medium">
                  {summary.promotionUpfrontDiscountType === "CURRENCY"
                    ? formatCurrency(
                        -summary.promotionUpfrontAmount,
                        settings.defaultCurrency,
                      )
                    : `-${summary.promotionUpfrontAmount}%`}
                </div>
              </div>
            )}
          {!!summary &&
            "promotionRecurringAmount" in summary &&
            "promotionRecurringDiscountType" in summary &&
            "promotionRecurringAmount" in summary &&
            summary.promotionRecurringAmount !== undefined && (
              <div className="text-success-600 dark:text-success-400 flex w-full flex-row justify-between">
                <div className="font-medium">
                  {t("pages.hostedPagesCheckout.recurringDiscount")}
                </div>
                <div className="font-medium">
                  {summary.promotionRecurringDiscountType === "CURRENCY"
                    ? formatCurrency(
                        -(summary.promotionRecurringAmount ?? 0),
                        settings.defaultCurrency,
                      )
                    : `-${summary.promotionRecurringAmount}%`}
                </div>
              </div>
            )}
          {summary && (
            <div className="dark:text-gray-0 flex w-full flex-row items-center justify-between text-gray-950">
              <div className="text-xl font-bold">
                {t("pages.hostedPagesCheckout.amountDue")}
              </div>
              <div className="text-xl font-bold">
                {formatCurrency(
                  summary.upfrontPriceDetails.totalAmountToPay,
                  settings.defaultCurrency,
                )}
              </div>
            </div>
          )}
        </div>
        {!invoice && (
          <div className="flex w-full flex-col gap-1">
            {cart?.type === "MEMBERSHIP" &&
              userMember?.subscriptions.some(
                membershipHelper.isCurrentSubscription,
              ) && (
                <ErrorMessage
                  text={t("pages.hostedPagesCheckout.alreadySubscribed")}
                />
              )}
            {showFixedStartDateInPastError && (
              <ErrorMessage
                text={t("pages.hostedPagesCheckout.fixedStartDateOnPast")}
              />
            )}

            {!arePaymentMethodsLoading &&
              !currentPaymentMethodId &&
              shouldCollectPaymentMethod && (
                <ErrorMessage
                  text={t("pages.hostedPagesCheckout.selectPaymentMethod")}
                />
              )}
            <Button
              intent="secondary"
              className="w-full"
              disabled={
                (!currentPaymentMethodId && shouldCollectPaymentMethod) ||
                !summary ||
                (cart?.type === "MEMBERSHIP" &&
                  userMember?.subscriptions.some(
                    membershipHelper.isCurrentSubscription,
                  )) ||
                showFixedStartDateInPastError
              }
              onClick={onPay}
            >
              {isFree
                ? t("common.getForFree")
                : t("common.payAmount", {
                    amount: formatCurrency(
                      summary?.upfrontPriceDetails.totalAmountToPay ?? 0,
                      settings.defaultCurrency,
                    ),
                  })}
            </Button>
          </div>
        )}
        {isQuickPurchase &&
          invoice?.status === "PAID" &&
          quickPurchaseFinishedButton}
      </div>
    </div>
  );
};
