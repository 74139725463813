import { cn } from "@gymflow/helpers";
import { CustomPaymentCategoryDTO } from "@gymflow/types";
import { Field, FieldProps, FormikProps } from "formik";

import {
  CustomPaymentCategorySelectInput,
  CustomPaymentCategorySelectInputProps,
} from "../base";
import { OptionType } from "../base/components/OptionsList/components/OptionItem";
import { ErrorMessage } from "../ErrorMessage";

type FormCustomPaymentCategorySelectInputProps<T> = {
  name: keyof T;
} & Omit<CustomPaymentCategorySelectInputProps, "value" | "onChange">;

export const FormCustomPaymentCategorySelectInput = <T,>({
  name,
  className,
  ...props
}: FormCustomPaymentCategorySelectInputProps<T>) => (
  <Field name={name}>
    {({
      field,
      form: { setFieldValue },
      meta: { touched, error },
    }: FieldProps<
      | OptionType<CustomPaymentCategoryDTO>
      | OptionType<CustomPaymentCategoryDTO>[],
      FormikProps<T>
    >) => {
      const isError = touched && error;
      return (
        <div className="flex flex-col gap-1.5">
          <CustomPaymentCategorySelectInput
            {...props}
            {...(props.isMulti
              ? {
                  isMulti: true,
                  value:
                    (field.value as OptionType<CustomPaymentCategoryDTO>[]) ??
                    [],
                }
              : {
                  isMulti: false,
                  value: field.value as
                    | OptionType<CustomPaymentCategoryDTO>
                    | undefined,
                })}
            onChange={(
              selected?:
                | OptionType<CustomPaymentCategoryDTO>
                | OptionType<CustomPaymentCategoryDTO>[],
            ) => setFieldValue(String(name), selected)}
            className={cn(className, {
              "outline-error-300 border-error-200": isError,
            })}
          />
          {isError && <ErrorMessage text={error} />}
        </div>
      );
    }}
  </Field>
);
