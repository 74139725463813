import {
  ApiListResponse,
  MembershipStatus,
  SessionPackDTO,
  SessionPackSummaryDTO,
} from "@gymflow/types";
import { AxiosInstance } from "axios";

export type CustomerCreditPackApiType = ReturnType<
  typeof customerCreditPackApi
>;

const customerCreditPackApi = (
  axiosInstance: AxiosInstance,
  clubId: number,
) => {
  const baseUrl = `club/${clubId}/customer/sessionpack`;

  return {
    getById(id: number) {
      return axiosInstance.get<SessionPackDTO>(`${baseUrl}/${id}`);
    },
    summary(params: { sessionPackId: number; promotionCode?: string }) {
      return axiosInstance.post<SessionPackSummaryDTO>(
        `${baseUrl}/summary`,
        params,
      );
    },
    getList(params: {
      status?: MembershipStatus;
      id?: number[];
      hasActivityCategory?: boolean;
      hasAppointableCategory?: boolean;
      page: number;
      size: number;
      sort?: string;
    }) {
      return axiosInstance.get<ApiListResponse<SessionPackDTO>>(`${baseUrl}`, {
        params,
      });
    },
  };
};

export default customerCreditPackApi;
