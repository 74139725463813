import { QueryKey } from "@tanstack/react-query";

export const creditPackAsMemberQueryKeys = {
  all: () => ["credit-pack-as-member"] as const,
  list: (filters: Readonly<{ [k: string]: any }>) =>
    [...creditPackAsMemberQueryKeys.all(), { filters }] as QueryKey,
  getById: (id?: number) =>
    [...creditPackAsMemberQueryKeys.all(), id] as QueryKey,
  summary: (params?: Readonly<{ [k: string]: any }>) =>
    [...creditPackAsMemberQueryKeys.all(), "summary", params] as QueryKey,
};
