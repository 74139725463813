import {
  AppointmentDTO,
  AppointmentPurchaseSummaryDTO,
  AppointmentSummaryAsMemberPostDTO,
  InvoiceDTO,
  MemberAppointmentPostDTO,
} from "@gymflow/types";
import { AxiosInstance } from "axios";

export type CustomerAppointmentApiType = ReturnType<
  typeof customerAppointmentApi
>;

const customerAppointmentApi = (
  axiosInstance: AxiosInstance,
  clubId: number,
) => {
  const clubPrefix = `club/${clubId}/`;

  return {
    create(params: MemberAppointmentPostDTO) {
      return axiosInstance.post<{
        appointment: AppointmentDTO;
        invoice: InvoiceDTO;
      }>(`${clubPrefix}customer/appointment`, params);
    },
    summary(params: AppointmentSummaryAsMemberPostDTO) {
      return axiosInstance.post<AppointmentPurchaseSummaryDTO>(
        `${clubPrefix}customer/appointment/summary`,
        params,
      );
    },
  };
};

export default customerAppointmentApi;
