import { DateTime } from "luxon";
import { createContext } from "react";

export const WeekPickerContext = createContext<{
  amountOfDays: number;
  setAmountOfDays: (amount: number) => void;
  currentDay: DateTime;
  setCurrentDay: (date: DateTime) => void;
  direction: 1 | -1;
}>({} as any);
