import {
  clubStaleTime,
  EventOccurrenceCreateVariables,
  EventOccurrenceEditVariables,
  useClub,
  useEventOccurrence,
  useEventOccurrenceCreate,
  useEventOccurrenceEdit,
} from "@gymflow/api";
import {
  DATE_TIME_FORMAT,
  PARAMETER_DATE_FORMAT_WITHOUT_TZ,
} from "@gymflow/common";
import { EventOccurrenceDTO } from "@gymflow/types";
import { STAFF_OR_FACILITY_HAS_NO_AVAILABLE_SLOTS_ERROR_CODES } from "apps/portal/src/constants/errorsFromBackend";
import { ToastContext } from "apps/portal/src/providers/ToastProvider/context";
import { isAxiosError } from "axios";
import moment from "moment-timezone";
import {
  createContext,
  ReactNode,
  useCallback,
  useContext,
  useState,
} from "react";

import { ModalContext, useClubSettings } from "../../../providers";
import useGymflowModels from "../../../store";
import { HostNotAvailableModal } from "../HostNotAvailableModal";
import { RecurrenceEditModal } from "./RecurrenceEditModal";
import { SideBarOccurrenceForm } from "./SideBarOccurrenceForm";

type MutationWithFallbackParams<TParams, TResult> = {
  mutationFn: (params: TParams) => Promise<TResult>;
  params: TParams;
};

interface SideBarOccurrenceFormProviderProps {
  children: ReactNode;
}

interface SideBarOccurrenceFormProviderContextType {
  openEdit: (occurrenceId: number) => void;
  openNew: (
    referenceDate: string,
    blankTime?: boolean,
    defaultHostId?: string | null,
    defaultFacilityId?: number | null,
  ) => void;
}

export const SideBarOccurrenceFormProviderContext =
  createContext<SideBarOccurrenceFormProviderContextType>({} as any);

export function SideBarOccurrenceFormProvider({
  children,
}: SideBarOccurrenceFormProviderProps) {
  const settings = useClubSettings();
  const clubId = settings.clubId;
  const { api } = useGymflowModels();

  const [isVisible, setIsVisible] = useState(false);
  const [occurrenceId, setOccurrenceId] = useState<number | null>(null);
  const [defaultDate, setDefaultDate] = useState<string | null>(null);
  const [defaultHostId, setDefaultHostId] = useState<string | null>(null);
  const [defaultFacilityId, setDefaultFacilityId] = useState<number | null>(
    null,
  );
  const [isSaving, setIsSaving] = useState(false);
  const [isTimeBlanked, setIsTimeBlanked] = useState(false);

  const { data: club } = useClub({ api, clubId }, { staleTime: clubStaleTime });
  const { data: existingOccurrence, isFetching } = useEventOccurrence({
    api,
    eventId: occurrenceId,
  });

  const { notifyDanger } = useContext(ToastContext);
  const errorHandler = useCallback(
    (e: any) => {
      notifyDanger(e);
    },
    [notifyDanger],
  );
  const createOccurrenceMutation = useEventOccurrenceCreate({
    api,
    tz: club?.timezone as string,
  });
  const editOccurrenceMutation = useEventOccurrenceEdit({
    api,
    tz: club?.timezone as string,
  });

  const modal = useContext(ModalContext);

  const mutationWithFallback = useCallback(
    async <TParams, TResult>({
      mutationFn,
      params,
    }: MutationWithFallbackParams<TParams, TResult>): Promise<TResult> => {
      try {
        const response = await mutationFn(params);
        setIsSaving(false);
        setIsVisible(false);
        return response;
      } catch (e) {
        if (
          isAxiosError(e) &&
          STAFF_OR_FACILITY_HAS_NO_AVAILABLE_SLOTS_ERROR_CODES.includes(
            e.response?.data.error_code,
          )
        ) {
          return new Promise<TResult>((resolve, reject) => {
            modal.setModal(
              <HostNotAvailableModal
                modalType="CLASS"
                onConfirm={async () => {
                  try {
                    const fallbackResponse = await mutationFn({
                      ...params,
                      ignoreAvailabilityValidation: true,
                    });
                    setIsSaving(false);
                    setIsVisible(false);
                    resolve(fallbackResponse);
                    modal.hide();
                  } catch (fallbackError) {
                    setIsSaving(false);
                    setIsVisible(false);
                    modal.hide();
                    errorHandler(fallbackError);
                    reject(fallbackError);
                  }
                }}
                onCancel={() => {
                  setIsSaving(false);
                  modal.hide();
                  reject();
                }}
              />,
            );
          });
        } else {
          setIsSaving(false);
          setIsVisible(false);
          errorHandler(e);
          throw e;
        }
      }
    },
    [errorHandler, modal],
  );

  return (
    <SideBarOccurrenceFormProviderContext.Provider
      value={{
        openEdit: (occurrenceId) => {
          setIsTimeBlanked(false);
          setIsVisible(true);
          setOccurrenceId(occurrenceId);
        },
        openNew: (
          referenceDate,
          blankTime = false,
          defaultHostId = null,
          defaultFacilityId = null,
        ) => {
          setIsTimeBlanked(blankTime);
          setDefaultDate(referenceDate);
          setIsVisible(true);
          setDefaultHostId(defaultHostId);
          setDefaultFacilityId(defaultFacilityId);
        },
      }}
    >
      <SideBarOccurrenceForm
        isOpen={isVisible}
        onClose={() => {
          setIsVisible(false);
          setOccurrenceId(null);
          setDefaultHostId(null);
        }}
        defaultStartDate={
          existingOccurrence
            ? moment(
                existingOccurrence.startDate,
                PARAMETER_DATE_FORMAT_WITHOUT_TZ,
              ).format(DATE_TIME_FORMAT)
            : defaultDate
        }
        defaultHostId={defaultHostId}
        defaultFacilityId={defaultFacilityId}
        value={existingOccurrence as EventOccurrenceDTO | null}
        blankTime={isTimeBlanked}
        isLoading={isFetching || isSaving}
        // Refactor it; More info in the SideBarOccurrenceForm;
        // Maybe move this logic to the SideBarOccurrenceForm component.
        onChange={async (newValues) => {
          setIsSaving(true);
          let newOccurrenceId;
          if (occurrenceId) {
            if (existingOccurrence?.event.isRecurring) {
              modal.setModal(
                <RecurrenceEditModal
                  onConfirm={async (updateAll) => {
                    modal.hide();
                    await mutationWithFallback({
                      mutationFn: editOccurrenceMutation.mutateAsync,
                      params: {
                        eventId: occurrenceId,
                        patchedFields: {
                          ...newValues,
                        },
                        isUpdateAll: updateAll,
                      },
                    })
                      .then(() => {
                        // nothing
                      })
                      .catch(() => {
                        // nothing
                      });
                  }}
                  onCancel={() => {
                    setIsSaving(false);
                    modal.hide();
                  }}
                />,
              );
              return;
            } else if (newValues?.["isRecurring"]) {
              await mutationWithFallback<
                EventOccurrenceEditVariables,
                EventOccurrenceDTO
              >({
                mutationFn: editOccurrenceMutation.mutateAsync,
                params: {
                  eventId: occurrenceId,
                  patchedFields: newValues,
                  isUpdateAll: true,
                },
              })
                .then(({ id }) => {
                  newOccurrenceId = id;
                })
                .catch(() => {
                  // nothing
                });
            } else {
              await mutationWithFallback<
                EventOccurrenceEditVariables,
                EventOccurrenceDTO
              >({
                mutationFn: editOccurrenceMutation.mutateAsync,
                params: {
                  eventId: occurrenceId,
                  patchedFields: newValues,
                },
              })
                .then(({ id }) => {
                  newOccurrenceId = id;
                })
                .catch(() => {
                  // nothing
                });
            }
          } else {
            await mutationWithFallback<
              EventOccurrenceCreateVariables,
              EventOccurrenceDTO
            >({
              mutationFn: createOccurrenceMutation.mutateAsync,
              params: {
                fields: newValues,
              },
            })
              .then(({ id }) => {
                newOccurrenceId = id;
              })
              .catch(() => {
                // nothing
              });
          }
          // TODO Review this newOccurrenceId
          console.info(newOccurrenceId);
        }}
      />
      {children}
    </SideBarOccurrenceFormProviderContext.Provider>
  );
}
