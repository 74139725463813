import { ApiType } from "@gymflow/common/lib/api/ApiType";
import { ActivityDTO, ApiListResponse } from "@gymflow/types";
import { useQuery } from "@tanstack/react-query";

import { activityAsPublicQueryKeys } from "./activityAsPublicQueryKeys";

/*** @deprecated - should be removed once Timetable.jsx is removed */
export async function activityListAsPublicQueryFn({
  filter,
  api,
}: {
  filter?: Parameters<ApiType["public"]["activityApi"]["find"]>[0];
  api: ApiType;
}) {
  const result = await api.public.activityApi.find({ ...filter });
  return result.data as ApiListResponse<ActivityDTO>;
}

export function useQueryActivityListAsPublic({
  api,
  opts,
}: {
  api: ApiType;
  opts?: Parameters<ApiType["public"]["activityApi"]["find"]>[0];
},
{
  enabled,
}: {
  enabled?: boolean;
} = {
  enabled: true,
},) {
  return useQuery({
    queryKey: activityAsPublicQueryKeys.list(opts),
    queryFn: async () => (await api.public.activityApi.find({ ...opts })).data,
    enabled
  });
}
