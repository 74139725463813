import { useAutoAnimate } from "@formkit/auto-animate/react";
import { cn } from "@gymflow/helpers";
import { usePortalRoutes } from "apps/portal/src/hooks";
import { useAuthenticatedUser } from "apps/portal/src/providers";
import { RouteFeature } from "apps/portal/src/routes";
import mapTorTArrayToTArray from "libs/helpers/src/lib/mapTorTArrayToTArray";
import { pick } from "lodash";
import qs from "qs";
import { useState } from "react";
import { useTranslation } from "react-i18next";
import { useHistory, useParams } from "react-router";

import { BarChartIcon, DotPointsIcon } from "../../atoms";
import { ToggleModeButton } from "../../pages/Leads/ToggleModeButton";
import HostedPagesProfileAvatar from "../components/HostedPagesProfileAvatar";
import {
  HostedPagesClassesFilterPopover,
  HostedPagesClassesFilterPopoverProps,
} from "./components/HostedPagesClassesFilterPopover";
import useTimetableQueryParamsStore, {
  getTimetableQueryParams,
} from "./components/useTimetableQueryParamsStore";
import WeekPicker from "./components/WeekPicker/WeekPicker";
import WeekPickerContextProvider from "./components/WeekPicker/WeekPickerContextProvider";
import HostedPagesClassesDayCalendar from "./HostedPagesClassesDayCalendar";
import HostedPagesClassesDayList from "./HostedPagesClassesDayList";

export type HostedPagesClassesProps = {};
export type HostedClassesUrlFiltersType = {
  activityCategory?: string | string[];
  activity?: string | string[];
  host?: string | string[];
  facility?: string | string[];
  currentDate?: string;
};
const HostedPagesClasses = ({}: HostedPagesClassesProps) => {
  useTimetableQueryParamsStore();

  const history = useHistory();
  const { isEmbed, createSiteOrEmbedLink } = usePortalRoutes();
  const auth = useAuthenticatedUser();
  const { t } = useTranslation();
  // pick is used because some links will have custom tracking query params like fbclid
  const urlFilters = pick(
    qs.parse(window.location.search, {
      ignoreQueryPrefix: true,
    }) as HostedClassesUrlFiltersType,
    ["activity", "activityCategory", "currentDate", "facility", "host"],
  );
  const hasCustomFilters =
    Object.keys(urlFilters).length > 1 ||
    (Object.keys(urlFilters).length === 1 && !urlFilters.currentDate);
  const [filter, setFilter] = useState<
    HostedPagesClassesFilterPopoverProps["filter"]
  >({
    activityCategoryId:
      mapTorTArrayToTArray(urlFilters?.activityCategory)?.map(Number) ?? [],
    activityId: mapTorTArrayToTArray(urlFilters?.activity)?.map(Number) ?? [],
    eventHostId: mapTorTArrayToTArray(urlFilters?.host) ?? [],
    facilityId: mapTorTArrayToTArray(urlFilters?.facility)?.map(Number) ?? [],
  });
  const { viewType } = useParams<{ viewType: "calendar" | "list" }>();

  const [parent] = useAutoAnimate();
  return (
    <div
      ref={parent}
      className="flex h-full max-h-full flex-col bg-gray-50 dark:bg-gray-800"
    >
      <WeekPickerContextProvider>
        <div className="track-height bg-gray-0 flex flex-col gap-4 border-b border-gray-300 p-4 dark:border-gray-700 dark:bg-gray-950 lg:px-28">
          <div className="flex flex-row flex-wrap items-center justify-between gap-2">
            <div className="flex flex-row items-center gap-4">
              {isEmbed && auth.id ? (
                <HostedPagesProfileAvatar />
              ) : (
                <div className="dark:text-gray-0 text-xl font-medium text-gray-950">
                  {t("page.hostedPagesClasses.title")}
                </div>
              )}
            </div>
            <div className="flex flex-row flex-wrap gap-2">
              <WeekPicker
                className={cn("max-lg:hidden transition-opacity", {
                  "opacity-0 max-lg:hidden": viewType === "list",
                })}
              />
              <ToggleModeButton
                className="w-fit"
                value={viewType === "list" ? "RIGHT" : "LEFT"}
                onChange={(newValue) =>
                  history.push(
                    createSiteOrEmbedLink(
                      `${RouteFeature.SiteTimetableType.replace(
                        ":viewType",
                        newValue === "RIGHT" ? "list" : "calendar",
                      )}?${getTimetableQueryParams()}`,
                    ),
                  )
                }
                left={
                  <div className="flex h-11 w-11 items-center justify-center">
                    <BarChartIcon pathClassName="stroke-gray-700 dark:stroke-gray-300" />
                  </div>
                }
                right={
                  <div className="flex h-11 w-11 items-center justify-center">
                    <DotPointsIcon pathClassName="stroke-gray-700 dark:stroke-gray-300" />
                  </div>
                }
              />
              {!hasCustomFilters && (
                <HostedPagesClassesFilterPopover
                  filter={filter}
                  onChange={setFilter}
                />
              )}
            </div>
            <WeekPicker
              className={cn("lg:hidden transition-opacity", {
                "opacity-0 max-lg:hidden": viewType === "list",
              })}
            />
          </div>
        </div>
        <div className="relative flex h-full w-full">
          <div className="absolute inset-0">
            {viewType === "calendar" && (
              <HostedPagesClassesDayCalendar
                setFilter={setFilter}
                filter={filter}
              />
            )}
            {viewType === "list" && (
              <HostedPagesClassesDayList
                setFilter={setFilter}
                filter={filter}
              />
            )}
          </div>
        </div>
      </WeekPickerContextProvider>
    </div>
  );
};

export default HostedPagesClasses;
