import { useAutoAnimate } from "@formkit/auto-animate/react";
import {
  useMemberInvoice,
  useQueryGetInvoiceByInvoiceNumber,
} from "@gymflow/api";
import { formatCurrency } from "@gymflow/helpers";
import { useClubSettings } from "apps/portal/src/providers";
import { ToastContext } from "apps/portal/src/providers/ToastProvider/context";
import useGymflowModels from "apps/portal/src/store";
import { isAxiosError } from "axios";
import { FormikProvider, useFormik } from "formik";
import { useCallback, useContext } from "react";
import { useTranslation } from "react-i18next";

import {
  Button,
  ChangeCalculator,
  CloseIcon,
  FormCustomPaymentCategorySelectInput,
  FormPaymentMethodPicker,
  FormTabs,
  SlideSideBar,
  Spinner,
} from "../../../atoms";
import DashedIcon from "../../../atoms/icons/DashedIcon";
import {
  collectPaymentManuallyFormSchema,
  INITIAL_VALUES_COLLECT_PAYMENT_MANUALLY_FORM,
  TABS,
} from "./constants";
import {
  CollectPaymentManuallyFormValues,
  TabsCollectManuallyType,
  UserMemberPaymentsCollectManuallySidebarProps,
} from "./types";

const UserMemberPaymentsCollectManuallySidebar = ({
  onClose,
  isVisible,
  userMember,
  invoiceNumber,
}: UserMemberPaymentsCollectManuallySidebarProps) => {
  const { t } = useTranslation();
  const { defaultCurrency } = useClubSettings();
  const { api } = useGymflowModels();
  const [parent] = useAutoAnimate();
  const { notifyDanger } = useContext(ToastContext);

  const { data: invoice, isFetching } = useQueryGetInvoiceByInvoiceNumber(
    {
      api,
      invoiceNumber,
    },
    { enabled: isVisible && !!invoiceNumber },
  );
  const {
    collectPastDueByPaymentMethodIdMutation,
    markCustomPaymentCollectedByCustomPaymentCategoryIdMutation,
  } = useMemberInvoice({ api, memberId: userMember.id });

  const formik = useFormik<CollectPaymentManuallyFormValues>({
    initialValues: INITIAL_VALUES_COLLECT_PAYMENT_MANUALLY_FORM,
    validationSchema: collectPaymentManuallyFormSchema,
    onSubmit: async ({
      activeTabId,
      inPersonPaymentMethod,
      paymentMethodId,
    }: CollectPaymentManuallyFormValues) => {
      try {
        if (activeTabId === "ONLINE" && paymentMethodId) {
          await collectPastDueByPaymentMethodIdMutation.mutateAsync({
            invoiceNumber,
            paymentMethodId,
          });
          onCloseSidebar();
        } else if (
          activeTabId === "IN_PERSON" &&
          inPersonPaymentMethod?.value.id
        ) {
          await markCustomPaymentCollectedByCustomPaymentCategoryIdMutation.mutateAsync(
            {
              invoiceNumber,
              customPaymentCategoryId: inPersonPaymentMethod.value.id,
            },
          );
          onCloseSidebar();
        }
      } catch (error) {
        if (isAxiosError(error)) {
          notifyDanger(error);
        }
      }
    },
  });

  const onCloseSidebar = useCallback(() => {
    onClose();
    formik.resetForm();
  }, [formik, onClose]);

  const { inPersonPaymentMethod, activeTabId } = formik.values;

  const dueAmount = invoice?.dueAmount ?? 0;

  return (
    <SlideSideBar
      isOpen={isVisible}
      hide={() => onCloseSidebar()}
      className="!w-full lg:!w-1/2"
    >
      <FormikProvider value={formik}>
        <div className="flex h-full flex-col overflow-hidden">
          <div className="flex-1 overflow-y-auto">
            <div className="flex flex-col justify-between border-b border-b-gray-300 p-6 pb-5">
              <div className="mb-1 flex flex-row items-center justify-between">
                <div className="text-lg font-semibold text-gray-900">
                  {t(
                    "page.userMember.tab.payments.sidebar.collectManually.title",
                  )}
                </div>
                <div
                  className="cursor-pointer"
                  onClick={() => onCloseSidebar()}
                >
                  <CloseIcon className="h-6 w-6" />
                </div>
              </div>
            </div>
            {isFetching ? (
              <div className="absolute inset-0 flex items-center justify-center">
                <Spinner className="h-12 w-12" />
              </div>
            ) : (
              <div className="flex flex-col gap-6 p-6 pt-8">
                <div className="flex flex-col gap-5">
                  <FormTabs<
                    CollectPaymentManuallyFormValues,
                    TabsCollectManuallyType
                  >
                    name="activeTabId"
                    tabs={TABS}
                    className="justify-evenly gap-3 border-b-gray-300 pb-5"
                    tabClassName="py-2.5 px-3 rounded-xl max-w-none w-full items-center justify-center bg-gray-0 border border-gray-200"
                    activeTabClassName="bg-secondary-25 border border-secondary-700"
                    activeTabLabelClassName="text-secondary-700"
                  />
                  <div ref={parent}>
                    {activeTabId === "ONLINE" ? (
                      <FormPaymentMethodPicker<CollectPaymentManuallyFormValues>
                        name="paymentMethodId"
                        userMember={userMember}
                        customLabel={t(
                          "page.userMember.tab.payments.sidebar.collectManually.formPaymentMethodPicker.customLabel",
                        )}
                      />
                    ) : (
                      <FormCustomPaymentCategorySelectInput<CollectPaymentManuallyFormValues>
                        name="inPersonPaymentMethod"
                        label={t(
                          "page.userMember.tab.payments.sidebar.collectManually.formCustomPaymentCategorySelectInput.label",
                        )}
                        labelClassName="text-base"
                      />
                    )}
                  </div>
                </div>
                <DashedIcon />
                <div className="flex flex-col gap-2">
                  <div className="flex justify-between">
                    <div className="text-xl font-bold text-gray-950">
                      {t(
                        "page.userMember.tab.payments.sidebar.collectManually.amountDue",
                      )}
                    </div>
                    <div className="text-xl font-bold text-gray-950">
                      {formatCurrency(dueAmount, defaultCurrency)}
                    </div>
                  </div>
                </div>
                {inPersonPaymentMethod?.value.name === "Cash" &&
                  activeTabId === "IN_PERSON" && (
                    <ChangeCalculator price={dueAmount} />
                  )}
              </div>
            )}
          </div>
          <div className="flex items-center justify-between gap-3 border-t border-t-gray-300 p-6">
            <Button
              intent="default"
              className="w-full"
              onClick={() => onCloseSidebar()}
            >
              {t(
                "page.userMember.tab.payments.sidebar.collectManually.button.cancel",
              )}
            </Button>
            <Button
              intent="secondary"
              className="w-full"
              disabled={isFetching}
              onClick={async () => await formik.submitForm()}
            >
              {t(
                "page.userMember.tab.payments.sidebar.collectManually.button.takePayment",
              )}
            </Button>
          </div>
        </div>
      </FormikProvider>
    </SlideSideBar>
  );
};

export default UserMemberPaymentsCollectManuallySidebar;
