import { useMemberEmailExistsAsPublic } from "@gymflow/api";
import { cn } from "@gymflow/helpers";
import { usePortalRoutes } from "apps/portal/src/hooks";
import classNames from "classnames";
import { useEffect, useState } from "react";
import { z } from "zod";

import { Button, TextInput } from "../../../components/atoms";
import useGymflowModels from "../../../store";

export function EmailExistsForm({
  email: initialEmail,
  onSubmit,
}: {
  email?: string;
  onSubmit: (newEmail: string, isAlreadyRegistered: boolean) => void;
}) {
  const { isEmbed } = usePortalRoutes();
  const { api } = useGymflowModels();
  const [email, setEmail] = useState(initialEmail || "");
  const [error, setError] = useState("");

  const validEmail = z
    .string()
    .trim()
    .toLowerCase()
    .email()
    .safeParse(email).success;
  const { data } = useMemberEmailExistsAsPublic(
    { api, email: email.trim().toLowerCase() },
    { enabled: validEmail },
  );
  const [delayedInputShow, setDelayedInputShow] = useState(false);
  useEffect(() => {
    setTimeout(() => {
      setDelayedInputShow(true);
    }, 200);
  }, []);
  return (
    <form
      onSubmit={(e) => {
        e.preventDefault();
      }}
      className={cn(
        "flex h-screen w-full max-w-[25rem] flex-col justify-center gap-8 overflow-y-auto px-1 md:h-auto ",
        {
          "justify-start": isEmbed,
        },
      )}
    >
      <div className="flex flex-col items-center gap-6">
        <div className="text-center text-2xl font-bold text-gray-900 dark:text-gray-50">
          Sign In or Sign Up
        </div>
        <div className="text-center text-sm font-medium text-gray-600 dark:text-gray-400">
          Enter your email to continue
        </div>
      </div>
      <div className="flex flex-col gap-6">
        <div className="flex flex-col gap-2">
          <div className="text-sm text-gray-600 dark:text-gray-400">Email:</div>
          {delayedInputShow && (
            <TextInput
              autoFocus
              value={email}
              onChange={({ currentTarget: { value } }) => {
                setEmail(value);
              }}
            />
          )}
          <div className={classNames("text-error-500", { invisible: !error })}>
            {error}
          </div>
        </div>
        <div className="pb-8">
          <Button
            intent="primary"
            type="submit"
            className="mt-2 w-full"
            onClick={() => {
              if (validEmail) {
                setError("");
                onSubmit(email.trim().toLowerCase(), data || false);
              } else {
                setError("Invalid email.");
              }
            }}
          >
            Continue
          </Button>
        </div>
      </div>
    </form>
  );
}
