import { ApiType } from "@gymflow/common/lib/api/ApiType";
import { useMutation, useQueryClient } from "@tanstack/react-query";

import { ruleQueryKeys } from "./ruleQueryKeys";

export function useMutationRuleGroupNew({ api }: { api: ApiType }) {
  const queryClient = useQueryClient();

  return useMutation({
    mutationFn: ({
      fields,
    }: {
      fields: Parameters<ApiType["ruleApi"]["newRuleGroup"]>[0];
    }) => api.ruleApi.newRuleGroup(fields),
    onSuccess: () => {
      queryClient.invalidateQueries({ queryKey: ruleQueryKeys.all() });
    },
  });
}
