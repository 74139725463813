import { ApiType } from "@gymflow/common/lib/api/ApiType";
import { useMutation, useQueryClient } from "@tanstack/react-query";

import { ruleQueryKeys } from "./ruleQueryKeys";

export function useMutationRuleValuesEdit({ api }: { api: ApiType }) {
  const queryClient = useQueryClient();
  return useMutation({
    mutationFn: ({
      clubId,
      patchedFields,
    }: {
      clubId: number;
      patchedFields: { [k: string]: any };
    }) => api.ruleApi.updateValues(clubId, patchedFields),
    onSuccess: () => {
      queryClient.invalidateQueries({ queryKey: ruleQueryKeys.all() });
    },
  });
}
