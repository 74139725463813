import { useClubSettings } from "apps/portal/src/providers";
import { DateTime } from "luxon";
import qs from "qs";
import { ReactNode, useEffect, useState } from "react";
import { useHistory } from "react-router";

import { HostedClassesUrlFiltersType } from "../../HostedPagesClasses";
import { WeekPickerContext } from "./WeekPickerContext";

export default function WeekPickerContextProvider({
  children,
}: {
  children: ReactNode;
}) {
  const settings = useClubSettings();
  const urlFilters = qs.parse(window.location.search, {
    ignoreQueryPrefix: true,
  }) as HostedClassesUrlFiltersType;
  const [direction, setDirection] = useState<1 | -1>(1);
  const history = useHistory();
  const [amountOfDays, setAmountOfDays] = useState(7);
  const [currentDay, _setCurrentDay] = useState(
    urlFilters.currentDate
      ? DateTime.fromISO(urlFilters.currentDate, { zone: settings.timezone })
      : DateTime.now().setZone(settings.timezone).startOf("day"),
  );
  const setCurrentDay = (newDay: DateTime) => {
    setDirection(newDay.diff(currentDay, "days").days > 0 ? 1 : -1);
    _setCurrentDay(newDay);
  };
  useEffect(() => {
    const currentDateIso = currentDay.setZone(settings.timezone).toISODate();
    if (urlFilters.currentDate !== currentDateIso) {
      history.replace({
        search: qs.stringify(
          {
            ...urlFilters,
            currentDate: currentDateIso,
          },
          { skipNulls: true },
        ),
      });
    }
  }, [currentDay, history, settings.timezone, urlFilters]);
  return (
    <WeekPickerContext.Provider
      value={{
        amountOfDays,
        setAmountOfDays,
        currentDay,
        setCurrentDay,
        direction,
      }}
    >
      {children}
    </WeekPickerContext.Provider>
  );
}
