import { ApiType } from "@gymflow/common/lib/api/ApiType";
import { useQuery } from "@tanstack/react-query";

import { appointmentAsMemberQueryKeys } from "./appointmentAsMemberQueryKeys";

export function useQueryAppointmentSummaryAsMember({
  api,
  opts,
}: {
  api: ApiType;
  opts: Parameters<ApiType["customerAppointmentApi"]["summary"]>[0];
}) {
  return useQuery({
    queryKey: appointmentAsMemberQueryKeys.summaryNew(opts),
    queryFn: async () => (await api.customerAppointmentApi.summary(opts)).data,
  });
}
