import { cn } from "@gymflow/helpers";

export function AppStoreButton({ className }: { className?: string }) {
  return (
    <a
      href="https://apps.apple.com/us/app/gymflow-members/id1631161012"
      target="_blank"
      rel="noreferrer"
      className={cn("flex items-center justify-center", className)}
    >
      <svg
        xmlns="http://www.w3.org/2000/svg"
        width="124"
        height="46"
        viewBox="0 0 124 46"
        fill="none"
      >
        <rect
          x="0.755432"
          y="0.5"
          width="121.902"
          height="45"
          rx="8.5"
          fill="white"
          stroke="#090909"
        />
        <path
          d="M28.968 23.6671C28.9918 21.8081 29.9863 20.0491 31.5639 19.0754C30.5687 17.6484 28.9017 16.7437 27.1673 16.6892C25.3175 16.4943 23.5242 17.8005 22.5815 17.8005C21.6207 17.8005 20.1693 16.7086 18.6065 16.7409C16.5694 16.8069 14.6703 17.9696 13.6792 19.7576C11.5487 23.4605 13.1378 28.9026 15.1786 31.8958C16.1997 33.3615 17.393 34.9987 18.9545 34.9407C20.4824 34.8771 21.0531 33.9625 22.8975 33.9625C24.7247 33.9625 25.2601 34.9407 26.8533 34.9038C28.4928 34.877 29.5259 33.4316 30.5111 31.952C31.2447 30.9076 31.8092 29.7534 32.1838 28.532C30.257 27.7139 28.9702 25.7673 28.968 23.6671Z"
          fill="#090909"
        />
        <path
          d="M25.9588 14.7208C26.8528 13.6434 27.2932 12.2586 27.1865 10.8605C25.8208 11.0045 24.5592 11.6598 23.6531 12.6959C22.7671 13.7081 22.3061 15.0685 22.3932 16.4132C23.7595 16.4273 25.1027 15.7898 25.9588 14.7208Z"
          fill="#090909"
        />
        <path
          d="M83.4661 17.7621V20.0148H82.0596V21.4921H83.4661V26.5117C83.4661 28.2257 84.2385 28.9113 86.1816 28.9113C86.5231 28.9113 86.8483 28.8705 87.1328 28.8215V27.3605C86.8889 27.385 86.7344 27.4014 86.4662 27.4014C85.5962 27.4014 85.2141 26.9933 85.2141 26.0628V21.4921H87.1328V20.0148H85.2141V17.7621H83.4661Z"
          fill="#090909"
        />
        <path
          d="M92.0828 29.0337C94.6682 29.0337 96.2536 27.2952 96.2536 24.4141C96.2536 21.5493 94.6601 19.8026 92.0828 19.8026C89.4974 19.8026 87.9039 21.5493 87.9039 24.4141C87.9039 27.2952 89.4893 29.0337 92.0828 29.0337ZM92.0828 27.4748C90.5625 27.4748 89.7088 26.3566 89.7088 24.4141C89.7088 22.4879 90.5625 21.3615 92.0828 21.3615C93.595 21.3615 94.4568 22.4879 94.4568 24.4141C94.4568 26.3485 93.595 27.4748 92.0828 27.4748Z"
          fill="#090909"
        />
        <path
          d="M97.61 28.8623H99.358V23.6142C99.358 22.3655 100.293 21.5248 101.618 21.5248C101.927 21.5248 102.447 21.5819 102.594 21.6309V19.9006C102.407 19.8516 102.073 19.8271 101.813 19.8271C100.659 19.8271 99.6751 20.4637 99.4231 21.337H99.293V19.974H97.61V28.8623Z"
          fill="#090909"
        />
        <path
          d="M106.934 21.2962C108.227 21.2962 109.072 22.2022 109.113 23.5979H104.641C104.739 22.2104 105.641 21.2962 106.934 21.2962ZM109.105 26.4627C108.78 27.1565 108.056 27.5401 106.999 27.5401C105.601 27.5401 104.698 26.5525 104.641 24.9936V24.8956H110.893V24.2835C110.893 21.4921 109.406 19.8026 106.942 19.8026C104.446 19.8026 102.861 21.6064 102.861 24.4467C102.861 27.2871 104.414 29.0337 106.95 29.0337C108.975 29.0337 110.389 28.0543 110.788 26.4627H109.105Z"
          fill="#090909"
        />
        <path
          d="M72.0031 25.58C72.1379 27.7626 73.9496 29.1584 76.6462 29.1584C79.5281 29.1584 81.3314 27.6949 81.3314 25.3601C81.3314 23.5243 80.3034 22.5092 77.8091 21.9255L76.4692 21.5956C74.885 21.2233 74.2446 20.7242 74.2446 19.8529C74.2446 18.7531 75.2389 18.0341 76.7304 18.0341C78.1461 18.0341 79.1236 18.7362 79.3006 19.8613H81.1376C81.0281 17.8057 79.2247 16.3591 76.7557 16.3591C74.1013 16.3591 72.3317 17.8057 72.3317 19.9798C72.3317 21.7732 73.3345 22.8391 75.5338 23.3551L77.1012 23.7358C78.7107 24.1165 79.4186 24.6748 79.4186 25.6054C79.4186 26.6882 78.3062 27.475 76.7894 27.475C75.1631 27.475 74.0339 26.739 73.8738 25.58H72.0031Z"
          fill="#090909"
        />
        <path
          d="M53.8957 19.8271C52.6924 19.8271 51.6518 20.4311 51.1152 21.4432H50.9851V19.974H49.3021V31.8169H51.0501V27.5156H51.1883C51.6518 28.4542 52.6518 29.0092 53.9119 29.0092C56.1477 29.0092 57.5705 27.2381 57.5705 24.4141C57.5705 21.5901 56.1477 19.8271 53.8957 19.8271ZM53.3997 27.434C51.9363 27.434 51.0176 26.275 51.0176 24.4223C51.0176 22.5613 51.9363 21.4023 53.4079 21.4023C54.8876 21.4023 55.7738 22.5369 55.7738 24.4141C55.7738 26.2995 54.8876 27.434 53.3997 27.434Z"
          fill="#090909"
        />
        <path
          d="M63.687 19.8271C62.4838 19.8271 61.4431 20.4311 60.9065 21.4432H60.7764V19.974H59.0935V31.8169H60.8415V27.5156H60.9797C61.4431 28.4542 62.4431 29.0092 63.7033 29.0092C65.9391 29.0092 67.3619 27.2381 67.3619 24.4141C67.3619 21.5901 65.9391 19.8271 63.687 19.8271ZM63.1911 27.434C61.7277 27.434 60.8089 26.275 60.8089 24.4223C60.8089 22.5613 61.7277 21.4023 63.1992 21.4023C64.6789 21.4023 65.5651 22.5369 65.5651 24.4141C65.5651 26.2995 64.6789 27.434 63.1911 27.434Z"
          fill="#090909"
        />
        <path
          d="M46.1659 28.8623H48.1715L43.7812 16.6552H41.7504L37.36 28.8623H39.2982L40.4189 25.6223H45.0536L46.1659 28.8623ZM42.6689 18.8716H42.8121L44.5733 24.0319H40.8993L42.6689 18.8716Z"
          fill="#090909"
        />
      </svg>
    </a>
  );
}
