/* eslint-disable react/prop-types */
import { useMemberSubscription } from "@gymflow/api";
import {
  DATE_FORMAT,
  PARAMETER_DATE_ONLY_FORMAT,
  useParseErrors,
} from "@gymflow/common";
import { currencies } from "@gymflow/helpers";
import { UserMemberSubscriptionBeanWithMembership } from "@gymflow/types";
import { ModalContext, useClubSettings } from "apps/portal/src/providers";
import useGymflowModels from "apps/portal/src/store";
import { useFormik } from "formik";
import { LabeledFormikInput } from "libs/common/src/lib/components/molecules/FormikInput";
import { cn } from "libs/common/src/lib/helpers/cn";
import moment, { Moment } from "moment-timezone";
import React, { useCallback, useContext, useState } from "react";
import Datetime from "react-datetime";
import { z } from "zod";
import { toFormikValidationSchema } from "zod-formik-adapter";

import { ConfirmModal } from "../../../templates";
import { NotPrimaryMembershipWarning } from "../NotPrimaryMembershipWarning";

export interface UpdateBillingMembershipModalProps {
  memberId: string;
  membership: UserMemberSubscriptionBeanWithMembership;
}

export const UpdateBillingMembershipModal: React.FC<
  UpdateBillingMembershipModalProps
> = ({ memberId, membership }) => {
  const { timezone, clubId, defaultCurrency } = useClubSettings();
  const { api } = useGymflowModels();

  const parseError = useParseErrors();
  const { changeBillingDateMutation, changeSubscriptionPriceMutation } =
    useMemberSubscription(
      { api, tz: timezone },
      {
        onError: (error) => {
          parseError((error as any)?.response);
        },
      },
    );
  const { hide } = useContext(ModalContext);
  const [billingDate, setBillingDate] = useState<string>(
    moment(membership.endDate).add(1, "second").format(DATE_FORMAT),
  );

  const isValidDate = useCallback(
    (date: Moment) => date.isAfter(moment().tz(timezone)),
    [timezone],
  );
  
  const formik = useFormik<{ newSubscriptionPrice: number }>({
    initialValues: {
      newSubscriptionPrice: membership.price,
    },
    validationSchema: toFormikValidationSchema(
      z.object({
        newSubscriptionPrice: z
          .number({
            coerce: true,
            invalid_type_error: "Subscription price must be a number",
            required_error: "Subscription price is required",
          })
          .min(0, "Subscription price must be greater than 0"),
      }),
    ),
    onSubmit: () => {},
  });
  return (
    <ConfirmModal
      isConfirmDisabled={!formik.isValid}
      onConfirm={async () => {
        const newSubscriptionPrice = Number(formik.values.newSubscriptionPrice);
        if (membership.price !== newSubscriptionPrice) {
          await changeSubscriptionPriceMutation.mutateAsync({
            subscriptionId: membership.id,
            memberId: memberId,
            clubId: clubId,
            newSubscriptionPrice,
          });
        }
        await changeBillingDateMutation.mutateAsync({
          clubId,
          subscriptionId: membership.id,
          newDate: moment(billingDate, DATE_FORMAT).format(
            PARAMETER_DATE_ONLY_FORMAT,
          ),
        });
        hide();
      }}
      title="Update Billing"
      onCancel={() => {
        hide();
      }}
    >
      <div className="flex flex-col gap-4">
        <div>
          Change the regular billing date or price. Changes are immediately and
          reflected on the next bill.
        </div>
        <LabeledFormikInput
          label={`Membership Price (${currencies[defaultCurrency].symbol})`}
          name="newSubscriptionPrice"
          formikProps={formik as any}
          value={formik.values.newSubscriptionPrice}
          onChange={(e) => {
            formik.setFieldValue("newSubscriptionPrice", e.target.value);
          }}
          error={formik.errors.newSubscriptionPrice}
        />
        <div className="flex flex-col gap-y-1">
          <div className="text-sm font-semibold">Billing Date</div>
          <div
            className={cn(
              "relative flex h-11 w-full cursor-pointer flex-row items-center rounded-lg border border-gray-300 bg-gray-0 text-center text-gray-600 hover:bg-gray-100",
            )}
          >
            <Datetime
              dateFormat={DATE_FORMAT}
              timeFormat={false}
              onChange={(v: Moment | string) => {
                if (typeof v !== "string") {
                  setBillingDate(v.format(DATE_FORMAT));
                }
              }}
              value={billingDate}
              closeOnSelect
              isValidDate={isValidDate}
              renderDay={(props, currentDate: Moment, selectedDate: Moment) => {
                return (
                  <td
                    {...props}
                    className={cn(props?.className, "relative", {
                      "opacity-20": !isValidDate(currentDate),
                      "!bg-secondary-500 hover:!bg-secondary-600 text-gray-0":
                        currentDate?.format(DATE_FORMAT) ===
                        selectedDate?.format(DATE_FORMAT),
                    })}
                  >
                    {currentDate.date()}
                  </td>
                );
              }}
              className={cn("h-full w-full px-4")}
              inputProps={{
                readOnly: true,
                className: cn(
                  "h-full w-full bg-gray-0 font-medium border-0 focus:outline-none cursor-pointer text-gray-600 placeholder:text-gray-600",
                ),
              }}
            />
          </div>
        </div>
        <NotPrimaryMembershipWarning membership={membership} />
      </div>
    </ConfirmModal>
  );
};
