import { useAbility } from "@casl/react";
import {
  useClubFeatureFlags,
  useMutationMemberListCsv,
  UserMemberListFilter,
} from "@gymflow/api";
import { cn, pluralize } from "@gymflow/helpers";
import {
  Button,
  DownloadIcon,
  FilterIcon,
  MailIcon,
  PlusIcon,
  SMSIcon,
} from "apps/portal/src/components/atoms";
import { NewUserSideBarProviderContext } from "apps/portal/src/components/molecules";
import { AbilityContext, Subject, Verb } from "apps/portal/src/permissions";
import { useClubSettings } from "apps/portal/src/providers";
import useGymflowModels from "apps/portal/src/store";
import omit from "lodash/omit";
import { useCallback, useContext, useMemo } from "react";

import { getFilterAppliedCount } from "../../../../helpers";
import { SendEmailSidebarProviderContext } from "../../SendEmailSidebarProvider";
import { dataFiltersPreparator } from "../utils/dataFiltersPreparator";

type ActionsSettingsContainerProps = {
  reset: () => void;
  filters: UserMemberListFilter;
  selected: string[];
  areAllSelected: boolean;
  selectionCount: number;
  excluded: string[];
  setFiltersAreVisible: (filtersAreVisible: boolean) => void;
  setIsSendSMSSidebarVisible: (isSendSMSSidebarVisible: boolean) => void;
};

export const ActionsSettingsContainer = ({
  reset,
  filters,
  selected,
  setFiltersAreVisible,
  setIsSendSMSSidebarVisible,
  areAllSelected,
  selectionCount,
  excluded,
}: ActionsSettingsContainerProps) => {
  const { api } = useGymflowModels();
  const { clubId, timezone: tz } = useClubSettings();
  const { data: featureFlags } = useClubFeatureFlags({ clubId, api });
  const ability = useAbility(AbilityContext);
  const { mutateAsync: downloadCsv } = useMutationMemberListCsv({
    api,
    tz,
  });
  const { open } = useContext(NewUserSideBarProviderContext);
  const { openSendEmailSidebar } = useContext(SendEmailSidebarProviderContext);

  const onClickMail = useCallback(() => {
    const recipient = `${selectionCount} ${pluralize(
      "user",
      "users",
      selectionCount,
    )}`;
    openSendEmailSidebar({
      recipient,
      allowMarketing: true,
      requestType: "USER",
      ...(areAllSelected
        ? {
            filter: dataFiltersPreparator(filters, excluded),
          }
        : { listOfIds: selected }),
      onSuccess: () => reset(),
    });
  }, [
    areAllSelected,
    excluded,
    filters,
    openSendEmailSidebar,
    reset,
    selected,
    selectionCount,
  ]);

  const isNotSelected = useMemo(
    () => selected.length === 0 && !areAllSelected,
    [areAllSelected, selected.length],
  );

  const isHiddenEmail = useMemo(
    () => isNotSelected || !ability.can(Verb.Create, Subject.Email),
    [ability, isNotSelected],
  );

  const isHiddenSMS = useMemo(
    () =>
      !featureFlags?.featureFlags.PORTAL_SEND_SMS ||
      isNotSelected ||
      !ability.can(Verb.Create, Subject.SMS),
    [ability, featureFlags?.featureFlags.PORTAL_SEND_SMS, isNotSelected],
  );

  return (
    <>
      <Button
        onClick={async () => await downloadCsv(filters)}
        className="mt-0 w-11 min-w-[2.75rem] p-0"
      >
        <DownloadIcon pathClassName="stroke-gray-500" />
      </Button>
      <Button
        className={cn("mt-0 w-11 min-w-[2.75rem] p-0", {
          hidden: isHiddenEmail,
        })}
        onClick={onClickMail}
      >
        <MailIcon className="h-5 w-5" pathClassName="stroke-gray-500" />
      </Button>
      <Button
        className={cn("mt-0 w-11 min-w-[2.75rem] p-0", {
          hidden: isHiddenSMS,
        })}
        onClick={() => setIsSendSMSSidebarVisible(true)}
      >
        <SMSIcon className="h-5 w-5" pathClassName="stroke-gray-500" />
      </Button>
      <Button
        className="mt-0 w-11 min-w-[2.75rem] lg:w-auto lg:min-w-fit"
        onClick={() => setFiltersAreVisible(true)}
        badgeContent={getFilterAppliedCount(
          omit(filters, [
            "createdTo",
            "membershipStartTo",
            "membershipCancellationTo",
            "membershipExpireTo",
          ]),
        )}
      >
        <div className="inline-flex items-center gap-x-2">
          <FilterIcon
            className="h-[1.125rem] w-[1.125rem]"
            pathClassName="stroke-gray-500"
          />
          <div className={cn("hidden lg:flex")}>Filters</div>
        </div>
      </Button>
      <Button
        className="mt-0 w-11 min-w-[2.75rem] lg:w-auto lg:min-w-fit"
        onClick={() => open()}
        intent="secondary"
      >
        <div className="inline-flex items-center gap-x-2">
          <PlusIcon
            className="h-[1.125rem] w-[1.125rem]"
            pathClassName="stroke-white"
          />
          <div className={cn("hidden lg:flex")}>User</div>
        </div>
      </Button>
    </>
  );
};
