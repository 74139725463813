import {
  useAppointmentCollectPayment,
  useClubFeatureFlags,
  useMutationAppointmentBookingConsumeSessions,
  useMutationAppointmentBookingCustomPaymentByCustomPaymentCategoryId,
  useMutationAppointmentBookingPaymentByPaymentMethodId,
} from "@gymflow/api";
import { CustomPaymentCategoryDTO, InvoiceDTO } from "@gymflow/types";
import { useClubSettings } from "apps/portal/src/providers";
import useGymflowModels from "apps/portal/src/store";

import { PaymentUnitType } from "../SideBarAppointment";

type HandleAppointmentPaymentParams = {
  appointmentId: number;
  paymentUnit: PaymentUnitType;
  paymentMethodId?: string;
  promotionCode?: string;
  inPersonPaymentMethod?: { value: CustomPaymentCategoryDTO };
};

export const useHandleAppointmentPayment = () => {
  const { clubId } = useClubSettings();
  const { api } = useGymflowModels();
  const { data: featureFlags } = useClubFeatureFlags({ api, clubId });

  const collectPaymentMutation = useAppointmentCollectPayment({ api });
  const { mutateAsync: payOnline } =
    useMutationAppointmentBookingPaymentByPaymentMethodId({ api });
  const { mutateAsync: payWithCredits } =
    useMutationAppointmentBookingConsumeSessions({ api });
  const { mutateAsync: payCustom } =
    useMutationAppointmentBookingCustomPaymentByCustomPaymentCategoryId({
      api,
    });

  const handlePayment = async ({
    appointmentId,
    paymentUnit,
    paymentMethodId,
    promotionCode,
    inPersonPaymentMethod,
  }: HandleAppointmentPaymentParams): Promise<{
    invoice?: InvoiceDTO;
    invoiceNumber?: string;
  }> => {
    if (paymentUnit === "ONLINE" && paymentMethodId) {
      if (
        featureFlags?.featureFlags
          .PORTAL_NEW_APPOINTMENT_PAYMENT_ONLINE_ENDPOINT
      ) {
        // new
        return await payOnline({
          appointmentId,
          paymentMethodId,
          promotionCode,
        });
      } else {
        // old
        return await collectPaymentMutation.mutateAsync({
          appointmentId,
          paymentMethodId,
          paidWithSessions: undefined,
          promotionCode,
        });
      }
    }

    if (paymentUnit === "CREDITS") {
      if (
        featureFlags?.featureFlags
          .PORTAL_NEW_APPOINTMENT_PAYMENT_CREDITS_ENDPOINT
      ) {
        // new
        await payWithCredits({ appointmentId, promotionCode });
        // without response (204 code)
        return {};
      } else {
        // old
        return await collectPaymentMutation.mutateAsync({
          appointmentId,
          paymentMethodId: undefined,
          paidWithSessions: true,
          promotionCode,
        });
      }
    }

    if (paymentUnit === "IN_PERSON" && inPersonPaymentMethod) {
      // new
      return await payCustom({
        appointmentId,
        customPaymentCategoryId: inPersonPaymentMethod.value.id,
        promotionCode,
      });
    }

    return {};
  };

  return { handlePayment };
};
