import { ApiType } from "@gymflow/common/lib/api/ApiType";
import { useQuery } from "@tanstack/react-query";

import { memberAsMemberQueryKeys } from "./memberAsMemberQueryKeys";

export function useMemberAsMemberNew(
  { api }: { api: ApiType },
  opts?: {
    enabled?: boolean;
  },
) {
  return useQuery({
    queryKey: memberAsMemberQueryKeys.all(),
    queryFn: async () => (await api.profileApi.get()).data,
    ...opts,
  });
}
