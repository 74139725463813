import "./AppointmentActionsDropdown.css";

import { useAutoAnimate } from "@formkit/auto-animate/react";
import { faClose } from "@fortawesome/free-solid-svg-icons";
import { FontAwesomeIcon } from "@fortawesome/react-fontawesome";
import {
  appointmentQueryKeys,
  calendarQueryKeys,
  clubStaleTime,
  useAppointmentEdit,
  useAppointmentRefundPayment,
  useClub,
  useMutationRecurringAppointmentCancel,
  useMutationRecurringAppointmentEdit,
} from "@gymflow/api";
import { cn } from "@gymflow/helpers";
import { AppointmentDTO, CustomPaymentCategoryDTO } from "@gymflow/types";
import { ChevronDownIcon } from "@heroicons/react/20/solid";
import * as DropdownMenu from "@radix-ui/react-dropdown-menu";
import { useQueryClient } from "@tanstack/react-query";
import { STAFF_OR_FACILITY_HAS_NO_AVAILABLE_SLOTS_ERROR_CODES } from "apps/portal/src/constants/errorsFromBackend";
import useScaModal from "apps/portal/src/hooks/useScaModal";
import { ToastContext } from "apps/portal/src/providers/ToastProvider/context";
import { isAxiosError } from "axios";
import classNames from "classnames";
import { useCallback, useContext, useEffect, useState } from "react";

import { ModalContext, useClubSettings } from "../../../providers";
import useGymflowModels from "../../../store";
import {
  Button,
  ChevronRightIcon,
  SelectInputOptionType,
  SlideSideBar,
} from "../../atoms";
import { ConfirmModal } from "../../templates";
import { HostNotAvailableModal } from "../HostNotAvailableModal";
import { AppointmentDetails } from "./AppointmentDetails";
import { AppointmentNotes } from "./AppointmentNotes";
import { AppointmentPayment } from "./AppointmentPayment";
import { useHandleAppointmentPayment } from "./hooks/useHandleAppointmentPayment";
import { AppointmentRescheduleModal } from "./RescheduleModal";
import { RecurringAppointmentRescheduleModal } from "./RescheduleRecurringModal";

interface SideBarAppointmentProps {
  onClose: () => void;
  isOpen: boolean;
  value: AppointmentDTO;
  isLoading: boolean;
}

export type PaymentUnitType = "ONLINE" | "CREDITS" | "IN_PERSON" | undefined;

// TODO: need to refactor (whole file)
export function SideBarAppointment({
  onClose,
  isOpen,
  value,
  isLoading,
}: SideBarAppointmentProps) {
  const [parent] = useAutoAnimate();
  const settings = useClubSettings();
  const { notifyDanger, toast } = useContext(ToastContext);
  const clubId = settings.clubId;
  const { api } = useGymflowModels();
  const { data: club } = useClub({ api, clubId }, { staleTime: clubStaleTime });
  const { handlePayment } = useHandleAppointmentPayment();
  const [paymentMethodId, setPaymentMethodId] = useState<string>();
  const [inPersonPaymentMethod, setInPersonPaymentMethod] =
    useState<SelectInputOptionType<CustomPaymentCategoryDTO>>();

  const [selectedTab, setSelectedTab] = useState<
    "DETAILS" | "PAYMENT" | "NOTES"
  >("DETAILS");
  const [paymentUnit, setPaymentUnit] = useState<PaymentUnitType>();

  const [promoCode, setPromoCode] = useState<string>();

  const [isTabOpen, setIsTabOpen] = useState(isOpen);
  useEffect(() => {
    setIsTabOpen(isOpen);
  }, [isOpen]);

  const showScaModal = useScaModal({
    asMember: false,
  });

  const queryClient = useQueryClient();

  return (
    <SlideSideBar
      isOpen={isTabOpen}
      hide={onClose}
      className="!w-[32rem]"
      isLoading={isLoading}
    >
      <div className="flex h-full max-h-full flex-col overflow-hidden">
        <div className="flex h-full max-h-full flex-col overflow-hidden">
          <div className="flex flex-col justify-between border-b border-gray-200 p-8">
            <div className="mb-1 flex flex-row items-center justify-between">
              <div className="text-xl font-semibold text-gray-900">
                View Appointment
              </div>

              <FontAwesomeIcon
                onClick={() => {
                  onClose();
                }}
                className="cursor-pointer text-xl text-gray-600"
                icon={faClose}
              />
            </div>
            <div className="text-sm font-medium text-gray-600">
              View, update and cancel an existing appointment.
            </div>

            <div className="mt-4 flex">
              <Button
                onClick={() => setSelectedTab("DETAILS")}
                className={classNames(
                  "w-full !rounded-r-none focus:outline-none",
                  {
                    "bg-gray-100": selectedTab === "DETAILS",
                  },
                )}
              >
                Details
              </Button>
              <Button
                onClick={() => setSelectedTab("PAYMENT")}
                className={classNames(
                  "w-full !rounded-none focus:outline-none",
                  {
                    "bg-gray-100": selectedTab === "PAYMENT",
                  },
                )}
              >
                Payment
              </Button>
              <Button
                onClick={() => setSelectedTab("NOTES")}
                className={classNames(
                  "w-full !rounded-l-none focus:outline-none",
                  {
                    "bg-gray-100": selectedTab === "NOTES",
                  },
                )}
              >
                Notes
              </Button>
            </div>
          </div>

          <div
            ref={parent}
            className="overflow-y-auto border-b border-gray-200 py-4"
          >
            {selectedTab === "DETAILS" && (
              <AppointmentDetails
                status={value.status}
                appointableName={value.appointable.name}
                appointablePrice={value.finalPrice}
                appointableSessionCost={value.finalSessionCost}
                hostName={
                  value.appointable.availabilityType === "STAFF"
                    ? `${value.appointmentHost?.firstName || ""} ${
                        value.appointmentHost?.lastName || ""
                      }`
                    : undefined
                }
                facilityName={
                  value.appointable.availabilityType === "FACILITY"
                    ? value.appointmentFacility?.name
                    : undefined
                }
                availabilityType={value.appointable.availabilityType}
                memberName={`${value.appointmentUser.firstName} ${value.appointmentUser.lastName}`}
                memberId={value.appointmentUser.id}
                paymentStatus={value.paymentStatus}
                startDate={value.startDate}
                endDate={value.endDate}
                currency={club?.defaultCurrency!}
                appointablePricingModel={value.appointable.pricingModel}
              />
            )}
            {selectedTab === "PAYMENT" && (
              <AppointmentPayment
                paymentStatus={value.paymentStatus}
                appointablePrice={value.finalPrice}
                appointableSessionCost={value.finalSessionCost}
                paymentUpfront={value.paymentUpfront}
                appointableId={value.appointable.id}
                appointmentId={value.id}
                member={value.appointmentUser}
                cardRequired={value.appointable.cardUpfront}
                setPaymentMethodId={setPaymentMethodId}
                paymentMethodId={paymentMethodId}
                setPaymentUnit={setPaymentUnit}
                paymentUnit={paymentUnit}
                pricingModel={value.pricingModel}
                setPromoCode={setPromoCode}
                promoCode={promoCode}
                appointableName={value.appointable.name}
                setInPersonPaymentMethod={setInPersonPaymentMethod}
                inPersonPaymentMethod={inPersonPaymentMethod}
              />
            )}
            {selectedTab === "NOTES" && (
              <AppointmentNotes
                appointmentId={value.id}
                noteList={value.noteList}
                setIsTabOpen={setIsTabOpen}
              />
            )}
          </div>
        </div>

        <div className="flex h-20 flex-row items-center justify-end gap-2 border-t border-gray-200 px-6">
          <AppointmentActionsDropdown
            className="flex-1"
            appointment={value}
            setIsTabOpen={setIsTabOpen}
          />
          <Button
            intent="primary"
            className={classNames("flex-1", {
              hidden:
                value.paymentStatus === "PAID" || selectedTab === "PAYMENT",
            })}
            onClick={() => setSelectedTab("PAYMENT")}
          >
            Checkout
          </Button>
          <Button
            intent="primary"
            className={classNames("flex-1", {
              hidden:
                value.paymentStatus === "PAID" || selectedTab !== "PAYMENT",
            })}
            disabled={
              !paymentUnit ||
              (paymentUnit === "ONLINE" && !paymentMethodId) ||
              (paymentUnit === "IN_PERSON" && !inPersonPaymentMethod?.value)
            }
            onClick={async () => {
              try {
                const response = await handlePayment({
                  appointmentId: value.id,
                  paymentUnit,
                  paymentMethodId,
                  promotionCode: promoCode,
                  inPersonPaymentMethod,
                });

                if (response?.invoice?.number || response?.invoiceNumber) {
                  await showScaModal({
                    invoiceNumber: String(
                      response?.invoice?.number ?? response?.invoiceNumber,
                    ),
                    userMemberId: value.appointmentUser.id,
                    onComplete: () => {
                      queryClient.invalidateQueries({
                        queryKey: appointmentQueryKeys.all(),
                      });
                      queryClient.invalidateQueries({
                        queryKey: calendarQueryKeys.appointments(),
                      });
                    },
                  });
                }
              } catch (e: any) {
                if (e?.response?.data?.resource_not_found === "Promotion") {
                  toast({
                    intent: "error",
                    message: "Promotion code is not valid.",
                  });
                } else {
                  notifyDanger(e);
                }
              } finally {
                queryClient.invalidateQueries({
                  queryKey: appointmentQueryKeys.all(),
                });
                queryClient.invalidateQueries({
                  queryKey: calendarQueryKeys.appointments(),
                });
                setIsTabOpen(true);
              }
            }}
          >
            Take Payment
          </Button>
        </div>
      </div>
    </SlideSideBar>
  );
}

export function AppointmentActionsDropdown({
  className,
  appointment,
  setIsTabOpen,
}: {
  className?: string;
  appointment: AppointmentDTO;
  setIsTabOpen: (newValue: boolean) => void;
}) {
  const { setModal, hide: hideModal } = useContext(ModalContext);
  const { notifyDanger, toast } = useContext(ToastContext);

  const settings = useClubSettings();
  const { api } = useGymflowModels();

  const editAppointmentMutation = useAppointmentEdit({
    api,
    tz: settings.timezone,
  });

  const rescheduleRecurringAppointment = useMutationRecurringAppointmentEdit({
    api,
  });

  const refundAppointmentMutation = useAppointmentRefundPayment({
    api,
  });

  const cancelRecurringAppointment = useMutationRecurringAppointmentCancel({
    api,
  });

  const rescheduleCallback = useCallback(() => {
    setIsTabOpen(false);
    setModal(
      <AppointmentRescheduleModal
        appointment={appointment}
        onConfirm={async (newValues) => {
          try {
            await editAppointmentMutation.mutateAsync({
              appointmentId: appointment.id,
              patchedFields: newValues,
            });

            hideModal();
            setIsTabOpen(true);
          } catch (e: any) {
            hideModal();
            if (
              isAxiosError(e) &&
              STAFF_OR_FACILITY_HAS_NO_AVAILABLE_SLOTS_ERROR_CODES.includes(
                e.response?.data.error_code,
              )
            ) {
              setModal(
                <HostNotAvailableModal
                  modalType="APPOINTMENT"
                  onConfirm={async () => {
                    await editAppointmentMutation.mutateAsync({
                      appointmentId: appointment.id,
                      patchedFields: {
                        ...newValues,
                        availabilityValidationIgnored: true,
                      },
                    });
                    hideModal();
                    setIsTabOpen(true);

                    toast({
                      message: "Appointment rescheduled.",
                    });
                  }}
                  onCancel={() => {
                    hideModal();
                    setIsTabOpen(true);
                  }}
                />,
              );
            } else {
              notifyDanger(e);
            }
          }
        }}
        onCancel={() => {
          hideModal();
          setIsTabOpen(true);
        }}
      />,
    );
  }, [
    appointment,
    editAppointmentMutation,
    hideModal,
    toast,
    notifyDanger,
    setIsTabOpen,
    setModal,
  ]);

  const cancelCallback = useCallback(
    async (newStatus: "CANCELLED" | "LATE_CANCELLED") => {
      try {
        await editAppointmentMutation.mutateAsync({
          appointmentId: appointment.id,
          patchedFields: { status: newStatus },
        });

        if (
          ((appointment.finalPrice !== 0 &&
            appointment.finalPrice !== undefined) ||
            (appointment.finalSessionCost !== 0 &&
              appointment.finalSessionCost !== undefined)) &&
          appointment.paymentStatus === "PAID"
        ) {
          setIsTabOpen(false);
          setModal(
            <ConfirmModal
              title="Issue Refund?"
              cancelText="No"
              confirmText="Yes"
              onCancel={() => {
                hideModal();
                setIsTabOpen(true);
                toast({ message: "Appointment cancelled." });
              }}
              onConfirm={async () => {
                await refundAppointmentMutation.mutateAsync({
                  clubId: settings.clubId,
                  appointmentId: appointment.id,
                });

                hideModal();
                setIsTabOpen(true);
                toast({
                  message: "Appointment cancelled and refunded.",
                });
              }}
            >
              Would you like to refund the payment or credits for this
              appointment.
            </ConfirmModal>,
          );
        }
      } catch (e) {
        notifyDanger(e as any);
      }
    },
    [
      appointment.finalPrice,
      appointment.finalSessionCost,
      appointment.id,
      appointment.paymentStatus,
      editAppointmentMutation,
      hideModal,
      toast,
      notifyDanger,
      refundAppointmentMutation,
      setIsTabOpen,
      setModal,
      settings.clubId,
    ],
  );

  const optionClassNames =
    "flex justify-between p-2.5 cursor-pointer hover:bg-gray-50 text-sm font-medium";

  return (
    <DropdownMenu.Root>
      <DropdownMenu.Trigger asChild>
        <div
          className={cn(
            "flex rounded-lg border border-gray-300 px-4 py-2.5",
            className,
          )}
        >
          <div className="flex-1 text-center">More Options</div>
          <div className="h-6 w-6">
            <ChevronDownIcon fontSize={20} />
          </div>
        </div>
      </DropdownMenu.Trigger>

      <DropdownMenu.Content
        className="DropdownMenuContent bg-gray-0 rounded border border-gray-200"
        sideOffset={5}
      >
        {appointment.paymentStatus === "PAID" && (
          <DropdownMenu.Item
            className={cn("text-error-700", optionClassNames)}
            onSelect={async () => {
              try {
                await refundAppointmentMutation.mutateAsync({
                  clubId: settings.clubId,
                  appointmentId: appointment.id,
                });
                toast({ message: "Appointment refunded." });
              } catch (e) {
                notifyDanger(e as any);
              }
            }}
          >
            Refund
          </DropdownMenu.Item>
        )}
        {appointment.recurringAppointmentId === undefined &&
          appointment.kisiRoleAssignmentId === undefined && (
            <DropdownMenu.Item
              className={optionClassNames}
              onSelect={rescheduleCallback}
            >
              Reschedule
            </DropdownMenu.Item>
          )}
        {appointment.recurringAppointmentId !== undefined &&
          appointment.kisiRoleAssignmentId === undefined && (
            <DropdownMenu.Sub>
              <DropdownMenu.SubTrigger className={optionClassNames}>
                Reschedule
                <div>
                  <ChevronRightIcon pathClassName="stroke-[#667085] h-5 w-5" />
                </div>
              </DropdownMenu.SubTrigger>
              <DropdownMenu.SubContent
                className="bg-gray-0 rounded border border-gray-200"
                sideOffset={2}
                alignOffset={-5}
              >
                <DropdownMenu.Item
                  className={optionClassNames}
                  onSelect={rescheduleCallback}
                >
                  Only this appointment
                </DropdownMenu.Item>
                <DropdownMenu.Item
                  className={optionClassNames}
                  onSelect={() => {
                    setIsTabOpen(false);
                    setModal(
                      <RecurringAppointmentRescheduleModal
                        appointment={appointment}
                        onConfirm={async (newValues) => {
                          setModal(
                            <ConfirmModal
                              type="warning"
                              onConfirm={async () => {
                                await rescheduleRecurringAppointment.mutateAsync(
                                  {
                                    recurringAppointmentId:
                                      appointment.recurringAppointmentId!,
                                    patchedFields: {
                                      ...newValues,
                                      fromAppointmentId: appointment.id,
                                    },
                                  },
                                );
                                hideModal();
                                setIsTabOpen(true);
                              }}
                              onCancel={() => {
                                hideModal();
                                setIsTabOpen(true);
                              }}
                              title="Attention"
                              confirmText="I got it"
                            >
                              If you have changed specific appointments in this
                              series, your changes will be undone and those
                              appointments will be consistent with the series
                              again.
                            </ConfirmModal>,
                          );
                        }}
                        onCancel={() => {
                          hideModal();
                          setIsTabOpen(true);
                        }}
                      />,
                    );
                  }}
                >
                  This and all future appointments in this series
                </DropdownMenu.Item>
              </DropdownMenu.SubContent>
            </DropdownMenu.Sub>
          )}
        {appointment.recurringAppointmentId === undefined && (
          <DropdownMenu.Item
            className={optionClassNames}
            onSelect={() => cancelCallback("CANCELLED")}
          >
            Cancel
          </DropdownMenu.Item>
        )}

        {appointment.recurringAppointmentId !== undefined && (
          <DropdownMenu.Sub>
            <DropdownMenu.SubTrigger className={optionClassNames}>
              Cancel
              <div>
                <ChevronRightIcon pathClassName="stroke-[#667085] h-5 w-5" />
              </div>
            </DropdownMenu.SubTrigger>
            <DropdownMenu.SubContent
              className="bg-gray-0 rounded border border-gray-200"
              sideOffset={2}
              alignOffset={-5}
            >
              <DropdownMenu.Item
                className={optionClassNames}
                onSelect={() => cancelCallback("CANCELLED")}
              >
                Only this appointment
              </DropdownMenu.Item>
              <DropdownMenu.Item
                className={optionClassNames}
                onSelect={() => {
                  setIsTabOpen(false);
                  setModal(
                    <ConfirmModal
                      type="warning"
                      onConfirm={async () => {
                        try {
                          await cancelRecurringAppointment.mutateAsync({
                            fromAppointmentId: appointment.id,
                            recurringAppointmentId:
                              appointment.recurringAppointmentId!,
                          });
                          hideModal();
                          toast({ message: "Appointment series cancelled." });
                        } catch (e) {
                          hideModal();
                          notifyDanger(e as any);
                        }
                        setIsTabOpen(true);
                      }}
                      title="Cancelling recurring appointment"
                      onCancel={() => {
                        hideModal();
                        setIsTabOpen(true);
                      }}
                    >
                      Are you sure you want to cancel all following instances of
                      this recurring appointment?
                    </ConfirmModal>,
                  );
                }}
              >
                This and all future appointments in this series
              </DropdownMenu.Item>
            </DropdownMenu.SubContent>
          </DropdownMenu.Sub>
        )}

        <DropdownMenu.Item
          className={optionClassNames}
          onSelect={async () => {
            try {
              await editAppointmentMutation.mutateAsync({
                appointmentId: appointment.id,
                patchedFields: { status: "NO_SHOW" },
              });
              toast({
                message: "Appointment marked as no show.",
              });
            } catch (e) {
              notifyDanger(e as any);
            }
          }}
        >
          No Show
        </DropdownMenu.Item>
        <DropdownMenu.Item
          className={optionClassNames}
          onSelect={() => cancelCallback("LATE_CANCELLED")}
        >
          Late Cancel
        </DropdownMenu.Item>
        <DropdownMenu.Item
          className={optionClassNames}
          onSelect={async () => {
            try {
              await editAppointmentMutation.mutateAsync({
                appointmentId: appointment.id,
                patchedFields: { status: "ATTENDED" },
              });
              toast({
                message: "Appointment checked in.",
              });
            } catch (e) {
              notifyDanger(e as any);
            }
          }}
        >
          Check In
        </DropdownMenu.Item>
      </DropdownMenu.Content>
    </DropdownMenu.Root>
  );
}
