import { ApiType } from "@gymflow/common/lib/api/ApiType";
import { RuleGroup } from "@gymflow/types";
import { useQuery } from "@tanstack/react-query";

import { ruleAsPublicQueryKeys } from "./ruleAsPublicQueryKeys";

/*** @deprecated - this should be removed when the Timetable is removed */
export const rulesAsPublicFn = async (
  api: ApiType,
  params: Parameters<ApiType["public"]["ruleApi"]["getRulesFor"]>[0],
) => {
  return (await api.public.ruleApi.getRulesFor(params)).data as RuleGroup;
};

export function useQueryRulesAsPublic({
  api,
  params,
}: {
  api: ApiType;
  params?: Parameters<ApiType["public"]["ruleApi"]["getRulesFor"]>[0];
}) {
  return useQuery({
    queryKey: ruleAsPublicQueryKeys.rules(params),
    queryFn: async () => {
      return (await api.public.ruleApi.getRulesFor(params!)).data;
    },
    enabled: !!params,
  });
}
