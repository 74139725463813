import { useAutoAnimate } from "@formkit/auto-animate/react";
import { faAngleRight } from "@fortawesome/free-solid-svg-icons";
import { FontAwesomeIcon } from "@fortawesome/react-fontawesome";
import {
  memberAsMemberQueryKeys,
  useMemberAsMember,
  useMemberInvoiceListAsMember,
  useMutationAppointmentCreateAsMember,
  useMutationPayInvoiceAsMember,
  useQueryAppointableAsPublic,
  useQueryAppointmentSummaryAsMember,
  useQuerySessionPackUsageAsMember,
} from "@gymflow/api";
import { cn, formatCurrency, pluralize } from "@gymflow/helpers";
import { useQueryClient } from "@tanstack/react-query";
import { usePortalRoutes, useRedirectUrl } from "apps/portal/src/hooks";
import useScaModal from "apps/portal/src/hooks/useScaModal";
import {
  useAuthenticatedUser,
  useClubSettings,
} from "apps/portal/src/providers";
import { ToastContext } from "apps/portal/src/providers/ToastProvider/context";
import { RouteFeature } from "apps/portal/src/routes";
import useGymflowModels from "apps/portal/src/store";
import { isAxiosError } from "axios";
import { DateTime } from "luxon";
import React, { useCallback, useContext, useEffect, useState } from "react";
import { useTranslation } from "react-i18next";
import { useHistory, useParams } from "react-router";

import {
  Button,
  CreditsIcon,
  ErrorMessage,
  LabeledForm,
  LayeredCircles,
  ShopBagIcon,
  Spinner,
} from "../../atoms";
import { ExclamationCircleIcon } from "../../atoms/icons/ExclamationCircleIcon";
import { PaymentMethodPicker } from "../../molecules/PaymentMethodPicker";
import { ToggleModeButton } from "../../pages/Leads/ToggleModeButton";
import { HostedPagesBackHeader } from "../components/HostedPagesBackHeader";
import HostedPagesQuickPurchaseOverlay from "../HostedPagesQuickPurchase";
import useRedirectIfNotAuthenticated from "../useRedirectIfNotAuthenticated";

export type HostedPagesAppointmentCheckoutProps = {};

export const HostedPagesAppointmentCheckout: React.FC<
  HostedPagesAppointmentCheckoutProps
> = ({}) => {
  const { isEmbed } = usePortalRoutes();
  const { t } = useTranslation();
  const history = useHistory();
  const [currentInvoiceId, setCurrentInvoiceId] = useState<string>();
  const showScaModal = useScaModal({
    asMember: true,
  });
  const { createSiteOrEmbedLink } = usePortalRoutes();
  useRedirectIfNotAuthenticated(
    createSiteOrEmbedLink(RouteFeature.Appointables),
  );
  const auth = useAuthenticatedUser();

  const { api } = useGymflowModels();
  const settings = useClubSettings();
  const { data: userMember } = useMemberAsMember({
    api,
    memberId: auth.id,
    tz: settings.timezone,
  });
  const { appointableId, staffOrFacilityId, timestamp } = useParams<{
    appointableId: string;
    staffOrFacilityId: string;
    timestamp: string;
  }>();
  const startTimestamp = DateTime.fromISO(timestamp).setZone(settings.timezone);

  const [promotionCodeInput, setPromotionCodeInput] = useState<string>();
  const [currentPaymentMethodId, setCurrentPaymentMethodId] =
    useState<string>();

  const { data: appointable, isLoading } = useQueryAppointableAsPublic({
    api,
    id: parseInt(appointableId),
  });
  const [paidWith, setPaidWith] = useState<"CARD" | "CREDITS">("CREDITS");
  useEffect(() => {
    if (appointable?.sessionCost !== undefined) {
      setPaidWith("CREDITS");
    } else {
      setPaidWith("CARD");
    }
  }, [appointable]);

  const [promotionCode, setPromotionCode] = useState<string>();
  const { data: summary, isFetching: summaryIsFetching } =
    useQueryAppointmentSummaryAsMember({
      api,
      opts: {
        appointableId: parseInt(appointableId),
        appointmentPaymentPostDTO: {
          promotionCode: promotionCode,
          paidWithSessions: paidWith === "CREDITS",
          paymentMethodId: currentPaymentMethodId,
        },
      },
    });
  const { data: invoices } = useMemberInvoiceListAsMember({
    api,
    opts: {
      extraParams: {
        number: currentInvoiceId,
      },
    },
    enabled: !!currentInvoiceId,
  });
  const invoice = invoices?.content?.[0];

  const { mutateAsync: payFailed } = useMutationPayInvoiceAsMember({
    api,
  });
  const { mutateAsync: bookAppointment, isSuccess: bookAppointmentSuccess } =
    useMutationAppointmentCreateAsMember({
      api,
    });
  const closeOverlay = useCallback(() => {
    history.replace(
      createSiteOrEmbedLink(
        RouteFeature.AppointableIdCheckout.replace(
          ":appointableId",
          appointable?.id?.toString() ?? "",
        )
          .replace(":appointableId", appointableId.toString())
          .replace(":staffOrFacilityId", staffOrFacilityId)
          .replace(":timestamp", timestamp ?? ""),
      ),
    );
  }, [
    appointable?.id,
    appointableId,
    createSiteOrEmbedLink,
    history,
    staffOrFacilityId,
    timestamp,
  ]);
  const { notifyDanger, toast } = useContext(ToastContext);
  const onPay = async () => {
    try {
      if (!appointable) return;
      const paymentMethod = isFree ? undefined : currentPaymentMethodId;
      let result = await bookAppointment({
        appointableId: Number(appointableId),
        startDate:
          startTimestamp.toUTC().toISO({ suppressMilliseconds: true }) ?? "",
        status: "BOOKED",

        ...(staffOrFacilityId === "no-preference"
          ? {}
          : appointable?.availabilityType === "STAFF"
          ? {
              appointmentHostId: staffOrFacilityId,
            }
          : {
              appointmentFacilityId: Number(staffOrFacilityId),
            }),
        appointmentPayment: {
          paidWithSessions: paidWith === "CREDITS",
          promotionCode: paidWith === "CREDITS" ? undefined : promotionCode,
          paymentMethodId: paidWith === "CREDITS" ? undefined : paymentMethod,
        },
      });
      if (result.invoice.number) {
        setCurrentInvoiceId(result.invoice.number);
        showScaModal({
          invoiceNumber: result.invoice.number,
        });
      } else {
        toast({
          message: t("pages.HostedPagesAppointmentCheckout.invoicePaid"),
          description: t(
            "pages.HostedPagesAppointmentCheckout.invoicePaidDescription",
          ),
          intent: "success",
        });
        setTimeout(() => {
          redirectToCustomizedWebsiteOr();
        }, 1500);
      }
    } catch (e) {
      if (isAxiosError(e)) {
        notifyDanger(e);
      }
    }
  };
  const sessionPackUsageQuery = useQuerySessionPackUsageAsMember({
    api,
    params:
      appointable && appointable.sessionCost !== undefined
        ? {
            sessionCost: appointable.sessionCost,
            appointableCategoryId: appointable.appointableCategory.id,
          }
        : undefined,
  });
  const queryClient = useQueryClient();

  const { redirectToCustomizedWebsiteOr } = useRedirectUrl();

  const isFree =
    (summary?.paidWithSessions
      ? summary.sessionPackUsage.result
      : summary?.cardUsage?.upfrontPriceDetails.totalAmountToPay) === 0;
  const [parent] = useAutoAnimate();
  const paymentsLoadingStatus = queryClient.getQueryState(
    memberAsMemberQueryKeys.paymentMethods(settings.clubId, auth.id),
  )?.status;
  const arePaymentMethodsLoading =
    !paymentsLoadingStatus || paymentsLoadingStatus === "loading";
  const appointmentHasBeenPaid =
    invoice?.status === "PAID" ||
    (summary?.paidWithSessions && bookAppointmentSuccess);
  useEffect(() => {
    if (appointmentHasBeenPaid) {
      toast({
        message: t("pages.HostedPagesAppointmentCheckout.invoicePaid"),
        description: t(
          "pages.HostedPagesAppointmentCheckout.invoicePaidDescription",
        ),
        intent: "success",
      });
      setTimeout(() => {
        redirectToCustomizedWebsiteOr();
      }, 1500);
    }
  }, [
    appointmentHasBeenPaid,
    queryClient,
    redirectToCustomizedWebsiteOr,
    t,
    toast,
  ]);
  return (
    <div
      className={cn("flex w-full flex-col items-center overflow-y-auto", {
        "h-fit track-height": isEmbed,
        "h-full": !isEmbed,
      })}
    >
      <HostedPagesBackHeader label={t("common.checkout")} />

      <div
        ref={parent}
        className="flex h-fit w-full max-w-lg flex-col gap-4 px-4 py-6"
      >
        {isLoading && <Spinner />}
        {appointable &&
          appointable.price !== undefined &&
          appointable.sessionCost !== undefined &&
          !appointmentHasBeenPaid &&
          !invoice && (
            <div className="flex w-full flex-col gap-3">
              <div className="dark:text-gray-0 text-gray-950">
                {t("page.hostedPagesAppointmentCheckout.paymentMode")}
              </div>
              <ToggleModeButton
                value={paidWith === "CREDITS" ? "RIGHT" : "LEFT"}
                onChange={() => {
                  setPaidWith(paidWith === "CREDITS" ? "CARD" : "CREDITS");
                }}
                left={
                  <div className="dark:text-gray-0 flex h-11 w-1/2 items-center justify-center text-sm font-semibold text-gray-950">
                    {t("page.hostedPagesAppointmentCheckout.paymentModeCard")}
                  </div>
                }
                right={
                  <div className="dark:text-gray-0 flex h-11 w-1/2 items-center justify-center text-sm font-semibold text-gray-950">
                    {t(
                      "page.hostedPagesAppointmentCheckout.paymentModeCredits",
                    )}
                  </div>
                }
              />
            </div>
          )}
        {invoice && (
          <div className="flex w-full flex-col items-center gap-y-3">
            <div className="dark:text-gray-0 text-3xl font-bold text-gray-950">
              {formatCurrency(invoice.totalAmount, settings.defaultCurrency)}
            </div>
            <div className="flex flex-col items-center gap-1">
              {invoice.status === "PAID" && (
                <>
                  <div className="text-success-600 dark:text-success-400 text-center font-bold">
                    {t("pages.hostedPagesCheckout.paymentSuccessful")}
                  </div>
                  <div className="font-medium text-gray-600 dark:text-gray-400">
                    {DateTime.fromISO(
                      invoice.issueDate ?? new Date().toISOString(),
                    ).toLocaleString(DateTime.DATE_MED)}
                  </div>
                </>
              )}
              {invoice.status === "PAST_DUE" && (
                <div className="flex flex-col items-center gap-3">
                  <div className="text-error-600 dark:text-error-400 text-center font-bold">
                    {t("pages.hostedPagesCheckout.paymentFailed")}
                  </div>
                  <div className="font-medium text-gray-600 dark:text-gray-400">
                    <Button
                      onClick={async () => {
                        const newInvoice = await payFailed({
                          invoiceNumber: invoice.number,
                          amount: invoice.totalAmount,
                          paymentMethodId: currentPaymentMethodId,
                        });
                        showScaModal({
                          invoiceNumber: newInvoice.number,
                        });
                      }}
                    >
                      {t("pages.hostedPagesCheckout.retry")}
                    </Button>
                  </div>
                </div>
              )}
              {invoice.status === "AWAITING_AUTHORIZATION" && (
                <div className="text-warning-600 text-center font-bold">
                  {t("pages.hostedPagesCheckout.authorize")}
                </div>
              )}
            </div>
          </div>
        )}
        {appointmentHasBeenPaid && summary?.paidWithSessions && (
          <div className="flex w-full flex-col items-center gap-y-3">
            <div className="dark:text-gray-0 text-3xl font-bold text-gray-950">
              {pluralize(
                t("page.hostedPagesAppointmentCheckout.creditAmount", {
                  amount: appointable?.sessionCost ?? 0,
                }),
                t("page.hostedPagesAppointmentCheckout.creditsAmount", {
                  amount: appointable?.sessionCost ?? 0,
                }),
                appointable?.sessionCost ?? 0,
              )}
            </div>
            <div className="flex flex-col items-center gap-1">
              <div className="text-success-600 dark:text-success-400 text-center font-bold">
                {t(
                  "page.hostedPagesAppointmentCheckout.creditDeductedSuccessfully",
                )}
              </div>
              <div className="font-medium text-gray-600 dark:text-gray-400">
                {DateTime.now()
                  .setZone(settings.timezone)
                  .toLocaleString(DateTime.DATE_MED)}
              </div>
            </div>
          </div>
        )}
        {!isFree && userMember && paidWith === "CARD" && (
          <div className="max-w-full">
            <PaymentMethodPicker
              userMember={userMember}
              currentPaymentMethodId={currentPaymentMethodId}
              onChange={setCurrentPaymentMethodId}
              disabled={!!invoice}
              asMember
            />
          </div>
        )}
        {!isFree && paidWith === "CREDITS" && !appointmentHasBeenPaid && (
          <div className="flex w-full flex-col gap-4">
            <div className="flex w-full flex-col gap-2">
              <div className="flex flex-row items-center gap-2">
                <LayeredCircles className="h-8 w-8" intent="default">
                  <ExclamationCircleIcon pathClassName="stroke-gray-500" />
                </LayeredCircles>
                <div className="dark:text-gray-0 font-semibold text-gray-950">
                  {t("page.hostedPagesAppointmentCheckout.credit")}
                </div>
              </div>
              <div className="text-sm text-gray-500">
                {t("page.hostedPagesAppointmentCheckout.creditDescription")}
              </div>
            </div>
            <div className="flex flex-col gap-4">
              {sessionPackUsageQuery.data &&
                sessionPackUsageQuery.data?.result !== "NOT_ENOUGH_SESSIONS" &&
                sessionPackUsageQuery.data?.sessionPackUsageCalculations.map(
                  (usageEntry, i) => (
                    <div
                      key={i}
                      className="flex flex-row items-center justify-between rounded-xl border border-gray-300 p-3 dark:border-gray-700 dark:bg-gray-900"
                    >
                      <div className="flex flex-col">
                        <div className="dark:text-gray-0 text-sm font-bold text-gray-950">
                          {usageEntry.sessionPack.name}
                        </div>
                        <div className="text-sm text-gray-500">
                          {t(
                            "page.hostedPagesAppointmentCheckout.creditExpiry",
                            {
                              date: usageEntry.sessionPack.endDate
                                ? DateTime.fromISO(
                                    usageEntry.sessionPack.endDate,
                                  )
                                    .setZone(settings.timezone)
                                    .toLocaleString(DateTime.DATE_FULL)
                                : t("common.unlimited"),
                            },
                          )}
                        </div>
                      </div>
                      <div className="flex flex-row gap-2">
                        <div className="dark:text-gray-0 text-xl font-bold text-gray-950">
                          {usageEntry.numberOfSessionsUsed}
                        </div>
                        <CreditsIcon className="stroke-secondary-500 h-6 w-6" />
                      </div>
                    </div>
                  ),
                )}
            </div>
            {sessionPackUsageQuery.data &&
              sessionPackUsageQuery.data?.result === "NOT_ENOUGH_SESSIONS" && (
                <div className="flex flex-col gap-2">
                  <div className="flex flex-row items-center justify-between rounded-xl border border-gray-300 p-3 dark:border-gray-700 dark:bg-gray-900">
                    <div className="flex flex-col">
                      <div className="dark:text-gray-0 text-sm font-bold text-gray-950">
                        {t("page.hostedPagesAppointmentCheckout.noCreditPack")}
                      </div>
                    </div>
                    <div className="flex flex-row gap-2">
                      <div className="dark:text-gray-0 text-xl font-bold text-gray-950">
                        0
                      </div>
                      <CreditsIcon className="stroke-secondary-500 h-6 w-6" />
                    </div>
                  </div>

                  <Button
                    link={createSiteOrEmbedLink(
                      RouteFeature.AppointableQuickPurchase.replace(
                        ":appointableId",
                        appointable?.id?.toString() ?? "",
                      )
                        .replace(":appointableId", appointableId.toString())
                        .replace(":staffOrFacilityId", staffOrFacilityId)
                        .replace(":timestamp", timestamp ?? "")
                        .replace(":type", "session-pack"),
                    )}
                    className="h-12 w-full justify-between rounded-lg border-gray-300 py-3 dark:border-gray-700"
                  >
                    <div className="text-md dark:text-gray-0 flex flex-row items-center gap-2 font-medium text-gray-950">
                      <ShopBagIcon pathClassName="stroke-gray-500" />
                      {t("page.hostedPagesAppointmentCheckout.buyCreditPack")}
                    </div>
                    <FontAwesomeIcon
                      className="text-gray-500"
                      icon={faAngleRight}
                    />
                  </Button>
                </div>
              )}
          </div>
        )}
        {!!invoice && promotionCode && (
          <div className="flex flex-row items-center justify-between">
            <div className="text-base text-gray-500">
              {t("pages.hostedPagesCheckout.promoCode")}
            </div>
            <div className="text-base font-bold text-gray-950">
              {promotionCode}
            </div>
          </div>
        )}
        {!invoice && !summary?.paidWithSessions && (
          <LabeledForm label={t("pages.hostedPagesCheckout.promoCode")}>
            <div className="bg-gray-0 inline-flex h-10 flex-row items-start justify-start rounded-lg border border-gray-300 shadow-sm dark:border-gray-700 dark:bg-gray-800">
              <div className="bg-gray-0 flex h-full flex-1 items-center justify-start gap-2 overflow-hidden rounded-lg rounded-r-none border-r border-gray-300 dark:border-gray-700 dark:bg-gray-950">
                <input
                  placeholder={t("pages.hostedPagesCheckout.enterCode")}
                  className="bg-gray-0 h-full w-full px-3 py-2 text-gray-500 outline-none dark:bg-gray-950"
                  value={promotionCodeInput ?? ""}
                  onChange={(e) => {
                    setPromotionCodeInput(e.target.value);
                  }}
                  disabled={promotionCode !== undefined}
                />
              </div>
              <Button
                onClick={() => {
                  if (!promotionCode) {
                    setPromotionCode(promotionCodeInput);
                  } else {
                    setPromotionCode(undefined);
                    setPromotionCodeInput("");
                  }
                }}
                className="h-full min-h-0 !rounded-lg !rounded-l-none !border-none focus:!outline-none"
              >
                {summaryIsFetching ? (
                  <Spinner />
                ) : promotionCode === undefined ? (
                  t("common.apply")
                ) : (
                  t("common.remove")
                )}
              </Button>
            </div>
          </LabeledForm>
        )}
        <div className="flex w-full border-b-2 border-dashed border-gray-400 dark:border-gray-600" />
        <div className="flex w-full flex-col gap-2">
          {summary &&
            promotionCode &&
            !summary?.paidWithSessions &&
            summary.cardUsage.validPromotionCode !== "YES" && (
              <div className="flex w-full flex-row justify-between font-semibold text-red-500">
                {t("pages.hostedPagesCheckout.promoCodeInvalid")}
              </div>
            )}
          {summary && (
            <div className="flex w-full flex-row justify-between">
              <div className="font-medium text-gray-500">
                {appointable?.name}
              </div>
              <div className="font-medium text-gray-500">
                {summary?.paidWithSessions
                  ? pluralize(
                      t("page.hostedPagesAppointmentCheckout.creditAmount", {
                        amount: appointable?.sessionCost ?? 0,
                      }),
                      t("page.hostedPagesAppointmentCheckout.creditsAmount", {
                        amount: appointable?.sessionCost ?? 0,
                      }),
                      appointable?.sessionCost ?? 0,
                    )
                  : formatCurrency(
                      summary.cardUsage.upfrontPriceDetails
                        .totalAmountBeforeDiscount,
                      settings.defaultCurrency,
                    )}
              </div>
            </div>
          )}
          {!!summary &&
            !summary?.paidWithSessions &&
            "promotionUpfrontAmount" in summary.cardUsage &&
            summary.cardUsage.promotionUpfrontAmount !== undefined && (
              <div className="text-success-600 dark:text-success-400 flex w-full flex-row justify-between">
                <div className="font-medium">
                  {t("pages.hostedPagesCheckout.upfrontDiscount")}
                </div>
                <div className="font-medium">
                  {summary.cardUsage.promotionUpfrontDiscountType === "CURRENCY"
                    ? formatCurrency(
                        -summary.cardUsage.promotionUpfrontAmount,
                        settings.defaultCurrency,
                      )
                    : `-${summary.cardUsage.promotionUpfrontAmount}%`}
                </div>
              </div>
            )}

          {summary && (
            <div className="dark:text-gray-0 flex w-full flex-row items-center justify-between text-gray-950">
              <div className="text-xl font-bold">
                {t("pages.hostedPagesCheckout.amountDue")}
              </div>
              <div className="text-xl font-bold">
                {summary?.paidWithSessions
                  ? pluralize(
                      t("page.hostedPagesAppointmentCheckout.creditAmount", {
                        amount: appointable?.sessionCost ?? 0,
                      }),
                      t("page.hostedPagesAppointmentCheckout.creditsAmount", {
                        amount: appointable?.sessionCost ?? 0,
                      }),
                      appointable?.sessionCost ?? 0,
                    )
                  : formatCurrency(
                      summary.cardUsage.upfrontPriceDetails.totalAmountToPay,
                      settings.defaultCurrency,
                    )}
              </div>
            </div>
          )}
        </div>

        {!invoice && summary && !appointmentHasBeenPaid && (
          <div className="flex w-full flex-col gap-1">
            {!arePaymentMethodsLoading &&
              !currentPaymentMethodId &&
              !isFree &&
              !summary.paidWithSessions && (
                <ErrorMessage
                  text={t("pages.hostedPagesCheckout.selectPaymentMethod")}
                />
              )}
            {summary.paidWithSessions ? (
              <Button
                intent="secondary"
                className="w-full"
                disabled={
                  !summary ||
                  summary.sessionPackUsage.result === "NOT_ENOUGH_SESSIONS"
                }
                onClick={onPay}
              >
                {isFree
                  ? t("common.getForFree")
                  : pluralize(
                      t("pages.HostedPagesAppointmentCheckout.deductAmount", {
                        amount: appointable?.sessionCost ?? 0,
                      }),
                      t("pages.HostedPagesAppointmentCheckout.deductAmount", {
                        amount: appointable?.sessionCost ?? 0,
                      }),
                      appointable?.sessionCost ?? 0,
                    )}
              </Button>
            ) : (
              <Button
                intent="secondary"
                className="w-full"
                disabled={(!currentPaymentMethodId && !isFree) || !summary}
                onClick={onPay}
              >
                {isFree
                  ? t("common.getForFree")
                  : t("common.payAmount", {
                      amount: formatCurrency(
                        summary?.cardUsage.upfrontPriceDetails.totalAmountToPay,
                        settings.defaultCurrency,
                      ),
                    })}
              </Button>
            )}
          </div>
        )}
      </div>
      <HostedPagesQuickPurchaseOverlay
        closeOverlay={closeOverlay}
        purchaseFinishedButton={
          <Button
            link={createSiteOrEmbedLink(
              RouteFeature.AppointableIdCheckout.replace(
                ":appointableId",
                appointable?.id?.toString() ?? "",
              )
                .replace(":appointableId", appointableId.toString())
                .replace(":staffOrFacilityId", staffOrFacilityId)
                .replace(":timestamp", timestamp ?? ""),
            )}
          >
            {t("pages.hostedPagesAppointmentCheckout.proceedToBooking")}
          </Button>
        }
      />
    </div>
  );
};
