import { useAutoAnimate } from "@formkit/auto-animate/react";
import { faQuestionCircle } from "@fortawesome/free-regular-svg-icons";
import { FontAwesomeIcon } from "@fortawesome/react-fontawesome";
import { useAppointmentSummary } from "@gymflow/api";
import { PARAMETER_DATE_FORMAT_WITHOUT_TZ } from "@gymflow/common";
import { cn, formatCurrency, pluralize } from "@gymflow/helpers";
import {
  AppointableDtoPricingModel,
  AppointmentPaymentStatus,
  CustomPaymentCategoryDTO,
  UserMemberPublicDTO,
  UserMemberSubscriptionBean,
} from "@gymflow/types";
import classNames from "classnames";
import moment from "moment-timezone";
import { useEffect, useMemo } from "react";

import { useClubSettings } from "../../../providers";
import useGymflowModels from "../../../store";
import {
  ChangeCalculator,
  CustomPaymentCategorySelectInput,
  InputWithSaveButton,
  SelectInputOptionType,
  Spinner,
  Tabs,
  TabType,
} from "../../atoms";
import CustomTooltip from "../../atoms/CustomTooltip";
import CashIcon from "../../atoms/icons/CashIcon";
import { Coins03Icon } from "../../atoms/icons/Coins03Icon";
import { CreditCard02Icon } from "../../atoms/icons/CreditCard02Icon";
import DashedIcon from "../../atoms/icons/DashedIcon";
import { PaymentMethodPicker } from "../PaymentMethodPicker";
import { PaymentStatusBadge } from "./PaymentStatusBadge";
import { PaymentUnitType } from "./SideBarAppointment";

// TODO: need to refactor (whole file)
export function AppointmentPayment({
  appointmentId,
  appointableId,
  paymentStatus,
  appointablePrice,
  appointableSessionCost,
  member,
  setPaymentMethodId,
  paymentMethodId,
  setPaymentUnit,
  paymentUnit,
  setPromoCode,
  promoCode,
  appointableName,
  pricingModel,
  inPersonPaymentMethod,
  setInPersonPaymentMethod,
}: {
  paymentStatus: AppointmentPaymentStatus;
  appointablePrice?: number;
  appointableSessionCost?: number;
  paymentUpfront: boolean;
  appointmentId: number;
  appointableId: number;
  member: UserMemberPublicDTO;
  cardRequired: boolean;
  setPaymentMethodId: React.Dispatch<React.SetStateAction<string | undefined>>;
  paymentMethodId?: string;
  setPaymentUnit: React.Dispatch<React.SetStateAction<PaymentUnitType>>;
  paymentUnit: PaymentUnitType;
  setPromoCode: React.Dispatch<React.SetStateAction<string | undefined>>;
  promoCode?: string;
  appointableName: string;
  pricingModel: AppointableDtoPricingModel;
  inPersonPaymentMethod:
    | SelectInputOptionType<CustomPaymentCategoryDTO>
    | undefined;
  setInPersonPaymentMethod: React.Dispatch<
    SelectInputOptionType<CustomPaymentCategoryDTO> | undefined
  >;
}) {
  const [parent] = useAutoAnimate();
  const { api } = useGymflowModels();
  const {
    data: summary,
    isFetching: isLoadingSummary,
    isSuccess,
  } = useAppointmentSummary(
    {
      api,
      opts: {
        appointableId,
        userMemberId: member.id,
        appointmentPaymentPostDTO: {
          appointmentId,
          paidWithSessions: paymentUnit === "CREDITS",
          promotionCode: !!promoCode ? promoCode : undefined,
        },
      },
    },
    { enabled: !!paymentUnit },
  );

  const settings = useClubSettings();

  useEffect(() => {
    const getDefaultState = (): PaymentUnitType => {
      if (!isSuccess || paymentUnit !== undefined) {
        return;
      }
      if (pricingModel === "FREE") {
        return "ONLINE";
      }
      if (appointableSessionCost === undefined) {
        return "ONLINE";
      }

      if (appointablePrice === undefined) {
        return "CREDITS";
      }

      if (
        summary?.paidWithSessions &&
        summary.sessionPackUsage.result !== "NOT_ENOUGH_SESSIONS"
      ) {
        return "CREDITS";
      }
      return "ONLINE";
    };
    let result = getDefaultState();
    if (result) {
      setPaymentUnit(result);
    }
  }, [
    appointablePrice,
    appointableSessionCost,
    isSuccess,
    paymentUnit,
    pricingModel,
    setPaymentUnit,
    summary,
  ]);

  const tabs = useMemo((): TabType<PaymentUnitType>[] => {
    const baseTabs: TabType<PaymentUnitType>[] = [
      {
        id: "ONLINE",
        label: (isActive) => (
          <>
            <CreditCard02Icon
              className="h-7 w-7"
              pathClassName={cn("stroke-1", {
                "stroke-secondary-700": isActive,
              })}
            />
            <div className="hidden sm:block">Online</div>
          </>
        ),
      },
      {
        id: "CREDITS",
        label: (isActive) => (
          <>
            <Coins03Icon
              className="h-7 w-7"
              pathClassName={cn("stroke-1", {
                "stroke-secondary-700": isActive,
                "stroke-gray-500": !isActive,
              })}
            />
            <div className="hidden sm:block">Credits</div>
          </>
        ),
      },
      {
        id: "IN_PERSON",
        label: (isActive) => (
          <>
            <CashIcon
              className="h-7 w-7"
              pathClassName={cn({
                "fill-secondary-700": isActive,
              })}
            />
            <div className="hidden sm:block">In Person</div>
          </>
        ),
      },
    ];

    return baseTabs.filter((tab) => {
      if (tab.id === "CREDITS" && appointableSessionCost === undefined) {
        return false;
      }
      if (
        (tab.id === "ONLINE" || tab.id === "IN_PERSON") &&
        appointablePrice === undefined
      ) {
        return false;
      }
      if (tab.id === "IN_PERSON" && settings.customPaymentsEnabled === false) {
        return false;
      }
      return true;
    });
  }, [
    appointableSessionCost,
    appointablePrice,
    settings.customPaymentsEnabled,
  ]);

  const isOnlineOrInPerson =
    paymentUnit === "ONLINE" || paymentUnit === "IN_PERSON";

  const renderUnpaid = () => {
    if (paymentStatus !== "UNPAID") {
      return null;
    }
    return (
      <div>
        <div className="shadow-xs flex flex-col gap-5 rounded-2xl border border-gray-200 bg-gray-50 p-4">
          <Tabs
            tabs={tabs}
            className="justify-evenly gap-3 border-b-gray-300 pb-5"
            tabClassName="py-2.5 px-3 rounded-xl max-w-none w-full items-center justify-center bg-gray-0 border border-gray-200"
            activeTabClassName="bg-secondary-25 border border-secondary-700"
            activeTabLabelClassName="text-secondary-700"
            activeTabId={paymentUnit}
            setActiveTabId={(id) => setPaymentUnit(id)}
          />
          <div ref={parent}>
            {paymentUnit === "ONLINE" && (
              <PaymentMethodPicker
                userMember={member}
                currentPaymentMethodId={paymentMethodId}
                onChange={setPaymentMethodId}
                customLabel="Select payment method"
              />
            )}
            {paymentUnit === "CREDITS" && (
              <CreditPackDetails
                sessionPackCalculations={
                  summary?.paidWithSessions === true
                    ? summary.sessionPackUsage.sessionPackUsageCalculations
                    : undefined
                }
                isLoadingSummary={isLoadingSummary}
              />
            )}
            {paymentUnit === "IN_PERSON" && (
              <CustomPaymentCategorySelectInput
                label="Select payment method"
                labelClassName="text-base"
                value={inPersonPaymentMethod}
                onChange={(value) => setInPersonPaymentMethod(value)}
              />
            )}
          </div>
        </div>
        <div className="mt-5 flex flex-col">
          {isOnlineOrInPerson && (
            <>
              <div className="text-sm font-medium text-gray-950">
                Promotional Code
              </div>
              <InputWithSaveButton
                onChange={(newPromoCode) => {
                  setPromoCode(newPromoCode);
                }}
                value={
                  promoCode &&
                  summary?.paidWithSessions === false &&
                  summary.cardUsage.validPromotionCode === "YES"
                    ? promoCode
                    : ""
                }
                isLoading={isLoadingSummary}
              />
              <div
                className={classNames("text-error-600", {
                  hidden: !(
                    promoCode &&
                    summary?.paidWithSessions === false &&
                    summary.cardUsage.validPromotionCode !== "YES"
                  ),
                })}
              >
                Promotion code is not valid.
              </div>
            </>
          )}
          <DashedIcon className="my-6 gap-2" />
          <div className="flex justify-between">
            <div className="text-base font-medium text-gray-500">
              {appointableName}
            </div>
            <div className="text-base font-medium text-gray-500">
              {isOnlineOrInPerson &&
                summary?.paidWithSessions === false &&
                formatCurrency(
                  summary.cardUsage.upfrontPriceDetails
                    .totalAmountBeforeDiscount,
                  settings.defaultCurrency,
                )}
              {paymentUnit === "CREDITS" &&
                appointableSessionCost !== undefined &&
                `${appointableSessionCost} ${pluralize(
                  "Credit",
                  "Credits",
                  appointableSessionCost,
                )}`}
            </div>
          </div>
          <div
            className={classNames("flex justify-between", {
              hidden:
                isLoadingSummary ||
                paymentUnit === "CREDITS" ||
                !promoCode ||
                (summary?.paidWithSessions === false &&
                  summary.cardUsage.validPromotionCode !== "YES"),
            })}
          >
            <div>Discount</div>
            <div>
              {summary?.paidWithSessions === false &&
                formatCurrency(
                  summary
                    ? -(summary?.cardUsage.upfrontPriceDetails
                        .totalAmountDifference as number)
                    : 0,
                  settings.defaultCurrency,
                )}
            </div>
          </div>
          <div className="flex justify-between">
            <div className="text-xl font-bold text-gray-950">Amount Due</div>
            <div className="text-xl font-bold text-gray-950">
              {isOnlineOrInPerson &&
                summary?.paidWithSessions === false &&
                formatCurrency(
                  summary.cardUsage.upfrontPriceDetails.totalAmountToPay,
                  settings.defaultCurrency!,
                )}
              {paymentUnit === "CREDITS" &&
                appointableSessionCost !== undefined &&
                `${appointableSessionCost} ${pluralize(
                  "Credit",
                  "Credits",
                  appointableSessionCost,
                )}`}
            </div>
          </div>
        </div>
        {inPersonPaymentMethod?.value.name === "Cash" &&
          paymentUnit === "IN_PERSON" &&
          isOnlineOrInPerson &&
          summary?.paidWithSessions === false && (
            <ChangeCalculator
              className="mt-6"
              price={summary.cardUsage.upfrontPriceDetails.totalAmountToPay}
            />
          )}
      </div>
    );
  };

  return (
    <div className="flex flex-col gap-4 px-8">
      <div className="flex justify-between">
        <div className="font-medium text-gray-900">Payment Status</div>
        <div>
          <PaymentStatusBadge paymentStatus={paymentStatus} />
        </div>
      </div>
      {renderUnpaid()}
    </div>
  );
}

function CreditPackDetails({
  sessionPackCalculations,
  isLoadingSummary,
}: {
  sessionPackCalculations?: {
    readonly sessionPack: UserMemberSubscriptionBean;
    readonly numberOfSessionsUsed: number;
  }[];
  isLoadingSummary: boolean;
}) {
  if (isLoadingSummary) {
    return <Spinner />;
  }

  return (
    <div className="flex flex-col gap-2.5">
      <div className="flex gap-3">
        <div className="text-base font-medium text-gray-950">Credit packs</div>
        <CustomTooltip
          message="Charge for this appointment with credits available on the user accounts. Credits will deducted from the pack expiring soonest."
          hideIfEmpty
        >
          <FontAwesomeIcon
            icon={faQuestionCircle}
            className="h-4 w-4 text-gray-500"
          />
        </CustomTooltip>
      </div>
      <div className="flex max-h-48 flex-col gap-2 overflow-y-auto">
        {sessionPackCalculations && sessionPackCalculations?.length > 0 ? (
          sessionPackCalculations?.map(
            ({
              sessionPack: { id, name, sessionsLeft, endDate, sessionPackBean },
            }) => {
              return (
                <div
                  key={id}
                  className="bg-gray-0 flex justify-between rounded-xl border border-gray-200 px-4 py-3"
                >
                  <div className="flex flex-col">
                    <div className="text-sm font-bold text-gray-950">
                      {name}
                    </div>
                    <div className="text-xs font-medium text-gray-500">
                      {`Expiry: ${moment(
                        endDate,
                        PARAMETER_DATE_FORMAT_WITHOUT_TZ,
                      ).format("D MMM, YYYY")}`}
                    </div>
                  </div>
                  <div className="flex items-center gap-1">
                    <div className="text-xl font-bold text-gray-950">
                      {sessionPackBean?.sessionsUnlimited ? "∞" : sessionsLeft}
                    </div>
                    <Coins03Icon className="h-6 w-6" />
                  </div>
                </div>
              );
            },
          )
        ) : (
          <div className="h-8 text-sm font-medium text-gray-500">
            The user has no credits.
          </div>
        )}
      </div>
    </div>
  );
}
