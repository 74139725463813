import { useAutoAnimate } from "@formkit/auto-animate/react";
import { faAngleRight } from "@fortawesome/free-solid-svg-icons";
import { FontAwesomeIcon } from "@fortawesome/react-fontawesome";
import { useQueryAppointableAsPublic } from "@gymflow/api";
import { cn } from "@gymflow/helpers";
import { usePortalRoutes } from "apps/portal/src/hooks";
import { useAuthenticatedUser } from "apps/portal/src/providers";
import { LoginOverlayContext } from "apps/portal/src/providers/authentication/loginOverlay/context";
import { RouteFeature } from "apps/portal/src/routes";
import React, { useContext } from "react";
import { useTranslation } from "react-i18next";
import { useHistory, useParams } from "react-router";

import useGymflowModels from "../../../store";
import { Button, Spinner, UsersIcon } from "../../atoms";
import { HostedPagesBackHeader } from "../components/HostedPagesBackHeader";
import useRedirectIfNotAuthenticated from "../useRedirectIfNotAuthenticated";

export type HostedPagesAppointmentSelectProps = {};

export const HostedPagesAppointmentSelect: React.FC<
  HostedPagesAppointmentSelectProps
> = ({}) => {
  const history = useHistory();
  const auth = useAuthenticatedUser();
  const { showSignUpOverlay } = useContext(LoginOverlayContext);
  const { appointableId } = useParams<{ appointableId: string }>();
  const { createSiteOrEmbedLink, isEmbed } = usePortalRoutes();
  useRedirectIfNotAuthenticated(
    createSiteOrEmbedLink(RouteFeature.Appointables),
  );
  const { t } = useTranslation();
  const { api } = useGymflowModels();
  const { data: appointable, isLoading } = useQueryAppointableAsPublic({
    api,
    id: parseInt(appointableId),
  });

  const [parent] = useAutoAnimate();
  const [innerParent] = useAutoAnimate();
  return (
    <div
      className={cn(
        "flex w-full flex-col overflow-y-auto bg-gray-50 dark:bg-gray-800",
        {
          "h-fit track-height": isEmbed,
          "h-full": !isEmbed,
        },
      )}
    >
      <HostedPagesBackHeader
        label={
          isLoading
            ? ""
            : appointable?.availabilityType === "FACILITY"
            ? t("pages.hostedPagesAppointableSelect.facility")
            : t("pages.hostedPagesAppointableSelect.host")
        }
      />
      <div ref={parent} className="flex h-full flex-col items-center">
        {isLoading && <Spinner />}
        {!isLoading && appointable && (
          <div
            ref={innerParent}
            className="flex w-[32rem] max-w-full flex-col items-center gap-2 overflow-y-auto overflow-x-hidden p-6 max-lg:justify-between"
          >
            <Button
              {...(auth.id
                ? {
                    link: createSiteOrEmbedLink(
                      RouteFeature.AppointableIdSelectTime.replace(
                        ":appointableId",
                        appointableId.toString(),
                      ).replace(":staffOrFacilityId", "no-preference"),
                    ),
                  }
                : {
                    onClick: async () => {
                      const { isLoggedIn } = await showSignUpOverlay();
                      if (isLoggedIn) {
                        history.push(
                          createSiteOrEmbedLink(
                            RouteFeature.AppointableIdSelectTime.replace(
                              ":appointableId",
                              appointableId.toString(),
                            ).replace(":staffOrFacilityId", "no-preference"),
                          ),
                        );
                      }
                    },
                  })}
              className="shadow-xs flex h-fit w-full flex-row items-center justify-between border-gray-200 px-3 py-2 dark:border-gray-800"
            >
              <div className="flex flex-row items-center gap-4">
                <UsersIcon pathClassName="stroke-gray-500" />
                <div className="flex flex-col items-start">
                  <div className="text-sm font-semibold text-gray-950 dark:text-gray-0">
                    {t("pages.hostedPagesAppointableSelect.noPreference")}
                  </div>
                  <div className="text-sm font-normal text-gray-500">
                    {t(
                      "pages.hostedPagesAppointableSelect.maximumAvailability",
                    )}
                  </div>
                </div>
              </div>
              <FontAwesomeIcon
                icon={faAngleRight}
                className="h-4 w-4 text-gray-500"
              />
            </Button>
            <div className="my-2 flex w-full border-b border-gray-300" />

            {(appointable.availabilityType === "FACILITY"
              ? appointable.appointableFacilities
              : appointable.appointableHosts
            ).map((e) => {
              return (
                <Button
                  key={e.id}
                  className="shadow-xs flex h-fit w-full flex-row items-center justify-between border-gray-200 px-3 py-2 dark:border-gray-800"
                  {...(auth.id
                    ? {
                        link: createSiteOrEmbedLink(
                          RouteFeature.AppointableIdSelectTime.replace(
                            ":appointableId",
                            appointableId.toString(),
                          ).replace(":staffOrFacilityId", e.id.toString()),
                        ),
                      }
                    : {
                        onClick: async () => {
                          const { isLoggedIn } = await showSignUpOverlay();
                          if (isLoggedIn) {
                            history.push(
                              createSiteOrEmbedLink(
                                RouteFeature.AppointableIdSelectTime.replace(
                                  ":appointableId",
                                  appointableId.toString(),
                                ).replace(
                                  ":staffOrFacilityId",
                                  e.id.toString(),
                                ),
                              ),
                            );
                          }
                        },
                      })}
                >
                  <div className="flex flex-row items-center gap-4">
                    <div className="flex h-8 w-8 items-center justify-center overflow-hidden rounded-full border border-gray-100 dark:border-gray-900">
                      {e.picture ? (
                        <img src={e.picture} alt="avatar" />
                      ) : (
                        <div className="flex h-5 w-5 items-center justify-center font-semibold text-gray-500">
                          {"firstName" in e
                            ? e.firstName[0] + e.lastName[0]
                            : e.name[0]}
                        </div>
                      )}
                    </div>
                    <div className="flex flex-col items-start">
                      <div className="text-sm font-semibold text-gray-950 dark:text-gray-0">
                        {"firstName" in e
                          ? `${e.firstName} ${e.lastName}`
                          : e.name}
                      </div>
                    </div>
                  </div>
                  <FontAwesomeIcon
                    icon={faAngleRight}
                    className="h-4 w-4 text-gray-500"
                  />
                </Button>
              );
            })}
          </div>
        )}
      </div>
    </div>
  );
};
