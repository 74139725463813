import { useAutoAnimate } from "@formkit/auto-animate/react";
import {
  memberAsMemberQueryKeys,
  useMemberAsMember,
  useMemberInvoiceListAsMember,
  useMutationLateCancelAsMember,
  useMutationPayInvoiceAsMember,
  useQueryEventOccurrenceAsMember,
  useQueryRulesAsPublic,
} from "@gymflow/api";
import { cn, formatCurrency, rulesHelper } from "@gymflow/helpers";
import { useQueryClient } from "@tanstack/react-query";
import { usePortalRoutes } from "apps/portal/src/hooks";
import useScaModal from "apps/portal/src/hooks/useScaModal";
import {
  useAuthenticatedUser,
  useClubSettings,
} from "apps/portal/src/providers";
import { ToastContext } from "apps/portal/src/providers/ToastProvider/context";
import { RouteFeature } from "apps/portal/src/routes";
import useGymflowModels from "apps/portal/src/store";
import { isAxiosError } from "axios";
import { DateTime } from "luxon";
import { useContext, useEffect, useState } from "react";
import { useTranslation } from "react-i18next";
import { useHistory, useParams } from "react-router";

import { Button, ErrorMessage } from "../../atoms";
import { PaymentMethodPicker } from "../../molecules/PaymentMethodPicker";
import { HostedPagesBackHeader } from "../components/HostedPagesBackHeader";
import useRedirectIfNotAuthenticated from "../useRedirectIfNotAuthenticated";
import { getTimetableQueryParams } from "./components/useTimetableQueryParamsStore";

export type HostedPagesClassLateCancelCheckoutProps = {};

const HostedPagesClassLateCancelCheckout =
  ({}: HostedPagesClassLateCancelCheckoutProps) => {
    const { isEmbed, createSiteOrEmbedLink } = usePortalRoutes();
    const { t } = useTranslation();
    const [currentInvoiceId, setCurrentInvoiceId] = useState<string>();
    const showScaModal = useScaModal({
      asMember: true,
    });
    const auth = useAuthenticatedUser();

    useRedirectIfNotAuthenticated(
      createSiteOrEmbedLink(
        `${RouteFeature.SiteTimetable}?${getTimetableQueryParams()}`,
      ),
    );
    const { api } = useGymflowModels();
    const settings = useClubSettings();
    const { data: userMember } = useMemberAsMember({
      api,
      memberId: auth.id,
      tz: settings.timezone,
    });
    const { eventOccurrenceId } = useParams<{ eventOccurrenceId: string }>();
    const [currentPaymentMethodId, setCurrentPaymentMethodId] =
      useState<string>();

    const { data: invoices } = useMemberInvoiceListAsMember({
      api,
      opts: {
        extraParams: {
          number: currentInvoiceId,
        },
      },
      enabled: !!currentInvoiceId,
    });
    const invoice = invoices?.content?.[0];

    const { mutateAsync: payFailed } = useMutationPayInvoiceAsMember({
      api,
    });

    const { data: eventOccurrence } = useQueryEventOccurrenceAsMember({
      api,
      id: Number(eventOccurrenceId),
    });
    const currentRsvp = eventOccurrence?.eventRsvpList.find(
      (e) => e.userMember.id === auth.id,
    );
    const lateCancelMutation = useMutationLateCancelAsMember({
      api,
    });
    const { notifyDanger, toast } = useContext(ToastContext);
    const onPay = async () => {
      try {
        if (!currentRsvp) {
          return;
        }
        const result = await lateCancelMutation.mutateAsync({
          rsvpId: currentRsvp.id,
          paymentMethodId: currentPaymentMethodId,
        });
        setCurrentInvoiceId(result.invoiceNumber);
        if (result.invoiceNumber) {
          showScaModal({
            invoiceNumber: result.invoiceNumber,
          });
        }
      } catch (e) {
        if (isAxiosError(e)) {
          notifyDanger(e);
        }
      }
    };
    const queryClient = useQueryClient();

    const history = useHistory();
    useEffect(() => {
      if (invoice?.status === "PAID") {
        toast({
          message: t("pages.hostedPagesCheckout.invoicePaid"),
          description: t("pages.hostedPagesCheckout.invoicePaidDescription"),
          intent: "success",
        });
        setTimeout(() => {
          history.goBack();
        }, 1500);
      }
    }, [history, invoice?.status, t, toast]);
    const [parent] = useAutoAnimate();
    const paymentsLoadingStatus = queryClient.getQueryState(
      memberAsMemberQueryKeys.paymentMethods(settings.clubId, auth.id),
    )?.status;
    const { data: activityRules } = useQueryRulesAsPublic({
      api,
      params: eventOccurrence
        ? {
            activityId: eventOccurrence?.event.activity.id,
          }
        : undefined,
    });
    const rules = rulesHelper.mapRuleGroupToClassRules(activityRules);
    const arePaymentMethodsLoading =
      !paymentsLoadingStatus || paymentsLoadingStatus === "loading";
    return (
      <div
        className={cn(
          "track-height flex w-full flex-col items-center overflow-y-auto bg-gray-50 dark:bg-gray-800",
          {
            "h-full": !isEmbed,
            "h-fit": isEmbed,
          },
        )}
      >
        <HostedPagesBackHeader
          label={t("pages.hostedPagesClassesCheckout.cancelBooking")}
        />
        <div
          ref={parent}
          className="flex h-fit w-full max-w-lg flex-col gap-4 px-4 py-6"
        >
          {invoice && (
            <div className="flex w-full flex-col items-center gap-y-3">
              <div className="dark:text-gray-0 text-3xl font-bold text-gray-950">
                {formatCurrency(invoice.totalAmount, settings.defaultCurrency)}
              </div>
              <div className="flex flex-col items-center gap-1">
                {invoice.status === "PAID" && (
                  <>
                    <div className="text-success-600 dark:text-success-400 text-center font-bold">
                      Payment Successful
                    </div>
                    <div className="font-medium text-gray-600 dark:text-gray-400">
                      {DateTime.fromISO(
                        invoice.issueDate ?? new Date().toISOString(),
                      ).toLocaleString(DateTime.DATE_MED)}
                    </div>
                  </>
                )}
                {invoice.status === "PAST_DUE" && (
                  <div className="flex flex-col items-center gap-3">
                    <div className="text-error-600 dark:text-error-400 text-center font-bold">
                      Payment Failed
                    </div>
                    <div className="font-medium text-gray-600 dark:text-gray-400">
                      <Button
                        onClick={async () => {
                          const newInvoice = await payFailed({
                            invoiceNumber: invoice.number,
                            amount: invoice.totalAmount,
                            paymentMethodId: currentPaymentMethodId,
                          });
                          showScaModal({
                            invoiceNumber: newInvoice.number,
                          });
                        }}
                      >
                        Retry Payment
                      </Button>
                    </div>
                  </div>
                )}
                {invoice.status === "AWAITING_AUTHORIZATION" && (
                  <div className="text-warning-600 text-center font-bold">
                    Payment Awaiting Authorization
                  </div>
                )}
              </div>
            </div>
          )}
          {userMember && (
            <div className="max-w-full">
              <PaymentMethodPicker
                userMember={userMember}
                currentPaymentMethodId={currentPaymentMethodId}
                onChange={setCurrentPaymentMethodId}
                disabled={!!invoice}
                asMember
              />
            </div>
          )}
          <div className="flex w-full border-b-2 border-dashed border-gray-400 dark:border-gray-600" />
          <div className="flex w-full flex-col gap-2">
            {rules.bookingCancellationRule && (
              <div className="flex w-full flex-row justify-between">
                <div className="font-medium text-gray-500">
                  {t("pages.hostedPagesClassesCheckout.lateCancelationFee")}
                </div>
                <div className="font-medium text-gray-500">
                  {formatCurrency(
                    rules.bookingCancellationRule.cancellationFee,
                    settings.defaultCurrency,
                  )}
                </div>
              </div>
            )}

            {rules.bookingCancellationRule && (
              <div className="dark:text-gray-0 flex w-full flex-row items-center justify-between text-gray-950">
                <div className="text-xl font-bold">
                  {t("pages.hostedPagesClassesCheckout.amountDue")}
                </div>
                <div className="text-xl font-bold">
                  {formatCurrency(
                    rules.bookingCancellationRule.cancellationFee,
                    settings.defaultCurrency,
                  )}
                </div>
              </div>
            )}
          </div>
          {!invoice && (
            <div className="flex w-full flex-col gap-1">
              {!arePaymentMethodsLoading && !currentPaymentMethodId && (
                <ErrorMessage text="Please select a payment method to continue" />
              )}
              <Button
                intent="secondary"
                className="w-full"
                disabled={
                  !rules.bookingCancellationRule || !currentPaymentMethodId
                }
                onClick={onPay}
              >
                {`Pay ${
                  rules.bookingCancellationRule
                    ? formatCurrency(
                        rules.bookingCancellationRule.cancellationFee,
                        settings.defaultCurrency,
                      )
                    : ""
                }
                `}
              </Button>
            </div>
          )}
        </div>
      </div>
    );
  };

export default HostedPagesClassLateCancelCheckout;
