import { useAutoAnimate } from "@formkit/auto-animate/react";
import { useState } from "react";
import { useTranslation } from "react-i18next";

import { Switch } from "../Switch";
import { CheckboxInput } from "./CheckboxInput";

export type NotificationPreferencesProps = {
  onChange: (checked: boolean) => void;
  onEmailCommunicationChange: (checked: boolean) => void;
  onSmsCommunicationChange: (checked: boolean) => void;
  emailCommunicationValue: boolean;
  smsCommunicationValue: boolean;
};

const NotificationPreferences = ({
  onChange,
  onEmailCommunicationChange,
  onSmsCommunicationChange,
  emailCommunicationValue,
  smsCommunicationValue,
}: NotificationPreferencesProps) => {
  const { t } = useTranslation();
  const [showReceiveNotifications, setShowReceiveNotifications] =
    useState(false);
  const [parent] = useAutoAnimate();
  return (
    <div
      ref={parent}
      className="rounded-xl border border-gray-200 bg-gray-0 p-3 dark:border-gray-800 dark:bg-gray-800"
    >
      <div className="flex flex-row justify-between">
        <div>
          <div className="flex text-sm font-semibold text-gray-950 dark:text-gray-0">
            {t("components.forms.notifications.title")}
          </div>
          <div className="flex text-xs text-gray-500">
            {t("components.forms.notifications.description")}
          </div>
        </div>
        <Switch
          className="-mr-2 scale-75"
          value={showReceiveNotifications}
          onChange={() => {
            onChange(!showReceiveNotifications);
            setShowReceiveNotifications(!showReceiveNotifications);
          }}
        />
      </div>

      {showReceiveNotifications && (
        <div className="mt-2 flex flex-row items-center gap-4 border-t border-gray-300  pt-2 dark:border-gray-700">
          <div className="flex flex-row items-center gap-2 font-medium text-gray-500">
            <CheckboxInput
              containerClassName="gap-1"
              label={t("components.forms.notifications.emailCommunication")}
              checked={emailCommunicationValue}
              onChange={onEmailCommunicationChange}
            />
          </div>
          <div className="flex flex-row items-center gap-2 font-medium text-gray-500">
            <CheckboxInput
              containerClassName="gap-1"
              label={t("components.forms.notifications.smsCommunication")}
              checked={smsCommunicationValue}
              onChange={onSmsCommunicationChange}
            />
          </div>
        </div>
      )}
    </div>
  );
};

export default NotificationPreferences;
