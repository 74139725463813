import {
  useQueryActivityCategoryListAsMember,
  useQueryActivityCategoryListAsPublic,
  useQueryActivityListAsMember,
  useQueryActivityListAsPublic,
  useQueryFacilityListAsPublic,
} from "@gymflow/api";
import { useAuthenticatedUser } from "apps/portal/src/providers";
import useGymflowModels from "apps/portal/src/store";
import React from "react";
import { useTranslation } from "react-i18next";

import { LabeledForm, Spinner } from "../../../atoms";
import { BadgePicker } from "../../../atoms/BadgePicker";
import { HostedPagesFilterPopover } from "../../components/HostedPagesFilterPopover";

export type CreditPackType = "CLASSES" | "APPOINTMENTS";
export type HostedPagesClassesFilterPopoverProps = {
  filter: {
    eventHostId: string[];
    facilityId: number[];
    activityId: number[];
    activityCategoryId: number[];
  };
  onChange: (newFilter: HostedPagesClassesFilterPopoverProps["filter"]) => void;
};

export const HostedPagesClassesFilterPopover: React.FC<
  HostedPagesClassesFilterPopoverProps
> = ({ filter, onChange }) => {
  const auth = useAuthenticatedUser();
  const { t } = useTranslation();
  const { api } = useGymflowModels();
  const useQueryActivityListQueryOptions: Parameters<
    typeof useQueryActivityListAsPublic
  >[0] = {
    api,
    opts: {
      status: "ACTIVE",
      size: 200,
      page: 0,
    },
  };
  const activityListQueryAsMember = useQueryActivityListAsMember(
    useQueryActivityListQueryOptions,
    { enabled: !!auth.id },
  );
  const activityListQueryAsPublic = useQueryActivityListAsPublic(
    useQueryActivityListQueryOptions,
    { enabled: !auth.id },
  );
  const { data: activities, isLoading: activitiesLoading } = !!auth.id
    ? activityListQueryAsMember
    : activityListQueryAsPublic;
  const activityCategoryQueryOptions: Parameters<
    typeof useQueryActivityCategoryListAsMember
  >[0] = {
    api,
    filter: {
      size: 200,
      statusList: ["ACTIVE"],
    },
  };
  const activityCategoryListAsMember = useQueryActivityCategoryListAsMember(
    activityCategoryQueryOptions,
    { enabled: !!auth.id },
  );
  const activityCategoryListAsPublic = useQueryActivityCategoryListAsPublic(
    activityCategoryQueryOptions,
    { enabled: !auth.id },
  );
  const { data: activityCategories, isLoading: activityCategoriesLoading } =
    !!auth.id ? activityCategoryListAsMember : activityCategoryListAsPublic;
  const { data: facilities, isLoading: facilitiesLoading } =
    useQueryFacilityListAsPublic({
      api,
      opts: {
        extraParams: {
          status: "ACTIVE",
        },
        limit: 200,
        page: 0,
      },
    });
  const hasFiltersApplied =
    filter.activityCategoryId.length > 0 ||
    filter.activityId.length > 0 ||
    filter.eventHostId.length > 0 ||
    filter.facilityId.length > 0;
  return (
    <HostedPagesFilterPopover.Container>
      <HostedPagesFilterPopover.Trigger isFilterEmpty={!hasFiltersApplied} />
      <HostedPagesFilterPopover.Content>
        <HostedPagesFilterPopover.ContentHeader
          isFilterEmpty={!hasFiltersApplied}
          onReset={() => {
            onChange({
              eventHostId: [],
              facilityId: [],
              activityId: [],
              activityCategoryId: [],
            });
          }}
        />
        <div className="flex max-w-full flex-col gap-4 p-4 pt-0">
          <LabeledForm
            label={t("page.hostedPagesClassesFilter.class")}
            className="gap-y-2"
          >
            {activitiesLoading && <Spinner />}
            {!activitiesLoading && activities && (
              <BadgePicker
                className="max-w-[80vw] lg:max-w-full"
                isMulti
                hasAllOption
                options={activities.content.map((e) => ({
                  label: e.name,
                  value: e.id,
                }))}
                onChange={(newValue) => {
                  onChange({
                    ...filter,
                    activityId: newValue,
                  });
                }}
                value={filter.activityId}
              />
            )}
          </LabeledForm>
          <LabeledForm
            label={t("page.hostedPagesClassesFilter.classCategory")}
            className="gap-y-2"
          >
            {activityCategoriesLoading && <Spinner />}
            {!activityCategoriesLoading && activityCategories && (
              <BadgePicker
                className="max-w-[80vw] lg:max-w-full"
                isMulti
                hasAllOption
                options={activityCategories.content.map((e) => ({
                  label: e.name,
                  value: e.id,
                }))}
                onChange={(newValue) => {
                  onChange({
                    ...filter,
                    activityCategoryId: newValue,
                  });
                }}
                value={filter.activityCategoryId}
              />
            )}
          </LabeledForm>
          <LabeledForm
            label={t("page.hostedPagesClassesFilter.facility")}
            className="gap-y-2"
          >
            {facilitiesLoading && <Spinner />}
            {!facilitiesLoading && facilities && (
              <BadgePicker
                className="max-w-[80vw] lg:max-w-full"
                isMulti
                hasAllOption
                options={facilities.content.map((e) => ({
                  label: e.name,
                  value: e.id,
                }))}
                onChange={(newValue) => {
                  onChange({
                    ...filter,
                    facilityId: newValue,
                  });
                }}
                value={filter.facilityId}
              />
            )}
          </LabeledForm>
        </div>
      </HostedPagesFilterPopover.Content>
    </HostedPagesFilterPopover.Container>
  );
};
