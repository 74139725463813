import { memberAsMemberQueryKeys } from "@gymflow/api";
import { portalIsLocalhost } from "@gymflow/helpers";
import { useQueryClient } from "@tanstack/react-query";
import { useContext, useEffect } from "react";
import { useTranslation } from "react-i18next";

import { ModalContext, ModalWrapper } from "../../providers";
import { BankIcon } from "../atoms/icons/BankIcon";

export type ScaModalAsMemberProps = {
  scaRedirectUrl: string;
  onComplete?: () => void;
};

const ScaModalAsMember = ({
  scaRedirectUrl,
  onComplete,
}: ScaModalAsMemberProps) => {
  const queryClient = useQueryClient();
  const { t } = useTranslation();
  const { popModal } = useContext(ModalContext);

  useEffect(() => {
    if (!portalIsLocalhost()) return;
    const listener = (e: KeyboardEvent) => {
      if (e.key === "Escape") {
        queryClient.invalidateQueries(memberAsMemberQueryKeys.all());
        popModal();
      }
    };
    document.addEventListener("keydown", listener);
    return () => {
      document.removeEventListener("keydown", listener);
    };
  }, [popModal, queryClient]);
  return (
    <ModalWrapper className="flex h-[80vh] flex-col gap-4" onCancel={() => {}}>
      <div className="flex flex-col items-center justify-center gap-4">
        <div className="bg-secondary-50 flex h-16 w-16 items-center justify-center rounded-full">
          <div className="bg-secondary-100 flex h-12 w-12 items-center justify-center rounded-full">
            <BankIcon pathClassName="stroke-secondary-500" />
          </div>
        </div>
        <div className="flex flex-col items-center gap-1">
          <div className="text-center text-xl font-bold text-gray-950 dark:text-gray-0">
            {t("page.scaModal.title")}
          </div>
          <div className="text-center text-lg font-medium text-gray-500">
            {t("page.scaModal.subTitle")}
          </div>
        </div>
      </div>
      <iframe
        className="flex h-full w-full"
        title={t("pages.hostedPagesCheckout.paymentAuthorization")}
        src={scaRedirectUrl}
        onLoad={(e) => {
          const iframe = e.target as HTMLIFrameElement;
          try {
            // This operation `throws` when the iframe domain is not our own
            // @ts-expect-error
            // eslint-disable-next-line @typescript-eslint/no-unused-vars
            const _ = iframe.contentWindow?.location.host;
            queryClient.invalidateQueries(memberAsMemberQueryKeys.all());
            onComplete?.();
            popModal();
          } catch {}
        }}
      />
    </ModalWrapper>
  );
};

export default ScaModalAsMember;
