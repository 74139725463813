import { ApiType } from "@gymflow/common/lib/api/ApiType";
import { useMutation, useQueryClient } from "@tanstack/react-query";

import { memberAsMemberQueryKeys } from "./memberAsMemberQueryKeys";

/*** @deprecated - use useMutationMemberEditAsMemberNew instead*/
export function useMutationMemberEditAsMember({ api }: { api: ApiType }) {
  const queryClient = useQueryClient();

  const mutation = useMutation({
    mutationFn: ({
      memberId,
      clubId,
      patchedFields,
    }: {
      memberId: string;
      clubId: number;
      patchedFields: { [k: string]: any };
    }) => api.profileApi.update(memberId, clubId, patchedFields),
    onSuccess: () => {
      queryClient.invalidateQueries({
        queryKey: memberAsMemberQueryKeys.all(),
      });
    },
  });
  return mutation;
}
