import { usePromotionEdit } from "@gymflow/api";
import { cn } from "@gymflow/helpers";
import { cva, type VariantProps } from "class-variance-authority";
import React, { useContext } from "react";
import { useHistory } from "react-router-dom";

import { usePortalRoutes } from "../../../hooks";
import { ModalContext, ModalWrapper } from "../../../providers";
import { ToastContext } from "../../../providers/ToastProvider/context";
import { RouteFeature, RouteLayout } from "../../../routes";
import useGymflowModels from "../../../store";
import { marketingRoute } from "../../../views/Marketing";
import { Button } from "../../atoms";
import { QuickActionsIcon } from "../../atoms/icons/QuickActionsIcon";

export interface PromotionQuickActionsButtonProps
  extends VariantProps<typeof PromotionQuickActionButtonVariants> {
  label?: string;
  className?: string;
  promoCodeId: number;
  isPromoCodeActive: boolean;
}
const PromotionQuickActionButtonVariants = cva(["h-11"], {
  variants: {
    variant: {
      secondary: [
        "bg-secondary-500 hover:bg-secondary-400 active:bg-secondary-600 text-gray-0",
      ],
      default: [],
    },
    size: {
      icon: ["w-11"],
      label: [],
    },
  },
  defaultVariants: {
    variant: "default",
    size: "icon",
  },
});

const PromotionQuickButtonIconVariants = cva(["h-5 w-5"], {
  variants: {
    variant: {
      secondary: ["stroke-white"],
      default: [],
    },
  },
  defaultVariants: {
    variant: "default",
  },
});

export const PromotionQuickActionsButton: React.FC<
  PromotionQuickActionsButtonProps
> = ({ label, variant, className, size, promoCodeId, isPromoCodeActive }) => {
  const { setModal } = useContext(ModalContext);

  return (
    <Button
      onClick={() => {
        setModal(
          <ActionMenuModal
            onCancel={() => setModal(null)}
            promoCodeId={promoCodeId}
            isPromoCodeActive={isPromoCodeActive}
          />,
        );
      }}
      intent={variant}
      className={cn(
        PromotionQuickActionButtonVariants({ variant, size }),
        className,
      )}
    >
      <div className="flex items-center gap-x-2">
        <QuickActionsIcon
          className={cn(PromotionQuickButtonIconVariants({ variant }))}
        />
        {label}
      </div>
    </Button>
  );
};

interface ActionMenuModalProps {
  onCancel: () => void;
  promoCodeId: number;
  isPromoCodeActive: boolean;
}

const ActionMenuModal: React.FC<ActionMenuModalProps> = ({
  onCancel,
  promoCodeId,
  isPromoCodeActive,
}) => {
  const { api } = useGymflowModels();
  const history = useHistory();
  const { createClubLink } = usePortalRoutes();
  const editPromotion = usePromotionEdit({ api });
  const { toast } = useContext(ToastContext);

  return (
    <ModalWrapper className="flex flex-col gap-y-4 !p-6 " onCancel={onCancel}>
      <>
        <div className="flex w-full flex-row items-start justify-between">
          <div className="flex flex-col">
            <div className="flex text-lg font-semibold text-gray-900">
              Action Menu
            </div>
          </div>
        </div>
        <Button
          onClick={() => {
            onCancel();
            history.push(
              createClubLink(
                RouteLayout.Staff,
                RouteFeature.Marketing,
                marketingRoute.Promotions,
                `/form/${promoCodeId}`,
              ),
            );
          }}
          className="flex"
        >
          <div className="flex flex-row items-center gap-x-2">Edit</div>
        </Button>
        <Button
          onClick={async () => {
            if (isPromoCodeActive) {
              await editPromotion.mutateAsync({
                promotionId: promoCodeId,
                patchedFields: { active: false },
              });
              toast({ message: "Promotion enabled successfully." });
            } else {
              await editPromotion.mutateAsync({
                promotionId: promoCodeId,
                patchedFields: { active: true },
              });
              toast({ message: "Promotion disabled successfully." });
            }
            onCancel();
          }}
          className="flex"
        >
          <div className="flex flex-row items-center gap-x-2">
            {isPromoCodeActive ? "Disable" : "Enable"}
          </div>
        </Button>

        <Button intent="primary" onClick={onCancel} className="mt-0 ">
          Close
        </Button>
      </>
    </ModalWrapper>
  );
};
