import { ApiType } from "@gymflow/common/lib/api/ApiType";
import { useMutation, useQueryClient } from "@tanstack/react-query";

import { calendarAsMemberQueryKeys } from "../calendarAsMember";
import { eventOccurrenceAsMemberQueryKeys } from "./eventOccurrenceAsMemberQueryKeys";

export function useMutationAddMemberToOccurrenceAsMember({ api }: { api: ApiType }) {
  const queryClient = useQueryClient();
  return useMutation({
    mutationFn: ({
      userMemberId,
      occurrenceId,
    }: {
      userMemberId: string;
      occurrenceId: number;
    }) =>
      api.customerOccurrenceApi.addAttendeeToRsvp(userMemberId, occurrenceId),
    onSuccess: () => {
      queryClient.invalidateQueries({
        queryKey: eventOccurrenceAsMemberQueryKeys.all(),
      });
      queryClient.invalidateQueries({
        queryKey: calendarAsMemberQueryKeys.eventOccurrences(),
      });
      queryClient.invalidateQueries({
        queryKey: calendarAsMemberQueryKeys.userMemberBookingAgenda(),
      });
    },
  });
}
