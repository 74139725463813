import { useContext, useState } from "react";

import { LoginOverlayContext } from "./context";
import { EmailExistsForm } from "./EmailExistsForm";
import { SignUpFormLoader } from "./SignUpForm";

export function TrySignUpOverlay({
  defaultEmail = "",
  defaultScreen = "TRY_EMAIL",
}: {
  defaultEmail?: string;
  defaultScreen?: ScreenType;
}) {
  const { showLoginOverlay, closeOverlay } = useContext(LoginOverlayContext);
  const [screen, setScreen] = useState<"TRY_EMAIL" | "SIGN_UP_FORM">(
    defaultScreen,
  );
  const [email, setEmail] = useState(defaultEmail);
  return (
    <>
      {screen === "TRY_EMAIL" && (
        <EmailExistsForm
          email={email}
          onSubmit={(newEmail, isAlreadyRegistered) => {
            setEmail(newEmail);
            if (isAlreadyRegistered) {
              showLoginOverlay(newEmail);
            } else {
              setScreen("SIGN_UP_FORM");
            }
          }}
        />
      )}
      {screen === "SIGN_UP_FORM" && (
        <SignUpFormLoader
          initialEmail={email}
          onSuccess={() => {
            closeOverlay({ isLoggedIn: true });
          }}
        />
      )}
    </>
  );
}

type ScreenType = "TRY_EMAIL" | "SIGN_UP_FORM";
