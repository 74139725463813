import { statusColors } from "./statusColors";

export function LeadBadge({ statusName }: { statusName: string }) {
  return (
    <div
      style={{
        background:
          statusName in statusColors
            ? statusColors[statusName]?.badgeBackground
            : "#ECE9FE",
      }}
      className="line-clamp-1 flex w-fit flex-row items-center gap-2 rounded-3xl px-3 py-1"
    >
      <div
        style={{
          borderColor:
            statusName in statusColors
              ? statusColors[statusName]?.badgeCircle
              : "#DDD6FE",
        }}
        className="h-4 w-4 rounded-lg border-4"
      />
      {statusName}
    </div>
  );
}
