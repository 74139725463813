import { ApiType } from "@gymflow/common/lib/api/ApiType";
import { useQuery } from "@tanstack/react-query";

import { ruleQueryKeys } from "./ruleQueryKeys";

export function useQueryRuleGroup({
  api,
  ruleGroupId,
}: {
  api: ApiType;
  ruleGroupId?: number;
}) {
  return useQuery({
    queryKey: ruleQueryKeys.ruleGroup(ruleGroupId),
    queryFn: async () => {
      const result = await api.ruleApi.ruleGroup(ruleGroupId!);
      return result.data;
    },
    enabled: !!ruleGroupId,
  });
}
