import { useAutoAnimate } from "@formkit/auto-animate/react";
import { useMutationClubEditSettings } from "@gymflow/api";
import { cn } from "@gymflow/helpers";
import { ClubSettings } from "@gymflow/types";
import { FormikProvider, useFormik } from "formik";
import { useState } from "react";
import { useTranslation } from "react-i18next";
import { toFormikValidationSchema } from "zod-formik-adapter";

import useGymflowModels from "../../../store";
import {
  Avatar,
  Button,
  ColorInput,
  FormLabeledSwitch,
  FormSelectInput,
  LabeledForm,
  Switch,
  UploadArea,
} from "../../atoms";
import LikeIcon from "../../atoms/icons/LikeIcon";
import {
  BrandingValues,
  BrandingZodSchema,
  defaultShades,
  fontOptions,
} from "./constants";

interface BrandingProps {
  readonly clubSettings: ClubSettings;
}

export function Branding({ clubSettings }: BrandingProps) {
  const { t } = useTranslation();
  const { api } = useGymflowModels();
  const editSettingsMutation = useMutationClubEditSettings({ api });
  const [isInEditMode, setIsInEditMode] = useState(false);

  const formik = useFormik<BrandingValues>({
    enableReinitialize: true,
    initialValues: {
      ...clubSettings,
      font: {
        id: clubSettings.font,
        value: clubSettings.font,
        label: clubSettings.font,
      },
      advancedSettings: !areGrayShadesDefault(clubSettings),
      logo: {
        name: clubSettings.logo || "",
      },
    },
    onSubmit: async (values) => {
      const { logo, ...fields } = values;
      await editSettingsMutation.mutateAsync({
        fields: {
          ...fields,
          font: fields.font.value,
          grayShades: fields.advancedSettings
            ? fields.grayShades
            : defaultShades,
        },
        logo: logo.file ? { name: logo.name, blob: logo.file } : undefined,
      });
    },
    validationSchema: toFormikValidationSchema(BrandingZodSchema),
  });
  const [parent] = useAutoAnimate();
  return (
    <FormikProvider value={formik}>
      <div className="grid grid-cols-1 md:grid-cols-8">
        <div className="md:grid-cols-subgrid md:col-span-3 md:grid">
          <div className="md:col-start-1 md:col-end-3">
            <div className="text-base font-semibold">
              {t("pages.websiteSettings.branding")}
            </div>
            <div className="font-normal text-gray-600">
              {t("pages.websiteSettings.brandingDescription")}
            </div>
          </div>
        </div>
        <div className="bg-gray-0 flex flex-col gap-4 rounded-xl border border-gray-200 p-6 pb-0 shadow-sm md:col-span-5">
          <div className="flex flex-col gap-2">
            <div className="text-sm font-semibold">
              {t("pages.websiteSettings.yourLogo")}
            </div>
            <div className="flex gap-2">
              <div className={cn({ hidden: !formik.values.logo.name })}>
                <Avatar
                  url={formik.values.logo.blob || formik.values.logo.name}
                  className="!h-16 !w-16"
                />
              </div>
              <UploadArea
                disabled={!isInEditMode}
                className="col-span-5 flex-1"
                onChange={(file) => {
                  if (file.blob) {
                    formik.setFieldValue("logo", {
                      name: file.name,
                      blob: file.blob,
                      file: file.file,
                    });
                  }
                }}
                description="PNG or JPG (max. 1500x700)"
                maxHeight={700}
                maxWidth={1500}
              />
            </div>
          </div>
          <div className="grid grid-cols-3 gap-3">
            <LabeledForm label={t("pages.websiteSettings.primaryColor")}>
              <ColorInput
                disabled={!isInEditMode}
                onChange={(newColor) => {
                  formik.setFieldValue("primaryColor", newColor);
                }}
                value={formik.values["primaryColor"]}
              />
            </LabeledForm>
            <FormSelectInput<BrandingValues, string>
              label={t("pages.websiteSettings.font")}
              name="font"
              options={fontOptions}
              disabled={!isInEditMode}
            />
            <LabeledForm
              label={t("pages.websiteSettings.darkMode")}
              tooltip="We'll automatically apply a dark theme to your pages using your primary color."
            >
              <div className="flex h-10">
                <FormLabeledSwitch<BrandingValues>
                  name="darkMode"
                  labelStyle="vertical"
                  disabled={!isInEditMode}
                />
              </div>
            </LabeledForm>
          </div>

          <div
            ref={parent}
            className="flex flex-col gap-4 rounded-xl border border-gray-200 p-4"
          >
            <div className="flex flex-row gap-1">
              <Switch
                disabled={!isInEditMode}
                value={formik.values["advancedSettings"]}
                onChange={(checked) => {
                  formik.setFieldValue("advancedSettings", checked);
                }}
              />
              <div className="font-medium">
                {t("pages.websiteSettings.advancedSettings")}
              </div>
            </div>
            {formik.values["advancedSettings"] && (
              <>
                <div className="bg-secondary-25 flex flex-row gap-2 rounded-lg px-3 py-2">
                  <div className="h-4 w-4 pt-1">
                    <LikeIcon />
                  </div>
                  <div className="text-secondary-700 text-sm font-medium">
                    {t("pages.websiteSettings.advancedSettingsDescription")}
                  </div>
                </div>
                <div className="grid grid-cols-2 gap-3">
                  {[
                    "0",
                    "25",
                    "50",
                    "100",
                    "200",
                    "300",
                    "400",
                    "500",
                    "600",
                    "700",
                    "800",
                    "900",
                    "950",
                  ].map((e) => {
                    return (
                      <LabeledForm
                        key={e}
                        label={`${t("pages.websiteSettings.gray")} ${e}`}
                      >
                        <ColorInput
                          disabled={!isInEditMode}
                          onChange={(newColor) => {
                            formik.setFieldValue(`grayShades.${e}`, newColor);
                          }}
                          value={
                            formik.values["grayShades"][
                              e as keyof (typeof formik.values)["grayShades"]
                            ]
                          }
                        />
                      </LabeledForm>
                    );
                  })}
                </div>
              </>
            )}
          </div>

          <div className="-mx-6 flex justify-end border-t border-t-gray-200 px-6 py-4">
            <Button
              intent={isInEditMode ? "secondary" : "default"}
              size="small"
              onClick={async () => {
                if (isInEditMode) {
                  await formik.submitForm();
                  setIsInEditMode(false);
                } else {
                  setIsInEditMode(true);
                }
              }}
            >
              {isInEditMode ? "Save" : "Edit"}
            </Button>
          </div>
        </div>
      </div>
    </FormikProvider>
  );
}

function areGrayShadesDefault(clubSettings: ClubSettings) {
  return Object.keys(clubSettings.grayShades).every(
    (key) =>
      clubSettings.grayShades[
        key as keyof typeof defaultShades
      ].toLowerCase() ===
      defaultShades[key as keyof typeof defaultShades].toLowerCase(),
  );
}
