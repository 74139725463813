import { cn } from "@gymflow/helpers";
import { Field, FieldProps, FormikProps } from "formik";

import { CheckboxInput, CheckboxInputProps } from "../base";
import { ErrorMessage } from "../ErrorMessage";

type FormCheckboxInputProps<T> = {
  name: keyof T;
  containerClassName?: string;
} & Pick<CheckboxInputProps, "label">;

export const FormCheckboxInput = <T,>({
  name,
  containerClassName,
  ...props
}: FormCheckboxInputProps<T>) => {
  return (
    <Field name={name}>
      {({
        field,
        form: { setFieldValue },
        meta: { touched, error },
      }: FieldProps<boolean, FormikProps<T>>) => {
        const isError = touched && error;
        return (
          <div className={cn("flex flex-col gap-1.5", containerClassName)}>
            <CheckboxInput
              {...props}
              {...field}
              checked={field.value}
              onChange={(checked) => {
                setFieldValue(field.name, checked);
              }}
            />
            {isError && <ErrorMessage text={error} />}
          </div>
        );
      }}
    </Field>
  );
};
