import { ApiType } from "@gymflow/common/lib/api/ApiType";
import { useMutation, useQueryClient } from "@tanstack/react-query";

import { strongCustomerAuthorization } from "./strongCustomerAuthorization";

export function useMutationSendScaEmail({ api }: { api: ApiType }) {
  const queryClient = useQueryClient();
  return useMutation({
    mutationFn: ({ invoiceNumber }: { invoiceNumber: string }) =>
      api.strongCustomerAuthorizationApi.sendEmail(invoiceNumber),
    onSuccess: () => {
      queryClient.invalidateQueries({
        queryKey: strongCustomerAuthorization.all(),
      });
    },
  });
}
