import { RuleClubDTO, RuleGroup } from "@gymflow/types";

import { createPublicAxiosInstance } from "./publicAxiosInstance";

export type RulesPayloadType =
  | {
      activityId: number;
    }
  | {
      appointableId: number;
    }
  | {
      membershipId: number;
    };

export type PublicRuleApiType = ReturnType<typeof publicRuleApi>;

const publicRuleApi = (apiUrl: string, clubId: number) => {
  const clubPrefix = `club/${clubId}/`;
  const axiosInstance = createPublicAxiosInstance({
    apiUrl,
  });

  return {
    getRulesFor(params: RulesPayloadType) {
      return axiosInstance.get<RuleGroup>(`${clubPrefix}hosted/rule-group`, {
        params,
      });
    },
    getAllRules() {
      return axiosInstance.get<RuleClubDTO[]>(`${clubPrefix}hosted/rules`);
    },
    /*** @deprecated - should be removed when redux is removed */
    getValuesByClubId(clubId: number /* unused */) {
      return axiosInstance.get<RuleClubDTO[]>(`${clubPrefix}hosted/rules`);
    },
  };
};

export default publicRuleApi;
