import { useTranslation } from "react-i18next";

type DescriptionBlockProps = {
  isChangeImmediately: boolean;
};

export const DescriptionBlock = ({
  isChangeImmediately,
}: DescriptionBlockProps) => {
  const { t } = useTranslation();

  return (
    <div>
      {isChangeImmediately
        ? t(
            "page.userMember.tab.userMemberAccount.userMemberMembership.membershipCard.modal.changeMembershipModal.descriptionBlock.changeImmediately",
          )
        : t(
            "page.userMember.tab.userMemberAccount.userMemberMembership.membershipCard.modal.changeMembershipModal.descriptionBlock.changeNotImmediately",
          )}
    </div>
  );
};
