import { ApiType } from "@gymflow/common/lib/api/ApiType";
import { useQuery } from "@tanstack/react-query";

import { availabilityAsMemberQueryKeys } from "./availabilityAsMemberQueryKeys";

export function useQueryHostAvailabilityCalculateAsMemberNew({
  api,
  opts,
  enabled = true,
}: {
  api: ApiType;
  opts: Parameters<
    ApiType["customerAvailabilityApi"]["hostCalculateAvailability"]
  >[0];
  enabled?: boolean;
}) {
  return useQuery({
    queryKey: availabilityAsMemberQueryKeys.hostCalculateAvailability(opts),
    queryFn: async () => {
      return (await api.customerAvailabilityApi.hostCalculateAvailability(opts))
        .data;
    },
    enabled,
  });
}
