import { useAutoAnimate } from "@formkit/auto-animate/react";
import { faClose } from "@fortawesome/free-solid-svg-icons";
import { FontAwesomeIcon } from "@fortawesome/react-fontawesome";
import {
  useQueryAppointableAsPublic,
  useQueryCreditPackListAsMember,
  useQueryEventOccurrenceAsMember,
  useQueryMembershipListAsMember,
} from "@gymflow/api";
import { cn } from "@gymflow/helpers";
import useGymflowModels from "apps/portal/src/store";
import { useEffect, useMemo } from "react";
import { useTranslation } from "react-i18next";
import {
  matchPath,
  Route,
  Switch,
  useHistory,
  useParams,
  useRouteMatch,
} from "react-router";

import { Button } from "../atoms";
import { EmptyPage } from "../atoms/EmptyPage";
import HostedPagesOverlay from "./components/HostedPagesOverlay";
import { HostedPagesCheckout } from "./HostedPagesCheckout";
import { HostedPagesCreditPackCard } from "./HostedPagesCreditPack/components/HostedPagesCreditPackCard";
import { HostedPagesCreditPackOverview } from "./HostedPagesCreditPack/HostedPagesCreditPackOverview";
import { HostedPagesMembershipCard } from "./HostedPagesMembership/components/HostedPagesMembershipCard";
import { HostedPagesMembershipOverview } from "./HostedPagesMembership/HostedPagesMembershipOverview";

export type HostedPagesQuickPurchaseProps = {
  onClose: () => void;
};

const HostedPagesQuickPurchase = ({
  onClose,
}: HostedPagesQuickPurchaseProps) => {
  const history = useHistory();
  const { type: typeParam } = useParams<{ type: string }>();
  const type = typeParam === "membership" ? "MEMBERSHIP" : "CREDIT_PACK";
  const { api } = useGymflowModels();
  const { t } = useTranslation();
  const { eventOccurrenceId, appointableId } = useParams<{
    eventOccurrenceId?: string;
    appointableId?: string;
  }>();
  const { data: eventOccurrence } = useQueryEventOccurrenceAsMember({
    api,
    id: eventOccurrenceId ? Number(eventOccurrenceId) : undefined,
  });
  const { data: appointable } = useQueryAppointableAsPublic({
    api,
    id: appointableId ? Number(appointableId) : undefined,
  });
  const { data: creditPacks } = useQueryCreditPackListAsMember({
    api,
    opts: {
      ...(!!eventOccurrence ? { hasActivityCategory: true } : {}),
      ...(!!appointableId ? { hasAppointableCategory: true } : {}),
      status: "ACTIVE",
      size: 200,
      page: 0,
    },
  });
  const matchingCreditPacks = creditPacks?.content.filter(
    (e) =>
      e.activityCategoryIdList.includes(
        eventOccurrence?.event.activity.activityCategory.id ?? -1,
      ) ||
      e.appointableCategoryIdList.includes(
        appointable?.appointableCategory.id ?? -1,
      ),
  );
  const { data: memberships } = useQueryMembershipListAsMember({
    api,
    opts: {
      status: "ACTIVE",
      size: 200,
      page: 0,
    },
  });
  const matchingMemberships = memberships?.content.filter(
    (membership) =>
      membership.membershipAddonList?.some((e) => {
        return (
          e.activityCategoryIdList?.includes(
            eventOccurrence?.event.activity.activityCategory.id ?? -1,
          ) ||
          e.appointableCategoryIdList?.includes(
            appointable?.appointableCategory.id ?? -1,
          )
        );
      }),
  );
  const tabs = useMemo(
    () =>
      [
        ...(matchingCreditPacks && matchingCreditPacks?.length > 0
          ? (["CREDIT_PACK"] as const)
          : []),
        ...(matchingMemberships && matchingMemberships?.length > 0
          ? (["MEMBERSHIP"] as const)
          : []),
      ] as const,
    [matchingCreditPacks, matchingMemberships],
  );
  useEffect(() => {
    if (tabs.length === 1) {
      const tab = (
        {
          CREDIT_PACK: "session-pack",
          MEMBERSHIP: "membership",
        } as const
      )[tabs[0]];
      if (!window.location.pathname.includes(tab)) {
        history.replace(
          (
            {
              CREDIT_PACK: "session-pack",
              MEMBERSHIP: "membership",
            } as const
          )[tabs[0]],
        );
      }
    }
  }, [history, tabs, tabs.length]);
  const [parent] = useAutoAnimate();
  return (
    <div className="flex h-full max-h-full w-full flex-col bg-gray-50 dark:bg-gray-800">
      <div className="bg-gray-0 flex flex-col gap-4 border-b border-gray-300 p-4 dark:border-gray-700 dark:bg-gray-950 lg:px-28">
        <div className="flex flex-row flex-wrap items-center justify-between gap-4">
          <div className="flex items-center gap-4">
            <div className="dark:text-gray-0 text-xl font-medium text-gray-950">
              {t("pages.hostedPagesClassesQuickPurchase.purchaseCredits")}
            </div>
            <div className="hidden flex-row items-center gap-2 lg:flex">
              {tabs.map((e) => {
                return (
                  <Button
                    key={e}
                    size="small"
                    link={e === "CREDIT_PACK" ? "session-pack" : "membership"}
                    className={cn("text-sm transition-all", {
                      "!border-secondary-500 !text-secondary-500": type === e,
                    })}
                  >
                    {t("pages.hostedPagesClassesQuickPurchase.tabs." + e)}
                  </Button>
                );
              })}
            </div>
          </div>
          <Button
            onClick={() => {
              onClose();
            }}
            className="aspect-square h-10 w-10"
            intent="transparent"
          >
            <FontAwesomeIcon
              className="cursor-pointer text-xl text-gray-600"
              icon={faClose}
            />
          </Button>
        </div>
        <div className="flex w-full flex-row gap-2 lg:hidden">
          {tabs.map((e) => {
            return (
              <Button
                key={e}
                className={cn("text-sm transition-all flex-1", {
                  "!border-secondary-500 !text-secondary-500": type === e,
                })}
                link={e === "CREDIT_PACK" ? "session-pack" : "membership"}
              >
                {t("pages.hostedPagesClassesQuickPurchase.tabs." + e)}
              </Button>
            );
          })}
        </div>
      </div>
      <div className="p-4 lg:hidden lg:px-28 lg:pt-6"></div>
      <div
        ref={parent}
        className="flex min-h-[16rem] flex-col gap-4 overflow-y-auto overflow-x-hidden bg-gray-50 p-4 dark:bg-gray-800 lg:px-28 lg:pt-6"
      >
        {tabs.length === 0 && (
          <div className="flex w-80 flex-col items-center self-center pt-32">
            <EmptyPage
              textOverride={t(
                "pages.hostedPagesClassesQuickPurchase.nothingFound",
              )}
              action={
                <div className="-mt-2 flex flex-col items-center gap-2">
                  <div className="text-center text-sm font-normal text-gray-500">
                    {t("pages.hostedPagesClassesQuickPurchase.noPacks")}
                  </div>
                  <Button
                    className="w-fit"
                    onClick={() => {
                      onClose();
                    }}
                  >
                    {t("pages.hostedPagesClassesQuickPurchase.closeThePage")}
                  </Button>
                </div>
              }
            />
          </div>
        )}
        {type === "CREDIT_PACK" &&
          matchingCreditPacks?.map((e) => (
            <HostedPagesCreditPackCard creditPack={e} key={e.id} />
          ))}
        {type === "MEMBERSHIP" &&
          matchingMemberships?.map((e) => (
            <HostedPagesMembershipCard membership={e} key={e.id} />
          ))}
      </div>
    </div>
  );
};
const HostedPagesQuickPurchaseOverlay = ({
  closeOverlay,
  purchaseFinishedButton,
}: {
  closeOverlay: () => void;
  purchaseFinishedButton: React.ReactNode;
}) => {
  const { path } = useRouteMatch();
  const [overlayParent] = useAutoAnimate();
  return (
    <HostedPagesOverlay
      ref={overlayParent}
      isOpen={[
        "/quick-purchase/:type",
        "/quick-purchase/session-pack/:sessionPackId",
        "/quick-purchase/membership/:membershipId",
        "/quick-purchase/session-pack/:sessionPackId/checkout",
        "/quick-purchase/membership/:membershipId/checkout",
      ].some((route) => matchPath(window.location.pathname, `${path}${route}`))}
    >
      <Switch>
        <Route
          path={`${path}/quick-purchase/session-pack/:sessionPackId/checkout`}
        >
          <HostedPagesCheckout
            type="CREDIT_PACK"
            onClose={() => {
              closeOverlay();
            }}
            quickPurchaseFinishedButton={purchaseFinishedButton}
          />
        </Route>
        <Route
          path={`${path}/quick-purchase/membership/:membershipId/checkout`}
        >
          <HostedPagesCheckout
            type="MEMBERSHIP"
            onClose={() => {
              closeOverlay();
            }}
            quickPurchaseFinishedButton={purchaseFinishedButton}
          />
        </Route>
        <Route path={`${path}/quick-purchase/membership/:membershipId`}>
          <HostedPagesMembershipOverview
            onClose={() => {
              closeOverlay();
            }}
          />
        </Route>
        <Route path={`${path}/quick-purchase/session-pack/:sessionPackId`}>
          <HostedPagesCreditPackOverview
            onClose={() => {
              closeOverlay();
            }}
          />
        </Route>
        <Route path={`${path}/quick-purchase/:type`}>
          <HostedPagesQuickPurchase
            onClose={() => {
              closeOverlay();
            }}
          />
        </Route>
      </Switch>
    </HostedPagesOverlay>
  );
};
export default HostedPagesQuickPurchaseOverlay;
