import { useMutationMigrationNewPassword } from "@gymflow/api";
import { useFormik } from "formik";
import { ReactNode, useCallback, useState } from "react";
import { z } from "zod";
import { toFormikValidate, toFormikValidationSchema } from "zod-formik-adapter";

import useGymflowModels from "../../../store";
import {
  Button,
  CardIcon,
  CheckCircleIcon,
  EyeIcon,
  LockIcon,
  TextInput,
} from "../../atoms";
import { Breadcrumbs } from "./Breadcrumbs";

export function MigrationSetPassword({
  needsPaymentMethod,
  invitationToken,
  onSuccess,
}: {
  needsPaymentMethod: boolean;
  invitationToken: string;
  onSuccess: () => void;
}) {
  const pages: {
    name: string;
    icon: (props: { className?: string }) => ReactNode;
  }[] = [
    {
      name: "Password",
      icon: ({ className }) => <CheckCircleIcon pathClassName={className} />,
    },
  ];

  if (needsPaymentMethod) {
    pages.push({
      name: "Card",
      icon: ({ className }) => <CardIcon pathClassName={className} />,
    });
  }

  const { api } = useGymflowModels();
  const { mutateAsync: setNewPassword } = useMutationMigrationNewPassword({
    api,
  });
  const [isPasswordVisible, setIsPasswordVisible] = useState<boolean>(false);
  const formik = useFormik<{ password: string; repeatPassword: string }>({
    initialValues: { password: "", repeatPassword: "" },
    onSubmit: async (values) => {
      try {
        await setNewPassword({
          invitationToken,
          newPassword: values.password,
        });
        onSuccess();
      } catch {}
    },
    validationSchema: toFormikValidationSchema(schema),
    validate: toFormikValidate(schema),
  });

  const togglePasswordVisibility = useCallback(() => {
    setIsPasswordVisible(!isPasswordVisible);
  }, [isPasswordVisible]);

  return (
    <div className="flex flex-col">
      <div className="bg-gray-0 flex justify-center border-b border-b-gray-300 py-6 dark:border-b-gray-700 dark:bg-gray-950">
        <Breadcrumbs pages={pages} />
      </div>
      <div className="flex justify-center">
        <div className="mt-16 flex max-w-sm flex-col items-center justify-center gap-6">
          <div className="flex flex-col items-center gap-1.5">
            <div className="dark:text-gray-25 text-xl font-bold text-gray-950">
              Create password
            </div>
            <div className="text-base font-medium text-gray-500 dark:text-gray-400">
              Create a password to access your account
            </div>
          </div>
          <div className="w-100 flex flex-col gap-4">
            <div className="flex flex-col gap-1.5">
              <label
                className="dark:text-gray-25 !m-0 text-sm font-medium text-gray-950"
                htmlFor="password"
              >
                Password
              </label>
              <div className="relative rounded-md shadow-sm">
                <div className="pointer-events-none absolute inset-y-0 left-0 flex items-center pl-3">
                  <LockIcon
                    className="h-5 w-5"
                    pathClassName=" stroke-gray-500"
                  />
                </div>
                <TextInput
                  placeholder="Password"
                  autoComplete="off"
                  maxLength={128}
                  name="password"
                  type={isPasswordVisible ? "text" : "password"}
                  className="px-10"
                  value={formik.values.password}
                  onChange={({ currentTarget: { value } }) => {
                    formik.setFieldValue("password", value);
                  }}
                />
                <div
                  className="absolute inset-y-0 right-0 flex cursor-pointer items-center pr-3"
                  onClick={togglePasswordVisibility}
                >
                  <EyeIcon
                    className="h-5 w-5"
                    pathClassName="stroke-gray-500"
                  />
                </div>
              </div>
            </div>
            {formik.submitCount > 0 && formik.errors.password && (
              <div className="text-error-600 mt-1.5 text-sm font-normal">
                {formik.errors.password}
              </div>
            )}
            <div className="flex flex-col gap-1.5">
              <label
                className="dark:text-gray-25 !m-0 text-sm font-medium text-gray-950"
                htmlFor="password"
              >
                Repeat Password
              </label>
              <div className="relative rounded-md shadow-sm">
                <div className="pointer-events-none absolute inset-y-0 left-0 flex items-center pl-3">
                  <LockIcon
                    className="h-5 w-5"
                    pathClassName=" stroke-gray-500"
                  />
                </div>
                <TextInput
                  placeholder="Repeat password"
                  autoComplete="off"
                  maxLength={128}
                  name="repeatPassword"
                  type={isPasswordVisible ? "text" : "password"}
                  className="px-10"
                  value={formik.values.repeatPassword}
                  onChange={({ currentTarget: { value } }) => {
                    formik.setFieldValue("repeatPassword", value);
                  }}
                />
                <div
                  className="absolute inset-y-0 right-0 flex cursor-pointer items-center pr-3"
                  onClick={togglePasswordVisibility}
                >
                  <EyeIcon
                    className="h-5 w-5"
                    pathClassName="stroke-gray-500"
                  />
                </div>
              </div>
            </div>
            {formik.submitCount > 0 && formik.errors.repeatPassword && (
              <div className="text-error-600 mt-1.5 text-sm font-normal">
                {formik.errors.repeatPassword}
              </div>
            )}
          </div>
          <div className="w-100 flex">
            <Button
              intent="secondary"
              className="mt-2 flex-1"
              onClick={async () => {
                formik.submitForm();
              }}
              showSpinner={formik.isSubmitting}
            >
              Create password
            </Button>
          </div>
        </div>
      </div>
    </div>
  );
}

const schema = z
  .object({
    password: z.string().min(6, "Password should be at least 6 characters."),
    repeatPassword: z.string(),
  })
  .refine((data) => data.password === data.repeatPassword, {
    message: "Passwords don't match",
    path: ["repeatPassword"],
  });
