import { ApiType } from "@gymflow/common/lib/api/ApiType";
import { EventRsvpStatus } from "@gymflow/types";
import { useMutation, useQueryClient } from "@tanstack/react-query";

import { calendarAsMemberQueryKeys } from "../calendarAsMember";
import { eventOccurrenceAsMemberQueryKeys } from "./eventOccurrenceAsMemberQueryKeys";

export function useMutationUpdateRsvpAsMember({ api }: { api: ApiType }) {
  const queryClient = useQueryClient();

  return useMutation({
    mutationFn: async ({
      rsvpId,
      status,
    }: {
      rsvpId: number;
      status: EventRsvpStatus;
    }) => (await api.customerOccurrenceApi.updateRsvp(rsvpId, status)).data,
    onSuccess: () => {
      queryClient.invalidateQueries({
        queryKey: eventOccurrenceAsMemberQueryKeys.all(),
      });
      queryClient.invalidateQueries({
        queryKey: calendarAsMemberQueryKeys.eventOccurrences(),
      });
      queryClient.invalidateQueries({
        queryKey: calendarAsMemberQueryKeys.userMemberBookingAgenda(),
      });
    },
  });
}
