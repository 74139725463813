import { ApiType } from "@gymflow/common/lib/api/ApiType";
import { utcToZonedTime } from "@gymflow/helpers";
import { MembershipStatusHistoryItem } from "@gymflow/types";
import { useQuery } from "@tanstack/react-query";

import { membershipQueryKeys } from "./membershipQueryKeys";

export function useQueryMembershipStatusHistory({
  api,
  memberId,
  tz,
}: {
  api: ApiType;
  memberId?: string;
  tz: string;
}) {
  const result = useQuery({
    queryKey: membershipQueryKeys.statusHistory(memberId),
    queryFn: async () => {
      const { data } = await api.serviceApi.getStatusHistory(memberId!);

      return data.map((e: MembershipStatusHistoryItem) => {
        e.createdDate = utcToZonedTime(e.createdDate, tz);
        return e;
      });
    },
    enabled: !!memberId,
  });

  return result;
}
