/* eslint-disable no-redeclare */
import {
  useMutationGetInvoice,
  useMutationGetInvoiceAsMember,
} from "@gymflow/api";
import { ApiType } from "@gymflow/common/lib/api/ApiType";
import { useContext } from "react";
import { useTranslation } from "react-i18next";

import ScaModalAsMember from "../components/molecules/ScaModalAsMember";
import { ScaModalAsStaff } from "../components/molecules/ScaModalAsStaff";
import { ModalContext } from "../providers";
import { ToastContext } from "../providers/ToastProvider/context";
import useGymflowModels from "../store";

type MemberParams = { invoiceNumber: string; onComplete?: () => void };
type StaffParams = {
  invoiceNumber: string;
  userMemberId: string;
  onComplete?: () => void;
};
function useScaModal(props: {
  asMember: true;
}): (params: MemberParams) => Promise<void>;
function useScaModal(props: {
  asMember: false;
}): (params: StaffParams) => Promise<void>;

function useScaModal({ asMember }: { asMember: boolean }) {
  const { t } = useTranslation();
  const { api } = useGymflowModels();
  const { toast } = useContext(ToastContext);
  const { stackModal } = useContext(ModalContext);

  const mutationGetInvoiceAsStaff = useMutationGetInvoice({ api });
  const mutationGetInvoiceAsMember = useMutationGetInvoiceAsMember({ api });

  if (asMember) {
    return async ({ invoiceNumber, onComplete }: MemberParams) => {
      const invoice = await mutationGetInvoiceAsMember.mutateAsync({
        invoiceNumber,
      });
      if (invoice.status !== "AWAITING_AUTHORIZATION") {
        if (invoice.status === "PAID") {
          toast({
            message: t("pages.scaModal.invoiceProcessed"),
            type: "small",
          });
        }
        return;
      }
      stackModal(
        <ScaModalAsMember
          scaRedirectUrl={(
            api as ApiType
          ).strongCustomerAuthorizationApi.generateScaRedirectUrl(
            invoiceNumber,
          )}
          onComplete={onComplete}
        />,
      );
    };
  } else {
    return async ({ invoiceNumber, userMemberId, onComplete }: StaffParams) => {
      const invoice = await mutationGetInvoiceAsStaff.mutateAsync({
        invoiceNumber,
        userMemberId,
      });
      if (invoice.status !== "AWAITING_AUTHORIZATION") {
        return;
      }
      setTimeout(() => {
        stackModal(
          <ScaModalAsStaff
            invoiceNumber={invoiceNumber}
            userMemberId={userMemberId}
            onComplete={onComplete}
          />,
        );
      }, 100);
    };
  }
}

export default useScaModal;
