import { cn } from "@gymflow/helpers";

import { OptionImage } from "./OptionImage";

type SelectedOptionItemProps = {
  className?: string;
  disabled?: boolean;
  show: boolean;
  imageUrl?: string;
  label: string;
  subLabel?: string;
  withImageByDefault: boolean;
};

export const SelectedOptionItem = ({
  className,
  disabled = false,
  show,
  withImageByDefault,
  imageUrl,
  label,
  subLabel,
}: SelectedOptionItemProps) => {
  if (!show) return undefined;
  return (
    <div
      className={cn(
        "pointer-events-none absolute inset-y-0.5 left-3.5 right-14 flex items-center gap-2 dark:bg-gray-800",
        {
          "opacity-50 cursor-not-allowed": disabled,
          "cursor-pointer": !disabled,
        },
        className,
      )}
    >
      <OptionImage
        imageUrl={imageUrl}
        label={label}
        withImageByDefault={withImageByDefault}
      />
      <div
        className={cn(
          "truncate text-base font-medium text-gray-950 dark:text-gray-0",
          {
            "text-gray-500": disabled,
          },
        )}
      >
        {label}
      </div>
      {subLabel && (
        <div className="truncate text-base text-gray-500">{subLabel}</div>
      )}
    </div>
  );
};
