import { useQueryAppointableCategoriesAsPublic } from "@gymflow/api";
import useGymflowModels from "apps/portal/src/store";
import React from "react";

import { LabeledForm } from "../../../atoms";
import { BadgePicker } from "../../../atoms/BadgePicker";
import { HostedPagesFilterPopover } from "../../components/HostedPagesFilterPopover";

export type HostedPagesAppointmentsFilterPopoverProps = {
  filter: {
    appointableCategories: number[];
  };
  onChange: (
    newFilter: HostedPagesAppointmentsFilterPopoverProps["filter"],
  ) => void;
};

export const HostedPagesAppointmentsFilterPopover: React.FC<
  HostedPagesAppointmentsFilterPopoverProps
> = ({ filter, onChange }) => {
  const { api } = useGymflowModels();
  const { data: appointableCategories } = useQueryAppointableCategoriesAsPublic(
    {
      api,
      paginationOption: { page: 0, size: 200 },
      filter: {
        statusList: ["ACTIVE"],
      },
    },
  );

  return (
    <HostedPagesFilterPopover.Container>
      <HostedPagesFilterPopover.Trigger
        isFilterEmpty={filter.appointableCategories.length === 0}
      />
      <HostedPagesFilterPopover.Content>
        <HostedPagesFilterPopover.ContentHeader
          isFilterEmpty={filter.appointableCategories.length === 0}
          onReset={() => {
            onChange({
              appointableCategories: [],
            });
          }}
        />
        <div className="flex max-w-full p-4 pt-0">
          <LabeledForm label="Appointable Category" className="gap-y-2">
            <BadgePicker
              className="max-w-[80vw] lg:max-w-full"
              isMulti
              hasAllOption
              options={
                appointableCategories?.content.map((e) => ({
                  label: e.name,
                  value: e.id,
                })) ?? []
              }
              onChange={(newValue) => {
                onChange({
                  ...filter,
                  appointableCategories: newValue,
                });
              }}
              value={filter.appointableCategories}
            />
          </LabeledForm>
        </div>
      </HostedPagesFilterPopover.Content>
    </HostedPagesFilterPopover.Container>
  );
};
