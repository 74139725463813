import { cn, currencies, formatCurrency } from "@gymflow/helpers";
import { useClubSettings } from "apps/portal/src/providers";
import { useState } from "react";
import { useTranslation } from "react-i18next";

import { Input } from "./Input/Input";

type ChangeCalculatorProps = {
  price: number;
  className?: string;
};

export const ChangeCalculator = ({
  price,
  className,
}: ChangeCalculatorProps) => {
  const [cashReceivedInput, setCashReceivedInput] = useState<string>("0");

  const { t } = useTranslation();
  const { defaultCurrency } = useClubSettings();

  return (
    <div
      className={cn(
        "shadow-xs flex flex-col gap-2 rounded-2xl border border-gray-200 bg-gray-50 p-4",
        className,
      )}
    >
      <div className="flex justify-between">
        <div className="text-base font-medium text-gray-950">
          {t("components.base.changeCalculator.cashReceived")} (
          {currencies[defaultCurrency].symbol})
        </div>
        <div className="w-[7.5rem]">
          <Input
            className="text-right"
            type="number"
            value={cashReceivedInput}
            onChange={(e) => setCashReceivedInput(e.target.value)}
          />
        </div>
      </div>
      <div className="flex justify-between">
        <div className="text-base font-medium text-gray-950">
          {t("components.base.changeCalculator.changeDue")}
        </div>
        <div className="text-base font-medium text-gray-950">
          {formatCurrency(Number(cashReceivedInput) - price, defaultCurrency)}
        </div>
      </div>
    </div>
  );
};
