/* eslint-disable react/prop-types */
import { useMemberSubscription } from "@gymflow/api";
import {
  DATE_FORMAT,
  PARAMETER_DATE_ONLY_FORMAT,
  useParseErrors,
} from "@gymflow/common";
import { UserMemberSubscriptionBeanWithMembership } from "@gymflow/types";
import { ModalContext, useClubSettings } from "apps/portal/src/providers";
import useGymflowModels from "apps/portal/src/store";
import { cn } from "libs/common/src/lib/helpers/cn";
import { DateTime } from "luxon";
import moment, { Moment } from "moment";
import React, { useCallback, useContext, useState } from "react";
import Datetime from "react-datetime";

import { ConfirmModal } from "../../../templates";
import { NotPrimaryMembershipWarning } from "../NotPrimaryMembershipWarning";

export interface UpdateContractEndDateMembershipModalProps {
  membership: UserMemberSubscriptionBeanWithMembership;
}

export const UpdateContractEndDateMembershipModal: React.FC<
  UpdateContractEndDateMembershipModalProps
> = ({ membership }) => {
  const todayInDateFormat = moment().format(DATE_FORMAT);
  const { timezone } = useClubSettings();
  const { api } = useGymflowModels();

  const parseError = useParseErrors();
  const { changeContractEndDateMutation } = useMemberSubscription(
    { api, tz: timezone },
    {
      onError: (error) => {
        parseError((error as any)?.response);
      },
    },
  );
  const { hide } = useContext(ModalContext);
  const [newContractEndDate, setNewContractEndDate] = useState<string | null>(
    moment(membership.endContractDate).format(DATE_FORMAT),
  );
  const isValidDate = useCallback(
    (date: Moment) => date.isAfter(moment().add(-1, "day")),
    [],
  );
  return (
    <ConfirmModal
      onConfirm={async () => {
        if (!newContractEndDate) return;
        await changeContractEndDateMutation.mutateAsync({
          subscriptionId: membership.id,
          newDate: moment(newContractEndDate, DATE_FORMAT).format(
            PARAMETER_DATE_ONLY_FORMAT,
          ),
        });
        hide();
      }}
      title="Update Contract End Date"
      onCancel={() => {
        hide();
      }}
    >
      <div className="flex flex-col gap-4">
        <div>
          Select a new contract end date, the current contract end date is:{" "}
          {DateTime.fromISO(membership.endDate).toFormat("dd/MM/yyyy")}
        </div>
        <div
          className={cn(
            "relative flex h-11 w-full cursor-pointer flex-row items-center rounded-lg border border-gray-200 bg-gray-0 text-center text-gray-600 hover:bg-gray-100",
          )}
        >
          <Datetime
            dateFormat={DATE_FORMAT}
            timeFormat={false}
            onChange={(v: Moment | string) => {
              if (typeof v !== "string") {
                if (v.format(DATE_FORMAT) === todayInDateFormat) {
                  setNewContractEndDate(null);
                } else {
                  setNewContractEndDate(v.format(DATE_FORMAT));
                }
              }
            }}
            value={newContractEndDate ?? undefined}
            closeOnSelect
            isValidDate={isValidDate}
            renderDay={(props, currentDate: Moment, selectedDate: Moment) => {
              return (
                <td
                  {...props}
                  className={cn(props?.className, "relative", {
                    "opacity-20": !isValidDate(currentDate),
                    "!bg-secondary-500 hover:!bg-secondary-600 text-gray-0":
                      currentDate?.format(DATE_FORMAT) ===
                      selectedDate?.format(DATE_FORMAT),
                  })}
                >
                  {currentDate.date()}
                </td>
              );
            }}
            className={cn("h-full w-full px-4")}
            inputProps={{
              readOnly: true,
              placeholder: "Today",
              value: newContractEndDate ?? "Today",
              className: cn(
                "h-full w-full bg-gray-0 font-medium border-0 focus:outline-none cursor-pointer text-gray-600 placeholder:text-gray-600",
              ),
            }}
          />
        </div>
        <NotPrimaryMembershipWarning membership={membership} />
      </div>
    </ConfirmModal>
  );
};
