import { useAutoAnimate } from "@formkit/auto-animate/react";
import {
  useQueryAppointableCategoriesAsPublic,
  useQueryAppointableListAsCustomer,
  useQueryAppointableListAsPublic,
} from "@gymflow/api";
import { AppointableDTO } from "@gymflow/types";
import { usePortalRoutes } from "apps/portal/src/hooks";
import { useAuthenticatedUser } from "apps/portal/src/providers";
import React, { useMemo, useState } from "react";
import { useTranslation } from "react-i18next";

import useGymflowModels from "../../../store";
import { Button, Spinner } from "../../atoms";
import { EmptyPage } from "../../atoms/EmptyPage";
import HostedPagesProfileAvatar from "../components/HostedPagesProfileAvatar";
import { SortPopover, SortType } from "../SortPopover";
import HostedPagesAppointableCard from "./components/HostedPagesAppointableCard";
import {
  HostedPagesAppointmentsFilterPopover,
  HostedPagesAppointmentsFilterPopoverProps,
} from "./components/HostedPagesAppointmentsFilterPopover";

export type HostedPagesAppointmentsProps = {};

export const HostedPagesAppointments: React.FC<
  HostedPagesAppointmentsProps
> = ({}) => {
  const { api } = useGymflowModels();
  const { isEmbed } = usePortalRoutes();
  const auth = useAuthenticatedUser();
  const { t } = useTranslation();

  const [filter, setFilter] = useState<
    HostedPagesAppointmentsFilterPopoverProps["filter"]
  >({
    appointableCategories: [],
  });

  const [sort, setSort] = useState<SortType | undefined>("ASC");
  const appointableListQueryOptions = {
    api,
    opts: {
      extraParams: {
        status: "ACTIVE",
        allowOnlineBookings: true,
        "appointableCategory.id": filter.appointableCategories,
      },
      limit: 200,
      page: 0,
      sort: {
        field: "price,sessionCost",
        desc: sort !== "ASC",
      },
    },
  } as const;
  const appointableListQueryAsPublic = useQueryAppointableListAsPublic(
    appointableListQueryOptions,
    { enabled: !auth.id },
  );
  const appointableListQueryAsCustomer = useQueryAppointableListAsCustomer(
    appointableListQueryOptions,
    { enabled: !!auth.id },
  );
  const { data, isLoading, isFetching } = auth.id
    ? appointableListQueryAsCustomer
    : appointableListQueryAsPublic;
  const { data: appointableCategories } = useQueryAppointableCategoriesAsPublic(
    {
      api,
      paginationOption: { page: 0, size: 200 },
      filter: {
        statusList: ["ACTIVE"],
      },
    },
  );

  const appointablesToShow = useMemo(() => {
    const idToCategoryName =
      appointableCategories?.content.reduce(
        (acc, curr) => {
          acc[curr.id] = curr.name;
          return acc;
        },
        {} as Record<number, string>,
      ) ?? {};
    const groups = data?.content?.reduce(
      (acc, curr) => {
        acc[curr.appointableCategory?.id] ??= {
          name: idToCategoryName[curr.appointableCategory?.id],
          appointables: [] as AppointableDTO[],
        };
        acc[curr.appointableCategory?.id].appointables.push(curr);
        return acc;
      },
      {} as Record<string, { name: string; appointables: AppointableDTO[] }>,
    );
    return groups;
  }, [appointableCategories?.content, data?.content]);
  const [parent] = useAutoAnimate();
  return (
    <div className="flex h-full max-h-full flex-col bg-gray-50 dark:bg-gray-800">
      <div className="track-height bg-gray-0 flex flex-col gap-4 border-b border-gray-300 p-4 dark:border-gray-700 dark:bg-gray-950 lg:px-28">
        <div className="flex flex-row flex-wrap items-center justify-between gap-2">
          <div className="flex flex-row items-center gap-4">
            {isEmbed && auth.id ? (
              <HostedPagesProfileAvatar />
            ) : (
              <div className="dark:text-gray-0 text-xl font-medium text-gray-950">
                {t("page.hostedPagesAppointments.title")}
              </div>
            )}
          </div>
          <div className="flex flex-row gap-2">
            <SortPopover onChange={setSort} value={sort} />

            <HostedPagesAppointmentsFilterPopover
              filter={filter}
              onChange={setFilter}
            />
          </div>
        </div>
      </div>

      <div
        ref={parent}
        className="track-height flex min-h-[16rem] flex-col gap-4 overflow-y-auto overflow-x-hidden bg-gray-50 p-4 dark:bg-gray-800 lg:px-28 lg:pt-6"
      >
        {isLoading && (
          <div className="absolute inset-0 flex items-center justify-center">
            <Spinner className="h-12 w-12" />
          </div>
        )}
        {!isFetching && data?.content?.length === 0 && (
          <div className="absolute inset-0 flex items-center justify-center">
            <EmptyPage
              action={
                filter.appointableCategories.length > 0 ? (
                  <Button
                    onClick={() => {
                      setFilter({
                        appointableCategories: [],
                      });
                    }}
                  >
                    Clear Filters
                  </Button>
                ) : undefined
              }
            />
          </div>
        )}
        {!isLoading &&
          appointablesToShow &&
          Object.entries(appointablesToShow).map(([key, value]) => (
            <CreditPackRow
              key={key}
              name={value.name}
              appointables={value.appointables}
            />
          ))}
      </div>
    </div>
  );
};

const CreditPackRow = ({
  name,
  appointables,
}: {
  name: string;
  appointables: AppointableDTO[];
}) => {
  const [parent] = useAutoAnimate();
  return (
    <div className="flex flex-col gap-3">
      <div className="font-semibold text-gray-500">{name}</div>
      <div
        className="relative grid content-start gap-3 lg:grid-cols-3 lg:gap-x-6 lg:gap-y-8"
        ref={parent}
      >
        {appointables?.map((e) => (
          <HostedPagesAppointableCard appointable={e} key={e.id} />
        ))}
      </div>
    </div>
  );
};
