import { useAbility } from "@casl/react";
import {
  useMutationVisitTotalReportCsv,
  useQueryVisitTotalReport,
} from "@gymflow/api";
import { DATE_FORMAT, PARAMETER_DATE_FORMAT_WITHOUT_TZ } from "@gymflow/common";
import { cn, downloadCsv, pluralize } from "@gymflow/helpers";
import {
  UserMemberSubscriptionStatus,
  VisitTotalReportDTO,
} from "@gymflow/types";
import { createColumnHelper, SortingState } from "@tanstack/react-table";
import capitalize from "lodash/capitalize";
import uniq from "lodash/uniq";
import moment from "moment-timezone";
import qs from "qs";
import { useCallback, useContext, useEffect, useState } from "react";
import { useHistory, useLocation } from "react-router";

import {
  Badge,
  BadgeProps,
  Button,
  Checkbox,
  LinkButton,
  MailIcon,
} from "../../components/atoms";
import { ReportPagination } from "../../components/molecules";
import {
  SendEmailSidebarProviderContext,
  Table,
} from "../../components/organisms";
import { Report } from "../../components/organisms/Report";
import { useSelected } from "../../hooks";
import { usePortalRoutes } from "../../hooks/usePortalRoutes";
import { AbilityContext, Subject, Verb } from "../../permissions";
import { useClubSettings } from "../../providers";
import useGymflowModels from "../../store";

export function VisitTotal() {
  const { createMemberLink } = usePortalRoutes();
  const history = useHistory();
  const { api } = useGymflowModels();

  const settings = useClubSettings();
  const { openSendEmailSidebar } = useContext(SendEmailSidebarProviderContext);
  const location = useLocation();
  const searchParams = qs.parse(location.search, { ignoreQueryPrefix: true });

  const [startDate, setStartDate] = useState<string>();
  const [endDate, setEndDate] = useState<string>();
  const [currentPage, setCurrentPage] = useState<number>(0);
  const [pageSize, setPageSize] = useState(25);
  const [currentSort, setCurrentSort] = useState<SortingState>([]);

  const createSort = function () {
    return currentSort?.[0]
      ? { field: currentSort[0]?.id, desc: currentSort[0]?.desc }
      : undefined;
  };

  const { data, isFetching } = useQueryVisitTotalReport({
    api,
    dateFrom: startDate,
    dateTo: endDate,
    sort: createSort(),
    size: pageSize,
    page: currentPage,
    tz: settings.timezone,
  });
  const downloadCsvMutation = useMutationVisitTotalReportCsv({
    api,
    tz: settings.timezone,
  });

  useEffect(() => {
    if (searchParams?.["startDate"]) {
      setStartDate(searchParams["startDate"] as string);
    }
    if (searchParams?.["endDate"]) {
      setEndDate(searchParams["endDate"] as string);
    }
  }, [searchParams?.["startDate"], searchParams?.["endDate"]]);

  const {
    toggle,
    selected,
    areAllSelected,
    isSelected,
    selectMultiple,
    excluded,
    reset,
  } = useSelected<{ userMemberId: string; checkInDate: string }>();
  const columnHelper = createColumnHelper<VisitTotalReportDTO>();
  const columnsDefinition = [
    columnHelper.accessor("userMemberId", {
      id: "selected",
      enableSorting: false,
      header: () => <div></div>,
      cell: (cell) => {
        const selectedValue = {
          userMemberId: cell.row.original.userMemberId,
          checkInDate: cell.row.original.checkInDate,
        };
        return (
          <Checkbox
            className="pr-4"
            inputClassName="!ring-secondary-500"
            activeInputClassName="!bg-secondary-500 !border-secondary-500"
            value={isSelected(selectedValue)}
            onChange={function (_, e) {
              if (e.shiftKey) {
                e.preventDefault();
                e.stopPropagation();
                const previousSelectedId = selected[selected.length - 1];
                if (previousSelectedId !== undefined) {
                  const previousSelectedIdx = data!.content.findIndex(
                    (row) =>
                      row.userMemberId === previousSelectedId.userMemberId &&
                      row.checkInDate === previousSelectedId.checkInDate,
                  );
                  if (previousSelectedIdx !== -1) {
                    const a =
                      previousSelectedIdx <= cell.row.index
                        ? previousSelectedIdx
                        : cell.row.index;
                    const b =
                      previousSelectedIdx > cell.row.index
                        ? previousSelectedIdx
                        : cell.row.index;

                    const slice = data!.content.slice(a, b + 1).map((r) => ({
                      userMemberId: r.userMemberId,
                      checkInDate: r.checkInDate,
                    }));
                    selectMultiple(slice);
                    return;
                  }
                }
              }
              toggle(selectedValue);
            }}
          />
        );
      },
    }),
    columnHelper.accessor(
      (row) => {
        return {
          firstName: row.firstName,
          lastName: row.lastName,
          id: row.userMemberId,
        };
      },
      {
        id: "firstName",
        cell: (info) => {
          const memberName = `${info.getValue().firstName} ${
            info.getValue().lastName
          }`;
          return (
            <div>
              <LinkButton
                className="text-sm"
                to={createMemberLink(info.getValue().id)}
              >
                {memberName}
              </LinkButton>
            </div>
          );
        },
        header: () => {
          return <div>Full Name</div>;
        },
        enableSorting: false,
      },
    ),
    columnHelper.accessor("membershipName", {
      cell: (info) => (
        <div className="text-sm font-normal text-gray-600">
          {info.getValue()}
        </div>
      ),
      header: "Current Subscription",
      enableSorting: false,
    }),
    columnHelper.accessor("membershipStatus", {
      cell: (info) => {
        const status = info.getValue();
        if (!status) {
          return null;
        }
        return (
          <Badge intent={membershipColorMap[status]} className="h-5 text-xs">
            {capitalize(info.getValue())}
          </Badge>
        );
      },
      header: "Status",
      enableSorting: false,
    }),
    columnHelper.accessor("checkInDate", {
      cell: (info) => (
        <div className="text-sm font-normal text-gray-600">
          {moment(info.getValue(), PARAMETER_DATE_FORMAT_WITHOUT_TZ).format(
            "MMM Do YYYY @ hh:mm A",
          )}
        </div>
      ),
      header: "Check In",
    }),
    columnHelper.accessor("checkOutDate", {
      cell: (info) => {
        if (info.getValue() === undefined) {
          return null;
        }
        return (
          <div className="text-sm font-normal text-gray-600">
            {moment(info.getValue(), PARAMETER_DATE_FORMAT_WITHOUT_TZ).format(
              "MMM Do YYYY @ hh:mm A",
            )}
          </div>
        );
      },
      header: "Check Out",
    }),
  ];

  const handleDateRangeChange = useCallback((newDate: any) => {
    if (newDate) {
      history.push({
        search: qs.stringify({
          startDate: moment(newDate.startDate, DATE_FORMAT).format(
            PARAMETER_DATE_FORMAT_WITHOUT_TZ,
          ),
          endDate: moment(newDate.endDate, DATE_FORMAT)
            .endOf("day")
            .format(PARAMETER_DATE_FORMAT_WITHOUT_TZ),
          backRoute: searchParams["backRoute"],
        }),
      });
    }
  }, []);

  const selectionCount = areAllSelected
    ? (data?.totalElements ?? 0) - excluded.length
    : selected.length;
  let title = "Total Visits";
  if (selectionCount > 0) {
    title += ` (${selectionCount} selected)`;
  }

  const ability = useAbility(AbilityContext);
  return (
    <Report
      title={title}
      description="List of all visit's within the date range selected."
      onDownloadClick={async () => {
        const data = await downloadCsvMutation.mutateAsync({
          startDate: startDate!,
          endDate: endDate!,
        });
        downloadCsv(data, `VisitTotal.csv`);
      }}
      renderMoreButtons={() => {
        const userMemberIds = uniq(selected.map((s) => s.userMemberId));
        return (
          <Button
            className={cn("mr-4", {
              hidden:
                (selected.length === 0 && !areAllSelected) ||
                !ability.can(Verb.Create, Subject.Email),
            })}
            onClick={() => {
              const recipient = `${userMemberIds.length} ${pluralize(
                "user",
                "users",
                userMemberIds.length,
              )}`;
              openSendEmailSidebar({
                recipient,
                allowMarketing: true,
                requestType: "USER",
                listOfIds: userMemberIds,
                onSuccess: () => reset(),
              });
            }}
          >
            <MailIcon pathClassName="stroke-gray-500" />
          </Button>
        );
      }}
      handleDateRangeChange={handleDateRangeChange}
      dateRange={
        startDate && endDate
          ? {
              startDate: moment(
                startDate,
                PARAMETER_DATE_FORMAT_WITHOUT_TZ,
              ).format(DATE_FORMAT),
              endDate: moment(endDate, PARAMETER_DATE_FORMAT_WITHOUT_TZ).format(
                DATE_FORMAT,
              ),
            }
          : undefined
      }
      close={() => {
        history.push({
          pathname: searchParams["backRoute"] as string,
          search: qs.stringify({
            dates: {
              startDate: moment(
                searchParams["startDate"] as string,
                PARAMETER_DATE_FORMAT_WITHOUT_TZ,
              ).format(DATE_FORMAT),
              endDate: moment(
                searchParams["endDate"] as string,
                PARAMETER_DATE_FORMAT_WITHOUT_TZ,
              ).format(DATE_FORMAT),
            },
          }),
        });
      }}
      table={
        <>
          <Table
            data={data?.content ?? []}
            columns={columnsDefinition}
            pageCount={data?.totalPages}
            pageIndex={data?.number}
            onSortingChange={setCurrentSort}
            sort={currentSort}
            isFetching={isFetching}
            pageSize={pageSize}
            rowClassName="h-14"
          />
          <div className="border-t-[0.063rem] border-gray-300">
            <ReportPagination
              pageCount={data?.totalPages as number}
              currentPage={data?.number as number}
              onPageChange={(newPage) => {
                setCurrentPage(newPage);
              }}
              pageSize={pageSize}
              setPageSize={(newValue) => {
                setPageSize(newValue);
                setCurrentPage(0);
              }}
            />
          </div>
        </>
      }
    />
  );
}

const membershipColorMap = {
  ACTIVE: "success",
  PAUSED: "warning",
  PENDING: "primary",
  OVERDUE: "error",
  CANCELLED: "default",
  DELETED: "error",
  EXPIRED: "default",
} as {
  [key in UserMemberSubscriptionStatus]: BadgeProps["intent"];
};
