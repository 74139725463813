import { useAutoAnimate } from "@formkit/auto-animate/react";
import {
  leadSourceListAsPublicQueryOptions,
  useClubAsPublic,
  useMemberCreateAsPublic,
  useQueryUserFormRulesAsPublic,
} from "@gymflow/api";
import { Gender, LeadSourceDTO, UserFormRule } from "@gymflow/types";
import { InfiniteData } from "@tanstack/react-query";
import Label from "apps/portal/src/components/atoms/base/Label";
import { FormCheckboxInput } from "apps/portal/src/components/atoms/form/FormCheckboxInput";
import { FormNotificationPreferences } from "apps/portal/src/components/atoms/form/FormNotificationPreferences";
import { FormPasswordInput } from "apps/portal/src/components/atoms/form/FormPasswordInput";
import { FormPhoneNumberInput } from "apps/portal/src/components/atoms/form/FormPhoneNumberInput";
import { FormSingleDatePicker } from "apps/portal/src/components/atoms/form/FormSingleDatePicker/FormSingleDatePicker";
import CheckmarkIcon from "apps/portal/src/components/atoms/icons/CheckmarkIcon";
import { isAxiosError } from "axios";
import { FormikProvider, useFormik } from "formik";
import { DateTime } from "luxon";
import { useContext, useMemo } from "react";
import { useTranslation } from "react-i18next";
import { ZodSchema } from "zod";
import { toFormikValidationSchema } from "zod-formik-adapter";

import {
  Button,
  FormInput,
  FormSelectInput,
  LayeredCircles,
} from "../../../components/atoms";
import useGymflowModels from "../../../store";
import { useClubSettings } from "../../settings";
import { ToastContext } from "../../ToastProvider/context";
import { useAuthenticatedUser } from "../AuthenticatedProvider";
import { generateSchema, SignUpSchema } from "./schema";

export const SignUpFormLoader = (props: {
  initialEmail?: string;
  onSuccess?: () => void;
}) => {
  const { api } = useGymflowModels();
  const settings = useClubSettings();
  const { data } = useQueryUserFormRulesAsPublic({ api });
  const rules = data?.find(
    (r) => r.ruleType === "USER_FORM_REQUIREMENT_CONFIG",
  );
  if (!rules?.userFormRule) return null;
  const schema = generateSchema({
    rules: rules?.userFormRule,
    postCodeFormat: settings.postal_code_country,
  });
  return (
    <SignUpForm schema={schema} formRule={rules.userFormRule} {...props} />
  );
};

function SignUpForm({
  schema,
  formRule,
  onSuccess,
  initialEmail,
}: {
  schema: ZodSchema;
  formRule: UserFormRule;
  onSuccess?: () => void;
  initialEmail?: string;
}) {
  const { notifyDanger } = useContext(ToastContext);
  const auth = useAuthenticatedUser();
  const { t } = useTranslation();
  const { api } = useGymflowModels();
  const settings = useClubSettings();
  const { data: club } = useClubAsPublic({ api, clubId: settings.clubId });
  const createMemberMutation = useMemberCreateAsPublic({ api });

  const formik = useFormik<SignUpSchema>({
    initialValues: {
      email: initialEmail ?? "",
      emailCommunication: false,
      pushCommunication: false,
      smsCommunication: false,
      password: "",
      confirmPassword: "",
      firstName: "",
      lastName: "",
      addressLine2: "",
      leadSource: undefined as any,
      isClubWaiverAccepted: false,
      mobileNumber: "",
      dateBirth: DateTime.now().minus({ years: 18 }).toJSDate(),
      gender: undefined,
      personalNumber: "",
      postCode: "",
      city: "",
      emergencyContactName: "",
      addressLine1: "",
      emergencyContact: undefined,
    },
    validationSchema: toFormikValidationSchema<SignUpSchema>(schema),

    onSubmit: async (values) => {
      try {
        const toSubmit = {
          ...values,
          email: values.email.trim().toLowerCase(),
        };
        delete (toSubmit as any).confirmPassword;
        delete (toSubmit as any).leadSource;
        await createMemberMutation.mutateAsync({
          ...toSubmit,
          country: settings.default_nationality,
          sourceId: values.leadSource.id,
          dateBirth: toSubmit.dateBirth
            ? DateTime.fromJSDate(toSubmit.dateBirth)
                .setZone(settings.timezone, { keepLocalTime: true })
                .toISODate() ?? undefined
            : undefined,
          gender: toSubmit.gender?.value,
          mobileNumber: toSubmit.mobileNumber?.replace(/ /g, ""),
          emergencyContact: toSubmit.emergencyContact?.replace(/ /g, ""),
        });
        await auth.login(toSubmit.email, toSubmit.password);
        onSuccess?.();
      } catch (e) {
        if (isAxiosError(e)) {
          notifyDanger(e);
        }
      }
    },
  });
  const requiredFields: Record<keyof UserFormRule, boolean> | null =
    useMemo(() => {
      return Object.keys(formRule!).reduce(
        (acc, r) => {
          if (!formRule) {
            return acc;
          }
          const field = r as keyof UserFormRule;
          acc[field] = formRule[field].isRequired;
          return acc as Record<keyof UserFormRule, boolean>;
        },
        {} as Record<keyof UserFormRule, boolean>,
      );
    }, [formRule]);
  const [parent] = useAutoAnimate();

  return (
    <div
      ref={parent}
      className="flex h-full w-full max-w-[30rem] flex-col gap-8 py-6"
    >
      {createMemberMutation.isSuccess && (
        <div className="flex h-full flex-col items-center gap-8 pt-12">
          <LayeredCircles intent="success">
            <CheckmarkIcon pathClassName="stroke-success-600" />
          </LayeredCircles>
          <div className="text-center text-xl font-semibold text-gray-950 dark:text-gray-0">
            {t("pages.signUp.success")}
          </div>
        </div>
      )}
      {!createMemberMutation.isSuccess && (
        <div className="flex flex-col items-center gap-1">
          <div className="text-center text-xl font-bold text-gray-950 dark:text-gray-0">
            {t("pages.signUp.title")}
          </div>

          <div className="text-center font-medium text-gray-500">
            {t("pages.signUp.description")}
          </div>
        </div>
      )}

      {!createMemberMutation.isSuccess && (
        <div className="flex flex-col gap-4 pb-6">
          <FormikProvider value={formik}>
            <FormInput<SignUpSchema>
              name="email"
              label={t("common.userMemberForm.email")}
              placeholder={t("common.userMemberForm.email")}
              isRequired
            />
            <div className="flex flex-row gap-2">
              <FormInput<SignUpSchema>
                name="firstName"
                placeholder={t("common.userMemberForm.firstName")}
                label={t("common.userMemberForm.firstName")}
                containerClassName="w-full"
                isRequired
              />
              <FormInput<SignUpSchema>
                name="lastName"
                label={t("common.userMemberForm.lastName")}
                placeholder={t("common.userMemberForm.lastName")}
                containerClassName="w-full"
                isRequired
              />
            </div>

            <div className="flex flex-row gap-2">
              <FormPasswordInput<SignUpSchema>
                name="password"
                label={t("common.userMemberForm.password")}
                placeholder={t("common.userMemberForm.password")}
                containerClassName="w-full"
                isRequired
              />

              <FormPasswordInput<SignUpSchema>
                name="confirmPassword"
                label={t("common.userMemberForm.confirmPassword")}
                placeholder={t("common.userMemberForm.confirmPassword")}
                containerClassName="w-full"
                isRequired
              />
            </div>
            <FormSelectInput<SignUpSchema, LeadSourceDTO>
              name="leadSource"
              label={t("common.userMemberForm.leadSource")}
              placeholder={t("common.userMemberForm.leadSource")}
              dataFetchingQuery={() => {
                return {
                  ...leadSourceListAsPublicQueryOptions(api),
                  select: (data) => {
                    const { pageParams, pages } = data as InfiniteData<
                      LeadSourceDTO[]
                    >;

                    const transformedPages = pages.map((page) =>
                      page.map((leadSource) => ({
                        id: leadSource.id,
                        label: leadSource.name,
                        value: leadSource,
                      })),
                    );
                    return {
                      pageParams,
                      pages: transformedPages,
                    };
                  },

                  getNextPageParam: () => undefined,
                };
              }}
              isRequired
            />
            {requiredFields.mobileNumber && (
              <FormPhoneNumberInput<SignUpSchema>
                name="mobileNumber"
                label={t("common.userMemberForm.mobileNumber")}
                placeholder={t("common.userMemberForm.mobileNumber")}
                isRequired
              />
            )}

            <div className="flex flex-row gap-2">
              {requiredFields.dateOfBirth && (
                <FormSingleDatePicker<SignUpSchema>
                  name="dateBirth"
                  label={t("common.userMemberForm.dateOfBirth")}
                  hidden={{ after: new Date() }}
                  containerClassName="w-full"
                  isRequired
                  hideNavigation
                  captionLayout="dropdown"
                />
              )}
              {requiredFields.gender && (
                <FormSelectInput<SignUpSchema, Gender>
                  name="gender"
                  label={t("common.userMemberForm.gender")}
                  options={[
                    {
                      id: "MALE",
                      label: "Male",
                      value: "MALE",
                    },
                    {
                      id: "FEMALE",
                      label: "Female",
                      value: "FEMALE",
                    },
                    {
                      id: "PREFER_NOT_TO_SAY",
                      label: "Prefer not to say",
                      value: "PREFER_NOT_TO_SAY",
                    },
                  ]}
                  containerClassName="w-full"
                  isRequired
                />
              )}
            </div>
            {requiredFields.personalNumber && (
              <FormInput<SignUpSchema>
                name="personalNumber"
                label={t("common.userMemberForm.personalNumber")}
                placeholder={t("common.userMemberForm.personalNumber")}
                isRequired
              />
            )}
            {(requiredFields.postCode || requiredFields.city) && (
              <div className="flex flex-row gap-2">
                {requiredFields.city && (
                  <FormInput<SignUpSchema>
                    name="city"
                    label={t("common.userMemberForm.city")}
                    placeholder={t("common.userMemberForm.city")}
                    isRequired
                    containerClassName="w-full"
                  />
                )}
                {requiredFields.postCode && (
                  <FormInput<SignUpSchema>
                    name="postCode"
                    label={t("common.userMemberForm.postCode")}
                    placeholder={t("common.userMemberForm.postCode")}
                    isRequired
                    containerClassName="w-full"
                  />
                )}
              </div>
            )}
            {requiredFields.addressLine && (
              <div className="flex flex-col gap-1">
                <Label
                  label={t("common.userMemberForm.addressLine")}
                  isRequired
                />
                <div className="flex flex-row gap-2">
                  <FormInput<SignUpSchema>
                    name="addressLine1"
                    placeholder={t("common.userMemberForm.addressLine1")}
                    isRequired
                    containerClassName="w-full"
                  />
                  <FormInput<SignUpSchema>
                    name="addressLine2"
                    placeholder={t("common.userMemberForm.addressLine2")}
                    isRequired
                    containerClassName="w-full"
                  />
                </div>
              </div>
            )}
            {requiredFields.emergencyContact && (
              <div className="flex flex-col gap-4 rounded-xl border border-gray-200 p-4 dark:border-gray-800">
                <div className="font-semibold text-gray-950 dark:text-gray-0">
                  {t("common.userMemberForm.emergencyContact")}
                </div>
                <div className="flex flex-col gap-2">
                  <FormInput<SignUpSchema>
                    name="emergencyContactName"
                    label={t("common.userMemberForm.emergencyContactName")}
                    placeholder={t(
                      "common.userMemberForm.emergencyContactName",
                    )}
                    isRequired
                  />
                  <FormPhoneNumberInput<SignUpSchema>
                    name="emergencyContact"
                    label={t("common.userMemberForm.mobileNumber")}
                    placeholder={t("common.userMemberForm.mobileNumber")}
                    isRequired
                  />
                </div>
              </div>
            )}

            <FormNotificationPreferences
              emailCommunicationFieldName="emailCommunication"
              smsCommunicationFieldName="smsCommunication"
            />
            <FormCheckboxInput<SignUpSchema>
              name="isClubWaiverAccepted"
              label={
                <div className="text-sm text-gray-500">
                  {t("common.userMemberForm.isClubWaiverAccepted")}&nbsp;
                  <a
                    className="!text-secondary-500 cursor-pointer hover:!underline"
                    target="_blank"
                    href={club?.termsConditions}
                    rel="noreferrer"
                  >
                    {t("common.userMemberForm.isClubWaiver")}
                  </a>
                </div>
              }
            />

            <Button
              intent="secondary"
              className="w-full"
              onClick={async () => {
                await formik.submitForm();
              }}
            >
              {t("common.userMemberForm.signUp")}
            </Button>
          </FormikProvider>
        </div>
      )}
    </div>
  );
}
