import { faAngleLeft, faAngleRight } from "@fortawesome/free-solid-svg-icons";
import { FontAwesomeIcon } from "@fortawesome/react-fontawesome";
import { cn } from "@gymflow/helpers";
import { useClubSettings } from "apps/portal/src/providers";
import { DateTime, Interval } from "luxon";
import { useContext } from "react";

import { Button } from "../../../../atoms";
import { WeekPickerContext } from "./WeekPickerContext";

export type WeekPickerProps = {
  className?: string;
};

const WeekPicker = ({ className }: WeekPickerProps) => {
  const settings = useClubSettings();
  const minimumDate = DateTime.now().setZone(settings.timezone).startOf("day");
  const { amountOfDays, currentDay, setCurrentDay } =
    useContext(WeekPickerContext);
  const currentInterval = Interval.fromDateTimes(
    DateTime.max(currentDay, minimumDate),
    currentDay.plus({ days: amountOfDays }),
  );
  return (
    <div className={cn("flex flex-row gap-2 max-lg:w-full", className)}>
      <Button
        disabled={currentDay <= minimumDate}
        onClick={() => {
          setCurrentDay(currentDay.minus({ days: amountOfDays }));
        }}
        className={cn("aspect-square p-0 transition-opacity", {
          "opacity-0": currentDay <= minimumDate,
        })}
      >
        <FontAwesomeIcon icon={faAngleLeft} className="h-4 w-4 text-gray-500" />
      </Button>
      <div className="dark:border-gray-700 flex items-center justify-center rounded-[.625rem] border border-gray-300 px-4 text-gray-950 shadow-sm dark:text-gray-0 max-lg:w-full lg:min-w-[28rem]">
        {currentInterval.toLocaleString(DateTime.DATE_FULL)}
      </div>
      <Button
        onClick={() => {
          setCurrentDay(currentDay.plus({ days: amountOfDays }));
        }}
        className="aspect-square p-0"
      >
        <FontAwesomeIcon
          icon={faAngleRight}
          className="h-4 w-4 text-gray-500"
        />
      </Button>
    </div>
  );
};

export default WeekPicker;
