import { cn } from "@gymflow/helpers";
import { Field, FieldProps, FormikProps } from "formik";

import {
  StaffSelectInput,
  StaffSelectInputOptionType,
  StaffSelectInputProps,
} from "../base/StaffSelectInput";
import { ErrorMessage } from "../ErrorMessage";

type FormStaffSelectInputProps<T> = {
  name: keyof T;
} & Omit<StaffSelectInputProps, "value" | "onChange">;

export const FormStaffSelectInput = <T,>({
  name,
  className,
  ...props
}: FormStaffSelectInputProps<T>) => (
  <Field name={name}>
    {({
      field,
      form: { setFieldValue },
      meta: { touched, error },
    }: FieldProps<
      StaffSelectInputOptionType | StaffSelectInputOptionType[],
      FormikProps<T>
    >) => {
      const isError = touched && error;
      return (
        <div className="flex flex-col gap-1.5">
          <StaffSelectInput
            {...props}
            {...(props.isMulti
              ? {
                  isMulti: true,
                  value: (field.value as StaffSelectInputOptionType[]) ?? [],
                }
              : {
                  isMulti: false,
                  value: field.value as StaffSelectInputOptionType | undefined,
                })}
            onChange={(
              selected?:
                | StaffSelectInputOptionType
                | StaffSelectInputOptionType[],
            ) => setFieldValue(String(name), selected)}
            className={cn(className, {
              "outline-error-300 border-error-200": isError,
            })}
          />
          {isError && <ErrorMessage text={error} />}
        </div>
      );
    }}
  </Field>
);
