export const MarkerPin01Icon = ({
  className,
  pathClassName,
}: {
  className?: string;
  pathClassName?: string;
}) => (
  <svg
    width="16"
    height="16"
    viewBox="0 0 16 16"
    fill="none"
    xmlns="http://www.w3.org/2000/svg"
    className={className}
  >
    <path
      d="M7.99999 8.66665C9.10456 8.66665 9.99999 7.77122 9.99999 6.66665C9.99999 5.56208 9.10456 4.66665 7.99999 4.66665C6.89542 4.66665 5.99999 5.56208 5.99999 6.66665C5.99999 7.77122 6.89542 8.66665 7.99999 8.66665Z"
      stroke="#667085"
      strokeWidth="1.5"
      strokeLinecap="round"
      strokeLinejoin="round"
      className={pathClassName}
    />
    <path
      d="M7.99999 14.6666C10.6667 12 13.3333 9.61217 13.3333 6.66665C13.3333 3.72113 10.9455 1.33331 7.99999 1.33331C5.05447 1.33331 2.66666 3.72113 2.66666 6.66665C2.66666 9.61217 5.33332 12 7.99999 14.6666Z"
      stroke="#667085"
      strokeWidth="1.5"
      strokeLinecap="round"
      strokeLinejoin="round"
      className={pathClassName}
    />
  </svg>
);
