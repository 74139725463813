import {
  useQueryAppointableCategoriesAsPublic,
  useQueryAppointableListAsPublic,
} from "@gymflow/api";
import { cn, formatCurrency, pluralize } from "@gymflow/helpers";
import { AppointableCategoryDTO, AppointableDTO } from "@gymflow/types";
import { Fragment, useState } from "react";

import { useClubSettings } from "../../../providers";
import useGymflowModels from "../../../store";
import { ArrowRightIcon, Badge } from "../../atoms";

export function SelectService({
  onSelect,
}: {
  onSelect: ({ service }: { service: AppointableDTO }) => void;
}) {
  const { api } = useGymflowModels();
  const { data: categories } = useQueryAppointableCategoriesAsPublic({
    api,
    paginationOption: { page: 0, size: 100 },
    filter: { statusList: ["ACTIVE"] },
  });

  const [selectedCategory, setSelectedCategory] =
    useState<AppointableCategoryDTO>();
  const { data: services } = useQueryAppointableListAsPublic({
    api,
    opts: {
      page: 0,
      limit: 100,
      extraParams: {
        status: "ACTIVE",
        "appointableCategory.id": selectedCategory && [selectedCategory.id],
        allowOnlineBookings: true,
      },
    },
  });
  const { defaultCurrency } = useClubSettings();

  const nonEmptyCategories = categories?.content.filter((category) => {
    return services?.content.some(
      (service) => service.appointableCategory.id === category.id,
    );
  });

  return (
    <div className="flex flex-col gap-6">
      <div className="text-2xl font-bold dark:text-gray-0">
        Select Appointment
      </div>
      <div className="flex flex-wrap gap-1">
        <Badge
          intent={selectedCategory ? "default" : "primary"}
          className="cursor-pointer font-semibold uppercase"
          onClick={() => {
            setSelectedCategory(undefined);
          }}
        >
          All
        </Badge>
        {categories?.content.map((category) => {
          return (
            <Badge
              intent={
                category.id === selectedCategory?.id ? "primary" : "default"
              }
              className="cursor-pointer uppercase"
              onClick={() => {
                if (selectedCategory?.id === category.id) {
                  setSelectedCategory(undefined);
                } else {
                  setSelectedCategory(category);
                }
              }}
              key={category.id}
            >
              {category.name}
            </Badge>
          );
        })}
      </div>
      <div className="flex flex-col gap-4">
        {nonEmptyCategories?.map((category) => {
          return (
            <Fragment key={category.id}>
              <div className="rounded border border-gray-200 bg-gray-50 px-4 py-2 text-lg font-semibold dark:border-0 dark:bg-gray-800 dark:text-gray-200">
                {category.name}
              </div>
              <div className="flex flex-col gap-4">
                {services?.content
                  .filter(
                    (service) => service.appointableCategory.id === category.id,
                  )
                  .map((service) => {
                    return (
                      <div
                        className="flex cursor-pointer gap-6 rounded border border-gray-300 p-4 hover:bg-gray-50 dark:border-gray-800 dark:hover:bg-gray-900"
                        key={service.id}
                        onClick={async () => {
                          onSelect({ service });
                        }}
                      >
                        <div className="flex grow flex-col ">
                          <div className="flex justify-between">
                            <div className="text-sm font-medium text-gray-900 dark:text-gray-200">
                              {service.name}
                            </div>
                            <div
                              className={cn(
                                "dark:text-gray-200 text-sm font-medium",
                                { invisible: service.price === undefined },
                              )}
                            >
                              {service.price === 0 ||
                              service.pricingModel === "FREE"
                                ? "FREE"
                                : formatCurrency(
                                    service.price ?? 0,
                                    defaultCurrency,
                                  )}
                            </div>
                          </div>
                          <div className="flex justify-between  text-sm font-normal text-gray-400 dark:text-gray-400">
                            <div>{`${service.duration} ${pluralize(
                              "minute",
                              "minutes",
                              service.duration,
                            )}`}</div>
                            <div>
                              {service.sessionCost &&
                                `${service.sessionCost} ${pluralize(
                                  "credit",
                                  "credits",
                                  service.sessionCost,
                                )}`}
                            </div>
                          </div>
                        </div>
                        <div className="flex items-center">
                          <ArrowRightIcon pathClassName="stroke-gray-500 dark:stroke-gray-400" />
                        </div>
                      </div>
                    );
                  })}
              </div>
            </Fragment>
          );
        })}
      </div>
    </div>
  );
}
