import { faEye, faEyeSlash } from "@fortawesome/free-regular-svg-icons";
import { FontAwesomeIcon } from "@fortawesome/react-fontawesome";
import { cn } from "@gymflow/helpers";
import React, {
  FocusEventHandler,
  forwardRef,
  ReactNode,
  useState,
} from "react";

import { Button } from "../Button";
import Label from "./Label";

export type PasswordInputProps = {
  value: string;
  onChange: (e: React.ChangeEvent<HTMLInputElement>) => void;
  placeholder?: string;
  className?: string;
  label?: string | ReactNode;
  onFocus?: FocusEventHandler;
  onBlur?: FocusEventHandler;
  isRequired?: boolean;
};

export const PasswordInput = forwardRef<HTMLInputElement, PasswordInputProps>(
  ({ className, label, value, isRequired, ...rest }, ref) => {
    const [showingPassword, setShowingPassword] = useState(false);
    const [isFocused, setIsFocused] = useState(false);

    return (
      <div className="relative flex w-full flex-col gap-1.5">
        <Label label={label} isRequired={isRequired} />
        <div className="relative">
          <input
            {...rest}
            ref={ref}
            placeholder={rest.placeholder}
            value={value}
            onFocus={(e) => {
              setIsFocused(true);
              rest.onFocus?.(e);
            }}
            onBlur={(e) => {
              setIsFocused(false);
              rest.onBlur?.(e);
            }}
            className={cn(
              "font-medium text-base dark:bg-gray-800 pr-10 shadow-xs outline-secondary-200 w-full rounded-[0.625rem] border border-gray-200 px-3.5 py-2.5 disabled:bg-gray-50 h-10 dark:!outline-none focus:dark:ring-1 dark:ring-secondary-600 dark:border-gray-800",
              {
                "text-gray-950 dark:text-gray-0": value,
                "text-gray-500": !value,
                "text-ellipsis overflow-hidden whitespace-nowrap": !isFocused,
              },
              className,
            )}
            type={showingPassword ? "text" : "password"}
          />
          <Button
            onClick={() => setShowingPassword((e) => !e)}
            className="absolute right-1 top-1/2 z-10 h-8 w-8 -translate-y-1/2 bg-transparent"
            intent="transparent"
          >
            {showingPassword ? (
              <FontAwesomeIcon icon={faEyeSlash} className="h-4 w-4" />
            ) : (
              <FontAwesomeIcon icon={faEye} className="h-4 w-4" />
            )}
          </Button>
        </div>
      </div>
    );
  },
);

PasswordInput.displayName = "PasswordInput";
