import {
  useAutomationPlaceholderLabels,
  useEmailTemplate,
  useEmailTemplateCreate,
  useEmailTemplateEdit,
} from "@gymflow/api";
import { ToastContext } from "apps/portal/src/providers/ToastProvider/context";
import useGymflowModels from "apps/portal/src/store";
import { isAxiosError } from "axios";
import { FormikProvider, useFormik } from "formik";
import { useContext, useEffect, useRef } from "react";
import { useTranslation } from "react-i18next";

import {
  Button,
  CloseIcon,
  FormEmailEditor,
  FormInput,
  PlaceholderSelectInput,
  SlideSideBar,
  Spinner,
} from "../../atoms";
import { EmailEditorRef } from "../../atoms/base/EmailEditor/types";
import {
  emailTemplateFormSchema,
  INITIAL_VALUES_EMAIL_TEMPLATE_FORM,
} from "./constants";
import { EmailTemplateFormValues, EmailTemplateSidebarProps } from "./types";

export const EmailTemplateSidebar = ({
  isVisible,
  onClose,
  title,
  disabledTemplateNameField = false,
  templateId,
}: EmailTemplateSidebarProps) => {
  const emailEditorRef = useRef<EmailEditorRef | null>(null);

  const { t } = useTranslation();
  const { api } = useGymflowModels();
  const { notifyDanger } = useContext(ToastContext);

  const { data: emailTemplate, isFetching: isFetchingEmailTemplate } =
    useEmailTemplate({
      api,
      emailTemplateId: templateId,
    });

  const placeholderType = emailTemplate?.templatePlaceholderType || "GENERIC";

  const { data: placeholders, isFetching: isFetchingPlaceholders } =
    useAutomationPlaceholderLabels({
      api,
      placeholderType,
    });
  const { mutateAsync: emailTemplateEditMutate } = useEmailTemplateEdit({
    api,
  });
  const { mutateAsync: emailTemplateCreateMutate } = useEmailTemplateCreate({
    api,
  });

  const formik = useFormik<EmailTemplateFormValues>({
    initialValues: INITIAL_VALUES_EMAIL_TEMPLATE_FORM,
    validationSchema: emailTemplateFormSchema,
    onSubmit: async ({
      body,
      subject,
      templateName,
    }: EmailTemplateFormValues) => {
      try {
        if (templateId) {
          await emailTemplateEditMutate({
            emailTemplateId: templateId,
            patchedFields: {
              body,
              subject,
              templateName: disabledTemplateNameField
                ? undefined
                : templateName,
            },
          });
        } else {
          emailTemplateCreateMutate({
            body,
            subject,
            templateName,
            templateType: "EMAIL",
          });
        }
        onClose();
      } catch (e) {
        if (isAxiosError(e)) {
          notifyDanger(e);
        }
      }
    },
  });

  useEffect(() => {
    if (isVisible) return;
    formik.resetForm();
    // eslint-disable-next-line react-compiler/react-compiler
    // eslint-disable-next-line react-hooks/exhaustive-deps
  }, [isVisible]);

  useEffect(() => {
    if (emailTemplate && !isFetchingEmailTemplate && !isFetchingPlaceholders) {
      const { body, templateName, subject } = emailTemplate;
      formik.setFieldValue("subject", subject);
      formik.setFieldValue("templateName", templateName);
      emailEditorRef.current?.overrideHTML(body);
    }
    // eslint-disable-next-line react-compiler/react-compiler
    // eslint-disable-next-line react-hooks/exhaustive-deps
  }, [emailTemplate, isFetchingEmailTemplate, isFetchingPlaceholders]);

  return (
    <SlideSideBar
      isOpen={isVisible}
      hide={onClose}
      className="!w-full lg:!w-1/2"
    >
      {isFetchingEmailTemplate || isFetchingPlaceholders ? (
        <div className="absolute inset-0 flex items-center justify-center">
          <Spinner className="h-12 w-12" />
        </div>
      ) : (
        <FormikProvider value={formik}>
          <div className="flex h-full flex-col overflow-hidden">
            <div className="flex-1 overflow-y-auto">
              <div className="flex flex-col justify-between p-6 pb-5">
                <div className="mb-1 flex flex-row items-center justify-between">
                  <div className="text-lg font-semibold text-gray-900">
                    {title}
                  </div>
                  <div className="cursor-pointer" onClick={onClose}>
                    <CloseIcon className="h-6 w-6" />
                  </div>
                </div>
              </div>
              <div className="flex flex-col gap-6 p-6 pt-3">
                <FormInput<EmailTemplateFormValues>
                  name="templateName"
                  isRequired
                  label={t(
                    "components.emailTemplateSidebar.input.templateName.label",
                  )}
                  placeholder={t(
                    "components.emailTemplateSidebar.input.templateName.placeholder",
                  )}
                  disabled={disabledTemplateNameField}
                />
                <FormInput<EmailTemplateFormValues>
                  name="subject"
                  isRequired
                  label={t(
                    "components.emailTemplateSidebar.input.subject.label",
                  )}
                  placeholder={t(
                    "components.emailTemplateSidebar.input.subject.placeholder",
                  )}
                />
                <div className="flex flex-col gap-3">
                  <PlaceholderSelectInput
                    className="w-1/2"
                    onSelect={(placeholder) =>
                      emailEditorRef.current?.insertText(placeholder)
                    }
                    placeholderType={placeholderType}
                  />
                  <FormEmailEditor<EmailTemplateFormValues>
                    innerRef={emailEditorRef}
                    name="body"
                    placeholders={placeholders}
                  />
                </div>
              </div>
            </div>
            <div className="flex items-center justify-between gap-3 border-t border-t-gray-300 p-6">
              <Button intent="default" className="w-full" onClick={onClose}>
                {t("components.emailTemplateSidebar.button.cancel")}
              </Button>
              <Button
                intent="secondary"
                className="w-full"
                onClick={async () => await formik.submitForm()}
              >
                {t("components.emailTemplateSidebar.button.save")}
              </Button>
            </div>
          </div>
        </FormikProvider>
      )}
    </SlideSideBar>
  );
};
