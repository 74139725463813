import { cn } from "@gymflow/helpers";
import { cva, VariantProps } from "class-variance-authority";
import React, { ReactNode } from "react";

const layeredCirclesVariantsInnerCircle = cva(
  "flex h-3/4 w-3/4 items-center justify-center rounded-full",
  {
    variants: {
      intent: {
        primary: "bg-primary-100",
        secondary: "bg-secondary-100",
        error: "bg-error-100",
        warning: "bg-warning-100",
        success: "bg-success-100",
        lightblue: "bg-lightblue-100",
        default: "bg-gray-100",
      },
    },
    defaultVariants: {
      intent: "secondary",
    },
  },
);
const layeredCirclesVariantsOuterCircle = cva(
  "flex h-16 w-16 items-center justify-center rounded-full",
  {
    variants: {
      intent: {
        primary: "bg-primary-50",
        secondary: "bg-secondary-50",
        error: "bg-error-50",
        warning: "bg-warning-50",
        success: "bg-success-50",
        lightblue: "bg-lightblue-50",
        default: "bg-gray-50",
      },
    },
    defaultVariants: {
      intent: "secondary",
    },
  },
);
export type LayeredCirclesProps = {
  className?: string;
  children: ReactNode;
} & VariantProps<typeof layeredCirclesVariantsOuterCircle>;

export const LayeredCircles: React.FC<LayeredCirclesProps> = ({
  intent,
  className,
  children,
}) => {
  return (
    <div
      className={cn(layeredCirclesVariantsOuterCircle({ intent }), className)}
    >
      <div className={cn(layeredCirclesVariantsInnerCircle({ intent }))}>
        {children}
      </div>
    </div>
  );
};
