import { ApiType } from "@gymflow/common/lib/api/ApiType";
import { useMutation, useQueryClient } from "@tanstack/react-query";

import { leadAsPublicQueryKeys } from "./leadAsPublicQueryKeys";

export function useMutationLeadCreateAsPublic({ api }: { api: ApiType }) {
  const queryClient = useQueryClient();

  return useMutation({
    mutationFn: async (
      newLead: Parameters<ApiType["public"]["leadApi"]["create"]>[0],
    ) => {
      return (await api.public.leadApi.create(newLead)).data;
    },
    onSuccess: () => {
      queryClient.invalidateQueries({ queryKey: leadAsPublicQueryKeys.all() });
    },
  });
}
