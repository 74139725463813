import { theme } from "@gymflow/common";
import { portalIsProduction } from "@gymflow/helpers";
import * as Sentry from "@sentry/react";
import { QueryClientProvider } from "@tanstack/react-query";
import { ReactQueryDevtools } from "@tanstack/react-query-devtools";
import { createBrowserHistory } from "history";
import { MotionConfig } from "motion/react";
import posthog from "posthog-js";
import { PostHogProvider } from "posthog-js/react";
import { useMemo } from "react";
import {
  Redirect,
  Route,
  Router,
  Switch,
  useParams,
  useRouteMatch,
} from "react-router-dom";
import { Toaster } from "sonner";
import { ThemeProvider } from "styled-components";

import environment from "./environment";
import { createQueryClient } from "./helpers/queryClient";
import { useEmbedLoadingStyleStart } from "./hooks/useEmbedLoadingStyle";
import { PortalRoutesContext } from "./hooks/usePortalRoutes";
import { AuthenticationLayout } from "./layouts/Authentication/Authentication";
import EmbedLayout from "./layouts/Embed/Embed";
import KioskLayout from "./layouts/Kiosk/Kiosk";
import { LinkLayout } from "./layouts/Link/Link";
import MemberLayout from "./layouts/Member/Member";
import { Migration as MigrationLayout } from "./layouts/Migration/Migration";
import SiteLayout from "./layouts/Site/Site";
import StaffLayout from "./layouts/Staff/Staff";
import { ToastProvider } from "./providers/ToastProvider";
import { RouteFeature } from "./routes/feature";
import { RouteLayout } from "./routes/layout";
import { GenericSecurePayment } from "./views/GenericSecurePayment";
import { StripeRedirect } from "./views/StripeRedirect";
import { Welcome } from "./views/Welcome";

const hist = createBrowserHistory();

function PortalRoutes() {
  const match = useRouteMatch();
  useEmbedLoadingStyleStart();

  return (
    <ThemeProvider theme={theme}>
      <Switch>
        <Route path={`${match.path}${RouteLayout.Authentication}`}>
          {(props) => <AuthenticationLayout {...props} />}
        </Route>
        <Route path={`${match.path}${RouteLayout.Staff}`}>
          {() => <StaffLayout />}
        </Route>
        <Route path={`${match.path}${RouteLayout.Member}`}>
          {() => <MemberLayout />}
        </Route>
        <Route path={`${match.path}${RouteLayout.Kiosk}`}>
          {(props) => <KioskLayout {...props} />}
        </Route>
        <Route path={`${match.path}${RouteLayout.Site}`}>
          {(props) => <SiteLayout {...props} />}
        </Route>
        <Route path={`${match.path}${RouteLayout.Migration}`}>
          <MigrationLayout />
        </Route>
        <Route path={`${match.path}${RouteLayout.Embed}`}>
          {(props) => <EmbedLayout {...props} />}
        </Route>
        <Route path={`${match.path}${RouteLayout.Link}`}>
          {(props) => <LinkLayout {...props} />}
        </Route>
        <Route>
          <Redirect
            exact
            from={match.path}
            to={`${match.url}${RouteLayout.Staff}`}
          />
        </Route>
      </Switch>
    </ThemeProvider>
  );
}

function PortalBootstrap() {
  const { brand, company, location } = useParams<{
    brand: string;
    company: string;
    location: string;
  }>();

  const routesConfig = useMemo(
    () => ({
      routeId: `${brand}/${company}/${location}`,
      brand,
      company,
      location,
    }),
    [brand, company, location],
  );

  return (
    <PortalRoutesContext.Provider value={routesConfig}>
      <PortalRoutes />
    </PortalRoutesContext.Provider>
  );
}

const queryClient = createQueryClient();
if (portalIsProduction()) {
  posthog.init("phc_MNP3DHE0ICvVTOFn07UKGPyLfGUjncfNgq1Drai9g1u", {
    api_host: "https://us.i.posthog.com",
    person_profiles: "identified_only", // or 'always' to create profiles for anonymous users as well
  });
}

function PortalRoot() {
  return (
    <PostHogProvider client={posthog}>
      <MotionConfig reducedMotion="user">
        <QueryClientProvider client={queryClient}>
          <Toaster position="top-center" className="w-96" />
          <ToastProvider>
            <Router history={hist}>
              <Switch>
                <Route path={`/stripe-redirect`}>
                  <StripeRedirect />
                </Route>
                <Route path="/:brand/:company/:location">
                  <PortalBootstrap />
                </Route>
                <Route path={RouteFeature.SiteSecurePayment}>
                  <GenericSecurePayment />
                </Route>
                <Route path="">{() => <Welcome />}</Route>
              </Switch>
            </Router>
          </ToastProvider>
          <ReactQueryDevtools initialIsOpen={false} />
        </QueryClientProvider>
      </MotionConfig>
    </PostHogProvider>
  );
}

export default environment.get("SENTRY_ENABLED")
  ? Sentry.withProfiler(PortalRoot)
  : PortalRoot;
