import { ApiType } from "@gymflow/common/lib/api/ApiType";
import { InvoiceInfo } from "@gymflow/types";
import { useQuery, UseQueryOptions } from "@tanstack/react-query";

import { memberQueryKeys } from "./memberQueryKeys";

export const useQueryGetInvoiceByInvoiceNumber = (
  {
    api,
    invoiceNumber,
  }: {
    api: ApiType;
    invoiceNumber: string;
  },
  opts?: UseQueryOptions<InvoiceInfo>,
) =>
  useQuery({
    queryKey: memberQueryKeys.invoice(invoiceNumber),
    queryFn: async () => {
      return (await api.memberApi.getInvoiceByInvoiceNumber(invoiceNumber))
        .data as InvoiceInfo;
    },
    ...opts,
  });
