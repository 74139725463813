import { formatCurrency, pluralize } from "@gymflow/helpers";
import { SessionPackDTO } from "@gymflow/types";
import { useClubSettings } from "apps/portal/src/providers";
import React from "react";
import { useTranslation } from "react-i18next";

import { Button, ClockIcon, CreditsIcon, InfinityIcon } from "../../../atoms";
import { HostedPagesCard } from "../../components/HostedPagesCard";

export type HostedPagesCreditPackCardProps = {
  creditPack: SessionPackDTO;
};

export const HostedPagesCreditPackCard: React.FC<
  HostedPagesCreditPackCardProps
> = ({ creditPack }) => {
  const { t } = useTranslation();

  const settings = useClubSettings();
  return (
    <HostedPagesCard.Container>
      <HostedPagesCard.Header>
        <div className="flex flex-row items-start justify-between gap-4">
          <div className="dark:text-gray-0 flex-wrap font-bold text-gray-950">
            {creditPack.name}
          </div>
          <div className="flex flex-row gap-2">
            {creditPack.sessionsUnlimited ? (
              <InfinityIcon className="stroke-gray-950 dark:stroke-gray-50" />
            ) : (
              <div className="dark:text-gray-0 font-bold text-gray-950">
                {creditPack.sessionsIncluded}
              </div>
            )}
            <CreditsIcon pathClassName="stroke-secondary-500" />
          </div>
        </div>
        <HostedPagesCard.Description description={creditPack.description} />
      </HostedPagesCard.Header>
      <HostedPagesCard.Separator />
      <HostedPagesCard.Footer>
        <HostedPagesCard.Price
          price={
            creditPack.price === 0
              ? t("common.free")
              : formatCurrency(creditPack.price, settings.defaultCurrency)
          }
          description={
            <>
              <ClockIcon />
              {creditPack.expiryType === "NA"
                ? "No expiry"
                : creditPack.expiryType === "WEEKLY"
                ? `${creditPack.expiryValue} ${pluralize(
                    "Week",
                    "Weeks",
                    1,
                  )} Expiry`
                : `${creditPack.expiryValue} ${pluralize(
                    "Month",
                    "Months",
                    1,
                  )} Expiry`}
            </>
          }
        />

        <Button
          link={`${document.location.pathname}/${creditPack.id}`}
          intent="secondary-outline"
          className="w-fit"
        >
          {t("common.viewMore")}
        </Button>
      </HostedPagesCard.Footer>
    </HostedPagesCard.Container>
  );
};
