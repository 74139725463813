import { subject } from "@casl/ability";
import { faClose } from "@fortawesome/free-solid-svg-icons";
import { FontAwesomeIcon } from "@fortawesome/react-fontawesome";
import {
  useMutationHostAvailabilityEdit,
  useMutationHostAvailabilityOverrideCreate,
  useQueryHostAvailability,
} from "@gymflow/api";
import { HostAvailabilitySlot } from "@gymflow/types";
import classNames from "classnames";
import qs from "qs";
import { useContext, useState } from "react";
import { useHistory, useParams } from "react-router";

import { Can, Subject, Verb } from "../../permissions";
import { ModalContext } from "../../providers";
import { ToastContext } from "../../providers/ToastProvider/context";
import useGymflowModels from "../../store";
import { Button, PlusCircleIcon, Switch, TabButtonGroup } from "../atoms";
import {
  AvailabilityTable,
  StaffAvailabilityOverrideFormModal,
  StaffBlockedTimeTable,
} from "../organisms";

export function StaffAvailability() {
  const { api } = useGymflowModels();
  const history = useHistory();
  const { staffId } = useParams<{ staffId: string }>();
  const { data: staffAvailability, isFetching } = useQueryHostAvailability({
    staffId,
    api,
  });

  const { section } = qs.parse(history.location.search, {
    ignoreQueryPrefix: true,
  });

  const [selectedTabIdx, setSelectedTabIdx] = useState(
    section === "blocked" ? 1 : 0,
  );

  const createOverrideMutation = useMutationHostAvailabilityOverrideCreate({
    api,
  });
  const { setModal, hide } = useContext(ModalContext);
  const { notifyDanger } = useContext(ToastContext);
  const editAvailabilityMutation = useMutationHostAvailabilityEdit({ api });

  return (
    <Can
      I={Verb.View}
      a={subject(Subject.AppointmentAvailability, { id: staffId })}
    >
      <div className="bg-gray-0 flex h-screen flex-col justify-between">
        <div className="flex flex-col gap-y-4 p-8">
          <div className="flex flex-row items-center justify-between">
            <div className="flex flex-1 flex-row flex-wrap items-center justify-between">
              <div className="mr-4 flex max-w-[800px] flex-col">
                <div className="text-lg font-semibold">Availability</div>
                <div className="text-sm text-gray-600">
                  Manage your standard weekly availability for appointments and
                  block time out in your diary for holidays and other events.
                </div>
              </div>
              <div className="flex flex-wrap items-center"></div>
            </div>
            <div className="flex items-center justify-center gap-x-6 pl-4">
              {selectedTabIdx === 1 && (
                <Button
                  intent="primary"
                  onClick={() => {
                    setModal(
                      <StaffAvailabilityOverrideFormModal
                        staffId={staffId}
                        onCancel={() => {
                          hide();
                        }}
                        onConfirm={async (newValues) => {
                          try {
                            await createOverrideMutation.mutateAsync({
                              staffId,
                              newOverride: newValues,
                            });
                          } catch (e) {
                            notifyDanger(e as any);
                          }
                          hide();
                        }}
                        value={null}
                      />,
                    );
                  }}
                >
                  <div className="flex">
                    <PlusCircleIcon
                      className="mr-2 self-center"
                      pathClassName="stroke-white"
                    />
                    Block time
                  </div>
                </Button>
              )}
              <FontAwesomeIcon
                onClick={() => {
                  history.goBack();
                }}
                className="cursor-pointer text-xl text-gray-500"
                icon={faClose}
              />
            </div>
          </div>
          <div className="flex justify-between text-sm">
            <div>Available for appointment bookings?</div>
            <div>
              <Switch
                value={staffAvailability?.availableForAppointments || false}
                disabled={!staffAvailability}
                onChange={(checked) => {
                  editAvailabilityMutation.mutateAsync({
                    staffId,
                    params: {
                      availableForAppointments: checked,
                      availabilitySlotList:
                        staffAvailability!.availabilitySlotList,
                    },
                  });
                }}
              />
            </div>
          </div>
          <TabButtonGroup
            className={classNames({
              hidden: !staffAvailability?.availableForAppointments,
            })}
            tabs={[
              {
                title: "Regular Hours",
                onClick: (newTabIdx) => {
                  setSelectedTabIdx(newTabIdx);
                },
              },
              {
                title: "Blocked Time",
                onClick: (newTabIdx) => {
                  setSelectedTabIdx(newTabIdx);
                },
              },
            ]}
            selectedIdx={selectedTabIdx}
          />
        </div>

        <div
          className={classNames(
            "relative flex h-full max-h-full w-full max-w-full flex-col",
            {
              hidden: !staffAvailability?.availableForAppointments,
            },
          )}
        >
          {selectedTabIdx === 0 && (
            <AvailabilityTable
              availabilityType="STAFF"
              value={staffAvailability || null}
              isLoading={isFetching}
              isDisabled={
                !staffAvailability?.availableForAppointments || isFetching
              }
              onChange={async (newSlots) => {
                await editAvailabilityMutation.mutateAsync({
                  staffId,
                  params: {
                    availableForAppointments: true,
                    availabilitySlotList: newSlots as HostAvailabilitySlot[],
                  },
                });
              }}
            />
          )}
          {selectedTabIdx === 1 && <StaffBlockedTimeTable />}
        </div>
      </div>
    </Can>
  );
}
