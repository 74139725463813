import { faClose } from "@fortawesome/free-solid-svg-icons";
import { FontAwesomeIcon } from "@fortawesome/react-fontawesome";
import {
  appointableListQueryFn,
  facilityListQueryFn,
  memberQueryFn,
  staffListQueryFn,
} from "@gymflow/api";
import {
  DATE_FORMAT,
  PARAMETER_DATE_FORMAT_WITHOUT_TZ,
  useRecordForm,
} from "@gymflow/common";
import {
  AppointmentPostDTO,
  FacilityDTO,
  RecurringAppointmentPostDTO,
} from "@gymflow/types";
import classNames from "classnames";
import { Formik, useFormikContext } from "formik";
import moment from "moment-timezone";
import { useContext, useEffect, useState } from "react";
import * as Yup from "yup";

import { useClubSettings } from "../../../providers";
import useGymflowModels from "../../../store";
import {
  Button,
  DateSelect,
  MemberSelect,
  PaginatedSelect,
  SlideSideBar,
  Switch,
  TextInput,
} from "../../atoms";
import { TimeLimitSelect } from "../../organisms";
import { CollapsibleSections } from "../CollapsibleSections";
import { DatePicker } from "../DatePicker";
import { NewUserSideBarProviderContext } from "../NewUserSideBar";
import { createDateAndTimeSchemaFields } from "../SideBarOccurrence";
import { AppointmentFormMapper } from "./AppointmentFormMapper";
import {
  APPOINTABLE_ID,
  APPOINTMENT_FACILITY_ID,
  APPOINTMENT_HOST_ID,
  APPOINTMENT_USER_ID,
  createAppointmentSchema,
  createRecurrenceFields,
  createServiceDetailsSchemaFields,
  createUserSchemaFields,
  RECURRING_END_DATE,
  RECURRING_INTERVAL,
  RECURRING_MONTHLY_DAY_OF_WEEK,
  RECURRING_MONTHLY_DAY_POSITION,
  RECURRING_TYPE,
  RECURRING_WEEKLY_DAYS,
  recurringOptions,
  START_DATE,
} from "./AppointmentSchema";
import { MonthlyRadio, MonthlyRecurringType } from "./MonthlyRadio";
import { WeekdaySelector } from "./WeekdaySelector";

interface SideBarAppointmentFormProps {
  onClose: () => void;
  isLoaded: boolean;
  isVisible: boolean;
  defaultStartDate?: string;
  defaultHost?: { name: string; id: string };
  value: any | null;
  isLoading: boolean;
  onChange: (
    newValue:
      | { isRecurring: true; values: RecurringAppointmentPostDTO }
      | { isRecurring: false; values: Omit<AppointmentPostDTO, "status"> },
  ) => Promise<void>;
}

export function SideBarAppointmentForm({
  onClose,
  isLoaded,
  isVisible,
  defaultStartDate,
  defaultHost,
  value = null,
  isLoading,
  onChange,
}: SideBarAppointmentFormProps) {
  const referenceDate = defaultStartDate
    ? defaultStartDate
    : moment().format(PARAMETER_DATE_FORMAT_WITHOUT_TZ);

  const { timezone } = useClubSettings();
  const [isRecurring, setIsRecurring] = useState(false);
  const schema = createAppointmentSchema({
    date: referenceDate,
    isRecurring,
    timezone,
  });

  const [monthlyRecurringType, setMonthlyRecurringType] =
    useState<MonthlyRecurringType>("DAY_OF_THE_EVENT");
  const { initialValues, getValues } = useRecordForm({
    fields: schema.getDefault(),
    record: value,
    mapper: new AppointmentFormMapper({ isRecurring, monthlyRecurringType }),
  });
  const [isTabOpen, setIsTabOpen] = useState(isVisible);
  useEffect(() => {
    setIsTabOpen(isVisible);
  }, [isVisible]);

  return (
    <SlideSideBar
      isOpen={isTabOpen}
      hide={onClose}
      className="!w-[32rem]"
      isLoading={isLoading}
      unmount={false}
    >
      {isLoaded && (
        <div className="flex h-full max-h-full flex-col overflow-hidden">
          <Formik
            initialValues={initialValues}
            onSubmit={async (values) => {
              if (isRecurring) {
                const payload = getValues(values);
                onChange({ isRecurring, values: payload });
              } else {
                onChange({ isRecurring, values: getValues(values) });
              }
            }}
            validationSchema={schema}
          >
            {(formik) => {
              const values = formik.values;

              return (
                <>
                  <div className="flex h-full max-h-full flex-col overflow-hidden">
                    <div className="flex flex-col justify-between border-b border-gray-200 p-8">
                      <div className="mb-1 flex flex-row items-center justify-between">
                        <div className="text-xl font-semibold text-gray-900">
                          {value ? "Update Appointment" : "Add Appointment"}
                        </div>

                        <FontAwesomeIcon
                          onClick={() => {
                            onClose();
                          }}
                          className="cursor-pointer text-xl text-gray-600"
                          icon={faClose}
                        />
                      </div>
                      <div className="text-sm font-medium text-gray-600">
                        {value
                          ? "Update existing appointment details."
                          : "Book a member in for a service at your club."}
                      </div>
                      <div
                        className={classNames(
                          "text-sm font-medium text-gray-600",
                          {
                            hidden:
                              !values[APPOINTMENT_USER_ID] &&
                              !values[APPOINTABLE_ID] &&
                              !values[START_DATE],
                          },
                        )}
                      >
                        Booking For:{" "}
                        {values[APPOINTMENT_USER_ID] && (
                          <div className="inline-block font-bold">
                            {values[APPOINTMENT_USER_ID].label}
                          </div>
                        )}
                        {values[APPOINTMENT_USER_ID] &&
                          (values[APPOINTABLE_ID] || values[START_DATE]) &&
                          ", "}
                        {values[APPOINTABLE_ID] && (
                          <div className="inline-block">
                            {values[APPOINTABLE_ID].label}
                          </div>
                        )}
                        {values[START_DATE] && (
                          <div className="inline-block">
                            &nbsp;@&nbsp;
                            {moment(
                              values[START_DATE],
                              PARAMETER_DATE_FORMAT_WITHOUT_TZ,
                            ).format("hh:mm a")}
                          </div>
                        )}
                      </div>
                    </div>

                    <AppointmentForm
                      setIsTabOpen={setIsTabOpen}
                      referenceDate={referenceDate}
                      defaultHost={defaultHost}
                      isRecurring={isRecurring}
                      setIsRecurring={setIsRecurring}
                      monthlyRecurringType={monthlyRecurringType}
                      setMonthlyRecurringType={setMonthlyRecurringType}
                    />
                  </div>

                  <div className="flex h-20 flex-row items-center justify-end gap-2 border-t border-gray-200 px-6">
                    <Button onClick={() => onClose()} className="w-full">
                      Cancel
                    </Button>

                    <Button
                      intent="primary"
                      className="w-full"
                      onClick={async () => {
                        await formik.submitForm();
                      }}
                    >
                      Apply
                    </Button>
                  </div>
                </>
              );
            }}
          </Formik>
        </div>
      )}
    </SlideSideBar>
  );
}

function AppointmentForm({
  referenceDate,
  defaultHost,
  setIsTabOpen,
  isRecurring,
  setIsRecurring,
  monthlyRecurringType,
  setMonthlyRecurringType,
}: {
  referenceDate: string;
  defaultHost?: { name: string; id: string };
  setIsTabOpen: React.Dispatch<React.SetStateAction<boolean>>;
  isRecurring: boolean;
  setIsRecurring: React.Dispatch<React.SetStateAction<boolean>>;
  monthlyRecurringType: MonthlyRecurringType;
  setMonthlyRecurringType: React.Dispatch<
    React.SetStateAction<MonthlyRecurringType>
  >;
}) {
  const { api } = useGymflowModels();
  const [openSectionIdx, setOpenSectionIdx] = useState(0);

  const formikProps = useFormikContext();
  const { setFieldValue } = formikProps;
  const values = formikProps.values as Record<string, any>;
  const errors = formikProps.errors as Record<string, any>;

  const { open: openNewUserSideBar } = useContext(
    NewUserSideBarProviderContext,
  );

  const userSchemaTabIndex = 0;
  const serviceDetailsTabIndex = 1;
  const dateAndTimeTabIndex = 2;
  const recurrenceTabIndex = 3;

  const [appointableFacilities, setAppointableFacilities] =
    useState<FacilityDTO[]>();

  useEffect(() => {
    if (defaultHost) {
      setFieldValue(APPOINTMENT_HOST_ID, {
        label: defaultHost.name,
        value: defaultHost.id,
      });
    }
  }, [defaultHost]);

  useEffect(() => {
    const validateAndOpenInvalidSections = () => {
      const userSchema = Yup.object().shape(createUserSchemaFields());
      try {
        userSchema.validateSync(values);
      } catch {
        setOpenSectionIdx(userSchemaTabIndex);
        return;
      }

      const serviceDetailsSchema = Yup.object().shape(
        createServiceDetailsSchemaFields(),
      );
      try {
        serviceDetailsSchema.validateSync(values);
      } catch {
        setOpenSectionIdx(serviceDetailsTabIndex);
        return;
      }

      const dateAndTimeSchema = Yup.object().shape(
        createDateAndTimeSchemaFields(),
      );
      try {
        dateAndTimeSchema.validateSync(values);
      } catch {
        setOpenSectionIdx(dateAndTimeTabIndex);
        return;
      }

      if (isRecurring) {
        const recurrenceSchema = Yup.object().shape(createRecurrenceFields());
        try {
          recurrenceSchema.validateSync(values);
        } catch {
          setOpenSectionIdx(recurrenceTabIndex);
          return;
        }
      }
    };

    validateAndOpenInvalidSections();
  }, [formikProps.submitCount]);

  const sections = [
    {
      title: "Member",
      body: (
        <div className="flex flex-col">
          <label
            htmlFor={APPOINTMENT_USER_ID}
            className="mb-0 flex pb-2 text-sm font-medium text-gray-700"
          >
            Add Participant
          </label>
          <div className="flex gap-2">
            <div className="flex-1">
              <MemberSelect
                value={
                  values[APPOINTMENT_USER_ID]?.value
                    ? values[APPOINTMENT_USER_ID]
                    : null
                }
                onChange={function (newValue) {
                  setFieldValue(APPOINTMENT_USER_ID, {
                    value: newValue.value,
                    label: `${newValue.value.firstName} ${newValue.value.lastName}`,
                  });
                }}
                resultMap={(searchResult, idx) => ({
                  label: (
                    <MemberSearchResult
                      isFirst={idx === 0}
                      key={searchResult.id}
                      name={`${searchResult.firstName} ${searchResult.lastName}`}
                      email={searchResult.email}
                    />
                  ),
                  value: searchResult,
                })}
              />
            </div>
            <div>
              <Button
                onClick={() => {
                  setIsTabOpen(false);
                  openNewUserSideBar({
                    onClose: async (arg) => {
                      setIsTabOpen(true);
                      if (arg?.userType === "MEMBER") {
                        const newMemberDetails = await memberQueryFn({
                          api,
                          memberId: arg.userMemberId,
                        });
                        setFieldValue(APPOINTMENT_USER_ID, {
                          label: `${newMemberDetails.firstName} ${newMemberDetails.lastName}`,
                          value: newMemberDetails,
                        });
                      }
                    },
                    creationMode: "MEMBER",
                  });
                }}
              >
                New User
              </Button>
            </div>
          </div>
          <div
            className={classNames("text-error-700 pt-2 text-sm", {
              hidden: !errors[APPOINTMENT_USER_ID],
            })}
          >
            {errors[APPOINTMENT_USER_ID]}
          </div>
        </div>
      ),
    },
    {
      title: "Service",
      body: (
        <>
          <div className="flex flex-col">
            <label
              htmlFor={APPOINTABLE_ID}
              className="mb-0 flex pb-2 text-sm font-medium text-gray-700"
            >
              Select Appointment
            </label>
            <div>
              <PaginatedSelect
                value={
                  values[APPOINTABLE_ID]?.value ? values[APPOINTABLE_ID] : null
                }
                onChange={function (newValue) {
                  setFieldValue(APPOINTABLE_ID, {
                    label: newValue.value.name,
                    value: newValue.value.id,
                  });
                  setFieldValue(
                    "availability-type",
                    newValue.value.availabilityType,
                  );
                  setAppointableFacilities(
                    newValue.value.appointableFacilities,
                  );
                }}
                loadOptions={async function (_, __, additional) {
                  const data = await appointableListQueryFn({
                    api,
                    filter: {
                      page: additional.page,
                      limit: 10,
                      extraParams: { status: "ACTIVE" },
                    },
                  });

                  return {
                    options: data.content.map((appointable) => ({
                      label: (
                        <div>
                          {appointable.name}
                          <span className="text-gray-500">
                            ({appointable.duration} minutes)
                          </span>
                        </div>
                      ),
                      value: appointable,
                    })),
                    hasMore: !data.last,
                    additional: {
                      page: additional.page + 1,
                    },
                  };
                }}
              />
            </div>
            <div
              className={classNames("text-error-700 pt-2 text-sm", {
                hidden: !errors[APPOINTABLE_ID],
              })}
            >
              {errors[APPOINTABLE_ID]}
            </div>
          </div>
          <div className="flex flex-col">
            <label
              htmlFor={APPOINTMENT_HOST_ID}
              className="mb-0 flex pb-2 text-sm font-medium text-gray-700"
            >
              Select Host
            </label>
            <div
              className={classNames({
                hidden: (values?.["availability-type"] ?? "STAFF") !== "STAFF",
              })}
            >
              <PaginatedSelect
                value={
                  values[APPOINTMENT_HOST_ID]?.value
                    ? values[APPOINTMENT_HOST_ID]
                    : null
                }
                onChange={function (newValue) {
                  setFieldValue(APPOINTMENT_HOST_ID, newValue);
                }}
                loadOptions={async function (_, __, additional) {
                  const data = await staffListQueryFn({
                    api,
                    opts: {
                      page: additional.page,
                      limit: 10,
                      extraParams: {
                        availableForAppointments: true,
                        appointableList: values[APPOINTABLE_ID]?.value
                          ? values[APPOINTABLE_ID].value
                          : undefined,
                      },
                    },
                  });

                  return {
                    options: data.content.map(
                      ({ id, firstName, lastName }) => ({
                        label: `${firstName} ${lastName}`,
                        value: id,
                      }),
                    ),
                    hasMore: !data.last,
                  };
                }}
                cacheUniqs={[values[APPOINTABLE_ID]?.value]}
              />
            </div>
            <div
              className={classNames("text-error-700 pt-2 text-sm", {
                hidden:
                  (values?.["availability-type"] ?? "STAFF") !== "STAFF" ||
                  !errors[APPOINTMENT_HOST_ID],
              })}
            >
              {errors[APPOINTMENT_HOST_ID]}
            </div>

            <div
              className={classNames({
                hidden: values?.["availability-type"] !== "FACILITY",
              })}
            >
              <PaginatedSelect
                value={
                  values[APPOINTMENT_FACILITY_ID]?.value
                    ? values[APPOINTMENT_FACILITY_ID]
                    : null
                }
                onChange={function (newValue) {
                  setFieldValue(APPOINTMENT_FACILITY_ID, newValue);
                }}
                loadOptions={async function (_, __, additional) {
                  const data = await facilityListQueryFn({
                    api,
                    opts: {
                      page: additional.page,
                      limit: 10,
                      extraParams: {
                        availableForAppointments: true,
                      },
                    },
                  });

                  return {
                    options: data.content
                      .filter((facility) => {
                        if (appointableFacilities !== undefined) {
                          return appointableFacilities.some(
                            (appointableFacility) => {
                              return appointableFacility.id === facility.id;
                            },
                          );
                        }
                        return true;
                      })
                      .map(({ id, name }) => ({
                        label: name,
                        value: id,
                      })),
                    hasMore: !data.last,
                    additional: {
                      page: additional.page + 1,
                    },
                  };
                }}
                cacheUniqs={[values[APPOINTMENT_FACILITY_ID]?.value]}
              />
            </div>
            <div
              className={classNames("text-error-700 pt-2 text-sm", {
                hidden:
                  values?.["availability-type"] !== "FACILITY" ||
                  !errors[APPOINTMENT_FACILITY_ID],
              })}
            >
              {errors[APPOINTMENT_FACILITY_ID]}
            </div>
          </div>
        </>
      ),
    },
    {
      title: "Date & Time",
      body: (
        <>
          <div className="flex flex-col">
            <label
              htmlFor={START_DATE}
              className="mb-0 flex pb-2 text-sm font-medium text-gray-700"
            >
              Date
            </label>
            <div>
              <DateSelect
                value={
                  values[START_DATE] &&
                  moment(
                    values[START_DATE],
                    PARAMETER_DATE_FORMAT_WITHOUT_TZ,
                  ).format(DATE_FORMAT)
                }
                onChange={(newDate) => {
                  const existingMoment = moment(
                    values[START_DATE],
                    PARAMETER_DATE_FORMAT_WITHOUT_TZ,
                  );
                  const newMoment = moment(
                    `${newDate} ${existingMoment.format("HH:mm")}`,
                    `${DATE_FORMAT} HH:mm`,
                  );
                  setFieldValue(
                    START_DATE,
                    newMoment.format(PARAMETER_DATE_FORMAT_WITHOUT_TZ),
                  );
                }}
                dateFormat={DATE_FORMAT}
                dateToStartFrom={moment(
                  referenceDate,
                  PARAMETER_DATE_FORMAT_WITHOUT_TZ,
                ).format(DATE_FORMAT)}
              />
            </div>
          </div>

          <div className="flex flex-col">
            <label
              htmlFor={START_DATE}
              className="mb-0 flex pb-2 text-sm font-medium text-gray-700"
            >
              Start Time
            </label>
            <div className="flex">
              <TimeLimitSelect
                className="w-100"
                value={
                  values[START_DATE]
                    ? moment(
                        values[START_DATE],
                        PARAMETER_DATE_FORMAT_WITHOUT_TZ,
                      ).format("HH:mm")
                    : "00:00"
                }
                minTime={"00:00"}
                onChange={(newTime) => {
                  const existingMoment = moment(
                    values[START_DATE],
                    PARAMETER_DATE_FORMAT_WITHOUT_TZ,
                  );
                  const newMoment = moment(
                    `${existingMoment.format("YYYY-MM-DD")}T${newTime}`,
                    `YYYY-MM-DDTHH:mm`,
                  );
                  setFieldValue(
                    START_DATE,
                    newMoment.format(PARAMETER_DATE_FORMAT_WITHOUT_TZ),
                  );
                }}
              />
            </div>
            <div
              className={classNames("text-error-700 pt-2 text-sm", {
                hidden: !errors[START_DATE],
              })}
            >
              {errors[START_DATE]}
            </div>
          </div>
        </>
      ),
    },
  ];
  sections.push({
    title: "Recurrence",
    body: (
      <>
        <div className="flex gap-3">
          <div>
            <Switch
              value={isRecurring}
              onChange={(checked) => {
                if (checked) {
                  setFieldValue(RECURRING_TYPE, "DAILY", true);
                } else {
                  setFieldValue(RECURRING_TYPE, undefined, true);
                }
                setIsRecurring(checked);
              }}
            />
          </div>
          <div className="mb-0 pb-2 text-sm font-medium text-gray-700">
            Repeat
          </div>
        </div>
        {isRecurring && (
          <>
            <div className="flex flex-col">
              <label
                htmlFor={START_DATE}
                className="mb-0 flex pb-2 text-sm font-medium text-gray-700"
              >
                Every
              </label>
              <div className="flex gap-4">
                <TextInput
                  className="flex-1"
                  value={values[RECURRING_INTERVAL]}
                  onChange={({ currentTarget: { value } }) => {
                    setFieldValue(RECURRING_INTERVAL, value);
                  }}
                />
                <PaginatedSelect
                  className="flex-1"
                  value={recurringOptions.find(
                    (option) => option.value === values[RECURRING_TYPE],
                  )}
                  onChange={(newValue) => {
                    setFieldValue(RECURRING_TYPE, newValue.value);
                  }}
                  loadOptions={() =>
                    Promise.resolve({ options: recurringOptions })
                  }
                />
              </div>
              <div
                className={classNames("text-error-700 pt-2 text-sm", {
                  hidden: !errors[RECURRING_INTERVAL],
                })}
              >
                {errors[RECURRING_INTERVAL]}
              </div>
            </div>
            {values[RECURRING_TYPE] === "WEEKLY" && (
              <div className="flex flex-col">
                <WeekdaySelector
                  value={values[RECURRING_WEEKLY_DAYS] || []}
                  onChange={(weekdays) => {
                    setFieldValue(RECURRING_WEEKLY_DAYS, weekdays);
                  }}
                />
              </div>
            )}
            {values[RECURRING_TYPE] === "MONTHLY" && (
              <div>
                <MonthlyRadio
                  value={{
                    option: monthlyRecurringType,
                    specificDay:
                      values[RECURRING_MONTHLY_DAY_POSITION] &&
                      values[RECURRING_MONTHLY_DAY_OF_WEEK]
                        ? {
                            position: values[RECURRING_MONTHLY_DAY_POSITION],
                            weekday: values[RECURRING_MONTHLY_DAY_OF_WEEK],
                          }
                        : undefined,
                  }}
                  onChange={(newOption) => {
                    setMonthlyRecurringType(newOption.option);
                    if (newOption.option === "SPECIFIC_DAY") {
                      setFieldValue(
                        RECURRING_MONTHLY_DAY_POSITION,
                        newOption.specificDay?.position,
                      );
                      setFieldValue(
                        RECURRING_MONTHLY_DAY_OF_WEEK,
                        newOption.specificDay?.weekday,
                      );
                    } else {
                      setFieldValue(RECURRING_MONTHLY_DAY_POSITION, undefined);
                      setFieldValue(RECURRING_MONTHLY_DAY_OF_WEEK, undefined);
                    }
                  }}
                />
              </div>
            )}
            <div className="flex flex-col">
              <label
                htmlFor={RECURRING_END_DATE}
                className="mb-0 flex pb-2 text-sm font-medium text-gray-700"
              >
                Until
              </label>
              <div className="flex gap-4">
                <DatePicker
                  inputType="field"
                  label={
                    values[RECURRING_END_DATE] &&
                    moment(values[RECURRING_END_DATE], DATE_FORMAT).format(
                      "Do MMMM YYYY",
                    )
                  }
                  mode="single"
                  isClearable
                  selected={
                    values[RECURRING_END_DATE] &&
                    moment(values[RECURRING_END_DATE], DATE_FORMAT).toDate()
                  }
                  handleDateSave={(newDate) => {
                    if (!newDate) {
                      setFieldValue(RECURRING_END_DATE, undefined);
                      return;
                    }
                    setFieldValue(
                      RECURRING_END_DATE,
                      moment(newDate).format(DATE_FORMAT),
                    );
                  }}
                />
              </div>
              <div
                className={classNames("text-error-700 pt-2 text-sm", {
                  hidden: !errors[RECURRING_END_DATE],
                })}
              >
                {errors[RECURRING_END_DATE]}
              </div>
            </div>
          </>
        )}
      </>
    ),
  });
  return (
    <div className="flex h-full max-h-full flex-col overflow-y-auto">
      <CollapsibleSections
        sections={sections}
        onChangeSection={(_, sectionIdx) => {
          setOpenSectionIdx(sectionIdx);
        }}
        openSectionIndex={openSectionIdx}
      />
    </div>
  );
}

function MemberSearchResult({
  name,
  email,
  isFirst,
}: {
  name: string;
  email: string;
  isFirst: boolean;
}) {
  return (
    <div
      className={classNames(
        "-mx-3 -my-2 flex items-center justify-between px-8 py-4",
        {
          "border-t border-t-gray-200": !isFirst,
        },
      )}
    >
      <div className="overflow-hidden text-left">
        <div className="overflow-hidden text-ellipsis font-medium text-gray-900">
          {name}
        </div>
        <div className="overflow-hidden text-ellipsis text-sm font-normal text-gray-600">
          {email}
        </div>
      </div>
      <div>
        <Button intent="link">Add</Button>
      </div>
    </div>
  );
}
