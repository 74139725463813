import { formatCurrency, membershipHelper } from "@gymflow/helpers";
import { MembershipBean } from "@gymflow/types";
import { useClubSettings } from "apps/portal/src/providers";
import React from "react";
import { useTranslation } from "react-i18next";

import { Button, CreditsIcon, InfinityIcon } from "../../../atoms";
import { HostedPagesCard } from "../../components/HostedPagesCard";

export type HostedPagesMembershipCardProps = {
  membership: MembershipBean;
};

export const HostedPagesMembershipCard: React.FC<
  HostedPagesMembershipCardProps
> = ({ membership }) => {
  const { t } = useTranslation();
  const settings = useClubSettings();
  const totalSessions = (membership.membershipAddonList ?? []).reduce(
    (acc, next) => {
      return acc + (next.sessionsUnlimited ? Infinity : next.credits ?? 0);
    },
    0,
  );
  const membershipType = membershipHelper.getMembershipType(membership);
  return (
    <HostedPagesCard.Container>
      <HostedPagesCard.Header>
        <div className="flex flex-row items-start justify-between gap-4">
          <div className="dark:text-gray-0 flex-wrap font-bold text-gray-950">
            {membership.name}
          </div>

          {(totalSessions > 0 || membershipType === "recurring") && (
            <div className="flex flex-row gap-2">
              {!Number.isFinite(totalSessions) ? (
                <InfinityIcon className="stroke-gray-950 dark:stroke-gray-50" />
              ) : (
                <div className="dark:text-gray-0 font-bold text-gray-950">
                  {totalSessions || "-"}
                </div>
              )}
              <CreditsIcon pathClassName="stroke-secondary-500" />
            </div>
          )}
        </div>
        <HostedPagesCard.Description
          description={membership.description}
          hideTooltip
        />
      </HostedPagesCard.Header>
      <HostedPagesCard.Separator />
      <HostedPagesCard.Footer>
        <HostedPagesCard.Price
          price={
            membership.defaultPrice === 0
              ? t("common.free")
              : formatCurrency(
                  membership.defaultPrice,
                  settings.defaultCurrency,
                )
          }
          description={membershipHelper.getBillingRecurrence(membership)}
        />

        <Button
          link={`${document.location.pathname}/${membership.id}`}
          intent="secondary-outline"
          className="w-fit"
        >
          {t("common.viewMore")}
        </Button>
      </HostedPagesCard.Footer>
    </HostedPagesCard.Container>
  );
};
