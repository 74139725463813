import { SMSPurpose } from "@gymflow/types";
import {
  Badge,
  Button,
  CloseIcon,
  FormLabeledSwitch,
  FormRadioButtonGroup,
  FormSMSTextInput,
  FormTimeSelect,
  SlideSideBar,
} from "apps/portal/src/components/atoms";
import { getDateTimeInGym } from "apps/portal/src/helpers/date";
import { useClubSettings } from "apps/portal/src/providers";
import { FormikProvider, useFormik } from "formik";
import { useEffect, useMemo, useRef } from "react";

import { FormSingleDatePicker } from "../../atoms/form/FormSingleDatePicker/FormSingleDatePicker";
import {
  INITIAL_VALUES_SEND_SMS,
  MARKETING_OPTIONS,
  sendSMSSchema,
  SMS_TEXT_INPUT_ROWS,
} from "./constants";
import { SendSMSFormValues, SendSMSSidebarProps } from "./types";

export const SendSMSSidebar = ({
  isVisible,
  onClose,
  recipient,
  onSubmit,
  description,
  hideMarketingOption,
  hideScheduleOption,
}: SendSMSSidebarProps) => {
  const { timezone } = useClubSettings();
  const inputRef = useRef<HTMLTextAreaElement>(null);

  const formik = useFormik<SendSMSFormValues>({
    initialValues: INITIAL_VALUES_SEND_SMS,
    validationSchema: sendSMSSchema(timezone),
    onSubmit,
  });

  useEffect(() => {
    if (isVisible) return;
    formik.resetForm();
    // eslint-disable-next-line react-compiler/react-compiler
    // eslint-disable-next-line react-hooks/exhaustive-deps
  }, [isVisible]);

  const { isSchedule } = formik.values;

  // TODO: uncomment the "placeholders" feature after the backend implements it on their side
  // const loadOptions = useCallback(async () => {
  //   const result = await automationPlaceholderLabelsQueryFn({ // TODO: maybe you will need to change this request (waiting for BE)
  //     api,
  //     placeholderType: "GENERIC",
  //   });

  //   return {
  //     options: Object.values(result).map((item) => ({
  //       label: item,
  //       value: item,
  //     })),
  //   };
  // }, [api]);

  // TODO: uncomment the "placeholders" feature after the backend implements it on their side
  // const handlePlaceholderSelect = useCallback(
  //   ({ label }: HandlePlaceholderSelect) => {
  //     const inputElement = inputRef.current;

  //     if (!label || !inputElement) return;

  //     const { selectionStart, selectionEnd } = inputElement; // getting the cursor position

  //     const newMessage =
  //       message.substring(0, selectionStart) +
  //       `\${${label}} ` +
  //       message.substring(selectionEnd); // insert the text at the desired position

  //     formik.setFieldValue("message", newMessage); // update value of message field

  //     const cursonPosition = selectionStart + label.length + 1;
  //     inputElement.setSelectionRange(cursonPosition, cursonPosition); // update the cursor position
  //   },
  //   [formik, message],
  // );

  const disabledDate = useMemo(() => {
    const nowInGym = getDateTimeInGym(timezone);

    return {
      before: nowInGym.toDate(),
      after: nowInGym.add(33, "days").toDate(),
    };
  }, [timezone]);

  return (
    <SlideSideBar isOpen={isVisible} hide={onClose} className="w-[32.25rem]">
      <FormikProvider value={formik}>
        <div className="flex h-full flex-col overflow-hidden">
          <div className="flex-1 overflow-y-auto">
            <div className="flex flex-col justify-between px-6 pt-6">
              <div className="mb-1 flex flex-row items-center justify-between">
                <div className="text-lg font-semibold text-gray-900">
                  Send SMS
                </div>
                <div className="cursor-pointer" onClick={onClose}>
                  <CloseIcon />
                </div>
              </div>
              <div className="text-sm text-gray-600">{description}</div>
            </div>
            <div className="flex flex-col gap-5 p-6">
              {!hideMarketingOption && (
                <div className="flex flex-col gap-4">
                  <div className="text-sm font-medium text-gray-950">
                    Marketing?
                  </div>
                  <FormRadioButtonGroup<SendSMSFormValues, SMSPurpose>
                    name="marketing"
                    options={MARKETING_OPTIONS}
                  />
                </div>
              )}
              <div className="flex items-center justify-between">
                <div className="text-sm font-medium text-gray-950">
                  Recipients:
                </div>
                <Badge className="text-sm font-medium text-gray-950 ring-gray-200">
                  {recipient}
                </Badge>
              </div>
              <div className="flex flex-col gap-3.5">
                <div className="flex items-center justify-between">
                  <div className="text-sm font-medium text-gray-950">
                    Message
                  </div>
                  {/* <PaginatedSelect TODO: uncomment the "placeholders" feature after the backend implements it on their side
                    placeholder="Placeholders"
                    value="" // it's ok, we need it for the "always placeholder show"
                    onChange={handlePlaceholderSelect}
                    loadOptions={loadOptions}
                  /> */}
                </div>
                <FormSMSTextInput<SendSMSFormValues>
                  innerRef={inputRef}
                  name="message"
                  rows={SMS_TEXT_INPUT_ROWS}
                />
              </div>
              {!hideScheduleOption && (
                <FormLabeledSwitch<SendSMSFormValues>
                  name="isSchedule"
                  label="Schedule SMS"
                />
              )}
              {isSchedule && (
                <div className="flex flex-col gap-3">
                  <FormSingleDatePicker<SendSMSFormValues>
                    name="date"
                    disabled={disabledDate}
                    label="Date"
                  />
                  <FormTimeSelect<SendSMSFormValues>
                    name="time"
                    fullWidth
                    label="Time"
                  />
                </div>
              )}
            </div>
          </div>
          <div className="flex h-20 flex-row items-center justify-end gap-3 px-6">
            <Button
              intent="default"
              className="w-full text-gray-950"
              onClick={onClose}
            >
              Cancel
            </Button>
            <Button
              intent="secondary"
              className="w-full"
              onClick={async () => await formik.submitForm()}
            >
              {isSchedule ? "Schedule" : "Send"}
            </Button>
          </div>
        </div>
      </FormikProvider>
    </SlideSideBar>
  );
};
