import React from "react";

export type PMLinkIconProps = {
  className?: string;
};

export const PMLinkIcon: React.FC<PMLinkIconProps> = ({ className }) => {
  return (
    <svg
      width="34"
      height="24"
      viewBox="0 0 34 24"
      fill="none"
      xmlns="http://www.w3.org/2000/svg"
      className={className}
    >
      <path
        d="M0.5 4C0.5 2.067 2.067 0.5 4 0.5H30C31.933 0.5 33.5 2.067 33.5 4V20C33.5 21.933 31.933 23.5 30 23.5H4C2.067 23.5 0.5 21.933 0.5 20V4Z"
        fill="white"
      />
      <path
        d="M0.5 4C0.5 2.067 2.067 0.5 4 0.5H30C31.933 0.5 33.5 2.067 33.5 4V20C33.5 21.933 31.933 23.5 30 23.5H4C2.067 23.5 0.5 21.933 0.5 20V4Z"
        stroke="#E4E7EC"
      />
      <circle cx="8.81105" cy="11.8111" r="4.81105" fill="#61D37A" />
      <path
        d="M6.78574 8.85718H8.11228C8.43654 9.59364 9.36807 11.0666 10.5001 11.0666V12.2193C9.36807 12.2193 8.43654 13.6922 8.11228 14.4287H6.78574C7.22792 13.5641 8.23609 11.7966 8.73133 11.6429C8.23609 11.4892 7.22792 9.72172 6.78574 8.85718Z"
        fill="black"
      />
      <path d="M16.8975 8.6377H15.8057V15.7349H16.8975V8.6377Z" fill="black" />
      <path d="M19.0812 10.8215H17.9893V15.735H19.0812V10.8215Z" fill="black" />
      <path d="M21.2652 10.8215H20.1733V15.735H21.2652V10.8215Z" fill="black" />
      <path
        d="M21.2651 11.0945V12.4594C21.4399 12.1098 21.7464 11.844 22.1172 11.7204L22.1954 11.6943C22.3025 11.6586 22.4145 11.6405 22.5273 11.6405H22.7342C23.0049 11.6405 23.2524 11.7934 23.3734 12.0355C23.423 12.1347 23.4489 12.2441 23.4489 12.3551V15.735H24.5407V12.1372C24.5407 11.989 24.5169 11.8417 24.47 11.7012C24.3417 11.3164 24.0506 11.0077 23.6741 10.857L23.5052 10.7895C23.1163 10.6339 22.6849 10.6213 22.2876 10.7537L21.2651 11.0945Z"
        fill="black"
      />
      <circle cx="18.5352" cy="9.18363" r="0.545935" fill="black" />
      <path d="M26.7243 8.6377H25.6324V15.7349H26.7243V8.6377Z" fill="black" />
      <path
        d="M30.0003 10.8215H28.8304C28.5445 11.471 27.723 12.77 26.7247 12.77V13.7865C27.723 13.7865 28.5445 15.0855 28.8304 15.735H30.0003C29.6104 14.9725 28.7213 13.4138 28.2845 13.2782C28.7213 13.1427 29.6104 11.584 30.0003 10.8215Z"
        fill="black"
      />
    </svg>
  );
};
