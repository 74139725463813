import { cn } from "@gymflow/helpers";
import { Trans } from "react-i18next";
import { z } from "zod";
import { toFormikValidationSchema } from "zod-formik-adapter";

import { TabType } from "../../../atoms";
import CashIcon from "../../../atoms/icons/CashIcon";
import { CreditCard02Icon } from "../../../atoms/icons/CreditCard02Icon";
import {
  CollectPaymentManuallyFormValues,
  TabsCollectManuallyType,
} from "./types";

export const TABS: TabType<TabsCollectManuallyType>[] = [
  {
    id: "ONLINE",
    label: (isActive) => (
      <>
        <CreditCard02Icon
          className="h-7 w-7"
          pathClassName={cn("stroke-1", { "stroke-secondary-700": isActive })}
        />
        <Trans i18nKey="page.userMember.tab.payments.sidebar.collectManually.tabs.online" />
      </>
    ),
  },
  {
    id: "IN_PERSON",
    label: (isActive) => (
      <>
        <CashIcon
          className="h-7 w-7"
          pathClassName={cn({ "fill-secondary-700": isActive })}
        />
        <Trans i18nKey="page.userMember.tab.payments.sidebar.collectManually.tabs.inPerson" />
      </>
    ),
  },
];

export const INITIAL_VALUES_COLLECT_PAYMENT_MANUALLY_FORM: CollectPaymentManuallyFormValues =
  {
    activeTabId: TABS[0].id,
    paymentMethodId: undefined,
    inPersonPaymentMethod: undefined,
  };

export const collectPaymentManuallyFormSchema = toFormikValidationSchema(
  z
    .object({
      activeTabId: z.enum(["ONLINE", "IN_PERSON"]),
      inPersonPaymentMethod: z.custom((e) => e).optional(),
      paymentMethodId: z.string().optional(),
    })
    .superRefine((data, ctx) => {
      if (data.activeTabId === "IN_PERSON" && !data.inPersonPaymentMethod) {
        ctx.addIssue({
          path: ["inPersonPaymentMethod"],
          code: z.ZodIssueCode.custom,
          message: "This field is required",
        });
      }

      if (data.activeTabId === "ONLINE" && !data.paymentMethodId) {
        ctx.addIssue({
          path: ["paymentMethodId"],
          code: z.ZodIssueCode.custom,
          message: "Add payment method",
        });
      }
    }),
);
