import { ApiType } from "@gymflow/common/lib/api/ApiType";
import { useMutation, useQueryClient } from "@tanstack/react-query";

import { membershipQueryKeys } from "./membershipQueryKeys";

export function useMembershipEnable({ api }: { api: ApiType }) {
  const queryClient = useQueryClient();
  const mutation = useMutation({
    mutationFn: async (membershipId: number) => {
      await api.serviceApi.activate(membershipId);
    },
    onSuccess: () => {
      queryClient.invalidateQueries({ queryKey: membershipQueryKeys.all() });
    },
  });

  return mutation;
}
