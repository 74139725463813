import React from "react";

export interface FacilityAvatarProps {
  facility: {
    name: string;
    picture?: string;
  };
}

export const FacilityAvatar: React.FC<FacilityAvatarProps> = ({ facility }) => {
  return (
    <div className="flex flex-row gap-2">
      <div className="flex h-5 w-5 items-center justify-center overflow-hidden rounded-full border border-gray-100 dark:border-gray-900">
        {facility.picture ? (
          <img src={facility.picture} alt="avatar" />
        ) : (
          <div className="flex h-5 w-5 items-center justify-center text-xs font-semibold text-gray-500 ">
            {facility.name[0]}
          </div>
        )}
      </div>
      <div className="flex flex-col justify-center">
        <div className="line-clamp-1 text-sm font-semibold text-gray-950 dark:text-gray-0">
          {facility.name}
        </div>
      </div>
    </div>
  );
};
