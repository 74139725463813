import { Iso2Country, UserFormRule } from "@gymflow/types";
import { validatePhoneNumber, validatePostCode } from "@gymflow/validation";
import { DateTime } from "luxon";
import z, { ZodType } from "zod";

function option<T extends ZodType>(isOptional: boolean, source: T) {
  if (isOptional)
    return z.preprocess((e) => (e === "" ? undefined : e), source.optional());
  return source;
}
export const generateMemberSchema = ({
  rules,
  postcodeCountry,
}: {
  rules: UserFormRule;
  postcodeCountry: Iso2Country;
}) =>
  z
    .object({
      email: z.string().email(),
      firstName: z.string().trim().min(2),
      lastName: z.string().trim().min(2),
      mobileNumber: option(
        !rules.mobileNumber.isRequired,
        z.string().trim(),
      ).refine(
        (e) => {
          return e === undefined || validatePhoneNumber(e);
        },
        { message: `validation.phoneNumber` },
      ),

      dateBirth: option(!rules.dateOfBirth.isRequired, z.date()).refine(
        (e) => {
          return e === undefined || DateTime.fromJSDate(e) < DateTime.now();
        },
        {
          message: "validation.beforeToday",
          params: {
            maximum: DateTime.now().toLocaleString(DateTime.DATE_MED),
          },
        },
      ),
      postCode: option(!rules.postCode.isRequired, z.string()).refine(
        (e) => e === undefined || validatePostCode(e, postcodeCountry),
        { message: `validation.postCode` },
      ),
      addressLine1: option(
        !rules.addressLine.isRequired,
        z.string().trim().min(3),
      ),
      addressLine2: z.string().trim().optional(),
      city: option(!rules.city.isRequired, z.string().trim().min(3)),
      gender: option(
        !rules.gender.isRequired,
        z.enum(["MALE", "FEMALE", "PREFER_NOT_TO_SAY"]),
      ),
      emailCommunication: z.boolean(),
      smsCommunication: z.boolean(),
      pushCommunication: z.boolean(),
      emergencyContactName: option(
        !rules.emergencyContact.isRequired,
        z.string().min(3),
      ),
      emergencyContact: option(
        !rules.emergencyContact.isRequired,
        z.string(),
      ).refine((e) => e === undefined || validatePhoneNumber(e), {
        message: `validation.phoneNumber`,
      }),
      isClubWaiverAccepted: z.boolean(),
      personalNumber: option(!rules.personalNumber.isRequired, z.string()),
      picture: z
        .object({
          url: z.string().optional(),
          blob: z.any(),
        })
        .optional(),
    })
    .refine(
      (user) => {
        if (
          user.addressLine2 &&
          user.addressLine2?.length > 0 &&
          (!user.addressLine1 || user.addressLine1?.length === 0)
        )
          return false;
        return true;
      },
      {
        path: ["addressLine1"],
        message: "validation.addressLine2RequiresAddressLine1",
      },
    )
    .refine(
      (user) => {
        if (!rules.mobileNumber.isRequired) return true;
        if (!rules.emergencyContact.isRequired) return true;
        if (user.mobileNumber === user.emergencyContact) return false;
        return true;
      },
      {
        path: ["emergencyContact"],
        message: "validation.noDuplicateContact",
      },
    )
    .refine(
      (user) => {
        if (!rules.emergencyContact.isRequired) return true;
        if (!user.emergencyContactName && !user.emergencyContact) return true;
        if (!user.emergencyContactName && !!user.emergencyContact) return false;
        return true;
      },
      {
        path: ["emergencyContactName"],
        message: "validation.emergencyContactWithoutName",
      },
    )
    .refine(
      (user) => {
        if (!rules.emergencyContact.isRequired) return true;
        if (!user.emergencyContactName && !user.emergencyContact) return true;
        if (!!user.emergencyContactName && !user.emergencyContact) return false;
        return true;
      },
      {
        path: ["emergencyContact"],
        message: "validation.emergencyContactWithoutNumber",
      },
    );
