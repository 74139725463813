export const CreditCard02Icon = ({
  className,
  pathClassName,
}: {
  className?: string;
  pathClassName?: string;
}) => (
  <svg
    width="20"
    height="20"
    viewBox="0 0 20 20"
    fill="none"
    xmlns="http://www.w3.org/2000/svg"
    className={className}
  >
    <path
      d="M18.3333 8.33329H1.66663M9.16663 11.6666H4.99996M1.66663 6.83329L1.66663 13.1666C1.66663 14.1 1.66663 14.5668 1.84828 14.9233C2.00807 15.2369 2.26304 15.4918 2.57664 15.6516C2.93316 15.8333 3.39987 15.8333 4.33329 15.8333L15.6666 15.8333C16.6 15.8333 17.0668 15.8333 17.4233 15.6516C17.7369 15.4918 17.9919 15.2369 18.1516 14.9233C18.3333 14.5668 18.3333 14.1 18.3333 13.1666V6.83329C18.3333 5.89987 18.3333 5.43316 18.1516 5.07664C17.9919 4.76304 17.7369 4.50807 17.4233 4.34828C17.0668 4.16663 16.6 4.16663 15.6666 4.16663L4.33329 4.16663C3.39987 4.16663 2.93316 4.16663 2.57664 4.34828C2.26304 4.50807 2.00807 4.76304 1.84828 5.07664C1.66663 5.43316 1.66663 5.89987 1.66663 6.83329Z"
      stroke="#667085"
      strokeWidth="1.5"
      strokeLinecap="round"
      strokeLinejoin="round"
      className={pathClassName}
    />
  </svg>
);
