import { clubQueryKeys, customPaymentCategoryQueryFn } from "@gymflow/api";
import { CustomPaymentCategoryDTO } from "@gymflow/types";
import { InfiniteData, UseInfiniteQueryOptions } from "@tanstack/react-query";
import { useClubSettings } from "apps/portal/src/providers";
import useGymflowModels from "apps/portal/src/store";
import { useCallback } from "react";

import {
  SelectInput,
  SelectInputOptionType,
  SelectInputProps,
} from "./SelectInput";

export type CustomPaymentCategorySelectInputProps =
  SelectInputProps<CustomPaymentCategoryDTO>;

export const CustomPaymentCategorySelectInput = (
  props: CustomPaymentCategorySelectInputProps,
) => {
  const { api } = useGymflowModels();
  const { clubId } = useClubSettings();

  const dataFetchingQuery = useCallback(
    (): UseInfiniteQueryOptions<
      unknown,
      unknown,
      SelectInputOptionType<CustomPaymentCategoryDTO>[]
    > => ({
      queryKey: clubQueryKeys.customPaymentCategory(clubId),
      queryFn: async () => await customPaymentCategoryQueryFn({ clubId, api }),
      select: (data) => {
        const { pageParams, pages } = data as InfiniteData<
          CustomPaymentCategoryDTO[]
        >;

        const transformedPages = pages.map((page) =>
          page.map((value) => ({
            id: value.id,
            label: value.name,
            value: value,
          })),
        );

        return {
          pageParams,
          pages: transformedPages,
        };
      },
      getNextPageParam: () => undefined,
    }),
    [api, clubId],
  );

  return <SelectInput {...props} dataFetchingQuery={dataFetchingQuery} />;
};
