import { ApiType } from "@gymflow/common/lib/api/ApiType";
import { RuleClubDTO } from "@gymflow/types";
import { useQuery, UseQueryOptions } from "@tanstack/react-query";

import { ruleQueryKeys } from "./ruleQueryKeys";

export function useQueryUserFormRules(
  {
    api,
  }: {
    api: ApiType;
  },
  opts?: UseQueryOptions<RuleClubDTO[]>,
) {
  return useQuery({
    queryKey: ruleQueryKeys.all(),
    queryFn: async () => {
      return (await api.ruleApi.getAllRules()).data;
    },
    ...opts,
  });
}
