export type LikeIconProps = {
  className?: string;
  pathClassName?: string;
};

const LikeIcon = ({ className, pathClassName }: LikeIconProps) => {
  return (
    <svg
      xmlns="http://www.w3.org/2000/svg"
      width="16"
      height="16"
      viewBox="0 0 16 16"
      fill="none"
      className={className}
    >
      <g clipPath="url(#clip0_8533_738)">
        <path
          d="M4.66659 14.6667V7.33337M1.33325 8.66671V13.3334C1.33325 14.0698 1.93021 14.6667 2.66659 14.6667H11.6174C12.6046 14.6667 13.4441 13.9465 13.5942 12.9708L14.3121 8.30416C14.4985 7.09264 13.5611 6.00004 12.3354 6.00004H9.99992C9.63173 6.00004 9.33325 5.70156 9.33325 5.33337V2.97727C9.33325 2.06937 8.59726 1.33337 7.68936 1.33337C7.47281 1.33337 7.27657 1.4609 7.18862 1.65879L4.84254 6.93747C4.73554 7.17822 4.4968 7.33337 4.23334 7.33337H2.66659C1.93021 7.33337 1.33325 7.93033 1.33325 8.66671Z"
          stroke="#5012B0"
          strokeWidth="1.5"
          strokeLinecap="round"
          strokeLinejoin="round"
          className={pathClassName}
        />
      </g>
      <defs>
        <clipPath id="clip0_8533_738">
          <rect width="16" height="16" fill="white" />
        </clipPath>
      </defs>
    </svg>
  );
};

export default LikeIcon;
