import { useMemberAsMemberNew } from "@gymflow/api";
import useGymflowModels from "apps/portal/src/store";
import { useContext } from "react";

import { Button, EditIcon, Spinner } from "../../../atoms";
import { MarkerPin01Icon } from "../../../atoms/icons/MarkerPin01Icon";
import { EditMemberSidebarProviderContext } from "./HostedPagesEditProfile/EditMemberSidebarProviderContext";

export const HostedPagesProfileUserBlock = () => {
  const { api } = useGymflowModels();

  const { data: userMember, isLoading } = useMemberAsMemberNew({ api });
  const { openEditMemberSidebar } = useContext(
    EditMemberSidebarProviderContext,
  );
  const initials = (
    (userMember?.firstName[0] ?? "") + (userMember?.lastName[0] ?? "")
  ).toUpperCase();

  return (
    <div className="shadow-xs bg-gray-0 flex justify-between gap-3 rounded-2xl border border-gray-300 py-3 pl-3 pr-2 dark:border-gray-700 dark:bg-gray-950">
      {isLoading ? (
        <div className="flex h-16 w-full items-center justify-center">
          <Spinner />
        </div>
      ) : (
        <>
          <div className="flex items-center gap-3">
            <div className="flex h-16 w-16 shrink-0 justify-center overflow-hidden rounded-full border border-gray-300 dark:border-gray-700">
              {userMember?.picture ? (
                <img
                  src={userMember?.picture}
                  alt="User's Profile"
                  className="flex aspect-square h-full w-full object-cover"
                />
              ) : (
                <div className="dark:text-gray-25 flex items-center justify-center text-2xl font-semibold text-gray-950">
                  {initials}
                </div>
              )}
            </div>
            <div className="flex flex-col gap-0.5">
              <div className="dark:text-gray-25 text-xl font-bold text-gray-950">{`${userMember?.firstName} ${userMember?.lastName}`}</div>
              <div className="flex items-center gap-1.5">
                <MarkerPin01Icon
                  className="h-4 w-4"
                  pathClassName="stroke-gray-500 dark:stroke-gray-400"
                />
                <div className="text-base font-medium text-gray-500 dark:text-gray-400">{`${userMember?.homeClub?.name}`}</div>
              </div>
            </div>
          </div>
          <Button
            intent="transparent"
            className="cursor-pointer p-2 opacity-50"
            onClick={() => {
              openEditMemberSidebar();
            }}
          >
            <EditIcon
              className="h-5 w-5"
              pathClassName="stroke-gray-500 stroke-2 dark:stroke-gray-400"
            />
          </Button>
        </>
      )}
    </div>
  );
};
