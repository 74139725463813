import { useClubFeatureFlags, useMember } from "@gymflow/api";
import { motion } from "motion/react";
import { ReactNode, useContext } from "react";
import { useTranslation } from "react-i18next";

import { useAccessToggleButton } from "../../../../hooks";
import {
  Can,
  Subject,
  SubjectType,
  Verb,
  VerbType,
} from "../../../../permissions";
import { useClubSettings } from "../../../../providers";
import useGymflowModels from "../../../../store";
import {
  CheckDoneIcon,
  CheckInIcon,
  CheckOutIcon,
  MailIcon,
  ShopBagIcon,
  SMSIcon,
  Spinner,
  StickerSquareIcon,
} from "../../../atoms";
import {
  CreateEditTaskSidebarProviderContext,
  SendEmailSidebarProviderContext,
  SendSMSToUserSidebarContext,
} from "../../../organisms";
import { ShopSidebarContext } from "../../../Shop/ShopSidebar/ShopSidebarContext";
import { NoteFormSideBarProviderContext } from "../../../UserMember/Notes/NoteFormSideBarProvider";

type MenuItem =
  | {
      type: "ACTION";
      label: string;
      icon: ReactNode;
      onClick: () => void;
      permission?: { action: VerbType; subject: SubjectType };
    }
  | {
      type: "DIVIDER";
    };

type DropdownContentProps = {
  setIsPopoverOpen: (isPopoverOpen: boolean) => void;
  memberId: string;
};

export const DropdownContent = ({
  setIsPopoverOpen,
  memberId,
}: DropdownContentProps) => {
  const { t } = useTranslation();
  const { api } = useGymflowModels();
  const { clubId, timezone } = useClubSettings();
  const { showShop } = useContext(ShopSidebarContext);
  const { open: openSendSMSSidebar } = useContext(SendSMSToUserSidebarContext);
  const { triggerNewNote } = useContext(NoteFormSideBarProviderContext);
  const { openSendEmailSidebar } = useContext(SendEmailSidebarProviderContext);
  const { data: featureFlags } = useClubFeatureFlags({ clubId, api });
  const { openCreateTaskSidebar } = useContext(
    CreateEditTaskSidebarProviderContext,
  );

  const {
    toggle,
    isReady: isAccessReady,
    isCheckedIn,
  } = useAccessToggleButton({ userMemberId: memberId });
  const { data: userData, isLoading: isUserLoading } = useMember({
    memberId,
    api,
    tz: timezone,
  });

  if (!isAccessReady || isUserLoading || !userData) {
    return (
      <motion.div
        initial={{ opacity: 0, scale: 0.8 }}
        animate={{ opacity: 1, scale: 1 }}
        exit={{ opacity: 0, scale: 0.8 }}
        className="flex items-center py-9"
      >
        <Spinner />
      </motion.div>
    );
  }

  const { user } = userData;
  const memberName = `${user.firstName} ${user.lastName}`;

  const menuItems: MenuItem[] = [
    {
      type: "ACTION",
      permission: { action: Verb.View, subject: Subject.Sales },
      icon: <ShopBagIcon className="h-4 w-4" pathClassName="stroke-gray-700" />,
      label: t(
        "components.userActionsDropdown.dropdownContent.menuItems.sellSomething",
      ),
      onClick: () => {
        setIsPopoverOpen(false);
        showShop(user);
      },
    },
    {
      type: "ACTION",
      permission: { action: Verb.View, subject: Subject.Access },
      icon: isCheckedIn ? (
        <CheckOutIcon className="h-4 w-4" pathClassName="stroke-gray-700" />
      ) : (
        <CheckInIcon className="h-4 w-4" pathClassName="stroke-gray-700" />
      ),
      label: isCheckedIn
        ? t("components.userActionsDropdown.dropdownContent.menuItems.checkOut")
        : t("components.userActionsDropdown.dropdownContent.menuItems.checkIn"),
      onClick: async () => {
        setIsPopoverOpen(false);
        await toggle();
      },
    },
    { type: "DIVIDER" },
    {
      type: "ACTION",
      permission: { action: Verb.Create, subject: Subject.Email },
      icon: <MailIcon className="h-4 w-4" pathClassName="stroke-gray-700" />,
      label: t(
        "components.userActionsDropdown.dropdownContent.menuItems.sendEmail",
      ),
      onClick: () => {
        setIsPopoverOpen(false);
        openSendEmailSidebar({
          recipient: memberName,
          allowMarketing: user.emailCommunication,
          requestType: "USER",
          listOfIds: [memberId],
        });
      },
    },
    // Remove ".filter(Boolean)" with PORTAL_SEND_SMS feature flag
    featureFlags?.featureFlags.PORTAL_SEND_SMS
      ? {
          type: "ACTION",
          permission: { action: Verb.Create, subject: Subject.SMS },
          icon: <SMSIcon className="h-4 w-4" pathClassName="stroke-gray-700" />,
          label: t(
            "components.userActionsDropdown.dropdownContent.menuItems.sendSMS",
          ),
          onClick: () => {
            setIsPopoverOpen(false);
            openSendSMSSidebar({ memberId, memberName });
          },
        }
      : null,
    { type: "DIVIDER" },
    {
      type: "ACTION",
      icon: (
        <StickerSquareIcon
          className="h-4 w-4"
          pathClassName="stroke-gray-700"
        />
      ),
      label: t(
        "components.userActionsDropdown.dropdownContent.menuItems.addNote",
      ),
      onClick: () => {
        setIsPopoverOpen(false);
        triggerNewNote({ userMemberId: memberId, name: memberName });
      },
    },
    {
      type: "ACTION",
      permission: { action: Verb.View, subject: Subject.Task },
      icon: (
        <CheckDoneIcon className="h-4 w-4" pathClassName="stroke-gray-700" />
      ),
      label: t(
        "components.userActionsDropdown.dropdownContent.menuItems.createTask",
      ),
      onClick: () => {
        setIsPopoverOpen(false);
        openCreateTaskSidebar({
          initialValues: {
            relatedUsers: [
              {
                id: memberId,
                label: memberName,
                value: user,
                imageUrl: user.picture,
              },
            ],
          },
        });
      },
    },
  ].filter(Boolean) as MenuItem[]; // Remove it with PORTAL_SEND_SMS feature flag

  return (
    <motion.div
      key="menu"
      initial={{ opacity: 0 }}
      animate={{ opacity: 1 }}
      exit={{ opacity: 0 }}
      transition={{ duration: 0.3, ease: "easeOut" }}
    >
      {menuItems.map((item, index) => {
        if (item.type === "DIVIDER") {
          return (
            <motion.div
              key={`divider-${index}`}
              initial={{ opacity: 0 }}
              animate={{ opacity: 1 }}
              exit={{ opacity: 0 }}
              transition={{ duration: 0.2 }}
              className="border-t border-t-gray-300"
            />
          );
        }

        return (
          <Can
            key={index}
            I={item.permission?.action}
            a={item.permission?.subject}
          >
            <motion.div
              initial={{ opacity: 0 }}
              animate={{ opacity: 1 }}
              exit={{ opacity: 0 }}
              transition={{ duration: 0.3, ease: "easeOut" }}
              className="flex cursor-pointer items-center gap-2 px-4 py-2 text-sm text-gray-700 hover:bg-gray-50"
              onClick={item.onClick}
            >
              {item.icon}
              <div>{item.label}</div>
            </motion.div>
          </Can>
        );
      })}
    </motion.div>
  );
};
