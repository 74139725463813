import { useQueryVisitPercentage } from "@gymflow/api";
import { DATE_FORMAT, PARAMETER_DATE_FORMAT_WITHOUT_TZ } from "@gymflow/common";
import { cn } from "@gymflow/helpers";
import moment from "moment";
import { useContext } from "react";
import { Pie } from "react-chartjs-2";

import { useClubSettings } from "../../providers";
import useGymflowModels from "../../store";
import { Spinner } from "../atoms";
import { ReportContext } from "../Reports/ReportContext";

export function VisitPercentageChart({ className }: { className?: string }) {
  const reportContext = useContext(ReportContext);
  const { api } = useGymflowModels();
  const { timezone } = useClubSettings();

  const { data, isFetching } = useQueryVisitPercentage({
    api,
    dateFrom: moment(reportContext.startDate, DATE_FORMAT).format(
      PARAMETER_DATE_FORMAT_WITHOUT_TZ,
    ),
    dateTo: moment(reportContext.endDate, DATE_FORMAT)
      .endOf("day")
      .format(PARAMETER_DATE_FORMAT_WITHOUT_TZ),
    tz: timezone,
  });

  const labels = ["Visited", "Did not visit"];
  const chartData = data ? [data.visit, data.noVisit] : [];
  return (
    <div
      className={cn(
        "relative mx-3 mt-6 min-w-[250px] flex-1 rounded-xl border border-gray-200 bg-gray-0 p-6 shadow",
        className,
      )}
    >
      <div className="flex flex-row items-center justify-between">
        <div className="text-sm font-medium text-gray-600">
          Member Visits Summary
        </div>
      </div>

      <div
        className={cn("absolute w-full pt-6", {
          hidden: !isFetching,
        })}
      >
        <Spinner className="!h-12 !w-12" />
      </div>
      <div
        className={cn("mt-3 h-[5.31rem] max-w-[17.5rem] ", {
          hidden: isFetching,
        })}
      >
        <Pie
          data={{
            labels,
            datasets: [
              {
                data: chartData,
                backgroundColor: ["#E7DAFB", "#B790F4"],
              },
            ],
          }}
          options={{
            maintainAspectRatio: false,
            animation: false,
            legend: {
              position: "right",
              labels: {
                usePointStyle: true,
                fontFamily: "Manrope",
                fontSize: 14,
                fontColor: "#667085",
              },
            },

            tooltips: {
              fontFamily: "Manrope",
              titleAlign: "center",
              bodyAlign: "center",
              displayColors: false,
              callbacks: {
                label: function (tooltipItem: any) {
                  const label = labels[tooltipItem.index];
                  const value = chartData[tooltipItem.index];
                  if (label !== undefined && value !== undefined) {
                    return `${label} - ${value}%`;
                  }
                  return "";
                },
              },
            },
          }}
          height={52}
        />
      </div>
    </div>
  );
}
