import { utcToZonedTime } from "@gymflow/helpers";
import { UserMemberBean } from "@gymflow/types";
import { useQuery, UseQueryResult } from "@tanstack/react-query";
import merge from "lodash/merge";

import { memberAsMemberQueryKeys } from "./memberAsMemberQueryKeys";

export async function memberAsMemberQueryFn({
  api,
}: {
  api: MemberAsMemberApi;
}) {
  const result = await api.profileApi.findById();
  return result.data as UserMemberBean;
}
/*** @deprecated - use useMemberAsMemberNew instead - it doesn't do timezone conversions */
export function useMemberAsMember(
  {
    api,
    memberId,
    tz,
  }: {
    api: MemberAsMemberApi;
    memberId?: string;
    tz: string;
  },
  opts?: UseQueryResult<UserMemberBean | null>,
) {
  const result = useQuery({
    queryKey: memberAsMemberQueryKeys.details(memberId),
    queryFn: () => memberAsMemberQueryFn({ api }),
    enabled: !!memberId && !!api?.profileApi?.findById,
    initialData: null,
    select: (data) => {
      if (!data) {
        return null;
      }
      return convertUserToZonedTime(data, tz);
    },
    ...opts,
  });

  return result;
}

const convertUserToZonedTime = (user: UserMemberBean, timezone: string) => {
  return merge({}, user, {
    subscriptions: user.subscriptions.map((sub) =>
      merge({}, sub, {
        startDate: utcToZonedTime(sub.startDate, timezone),
        endDate: sub.endDate && utcToZonedTime(sub.endDate, timezone),
        endContractDate:
          sub.endContractDate && utcToZonedTime(sub.endContractDate, timezone),
        cancellationDate:
          sub.cancellationDate &&
          utcToZonedTime(sub.cancellationDate, timezone),
        pauseStartDate:
          sub.pauseStartDate && utcToZonedTime(sub.pauseStartDate, timezone),
        pauseEndDate:
          sub.pauseEndDate && utcToZonedTime(sub.pauseEndDate, timezone),
      }),
    ),
  });
};

interface MemberAsMemberApi {
  profileApi: { findById: () => Promise<{ data: UserMemberBean }> };
}
