import { ClubSettings, FeatureFlags } from "@gymflow/types";
import { ReactNode } from "react";
import { Redirect, RouteChildrenProps } from "react-router";

import accessIcon from "../../assets/img/access.svg";
import automationsIcon from "../../assets/img/automations.svg";
import calendarIcon from "../../assets/img/calendar-icon.svg";
import checkDoneIcon from "../../assets/img/check-done-icon.svg";
import filterFunnelIcon from "../../assets/img/filter-funnel-icon.svg";
import homeIcon from "../../assets/img/home_icon.svg";
import pieChartIcon from "../../assets/img/pie-chart-icon.svg";
import roadmapIcon from "../../assets/img/roadmap-icon.svg";
import settingsIcon from "../../assets/img/settings-icon.svg";
import shoppingBagIcon from "../../assets/img/shopping-bag-icon.svg";
import tagIcon from "../../assets/img/tag-icon.svg";
import usersIcon from "../../assets/img/users-icon.svg";
import { LeadCountBadge } from "../components/atoms";
import CheckIn from "../components/Kiosk/CheckIn";
import {
  Calendar,
  FacilityAvailability,
  KisiIntegration,
  PromotionWizard,
  RuleFormRouter,
  ScheduleAppointment,
  SiteNotAvailable,
  SiteRegistration,
  StaffAvailability,
} from "../components/pages";
import { GrowMetrics } from "../components/pages/GrowMetrics";
import { Leads } from "../components/pages/Leads/Leads";
import {
  MigrationCheckEmail,
  MigrationForm,
} from "../components/pages/Migration";
import { AppointableWizardRoutes } from "../components/Settings/Appointable/AppointableWizard";
import { BuyMembership as BuyMembershipSite } from "../components/site/BuyMembership";
import { BuySessionPack as BuySessionPackSite } from "../components/site/BuySessionPack";
import { Enquiry } from "../components/site/Enquiry";
import { GuestRegistration } from "../components/site/GuestRegistration";
import { HostedPagesAppointmentCheckout } from "../components/site/HostedPagesAppointments/HostedPagesAppointmentCheckout";
import { HostedPagesAppointmentOverview } from "../components/site/HostedPagesAppointments/HostedPagesAppointmentOverview";
import { HostedPagesAppointmentReview } from "../components/site/HostedPagesAppointments/HostedPagesAppointmentReview";
import { HostedPagesAppointments } from "../components/site/HostedPagesAppointments/HostedPagesAppointments";
import { HostedPagesAppointmentSelect } from "../components/site/HostedPagesAppointments/HostedPagesAppointmentSelect";
import { HostedPagesAppointmentSelectTime } from "../components/site/HostedPagesAppointments/HostedPagesAppointmentSelectTime";
import { HostedPagesCheckout } from "../components/site/HostedPagesCheckout";
import HostedPagesClasses from "../components/site/HostedPagesClasses/HostedPagesClasses";
import HostedPagesClassLateCancelCheckout from "../components/site/HostedPagesClasses/HostedPagesClassLateCancelCheckout";
import { HostedPagesClassOverview } from "../components/site/HostedPagesClasses/HostedPagesClassOverview";
import { HostedPagesCreditPackList } from "../components/site/HostedPagesCreditPack/HostedPagesCreditPackList";
import { HostedPagesCreditPackOverview } from "../components/site/HostedPagesCreditPack/HostedPagesCreditPackOverview";
import HostedPagesLeadCapture from "../components/site/HostedPagesLeadCapture";
import { HostedPagesMembershipList } from "../components/site/HostedPagesMembership/HostedPagesMembershipList";
import { HostedPagesMembershipOverview } from "../components/site/HostedPagesMembership/HostedPagesMembershipOverview";
import { HostedPagesProfile } from "../components/site/HostedPagesProfile/HostedPagesProfile";
import { HostedPagesProfilePayments } from "../components/site/HostedPagesProfilePayments";
import { SaleCompleteSca } from "../components/site/SaleCompleteSca";
import { Timetable } from "../components/site/Timetable";
import { UserMemberPayments } from "../components/UserMember/UserMemberPayments/UserMemberPayments";
import { Subject, SubjectType } from "../permissions";
import { Access } from "../views/Access";
import Account from "../views/Account";
import { Automations } from "../views/Automations";
import Buy from "../views/Buy";
import { Dashboard } from "../views/Dashboard";
import { EventOccurrence } from "../views/EventOccurrence";
import { GenericSecurePayment } from "../views/GenericSecurePayment";
import LeadProfile from "../views/LeadProfile";
import { Marketing, marketingRoute } from "../views/Marketing";
import Profile from "../views/Profile";
import { ClassesRun } from "../views/reports/ClassesRun";
import { EventAttendance } from "../views/reports/EventAttendance";
import { LateCancellations } from "../views/reports/LateCancellations";
import { LeadOpen } from "../views/reports/LeadOpen";
import { LeadStatus } from "../views/reports/LeadStatus";
import { MembershipEnding } from "../views/reports/MembershipEnding";
import { MembershipOverdue } from "../views/reports/MembershipOverdue";
import { MembershipPaused } from "../views/reports/MembershipPaused";
import { MembershipPausing } from "../views/reports/MembershipPausing";
import { MembershipPending } from "../views/reports/MembershipPending";
import { MembershipResuming } from "../views/reports/MembershipResuming";
import { MembershipSold } from "../views/reports/MembershipSold";
import { NoShows } from "../views/reports/NoShows";
import { NoVisit } from "../views/reports/NoVisit";
import { ReportHome } from "../views/reports/ReportHome";
import { reportHomeRoutes } from "../views/reports/reportHomeRoutes";
import { RevenueAccountsReceivable } from "../views/reports/RevenueAccountsReceivable";
import { RevenueSchedule } from "../views/reports/RevenueSchedule";
import { RevenueTotal } from "../views/reports/RevenueTotal/RevenueTotal";
import { RevenueTransaction } from "../views/reports/RevenueTransaction";
import { VisitTotal } from "../views/reports/VisitTotal";
import { Roadmap } from "../views/Roadmap";
import Sales from "../views/Sales";
import { Sandbox } from "../views/Sandbox";
import { Settings } from "../views/Settings";
import { Tasks } from "../views/Tasks";
import { Unauthorized } from "../views/Unauthorized";
import UserCalendar from "../views/UserCalendar";
import { Dashboard as UserDashboard } from "../views/UserDashboard";
import { UserMember } from "../views/UserMember";
import { UserMemberList } from "../views/UserMemberList";
import {
  RouteFeature,
  RouteFeatureMigration,
  RouteFeatureSite,
} from "./feature";

export * from "./feature";
export * from "./layout";

export function generateStaffRoutes({
  featureFlags,
}: {
  featureFlags?: FeatureFlags | null;
}): RouteCollection {
  return [
    {
      path: RouteFeature.Dashboard,
      name: "Dashboard",
      component: Dashboard,
      icon: homeIcon,
      permission: Subject.Dashboard,
    },
    {
      path: RouteFeature.UserMember,
      name: "",
      component: UserMember,
      invisible: true,
      permission: Subject.MemberList,
    },
    {
      path: RouteFeature.Calendar,
      name: "Calendar",
      icon: calendarIcon,
      component: Calendar,
      permission: Subject.StaffCalendar,
    },
    {
      path: RouteFeature.Sales,
      name: "Sales",
      icon: shoppingBagIcon,
      component: Sales,
      permission: Subject.Sales,
      isShowShopSidebar: true,
    },
    {
      path: RouteFeature.EventOccurrence,
      name: "Event Occurrence",
      component: EventOccurrence,
      permission: Subject.StaffCalendar,
      invisible: true,
      navbarVisible: false,
    },
    {
      path: RouteFeature.Access,
      name: "Access",
      icon: accessIcon,
      component: Access,
      permission: Subject.Access,
    },
    {
      path: RouteFeature.Leads,
      name: "Leads",
      icon: filterFunnelIcon,
      sidebarComponent: LeadCountBadge,
      component: Leads,
      permission: Subject.Leads,
    },
    {
      path: RouteFeature.LeadProfile,
      name: "",
      component: LeadProfile,
      invisible: true,
      permission: Subject.Leads,
    },
    {
      path: RouteFeature.UserMemberList,
      name: "Users",
      icon: usersIcon,
      component: UserMemberList,
      permission: Subject.MemberList,
    },

    {
      path: "/sandbox",
      name: "Component Sandbox",
      component: Sandbox,
      invisible: true,
      navbarVisible: false,
    },
    {
      path: RouteFeature.Task,
      name: "Tasks",
      icon: checkDoneIcon,
      component: Tasks,
      permission: Subject.Task,
    },
    {
      path: `${RouteFeature.Marketing}${marketingRoute.Promotions}/form`,
      name: "Promotions Wizard",
      component: PromotionWizard,
      navbarVisible: false,
      invisible: true,
    },
    {
      path: RouteFeature.Marketing,
      name: "Marketing",
      icon: tagIcon,
      component: Marketing,
      permission: Subject.Marketing,
      children: [
        {
          path: `${RouteFeature.Marketing}${marketingRoute.Templates}`,
          name: "Templates",
          component: Marketing,
        },
        {
          path: `${RouteFeature.Marketing}${marketingRoute.Promotions}`,
          name: "Promotions",
          component: Marketing,
        },
      ],
    },
    {
      path: RouteFeature.Automation,
      name: "Automations",
      icon: automationsIcon,
      component: Automations,
      permission: Subject.Marketing,
    },
    {
      path: RouteFeature.Reports,
      name: "Reports",
      icon: pieChartIcon,
      component: ReportHome,
      children: [
        {
          path: `${RouteFeature.Reports}${reportHomeRoutes.Revenue}`,
          name: "Revenue",
          component: ReportHome,
        },
        {
          path: `${RouteFeature.Reports}${reportHomeRoutes.Members}`,
          name: "Members",
          component: ReportHome,
        },
        {
          path: `${RouteFeature.Reports}${reportHomeRoutes.Leads}`,
          name: "Leads",
          component: ReportHome,
        },
        {
          path: `${RouteFeature.Reports}${reportHomeRoutes.Events}`,
          name: "Classes",
          component: ReportHome,
        },
        {
          path: `${RouteFeature.Reports}${reportHomeRoutes.Visits}`,
          name: "Visits",
          component: ReportHome,
        },
      ],
      permission: Subject.Reports,
    },
    {
      path: RouteFeature.OverdueMembers,
      name: "Overdue Members",
      component: MembershipOverdue,
      navbarVisible: false,
      invisible: true,
    },
    {
      path: RouteFeature.MembershipEnding,
      name: "Memberships Ending",
      component: MembershipEnding,
      navbarVisible: false,
      invisible: true,
    },
    {
      path: RouteFeature.PendingMemberships,
      name: "Pending Memberships",
      component: MembershipPending,
      navbarVisible: false,
      invisible: true,
    },
    {
      path: RouteFeature.EventAttendance,
      name: "Event Attendance",
      component: EventAttendance,
      invisible: true,
      navbarVisible: false,
    },
    {
      path: RouteFeature.PausedMemberships,
      name: "Paused Memberships",
      component: MembershipPaused,
      invisible: true,
      navbarVisible: false,
    },
    {
      path: RouteFeature.PausingMemberships,
      name: "Pausing Memberships",
      component: MembershipPausing,
      invisible: true,
      navbarVisible: false,
    },
    {
      path: RouteFeature.ResumingMemberships,
      name: "Resuming Memberships",
      component: MembershipResuming,
      invisible: true,
      navbarVisible: false,
    },
    {
      path: RouteFeature.MembershipSold,
      name: "Memberships Sold",
      component: MembershipSold,
      invisible: true,
      navbarVisible: false,
    },
    {
      path: RouteFeature.NewLeads,
      name: "New Leads",
      component: () => <LeadStatus leadStatus="NEW_LEAD" />,
      invisible: true,
      navbarVisible: false,
    },
    {
      path: RouteFeature.OpenedLeads,
      name: "Open Leads",
      component: () => <LeadOpen />,
      invisible: true,
      navbarVisible: false,
    },
    {
      path: RouteFeature.ClosedLeads,
      name: "Closed Leads",
      component: () => <LeadStatus leadStatus="DEAL_CLOSED" />,
      invisible: true,
      navbarVisible: false,
    },
    {
      path: RouteFeature.LostLeads,
      name: "Lost Leads",
      component: () => <LeadStatus leadStatus="DEAL_LOST" />,
      invisible: true,
      navbarVisible: false,
    },
    {
      path: RouteFeature.Revenue,
      name: "Revenue",
      component: RevenueTotal,
      invisible: true,
      navbarVisible: false,
    },
    {
      path: RouteFeature.RevenueTransaction,
      name: "Revenue",
      component: RevenueTransaction,
      invisible: true,
      navbarVisible: false,
    },
    {
      path: RouteFeature.RevenueSchedule,
      name: "Revenue",
      component: RevenueSchedule,
      invisible: true,
      navbarVisible: false,
    },
    {
      path: RouteFeature.RevenueAccountReceivable,
      name: "Revenue",
      component: RevenueAccountsReceivable,
      invisible: true,
      navbarVisible: false,
    },
    {
      path: RouteFeature.ClassesRun,
      name: "Classes Run",
      component: ClassesRun,
      invisible: true,
      navbarVisible: false,
    },
    {
      path: RouteFeature.NoShows,
      name: "No Shows",
      component: NoShows,
      invisible: true,
      navbarVisible: false,
    },
    {
      path: RouteFeature.LateCancellations,
      name: "Late Cancellations",
      component: LateCancellations,
      invisible: true,
      navbarVisible: false,
    },
    {
      path: RouteFeature.VisitReport,
      name: "Visit",
      component: VisitTotal,
      invisible: true,
      navbarVisible: false,
    },
    {
      path: RouteFeature.NoVisitReport,
      name: "No Visit",
      component: NoVisit,
      invisible: true,
      navbarVisible: false,
    },
    {
      path: RouteFeature.KisiIntegration,
      name: "Kisi Integration",
      component: KisiIntegration,
      invisible: true,
      navbarVisible: false,
    },
    {
      path: RouteFeature.GrowMetricsIntegration,
      name: "GrowMetrics Integration",
      component: GrowMetrics,
      invisible: true,
      navbarVisible: false,
    },
    {
      path: RouteFeature.AppointableWizard,
      name: "Appointable Wizard",
      component: AppointableWizardRoutes,
      permission: Subject.Settings,
      invisible: true,
      navbarVisible: false,
    },
    {
      path: RouteFeature.StaffAvailability,
      name: "Staff Availability",
      component: StaffAvailability,
      invisible: true,
      navbarVisible: false,
    },
    {
      path: RouteFeature.FacilityAvailability,
      name: "Facility Availability",
      component: FacilityAvailability,
      invisible: true,
      navbarVisible: false,
    },
    {
      path: RouteFeature.Roadmap,
      name: "Roadmap",
      icon: roadmapIcon,
      component: Roadmap,
      align: "bottom",
    },
    {
      path: RouteFeature.RuleForm,
      name: "Rule",
      component: RuleFormRouter,
      permission: Subject.Settings,
      invisible: true,
      navbarVisible: false,
    },
    {
      path: RouteFeature.Settings,
      name: "Settings",
      icon: settingsIcon,
      component: Settings,
      permission: Subject.Settings,
      align: "bottom",
    },
  ];
}

export const kiosk: RouteCollection = [
  {
    path: RouteFeature.KioskCheckIn,
    name: "Kiosk Check In",
    component: CheckIn,
    invisible: true,
  },
];

export const member: RouteCollection = [
  {
    path: RouteFeature.Dashboard,
    name: "Dashboard",
    component: UserDashboard,
    icon: homeIcon,
    permission: Subject.MyProfile,
  },
  {
    path: RouteFeature.Profile,
    name: "Edit Profile",
    icon: usersIcon,
    component: Profile,
    permission: Subject.MyProfile,
  },
  {
    path: RouteFeature.Account,
    name: "Account",
    icon: usersIcon,
    component: Account,
    permission: Subject.MyProfile,
  },
  {
    path: RouteFeature.CustomerSale,
    name: "Shop",
    icon: tagIcon,
    component: Buy,
    permission: Subject.CustomerSales,
  },
  {
    path: RouteFeature.Calendar,
    name: "Book",
    icon: calendarIcon,
    component: UserCalendar,
    permission: Subject.UserCalendar,
  },
  {
    path: RouteFeature.Receipts,
    name: "Payments",
    icon: shoppingBagIcon,
    component: () => (
      <div className="h-full w-full p-8">
        <UserMemberPayments asMember={true} />
      </div>
    ),
    permission: Subject.MyProfile,
  },
];

export function generateSiteRoutes(
  featureFlags?: FeatureFlags | null,
): RouteDefinition[] {
  return [
    {
      path: RouteFeature.SiteBuyMembership,
      name: "Memberships",
      component: BuyMembershipSite,
    },
    {
      path: RouteFeature.SiteBuySessionPack,
      name: "Credit Packs",
      component: BuySessionPackSite,
    },
    ...(featureFlags?.featureFlags.HOSTED_PAGES_CLASSES_REDESIGN
      ? []
      : [
          {
            path: RouteFeature.SiteTimetable,
            name: "Schedule",
            component: Timetable,
          },
        ]),
    ...(featureFlags?.featureFlags.HOSTED_PAGES_GUEST_REGISTRATION_REDESIGN
      ? []
      : [
          {
            path: RouteFeature.SiteGuestRegistration,
            name: "Guest Registration",
            component: GuestRegistration,
            invisible: true,
          },
        ]),

    {
      path: RouteFeature.SiteEnquiry,
      name: "Contact Us",
      component: Enquiry,
    },
    {
      path: RouteFeature.SiteProcessingSca,
      name: "Processing Payment Authorization",
      component: SaleCompleteSca,
      invisible: true,
    },
    {
      path: RouteFeature.SiteSaleComplete,
      name: "Sale Complete",
      component: SaleCompleteSca,
      invisible: true,
    },
  ];
}

export function generateSiteWithAuthRoutes(
  featureFlags?: FeatureFlags | null,
  clubSettings?: ClubSettings | null,
): RouteDefinition[] {
  return [
    {
      path: RouteFeature.SiteSecurePayment,
      name: "Payment Authorization",
      component: GenericSecurePayment,
      invisible: true,
    },
    ...(featureFlags?.featureFlags.HOSTED_LEAD_CAPTURE_REDESIGN
      ? [
          {
            path: RouteFeature.SiteEnquiry,
            name: "Contact Us",
            component: HostedPagesLeadCapture,
            invisible: !clubSettings?.leadCaptureNavEnabled,
          },
        ]
      : []),
    ...(featureFlags?.featureFlags.HOSTED_PAGES_PROFILE
      ? [
          {
            path: RouteFeature.SiteProfilePayments,
            name: "Payments",
            component: HostedPagesProfilePayments,
            invisible: true,
          },
          {
            path: RouteFeature.SiteProfile,
            name: "Profile",
            component: HostedPagesProfile,
            invisible: true,
          },
        ]
      : []),
    ...(featureFlags?.featureFlags.HOSTED_PAGES_CLASSES_REDESIGN
      ? [
          {
            path: RouteFeature.SiteTimeTableLateCancelId,
            name: "Late Cancel Id",
            component: HostedPagesClassLateCancelCheckout,
            invisible: true,
          },
          {
            path: RouteFeature.SiteTimetableId,
            name: "Class Id",
            component: HostedPagesClassOverview,
            invisible: true,
          },
          {
            path: RouteFeature.SiteTimetableType,
            name: "Classes",
            component: HostedPagesClasses,
            invisible: true,
          },
          {
            path: RouteFeature.SiteTimetableType.replace(
              ":viewType",
              "calendar",
            ),
            name: "Classes",
            component: HostedPagesClasses,
            invisible: !clubSettings?.timetableNavEnabled,
          },
          {
            path: RouteFeature.SiteTimetable,
            name: "Classes",
            component: (
              e: RouteChildrenProps<
                {
                  [x: string]: string | undefined;
                },
                unknown
              >,
            ) => {
              return (
                <Redirect
                  to={`${e.location.pathname}/list${e.location.search}`}
                />
              );
            },
            invisible: true,
          },
        ]
      : []),
    ...(!featureFlags?.featureFlags.HOSTED_CREDIT_REDESIGN
      ? []
      : [
          {
            path: RouteFeature.SiteBuyMembershipIdCheckout,
            name: "Checkout Membership",
            component: () => <HostedPagesCheckout type="MEMBERSHIP" />,
            invisible: true,
          },
          {
            path: RouteFeature.SiteBuyMembershipId,
            name: "Membership Id",
            component: HostedPagesMembershipOverview,
            invisible: true,
          },

          {
            path: RouteFeature.SiteBuyMembershipType.replace(
              ":membershipType",
              "all",
            ),
            name: "Memberships",
            component: HostedPagesMembershipList,
            invisible: !clubSettings?.membershipsNavEnabled,
          },
          {
            path: RouteFeature.SiteBuyMembershipType,
            name: "Memberships",
            component: HostedPagesMembershipList,
            invisible: true,
          },
          {
            path: RouteFeature.SiteBuySessionPackIdCheckout,
            name: "Checkout Credit Pack",
            component: () => <HostedPagesCheckout type="CREDIT_PACK" />,
            invisible: true,
          },
          {
            path: RouteFeature.SiteBuySessionPackId,
            name: "Session Pack Id",
            component: HostedPagesCreditPackOverview,
            invisible: true,
          },
          {
            path: RouteFeature.SiteBuySessionPackType,
            name: "Credit Packs",
            component: HostedPagesCreditPackList,
            invisible: true,
          },
          {
            path: RouteFeature.SiteBuySessionPackType.replace(
              ":sessionPackType",
              "all",
            ),
            name: "Credit Packs",
            component: HostedPagesCreditPackList,
            invisible: !clubSettings?.creditPacksNavEnabled,
          },
          {
            path: RouteFeature.SiteBuySessionPack,
            name: "Session Packs",
            component: (
              e: RouteChildrenProps<
                {
                  [x: string]: string | undefined;
                },
                unknown
              >,
            ) => {
              return (
                <Redirect
                  to={`${e.location.pathname}/classes${e.location.search}`}
                />
              );
            },
            invisible: true,
          },
          {
            path: RouteFeature.SiteBuyMembership,
            name: "Memberships",
            component: (
              e: RouteChildrenProps<
                {
                  [x: string]: string | undefined;
                },
                unknown
              >,
            ) => {
              return (
                <Redirect
                  to={`${e.location.pathname}/all${e.location.search}`}
                />
              );
            },
            invisible: true,
          },
        ]),
    {
      path: RouteFeature.AppointableIdCheckout,
      name: "Appointments",
      component: HostedPagesAppointmentCheckout,
      invisible: true,
    },
    {
      path: RouteFeature.AppointableIdReview,
      name: "Appointments",
      component: HostedPagesAppointmentReview,
      invisible: true,
    },
    {
      path: RouteFeature.AppointableIdSelectTime,
      name: "Appointments",
      component: HostedPagesAppointmentSelectTime,
      invisible: true,
    },
    {
      path: RouteFeature.AppointableIdSelect,
      name: "Appointments",
      component: HostedPagesAppointmentSelect,
      invisible: true,
    },
    {
      path: RouteFeature.AppointableId,
      name: "Appointments",
      component: HostedPagesAppointmentOverview,
      invisible: true,
    },
    {
      path: RouteFeatureSite.Appointments,
      name: "Appointments",
      component: featureFlags?.featureFlags.HOSTED_APPOINTMENT_REDESIGN
        ? HostedPagesAppointments
        : ScheduleAppointment,
      invisible: !clubSettings?.appointmentsNavEnabled,
    },
    ...(featureFlags?.featureFlags.HOSTED_PAGES_GUEST_REGISTRATION_REDESIGN
      ? [
          {
            path: RouteFeature.SiteGuestRegistration,
            name: "Guest Registration",
            component: SiteRegistration,
            invisible: true,
          },
        ]
      : []),
    {
      path: RouteFeatureSite.NotAvailable,
      name: "Not Available",
      component: SiteNotAvailable,
      invisible: true,
    },
  ];
}

export const authentication: RouteDefinition[] = [
  {
    path: RouteFeature.Unauthorized,
    name: "Unauthorized",
    component: Unauthorized,
    invisible: true,
  },
];
export const migrationRoutes: RouteDefinition[] = [
  {
    path: RouteFeatureMigration.CheckExisting,
    name: "Transferring your data",
    component: MigrationCheckEmail,
  },
  {
    path: RouteFeatureMigration.MigrationForm,
    name: "Transferring your data",
    component: MigrationForm,
  },
];

export interface BaseRouteDefinition {
  readonly name: string;
  readonly invisible?: boolean;
  readonly icon?: string;
  readonly permission?: SubjectType;
  readonly align?: "bottom";
  readonly menuItemClassName?: string;
}

export interface ParentRouteDefinition extends BaseRouteDefinition {
  readonly path: string;
  readonly navbarVisible?: boolean;
  readonly component: () => ReactNode;
  readonly children: RouteDefinition[];
  readonly isShowShopSidebar?: boolean;
}

export interface RouteDefinition extends BaseRouteDefinition {
  readonly path: string;
  readonly component: (props?: any) => ReactNode;
  readonly navbarVisible?: boolean;
  readonly sidebarComponent?: (props?: any) => ReactNode;
  readonly isShowShopSidebar?: boolean;
}

export type RouteCollection = (ParentRouteDefinition | RouteDefinition)[];
