import classNames from "classnames";
import { useEffect, useState } from "react";

import { Button } from "./Button";

export function InputWithSaveButton({
  onChange,
  value,
  isLoading,
}: {
  onChange: (newValue: string) => void;
  value: string;
  isLoading: boolean;
}) {
  const [text, setText] = useState(value);
  useEffect(() => {
    setText(value);
  }, [value]);
  return (
    <div className="mt-2 flex rounded-md shadow-sm">
      <div className="relative flex grow items-stretch focus-within:z-10">
        <input
          type="text"
          disabled={!!value}
          className="bg-gray-0 block h-11 w-full flex-1 rounded-none rounded-l-lg border-0 px-3.5 py-2.5 uppercase text-gray-900 ring-1 ring-inset ring-gray-300 placeholder:text-gray-400 focus:ring-1 focus:ring-inset dark:bg-gray-950 dark:text-gray-50 dark:ring-gray-700 dark:placeholder:text-gray-500 sm:text-sm sm:leading-6"
          value={text}
          onChange={({ currentTarget: { value: newValue } }) => {
            setText(newValue);
          }}
        />
      </div>
      <Button
        className={classNames(
          "dark:ring-gray-700 min-w-[4.75rem] !rounded-l-none ring-inset",
          {
            hidden: !!value,
          },
        )}
        onClick={() => {
          onChange(text);
        }}
        showSpinner={isLoading}
      >
        Apply
      </Button>
      <Button
        className={classNames(
          "dark:ring-gray-700 min-w-[4.75rem] !rounded-l-none ring-inset",
          {
            hidden: !value,
          },
        )}
        onClick={() => {
          onChange("");
        }}
        showSpinner={isLoading}
      >
        Remove
      </Button>
    </div>
  );
}
