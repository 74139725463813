import { faArrowLeft } from "@fortawesome/free-solid-svg-icons";
import { FontAwesomeIcon } from "@fortawesome/react-fontawesome";
import { useClubFeatureFlags } from "@gymflow/api";
import { useOutsideAlerter } from "@gymflow/common";
import React, { useContext, useMemo, useState } from "react";

import { useClubSettings } from "../../providers";
import useGymflowModels from "../../store";
import { getReportWidgetList } from "../Reports/Widgets";
import { DynamicReportWidgetContext } from "./DynamicReportWidgetCard";

export interface ReportWidgetCardDropdownProps {
  hide: () => void;
}
export const ReportWidgetCardDropdown: React.FC<
  ReportWidgetCardDropdownProps
> = ({ hide }) => {
  // eslint-disable-next-line @typescript-eslint/no-non-null-assertion
  const { onComponentSelected } = useContext(DynamicReportWidgetContext)!;
  const { ref } = useOutsideAlerter(hide);
  const [selected, setSelected] = useState<number>();

  const { api } = useGymflowModels();
  const settings = useClubSettings();
  const clubId = settings.clubId;
  const { data: featureFlags } = useClubFeatureFlags({ api, clubId });
  const reportWidgetList = useMemo(() => {
    if (featureFlags) {
      return getReportWidgetList(featureFlags);
    }
    return [];
  }, [featureFlags]);
  return (
    <div
      ref={ref as any}
      className="bg-gray-0 absolute right-0 top-0 z-10 max-h-64 overflow-y-auto rounded-xl border border-gray-200 shadow"
    >
      {selected !== undefined && (
        <div
          onClick={() => {
            setSelected(undefined);
          }}
          className="cursor-pointer px-2 py-1 text-sm hover:bg-gray-100"
        >
          <FontAwesomeIcon icon={faArrowLeft} />
        </div>
      )}
      {selected === undefined &&
        reportWidgetList.map((e, i) => {
          return (
            <div
              key={e.title}
              onClick={() => {
                setSelected(i);
              }}
              className="cursor-pointer whitespace-nowrap px-2 py-1 text-sm hover:bg-gray-100"
            >
              {e.title}
            </div>
          );
        })}
      {selected !== undefined &&
        reportWidgetList[selected].components.map((e) => {
          return (
            <div
              key={e.route.join("/")}
              onClick={() => {
                onComponentSelected(e.route);
              }}
              className="flex h-7 cursor-pointer items-center whitespace-nowrap p-2 text-sm hover:bg-gray-100"
            >
              {e.name}
            </div>
          );
        })}
    </div>
  );
};
