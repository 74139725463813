import { cn } from "@gymflow/helpers";
import { useState } from "react";

import {
  buttonVariants,
  DotsVerticalIcon,
  Popover,
  PopoverContent,
  PopoverTrigger,
} from "../../atoms";
import { DropdownContent } from "./components/DropdownContent";

type UserActionsDropdownProps = {
  className?: string;
  memberId: string;
};

export function UserActionsDropdown({
  className,
  memberId,
}: UserActionsDropdownProps) {
  const [isPopoverOpen, setIsPopoverOpen] = useState(false);

  return (
    <Popover open={isPopoverOpen} onOpenChange={(e) => setIsPopoverOpen(e)}>
      <PopoverTrigger
        className={cn(
          buttonVariants({ size: "small", intent: "default" }),
          "mt-0 h-11 w-11 bg-gray-0 text-gray-700 hover:bg-gray-50",
          className,
        )}
      >
        <div className="flex items-center justify-center">
          <DotsVerticalIcon
            className="h-6 w-6"
            pathClassName="stroke-gray-700"
          />
        </div>
      </PopoverTrigger>
      <PopoverContent
        align="start"
        side="bottom"
        asChild
        className="flex w-40 flex-col overflow-hidden rounded-lg"
        hideWhenDetached
      >
        <div>
          <DropdownContent
            setIsPopoverOpen={setIsPopoverOpen}
            memberId={memberId}
          />
        </div>
      </PopoverContent>
    </Popover>
  );
}
