export const RouteFeature = {
  Dashboard: "/dashboard",
  Reports: "/reports",
  UserMember: "/member/:id",
  UserMemberList: "/users",
  Sales: "/sales",
  Calendar: "/calendar",
  Access: "/access",
  EventOccurrence: "/event-occurrence/:eventId",
  LeadProfile: "/lead/:id",
  Leads: "/leads",
  LeadPipeline: "/leads/pipeline",
  LeadList: "/leads/list",
  Settings: "/settings",
  RuleForm: "/rule-form",
  AppointableWizard: "/settings/appointable-form",
  StaffAvailability: "/staff-availability/:staffId",
  FacilityAvailability: "/facility-availability/:facilityId",
  Unauthorized: "/unauthorized",
  Profile: "/profile",
  Receipts: "/receipts",
  Account: "/account",
  CustomerSale: "/buy",
  OverdueMembers: "/overdue-members",
  MembershipEnding: "/memberships-ending",
  PendingMemberships: "/pending-memberships",
  EventAttendance: "/event-attendance",
  PausedMemberships: "/paused-memberships",
  PausingMemberships: "/pausing-memberships",
  ResumingMemberships: "/resuming-memberships",
  ClassesRun: "/classes-run",
  NoShows: "/no-shows",
  LateCancellations: "/late-cancellations",
  Revenue: "/revenue",
  RevenueTransaction: "/revenue-transaction",
  RevenueSchedule: "/revenue-schedule",
  RevenueAccountReceivable: "/revenue-accounts-receivable",
  MembershipSold: "/membership-sold",
  NewLeads: "/new-leads",
  OpenedLeads: "/opened-leads",
  ClosedLeads: "/closed-leads",
  LostLeads: "/lost-leads",
  VisitReport: "/visit-total-report",
  NoVisitReport: "/no-visit-report",

  Task: "/task",
  Marketing: "/marketing",
  Automation: "/automation",
  CustomerMembershipSale: "/buy/membership/:id",
  CustomerCreditPackSale: "/buy/credit-pack/:id",
  CustomerMembershipWithBooking: "/buy/membership/:id/book/:eventId",
  CustomerCreditPackWithBooking: "/buy/credit-pack/:id/book/:eventId",
  PaymentSca: "/payment/sca",
  KioskCheckIn: "/check-in",
  KisiIntegration: "/integrations/kisi",
  GrowMetricsIntegration: "/integrations/grow-metrics",

  SiteProfile: "/profile",
  SiteProfilePayments: "/profile/payments",
  /*** @deprecated old link - use /timetable/calendar instead */
  SiteTimetable: "/timetable",
  SiteTimetableType: "/timetable/:viewType",
  SiteTimetableId: "/timetable/:viewType/:eventOccurrenceId",
  SiteTimeTableLateCancelId:
    "/timetable/:viewType/:eventOccurrenceId/late-cancel",
  SiteTimeTableQuickPurchase:
    "/timetable/:viewType/:eventOccurrenceId/quick-purchase/:type",

  /*** @deprecated old link - use /membership/all instead */
  SiteBuyMembership: "/membership",
  SiteBuyMembershipType: "/membership/:membershipType",
  SiteBuyMembershipId: "/membership/:membershipType/:membershipId",
  SiteBuyMembershipIdCheckout:
    "/membership/:membershipType/:membershipId/checkout",

  /*** @deprecated old link - use /session-pack/all instead */
  SiteBuySessionPack: "/session-pack",
  SiteBuySessionPackType: "/session-pack/:sessionPackType",
  SiteBuySessionPackId: "/session-pack/:sessionPackType/:sessionPackId",
  SiteBuySessionPackIdCheckout:
    "/session-pack/:sessionPackType/:sessionPackId/checkout",

  Appointables: "/appointments",
  AppointableId: "/appointments/:appointableId",
  AppointableIdSelect: "/appointments/:appointableId/select",
  AppointableIdSelectTime:
    "/appointments/:appointableId/select/:staffOrFacilityId/time",
  AppointableIdReview:
    "/appointments/:appointableId/select/:staffOrFacilityId/time/:timestamp/review",
  AppointableIdCheckout:
    "/appointments/:appointableId/select/:staffOrFacilityId/time/:timestamp/review/checkout",
  AppointableQuickPurchase:
    "/appointments/:appointableId/select/:staffOrFacilityId/time/:timestamp/review/checkout/quick-purchase/:type",
  SiteGuestRegistration: "/guest-registration",
  SiteEnquiry: "/enquiry",
  SiteProcessingSca: "/sale-secure-processing",
  SiteSaleComplete: "/sale-secure-complete",
  SiteSecurePayment: "/secure-payment",

  Roadmap: "/roadmap",

  // v CANNOT BE CHANGED v
  LinkBuyMembership: "/membership",
  LinkBuySessionPack: "/session-pack",
  LinkGuestRegistration: "/guest-registration",
  LinkEnquiry: "/enquiry",
  LinkTimetable: "/timetable",
  // ^ CANNOT BE CHANGED ^
} as const;

export type RouteFeatureValue =
  (typeof RouteFeature)[keyof typeof RouteFeature];

export const RouteFeatureSite = {
  NotAvailable: "/not-available",
  // v CANNOT BE CHANGED v
  Appointments: "/appointments",
  LinkAppointments: "/appointments",
  // ^ CANNOT BE CHANGED ^
} as const;

export const RouteFeatureMigration = {
  CheckExisting: "/check-existing",
  MigrationForm: "/form",
} as const;

export type RouteFeatureSiteValue =
  (typeof RouteFeatureSite)[keyof typeof RouteFeatureSite];
