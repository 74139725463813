import { useTranslation } from "react-i18next";

type ErrorMessageProps = { text: string };

export const ErrorMessage = ({ text }: ErrorMessageProps) => {
  const { t, i18n } = useTranslation();
  return (
    <div className="text-sm text-red-600">
      {i18n.exists(text) ? t(text) : text}
    </div>
  );
};
