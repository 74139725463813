import { cn } from "@gymflow/helpers";

export function Spinner({
  className,
  circleClassName,
  pathClassName,
}: {
  className?: string;
  circleClassName?: string;
  pathClassName?: string;
}) {
  return (
    <svg
      className={cn("m-auto h-5 w-5 animate-spin", className)}
      xmlns="http://www.w3.org/2000/svg"
      fill="none"
      viewBox="0 0 24 24"
    >
      <circle
        className={cn(
          "opacity-25 stroke-gray-600 dark:stroke-gray-400",
          circleClassName,
        )}
        cx="12"
        cy="12"
        r="10"
        strokeWidth="4"
      ></circle>
      <path
        className={cn(
          "fill-gray-600 opacity-75 dark:fill-gray-400",
          pathClassName,
        )}
        d="M4 12a8 8 0 018-8V0C5.373 0 0 5.373 0 12h4zm2 5.291A7.962 7.962 0 014 12H0c0 3.042 1.135 5.824 3 7.938l3-2.647z"
      ></path>
    </svg>
  );
}
