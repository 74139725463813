import { ApiType } from "@gymflow/common/lib/api/ApiType";
import { useMutation, useQueryClient } from "@tanstack/react-query";
import omit from "lodash/omit";

import { membershipQueryKeys } from "./membershipQueryKeys";

export function useMutationMembershipEdit({ api }: { api: ApiType }) {
  const queryClient = useQueryClient();

  const mutation = useMutation({
    mutationFn: ({
      membershipId,
      patchedFields,
    }: {
      membershipId: number;
      patchedFields: { [k: string]: any };
    }) => {
      return api.serviceApi.update(membershipId, {
        patchedFields: omit(patchedFields, ["serviceType"]),
      });
    },
    onSuccess: () => {
      queryClient.invalidateQueries({ queryKey: membershipQueryKeys.all() });
    },
  });
  return mutation;
}
