import { cn } from "@gymflow/helpers";
import classNames from "classnames";
import { ReactNode } from "react";

import { ModalWrapper } from "../../providers";
import { Button, DangerIcon } from "../atoms";
import { XIcon } from "../atoms/icons/XIcon";

export type ConfirmModalType = "danger" | "warning" | "secondary";

type ConfirmModalProps = {
  title: string;
  onHide?: () => void;
  onCancel: () => void;
  onConfirm?: () => void;
  children: ReactNode;
  type?: ConfirmModalType;
  cancelText?: ReactNode;
  confirmText?: ReactNode;
  isConfirmDisabled?: boolean;
  icon?: ReactNode;
  showCloseButton?: boolean;
  wrapperClassName?: string;
};

export function ConfirmModal({
  title,
  onHide,
  onCancel,
  onConfirm,
  children,
  type,
  cancelText,
  confirmText,
  isConfirmDisabled,
  icon,
  showCloseButton,
  wrapperClassName,
}: ConfirmModalProps) {
  return (
    <ModalWrapper
      className={cn("flex flex-col gap-6", wrapperClassName)}
      onCancel={onHide ?? onCancel}
    >
      <div className="flex flex-row justify-between">
        <div
          className={cn(
            "flex h-11 w-11 items-center justify-center rounded-full",
            (
              {
                secondary: "bg-secondary-50",
                danger: "bg-error-50",
                warning: "bg-warning-50",
              } as { [key in ConfirmModalType]: string }
            )[type ?? "secondary"],
          )}
        >
          <div
            className={cn(
              "flex h-8 w-8 items-center justify-center self-center rounded-full",
              (
                {
                  secondary: "bg-secondary-100",
                  danger: "bg-error-100",
                  warning: "bg-warning-100",
                } as { [key in ConfirmModalType]: string }
              )[type ?? "secondary"],
            )}
          >
            {icon ?? (
              <DangerIcon
                className="h-5 w-5"
                pathClassName={
                  (
                    {
                      secondary: "stroke-secondary-600",
                      danger: "stroke-error-600",
                      warning: "stroke-warning-600",
                    } as { [key in ConfirmModalType]: string }
                  )[type ?? "secondary"]
                }
              />
            )}
          </div>
        </div>
        {showCloseButton && (
          <div className="cursor-pointer" onClick={onHide ?? onCancel}>
            <XIcon className="h-6 w-6" pathClassName="stroke-gray-600" />
          </div>
        )}
      </div>
      <div className="flex flex-col gap-1">
        <div className="text-lg font-semibold text-gray-950 dark:text-gray-0">
          {title}
        </div>
        <div className="text-sm text-gray-600">{children}</div>
      </div>
      <div className="flex flex-col gap-4 sm:flex-row">
        <Button
          onClick={onCancel}
          className={classNames("flex-1", {
            hidden: !onCancel,
          })}
        >
          {cancelText ?? "Cancel"}
        </Button>
        <Button
          intent={type ?? "secondary"}
          disabled={isConfirmDisabled}
          className={classNames("flex-1", {
            hidden: !onConfirm,
          })}
          onClick={onConfirm}
        >
          {confirmText ?? "Confirm"}
        </Button>
      </div>
    </ModalWrapper>
  );
}
