import { ApiType } from "@gymflow/common/lib/api/ApiType";
import { useMutation, useQueryClient } from "@tanstack/react-query";

import { membershipQueryKeys } from "./membershipQueryKeys";

export function useMutationMembershipTermsConditions({
  api,
}: {
  api: ApiType;
}) {
  const queryClient = useQueryClient();

  const mutation = useMutation({
    mutationFn: ({
      membershipId,
      patchedFields,
    }: {
      membershipId: number;
      patchedFields: { [k: string]: any };
    }) => {
      return api.serviceApi.updateTermsConditions(membershipId, {
        termsAndConditionsBlob: patchedFields["termsConditions"],
      });
    },
    onSuccess: () => {
      queryClient.invalidateQueries({ queryKey: membershipQueryKeys.all() });
    },
  });
  return mutation;
}
