import React from "react";

export type ClockIconProps = {
  className?: string;
  pathClassName?: string;
};

export const ClockIcon: React.FC<ClockIconProps> = ({
  pathClassName,
  className,
}) => {
  return (
    <svg
      className={className}
      xmlns="http://www.w3.org/2000/svg"
      width="14"
      height="14"
      viewBox="0 0 14 14"
      fill="none"
    >
      <g clipPath="url(#clip0_1028_2196)">
        <path
          d="M7.00033 3.49996V6.99996L9.33366 8.16663M12.8337 6.99996C12.8337 10.2216 10.222 12.8333 7.00033 12.8333C3.77866 12.8333 1.16699 10.2216 1.16699 6.99996C1.16699 3.7783 3.77866 1.16663 7.00033 1.16663C10.222 1.16663 12.8337 3.7783 12.8337 6.99996Z"
          className={pathClassName}
          stroke="#667085"
          strokeWidth="1.5"
          strokeLinecap="round"
          strokeLinejoin="round"
        />
      </g>
      <defs>
        <clipPath id="clip0_1028_2196">
          <rect width="14" height="14" fill="white" />
        </clipPath>
      </defs>
    </svg>
  );
};
