import { ApiType } from "@gymflow/common/lib/api/ApiType";
import { ActivityCategoryDTO } from "@gymflow/types";
import { useQuery } from "@tanstack/react-query";

import { defaultPage } from "../defaultPage";
import { activityAsPublicQueryKeys } from "./activityAsPublicQueryKeys";

export async function activityCategoryListAsPublicQueryFn({
  api,
  filter,
}: {
  api: ApiType;
  filter: Parameters<ApiType["public"]["activityApi"]["findCategories"]>[0];
}) {
  const result = await api.public.activityApi.findCategories({
    ...filter,
  });
  return result.data;
}

export function useQueryActivityCategoryListAsPublic(
  {
    api,
    filter,
  }: {
    api: ApiType;
    filter: Parameters<ApiType["public"]["activityApi"]["findCategories"]>[0];
  },
  {
    enabled,
  }: {
    enabled?: boolean;
  } = {
    enabled: true,
  },
) {
  return useQuery({
    queryKey: activityAsPublicQueryKeys.categories(filter),
    queryFn: () => activityCategoryListAsPublicQueryFn({ api, filter }),
    initialData: defaultPage<ActivityCategoryDTO>,
    enabled,
  });
}
