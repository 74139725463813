import { cn } from "@gymflow/helpers";
import { forwardRef } from "react";

import Label from "./Label";

export type TextareaProps = {
  value: string;
  onChange: (value: React.ChangeEvent<HTMLTextAreaElement>) => void;
  name?: string | undefined;
  rows?: number;
  className?: string;
  placeholder?: string;
  label?: string;
  isRequired?: boolean;
};

export const Textarea = forwardRef<HTMLTextAreaElement, TextareaProps>(
  ({ className, label, value, isRequired, ...rest }, ref) => {
    return (
      <div className="flex flex-col gap-1.5">
        <Label label={label} isRequired={isRequired} />
        <textarea
          ref={ref}
          value={value}
          className={cn(
            "shadow-xs dark:bg-gray-800 dark:border-gray-800 outline-secondary-200 w-full resize-y rounded-xl border border-gray-200 px-3.5 py-3 text-base font-medium dark:!outline-none focus:dark:ring-1 dark:ring-secondary-600",
            {
              "text-gray-950 dark:text-gray-0": value,
              "text-gray-500": !value,
            },
            className,
          )}
          {...rest}
        />
      </div>
    );
  },
);

Textarea.displayName = "Textarea";
