import {
  ApiListResponse,
  MembershipStatus,
  SessionPackDTO,
  SessionPackSummaryDTO,
} from "@gymflow/types";

import { createSortParam } from "./features/createSortParam";
import { createPublicAxiosInstance } from "./publicAxiosInstance";

export type PublicCreditPackApiType = ReturnType<typeof publicCreditPack>;

const publicCreditPack = (apiUrl: string, clubId: number) => {
  const baseUrl = `club/${clubId}/hosted/sessionpack`;
  const axiosInstance = createPublicAxiosInstance({
    apiUrl,
    paramsSerializer: {
      indexes: null,
    },
  });

  return {
    /*** @deprecated use getById instead */
    findById(id: number) {
      return axiosInstance.get<SessionPackDTO>(`${baseUrl}/${id}`);
    },
    getById(id: number) {
      return axiosInstance.get<SessionPackDTO>(`${baseUrl}/${id}`);
    },
    /*** @deprecated use getList instead */
    findByClubId(
      _clubId: number,
      { page, limit, sort, status, extraParams }: any,
    ) {
      return axiosInstance.get<SessionPackDTO>(`${baseUrl}`, {
        params: {
          page,
          size: limit,
          status,
          ...extraParams,
          sort: createSortParam(sort.field, sort.desc),
        },
      });
    },
    getList(params: {
      status?: MembershipStatus;
      id?: number[];
      hasActivityCategory?: boolean;
      hasAppointableCategory?: boolean;
      page: number;
      size: number;
      sort?: string;
    }) {
      return axiosInstance.get<ApiListResponse<SessionPackDTO>>(`${baseUrl}`, {
        params,
      });
    },
    summary(params: {
      creditPackId: number;
      promotionCode?: string;
      userMemberId: string;
    }) {
      return axiosInstance.post<SessionPackSummaryDTO>(`${baseUrl}/summary`, {
        sessionPackId: params.creditPackId,
        promotionCode: params.promotionCode,
        userMemberId: params.userMemberId,
      });
    },
  };
};

export default publicCreditPack;
