import { ApiType } from "@gymflow/common/lib/api/ApiType";
import { useInfiniteQuery } from "@tanstack/react-query";

import { memberQueryKeys } from "./memberQueryKeys";

export async function membershipChangeOptionsQueryFn({
  api,
  subscriptionId,
  nextPageToken,
}: {
  api: ApiType;
  subscriptionId: number;
  nextPageToken?: string;
}) {
  return (
    await api.memberApi.changeMembershipOptions({
      subscriptionId,
      nextPageToken,
    })
  ).data;
}
export function useInfiniteQueryMemberChangeMembershipOptions({
  api,
  subscriptionId,
}: {
  api: ApiType;
  subscriptionId: number;
}) {
  return useInfiniteQuery({
    queryKey: memberQueryKeys.membershipChangeOptions(subscriptionId),
    queryFn: async ({ pageParam }) => {
      return membershipChangeOptionsQueryFn({
        api,
        subscriptionId,
        nextPageToken: pageParam,
      });
    },
    getNextPageParam: (lastPage) => lastPage?.nextPageToken,
  });
}
