import { ApiType } from "@gymflow/common/lib/api/ApiType";
import { useQuery } from "@tanstack/react-query";

import { eventOccurrenceAsMemberQueryKeys } from "./eventOccurrenceAsMemberQueryKeys";

export function useQueryEventOccurrenceAsMember({
  api,
  id,
}: {
  api: ApiType;
  id?: Parameters<ApiType["customerOccurrenceApi"]["findById"]>[0];
}) {
  return useQuery({
    queryKey: eventOccurrenceAsMemberQueryKeys.findById(id),
    queryFn: async ({ pageParam }) => {
      return (await api.customerOccurrenceApi.findById(id!)).data;
    },
    enabled: !!id,
  });
}
