import { useAutoAnimate } from "@formkit/auto-animate/react";
import { faCreditCard } from "@fortawesome/free-regular-svg-icons";
import {
  faAdd,
  faCancel,
  faChevronRight,
  faClose,
  faEllipsisV,
} from "@fortawesome/free-solid-svg-icons";
import { FontAwesomeIcon } from "@fortawesome/react-fontawesome";
import {
  useClubFeatureFlags,
  useInfiniteQueryMemberInvoiceListNew,
  useInfiniteQueryMemberInvoiceListNewAsMember,
  useMemberAsMember,
  useMemberInvoice,
  useMemberPaymentMethodListAsMember,
  useMutationMemberInvoice,
  useMutationMemberInvoiceAsMember,
  useQueryMemberPaymentMethodListNew,
} from "@gymflow/api";
import { isMobile } from "@gymflow/common";
import { formatCurrency } from "@gymflow/helpers";
import { InvoiceStatus, UserMemberBean } from "@gymflow/types";
import { createColumnHelper } from "@tanstack/react-table";
import { useAddPaymentMethodAlert } from "apps/portal/src/hooks/useAddPaymentMethodAlert";
import { ToastContext } from "apps/portal/src/providers/ToastProvider/context";
import { isAxiosError } from "axios";
import { capitalize } from "lodash";
import { DateTime } from "luxon";
import { useContext, useRef, useState } from "react";
import { useTranslation } from "react-i18next";
import { useWindowSize } from "usehooks-ts";

import { downloadHtml } from "../../../../../../libs/helpers/src";
import { usePageSize } from "../../../hooks";
import {
  ModalContext,
  useAuthenticatedUser,
  useClubSettings,
} from "../../../providers";
import useGymflowModels from "../../../store";
import {
  Badge,
  Button,
  DownloadIcon,
  Popover,
  PopoverContent,
  PopoverTrigger,
  RefreshIcon,
} from "../../atoms";
import { EmptyDotBadge, EmptyDotBadgeProps } from "../../atoms/EmptyDotBadge";
import { PencilIcon } from "../../atoms/icons/PencilIcon";
import { PaymentMethodDetails } from "../../molecules/PaymentMethodDetails";
import { PaginatedTable } from "../../organisms";
import { ConfirmModal } from "../../templates";
import { UserMemberAdjustInvoiceModal } from "./UserMemberAdjustInvoiceModal";
import { UserMemberHighlightedInvoice } from "./UserMemberHighlightedInvoice";
import { UserMemberPaymentMethodSidebar } from "./UserMemberPaymentMethodSidebar";
import { UserMemberPaymentsCard } from "./UserMemberPaymentsCard";
import UserMemberPaymentsCollectManuallySidebar from "./UserMemberPaymentsCollectManuallySidebar/UserMemberPaymentsCollectManuallySidebar";

export type UserMemberPaymentsProps =
  | {
      asMember?: false;
      user: UserMemberBean;
    }
  | {
      asMember: true;
    };

const StatusToColor = {
  AWAITING_AUTHORIZATION: "warning",
  PAID: "success",
  PAST_DUE: "error",
  PROCESSING: "default",
  RE_SCHEDULE: "warning",
  REFUNDED: "default",
  SCHEDULED: "secondary",
  VOID: "error",
  WAITING_CONFIRMATION: "warning",
  WRITTEN_OFF: "default",
} as {
  [key in InvoiceStatus]: EmptyDotBadgeProps["intent"];
};

export const UserMemberPayments = (props: UserMemberPaymentsProps) => {
  const { t } = useTranslation();
  const { api } = useGymflowModels();
  const settings = useClubSettings();
  const { asMember } = props;
  const { id } = useAuthenticatedUser();
  const memberId = asMember ? id! : props.user.id;
  const { data: memberAsMember } = useMemberAsMember({
    api,
    memberId: asMember ? id! : undefined,
    tz: settings.timezone,
  });
  const userMember = (asMember ? memberAsMember : props.user) ?? undefined;
  const [currentPage, setCurrentPage] = useState(0);
  const [isVisibleCollectManuallySidebar, setIsVisibleCollectManuallySidebar] =
    useState(false);
  const [activeInvoiceNumber, setActiveInvoiceNumber] = useState<string>();
  const tableContainerRef = useRef<HTMLDivElement>(null);
  const { data: featureFlags } = useClubFeatureFlags({
    api,
    clubId: settings.clubId,
  });
  const pageSize = usePageSize({
    tableContainerRef,
    rowHeight: 56,
  });
  const invoiceQueryAsStaff = useInfiniteQueryMemberInvoiceListNew(
    {
      api,
      memberId: memberId,
      params: { limit: pageSize },
    },
    {
      enabled: !asMember,
    },
  );
  const invoiceQueryAsMember = useInfiniteQueryMemberInvoiceListNewAsMember(
    {
      api,
      memberId: memberId,
      params: { limit: pageSize },
    },
    {
      enabled: !!asMember,
    },
  );
  const {
    data: invoices,
    isLoading,
    fetchNextPage,
  } = asMember ? invoiceQueryAsMember : invoiceQueryAsStaff;
  const {
    collectInvoiceMutation,
    refundInvoiceMutation,
    writeOffInvoiceMutation,
    cancelAuthorizationMutation,
  } = useMemberInvoice({ api, memberId: memberId });
  const { stackModal, hide } = useContext(ModalContext);
  const columnHelper =
    createColumnHelper<
      NonNullable<typeof invoices>["pages"][number]["content"][number]
    >();
  const { notifyDanger } = useContext(ToastContext);
  const [isSidebarOpen, setIsSidebarOpen] = useState(false);
  const [parent] = useAutoAnimate();
  const paymentMethodsQueryAsStaff = useQueryMemberPaymentMethodListNew({
    api,
    memberId: memberId,
    clubId: settings.clubId,
    enabled: !asMember,
  });
  const paymentMethodsQueryAsMember = useMemberPaymentMethodListAsMember({
    api,
    memberId: memberId,
    clubId: settings.clubId,
    enabled: !!asMember,
  });
  const { data: paymentMethods, isLoading: isLoadingPaymentMethods } = asMember
    ? paymentMethodsQueryAsMember
    : paymentMethodsQueryAsStaff;
  const defaultPaymentMethod = paymentMethods?.find(
    (e) => e.defaultPaymentMethod,
  );
  const showAddPaymentMethodAlert = useAddPaymentMethodAlert({
    userMember,
    asMember,
  });
  const downloadInvoiceMutationAsStaff = useMutationMemberInvoice({
    api,
    memberId: memberId,
    clubId: settings.clubId,
  });
  const downloadInvoiceMutationAsMember = useMutationMemberInvoiceAsMember({
    api,
    memberId: memberId,
    clubId: settings.clubId,
  });
  const { mutateAsync: downloadInvoice } = asMember
    ? downloadInvoiceMutationAsMember
    : downloadInvoiceMutationAsStaff;

  const totalAmountColumn = columnHelper.display({
    header: "Amount",
    enableSorting: false,
    cell: (column) => {
      const invoice = column.row.original;
      return (
        <div className="flex flex-col items-start py-2">
          <div className="font-bold text-gray-950">
            {formatCurrency(invoice.totalAmount, settings.defaultCurrency)}
          </div>
          <div className="text-sm text-gray-300">
            {formatCurrency(invoice.dueAmount, settings.defaultCurrency)}
          </div>
        </div>
      );
    },
  });
  const issueDateColumn = columnHelper.accessor("issueDate", {
    header: "Date",
    enableSorting: false,
    cell: (column) => {
      return (
        <div>
          {DateTime.fromISO(column.getValue()).toLocaleString(
            DateTime.DATE_MED,
          )}
        </div>
      );
    },
  });

  const statusColumn = columnHelper.accessor("status", {
    header: "Status",
    enableSorting: false,
    cell: (column) => {
      const status = column.getValue();
      const errorCode = column.row.original.lastPaymentErrorCode;
      return (
        <EmptyDotBadge
          tooltip={
            status === "PAST_DUE"
              ? t(`StripeErrorCodes.Short.${errorCode ?? "UNKNOWN"}`)
              : undefined
          }
          intent={StatusToColor[status]}
        >
          {status
            .split("_")
            .map((e) => capitalize(e))
            .join(" ")}
        </EmptyDotBadge>
      );
    },
  });
  const actionColumn = columnHelper.display({
    id: "actions",
    enableSorting: false,
    cell: (column) => {
      const invoice = column.row.original;
      const actions = [];
      if (invoice.status !== "SCHEDULED") {
        actions.push(
          <Button
            intent="transparent"
            className="h-8 min-h-0 justify-start gap-2 p-2 py-0"
            onClick={async () => {
              const { number } = invoice;
              const { data: html } = await downloadInvoice({
                invoiceNumber: number,
              });
              downloadHtml(html, `Gymflow-${number}.html`);
            }}
          >
            <DownloadIcon pathClassName="stroke-gray-500" className="h-4 w-4" />
            Download
          </Button>,
        );
      }
      if (!asMember && invoice.status === "PAID" && invoice.totalAmount > 0) {
        actions.push(
          <Button
            intent="transparent"
            className="h-8 min-h-0 justify-start gap-2 p-2 py-0"
            onClick={async () => {
              stackModal(
                <ConfirmModal
                  type="warning"
                  title="Refund Invoice"
                  onCancel={() => {
                    hide();
                  }}
                  onConfirm={async () => {
                    try {
                      await refundInvoiceMutation.mutateAsync({
                        invoiceNumber: invoice.number,
                      });
                      hide();
                    } catch (e) {
                      if (isAxiosError(e)) {
                        notifyDanger(e);
                      }
                    }
                  }}
                >
                  Are you sure you want to refund this invoice?
                </ConfirmModal>,
              );
            }}
          >
            <RefreshIcon className="h-4 w-4" />
            Refund
          </Button>,
        );
      }
      if (!asMember && invoice.status === "AWAITING_AUTHORIZATION") {
        actions.push(
          <Button
            intent="transparent"
            className="h-8 min-h-0 justify-start gap-2 p-2 py-0"
            onClick={async () => {
              await cancelAuthorizationMutation.mutateAsync({
                invoiceNumber: invoice.number,
              });
            }}
          >
            <FontAwesomeIcon icon={faClose} className="h-4 w-4" />
            Cancel Auth
          </Button>,
        );
      }
      if (invoice.status === "PAST_DUE" && !asMember) {
        actions.push(
          <Button
            intent="transparent"
            className="h-8 min-h-0 justify-start gap-2 p-2 py-0"
            onClick={async () => {
              if (
                featureFlags?.featureFlags
                  .PORTAL_PAST_DUE_INVOICE_COLLECTION_SIDEBAR
              ) {
                setActiveInvoiceNumber(invoice.number);
                setIsVisibleCollectManuallySidebar(true);
                return;
              }

              try {
                await collectInvoiceMutation.mutateAsync({
                  invoiceNumber: invoice.number,
                });
              } catch (e) {
                if (isAxiosError(e)) {
                  notifyDanger(e);
                }
              }
            }}
          >
            <FontAwesomeIcon icon={faCreditCard} className="h-4 w-4" />
            Collect
          </Button>,
        );
      }
      if (
        !asMember &&
        (invoice.status === "PAST_DUE" || invoice.status === "SCHEDULED")
      ) {
        actions.push(
          <Button
            intent="transparent"
            className="h-8 min-h-0 justify-start gap-2 p-2 py-0"
            onClick={async () => {
              if (!userMember) return;
              stackModal(
                <UserMemberAdjustInvoiceModal
                  invoice={invoice}
                  userMember={userMember}
                />,
              );
            }}
          >
            <PencilIcon className="h-4 w-4" />
            Adjust
          </Button>,
        );
      }
      if (!asMember && invoice.status === "PAST_DUE") {
        actions.push(
          <Button
            intent="transparent"
            className="h-8 min-h-0 justify-start gap-2 p-2 py-0"
            onClick={async () => {
              await writeOffInvoiceMutation.mutateAsync({
                invoiceNumber: invoice.number,
              });
            }}
          >
            <FontAwesomeIcon icon={faCancel} className="h-4 w-4" />
            Write Off
          </Button>,
        );
      }
      if (actions.length === 0) {
        return;
      }
      return (
        <Popover>
          <PopoverTrigger asChild>
            <Button className="h-9 w-9 p-0">
              <FontAwesomeIcon
                icon={faEllipsisV}
                className="h-4 w-4 text-gray-950"
              />
            </Button>
          </PopoverTrigger>
          <PopoverContent className="flex w-48 flex-col gap-1 p-2" align="end">
            {actions}
          </PopoverContent>
        </Popover>
      );
    },
  });
  const mobileColumns = [
    columnHelper.display({
      id: "date-and-price",
      header: "Amount",
      enableSorting: false,
      cell: (column) => {
        const invoice = column.row.original;
        return (
          <div className="flex flex-col items-start py-2">
            <div className="text-gray-950">
              {DateTime.fromISO(invoice.issueDate).toLocaleString(
                DateTime.DATE_MED,
              )}
            </div>
            <div className="font-bold text-gray-950">
              {formatCurrency(invoice.totalAmount, settings.defaultCurrency)}
            </div>
            <div className="text-sm text-gray-300">
              {formatCurrency(invoice.dueAmount, settings.defaultCurrency)}
            </div>
          </div>
        );
      },
    }),
    statusColumn,
    actionColumn,
  ];
  const desktopColumns = [
    issueDateColumn,
    totalAmountColumn,
    columnHelper.accessor("promotionCode", {
      header: "Promotion Code",
      enableSorting: false,
      cell: (column) => {
        return <Badge>{column.getValue() ?? "-"}</Badge>;
      },
    }),
    statusColumn,
    actionColumn,
  ];

  const windowSize = useWindowSize({ debounceDelay: 100 });
  const currentPageInvoices = invoices?.pages[currentPage]?.content ?? [];

  return (
    <>
      {userMember && (
        <UserMemberPaymentMethodSidebar
          isOpen={isSidebarOpen}
          hide={() => setIsSidebarOpen(false)}
          userMember={userMember}
          asMember={asMember}
        />
      )}
      {userMember && !!activeInvoiceNumber && (
        <UserMemberPaymentsCollectManuallySidebar
          isVisible={isVisibleCollectManuallySidebar}
          onClose={() => {
            setIsVisibleCollectManuallySidebar(false);
            setActiveInvoiceNumber(undefined);
          }}
          userMember={userMember}
          invoiceNumber={activeInvoiceNumber}
        />
      )}
      <div
        ref={parent}
        className="-m-4 flex h-full flex-col gap-4 overflow-y-auto overflow-x-hidden p-4"
      >
        <div className="flex flex-col gap-4 lg:flex-row">
          {userMember && !asMember && (
            <UserMemberHighlightedInvoice
              userMember={userMember}
              onOpenCollectManuallySidebar={(invoiceNumber) => {
                setActiveInvoiceNumber(invoiceNumber);
                setIsVisibleCollectManuallySidebar(true);
              }}
            />
          )}
          <UserMemberPaymentsCard.Container isLoading={isLoadingPaymentMethods}>
            <UserMemberPaymentsCard.Header>
              <div className="flex flex-row items-center gap-2">
                <div className="bg-lightblue-100 flex h-9 w-9 items-center justify-center rounded-full">
                  <FontAwesomeIcon
                    icon={faCreditCard}
                    className="text-lightblue-500"
                  />
                </div>
                <div className="flex font-semibold text-gray-950">
                  Default Card
                </div>
              </div>
              {defaultPaymentMethod && (
                <Button
                  onClick={() => setIsSidebarOpen(true)}
                  intent="transparent"
                  className="h-9 items-center gap-2"
                >
                  <div>View all cards</div>
                  <FontAwesomeIcon icon={faChevronRight} />
                </Button>
              )}
            </UserMemberPaymentsCard.Header>
            <UserMemberPaymentsCard.Footer>
              {!defaultPaymentMethod && (
                <Button
                  onClick={() => {
                    showAddPaymentMethodAlert();
                  }}
                  intent="secondary-outline"
                  className="mt-4 flex w-full flex-row gap-1"
                >
                  <FontAwesomeIcon
                    icon={faAdd}
                    className="text-secondary-500"
                  />
                  Add Card
                </Button>
              )}
              {defaultPaymentMethod && (
                <div
                  className="flex h-16 flex-1 flex-row items-center justify-between rounded-xl border border-gray-200 px-4 py-3"
                  key={defaultPaymentMethod.id}
                >
                  <PaymentMethodDetails paymentMethod={defaultPaymentMethod} />
                </div>
              )}
            </UserMemberPaymentsCard.Footer>
          </UserMemberPaymentsCard.Container>
        </div>
        <div className="flex h-full min-h-[24rem] flex-col gap-4">
          <div className="text-lg font-semibold text-gray-950">Invoices</div>
          <PaginatedTable
            tableProps={{
              data: currentPageInvoices,
              columns: !isMobile(windowSize.width)
                ? desktopColumns
                : mobileColumns,
              isFetching: isLoading,
              pageSize: pageSize,
              tableContainerRef,
            }}
            hasPreviousPage={!!invoices && currentPage > 0}
            hasNextPage={
              !!invoices && !!invoices.pages[currentPage]?.nextPageToken
            }
            goToNextPage={() => {
              setCurrentPage((e) => e + 1);
              if (!invoices?.pages[currentPage + 1]) {
                fetchNextPage();
              }
            }}
            goToPreviousPage={() => {
              setCurrentPage((e) => e - 1);
            }}
          />
        </div>
      </div>
    </>
  );
};
