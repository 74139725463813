import { useAutoAnimate } from "@formkit/auto-animate/react";
import { faAngleLeft, faAngleRight } from "@fortawesome/free-solid-svg-icons";
import { FontAwesomeIcon } from "@fortawesome/react-fontawesome";
import { cn } from "@gymflow/helpers";
import { DateTime } from "luxon";
import { useRef, useState } from "react";
import { useResizeObserver } from "usehooks-ts";

import { Button } from "../../../atoms";

export type HostedPagesAppointableCalendarPickerProps = {
  currentDate: DateTime;
  setCurrentDate: (date: DateTime) => void;
};

const HostedPagesAppointableCalendarPicker = ({
  currentDate,
  setCurrentDate,
}: HostedPagesAppointableCalendarPickerProps) => {
  const containerRef = useRef<HTMLDivElement>(null);
  const { width = 0 } = useResizeObserver({
    ref: containerRef,
    box: "border-box",
  });
  const amountToRender = Math.floor(width / 56);
  const today = DateTime.now().setZone(currentDate.zone).startOf("day");
  const [currentFirstDate, setCurrentFirstDate] = useState(today);
  const [parent] = useAutoAnimate();
  return (
    <div className="flex w-full flex-col items-center gap-2 border-b border-gray-300 bg-gray-0 p-4 dark:border-gray-700 dark:bg-gray-950 lg:flex-row lg:justify-between lg:px-28">
      <div className="text-xl font-semibold text-gray-950 dark:text-gray-0 max-lg:w-[min(32rem,100vw-2rem)]">
        {currentDate.toLocaleString({
          weekday: "short",
          month: "short",
          day: "numeric",
        })}
      </div>
      <div className="flex w-[min(32rem,100vw-2rem)] flex-row items-center gap-2">
        <Button
          onClick={() => {
            setCurrentFirstDate(
              currentFirstDate.minus({ days: amountToRender }),
            );
          }}
          intent="transparent"
          className={cn("h-6 w-6 min-w-0 p-2 transition-opacity", {
            "opacity-0 cursor-default": currentFirstDate.hasSame(today, "day"),
          })}
          disabled={currentFirstDate.hasSame(today, "day")}
        >
          <FontAwesomeIcon icon={faAngleLeft} className="h-4 w-4" />
        </Button>
        <div ref={containerRef} className="flex w-full">
          <div
            className="flex w-full flex-row items-center justify-center gap-2"
            ref={parent}
          >
            {new Array(amountToRender ?? 0).fill(0).map((_, i) => {
              const date = currentFirstDate.plus({ days: i });
              return (
                <Button
                  intent={
                    date.hasSame(currentDate, "day")
                      ? "secondary-outline"
                      : "default"
                  }
                  key={date.toISO()}
                  className="!h-16 !w-12 flex-col !p-2"
                  onClick={() => {
                    setCurrentDate(date);
                  }}
                >
                  <div className="text-sm">
                    {date.toLocaleString({
                      weekday: "short",
                    })}
                  </div>
                  <div className="font-semibold">
                    {date.toLocaleString({
                      day: "numeric",
                    })}
                  </div>
                </Button>
              );
            })}
          </div>
        </div>

        <Button
          onClick={() => {
            setCurrentFirstDate(
              currentFirstDate.plus({ days: amountToRender }),
            );
          }}
          intent="transparent"
          className="h-6 w-6 min-w-0 p-2"
        >
          <FontAwesomeIcon icon={faAngleRight} className="h-4 w-4" />
        </Button>
      </div>
      <div className="invisible max-lg:hidden">
        {currentDate.toLocaleString({
          weekday: "short",
          month: "short",
          day: "numeric",
        })}
      </div>
    </div>
  );
};

export default HostedPagesAppointableCalendarPicker;
