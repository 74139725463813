import {
  useInfiniteQueryUserMemberBookingAgenda,
  useMutationUpdateRsvpAsMember,
} from "@gymflow/api";
import {
  AlertContext,
  DATE_FORMAT,
  InvoiceStatus,
  SubscriptionStatus,
} from "@gymflow/common";
import { formatCurrency } from "@gymflow/helpers";
import classNames from "classnames";
import { useStoreState } from "easy-peasy";
import noop from "lodash/noop";
import moment from "moment-timezone";
import qs from "qs";
import { useContext, useEffect, useState } from "react";
import { Link, useHistory } from "react-router-dom";
import { Col, Row } from "reactstrap";

import EventCard from "../components/EventCard/EventCard";
import OverdueBanner from "../components/OverdueBanner";
import PayInvoiceAlert from "../components/UserMember/PayInvoiceAlert";
import { usePortalRoutes } from "../hooks/usePortalRoutes";
import useScaModal from "../hooks/useScaModal";
import { useClubSettings } from "../providers";
import { RouteFeature } from "../routes/feature";
import { RouteLayout } from "../routes/layout";
import useGymflowModels from "../store";

function Dashboard() {
  const { api, UserMemberStore, settingsStore, UserInvoiceStore, authStore } =
    useGymflowModels();
  const { id } = useStoreState(authStore);
  const history = useHistory();
  const { createClubLink } = usePortalRoutes();

  const showScaModal = useScaModal({
    asMember: true,
  });
  const { fetchById: fetchUserDetails, clearEditingRecord } =
    UserMemberStore.useStoreActions((actions) => actions);
  const { memberships, editing: user } = UserMemberStore.useStoreState(
    (state) => state,
  );
  const hasOverdueMembership =
    memberships &&
    memberships.some((m) => m.status === SubscriptionStatus.Overdue);
  const { defaultCurrency: currency } = useStoreState(settingsStore);
  const settings = useClubSettings();
  const alert = useContext(AlertContext);
  const [overdueInvoice, setOverdueInvoice] = useState(null);
  const { fetchList: fetchInvoices } = UserInvoiceStore.useStoreActions(
    (actions) => actions,
  );
  const { rows: pastDueInvoices } = UserInvoiceStore.useStoreState(
    (state) => state,
  );

  const updateRsvp = useMutationUpdateRsvpAsMember({ api });
  const {
    data: userAgenda,
    hasNextPage,
    fetchNextPage,
  } = useInfiniteQueryUserMemberBookingAgenda({
    api,
    tz: settings.timezone,
    filters: {
      includeBookedCounts: true,
      includeWaitingCounts: true,
    },
  });

  useEffect(() => {
    if (hasNextPage) {
      fetchNextPage();
    }
  }, [fetchNextPage, hasNextPage, userAgenda?.pageParams.length]);

  useEffect(() => {
    fetchUserDetails();
    return () => clearEditingRecord();
  }, [id, fetchUserDetails, clearEditingRecord]);

  useEffect(() => {
    if (!hasOverdueMembership || !user) {
      return;
    }

    fetchInvoices({
      userId: user.id,
      sort: { field: "issueDate", desc: true },
      extraParams: { status: InvoiceStatus.PastDue },
      page: 0,
    });
  }, [hasOverdueMembership, fetchInvoices, user]);

  useEffect(() => {
    if (!pastDueInvoices) {
      return null;
    }

    setOverdueInvoice(pastDueInvoices[0]);
  }, [pastDueInvoices]);

  const eventCards = userAgenda?.pages
    .flatMap((p) => p)
    .filter((e) => e.eventOccurrenceId)
    .map((event) => {
      const {
        eventOccurrenceId: id,
        bookingName: name,
        bookingDescription: description,
        facilityName,
        hostName,
        hostPicture: picture,
        capacity,
        waitListCapacity,
        bookable: isBookable,
        eventRsvpId,
        eventRsvpStatus,
        startDate,
        bookedCount,
        waitingCount,
      } = event;
      const [hostFirstName, hostLastName] = hostName.split(" ");
      return (
        <EventCard
          key={id}
          activityId={event.activityId}
          eventId={id}
          activityName={name}
          activityDescription={description}
          facilityName={facilityName}
          startDate={startDate}
          capacity={capacity}
          waitListCapacity={waitListCapacity}
          bookedCount={bookedCount}
          waitingCount={waitingCount}
          isBookable={isBookable}
          hostFirstName={hostFirstName}
          hostLastName={hostLastName}
          hostPicture={picture}
          onCardClick={() => {
            history.push({
              pathname: createClubLink(
                RouteLayout.Member,
                RouteFeature.Calendar,
              ),
              search: qs.stringify({
                date: moment(startDate).format(DATE_FORMAT),
              }),
            });
          }}
          showDateLine
          showRsvpButton={isBookable}
          updateRsvp={async (rsvp) => {
            return await updateRsvp.mutateAsync({
              status: rsvp.status,
              rsvpId: eventRsvpId,
            });
          }}
          addAttendeeToRsvp={noop}
          rsvpStatus={eventRsvpStatus}
        />
      );
    });

  const renderOverdueBanner = () => {
    if (!hasOverdueMembership || !overdueInvoice) {
      return null;
    }
    return (
      <Row>
        <Col md="12" lg="8">
          <OverdueBanner
            amountDue={formatCurrency(
              overdueInvoice.chargeableAmount,
              currency,
            )}
            onPayClick={() => {
              alert.setAlert(
                <PayInvoiceAlert
                  onCancel={alert.hide}
                  onConfirm={async ({ amount }) => {
                    alert.hide();
                    try {
                      await api.profileApi.payInvoice(
                        overdueInvoice.number,
                        amount,
                      );
                      showScaModal({
                        invoiceNumber: overdueInvoice.number,
                      });
                    } catch (e) {
                      alert.showError(
                        "An error occurred while trying to pay the invoice.",
                      );
                    }
                    await fetchUserDetails();
                  }}
                  maxAmount={overdueInvoice?.chargeableAmount}
                  title="Pay Invoice"
                  confirmButtonText="Pay"
                  text="Do you want to pay this invoice?"
                  currency={currency}
                />,
              );
            }}
          />
        </Col>
      </Row>
    );
  };

  return (
    <div className="content h-full overflow-y-auto p-8">
      {renderOverdueBanner()}
      <Row>
        <Col md="12" lg="8" style={{ maxWidth: "1000px" }}>
          <Row className="mt-lg-20 mt-3">
            <Col>
              <h2 className="mb-4">Upcoming Bookings</h2>
            </Col>
          </Row>
          <Row>
            <Col
              className={classNames("card-list", {
                hidden: eventCards?.length === 0,
              })}
            >
              {eventCards}
            </Col>
            <Col className={classNames({ hidden: eventCards?.length > 0 })}>
              <span className="text-muted">
                Find something to book&nbsp;
                <Link
                  to={createClubLink(RouteLayout.Member, RouteFeature.Calendar)}
                >
                  here
                </Link>
              </span>
            </Col>
          </Row>
        </Col>
      </Row>
    </div>
  );
}

const DashboardWithProvider = () => {
  const { UserMemberStore, UserInvoiceStore } = useGymflowModels();
  return (
    <UserMemberStore.Provider>
      <UserInvoiceStore.Provider>
        <Dashboard />
      </UserInvoiceStore.Provider>
    </UserMemberStore.Provider>
  );
};

export { DashboardWithProvider as Dashboard };
