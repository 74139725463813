import { useAutoAnimate } from "@formkit/auto-animate/react";
import { faPlus } from "@fortawesome/free-solid-svg-icons";
import { FontAwesomeIcon } from "@fortawesome/react-fontawesome";
import {
  useMemberAsMemberNew,
  useMemberPaymentMethodListAsMember,
} from "@gymflow/api";
import { cn } from "@gymflow/helpers";
import { usePortalRoutes } from "apps/portal/src/hooks";
import { RouteFeature } from "apps/portal/src/routes";
import { useTranslation } from "react-i18next";

import { useAddPaymentMethodAlert } from "../../hooks/useAddPaymentMethodAlert";
import { useClubSettings } from "../../providers";
import useGymflowModels from "../../store";
import { Button, Spinner } from "../atoms";
import { EmptyPage } from "../atoms/EmptyPage";
import { PaymentMethodList } from "../UserMember/UserMemberPayments/PaymentMethodList";
import { HostedPagesBackHeader } from "./components/HostedPagesBackHeader";
import useRedirectIfNotAuthenticated from "./useRedirectIfNotAuthenticated";

export const HostedPagesProfilePayments = () => {
  const { t } = useTranslation();
  const { isEmbed } = usePortalRoutes();
  const { createSiteOrEmbedLink } = usePortalRoutes();
  useRedirectIfNotAuthenticated(
    createSiteOrEmbedLink(
      RouteFeature.SiteBuyMembershipType.replace(":membershipType", "all"),
    ),
  );
  const { api } = useGymflowModels();
  const settings = useClubSettings();
  const { data: userMember, isLoading: isLoadingUserMember } =
    useMemberAsMemberNew({ api });
  const { data: paymentMethods, isLoading: isLoadingPaymentMethods } =
    useMemberPaymentMethodListAsMember({
      api,
      memberId: userMember?.id,
      clubId: settings.clubId,
    });
  const isLoading = isLoadingUserMember || isLoadingPaymentMethods;
  const showAddPaymentMethodAlert = useAddPaymentMethodAlert({
    userMember,
    asMember: true,
  });
  const [parent] = useAutoAnimate();
  return (
    <div
      className={cn("track-height flex w-full flex-col overflow-y-auto", {
        "h-fit": isEmbed,
        "h-full": !isEmbed,
      })}
    >
      <HostedPagesBackHeader
        label={t("page.hostedPagesProfilePayments.header")}
        rightSideOptions={
          !isLoadingPaymentMethods &&
          paymentMethods &&
          paymentMethods.length > 0 ? (
            <Button
              intent="secondary-outline"
              className="items-center gap-2"
              onClick={() => {
                showAddPaymentMethodAlert();
              }}
            >
              <FontAwesomeIcon icon={faPlus} className="text-secondary-500" />
              <div className="max-lg:hidden">{t("pages.hostedPagesProfilePayments.addCard")}</div>
            </Button>
          ) : undefined
        }
      />
      <div
        ref={parent}
        className={cn("flex flex-col items-center bg-gray-50 dark:bg-gray-900 px-4 py-6", {
          "h-fit": isEmbed,
          "h-full": !isEmbed,
        })}
      >
        {isLoading && <Spinner />}
        {!isLoading &&
          userMember &&
          paymentMethods &&
          paymentMethods?.length === 0 && (
            <div className="flex h-full w-full flex-col items-center justify-center pb-8">
              <EmptyPage
                textOverride={t(
                  "pages.hostedPagesProfilePayments.emptyPage.title",
                )}
                description={t(
                  "pages.hostedPagesProfilePayments.emptyPage.description",
                )}
                action={
                  <Button
                    intent="secondary"
                    className="items-center gap-2"
                    onClick={() => {
                      showAddPaymentMethodAlert();
                    }}
                  >
                    <FontAwesomeIcon icon={faPlus} />
                    <div className="max-lg:hidden">{t("pages.hostedPagesProfilePayments.addCard")}</div>
                  </Button>
                }
              />
            </div>
          )}
        {!isLoading &&
          userMember &&
          paymentMethods &&
          paymentMethods?.length > 0 && (
            <div className="relative flex h-full w-full max-w-lg">
              <PaymentMethodList
                paymentMethods={paymentMethods}
                userMember={userMember}
                asMember={true}
              />
            </div>
          )}
      </div>
    </div>
  );
};
