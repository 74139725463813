import { cn } from "@gymflow/helpers";
import {
  useInfiniteQuery,
  UseInfiniteQueryOptions,
} from "@tanstack/react-query";
import mapTorTArrayToTArray from "libs/helpers/src/lib/mapTorTArrayToTArray";
import { useCallback, useEffect, useMemo, useRef, useState } from "react";
import { useOnClickOutside } from "usehooks-ts";

import { ChevronDownIcon, CloseIcon } from "../icons";
import { OptionType } from "./components/OptionsList/components/OptionItem";
import { OptionsList } from "./components/OptionsList/OptionsList";
import { OptionsListProps } from "./components/OptionsList/types";
import { SelectedOptionBadge } from "./components/SelectedOptionBadge";
import { SelectedOptionItem } from "./components/SelectedOptionItem";
import { Input, InputProps } from "./Input/Input";

export type SelectInputOptionType<T> = OptionType<T>;

export type SelectInputProps<T> = ((
  | {
      isMulti: true;
      value: SelectInputOptionType<T>[];
      onChange: (selected: SelectInputOptionType<T>[]) => void;
    }
  | {
      isMulti?: false;
      value?: SelectInputOptionType<T>;
      onChange: (selected?: SelectInputOptionType<T>) => void;
    }
) & {
  withImageByDefault?: boolean;
  isClearable?: boolean;
  disabled?: boolean;
} & (
    | {}
    | { options: SelectInputOptionType<T>[] }
    | {
        dataFetchingQuery: () => UseInfiniteQueryOptions<
          unknown,
          unknown,
          SelectInputOptionType<T>[]
        >;
      }
  )) &
  Pick<
    InputProps,
    | "label"
    | "placeholder"
    | "className"
    | "variant"
    | "isRequired"
    | "labelClassName"
  > &
  Pick<OptionsListProps<T>, "notFound" | "notFoundClassName" | "dropPosition">;

export const SelectInput = <T,>({
  dropPosition = "bottom",
  placeholder = "Select",
  withImageByDefault = false,
  notFoundClassName,
  labelClassName,
  notFound,
  isClearable,
  disabled = false,
  variant,
  isRequired,
  label,
  className,
  ...rest
}: SelectInputProps<T>) => {
  const dataFetchingQuery =
    "dataFetchingQuery" in rest ? rest.dataFetchingQuery : undefined;
  const options = "options" in rest ? rest.options : undefined;

  const [isOpen, setIsOpen] = useState(false);
  const inputRef = useRef<HTMLInputElement>(null);
  const listRef = useRef<HTMLDivElement>(null);
  const containerRef = useRef<HTMLDivElement>(null);

  const queryOptions = dataFetchingQuery
    ? dataFetchingQuery()
    : { queryKey: [], enabled: false };
  const { data, hasNextPage, isFetching, fetchNextPage } = useInfiniteQuery({
    ...queryOptions,
  });

  useOnClickOutside(containerRef, () => setIsOpen(false));

  const paginatedOptions = useMemo(() => {
    const fetchedOptions = data?.pages.flatMap((page) => page) || [];
    return [...(options || []), ...fetchedOptions];
  }, [data, options]);

  const hasScroll = useCallback(() => {
    if (!listRef.current) return false;
    return listRef.current.scrollHeight > listRef.current.clientHeight;
  }, []);

  // For case if we don't have scroll (because of small list) but hasNextPage is true
  useEffect(() => {
    if (isOpen && hasNextPage && !isFetching && !hasScroll()) {
      fetchNextPage();
    }
  }, [isOpen, hasNextPage, fetchNextPage, isFetching, hasScroll]);

  const handleScroll = async (e: React.UIEvent<HTMLDivElement>) => {
    if (isFetching || !hasNextPage || !isOpen) return;
    const { scrollTop, scrollHeight, clientHeight } = e.currentTarget;
    if (scrollTop + clientHeight >= scrollHeight - 20) {
      fetchNextPage();
    }
  };

  const handleSelectOption = useCallback(
    (option: SelectInputOptionType<T>) => {
      if (rest.isMulti) {
        inputRef.current?.focus();
        const isAlreadySelected = rest.value.some(
          (v) => String(v.id) === String(option.id),
        );
        const updatedValue = isAlreadySelected
          ? rest.value.filter((v) => String(v.id) !== String(option.id))
          : [...rest.value, option];

        return rest.onChange(updatedValue);
      } else {
        rest.onChange(option);
        setIsOpen(false);
      }
    },
    [rest],
  );

  const onClear = useCallback(() => {
    isOpen && inputRef.current?.focus();
    if (rest.isMulti) {
      rest.onChange([]);
    } else {
      rest.onChange(undefined);
    }
  }, [isOpen, rest]);

  const isSelectedAndMulti = rest.isMulti && rest.value.length > 0;
  const isSelectedSingleValue = !rest.isMulti && !!rest.value;
  return (
    <div className="relative">
      <div ref={containerRef}>
        <Input
          ref={inputRef}
          value="" // it's ok, we don't need it
          onChange={() => {}} // it's ok, we don't need it
          onFocus={() => !disabled && setIsOpen(true)}
          label={label}
          labelClassName={labelClassName}
          className={cn(
            "cursor-pointer",
            { "opacity-50 cursor-not-allowed": disabled },
            className,
          )}
          placeholder={isSelectedSingleValue ? "" : placeholder}
          readOnly
          disabled={disabled}
          isRequired={isRequired}
          variant={variant}
          renderOverInput={
            <>
              <div className="absolute inset-y-0 right-3.5 flex items-center gap-1.5">
                {isSelectedAndMulti && (
                  <SelectedOptionBadge
                    value={rest.value.length}
                    onClick={onClear}
                  />
                )}
                {isClearable && isSelectedSingleValue && (
                  <div onClick={onClear}>
                    <CloseIcon className="h-4 w-4 cursor-pointer" />
                  </div>
                )}
                <div
                  onClick={() => {
                    if (disabled) return;
                    !isOpen && inputRef.current?.focus();
                    setIsOpen(!isOpen);
                  }}
                >
                  <ChevronDownIcon
                    className={cn("cursor-pointer", {
                      "rotate-180": isOpen,
                      "opacity-50 cursor-not-allowed": disabled,
                    })}
                  />
                </div>
              </div>
              <SelectedOptionItem
                disabled={disabled}
                show={isSelectedSingleValue}
                label={mapTorTArrayToTArray(rest.value)?.[0]?.label ?? ""}
                subLabel={mapTorTArrayToTArray(rest.value)?.[0]?.subLabel}
                imageUrl={mapTorTArrayToTArray(rest.value)?.[0]?.imageUrl}
                withImageByDefault={withImageByDefault}
              />
            </>
          }
        />
        <OptionsList
          listRef={listRef}
          show={isOpen}
          options={paginatedOptions}
          value={mapTorTArrayToTArray(rest.value)!}
          handleSelectOption={handleSelectOption}
          dropPosition={dropPosition}
          withImageByDefault={withImageByDefault}
          notFoundClassName={notFoundClassName}
          notFound={notFound}
          onScroll={handleScroll}
          loading={isFetching}
        />
      </div>
      {isSelectedAndMulti && (
        <div className="mt-2 flex flex-wrap items-center gap-2">
          {rest.value.map(({ id, label }) => (
            <SelectedOptionBadge
              key={id}
              value={label}
              onClick={() =>
                rest.onChange(
                  rest.value.filter((v) => String(v.id) !== String(id)),
                )
              }
            />
          ))}
        </div>
      )}
    </div>
  );
};
