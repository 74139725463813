import { rulesHelper } from "@gymflow/helpers";
import { RuleGroup, RuleGroupPost } from "@gymflow/types";
import { z } from "zod";

const MembershipRuleSchema = z.object({
  name: z.string(),
  cancelMembership: z.discriminatedUnion("enabled", [
    z.object({
      enabled: z.literal(true),
      minimumPeriodNotice: z.number(),
    }),
    z.object({
      enabled: z.literal(false),
    }),
  ]),
  changeMembership: z
    .discriminatedUnion("enabled", [
      z.object({
        enabled: z.literal(true),
        minimumPeriodNotice: z.number(),
      }),
      z.object({
        enabled: z.literal(false),
      }),
    ])
    .optional(),
  membershipList: z
    .array(z.object({ value: z.number(), label: z.any() }))
    .optional(),
});
export type MembershipRuleFormType = z.infer<typeof MembershipRuleSchema>;
export const MembershipRuleMapper = {
  schema: MembershipRuleSchema,
  defaultValues: (PORTAL_NEW_MEMBERSHIP_CHANGE_RULE?: boolean) =>
    PORTAL_NEW_MEMBERSHIP_CHANGE_RULE
      ? ({
          name: "",
          cancelMembership: {
            enabled: false,
          },
          changeMembership: {
            enabled: false,
          },
        } as const)
      : ({
          name: "",
          cancelMembership: {
            enabled: false,
          },
        } as const),
  mapBEtoFE: (
    ruleGroup: RuleGroup,
    PORTAL_NEW_MEMBERSHIP_CHANGE_RULE?: boolean,
  ) => {
    const membershipRules = rulesHelper.mapRuleGroupToMembershipRules(
      ruleGroup,
      PORTAL_NEW_MEMBERSHIP_CHANGE_RULE,
    );
    const result: MembershipRuleFormType = {
      name: ruleGroup.name,
      cancelMembership: membershipRules.subscriptionCancellationRule!
        .allowCustomerCancelMemberships
        ? {
            enabled: true,
            minimumPeriodNotice:
              membershipRules.subscriptionCancellationRule.minimumPeriodNotice,
          }
        : {
            enabled: false,
          },
    };

    if (
      PORTAL_NEW_MEMBERSHIP_CHANGE_RULE &&
      membershipRules.subscriptionMembershipChangeRule
    ) {
      result.changeMembership =
        membershipRules.subscriptionMembershipChangeRule!
          .allowCustomerChangeMemberships
          ? {
              enabled: true,
              minimumPeriodNotice:
                membershipRules.subscriptionMembershipChangeRule
                  .minimumPeriodNotice,
            }
          : {
              enabled: false,
            };
    }

    return result;
  },
  mapFEtoBE: (
    membershipRule: MembershipRuleFormType,
    PORTAL_NEW_MEMBERSHIP_CHANGE_RULE?: boolean,
  ) => {
    const result: RuleGroupPost = {
      name: membershipRule.name,
      groupType: "ACCESS_GROUP",
      ruleClubList: [
        {
          ruleType: "SUBSCRIPTION_CANCELLATION",
          subscriptionCancellationRule: {
            allowCustomerCancelMemberships:
              membershipRule.cancelMembership.enabled,
            minimumPeriodNotice: membershipRule.cancelMembership.enabled
              ? membershipRule.cancelMembership.minimumPeriodNotice
              : 0,
          },
        },
      ],
    };

    if (PORTAL_NEW_MEMBERSHIP_CHANGE_RULE && membershipRule.changeMembership) {
      result.ruleClubList.push({
        ruleType: "SUBSCRIPTION_MEMBERSHIP_CHANGE",
        subscriptionMembershipChangeRule: {
          allowCustomerChangeMemberships:
            membershipRule.changeMembership.enabled,
          minimumPeriodNotice: membershipRule.changeMembership.enabled
            ? membershipRule.changeMembership.minimumPeriodNotice
            : 0,
        },
      });
    }

    return result;
  },
};
