import { ApiType } from "@gymflow/common/lib/api/ApiType";
import { useQuery } from "@tanstack/react-query";

import { availabilityAsMemberQueryKeys } from "./availabilityAsMemberQueryKeys";

export function useQueryFacilityAvailabilityCalculateAsMemberNew({
  api,
  opts,
  enabled = true,
}: {
  api: ApiType;
  opts: Parameters<
    ApiType["customerAvailabilityApi"]["facilityCalculateAvailability"]
  >[0];
  enabled?: boolean;
}) {
  return useQuery({
    queryKey: availabilityAsMemberQueryKeys.facilityCalculateAvailability(opts),
    queryFn: async () => {
      return (
        await api.customerAvailabilityApi.facilityCalculateAvailability(opts)
      ).data;
    },
    enabled,
  });
}
