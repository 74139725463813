import { useClubFeatureFlags, useMemberAsMemberNew } from "@gymflow/api";
import { usePortalRoutes } from "apps/portal/src/hooks";
import { RouteFeature } from "apps/portal/src/routes";
import { useTranslation } from "react-i18next";

import { useAuthenticatedUser, useClubSettings } from "../../../providers";
import useGymflowModels from "../../../store";
import { Button, Popover, PopoverContent, PopoverTrigger } from "../../atoms";

export type HostedPagesProfileAvatarProps = {};

const HostedPagesProfileAvatar = ({}: HostedPagesProfileAvatarProps) => {
  const { t } = useTranslation();
  const { api } = useGymflowModels();
  const { clubId } = useClubSettings();
  const { createSiteOrEmbedLink } = usePortalRoutes();
  const auth = useAuthenticatedUser();
  const { data: userMember } = useMemberAsMemberNew({ api });
  const { data: featureFlags } = useClubFeatureFlags({ api, clubId });

  const initials = (
    (userMember?.firstName[0] ?? "") + (userMember?.lastName[0] ?? "")
  ).toUpperCase();

  return (
    <Popover>
      <PopoverTrigger asChild>
        <Button
          link={
            featureFlags?.featureFlags.HOSTED_PAGES_PROFILE
              ? createSiteOrEmbedLink(RouteFeature.SiteProfile)
              : undefined
          }
          intent="transparent"
          className="!border-secondary-500 flex h-12 w-12 items-center justify-center overflow-hidden !rounded-full border-2 border-solid p-0"
        >
          {userMember?.picture && (
            <img
              src={userMember?.picture}
              alt="User's Profile"
              className="flex aspect-square h-full w-full object-cover"
            />
          )}
          {!userMember?.picture && (
            <div className="flex text-center text-lg font-semibold text-gray-950 dark:text-gray-0">
              {initials}
            </div>
          )}
        </Button>
      </PopoverTrigger>
      <PopoverContent
        className="h-fit w-fit p-2"
        align="center"
        side="bottom"
        sideOffset={8}
        hidden={featureFlags?.featureFlags.HOSTED_PAGES_PROFILE}
      >
        <Button onClick={() => auth.logout()}>
          {t("pages.hostedPagesProfileAvatar.logout")}
        </Button>
      </PopoverContent>
    </Popover>
  );
};

export default HostedPagesProfileAvatar;
