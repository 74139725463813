import { ApiType } from "@gymflow/common/lib/api/ApiType";
import {
  AppointmentBookingCustomPaymentByCustomPaymentCategoryIdProps,
  AppointmentInvoiceDTO,
} from "@gymflow/types";
import { useMutation, UseMutationOptions } from "@tanstack/react-query";

export const useMutationAppointmentBookingCustomPaymentByCustomPaymentCategoryId =
  (
    {
      api,
    }: {
      api: ApiType;
    },
    mutationOpts?: UseMutationOptions<
      AppointmentInvoiceDTO,
      unknown,
      AppointmentBookingCustomPaymentByCustomPaymentCategoryIdProps
    >,
  ) =>
    useMutation({
      mutationFn: async (
        params: AppointmentBookingCustomPaymentByCustomPaymentCategoryIdProps,
      ) =>
        (
          await api.appointmentApi.appointmentBookingCustomPaymentByCustomPaymentCategoryId(
            params,
          )
        ).data,
      ...mutationOpts,
    });
