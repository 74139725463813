import { Field, FieldProps } from "formik";

import { LabeledSwitch, LabeledSwitchProps } from "../Switch";

type FormLabeledSwitchProps<T> = {
  name: keyof T;
} & Omit<LabeledSwitchProps, "onChange" | "value">;

export const FormLabeledSwitch = <T,>({
  name,
  ...props
}: FormLabeledSwitchProps<T>) => (
  <Field name={name}>
    {({ field }: FieldProps) => (
      <LabeledSwitch
        {...props}
        {...field}
        onChange={(value) =>
          field.onChange({
            target: {
              name,
              value,
            },
          })
        }
      />
    )}
  </Field>
);
