import {
  useMemberAsMemberNew,
  useQueryUserFormRulesAsPublic,
} from "@gymflow/api";
import useGymflowModels from "apps/portal/src/store";
import { ReactNode, useCallback, useState } from "react";
import { createPortal } from "react-dom";
import { useTranslation } from "react-i18next";

import { SlideSideBar, Spinner } from "../../../../atoms";
import { EditMemberForm } from "./components/EditMemberForm";
import { EditMemberSidebarProviderContext } from "./EditMemberSidebarProviderContext";

export type EditMemberSidebarProviderProps = { children: ReactNode };

export const EditMemberSidebarProvider = ({
  children,
}: EditMemberSidebarProviderProps) => {
  const {t} = useTranslation()
  const [isVisible, setIsVisible] = useState(false);

  const { api } = useGymflowModels();
  const memberQuery = useMemberAsMemberNew({ api });
  const formRulesQuery = useQueryUserFormRulesAsPublic({ api });
  const isLoading = memberQuery.isLoading || formRulesQuery.isLoading;
  const isError = memberQuery.isError || formRulesQuery.isError;
  const closeSidebar = useCallback(() => {
    setIsVisible(false);
  }, []);

  const openEditMemberSidebar = useCallback(() => {
    setIsVisible(true);
  }, []);

  return (
    <EditMemberSidebarProviderContext.Provider
      value={{ openEditMemberSidebar }}
    >
      {createPortal(
        <SlideSideBar
          isOpen={isVisible}
          hide={closeSidebar}
          className="w-[30rem]"
        >
          {isLoading ? (
            <div className="absolute inset-0 flex items-center justify-center">
              <Spinner className="h-12 w-12" />
            </div>
          ) : isError ? (
            <div className="absolute inset-0 flex items-center justify-center">
              {t("common.error")}
            </div>
          ) : (
            <EditMemberForm
              originalMember={memberQuery.data!}
              rules={formRulesQuery.data!}
              onClose={closeSidebar}
            />
          )}
        </SlideSideBar>,
        document.body,
      )}
      {children}
    </EditMemberSidebarProviderContext.Provider>
  );
};
