import {
  automationPlaceholderLabelsQueryFn,
  automationQueryKeys,
} from "@gymflow/api";
import { AutomationPlaceholderLabels, AutomationType } from "@gymflow/types";
import { InfiniteData, UseInfiniteQueryOptions } from "@tanstack/react-query";
import useGymflowModels from "apps/portal/src/store";
import { useCallback } from "react";
import { useTranslation } from "react-i18next";

import { OptionType } from "./components/OptionsList/components/OptionItem";
import { SelectInput, SelectInputOptionType } from "./SelectInput";

export type PlaceholderSelectInputOptionsType = OptionType<string>[];

export type PlaceholderSelectInputProps = {
  placeholderType: AutomationType;
  className?: string;
  onSelect: (placeholder: string) => void;
};

export const PlaceholderSelectInput = ({
  placeholderType,
  onSelect,
  className,
}: PlaceholderSelectInputProps) => {
  const { api } = useGymflowModels();
  const { t } = useTranslation();

  const dataFetchingQuery = useCallback(
    (): UseInfiniteQueryOptions<
      unknown,
      unknown,
      SelectInputOptionType<string>[]
    > => ({
      queryKey:
        automationQueryKeys.automationPlaceholderLabelsInfiniteQuery(
          placeholderType,
        ),
      queryFn: async () =>
        await automationPlaceholderLabelsQueryFn({ api, placeholderType }),
      select: (data) => {
        const { pageParams, pages } =
          data as InfiniteData<AutomationPlaceholderLabels>;

        const transformedPages = pages.map((placeholders) =>
          Object.values(placeholders).map((value) => ({
            id: value,
            label: value,
            value,
          })),
        );

        return {
          pageParams,
          pages: transformedPages,
        };
      },
      getNextPageParam: () => undefined,
    }),
    [api, placeholderType],
  );

  return (
    <div className={className}>
      <SelectInput
        placeholder={t("components.placeholderSelectInput.placeholder")}
        label={t("components.placeholderSelectInput.label")}
        variant="small"
        dataFetchingQuery={dataFetchingQuery}
        onChange={(selected) => {
          if (!selected) return;
          onSelect("${" + selected.value + "}");
        }}
        isClearable
      />
    </div>
  );
};
