import { RouteLayout } from "../routes/layout";
import { usePortalRoutes } from "./usePortalRoutes";

export function useIframeEmbed() {
  const { createAbsoluteClubLink } = usePortalRoutes();
  return (section: string) =>
    `<iframe src="${createAbsoluteClubLink(
      RouteLayout.Embed,
      section,
    )}" id="gymflow-iframe" frameBorder="0" style="width:100%; height:800px"></iframe>`;
}
