import {
  ActivityStatus,
  ApiListResponse,
  AppointableCategoryDTO,
  AppointableDTO,
  AppointableDtoStatus,
  PaginationOptions,
} from "@gymflow/types";

import { createSortParam } from "../createSortParam";
import { createPublicAxiosInstance } from "./publicAxiosInstance";

export type PublicAppointableApiType = ReturnType<typeof publicAppointableApi>;
const publicAppointableApi = (apiUrl: string, clubId: number) => {
  const clubPrefix = `club/${clubId}/`;
  const axiosInstance = createPublicAxiosInstance({
    apiUrl,
    paramsSerializer: {
      indexes: null,
    },
  });

  return {
    find({
      page = 0,
      limit = 10,
      extraParams = {},
      sort,
    }: {
      extraParams?: {
        id?: number[];
        status?: AppointableDtoStatus;
        "appointableCategory.id"?: number[];
        allowOnlineBookings?: boolean;
        allowAppBookings?: boolean;
      };
      page?: number;
      limit?: number;
      sort?: {
        field: string;
        desc?: boolean;
      };
    }) {
      const config = {
        params: {
          page,
          size: limit,
          ...extraParams,
        },
      };
      if (sort) {
        (config.params as any).sort = createSortParam(sort);
      }

      return axiosInstance.get<ApiListResponse<AppointableDTO>>(
        `${clubPrefix}hosted/appointable`,
        config,
      );
    },
    findById(id: number) {
      return axiosInstance.get<AppointableDTO>(
        `${clubPrefix}hosted/appointable/${id}`,
      );
    },
    fetchCategories(
      params: PaginationOptions & { statusList?: ActivityStatus[] },
    ) {
      return axiosInstance.put<ApiListResponse<AppointableCategoryDTO>>(
        `${clubPrefix}hosted/appointable/category/search`,
        params,
      );
    },
  };
};

export default publicAppointableApi;
