import { ApiType } from "@gymflow/common/lib/api/ApiType";
import { useMutation, useQueryClient } from "@tanstack/react-query";

import { memberAsMemberQueryKeys } from "./memberAsMemberQueryKeys";

export function useMutationMemberEditAsMemberNew({ api }: { api: ApiType }) {
  const queryClient = useQueryClient();
  return useMutation({
    mutationFn: async (params: {
      patchedFields?: Parameters<ApiType["profileApi"]["updateNew"]>[0];
      picture?: Parameters<ApiType["profileApi"]["updatePictureNew"]>[0];
    }) => {
      const promises = [] as Promise<any>[];
      if (params.patchedFields) {
        promises.push(api.profileApi.updateNew(params.patchedFields));
      }
      if (params.picture) {
        promises.push(api.profileApi.updatePictureNew(params.picture));
      }
      await Promise.all(promises);
    },
    onSuccess: () => {
      queryClient.invalidateQueries({
        queryKey: memberAsMemberQueryKeys.all(),
      });
    },
  });
}
