import { ApiType } from "@gymflow/common/lib/api/ApiType";
import { MembershipSummaryDTO } from "@gymflow/types";
import { useQuery, UseQueryOptions } from "@tanstack/react-query";

import { membershipQueryKeys } from "./membershipQueryKeys";

export function useQueryMembershipSummary(
  {
    api,
    opts,
    enabled,
  }: {
    api: ApiType;
    enabled?: boolean;
    opts?: Parameters<ApiType["serviceApi"]["summary"]>[0];
  },
  queryOptions?: UseQueryOptions<MembershipSummaryDTO>,
) {
  return useQuery({
    queryKey: membershipQueryKeys.summary(opts),
    queryFn: async () => {
      return (await api.serviceApi.summary(opts!)).data;
    },
    enabled: !!opts && enabled,
    ...queryOptions,
  });
}
