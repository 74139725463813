import {
  AppointmentBookingConsumeSessionsProps,
  AppointmentBookingCustomPaymentByCustomPaymentCategoryIdProps,
  AppointmentBookingPaymentByPaymentMethodIdProps,
  AppointmentCollectPaymentResponse,
  AppointmentDTO,
  AppointmentInvoiceDTO,
  AppointmentPaymentPostDTO,
  AppointmentPostDTO,
  AppointmentPurchaseSummaryDTO,
  AppointmentPurchaseSummaryPostDTO,
  RecurringAppointmentDTO,
  RecurringAppointmentPostDTO,
  RecurringAppointmentPutDTO,
} from "@gymflow/types";
import { AxiosInstance } from "axios";

export type AppointmentApiType = ReturnType<typeof appointmentApi>;

const appointmentApi = (axiosInstance: AxiosInstance, clubId: number) => {
  const clubPrefix = `club/${clubId}/`;

  return {
    findById(id: number) {
      return axiosInstance.get<AppointmentDTO>(
        `${clubPrefix}appointment/${id}`,
      );
    },
    create(newResource: AppointmentPostDTO) {
      return axiosInstance.post<AppointmentDTO>(
        `${clubPrefix}appointment`,
        newResource,
      );
    },
    update(id: number, patchedFields: Partial<AppointmentPostDTO>) {
      return axiosInstance.patch<AppointmentDTO>(
        `${clubPrefix}appointment/${id}`,
        patchedFields,
      );
    },
    find({
      page = 0,
      limit = 10,
      extraParams = {},
    }: {
      page?: number;
      limit?: number;
      extraParams?: any;
    }) {
      return axiosInstance.put(`${clubPrefix}appointment/search`, {
        page,
        size: limit,
        ...extraParams,
      });
    },
    collectPayment(params: AppointmentPaymentPostDTO) {
      return axiosInstance.post<AppointmentCollectPaymentResponse>(
        `${clubPrefix}appointment/collect-payment`,
        params,
      );
    },
    appointmentBookingPaymentByPaymentMethodId({
      appointmentId,
      paymentMethodId,
      promotionCode,
    }: AppointmentBookingPaymentByPaymentMethodIdProps) {
      return axiosInstance.put<AppointmentInvoiceDTO>(
        `${clubPrefix}appointment-booking/${appointmentId}/payment/${paymentMethodId}`,
        { promotionCode },
      );
    },
    appointmentBookingCustomPaymentByCustomPaymentCategoryId({
      appointmentId,
      customPaymentCategoryId,
      promotionCode,
    }: AppointmentBookingCustomPaymentByCustomPaymentCategoryIdProps) {
      return axiosInstance.put<AppointmentInvoiceDTO>(
        `${clubPrefix}appointment-booking/${appointmentId}/custom-payment/${customPaymentCategoryId}`,
        { promotionCode },
      );
    },
    appointmentBookingConsumeSessions({
      appointmentId,
      promotionCode,
    }: AppointmentBookingConsumeSessionsProps) {
      return axiosInstance.put(
        `${clubPrefix}appointment-booking/${appointmentId}/consume-sessions`,
        { promotionCode },
      );
    },
    refundPayment(clubId: number, appointmentId: number) {
      return axiosInstance.post<AppointmentDTO>(
        `${clubPrefix}appointment/refund-payment/${appointmentId}`,
      );
    },

    summary(params: AppointmentPurchaseSummaryPostDTO) {
      return axiosInstance.post<AppointmentPurchaseSummaryDTO>(
        `${clubPrefix}appointment/summary`,
        params,
      );
    },

    findRecurringById(recurringAppointmentId: number) {
      return axiosInstance.get<RecurringAppointmentDTO>(
        `${clubPrefix}recurring-appointment/${recurringAppointmentId}`,
      );
    },

    createRecurring(recurringAppointmentFields: RecurringAppointmentPostDTO) {
      return axiosInstance.post<RecurringAppointmentPostDTO>(
        `${clubPrefix}recurring-appointment`,
        recurringAppointmentFields,
      );
    },

    updateRecurring(
      recurringAppointmentId: number,
      patchedFields: RecurringAppointmentPutDTO,
    ) {
      return axiosInstance.put<RecurringAppointmentDTO>(
        `${clubPrefix}recurring-appointment/${recurringAppointmentId}`,
        patchedFields,
      );
    },

    cancelRecurring(recurringAppointmentId: number, fromAppointmentId: number) {
      return axiosInstance.delete(
        `${clubPrefix}recurring-appointment/${recurringAppointmentId}`,
        { params: { fromAppointmentId } },
      );
    },
  };
};

export default appointmentApi;
