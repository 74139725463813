export type ContractIconProps = {
  className?: string;
  pathClassName?: string;
};

const ContractIcon = ({ className, pathClassName }: ContractIconProps) => {
  return (
    <svg
      xmlns="http://www.w3.org/2000/svg"
      width="20"
      height="20"
      viewBox="0 0 20 20"
      fill="none"
    >
      <path
        d="M11.6668 9.16675H6.66683M8.3335 12.5001H6.66683M13.3335 5.83341H6.66683M16.6668 8.33342V5.66675C16.6668 4.26662 16.6668 3.56655 16.3943 3.03177C16.1547 2.56137 15.7722 2.17892 15.3018 1.93923C14.767 1.66675 14.067 1.66675 12.6668 1.66675H7.3335C5.93336 1.66675 5.2333 1.66675 4.69852 1.93923C4.22811 2.17892 3.84566 2.56137 3.60598 3.03177C3.3335 3.56655 3.3335 4.26662 3.3335 5.66675V14.3334C3.3335 15.7335 3.3335 16.4336 3.60598 16.9684C3.84566 17.4388 4.22811 17.8212 4.69852 18.0609C5.2333 18.3334 5.93336 18.3334 7.3335 18.3334H10.4168M15.0002 17.5001C15.0002 17.5001 17.5002 16.3085 17.5002 14.5211V12.4358L15.6772 11.7844C15.2392 11.6275 14.7602 11.6275 14.3222 11.7844L12.5002 12.4358V14.5211C12.5002 16.3085 15.0002 17.5001 15.0002 17.5001Z"
        stroke="#667085"
        strokeWidth="1.5"
        strokeLinecap="round"
        strokeLinejoin="round"
      />
    </svg>
  );
};

export default ContractIcon;
