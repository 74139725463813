import { cn } from "@gymflow/helpers";

import { CheckIcon } from "../../../../icons";
import { SelectInputOptionType } from "../../../SelectInput";
import { OptionImage } from "../../OptionImage";

export type OptionType<T> = {
  id: string | number;
  label: string;
  subLabel?: string;
  value: T;
  imageUrl?: string;
};

type OptionItemProps<T> = {
  option: OptionType<T>;
  isSelected: boolean;
  onSelect: (option: SelectInputOptionType<T>) => void;
  withImageByDefault: boolean;
};

export const OptionItem = <T,>({
  option,
  isSelected,
  onSelect,
  withImageByDefault,
}: OptionItemProps<T>) => (
  <div
    className={cn(
      "flex min-h-11 cursor-pointer items-center justify-between rounded-[0.625rem] p-1.5 hover:bg-gray-50 dark:hover:bg-gray-900",
      {
        "bg-gray-50 dark:bg-gray-900": isSelected,
      },
    )}
    onClick={() => onSelect(option)}
  >
    <div className="flex items-center gap-2">
      <OptionImage
        imageUrl={option.imageUrl}
        label={option.label}
        withImageByDefault={withImageByDefault}
      />
      <div className="flex flex-col">
        <div className="text-sm font-medium text-gray-950 dark:text-gray-0">
          {option.label}
        </div>
        {option.subLabel && (
          <div className="text-sm text-gray-500">{option.subLabel}</div>
        )}
      </div>
    </div>
    {isSelected && <CheckIcon />}
  </div>
);
