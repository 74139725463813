import { RevenueReportItemDTO } from "@gymflow/types";
import { CellContext } from "@tanstack/react-table";
import PMCustomAllIcon from "apps/portal/src/components/atoms/icons/PMCustomAllIcon";
import PMCustomCashIcon from "apps/portal/src/components/atoms/icons/PMCustomCashIcon";
import { PMLinkIcon } from "apps/portal/src/components/atoms/icons/PMLinkIcon";
import PMUnknownCardBrandIcon from "apps/portal/src/components/atoms/icons/PMUnknownCardBrandIcon";
import { useMemo } from "react";
import { useTranslation } from "react-i18next";

import { CARD_BRAND_ICONS, WALLET_ICONS } from "./constants";

export type PaymentMethodColumnProps = {
  row: CellContext<RevenueReportItemDTO, RevenueReportItemDTO>;
};

export const PaymentMethodColumn = ({ row }: PaymentMethodColumnProps) => {
  const { t } = useTranslation();
  const {
    paymentType,
    linkEmail,
    customPaymentCategoryName,
    walletType,
    last4Digits,
    cardBrand,
  } = row.getValue();

  const { icon, text } = useMemo(() => {
    switch (paymentType) {
      case "CARD":
        const paymentText = last4Digits
          ? t("page.reports.revenueTotal.table.paymentMethodColumn.endingIn", {
              last4Digits,
            })
          : t("page.reports.revenueTotal.table.paymentMethodColumn.byCard");

        if (walletType) {
          return {
            icon: WALLET_ICONS[walletType] ?? <PMUnknownCardBrandIcon />,
            text: paymentText,
          };
        }
        return {
          icon: CARD_BRAND_ICONS[cardBrand ?? "unknown"],
          text: paymentText,
        };
      case "CUSTOM":
        return {
          icon:
            customPaymentCategoryName === "Cash" ? (
              <PMCustomCashIcon />
            ) : (
              <PMCustomAllIcon />
            ),
          text: customPaymentCategoryName,
        };
      case "LINK":
        return { icon: <PMLinkIcon />, text: linkEmail };
      default:
        return { icon: <PMCustomAllIcon />, text: "-" };
    }
  }, [
    cardBrand,
    customPaymentCategoryName,
    last4Digits,
    linkEmail,
    paymentType,
    t,
    walletType,
  ]);

  return (
    <div className="flex items-center gap-x-2 text-sm font-semibold text-gray-950">
      {icon}
      {text}
    </div>
  );
};
