import { QueryKey } from "@tanstack/react-query";

export const appointmentAsMemberQueryKeys = {
  all: () => ["appointmentAsMember"] as QueryKey,
  summary: (parameters: Readonly<{ [k: string]: any }>) =>
    [
      ...appointmentAsMemberQueryKeys.all(),
      "summary",
      { parameters },
    ] as QueryKey,
  summaryNew: (parameters: Readonly<{ [k: string]: any }>) =>
    [
      ...appointmentAsMemberQueryKeys.all(),
      "summaryNew",
      { parameters },
    ] as QueryKey,
};
