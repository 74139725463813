import { cn, formatCurrency } from "@gymflow/helpers";
import { AppointableDTO } from "@gymflow/types";
import React, { ReactNode, useState } from "react";
import { useTranslation } from "react-i18next";
import { Tooltip } from "react-tooltip";

import { useClubSettings } from "../../../providers";
import { CreditsIcon } from "../../atoms";

export type HostedPagesCardProps = {
  children: React.ReactNode;
};

export const HostedPagesCard = {
  Container: ({ children }: HostedPagesCardProps) => {
    return (
      <div
        className={cn("flex flex-col gap-3 rounded-2xl border p-4 shadow-sm", [
          "border-gray-300 bg-gray-0",
          "dark:border-gray-700 dark:bg-gray-950",
        ])}
      >
        {children}
      </div>
    );
  },
  Separator: () => (
    <div
      className={cn("mt-auto border-b", [
        "border-gray-300 bg-gray-0",
        "dark:border-gray-700 dark:bg-gray-950",
      ])}
    />
  ),
  Header: ({ children }: HostedPagesCardProps) => {
    return <div className="flex flex-col gap-2">{children}</div>;
  },
  Footer: ({ children }: HostedPagesCardProps) => {
    return (
      <div className="flex flex-row flex-wrap items-center justify-between gap-2">
        {children}
      </div>
    );
  },
  Price: ({
    price,
    description,
  }: {
    price: ReactNode;
    description: ReactNode;
  }) => {
    return (
      <div className="flex flex-1 flex-col flex-wrap">
        <div className="dark:text-gray-0 text-lg font-bold text-gray-950">
          {price}
        </div>
        <div className="flex flex-row items-center gap-1 whitespace-nowrap text-sm text-gray-500">
          {description}
        </div>
      </div>
    );
  },
  AppointablePrice: ({ appointable }: { appointable: AppointableDTO }) => {
    const { t } = useTranslation();
    const settings = useClubSettings();
    return (
      <div className="flex flex-row items-center gap-2">
        {appointable.pricingModel === "FREE" && (
          <div className="dark:text-gray-0 text-lg font-bold text-gray-950">
            {t("common.free")}
          </div>
        )}
        {appointable.pricingModel !== "FREE" && (
          <>
            {appointable.price !== undefined && (
              <div className="dark:text-gray-0 text-lg font-bold text-gray-950">
                {formatCurrency(appointable.price, settings.defaultCurrency)}
              </div>
            )}
            {appointable.price !== undefined &&
              appointable.sessionCost !== undefined && (
                <div className="text-gray-500">
                  {t("pages.hostedPagesAppointables.or")}
                </div>
              )}
            {appointable.sessionCost !== undefined && (
              <div className="dark:text-gray-0 flex flex-row items-center gap-1 text-lg font-bold text-gray-950">
                {appointable.sessionCost}
                <CreditsIcon pathClassName="stroke-secondary-500" />
              </div>
            )}
          </>
        )}
      </div>
    );
  },
  Description: ({
    description,
    hideTooltip,
  }: {
    description: string;
    hideTooltip?: boolean;
  }) => {
    const [id] = useState(Math.random().toString(36).substring(2, 15));
    return (
      <div
        className="line-clamp-2 cursor-default text-ellipsis text-sm text-gray-500"
        data-tooltip-id={`tooltip-${id}`}
        data-tooltip-content={description}
      >
        {description}
        {!hideTooltip && (
          <Tooltip
            className="!text-gray-0 !max-w-[min(24rem,calc(100vw-2rem))] !rounded-xl !bg-black !px-3 !py-2 !text-center !text-sm !font-semibold !opacity-100"
            id={`tooltip-${id}`}
          />
        )}
      </div>
    );
  },
};
