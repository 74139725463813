import { faAngleDown, faAngleUp } from "@fortawesome/free-solid-svg-icons";
import { FontAwesomeIcon } from "@fortawesome/react-fontawesome";
import classNames from "classnames";
import ReactSelect from "react-select";

export function Select({ value, onChange, styles, className, ...props }: any) {
  return (
    <ReactSelect
      components={{
        DropdownIndicator: ({ selectProps: { menuIsOpen } }: any) => {
          if (menuIsOpen) {
            return (
              <FontAwesomeIcon
                icon={faAngleUp}
                className="ml-2 mr-4 text-gray-500"
              />
            );
          }
          return (
            <FontAwesomeIcon
              icon={faAngleDown}
              className="ml-2 mr-4 text-gray-500"
            />
          );
        },
        IndicatorSeparator: () => {
          return null;
        },
        ValueContainer: ({ children }: any) => {
          return (
            <div className="ml-4 flex max-w-[70%] overflow-x-hidden font-semibold [&>div]:!text-gray-500">
              {children}
            </div>
          );
        },
      }}
      isSearchable={false}
      classNames={{
        control: () =>
          "!border-none bg-transparent !outline-0 !shadow-none !flex !flex-row justify-between w-full !flex-nowrap",
        menu: () => "!z-[9999] !rounded-lg",
        option: () =>
          classNames(
            `!cursor-pointer hover:!bg-primary-50 !bg-gray-0 !text-gray-800`,
          ),
        valueContainer: () =>
          "border-none rounded-xl shadow-none h-[calc(2.75rem-2px)] cursor-pointer",
      }}
      className={classNames(
        "flex h-11 min-w-fit cursor-pointer items-center rounded-lg border border-gray-300 text-center shadow-sm hover:bg-gray-100",
        className,
      )}
      value={value}
      onChange={onChange}
      styles={styles}
      {...props}
    />
  );
}
