import { ApiType } from "@gymflow/common/lib/api/ApiType";
import { useQuery } from "@tanstack/react-query";

import { ruleAsPublicQueryKeys } from "./ruleAsPublicQueryKeys";

export function useQueryUserFormRulesAsPublic({ api }: { api: ApiType }) {
  return useQuery({
    queryKey: ruleAsPublicQueryKeys.all(),
    queryFn: async () => {
      return (await api.public.ruleApi.getAllRules()).data;
    },
  });
}
