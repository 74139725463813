import { useQueryHostAvailabilityOverrideList } from "@gymflow/api";
import { TIME_FORMAT as TIME_FORMAT_MERIDIUM } from "@gymflow/common";
import { AvailabilityOverrideDTO } from "@gymflow/types";
import { TIME_FORMAT } from "@gymflow/validation";
import classNames from "classnames";
import areIntervalsOverlapping from "date-fns/areIntervalsOverlapping";
import moment from "moment-timezone";
import { useMemo, useState } from "react";

import { ModalWrapper } from "../../../providers";
import useGymflowModels from "../../../store";
import { Button, DateSelect, Switch, TimeSelect } from "../../atoms";

interface StaffAvailabilityOverrideFormModalProps {
  onCancel: () => void;
  onConfirm: (newValues: Omit<AvailabilityOverrideDTO, "id">) => Promise<void>;
  value: Omit<AvailabilityOverrideDTO, "id"> | null;
  staffId: string;
  overrideId?: number;
}

const dateFormat = "YYYY-MM-DD";
export function StaffAvailabilityOverrideFormModal({
  onCancel,
  onConfirm,
  value,
  staffId,
  overrideId,
}: StaffAvailabilityOverrideFormModalProps) {
  const defaultValue: Omit<AvailabilityOverrideDTO, "id"> = {
    startTime: "09:00:00",
    endTime: "17:00:00",
    isUnavailableAllDay: false,
    overrideDate: moment().format(dateFormat),
  };
  const [editing, setEditing] = useState<Omit<AvailabilityOverrideDTO, "id">>(
    value || defaultValue,
  );
  const error = useMemo(() => {
    if (
      !editing.isUnavailableAllDay &&
      moment(editing.startTime, TIME_FORMAT).isAfter(
        moment(editing.endTime, TIME_FORMAT),
      )
    ) {
      return "Invalid End time: Must be after Start time.";
    }
    return null;
  }, [editing.startTime, editing.endTime, editing.isUnavailableAllDay]);

  const { api } = useGymflowModels();
  const { data: existingOverrides } = useQueryHostAvailabilityOverrideList({
    api,
    staffId,
    opts: {
      overrideDate: editing.overrideDate,
    },
  });

  const isOverlapping = existingOverrides?.content.reduce((prev, current) => {
    if (current.id === overrideId) {
      return prev;
    }
    if (current.isUnavailableAllDay || editing.isUnavailableAllDay) {
      return true;
    }

    const currentStart = moment(current.startTime, TIME_FORMAT);
    const currentEnd = moment(current.endTime, TIME_FORMAT);
    const editingStart = moment(editing.startTime, TIME_FORMAT);
    const editingEnd = moment(editing.endTime, TIME_FORMAT);
    const doesCurrentOverlap = areIntervalsOverlapping(
      { start: currentStart.toDate(), end: currentEnd.toDate() },
      { start: editingStart.toDate(), end: editingEnd.toDate() },
    );
    if (doesCurrentOverlap) {
      return true;
    }

    return prev;
  }, false);

  return (
    <ModalWrapper onCancel={onCancel}>
      <div className="text-lg font-semibold text-gray-900">Block Time</div>
      <div className="mt-2 text-sm text-gray-600">
        Select date and time you will be unavailable.
      </div>
      <div>
        <div className="my-2 text-sm font-medium text-gray-700">Date</div>
        <div className="my-2">
          <DateSelect
            value={editing.overrideDate}
            onChange={(newDate) => {
              setEditing({ ...editing, overrideDate: newDate });
            }}
            dateFormat={dateFormat}
            dateToStartFrom={value?.overrideDate || defaultValue.overrideDate}
          />
        </div>
        <div>
          <div className="my-3 flex justify-between">
            <div className="text-sm font-medium text-gray-700">
              Unavailable all day
            </div>
            <div>
              <Switch
                value={editing.isUnavailableAllDay}
                onChange={(checked) => {
                  if (checked) {
                    setEditing({ ...editing, isUnavailableAllDay: checked });
                  } else {
                    setEditing({
                      ...editing,
                      isUnavailableAllDay: checked,
                      startTime: defaultValue.startTime,
                      endTime: defaultValue.endTime,
                    });
                  }
                }}
              />
            </div>
          </div>
        </div>
        <div className={classNames({ hidden: editing.isUnavailableAllDay })}>
          <div className="my-2 text-sm font-medium text-gray-700">
            Start Time
          </div>
          <div className="my-2 h-[44px]">
            <TimeSelect
              value={moment(editing.startTime, TIME_FORMAT).format(
                TIME_FORMAT_MERIDIUM,
              )}
              onChange={(newStartTime) => {
                setEditing({
                  ...editing,
                  startTime: moment(newStartTime, TIME_FORMAT_MERIDIUM).format(
                    TIME_FORMAT,
                  ),
                });
              }}
            />
          </div>
        </div>

        <div className={classNames({ hidden: editing.isUnavailableAllDay })}>
          <div className="my-2 text-sm font-medium text-gray-700">End Time</div>
          <div className="my-2 h-[44px]">
            <TimeSelect
              value={moment(editing.endTime, TIME_FORMAT).format(
                TIME_FORMAT_MERIDIUM,
              )}
              onChange={(newEndTime) => {
                setEditing({
                  ...editing,
                  endTime: moment(newEndTime, TIME_FORMAT_MERIDIUM).format(
                    TIME_FORMAT,
                  ),
                });
              }}
            />
          </div>
        </div>
        {isOverlapping && (
          <div className="text-error-700 my-2">
            This blocked time overlaps with an existing one.
          </div>
        )}
        {error && <div className="text-error-700 my-2">{error}</div>}
      </div>
      <div className="mt-5 flex">
        <Button
          onClick={onCancel}
          className={classNames("mt-2 flex-1", {
            hidden: !onCancel,
          })}
        >
          Cancel
        </Button>

        <Button
          intent="primary"
          className="ml-4 mt-2 flex-1"
          disabled={isOverlapping}
          onClick={async () => {
            if (error) {
              return;
            }
            await onConfirm(editing);
          }}
        >
          Save
        </Button>
      </div>
    </ModalWrapper>
  );
}
