import { ApiType } from "@gymflow/common/lib/api/ApiType";
import {
  AppointmentBookingPaymentByPaymentMethodIdProps,
  AppointmentInvoiceDTO,
} from "@gymflow/types";
import { useMutation, UseMutationOptions } from "@tanstack/react-query";

export const useMutationAppointmentBookingPaymentByPaymentMethodId = (
  {
    api,
  }: {
    api: ApiType;
  },
  mutationOpts?: UseMutationOptions<
    AppointmentInvoiceDTO,
    unknown,
    AppointmentBookingPaymentByPaymentMethodIdProps
  >,
) =>
  useMutation({
    mutationFn: async (
      params: AppointmentBookingPaymentByPaymentMethodIdProps,
    ) =>
      (
        await api.appointmentApi.appointmentBookingPaymentByPaymentMethodId(
          params,
        )
      ).data,
    ...mutationOpts,
  });
