import {
  useClubFeatureFlags,
  useMutationMembershipSignupCustomPayment,
  useMutationMembershipSignupPaymentMethod,
  useMutationProductOrderCheckoutCustomPayment,
  useMutationProductOrderCheckoutPaymentMethod,
  useMutationSessionPackSignupCustomPayment,
  useMutationSessionPackSignupPaymentMethod,
} from "@gymflow/api";
import { useMutationMemberSale } from "@gymflow/api/lib/hooks/member/useMutationMemberSale";
import { CustomPaymentCategoryDTO, InvoiceDTO } from "@gymflow/types";
import { useClubSettings } from "apps/portal/src/providers";
import useGymflowModels from "apps/portal/src/store";
import { useCallback } from "react";

import { SelectInputOptionType } from "../../../atoms";
import { ShopCartType } from "../../ShopTypes";
import { TabsType } from "../ShopSidebarProvider";

type ProcessCheckoutProps = {
  cart: ShopCartType;
  activeTabId: TabsType;
  orderKey: string;
  values: {
    priceOverride?: number;
    inPersonPaymentMethod?: SelectInputOptionType<CustomPaymentCategoryDTO>;
    paymentMethodId?: string;
  };
  startDate: string;
  userMemberId: string;
  promotionCode?: string;
  paymentMethodId?: string;
  priceOverride?: number;
};

type UseProcessCheckoutProps = {
  selectedUserId?: string | number;
};

export const useProcessCheckout = ({
  selectedUserId,
}: UseProcessCheckoutProps) => {
  const { clubId, timezone, customPaymentsEnabled } = useClubSettings();
  const { api } = useGymflowModels();
  const { data: featureFlags } = useClubFeatureFlags({
    clubId,
    api,
  });

  const { mutateAsync: checkout } = useMutationMemberSale({
    api,
    memberId: typeof selectedUserId === "string" ? selectedUserId : "",
    clubId,
    tz: timezone,
  });

  const { mutateAsync: membershipSignupCustomPaymentMutateAsync } =
    useMutationMembershipSignupCustomPayment({ api });
  const { mutateAsync: membershipSignupPaymentMethodMutateAsync } =
    useMutationMembershipSignupPaymentMethod({ api });
  const { mutateAsync: productOrderCheckoutCustomPaymentMutateAsync } =
    useMutationProductOrderCheckoutCustomPayment({ api });
  const { mutateAsync: productOrderCheckoutPaymentMethodMutateAsync } =
    useMutationProductOrderCheckoutPaymentMethod({ api });
  const { mutateAsync: sessionPackSignupCustomPaymentMutateAsync } =
    useMutationSessionPackSignupCustomPayment({ api });
  const { mutateAsync: sessionPackSignupPaymentMethodMutateAsync } =
    useMutationSessionPackSignupPaymentMethod({ api });

  const processCheckout = useCallback(
    async ({
      cart,
      activeTabId,
      orderKey,
      values,
      startDate,
      userMemberId,
      promotionCode,
      paymentMethodId,
      priceOverride,
    }: ProcessCheckoutProps) => {
      // Change it after refactoring to invoiceNumber only
      // invoice: InvoiceDTO - for old requests; invoiceNumber: string - for new requests
      let result: { invoice?: InvoiceDTO; invoiceNumber?: string } = {};

      if (cart.type === "MEMBERSHIP") {
        if (customPaymentsEnabled) {
          if (activeTabId === "IN_PERSON") {
            // new request (custom payment)
            result = await membershipSignupCustomPaymentMutateAsync({
              membershipId: cart.payload.id,
              startDate,
              orderKey,
              customPaymentCategoryId:
                (values?.inPersonPaymentMethod?.id as number) || 0,
              priceOverride,
              promotionCode,
              userMemberId,
            });
          } else if (activeTabId === "ONLINE") {
            // new request (online payment)
            result = await membershipSignupPaymentMethodMutateAsync({
              membershipId: cart.payload.id,
              startDate,
              orderKey,
              paymentMethodId,
              priceOverride,
              promotionCode,
              userMemberId,
            });
          }
        } else {
          if (
            featureFlags?.featureFlags.PORTAL_NEW_MEMBERSHIP_CHECKOUT_ENDPOINT
          ) {
            // new request (online payment)
            result = await membershipSignupPaymentMethodMutateAsync({
              membershipId: cart.payload.id,
              startDate,
              orderKey,
              paymentMethodId,
              priceOverride,
              promotionCode,
              userMemberId,
            });
          } else {
            // old request (online payment)
            result = await checkout({
              paymentMethod: paymentMethodId,
              promotionCode: promotionCode,
              membershipId: cart.payload.id,
              startDate,
              priceOverride,
            });
          }
        }
      } else if (cart.type === "CREDIT_PACK") {
        if (customPaymentsEnabled) {
          if (activeTabId === "IN_PERSON") {
            // new request (custom payment)
            result = await sessionPackSignupCustomPaymentMutateAsync({
              sessionPackId: cart.payload.id,
              startDate,
              orderKey,
              customPaymentCategoryId:
                (values?.inPersonPaymentMethod?.id as number) || 0,
              priceOverride,
              promotionCode,
              userMemberId,
            });
          } else if (activeTabId === "ONLINE") {
            // new request (online payment)
            result = await sessionPackSignupPaymentMethodMutateAsync({
              sessionPackId: cart.payload.id,
              startDate,
              orderKey,
              paymentMethodId,
              priceOverride,
              promotionCode,
              userMemberId,
            });
          }
        } else {
          if (
            featureFlags?.featureFlags.PORTAL_NEW_CREDIT_PACK_CHECKOUT_ENDPOINT
          ) {
            // new request (online payment)
            result = await sessionPackSignupPaymentMethodMutateAsync({
              sessionPackId: cart.payload.id,
              startDate,
              orderKey,
              paymentMethodId,
              priceOverride,
              promotionCode,
              userMemberId,
            });
          } else {
            // old request (online payment)
            result = await checkout({
              paymentMethod: paymentMethodId,
              promotionCode: promotionCode,
              sessionPackId: cart.payload.id,
              startDate,
              priceOverride,
            });
          }
        }
      } else {
        const products = cart.payload.map((e) => ({
          productId: e.product.id,
          quantity: e.quantity,
        }));
        if (customPaymentsEnabled) {
          if (activeTabId === "IN_PERSON") {
            // new request (custom payment)
            result = await productOrderCheckoutCustomPaymentMutateAsync({
              orderItems: products,
              orderKey,
              customPaymentCategoryId:
                (values?.inPersonPaymentMethod?.id as number) || 0,
              priceOverride,
              promotionCode,
              userMemberId,
            });
          } else if (activeTabId === "ONLINE") {
            // new request (online payment)
            result = await productOrderCheckoutPaymentMethodMutateAsync({
              orderItems: products,
              orderKey,
              paymentMethodId,
              priceOverride,
              promotionCode,
              userMemberId,
            });
          }
        } else {
          if (featureFlags?.featureFlags.PORTAL_NEW_PRODUCT_CHECKOUT_ENDPOINT) {
            // new request (online payment)
            result = await productOrderCheckoutPaymentMethodMutateAsync({
              orderItems: products,
              orderKey,
              paymentMethodId,
              priceOverride,
              promotionCode,
              userMemberId,
            });
          } else {
            // old request (online payment)
            result = await checkout({
              paymentMethod: paymentMethodId,
              promotionCode: promotionCode,
              products,
              priceOverride,
            });
          }
        }
      }
      return result;
    },
    [
      checkout,
      customPaymentsEnabled,
      featureFlags?.featureFlags.PORTAL_NEW_CREDIT_PACK_CHECKOUT_ENDPOINT,
      featureFlags?.featureFlags.PORTAL_NEW_MEMBERSHIP_CHECKOUT_ENDPOINT,
      featureFlags?.featureFlags.PORTAL_NEW_PRODUCT_CHECKOUT_ENDPOINT,
      membershipSignupCustomPaymentMutateAsync,
      membershipSignupPaymentMethodMutateAsync,
      productOrderCheckoutCustomPaymentMutateAsync,
      productOrderCheckoutPaymentMethodMutateAsync,
      sessionPackSignupCustomPaymentMutateAsync,
      sessionPackSignupPaymentMethodMutateAsync,
    ],
  );

  return { processCheckout };
};
