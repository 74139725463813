import { cn } from "@gymflow/helpers";

export function HostedPagesFooter({ className }: { className?: string }) {
  return (
    <div
      className={cn(
        "dark:bg-gray-950 w-full dark:border-gray-700 dark:text-gray-600 flex h-14 items-center justify-center border-t border-gray-300 px-4 text-sm text-gray-600 bg-gray-0",
        className,
      )}
    >
      Powered by&nbsp;
      <a
        className="text-primary-600 hover:!text-primary-600 underline"
        href="https://gymflow.io"
        target="_blank"
        rel="noreferrer"
      >
        Gymflow.io
      </a>
    </div>
  );
}
