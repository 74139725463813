import { cn } from "@gymflow/helpers";
import { Switch as HeadlessSwitch } from "@headlessui/react";
import { LabeledForm } from "./LabeledForm";

export interface SwitchProps {
  value: boolean;
  onChange: (checked: boolean) => void;
  disabled?: boolean;
  className?: string;
}

export type LabeledSwitchProps = {
  label?: string;
  labelClassName?: string;
  labelStyle?: "horizontal" | "vertical";
  tooltip?: string;
} & SwitchProps;

export function LabeledSwitch({
  label,
  value,
  onChange,
  disabled,
  className,
  labelClassName,
  labelStyle = "horizontal",
  tooltip,
}: LabeledSwitchProps) {
  if (labelStyle === "vertical" && label) {
    return (
      <LabeledForm tooltip={tooltip} label={label}>
        <Switch
          value={value}
          onChange={onChange}
          disabled={disabled}
          className={className}
        />
      </LabeledForm>
    );
  }
  return (
    <div className="flex flex-row items-center gap-x-2">
      <Switch
        value={value}
        onChange={onChange}
        disabled={disabled}
        className={className}
      />
      {labelStyle === "horizontal" && label && (
        <div className={labelClassName}>{label}</div>
      )}
    </div>
  );
}

export function Switch({ value, onChange, disabled, className }: SwitchProps) {
  return (
    <HeadlessSwitch
      checked={value}
      onChange={onChange}
      className={cn(
        value ? "bg-secondary-500" : "bg-gray-200",
        "relative inline-flex items-center pl-px h-6 w-11 flex-shrink-0 cursor-pointer rounded-full transition-colors duration-200 ease-in-out focus:outline-none ",
        className,
      )}
      disabled={disabled}
    >
      <span
        aria-hidden="true"
        className={cn(
          value ? "translate-x-5" : "translate-x-0",
          "pointer-events-none inline-block h-5 w-5 transform rounded-full bg-gray-0 shadow ring-0 transition duration-200 ease-in-out",
        )}
      />
    </HeadlessSwitch>
  );
}
