import { cn } from "@gymflow/helpers";
import { cva, VariantProps } from "class-variance-authority";
import React, { ReactNode } from "react";
import { toast } from "sonner";

import { Button, CheckCircleIcon, CloseIcon } from "../../components/atoms";
import CheckmarkIcon from "../../components/atoms/icons/CheckmarkIcon";
import { ExclamationCircleIcon } from "../../components/atoms/icons/ExclamationCircleIcon";

const smallToastVariants = cva(
  "text-gray-0 flex flex-row justify-center gap-2 rounded-xl px-5 py-2 text-sm shadow-sm shadow-gray-400",
  {
    variants: {
      intent: {
        error: "bg-error-600",
        warning: "bg-warning-600",
        success: "bg-success-600",
        info: "bg-gray-0",
      },
    },
    defaultVariants: {
      intent: "success",
    },
  },
);

const normalToastVariants = cva(
  "bg-gray-0 flex flex-row items-start gap-4 rounded-2xl border border-gray-300 p-4 shadow-sm shadow-gray-400 dark:border-gray-700 dark:bg-gray-800",
  {
    variants: {
      intent: {
        error: "",
        warning: "",
        success: "",
        info: "",
      },
    },
    defaultVariants: {
      intent: "success",
    },
  },
);

const toastInnerCircle = cva(
  "flex h-7 w-7 items-center justify-center rounded-full",
  {
    variants: {
      intent: {
        error: "bg-error-100",
        warning: "bg-warning-100",
        success: "bg-success-100",
        info: "hidden",
      },
    },
    defaultVariants: {
      intent: "success",
    },
  },
);
const toastCircle = cva(
  "flex h-9 w-9 items-center justify-center rounded-full",
  {
    variants: {
      intent: {
        error: "bg-error-50",
        warning: "bg-warning-50",
        success: "bg-success-50",
        info: "hidden",
      },
    },
    defaultVariants: {
      intent: "success",
    },
  },
);

export type ToastProps = VariantProps<typeof normalToastVariants> & {
  type?: "normal" | "small";
  toastId: Parameters<Parameters<typeof toast.custom>[0]>[0];
  message: string;
  description?: string;
  actions?: (closeToast: () => void) => ReactNode;
};
export const Toast: React.FC<ToastProps> = (toastProps: ToastProps) => {
  const type = toastProps.type ?? "normal";
  if (type === "normal") {
    return (
      <div className="flex w-96 items-center justify-center">
        <div
          className={cn(
            normalToastVariants({ intent: toastProps.intent }),

            {
              "items-center": !toastProps.description,
            },
          )}
        >
          <div className={toastCircle({ intent: toastProps.intent })}>
            <div className={toastInnerCircle({ intent: toastProps.intent })}>
              {
                (
                  {
                    error: (
                      <ExclamationCircleIcon pathClassName="stroke-error-600" />
                    ),
                    warning: (
                      <ExclamationCircleIcon pathClassName="stroke-warning-600" />
                    ),
                    success: (
                      <CheckCircleIcon pathClassName="stroke-success-600" />
                    ),
                    info: undefined,
                  } as const
                )[toastProps.intent ?? "success"]
              }
            </div>
          </div>
          <div className="flex-1 flex-col items-start" style={{ rowGap: 8 }}>
            <div>
              <div className="dark:text-gray-0 text-sm font-semibold text-gray-950">
                {toastProps.message}
              </div>
              {toastProps.description && (
                <div className="whitespace-pre-line text-sm text-gray-500">
                  {toastProps.description}
                </div>
              )}
            </div>
            {toastProps.actions?.(() => toast.dismiss(toastProps.toastId))}
          </div>
          <Button
            intent="transparent"
            className="flex h-fit min-h-0 w-fit min-w-0 items-center justify-center p-1"
            onClick={() => {
              toast.dismiss(toastProps.toastId);
            }}
          >
            <CloseIcon />
          </Button>
        </div>
      </div>
    );
  } else {
    return (
      <div className="flex w-96 items-center justify-center">
        <div className={cn(smallToastVariants({ intent: toastProps.intent }))}>
          {
            (
              {
                error: <ExclamationCircleIcon pathClassName="stroke-gray-0" />,
                warning: (
                  <ExclamationCircleIcon pathClassName="stroke-gray-0" />
                ),
                success: (
                  <CheckmarkIcon
                    pathClassName="stroke-gray-0"
                    className="h-5 w-5"
                  />
                ),
                info: undefined,
              } as const
            )[toastProps.intent ?? "success"]
          }
          {toastProps.message}
        </div>
      </div>
    );
  }
};
