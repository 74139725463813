export function getPatch<T>(original: T, updated: Partial<T>) {
  const patch: Partial<T> = {};
  Object.keys(updated).forEach((key) => {
    const typedKey = key as keyof T;
    if (
      JSON.stringify(original[typedKey]) !== JSON.stringify(updated[typedKey])
    ) {
      (patch[typedKey] as any) = updated[typedKey] ?? "";
    }
  });
  return patch;
}
