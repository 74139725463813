import { cn } from "@gymflow/helpers";
import { MouseEvent, ReactNode, useEffect, useState } from "react";

import { Button } from "./Button";
import { ReactiveButton } from "./ReactiveButton";

export function TextInputWithButton({
  className,
  onTextChange,
  value,
  buttonText,
  onButtonClick,
  reactiveContent,
}: {
  className?: string;
  onTextChange?: (newValue: string) => void;
  onButtonClick: (
    e: MouseEvent<HTMLButtonElement, MouseEvent>,
  ) => Promise<void> | void;
  buttonText: ReactNode;
  value: string;
  reactiveContent?: ReactNode;
}) {
  const [text, setText] = useState(value);
  useEffect(() => {
    setText(value);
  }, [value]);
  return (
    <div className={cn("flex rounded-md shadow-sm", className)}>
      <div className="relative flex grow items-stretch focus-within:z-10">
        <input
          type="text"
          disabled={!!value}
          className="-mr-px block h-11 w-full flex-1 rounded-none rounded-l-lg border-0 px-3.5 py-2.5 text-gray-900 ring-1 ring-inset ring-gray-300 placeholder:text-gray-400 focus:ring-1 focus:ring-inset sm:text-sm sm:leading-6"
          value={text}
          onChange={({ currentTarget: { value: newValue } }) => {
            if (onTextChange) {
              onTextChange(newValue);
            }
          }}
        />
      </div>
      {reactiveContent ? (
        <ReactiveButton
          className="w-32 !rounded-l-none ring-inset"
          onClick={onButtonClick}
          reactiveContent={reactiveContent}
        >
          {buttonText}
        </ReactiveButton>
      ) : (
        <Button className="!rounded-l-none ring-inset" onClick={onButtonClick}>
          {buttonText}
        </Button>
      )}
    </div>
  );
}
