import { rulesHelper } from "@gymflow/helpers";
import {
  RuleClubDTO,
  RuleGroup,
  RuleGroupItemDTO,
  WindowRule,
  WindowType,
} from "@gymflow/types";
import { intervalToDuration, isBefore } from "date-fns";
import { DateTime, Duration, Interval } from "luxon";

const windowValueToTimeUnitMap: { [key in WindowType]: keyof Duration } = {
  DAYS: "days",
  MONTHS: "months",
  HOURS: "hours",
  MINUTES: "minutes",
};
const durationFromWindow = (rule: WindowRule) => {
  return Duration.fromObject({
    [windowValueToTimeUnitMap[rule.windowType]]: rule.windowValue,
  });
};

export const evaluateBookingWindow = (
  isoStartDate: string,
  rules: RuleGroup,
) => {
  const { advancedBookingsRule, bookingNoticeRule } =
    rulesHelper.mapRuleGroupToClassRules(rules);

  const advancedDuration =
    advancedBookingsRule!.windowValue !== ""
      ? durationFromWindow(advancedBookingsRule!)
      : Duration.fromObject({ year: 1 });
  const noticeDuration =
    bookingNoticeRule!.windowValue !== ""
      ? durationFromWindow(bookingNoticeRule!)
      : Duration.fromObject({ minute: 0 });

  const startDate = DateTime.fromISO(isoStartDate);
  if (DateTime.now() < startDate.minus(advancedDuration)) {
    return {
      canBook: false,
      errorCode: "booking-limit",
      interpolation: {
        windowFrame: advancedDuration.rescale().toHuman({
          unitDisplay: "long",
          listStyle: "long",
        }),
      },
    } as const;
  } else if (startDate.minus(noticeDuration) < DateTime.now()) {
    return {
      canBook: false,
      errorCode: "booking-notice-period",
      interpolation: {
        windowFrame: noticeDuration.rescale().toHuman({
          unitDisplay: "long",
          listStyle: "long",
        }),
      },
    } as const;
  }
  return { canBook: true } as const;
};
type OutsideWindowReason =
  | "InvalidEvent"
  | "PastEvent"
  | "FutureEvent"
  | "NoRules";
/*** @deprecated - use isInsideBookingWindow instead */
export const isOccurrenceInsideBookingWindow = (
  startDateString?: string,
  rules?: RuleClubDTO[] | RuleGroupItemDTO[],
):
  | {
      isInside: true;
    }
  | {
      isInside: false;
      reason: Omit<OutsideWindowReason, "FutureEvent">;
    }
  | {
      isInside: false;
      reason: "FutureEvent";
      availableOn: string;
    } => {
  if (!rules) {
    return {
      isInside: false,
      reason: "InvalidEvent",
    };
  }
  if (!startDateString) {
    return {
      isInside: false,
      reason: "InvalidEvent",
    };
  }
  const rule = rules.find((e) => e.bookingRule);
  if (!rule?.bookingRule?.windowType) {
    return {
      isInside: true,
    };
  }
  const startDate = DateTime.fromISO(startDateString);
  if (startDate < DateTime.now()) {
    return {
      isInside: false,
      reason: "PastEvent",
    };
  }
  const interval = Interval.fromDateTimes(
    startDate.minus({
      [windowValueToTimeUnitMap[rule.bookingRule!.windowType]]:
        rule.bookingRule!.windowValue,
    }),
    startDate,
  );
  if (interval.contains(DateTime.now())) {
    return { isInside: true };
  }

  return {
    isInside: false,
    reason: "FutureEvent",
    availableOn: interval.start?.toJSDate().toISOString(),
  };
};

export const isOccurrenceInsideCancelationWindow = (
  startDate?: string,
  rule?: RuleClubDTO | RuleGroupItemDTO,
) => {
  if (!startDate) {
    return false;
  }
  if (!rule) {
    return true;
  }
  if (
    (rule.bookingCancellationRule?.windowType as any) === "" ||
    rule.bookingCancellationRule?.windowValue === ""
  ) {
    return true;
  }
  if (isBefore(new Date(startDate), new Date(Date.now()))) {
    return false;
  }

  const duration = intervalToDuration({
    start: new Date(Date.now()),
    end: new Date(startDate),
  });
  const bookingWindow = rule.bookingCancellationRule!;

  const bookingWindowInHours =
    Number(bookingWindow.windowValue) *
    (bookingWindow.windowType === "HOURS"
      ? 1
      : bookingWindow.windowType === "DAYS"
      ? 24
      : 24 * 365.25);
  const durationInHours =
    (duration.years ?? 0) * 365.25 * 24 +
    (duration.days ?? 0) * 24 +
    (duration.hours ?? 0);
  return durationInHours >= bookingWindowInHours;
};
