import { useAutoAnimate } from "@formkit/auto-animate/react";
import {
  useMemberPaymentMethod,
  useMemberPaymentMethodAsMember,
} from "@gymflow/api";
import { cn } from "@gymflow/helpers";
import { PaymentMethodDTO, UserMemberBean } from "@gymflow/types";

import { useClubSettings } from "../../../providers";
import useGymflowModels from "../../../store";
import { Button, TrashIcon } from "../../atoms";
import { StarIcon } from "../../atoms/icons/StarIcon";
import { PaymentMethodDetails } from "../../molecules/PaymentMethodDetails";

export const PaymentMethodList = ({
  userMember,
  paymentMethods,
  asMember,
}: {
  userMember: UserMemberBean;
  paymentMethods?: PaymentMethodDTO[];
  asMember?: boolean;
}) => {
  const { api } = useGymflowModels();
  const settings = useClubSettings();
  const paymentMethodMutationsAsStaff = useMemberPaymentMethod({
    api,
  });

  const paymentMethodMutationsAsMember = useMemberPaymentMethodAsMember({
    api,
  });
  const { removePaymentMethodMutation, assignDefaultPaymentMethodMutation } =
    asMember ? paymentMethodMutationsAsMember : paymentMethodMutationsAsStaff;

  const [parent] = useAutoAnimate();
  if (!paymentMethods) return undefined;

  return (
    <div
      ref={parent}
      className="absolute inset-0 -mx-8 flex flex-col gap-2 overflow-y-auto px-8"
    >
      {paymentMethods
        .sort((a, b) =>
          a.defaultPaymentMethod
            ? -1
            : b.defaultPaymentMethod
              ? 1
              : a.id.localeCompare(b.id),
        )
        .map((paymentMethod) => {
          return (
            <div
              className="flex w-full flex-row items-center gap-2"
              key={paymentMethod.id}
            >
              <div className="bg-gray-0 flex h-fit max-h-fit flex-1 flex-row items-center justify-between gap-2 rounded-xl border border-gray-200 px-4 py-3 dark:border-gray-800 dark:bg-gray-950">
                <PaymentMethodDetails paymentMethod={paymentMethod} />
                <Button
                  className="h-fit min-h-0 min-w-0 p-2"
                  intent="transparent"
                  onClick={async () => {
                    await assignDefaultPaymentMethodMutation.mutateAsync({
                      memberId: userMember.id,
                      paymentMethodId: paymentMethod.id,
                      clubId: settings.clubId,
                    });
                  }}
                  tooltip="Set as default"
                >
                  <StarIcon
                    className="h-5 w-5"
                    pathClassName={cn({
                      "fill-secondary-500 stroke-secondary-500":
                        paymentMethod.defaultPaymentMethod,
                      "fill-transparent stroke-gray-500":
                        !paymentMethod.defaultPaymentMethod,
                    })}
                  />
                </Button>
              </div>
              <Button
                className={cn(
                  "flex h-10 max-h-none w-10 items-center justify-center p-0",
                  {
                    invisible: paymentMethod.defaultPaymentMethod,
                  },
                )}
                intent="error-outline"
                onClick={async () => {
                  await removePaymentMethodMutation.mutateAsync({
                    clubId: settings.clubId,
                    memberId: userMember.id,
                    paymentMethodId: paymentMethod.id,
                  });
                }}
              >
                <TrashIcon
                  pathClassName="stroke-error-500"
                  className="h-5 w-5"
                />
              </Button>
            </div>
          );
        })}
    </div>
  );
};
