import {
  faArrowDown,
  faArrowUp,
  faEllipsisVertical,
  faMinus,
} from "@fortawesome/free-solid-svg-icons";
import { FontAwesomeIcon } from "@fortawesome/react-fontawesome";
import { cn } from "@gymflow/helpers";
import classNames from "classnames";
import { useContext, useState } from "react";
import { Tooltip } from "react-tooltip";

import { HelpCircleIcon } from "../atoms";
import { DynamicReportWidgetContext } from "./DynamicReportWidgetCard";
import { ReportWidgetCardDropdown } from "./ReportWidgetCardDropdown";

export interface ReportWidgetCardProps {
  positiveIsBad?: boolean;
  title: string;
  value: string;
  variation?: number | null;
  explanation?: string;
  onClick?: () => void;
  isLoading: boolean;
}

const variationColors = {
  success: {
    pill: "bg-success-50",
    arrow: "text-success-500",
    text: "text-success-700",
  },
  warning: {
    pill: "bg-warning-50",
    arrow: "text-warning-500",
    text: "text-warning-700",
  },
  error: {
    pill: "bg-error-50",
    arrow: "text-error-500",
    text: "text-error-700",
  },
  primary: {
    pill: "bg-primary-50",
    arrow: "text-primary-500",
    text: "text-primary-700",
  },
};
function ReportWidgetCard({
  variation = null,
  title,
  value,
  positiveIsBad,
  isLoading = false,
  onClick,
  explanation,
}: ReportWidgetCardProps) {
  const [showOptions, setShowOptions] = useState(false);
  const renderVariation = () => {
    let percentage;
    let variationColor = "primary" as keyof typeof variationColors;
    let valueString = "";
    if (variation !== null) {
      percentage = variation > 0 ? variation : variation * -1;
      percentage = percentage.toFixed(0);
      valueString = percentage + "%";
      if (positiveIsBad) {
        variationColor = variation < 0 ? "success" : "error";
      } else if (variation === 0) {
        valueString = "";
        variationColor = "primary";
      } else {
        variationColor = variation > 0 ? "success" : "error";
      }
    }

    return (
      <div
        className={`${variationColors[variationColor].pill} flex h-6 items-center rounded-2xl px-2 py-1`}
      >
        <FontAwesomeIcon
          className={`${variationColors[variationColor].arrow} `}
          icon={
            variation === null || variation === 0
              ? faMinus
              : variation > 0
              ? faArrowUp
              : faArrowDown
          }
        />
        <div
          className={`${variationColors[variationColor].text}  ml-1 text-sm font-medium`}
        >
          {isLoading ? (
            <div className="animate-pulse">
              <div className="h-2 w-12 rounded bg-gray-400"></div>
            </div>
          ) : (
            valueString
          )}
        </div>
      </div>
    );
  };
  const dynamicWidgetContext = useContext(DynamicReportWidgetContext);
  return (
    <div className="bg-gray-0 relative mx-3 mt-6 min-w-[250px] flex-1 rounded-xl border border-gray-200 shadow">
      {dynamicWidgetContext && showOptions && (
        <ReportWidgetCardDropdown hide={() => setShowOptions(false)} />
      )}
      <div className="flex flex-row items-center justify-between px-6 pt-6">
        <div className="text-sm font-medium text-gray-600">{title}</div>
        {dynamicWidgetContext && (
          <div
            className="flex w-4 cursor-pointer flex-row justify-end"
            onClick={() => {
              setShowOptions(true);
            }}
          >
            <FontAwesomeIcon
              className="cursor-pointer text-gray-400"
              icon={faEllipsisVertical}
            />
          </div>
        )}
      </div>
      <div className="mt-2 flex flex-row items-center justify-between px-6">
        <div
          className={classNames("animate-pulse", {
            hidden: !isLoading,
          })}
        >
          <div className="h-7 w-12 rounded bg-gray-400"></div>
        </div>
        <div
          className={classNames("text-3xl font-semibold", {
            hidden: isLoading,
          })}
        >
          {value}
        </div>
        {renderVariation()}
      </div>
      <div className="mt-6 flex flex-row items-center justify-between border-t border-gray-200 px-6 py-4">
        <div className={cn({ hidden: !explanation })}>
          <div
            className="stroke-gray-500 hover:stroke-[#8746EC]"
            data-tooltip-id={`explanation-tooltip${title}`}
            data-tooltip-content={explanation}
          >
            <HelpCircleIcon className="h-5 w-5" pathClassName="" />
          </div>

          <Tooltip
            className="text-gray-0 z-10 flex max-w-xs flex-col items-center rounded-lg !bg-black text-center text-xs font-semibold"
            id={`explanation-tooltip${title}`}
          />
        </div>
        <div
          onClick={onClick}
          className={`${
            onClick ? "cursor-pointer" : ""
          } text-primary-700 text-sm font-semibold`}
        >
          {onClick ? "View Report" : "\xa0"}
        </div>
      </div>
    </div>
  );
}

export default ReportWidgetCard;
