export const statusColors: Record<
  string,
  | {
      laneBackground: string;
      laneBorder: string;
      laneHovering: string;
      badgeBackground: string;
      badgeCircle: string;
    }
  | undefined
> = {
  "New Lead": {
    laneBorder: "#E4E7EC",
    laneBackground: "#F8F9FC",
    laneHovering: "#F2F4F7",
    badgeBackground: "#EAECF5",
    badgeCircle: "#B3B8DB",
  },
  "Attempted Contact": {
    laneBorder: "#FEDF89",
    laneBackground: "#FFFCF5",
    laneHovering: "#FEF0C7",
    badgeBackground: "#FEF0C7",
    badgeCircle: "#FDB022",
  },
  Contacted: {
    laneBorder: "#B9E6FE",
    laneBackground: "#F5FBFF",
    laneHovering: "#A9D1FF",
    badgeBackground: "#E0F2FE",
    badgeCircle: "#36BFFA",
  },
  Trial: {
    laneBorder: "#A6F4C5",
    laneBackground: "#F6FEF9",
    laneHovering: "#A6F4C5",
    badgeBackground: "#D1FADF",
    badgeCircle: "#32D583",
  },
};
