import { cn } from "@gymflow/helpers";
import {
  useInfiniteQuery,
  UseInfiniteQueryOptions,
} from "@tanstack/react-query";
import { useCallback, useMemo, useRef, useState } from "react";
import { useDebounceValue, useOnClickOutside } from "usehooks-ts";

import { CloseIcon, Search2Icon } from "../icons";
import { OptionType } from "./components/OptionsList/components/OptionItem";
import { OptionsList } from "./components/OptionsList/OptionsList";
import { OptionsListProps } from "./components/OptionsList/types";
import { SelectedOptionBadge } from "./components/SelectedOptionBadge";
import { SelectedOptionItem } from "./components/SelectedOptionItem";
import { Input, InputProps } from "./Input/Input";

export type SearchInputOptionType<T> = OptionType<T>;
export type SearchInputOptionsType<T> = OptionType<T>[];

export type SearchInputProps<T> = ({
  value: SearchInputOptionsType<T>;
  onChange: (selected: SearchInputOptionsType<T>) => void;
  isMulti?: boolean;
  withImageByDefault?: boolean;
} & (
  | { options: SearchInputOptionsType<T> }
  | {
      dataFetchingQuery: (
        searchTerm: string,
      ) => UseInfiniteQueryOptions<unknown, unknown, SearchInputOptionsType<T>>;
    }
)) &
  Pick<InputProps, "label" | "placeholder" | "className"> &
  Pick<OptionsListProps<T>, "notFound" | "notFoundClassName" | "dropPosition">;

// TODO: if isMulti = false -> only single result (no array)
export const SearchInput = <T,>({
  className,
  isMulti = false,
  value,
  onChange,
  dropPosition = "bottom",
  placeholder = "Search",
  withImageByDefault = false,
  notFoundClassName,
  notFound,
  label,
  ...rest
}: SearchInputProps<T>) => {
  const dataFetchingQuery =
    "dataFetchingQuery" in rest ? rest.dataFetchingQuery : undefined;
  const options = "options" in rest ? rest.options : undefined;

  const [isOpen, setIsOpen] = useState(false);
  const [inputValue, setInputValue] = useState("");
  const inputRef = useRef<HTMLInputElement>(null);
  const containerRef = useRef<HTMLDivElement>(null);

  const [queryOptions] = useDebounceValue(
    dataFetchingQuery
      ? dataFetchingQuery(inputValue)
      : { queryKey: [], enabled: false },
    200,
  );
  const { isFetching, data } = useInfiniteQuery(queryOptions);

  useOnClickOutside(containerRef, () => setIsOpen(false));

  const handleSelectOption = useCallback(
    (option: SearchInputOptionType<T>) => {
      if (isMulti) {
        inputRef.current?.focus();
        const isAlreadySelected = value.some(
          (v) => String(v.id) === String(option.id),
        );
        const updatedValue = isAlreadySelected
          ? value.filter((v) => String(v.id) !== String(option.id))
          : [...value, option];

        return onChange(updatedValue);
      }

      onChange([option]);
      setIsOpen(false);
      setInputValue(option.label);
    },
    [isMulti, onChange, value],
  );

  const onClear = useCallback(() => {
    isOpen && inputRef.current?.focus();
    onChange([]);
    setInputValue("");
  }, [isOpen, onChange]);

  const filteredOptions = useMemo(
    () =>
      dataFetchingQuery
        ? data?.pages.flatMap((page) => page)
        : options?.filter((option) =>
            option.label.toLowerCase().includes(inputValue.toLowerCase()),
          ),
    [data?.pages, dataFetchingQuery, inputValue, options],
  );

  const isSelectedAndMulti = isMulti && value.length > 0;

  return (
    <div className="relative">
      <div ref={containerRef}>
        <Input
          ref={inputRef}
          value={inputValue}
          onChange={(e) => setInputValue(e.target.value)}
          label={label}
          onFocus={() => setIsOpen(true)}
          startIcon={<Search2Icon />}
          className={cn(
            {
              "pr-20": isSelectedAndMulti,
              "pr-10": inputValue,
              "text-transparent placeholder:text-transparent":
                !isMulti && value.length > 0 && !isOpen,
            },
            className,
          )}
          placeholder={placeholder}
          renderOverInput={
            <>
              <div className="absolute inset-y-0 right-3.5 flex items-center gap-1.5">
                {!isMulti && (value[0] || inputValue) && (
                  <div onClick={onClear}>
                    <CloseIcon className="h-4 w-4 cursor-pointer" />
                  </div>
                )}
                {isSelectedAndMulti && (
                  <SelectedOptionBadge value={value.length} onClick={onClear} />
                )}
              </div>
              <SelectedOptionItem
                show={Boolean(!isOpen && !isMulti && value[0])}
                className="left-10"
                label={value[0]?.label}
                subLabel={value[0]?.subLabel}
                imageUrl={value[0]?.imageUrl}
                withImageByDefault={withImageByDefault}
              />
            </>
          }
        />
        <OptionsList
          show={isOpen}
          options={filteredOptions || []}
          value={value}
          handleSelectOption={handleSelectOption}
          dropPosition={dropPosition}
          withImageByDefault={withImageByDefault}
          loading={isFetching}
          notFoundClassName={notFoundClassName}
          notFound={notFound}
        />
      </div>
      {isSelectedAndMulti && (
        <div className="mt-2 flex flex-wrap items-center gap-2">
          {value.map(({ id, label }) => (
            <SelectedOptionBadge
              key={id}
              value={label}
              onClick={() =>
                onChange(value.filter((v) => String(v.id) !== String(id)))
              }
            />
          ))}
        </div>
      )}
    </div>
  );
};
