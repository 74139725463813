import { AlertTriangleIcon } from "apps/portal/src/components/atoms";
import { useTranslation } from "react-i18next";

export const BillingDateChangeWarning = () => {
  const { t } = useTranslation();

  return (
    <div className="flex flex-col gap-1">
      <div className="flex items-center gap-2">
        <div className="bg-warning-100 border-warning-50 rounded-full border-4 p-1">
          <AlertTriangleIcon
            className="h-4 w-4"
            pathClassName="stroke-warning-600"
          />
        </div>
        <div className="text-base font-semibold text-gray-950">
          {t(
            "page.userMember.tab.userMemberAccount.userMemberMembership.membershipCard.modal.changeMembershipModal.billingDateChangeWarning.title",
          )}
        </div>
      </div>
      <div className="text-sm font-medium text-gray-500">
        {t(
          "page.userMember.tab.userMemberAccount.userMemberMembership.membershipCard.modal.changeMembershipModal.billingDateChangeWarning.description",
        )}
      </div>
    </div>
  );
};
