import { cn } from "@gymflow/helpers";
import { MouseEvent as ReactMouseEvent } from "react";

/**
 * @deprecated use atoms/base/CheckboxInput instead
 */
export function Checkbox({
  className,
  inputClassName,
  activeInputClassName,
  onChange,
  value,
  isDisabled,
}: {
  className?: string;
  inputClassName?: string;
  activeInputClassName?: string;
  onChange: (
    newValue: boolean,
    e: ReactMouseEvent<HTMLInputElement, MouseEvent>,
  ) => void;
  value: boolean;
  isDisabled?: boolean;
}) {
  return (
    <div className={cn("flex h-6 items-center", className)}>
      <input
        type="checkbox"
        className={cn(
          "text-secondary-500 focus:ring-secondary-500 dark:bg-gray-950 dark:border-gray-700 h-4 w-4 border-gray-300",
          inputClassName,
          {
            "!bg-secondary-500 !border-secondary-500": value,
          },
          {
            [activeInputClassName ?? ""]: value,
          },
        )}
        checked={value}
        onChange={() => {}}
        onClick={(e) => {
          onChange(e.currentTarget.checked, e);
        }}
        readOnly={isDisabled}
        disabled={isDisabled}
      />
    </div>
  );
}
