export function SupportIcon({
  className,
  pathClassName,
}: {
  className?: string;
  pathClassName?: string;
}) {
  return (
    <svg
      xmlns="http://www.w3.org/2000/svg"
      width="20"
      height="20"
      viewBox="0 0 20 20"
      fill="none"
      className={className}
    >
      <path
        d="M8.74967 7.50186C8.8965 7.08447 9.18631 6.7325 9.56778 6.50831C9.94925 6.28412 10.3977 6.20217 10.8338 6.27697C11.2699 6.35177 11.6655 6.57851 11.9505 6.917C12.2354 7.2555 12.3914 7.68393 12.3907 8.1264C12.3907 9.37547 10.5171 10 10.5171 10M10.5413 12.5H10.5496M10.4163 16.6667C14.3284 16.6667 17.4997 13.4953 17.4997 9.58333C17.4997 5.67132 14.3284 2.5 10.4163 2.5C6.50432 2.5 3.333 5.67132 3.333 9.58333C3.333 10.375 3.46287 11.1363 3.70248 11.8472C3.79264 12.1147 3.83773 12.2484 3.84586 12.3512C3.85389 12.4527 3.84782 12.5238 3.82271 12.6225C3.79729 12.7223 3.74116 12.8262 3.62891 13.034L2.26586 15.557C2.07144 15.9168 1.97422 16.0968 1.99598 16.2356C2.01493 16.3566 2.08612 16.4631 2.19065 16.5269C2.31065 16.6001 2.51408 16.579 2.92095 16.537L7.18847 16.0958C7.3177 16.0825 7.38232 16.0758 7.44122 16.0781C7.49915 16.0803 7.54004 16.0857 7.59653 16.0987C7.65396 16.112 7.72618 16.1398 7.87063 16.1954C8.66067 16.4998 9.51902 16.6667 10.4163 16.6667Z"
        stroke="#C7CDFF"
        strokeWidth="1.5"
        strokeLinecap="round"
        strokeLinejoin="round"
        className={pathClassName}
      />
    </svg>
  );
}
