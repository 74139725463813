import { ApiType } from "@gymflow/common/lib/api/ApiType";
import { useMutation, useQueryClient } from "@tanstack/react-query";

import { memberQueryKeys } from "./memberQueryKeys";

export function useMemberInvoice({
  api,
  memberId,
}: {
  api: ApiType;
  memberId?: string;
}) {
  const queryClient = useQueryClient();

  const adjustInvoiceMutation = useMutation({
    mutationFn: ({
      invoiceNumber,
      amount,
    }: {
      invoiceNumber: string;
      amount: number;
    }) => api.memberApi.adjustInvoice(invoiceNumber, amount),
    onSettled: async () => {
      if (!memberId) return;
      await queryClient.invalidateQueries({
        queryKey: memberQueryKeys.invoices(memberId),
      });
      await queryClient.invalidateQueries({
        queryKey: memberQueryKeys.details(memberId),
      });
      await queryClient.invalidateQueries({
        queryKey: memberQueryKeys.highlightedInvoice(memberId),
      });
    },
  });
  const collectInvoiceMutation = useMutation({
    mutationFn: ({ invoiceNumber }: { invoiceNumber: string }) =>
      api.memberApi.collectInvoice(invoiceNumber),
    onSettled: async () => {
      await queryClient.invalidateQueries({
        queryKey: memberQueryKeys.invoices(memberId),
      });
      await queryClient.invalidateQueries({
        queryKey: memberQueryKeys.details(memberId),
      });
      await queryClient.invalidateQueries({
        queryKey: memberQueryKeys.highlightedInvoice(memberId),
      });
    },
  });

  const collectPastDueByPaymentMethodIdMutation = useMutation({
    mutationFn: ({
      invoiceNumber,
      paymentMethodId,
    }: {
      invoiceNumber: string;
      paymentMethodId: string;
    }) =>
      api.memberApi.collectPastDueByPaymentMethodId(
        invoiceNumber,
        paymentMethodId,
      ),
    onSettled: async () => {
      await queryClient.invalidateQueries({
        queryKey: memberQueryKeys.invoices(memberId),
      });
      await queryClient.invalidateQueries({
        queryKey: memberQueryKeys.details(memberId),
      });
      await queryClient.invalidateQueries({
        queryKey: memberQueryKeys.highlightedInvoice(memberId),
      });
    },
  });

  const markCustomPaymentCollectedByCustomPaymentCategoryIdMutation =
    useMutation({
      mutationFn: ({
        invoiceNumber,
        customPaymentCategoryId,
      }: {
        invoiceNumber: string;
        customPaymentCategoryId: number;
      }) =>
        api.memberApi.markCustomPaymentCollectedByCustomPaymentCategoryId(
          invoiceNumber,
          customPaymentCategoryId,
        ),
      onSettled: async () => {
        await queryClient.invalidateQueries({
          queryKey: memberQueryKeys.invoices(memberId),
        });
        await queryClient.invalidateQueries({
          queryKey: memberQueryKeys.details(memberId),
        });
        await queryClient.invalidateQueries({
          queryKey: memberQueryKeys.highlightedInvoice(memberId),
        });
      },
    });

  const refundInvoiceMutation = useMutation({
    mutationFn: ({ invoiceNumber }: { invoiceNumber: string }) =>
      api.memberApi.refundInvoice(invoiceNumber),
    onSettled: async () => {
      await queryClient.invalidateQueries({
        queryKey: memberQueryKeys.invoices(memberId),
      });
      await queryClient.invalidateQueries({
        queryKey: memberQueryKeys.details(memberId),
      });
      await queryClient.invalidateQueries({
        queryKey: memberQueryKeys.highlightedInvoice(memberId),
      });
    },
  });

  const writeOffInvoiceMutation = useMutation({
    mutationFn: ({ invoiceNumber }: { invoiceNumber: string }) =>
      api.memberApi.writeOffInvoice(invoiceNumber),
    onSettled: async () => {
      await queryClient.invalidateQueries({
        queryKey: memberQueryKeys.invoices(memberId),
      });
      await queryClient.invalidateQueries({
        queryKey: memberQueryKeys.details(memberId),
      });
      await queryClient.invalidateQueries({
        queryKey: memberQueryKeys.highlightedInvoice(memberId),
      });
    },
  });
  const cancelAuthorizationMutation = useMutation({
    mutationFn: ({ invoiceNumber }: { invoiceNumber: string }) =>
      api.memberApi.cancelInvoicePaymentAuth(invoiceNumber),
    onSettled: async () => {
      if (!memberId) return;
      await queryClient.invalidateQueries({
        queryKey: memberQueryKeys.invoices(memberId),
      });
      await queryClient.invalidateQueries({
        queryKey: memberQueryKeys.details(memberId),
      });
      await queryClient.invalidateQueries({
        queryKey: memberQueryKeys.highlightedInvoice(memberId),
      });
    },
  });

  return {
    collectInvoiceMutation,
    refundInvoiceMutation,
    writeOffInvoiceMutation,
    cancelAuthorizationMutation,
    adjustInvoiceMutation,
    collectPastDueByPaymentMethodIdMutation,
    markCustomPaymentCollectedByCustomPaymentCategoryIdMutation,
  };
}
