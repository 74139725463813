import { z } from "zod";
import { toFormikValidationSchema } from "zod-formik-adapter";

export const MARKETING_TYPE_OPTIONS = [
  {
    id: "ACCOUNT_RELATED",
    label: "Account Related",
    value: "ACCOUNT_RELATED",
  } as const,
  { id: "MARKETING", label: "Marketing", value: "MARKETING" } as const,
];
const MarketingTypeEnumSchema = z.enum(["MARKETING", "ACCOUNT_RELATED"]);
export type MarketingType = z.infer<typeof MarketingTypeEnumSchema>;

const SendEmailFormSchema = z.object({
  subject: z
    .string({ required_error: "Subject is required" })
    .min(3, "Minimum 3 characters")
    .max(100, "Maximum 100 characters"),
  body: z
    .string({ required_error: "Body is required" })
    .transform((value) => {
      // get ONLY text from HTML
      const parser = new DOMParser();
      const htmlDoc = parser.parseFromString(value, "text/html");
      return htmlDoc.documentElement.textContent || "";
    })
    .refine((value) => value.length >= 3, {
      message: "Minimum 3 characters",
    }),
  marketingType: z.object({
    id: z.string(),
    label: z.string(),
    value: MarketingTypeEnumSchema,
  }),
});
export type SendEmailFormValues = z.infer<typeof SendEmailFormSchema>;
export const sendEmailFormSchema =
  toFormikValidationSchema<SendEmailFormValues>(SendEmailFormSchema);

export const INITIAL_VALUES_SEND_EMAIL_FORM: SendEmailFormValues = {
  subject: "",
  body: "",
  marketingType: MARKETING_TYPE_OPTIONS[0],
};
