type PMCustomAllIconProps = {
  className?: string;
};

const PMCustomAllIcon = ({ className }: PMCustomAllIconProps) => (
  <svg
    width="34"
    height="24"
    viewBox="0 0 34 24"
    fill="none"
    xmlns="http://www.w3.org/2000/svg"
    className={className}
  >
    <path
      d="M0.5 4C0.5 2.067 2.067 0.5 4 0.5H30C31.933 0.5 33.5 2.067 33.5 4V20C33.5 21.933 31.933 23.5 30 23.5H4C2.067 23.5 0.5 21.933 0.5 20V4Z"
      fill="white"
    />
    <path
      d="M0.5 4C0.5 2.067 2.067 0.5 4 0.5H30C31.933 0.5 33.5 2.067 33.5 4V20C33.5 21.933 31.933 23.5 30 23.5H4C2.067 23.5 0.5 21.933 0.5 20V4Z"
      stroke="#E4E7EC"
    />
    <g clipPath="url(#clip0_8027_10417)">
      <path
        d="M18.0196 9.56807C17.638 9.84013 17.171 10.0002 16.6666 10.0002C15.3779 10.0002 14.3333 8.95549 14.3333 7.66683C14.3333 6.37816 15.3779 5.3335 16.6666 5.3335C17.5019 5.3335 18.2347 5.77244 18.6469 6.43226M12.9999 17.3916H14.7401C14.967 17.3916 15.1925 17.4186 15.4125 17.4726L17.2512 17.9194C17.6501 18.0166 18.0658 18.0261 18.4689 17.9478L20.5019 17.5522C21.0389 17.4476 21.533 17.1905 21.9201 16.8138L23.3585 15.4146C23.7693 15.0157 23.7693 14.3685 23.3585 13.9689C22.9887 13.6091 22.4031 13.5686 21.9847 13.8737L20.3083 15.0967C20.0682 15.2722 19.7761 15.3667 19.4757 15.3667H17.8569L18.8873 15.3667C19.4681 15.3667 19.9385 14.909 19.9385 14.3441V14.1396C19.9385 13.6705 19.6103 13.2615 19.1426 13.1481L17.5523 12.7613C17.2935 12.6986 17.0285 12.6668 16.762 12.6668C16.1188 12.6668 14.9545 13.1994 14.9545 13.1994L12.9999 14.0168M22.3333 8.3335C22.3333 9.62216 21.2886 10.6668 19.9999 10.6668C18.7113 10.6668 17.6666 9.62216 17.6666 8.3335C17.6666 7.04483 18.7113 6.00016 19.9999 6.00016C21.2886 6.00016 22.3333 7.04483 22.3333 8.3335ZM10.3333 13.7335L10.3333 17.6002C10.3333 17.9735 10.3333 18.1602 10.4059 18.3028C10.4698 18.4283 10.5718 18.5303 10.6973 18.5942C10.8399 18.6668 11.0266 18.6668 11.3999 18.6668H11.9333C12.3066 18.6668 12.4933 18.6668 12.6359 18.5942C12.7614 18.5303 12.8633 18.4283 12.9273 18.3028C12.9999 18.1602 12.9999 17.9735 12.9999 17.6002V13.7335C12.9999 13.3601 12.9999 13.1734 12.9273 13.0308C12.8633 12.9054 12.7614 12.8034 12.6359 12.7395C12.4933 12.6668 12.3066 12.6668 11.9333 12.6668L11.3999 12.6668C11.0266 12.6668 10.8399 12.6668 10.6973 12.7395C10.5718 12.8034 10.4698 12.9054 10.4059 13.0308C10.3333 13.1734 10.3333 13.3601 10.3333 13.7335Z"
        stroke="black"
        strokeWidth="1.2"
        strokeLinecap="round"
        strokeLinejoin="round"
      />
    </g>
    <defs>
      <clipPath id="clip0_8027_10417">
        <rect width="16" height="16" fill="white" transform="translate(9 4)" />
      </clipPath>
    </defs>
  </svg>
);

export default PMCustomAllIcon;
