import {
  ApiListResponse,
  ApiTokenListResponse,
  HighlightedInvoiceDTO,
  InvoiceDTO,
  InvoiceNewDTO,
  PaymentMethodDTO,
  PaymentMethodSetupIntent,
  SessionPackUsage,
  TokenPageableRequestParams,
  UserMemberBean,
  UserMemberPost,
  UserMemberSubscriptionSignUpBeanAsMember,
} from "@gymflow/types";
import axios, { AxiosInstance } from "axios";
import { assert } from "check-types";

// @ts-ignore
import { canFind, canFindById } from "./features/apiResource";
// @ts-ignore
import canSignUp from "./features/canSignUp";

export type ProfileApiType = ReturnType<typeof profileApi>;

const profileApi = (axiosInstance: AxiosInstance, clubId: number) => {
  const clubPrefix = `club/${clubId}/` as const;
  const initialState = {};
  const state = {
    ...initialState,
    ...canFind(initialState),
    ...canFindById(initialState),
    ...canSignUp(),
  };

  return Object.assign(state, {
    create(newResource: any) {
      return axiosInstance.post(
        `${clubPrefix}customer/user/member`,
        state.createRequestBody(newResource),
      );
    },
    /*** @deprecated - use updateNew instead*/
    update(_: any, clubId: number, patchedFields: any) {
      assert.number(clubId, "clubId must be a number");
      return axiosInstance.patch(
        `${clubPrefix}customer/user/member`,
        state.createRequestBody(patchedFields),
      );
    },
    updateNew(patchedFields: Partial<UserMemberPost>) {
      return axiosInstance.patch(
        `${clubPrefix}customer/user/member`,
        patchedFields,
      );
    },
    get() {
      return axiosInstance.get<UserMemberBean>(
        `${clubPrefix}customer/user/member`,
      );
    },
    checkout(patchedFields: {
      paymentMethod?: string;
      subscriptionSignUpBean: UserMemberSubscriptionSignUpBeanAsMember;
    }) {
      return (axiosInstance as AxiosInstance).patch<{ invoice: InvoiceDTO }>(
        `${clubPrefix}customer/user/member`,
        patchedFields,
      );
    },

    findById() {
      return axiosInstance.get(`${clubPrefix}customer/user/member`);
    },
    async updatePictureNew(blob: globalThis.Blob) {
      const formData = new FormData();
      formData.append("file", blob, "image");
      return axiosInstance.post(
        `${clubPrefix}customer/user/member/picture`,
        formData,
        { headers: { "Content-Type": "multipart/form-data" } },
      );
    },
    /*** @deprecated - use updateNew instead */
    async updatePicture(_: any, { blob, name }: any) {
      const blobResponse = await axios.get(blob, { responseType: "blob" });
      const formData = new FormData();
      formData.append("file", blobResponse.data, name);
      return axiosInstance.post(
        `${clubPrefix}customer/user/member/picture`,
        formData,
        { headers: { "Content-Type": "multipart/form-data" } },
      );
    },

    async fetchPaymentMethods(_: string, clubId: number) {
      const result = await axiosInstance.get<PaymentMethodDTO[]>(
        `${clubPrefix}customer/payment-method`,
      );
      (result as any).data = (result as any).data.map((e: any) => ({
        ...e,
        id: e.paymentMethodId,
      }));
      return result;
    },
    attachPaymentMethod(_: string, clubId: number, paymentMethodId: string) {
      return axiosInstance.put(
        `${clubPrefix}customer/payment-method/card/${paymentMethodId}`,
      );
    },

    removePaymentMethod(_: string, clubId: number, paymentMethodId: string) {
      return axiosInstance.delete(
        `${clubPrefix}customer/payment-method/${paymentMethodId}`,
      );
    },

    assignDefaultPaymentMethod(
      _: string | undefined,
      __: number | undefined,
      paymentMethodId: string,
    ) {
      return axiosInstance.put(
        `${clubPrefix}customer/payment-method/default/${paymentMethodId}`,
      );
    },

    resumeSubscription(_: string, subscriptionId: number) {
      return axiosInstance.post(
        `${clubPrefix}customer/user/member/service/resume/${subscriptionId}`,
      );
    },

    pauseSubscription(_: string, subscriptionId: number) {
      return axiosInstance.post(
        `${clubPrefix}customer/user/member/service/pause/${subscriptionId}`,
      );
    },

    cancelSubscription(_: string, subscriptionId: number) {
      return axiosInstance.post(
        `${clubPrefix}customer/user/member/service/cancel/${subscriptionId}`,
      );
    },

    findInvoices(
      _: string | undefined,
      { page = 0, limit = 10, extraParams = {}, sort }: any,
    ) {
      const url = `${clubPrefix}customer/user/member/invoices`;

      assert.maybe.number(page, "page must be number or undefined");
      assert.maybe.number(limit, "limit must be number or undefined");

      const config = { params: { page, size: limit, ...extraParams } };
      if (sort) {
        assert.string(
          sort.field,
          "sort.field must be a string if sort is specified.",
        );
        config.params.sort = state.createSortParam(sort.field, sort.desc);
      }

      return axiosInstance.get<ApiListResponse<InvoiceDTO>>(url, config);
    },

    payInvoice(invoiceNumber: string) {
      return axiosInstance.put<InvoiceDTO>(
        `${clubPrefix}invoice/${invoiceNumber}/collect-past-due/`,
      );
    },

    invoiceFile(clubId: number, invoiceNumber: string) {
      assert.number(clubId, "clubId must be a number");
      assert.string(invoiceNumber, "invoiceNumber must be a string");

      const url =
        `${clubPrefix}customer/user/member/invoice-file/:invoiceNumber`.replace(
          ":invoiceNumber",
          invoiceNumber,
        );

      return axiosInstance.get(url);
    },
    updateEmailAndPassword(payload: any) {
      const url = `${clubPrefix}customer/user/member/account`;
      return axiosInstance.patch(url, payload);
    },
    highlightedInvoice() {
      return axiosInstance.get<HighlightedInvoiceDTO>(
        `${clubPrefix}customer/user-member-invoice-list/highlighted`,
      );
    },
    findInvoicesNew({ ...params }: TokenPageableRequestParams) {
      return axiosInstance.get<ApiTokenListResponse<InvoiceNewDTO>>(
        `${clubPrefix}customer/user-member-invoice-list/`,
        { params },
      );
    },
    getPaymentMethodSetupIntent() {
      return axiosInstance.put<PaymentMethodSetupIntent>(
        `${clubPrefix}customer/payment-method/setup-intent`,
      );
    },
    attachAuthorizedSetupIntent(setupIntentId: string) {
      return axiosInstance.put<{}>(
        `${clubPrefix}customer/payment-method/setup-intent-authorized/${setupIntentId}`,
      );
    },
    sessionPackUsage(
      params: { sessionCost: number } & (
        | { appointableCategoryId: number }
        | { activityCategoryId: number }
      ),
    ) {
      return axiosInstance.put<SessionPackUsage>(
        `${clubPrefix}customer/user/member/session-pack-usage`,
        params,
      );
    },
  });
};

export default profileApi;
