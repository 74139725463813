import { ApiType } from "@gymflow/common/lib/api/ApiType";
import {
  CalculateChangeSubscriptionPriceNewRequestBody,
  CalculateChangeSubscriptionPriceNewResponseBody,
} from "@gymflow/types";
import { useQuery, UseQueryOptions } from "@tanstack/react-query";

import { memberQueryKeys } from "./memberQueryKeys";

export const useQueryMemberCalculateProRataForChangingSubscriptionNew = (
  {
    api,
    membershipId,
    subscriptionId,
  }: {
    api: ApiType;
  } & CalculateChangeSubscriptionPriceNewRequestBody,
  opts?: UseQueryOptions<CalculateChangeSubscriptionPriceNewResponseBody>,
) =>
  useQuery({
    queryKey: memberQueryKeys.calculateChangeSubscriptionPriceNew({
      membershipId,
      subscriptionId,
    }),
    queryFn: async () =>
      (
        await api.memberApi.calculateChangeSubscriptionPriceNew({
          membershipId,
          subscriptionId,
        })
      ).data,
    ...opts,
  });
