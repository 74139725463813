import {
  ApiListResponse,
  MembershipBean,
  MembershipStatus,
  MembershipSummaryDTO,
  MembershipType,
} from "@gymflow/types";
import { AxiosInstance } from "axios";

export type CustomerMembershipApiType = ReturnType<
  typeof customerMembershipApi
>;

const customerMembershipApi = (
  axiosInstance: AxiosInstance,
  clubId: number,
) => {
  const baseUrl = `club/${clubId}/customer/membership`;

  return {
    getById(id: number) {
      return axiosInstance.get<MembershipBean>(`${baseUrl}/${id}`);
    },
    summary(params: {
      membershipId: number;
      promotionCode?: string;
      startDate?: string;
    }) {
      return axiosInstance.post<MembershipSummaryDTO>(
        `${baseUrl}/summary`,
        params,
      );
    },
    getList(params: {
      status?: MembershipStatus;
      id?: number[];
      page: number;
      size: number;
      sort?: string;
      type?: MembershipType;
      isTrial?: boolean;
    }) {
      return axiosInstance.get<ApiListResponse<MembershipBean>>(`${baseUrl}`, {
        params,
      });
    },
  };
};

export default customerMembershipApi;
