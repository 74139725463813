import { faQuestionCircle } from "@fortawesome/free-regular-svg-icons";
import { faClose } from "@fortawesome/free-solid-svg-icons";
import { FontAwesomeIcon } from "@fortawesome/react-fontawesome";
import {
  clubStaleTime,
  useClub,
  useClubFeatureFlags,
  useMutationProductPicture,
  useProductCategorySearchList,
  useProductCreate,
  useProductEdit,
} from "@gymflow/api";
import {
  FormikInput,
  FormMapper,
  onlyNumbersProps,
  useRecordForm,
} from "@gymflow/common";
import { Product, ProductDTO } from "@gymflow/types";
import { useClubSettings } from "apps/portal/src/providers";
import { ToastContext } from "apps/portal/src/providers/ToastProvider/context";
import { useFormik } from "formik";
import omit from "lodash/omit";
import {
  createContext,
  ReactElement,
  useCallback,
  useContext,
  useState,
} from "react";
import { Tooltip } from "react-tooltip";
import { toFormikValidationSchema } from "zod-formik-adapter";

import { getDefaultsFromZodSchema } from "../../../helpers/zod";
import useGymflowModels from "../../../store";
import {
  Button,
  ImageInputWithModal,
  PaginatedSelect,
  SlideSideBar,
} from "../../atoms";
import Switch from "../../Switch";
import { ProductSchema, ProductType } from "./ProductSchema";

const TypedFormikInput = FormikInput as React.FC<{ [key: string]: any }>;
export const ProductFormSidebarProviderContext = createContext<{
  showProductForm: ({
    currentProduct,
    onClose,
  }: {
    currentProduct?: Product;
    onClose?: (newProduct?: ProductDTO) => void;
  }) => void;
}>({} as any);

export const ProductFormSidebarProvider: React.FC<{
  children: ReactElement | ReactElement[];
}> = ({ children }) => {
  const [sidebarState, setSidebarState] = useState<
    | {
        currentProduct?: Product;
        isVisible: boolean;
        onClose: (newProduct?: ProductDTO) => void;
      }
    | undefined
  >({
    isVisible: false,
    onClose: () => {},
  });
  const { api } = useGymflowModels();
  const settings = useClubSettings();
  const clubId = settings.clubId;
  const { data: club } = useClub(
    { api, clubId: clubId },
    { staleTime: clubStaleTime },
  );
  const { initialValues, getPatchedValues, getValues } = useRecordForm({
    record: sidebarState?.currentProduct ?? {},
    fields: getDefaultsFromZodSchema(ProductSchema),
    mapper,
  });

  const formik = useFormik<ProductType>({
    enableReinitialize: true,
    initialValues,
    onSubmit: async (values) => {
      if (sidebarState?.currentProduct) {
        const patchedFields = getPatchedValues(values);
        await onSubmit({
          id: sidebarState?.currentProduct.id,
          patchedFields: patchedFields,
        });
      } else {
        const fields = getValues(values);
        await onSubmit(fields);
      }
    },
    validationSchema: toFormikValidationSchema(ProductSchema),
  });

  const hide = useCallback(
    (newProduct?: ProductDTO) => {
      formik.resetForm();
      sidebarState?.onClose(newProduct);
      setSidebarState(undefined);
    },
    [formik, sidebarState],
  );
  const changeProductMutation = useProductEdit({ api });
  const createProductMutation = useProductCreate({ api });
  const { notifyDanger } = useContext(ToastContext);
  const { mutateAsync: uploadPicture, isLoading: isLoadingUploadPicture } =
    useMutationProductPicture({ api });
  const onSubmit = useCallback(
    async (values: any) => {
      try {
        let result: ProductDTO;
        if (sidebarState?.currentProduct) {
          result = await changeProductMutation.mutateAsync({
            productId: values.id,
            patchedFields: omit(values.patchedFields, "picture"),
          });
          if (values.patchedFields.picture?.blob) {
            await uploadPicture({
              productId: values.id,
              picture: values.patchedFields.picture.blob,
            });
          }
        } else {
          result = await createProductMutation.mutateAsync({
            ...omit(values, "picture"),
            taxRate: club?.defaultTaxRate,
          });
          if (values.picture?.blob) {
            await uploadPicture({
              productId: result.id,
              picture: values.picture.blob,
            });
          }
        }
        hide(result);
      } catch (e) {
        notifyDanger(e as any);
      }
    },
    [
      sidebarState?.currentProduct,
      hide,
      changeProductMutation,
      uploadPicture,
      createProductMutation,
      club?.defaultTaxRate,
      notifyDanger,
    ],
  );

  const categories = useProductCategorySearchList({
    api,
    opts: { limit: 1000, page: 0 },
  });

  const showProductForm = useCallback(
    (params: {
      currentProduct?: Product;
      onClose?: (newProduct?: ProductDTO) => void;
    }) => {
      if (
        params.currentProduct?.id !== sidebarState?.currentProduct?.id ||
        (!params.currentProduct && !sidebarState?.isVisible)
      ) {
        setSidebarState({
          isVisible: true,
          currentProduct: params?.currentProduct,
          onClose: (newProduct) => {
            params?.onClose?.(newProduct);
          },
        });
        if (params.currentProduct) {
          formik.setValues(params.currentProduct as any);
        }
      }
    },
    [sidebarState?.currentProduct?.id, sidebarState?.isVisible],
  );

  const isLoading =
    changeProductMutation.isLoading ||
    createProductMutation.isLoading ||
    isLoadingUploadPicture;
  const { data: featureFlags } = useClubFeatureFlags({
    api,
    clubId: settings.clubId,
  });
  return (
    <ProductFormSidebarProviderContext.Provider
      value={{
        showProductForm,
      }}
    >
      {children}
      <SlideSideBar
        isOpen={!!sidebarState?.isVisible}
        hide={hide}
        className="!w-[32rem] max-w-full"
        isLoading={false}
      >
        <div className="flex h-full max-h-full flex-col overflow-hidden">
          <div className="flex flex-col justify-between border-b border-gray-200 p-8">
            <div className="mb-1 flex flex-row items-center justify-between">
              <div className="text-xl font-semibold text-gray-900">
                {sidebarState?.currentProduct
                  ? "Update Product"
                  : "Create Product"}
              </div>

              <FontAwesomeIcon
                onClick={() => {
                  hide();
                }}
                className="cursor-pointer text-xl text-gray-600"
                icon={faClose}
              />
            </div>
            <div className="text-sm font-medium text-gray-600">
              {sidebarState?.currentProduct
                ? "Update inventory items to be sold at point of sale"
                : "Create inventory items to be sold at point of sale"}
            </div>
          </div>
          <div className="flex h-full flex-col overflow-y-auto overflow-x-hidden">
            <div className="flex flex-col gap-y-4 border-b border-gray-200 p-8">
              <div className="flex flex-col gap-3">
                <label className="!m-0 text-sm font-medium text-gray-500">
                  Product Image
                </label>
                <ImageInputWithModal
                  value={formik.values["picture"]}
                  onChange={(blob) => {
                    const url = URL.createObjectURL(blob);
                    if (blob) {
                      formik.setFieldValue("picture", { blob, url });
                    } else {
                      formik.setFieldValue("picture", undefined);
                    }
                  }}
                  modalTitle="Add product image"
                  //get the initials
                  placeholder={`${formik.values.name
                    .split(" ")
                    .map((e) => e.charAt(0))
                    .join("")}`}
                />
              </div>
              <div className="flex flex-col gap-3">
                <label
                  className="!m-0 text-sm font-medium text-gray-500"
                  htmlFor="name"
                >
                  Name*
                </label>
                <TypedFormikInput
                  placeholder="Enter a product name"
                  autoComplete="off"
                  data-testid="name"
                  maxLength="128"
                  name="name"
                  type="text"
                  formikProps={formik}
                  className="!p-3 text-gray-700"
                />
              </div>
              <div className="flex flex-col gap-3">
                <label
                  className="!m-0 text-sm font-medium text-gray-500"
                  htmlFor="price"
                >
                  Price*
                </label>
                <TypedFormikInput
                  placeholder="Enter the price inclusive of sales tax"
                  autoComplete="off"
                  data-testid="price"
                  name="price"
                  type="number"
                  formikProps={formik}
                  className="!p-3 text-gray-700"
                  {...onlyNumbersProps}
                />
              </div>
              <div className="flex flex-col gap-3">
                <label
                  className="!m-0 text-sm font-medium text-gray-500"
                  htmlFor="purchase-price"
                >
                  Wholesale Price*{" "}
                  <FontAwesomeIcon
                    data-tooltip-id="wholesale-price-tooltip"
                    data-tooltip-content="This is the price you paid for the product and use to calculate gross profit margins."
                    className="cursor-pointer"
                    icon={faQuestionCircle}
                  />
                  <Tooltip
                    className="!bg-primary-700 flex max-w-sm flex-col items-center rounded-lg text-center text-xs"
                    id="wholesale-price-tooltip"
                  />
                </label>
                <TypedFormikInput
                  placeholder="Enter the wholesale price"
                  autoComplete="off"
                  data-testid="purchase-price"
                  name="purchase-price"
                  type="number"
                  formikProps={formik}
                  className="!p-3 text-gray-700"
                  {...onlyNumbersProps}
                />
              </div>
              <div className="flex flex-col gap-3">
                <label
                  className="!m-0 text-sm font-medium text-gray-500"
                  htmlFor="period"
                >
                  Category*
                </label>
                <div className="flex flex-row gap-x-2">
                  <PaginatedSelect
                    isSearchable
                    className="w-full"
                    loadOptions={async () => ({
                      options:
                        categories.data?.content.map((e) => ({
                          label: e.name,
                          value: e.id,
                        })) ?? [],
                    })}
                    onChange={({ value }) => {
                      formik.setFieldValue("product-category-id", value);
                    }}
                    value={categories.data?.content
                      ?.map((e) => ({
                        label: e.name,
                        value: e.id,
                      }))
                      .find((d) => {
                        return d.value === formik.values["product-category-id"];
                      })}
                  />
                </div>
                <label className="text-error-500">
                  {formik.touched["product-category-id"] &&
                    formik.errors["product-category-id"]}
                </label>
              </div>
              <div className="flex flex-row items-center justify-between">
                <label
                  className="!m-0 flex flex-row items-center gap-x-2 text-sm font-medium text-gray-500"
                  htmlFor="allow-app-purchases"
                >
                  App Purchases
                  <FontAwesomeIcon
                    data-tooltip-id="app-purchases-tooltip"
                    data-tooltip-content="This product will be made available for purchase in the members app."
                    className="cursor-pointer"
                    icon={faQuestionCircle}
                  />
                  <Tooltip
                    className="!bg-primary-700 flex max-w-sm flex-col items-center rounded-lg text-center text-xs"
                    id="app-purchases-tooltip"
                  />
                </label>
                <Switch
                  checked={formik.values["allow-app-purchases"]}
                  onChange={(value) => {
                    formik.setFieldValue("allow-app-purchases", value);
                  }}
                />
              </div>
              <div className="flex flex-row items-center justify-between">
                <label
                  className="!m-0 flex flex-row items-center gap-x-2 text-sm font-medium text-gray-500"
                  htmlFor="allow-web-purchases"
                >
                  Website Purchases
                  <FontAwesomeIcon
                    data-tooltip-id="web-purchases-tooltip"
                    data-tooltip-content="This product will be made available for purchase on your website pages."
                    className="cursor-pointer"
                    icon={faQuestionCircle}
                  />
                  <Tooltip
                    className="!bg-primary-700 flex max-w-sm flex-col items-center rounded-lg text-center text-xs"
                    id="web-purchases-tooltip"
                  />
                </label>
                <Switch
                  checked={formik.values["allow-web-purchases"]}
                  onChange={(value) => {
                    formik.setFieldValue("allow-web-purchases", value);
                  }}
                />
              </div>
              {featureFlags?.featureFlags.FE_MEMBERS_ONLY && (
                <div className="flex flex-row items-center justify-between">
                  <label
                    className="!m-0 flex flex-row items-center gap-x-2 text-sm font-medium text-gray-500"
                    htmlFor="for-members-only"
                  >
                    Available For Members Only
                  </label>
                  <Switch
                    checked={formik.values["for-members-only"]}
                    onChange={(value) => {
                      formik.setFieldValue("for-members-only", value);
                    }}
                  />
                </div>
              )}

              <div className="flex flex-row items-center justify-between">
                <label
                  className="!m-0 flex flex-row items-center gap-x-2 text-sm font-medium text-gray-500"
                  htmlFor="stock-type"
                >
                  Track Stock Levels
                  <FontAwesomeIcon
                    data-tooltip-id="track-stock-tooltip"
                    data-tooltip-content="Optionally track stock levels and see when you are running low on products so you can place new orders."
                    className="cursor-pointer"
                    icon={faQuestionCircle}
                  />
                  <Tooltip
                    className="!bg-primary-700 flex max-w-sm flex-col items-center rounded-lg text-center text-xs"
                    id="track-stock-tooltip"
                  />
                </label>
                <Switch
                  checked={formik.values["stock-type"] !== "UNLIMITED"}
                  onChange={(value) => {
                    formik.setFieldValue(
                      "stock-type",
                      value ? "LIMITED" : "UNLIMITED",
                    );
                  }}
                />
              </div>
              {formik.values["stock-type"] === "LIMITED" && (
                <div className="flex flex-col gap-3">
                  <label
                    className="!m-0 text-sm font-medium text-gray-500"
                    htmlFor="stock-quantity"
                  >
                    Stock On Hand
                  </label>
                  <TypedFormikInput
                    placeholder="Enter current stock level e.g 5"
                    autoComplete="off"
                    data-testid="stock-quantity"
                    name="stock-quantity"
                    type="text"
                    formikProps={formik}
                    className="!p-3 text-gray-700"
                    {...onlyNumbersProps}
                  />
                </div>
              )}
            </div>
          </div>
          <div className="flex h-20 w-full flex-row items-center justify-end gap-x-2 border-t border-gray-200 bg-gray-50 px-6 py-4">
            <Button
              className="flex-1"
              onClick={() => {
                hide();
              }}
            >
              Cancel
            </Button>
            <Button
              className="flex-1"
              intent="secondary"
              showSpinner={isLoading}
              disabled={isLoading}
              onClick={() => {
                try {
                  formik.handleSubmit();
                } catch (err) {
                  notifyDanger(err as any);
                }
              }}
            >
              {sidebarState?.currentProduct ? "Update" : "Create"}
            </Button>
          </div>
        </div>
      </SlideSideBar>
    </ProductFormSidebarProviderContext.Provider>
  );
};

const mapper = new FormMapper({
  inValue: [
    {
      key: "picture",
      transform: (picture: string) => ({ url: picture }),
    },
  ],
});
