import { cn, isValidHexColor } from "@gymflow/helpers";
import { useEffect, useState } from "react";
import { SketchPicker } from "react-color";

import { Popover, PopoverContent, PopoverTrigger } from "./Popover";

export function ColorInput({
  value,
  onChange,
  disabled,
}: {
  value: string;
  onChange: (newColor: string) => void;
  disabled?: boolean;
}) {
  const [open, setOpen] = useState(false);
  const [color, setColor] = useState(value);
  useEffect(() => {
    if (isValidHexColor(value)) {
      setColor(value);
    }
  }, [value]);
  const handleChange = (newColor: { hex: string }) => {
    return onChange(newColor.hex.toUpperCase());
  };
  return (
    <Popover
      open={!disabled && open}
      onOpenChange={(newState) => {
        if (disabled) return;
        setOpen(newState);
      }}
    >
      <PopoverTrigger
        asChild
        disabled={disabled}
        className={cn("flex h-10 flex-row gap-1", {
          "cursor-not-allowed": disabled,
        })}
      >
        <div
          className={cn({
            "!cursor-not-allowed": disabled,
          })}
        >
          <div
            className="h-10 w-10 rounded-[0.625rem] border bg-gray-200"
            style={{ backgroundColor: value }}
          />
          <div
            className={cn(
              "flex h-10 flex-1 items-center rounded-[0.625rem] border border-gray-200 px-3 font-medium text-gray-950",
              {
                "bg-gray-50 opacity-50": disabled,
              },
            )}
          >
            {value.toUpperCase()}
          </div>
        </div>
      </PopoverTrigger>
      <PopoverContent className="w-fit">
        <SketchPicker color={color} onChange={handleChange} />
      </PopoverContent>
    </Popover>
  );
}
