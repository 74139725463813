export const ReceiptCheckIcon = ({
  className,
  pathClassName,
}: {
  className?: string;
  pathClassName?: string;
}) => (
  <svg
    width="20"
    height="20"
    viewBox="0 0 20 20"
    fill="none"
    xmlns="http://www.w3.org/2000/svg"
    className={className}
  >
    <path
      d="M7.50004 8.75L9.16671 10.4167L12.9167 6.66667M16.6667 17.5V6.5C16.6667 5.09987 16.6667 4.3998 16.3942 3.86502C16.1545 3.39462 15.7721 3.01217 15.3017 2.77248C14.7669 2.5 14.0668 2.5 12.6667 2.5H7.33337C5.93324 2.5 5.23318 2.5 4.6984 2.77248C4.22799 3.01217 3.84554 3.39462 3.60586 3.86502C3.33337 4.3998 3.33337 5.09987 3.33337 6.5V17.5L5.62504 15.8333L7.70837 17.5L10 15.8333L12.2917 17.5L14.375 15.8333L16.6667 17.5Z"
      stroke="#667085"
      strokeWidth="1.66667"
      strokeLinecap="round"
      strokeLinejoin="round"
      className={pathClassName}
    />
  </svg>
);
