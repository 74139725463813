import { BrandingPatchDTO } from "@gymflow/types";
import { AxiosInstance } from "axios";

export type HostedClubApiType = ReturnType<typeof hostedClubApi>;

const hostedClubApi = (axiosInstance: AxiosInstance, clubId: number) => {
  const clubPrefix = `club/${clubId}/`;
  return {
    updateNew({ fields, logo }: { fields?: BrandingPatchDTO; logo?: any }) {
      const formData = new FormData();
      if (logo) {
        formData.append("file", logo.blob, logo.name);
      }

      if (fields) {
        formData.append(
          "club_hosted_page",
          new Blob([JSON.stringify({ settings: fields })], {
            type: "application/json",
          }),
        );
      }

      return axiosInstance.put(`${clubPrefix}settings`, formData, {
        headers: {
          "Content-Type": "multipart/form-data",
        },
      });
    },
    /*** @deprecated - this function should be removed when redux is removed */
    update(
      id: undefined,
      { fields, logo }: { fields?: BrandingPatchDTO; logo?: any },
    ) {
      const formData = new FormData();
      if (logo) {
        formData.append("file", logo.blob, logo.name);
      }

      if (fields) {
        formData.append(
          "club_hosted_page",
          new Blob([JSON.stringify({ settings: fields })], {
            type: "application/json",
          }),
        );
      }

      return axiosInstance.put(`${clubPrefix}settings`, formData, {
        headers: {
          "Content-Type": "multipart/form-data",
        },
      });
    },
    /*** @deprecated - this function should be removed when redux is removed */
    findById() {
      return axiosInstance.get(`${clubPrefix}hosted/settings/`);
    },
    /*** @deprecated - this function should be removed when redux is removed */
    create(
      id: number,
      { fields, logo }: { fields?: BrandingPatchDTO; logo?: any },
    ) {
      function transformIntoFormData({
        file,
        fields,
      }: {
        fields?: BrandingPatchDTO;
        file?: any;
      }): any {
        const formData = new FormData();
        if (file) {
          formData.append("file", file.blob, file.name);
        }

        if (fields) {
          formData.append(
            "club_hosted_page",
            new Blob([JSON.stringify({ settings: fields })], {
              type: "application/json",
            }),
          );
        }

        return formData;
      }
      const formData = transformIntoFormData({ file: logo, fields });

      return axiosInstance.post(`${clubPrefix}settings`, formData, {
        headers: {
          "Content-Type": "multipart/form-data",
        },
      });
    },
  };
};

export default hostedClubApi;
