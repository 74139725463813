import {
  ApiListResponse,
  DayOfWeek,
  Membership,
  MembershipCalculateCancelDatesResponse,
  MembershipStatusHistoryItem,
  MembershipType,
} from "@gymflow/types";
import { AxiosInstance } from "axios";

import { createSortParam } from "../createSortParam";
import endpoints from "./endpoints";
import { trimFields } from "./features/trimFiels";
import ServiceType from "./ServiceType";

export type ServiceApiType = ReturnType<typeof serviceApi>;

export interface MembershipListFilter {
  readonly extraParams?: {
    readonly status?: "ACTIVE" | "INACTIVE";
    readonly type?: MembershipType;
    readonly name?: string;
    readonly id?: number[];
    readonly [k: string]: any;
  };
  readonly page?: number;
  readonly limit?: number;
  readonly sort?: {
    readonly field: string;
    readonly desc: boolean;
  };
}

const serviceApi = (axiosInstance: AxiosInstance, clubId: number) => {
  const fieldsToTrim = ["name", "currency"];
  const clubPrefix = `club/${clubId}/`;

  return {
    /*** @deprecated - do not use this */
    find({
      page = 0,
      limit = 10,
      extraParams = {},
      sort,
    }: MembershipListFilter) {
      const config = {
        params: {
          page,
          size: limit,
          ...extraParams,
        },
      } as any;
      if (sort) {
        config.params.sort = createSortParam(sort);
      }
      return axiosInstance.get<ApiListResponse<Membership>>(
        `${clubPrefix}membership`,
        config,
      );
    },
    deactivate(id: number) {
      return axiosInstance.patch(`${clubPrefix}membership/${id}/inactive`);
    },
    activate(id: number) {
      return axiosInstance.patch(`${clubPrefix}membership/${id}/active`);
    },
    findById(id: number, proRataStartDate?: string) {
      let config;
      if (proRataStartDate) {
        config = {
          params: {
            startDate: proRataStartDate,
          },
        };
      }
      return axiosInstance.get<Membership>(
        `${clubPrefix}membership/${id}`,
        config,
      );
    },
    /*** @deprecated - do not use this */
    createRecurring(newService: any, termsAndConditionsBlob: any) {
      return create(
        ServiceType.Recurring,
        { ...newService, clubIdList: [clubId] },
        termsAndConditionsBlob,
        fieldsToTrim,
        axiosInstance,
        clubPrefix,
      );
    },
    /*** @deprecated - do not use this */
    createPrePaid(newService: any, termsAndConditionsBlob: any) {
      return create(
        ServiceType.Prepaid,
        { ...newService, clubIdList: [clubId] },
        termsAndConditionsBlob,
        fieldsToTrim,
        axiosInstance,
        clubPrefix,
      );
    },

    update(id: number, { patchedFields }: any) {
      return axiosInstance.patch(
        `${clubPrefix}membership/${id}/v2`,
        patchedFields,
      );
    },

    updateTermsConditions(id: number, { termsAndConditionsBlob }: any) {
      const formData = transformIntoFormData(
        {
          file: termsAndConditionsBlob,
        },
        fieldsToTrim,
      );

      return axiosInstance.patch(
        `${clubPrefix}membership/${id}/terms-conditions`,
        formData,
        {
          headers: {
            "Content-Type": "multipart/form-data",
          },
        },
      );
    },
    /*** @deprecated - do not use this */
    findByClubId(
      clubId: number,
      { page, limit, status, extraParams, type, sort }: any,
    ) {
      function find({
        page = 0,
        limit = 10,
        extraParams = {},
        sort,
      }: MembershipListFilter) {
        const config = {
          params: {
            page,
            size: limit,
            ...extraParams,
          },
        } as any;
        if (sort) {
          config.params.sort = createSortParam(sort);
        }
        return axiosInstance.get<ApiListResponse<Membership>>(
          `${clubPrefix}membership`,
          config,
        );
      }
      return find({
        page,
        limit,
        sort,
        extraParams: {
          type,
          status,
          ...extraParams,
          "clubList.id": clubId,
        },
      });
    },

    summary({
      membershipId,
      startDate,
      promotionCode,
      userMemberId,
    }: {
      membershipId: number;
      startDate: string;
      promotionCode?: string;
      userMemberId: string;
    }) {
      return axiosInstance.post(`${clubPrefix}${endpoints.service}/summary`, {
        membershipId: membershipId,
        startDate,
        promotionCode,
        userMemberId,
      });
    },
    delete(id: number) {
      return axiosInstance.delete(`${clubPrefix}membership/${id}`);
    },
    getStatusHistory(memberId: string) {
      return axiosInstance.get<MembershipStatusHistoryItem[]>(
        `${clubPrefix}user/member/${memberId}/membership-history`,
      );
    },
    calculateCancelDates(
      filter:
        | {
            billingType: "MONTHLY";
            billingPeriod: number;
            monthlyBillingDay: number;
            startDate: string;
          }
        | {
            billingType: "WEEKLY";
            billingPeriod: number;
            weeklyBillingDay: DayOfWeek;
            startDate: string;
          },
    ) {
      return axiosInstance.put<MembershipCalculateCancelDatesResponse>(
        `${clubPrefix}membership/calculate-cancel-dates`,
        filter,
      );
    },
  };
};

export default serviceApi;

/*** @deprecated - do not use this */
function transformIntoFormData({ file, service }: any, fieldsToTrim: string[]) {
  const formData = new FormData();
  const trimmedData = trimFields(service, fieldsToTrim);
  if (file) {
    formData.append("file", file, file.name);
  }

  if (service) {
    formData.append(
      "membership",
      new Blob([JSON.stringify(trimmedData)], {
        type: "application/json",
      }),
    );
  }

  return formData;
}
function create(
  serviceType: any,
  newService: any,
  termsAndConditionsBlob: any,
  fieldsToTrim: string[],
  axiosInstance: AxiosInstance,
  clubPrefix: string,
) {
  const formData = transformIntoFormData(
    {
      file: termsAndConditionsBlob,
      service: newService,
    },
    fieldsToTrim,
  );

  let endpoint;
  switch (serviceType) {
    case ServiceType.Recurring:
      endpoint = `${endpoints.service}/recurring`;
      break;
    case ServiceType.Prepaid:
      endpoint = `${endpoints.service}/prepaid`;
      break;
    default:
      throw new Error("Service type unknown");
  }

  return axiosInstance.post(`${clubPrefix}${endpoint}`, formData, {
    headers: {
      "Content-Type": "multipart/form-data",
    },
  });
}
