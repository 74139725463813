import { ApiType } from "@gymflow/common/lib/api/ApiType";
import { useQuery } from "@tanstack/react-query";

import { leadSourceAsPublicQueryKeys } from "./leadSourceAsPublicQueryKeys";

export async function leadSourceListAsPublicQueryFn({ api }: { api: ApiType }) {
  return (await api.public.leadApi.findLeadSources()).data;
}
export function leadSourceListAsPublicQueryOptions(api: ApiType) {
  return {
    queryKey: leadSourceAsPublicQueryKeys.list(),
    queryFn: () => leadSourceListAsPublicQueryFn({ api }),
  };
}
export function useLeadSourceListAsPublic({ api }: { api: ApiType }) {
  return useQuery(leadSourceListAsPublicQueryOptions(api));
}
