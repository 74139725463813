import { AppointableDTO, AvailabilityCalculationResult } from "@gymflow/types";
import { range } from "lodash";
import { DateTime } from "luxon";

import { pluralize } from "./string";

export function generateDurationOptions({
  intervalsInMinutes,
  hours,
}: {
  intervalsInMinutes: number;
  hours: number;
}) {
  const options: { label: string; value: number }[] = [];
  range(hours + 1).forEach((hour) => {
    if (hour === hours) {
      options.push({
        label: `${hour} ${pluralize("Hour", "Hours", hour)}`,
        value: 60 * hour,
      });
    } else {
      const intervalsPerHour = 60 / intervalsInMinutes;
      for (let m = 0; m < intervalsPerHour; m++) {
        if (hour === 0 && m === 0) {
          continue;
        }
        const minuteString = (m * intervalsInMinutes)
          .toString()
          .padStart(2, "0");
        let label;
        if (minuteString === "00") {
          label = `${hour} ${pluralize("Hour", "Hours", hour)}`;
        } else if (hour === 0) {
          label = `${minuteString} ${pluralize(
            "Minute",
            "Minutes",
            +minuteString,
          )}`;
        } else {
          label = `${hour} ${pluralize(
            "Hour",
            "Hours",
            hour,
          )} ${minuteString} ${pluralize("Minute", "Minutes", +minuteString)}`;
        }
        options.push({
          label,
          value: 60 * hour + m * intervalsInMinutes,
        });
      }
    }
  });
  return options;
}

export const roundToNextQuarterHour = (date: DateTime) => {
  const remainder = date.minute % 15;

  return remainder === 0
    ? date
    : date.plus({ minutes: 15 - remainder }).startOf("minute");
};

export const generateAvailableTimeSlots = (
  availability: AvailabilityCalculationResult[] | undefined,
  timezone: string,
  appointable: AppointableDTO | undefined,
) => {
  if (!availability) return undefined;

  const result = availability
    .map((slot) => {
      const start = roundToNextQuarterHour(
        DateTime.fromISO(slot.startTime).setZone(timezone),
      );
      const end = DateTime.fromISO(slot.endTime)
        .setZone(timezone)
        .minus({
          minutes:
            (appointable?.duration ?? 0) + (appointable?.blockedTime ?? 0),
        });
      const newSlots = [];
      for (
        let currentSlot = start;
        currentSlot <= end;
        currentSlot = currentSlot.plus({ minutes: 15 })
      ) {
        newSlots.push({
          start: currentSlot,
          hosts: slot.availableHostsIdList,
        });
      }
      return newSlots;
    })
    .flatMap((s) => s);

  return {
    morning: result.filter((s) => s.start.hour < 12),
    afternoon: result.filter((s) => s.start.hour >= 12),
  };
};
