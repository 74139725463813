import { formatCurrency } from "@gymflow/helpers";
import { StripeProductItem } from "@gymflow/types";
import { Button, SMSIcon } from "apps/portal/src/components/atoms";
import { useTranslation } from "react-i18next";

type SMSPackageItemProps = StripeProductItem & {
  onClose: () => void;
};

export const SMSPackageItem = ({
  creditsAmount,
  pricePerCredit,
  totalPrice,
  currency,
  onClose,
  url,
}: SMSPackageItemProps) => {
  const { t } = useTranslation();

  return (
    <div className="flex flex-col overflow-hidden rounded-2xl border border-gray-200 shadow-sm">
      <div className="bg-secondary-25 flex items-center gap-2 rounded-t-2xl p-3">
        <SMSIcon className="h-6 w-6" pathClassName="stroke-secondary-700" />
        <div className="text-secondary-700 text-xl font-bold">
          {t(
            "page.settings.tab.sms.creditsBalanceBlock.chooseSMSPackageModal.smsPackageItem.sms",
            { creditsAmount },
          )}
        </div>
      </div>
      <div className="flex flex-col gap-4 rounded-b-2xl bg-gray-0 p-4 pt-8">
        <div className="flex flex-col gap-2">
          <div className="flex flex-row justify-between text-sm font-medium text-gray-500">
            <div>
              {t(
                "page.settings.tab.sms.creditsBalanceBlock.chooseSMSPackageModal.smsPackageItem.perOneSMS",
              )}
            </div>
            <div>{formatCurrency(pricePerCredit, currency)}</div>
          </div>
          <div className="flex flex-row justify-between text-lg font-bold text-gray-950">
            <div>
              {t(
                "page.settings.tab.sms.creditsBalanceBlock.chooseSMSPackageModal.smsPackageItem.totalPrice",
              )}
            </div>
            <div>{formatCurrency(totalPrice, currency)}</div>
          </div>
        </div>
        <Button
          intent="secondary-outline"
          onClick={() => {
            window.open(url, "_blank");
            onClose();
          }}
        >
          {t(
            "page.settings.tab.sms.creditsBalanceBlock.chooseSMSPackageModal.smsPackageItem.purchase",
          )}
        </Button>
      </div>
    </div>
  );
};
