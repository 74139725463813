import { PublicCreditPackApiType } from "@gymflow/common/lib/api/publicCreditPackApi";
import { useQuery } from "@tanstack/react-query";

import { creditPackAsPublicQueryKeys } from "./creditPackAsPublicQueryKeys";

export function useQueryCreditPackAsPublic({
  api,
  creditPackId,
}: {
  api: {
    public: {
      creditPackApi: PublicCreditPackApiType;
    };
  };
  creditPackId?: Parameters<PublicCreditPackApiType["getById"]>[0];
}) {
  const result = useQuery({
    queryKey: creditPackAsPublicQueryKeys.details(creditPackId),
    queryFn: async () => {
      const result = await api.public.creditPackApi.getById(creditPackId!);
      return result.data;
    },
    enabled: !!creditPackId,
  });

  return result;
}
