import { staffListQueryFn, staffQueryKeys } from "@gymflow/api";
import {
  ApiListResponse,
  UserStaffBean,
  UserStaffTaskDTO,
} from "@gymflow/types";
import { InfiniteData, UseInfiniteQueryOptions } from "@tanstack/react-query";
import useGymflowModels from "apps/portal/src/store";
import { useCallback } from "react";

import { OptionType } from "./components/OptionsList/components/OptionItem";
import {
  SelectInput,
  SelectInputOptionType,
  SelectInputProps,
} from "./SelectInput";

type StaffType = UserStaffBean | UserStaffTaskDTO;

export type StaffSelectInputOptionType = OptionType<StaffType>;

export type StaffSelectInputProps = SelectInputProps<StaffType> 

export const StaffSelectInput = (props: StaffSelectInputProps) => {
  const { api } = useGymflowModels();

  const dataFetchingQuery = useCallback(
    (): UseInfiniteQueryOptions<
      unknown,
      unknown,
      SelectInputOptionType<StaffType>[]
    > => ({
      queryKey: staffQueryKeys.list(),
      queryFn: async ({ pageParam }) =>
        await staffListQueryFn({
          api,
          opts: {
            extraParams: { activeUser: true },
            page: pageParam,
            limit: 50,
          },
        }),
      select: (data) => {
        const { pageParams, pages } = data as InfiniteData<
          ApiListResponse<UserStaffBean>
        >;

        const transformedPages = pages.map((page) =>
          page.content.map((staff) => ({
            id: staff.id,
            label: `${staff.firstName} ${staff.lastName}`,
            value: staff,
            imageUrl: staff.picture,
            subLabel: staff.email,
          })),
        );

        return {
          pageParams,
          pages: transformedPages,
        };
      },
      getNextPageParam: (lastPage) => {
        const typedLastPage = lastPage as ApiListResponse<UserStaffBean>;
        return typedLastPage.last ? undefined : typedLastPage.number + 1;
      },
    }),
    [api],
  );

  return <SelectInput {...props} dataFetchingQuery={dataFetchingQuery} />;
};
