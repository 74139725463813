import { cn } from "@gymflow/helpers";
import { ReactElement, ReactNode } from "react";
import {
  matchPath,
  Redirect,
  Route,
  Switch,
  useHistory,
  useLocation,
} from "react-router-dom";

import { PaginatedSelect } from "../atoms";

function PillTabsLayout({
  tabs,
  moreActions,
  className,
}: {
  moreActions?: ReactNode;
  tabs: {
    invisible?: boolean;
    text: string;
    path: string;
    component: (params: any) => ReactElement;
    props?: any;
  }[];
  className?: string;
}) {
  const history = useHistory();
  const location = useLocation();
  const defaultTab = tabs[0];
  const visibleTabs = tabs.filter((tab) => !tab.invisible);
  const renderRoutes = () =>
    visibleTabs.map((tab) => {
      return (
        <Route path={tab.path} key={tab.text}>
          {(p) => <tab.component {...p} {...tab.props} />}
        </Route>
      );
    });
  return (
    <div
      className={cn(
        "flex h-full max-h-full w-full flex-col gap-y-4",
        className,
      )}
    >
      <div className="hidden flex-row flex-wrap justify-between gap-3 lg:flex">
        <div className="flex-row flex-wrap gap-y-2">
          {visibleTabs.map((tab) => (
            <div
              key={tab.text}
              onClick={() => history.push(tab.path)}
              className={cn(
                "inline-flex cursor-pointer rounded-sm px-4 py-2 text-sm font-semibold text-gray-500 transition-all duration-100 ease-in-out",
                {
                  "bg-primary-100 text-primary-700": matchPath(
                    location.pathname,
                    tab.path,
                  ),
                  "hover:text-primary-700": location.pathname !== tab.path,
                },
              )}
            >
              {tab.text}
            </div>
          ))}
        </div>
        {moreActions}
      </div>
      <div className="flex flex-row flex-wrap justify-between gap-3 gap-x-2 lg:hidden">
        <PaginatedSelect
          className="bg-gray-0 w-full"
          loadOptions={async () => {
            return {
              options: visibleTabs.map((tab) => ({
                label: tab.text,
                value: tab.path,
              })),
            };
          }}
          value={visibleTabs
            .map((tab) => ({
              label: tab.text,
              value: tab.path,
            }))
            .find((tab) => matchPath(location.pathname, tab.value))}
          onChange={(selectedTab) => {
            history.push(selectedTab.value);
          }}
        />
        {moreActions}
      </div>
      <Switch>
        {renderRoutes()}
        <Route>
          <Redirect to={defaultTab.path} />
        </Route>
      </Switch>
    </div>
  );
}

export default PillTabsLayout;
