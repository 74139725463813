import { ApiType } from "@gymflow/common/lib/api/ApiType";
import { useQuery } from "@tanstack/react-query";

import { memberAsMemberQueryKeys } from "./memberAsMemberQueryKeys";

export function useQuerySessionPackUsageAsMember({
  api,
  params,
}: {
  api: ApiType;
  params?: Parameters<ApiType["profileApi"]["sessionPackUsage"]>[0];
  enabled?: boolean;
}) {
  return useQuery({
    queryKey: memberAsMemberQueryKeys.sessionPackUsage(params),
    queryFn: async () => {
      const result = await api.profileApi.sessionPackUsage(params!);
      return result.data;
    },
    enabled: !!params,
  });
}
