import { ApiType } from "@gymflow/common/lib/api/ApiType";
import { useQuery } from "@tanstack/react-query";

import { appointableAsPublicQueryKeys } from "./appointableAsPublicQueryKeys";

export function useQueryAppointableListAsPublic(
  {
    api,
    opts,
  }: {
    api: ApiType;
    opts: Parameters<ApiType["public"]["appointableApi"]["find"]>[0];
  },
  {
    enabled,
  }: {
    enabled?: boolean;
  } = { enabled: true },
) {
  return useQuery({
    queryKey: appointableAsPublicQueryKeys.list(opts),
    queryFn: async () => (await api.public.appointableApi.find(opts)).data,
    enabled,
  });
}
