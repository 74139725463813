import { faClipboardCheck } from "@fortawesome/free-solid-svg-icons";
import { FontAwesomeIcon } from "@fortawesome/react-fontawesome";
import { useClubFeatureFlags } from "@gymflow/api";
import { AlertContext, isMobile } from "@gymflow/common";
import { cn } from "@gymflow/helpers";
import { useClubSettings } from "apps/portal/src/providers";
import useGymflowModels from "apps/portal/src/store";
import { usePostHog } from "posthog-js/react";
import { useContext } from "react";
import { useWindowSize } from "usehooks-ts";

import { copyToClipboard } from "../../../helpers/copyToClipboard";
import { useIframeEmbed } from "../../../hooks/useIframeEmbed";
import { usePortalRoutes } from "../../../hooks/usePortalRoutes";
import { RouteFeature, RouteFeatureMigration } from "../../../routes/feature";
import { RouteLayout } from "../../../routes/layout";
import {
  Button,
  CodeIcon,
  LinkIcon,
  PlusCircleIcon,
  ReactiveButton,
} from "../../atoms";
import LinkGeneratorWizard from "../../Settings/Hosted/LinkGenerator/LinkGeneratorWizard";

export function Links() {
  const settings = useClubSettings();
  const { api } = useGymflowModels();
  const { data: featureFlags } = useClubFeatureFlags({
    api,
    clubId: settings.clubId,
  });
  const { createAbsoluteClubLink } = usePortalRoutes();

  const { setAlert, hide } = useContext(AlertContext);
  const posthog = usePostHog();
  return (
    <div className="grid grid-cols-1 md:grid-cols-8">
      <div className="md:grid-cols-subgrid md:col-span-3 md:grid">
        <div className="md:col-start-1 md:col-end-3">
          <div className="text-base font-semibold">Links & Embeds</div>
          <div className="font-normal text-gray-600">
            Copy links and code to link or embed your pages to your own website.
          </div>
        </div>
      </div>
      <div
        className="bg-gray-0 flex flex-col gap-2 rounded-xl border border-gray-200 p-6 shadow-sm md:col-span-5
        "
      >
        {!featureFlags?.featureFlags.HOSTED_CREDIT_REDESIGN && (
          <>
            <LinkRow
              label="Memberships"
              link={RouteFeature.SiteBuyMembership}
            />
            <LinkRow
              label="Credit Packs"
              link={RouteFeature.SiteBuySessionPack}
            />
          </>
        )}
        {featureFlags?.featureFlags.HOSTED_CREDIT_REDESIGN && (
          <>
            <LinkRow
              label="Memberships"
              link={RouteFeature.SiteBuyMembershipType.replace(
                ":membershipType",
                "all",
              )}
            />
            <LinkRow
              indent
              label="Recurring Memberships"
              link={RouteFeature.SiteBuyMembershipType.replace(
                ":membershipType",
                "recurring",
              )}
            />
            <LinkRow
              indent
              label="Pre-Paid Memberships"
              link={RouteFeature.SiteBuyMembershipType.replace(
                ":membershipType",
                "prepaid",
              )}
            />
            <LinkRow
              indent
              label="Trial Memberships"
              link={RouteFeature.SiteBuyMembershipType.replace(
                ":membershipType",
                "trial",
              )}
            />
            <Separator />
            <LinkRow
              label="Credit Packs"
              link={RouteFeature.SiteBuySessionPackType.replace(
                ":sessionPackType",
                "all",
              )}
            />
            <LinkRow
              indent
              label="Class Packs"
              link={RouteFeature.SiteBuySessionPackType.replace(
                ":sessionPackType",
                "classes",
              )}
            />
            <LinkRow
              indent
              label="Appointment Packs"
              link={RouteFeature.SiteBuySessionPackType.replace(
                ":sessionPackType",
                "appointments",
              )}
            />
          </>
        )}
        <Separator />
        <LinkRow label="Appointments" link={RouteFeature.Appointables} />

        <Separator />
        {!featureFlags?.featureFlags.HOSTED_PAGES_CLASSES_REDESIGN && (
          <LinkRow label="Schedule" link={RouteFeature.SiteTimetable} />
        )}
        {featureFlags?.featureFlags.HOSTED_PAGES_CLASSES_REDESIGN && (
          <>
            <LinkRow
              label="Schedule (Calendar View)"
              link={RouteFeature.SiteTimetableType.replace(
                ":viewType",
                "calendar",
              )}
            />
            <LinkRow
              label="Schedule (List View)"
              link={RouteFeature.SiteTimetableType.replace(":viewType", "list")}
            />
          </>
        )}
        <Separator />
        <LinkRow label="Contact Us" link={RouteFeature.SiteEnquiry} />
        <Separator />
        <LinkRow
          label="Registration"
          link={RouteFeature.SiteGuestRegistration}
        />
        <Separator />
        <LinkRow
          label="Kiosk"
          absoluteLink={createAbsoluteClubLink(
            RouteLayout.Kiosk,
            RouteFeature.KioskCheckIn,
          )}
        />
        <Separator />
        <LinkRow
          label="Account Activation"
          absoluteLink={createAbsoluteClubLink(
            RouteLayout.Migration,
            RouteFeatureMigration.CheckExisting,
          )}
        />

        {!featureFlags?.featureFlags.HOSTED_CREDIT_REDESIGN && (
          <div>
            <div className="text-base font-semibold">Custom Links</div>
            <div className="flex items-center justify-between">
              <div className="text-sm font-normal text-gray-500">
                Build customised links or embeds that only show the options you
                choose to display.
              </div>
              <div>
                <Button
                  intent="secondary"
                  size="small"
                  onClick={() => {
                    posthog.capture("clicked_link_generator_wizard");
                    setAlert(<LinkGeneratorWizard onClose={hide} />);
                  }}
                  className="w-32"
                >
                  <div className="flex items-center gap-1">
                    <PlusCircleIcon pathClassName="stroke-white" />
                    <div>New Link</div>
                  </div>
                </Button>
              </div>
            </div>
          </div>
        )}
      </div>
    </div>
  );
}
const Separator = () => {
  return (
    <div className="flex w-full items-center justify-center">
      <div className="h-px w-full bg-gray-200 dark:bg-gray-800"></div>
    </div>
  );
};
const LinkRow = ({
  label,
  indent,
  ...props
}: {
  label: string;
  indent?: boolean;
} & (
  | {
      absoluteLink: string;
    }
  | {
      link: string;
    }
)) => {
  const windowWidth = useWindowSize({ debounceDelay: 100 }).width;
  const iframeEmbed = useIframeEmbed();
  const { createAbsoluteClubLink } = usePortalRoutes();
  return (
    <div className="flex flex-wrap items-center justify-between gap-1">
      <div
        className={cn("text-sm font-medium flex flex-row gap-1", {
          "text-gray-950": !indent,
          "text-gray-500": indent,
        })}
      >
        {indent && <div className="px-1 text-sm font-thin">●</div>}
        {label}
      </div>
      <div className="flex flex-wrap gap-2">
        <ReactiveButton
          size="small"
          onClick={async () => {
            await copyToClipboard(
              "link" in props
                ? createAbsoluteClubLink(RouteLayout.Link, props.link)
                : props.absoluteLink,
            );
          }}
          reactiveContent={
            !isMobile(windowWidth) ? (
              "Copied"
            ) : (
              <FontAwesomeIcon icon={faClipboardCheck} />
            )
          }
          className="px-12 max-md:px-4"
        >
          <div className="flex items-center gap-1">
            <LinkIcon />
            <div className="max-md:hidden">Link</div>
          </div>
        </ReactiveButton>
        {"link" in props && (
          <ReactiveButton
            size="small"
            onClick={async () => {
              await copyToClipboard(iframeEmbed(props.link));
            }}
            reactiveContent={
              !isMobile(windowWidth) ? (
                "Copied"
              ) : (
                <FontAwesomeIcon icon={faClipboardCheck} />
              )
            }
            className="px-12 max-md:px-4"
          >
            <div className="flex items-center gap-1">
              <CodeIcon />
              <div className="max-md:hidden">Code</div>
            </div>
          </ReactiveButton>
        )}
      </div>
    </div>
  );
};
