import {
  useInfiniteQueryPastMemberBookingList,
  useInfiniteQueryUpcomingMemberBookingList,
} from "@gymflow/api";
import {
  DATE_FORMAT,
  humanizeTimeInterval,
  PARAMETER_DATE_FORMAT_WITHOUT_TZ,
  TIME_FORMAT,
} from "@gymflow/common";
import { MemberBookingDTO } from "@gymflow/types";
import { createColumnHelper } from "@tanstack/react-table";
import moment from "moment-timezone";
import qs from "qs";
import { useMemo, useState } from "react";
import { useHistory, useLocation } from "react-router";

import { usePortalRoutes } from "../../hooks/usePortalRoutes";
import { useClubSettings } from "../../providers";
import { RouteFeature } from "../../routes/feature";
import { RouteLayout } from "../../routes/layout";
import useGymflowModels from "../../store";
import { Badge, PaginatedSelect, TextButton } from "../atoms";
import { InfiniteTable } from "../organisms/InfiniteTable";

export function UserBookings({ userMemberId }: { userMemberId?: string }) {
  const settings = useClubSettings();
  const { api } = useGymflowModels();
  const history = useHistory();
  const { createClubLink } = usePortalRoutes();

  const location = useLocation();
  const [bookingType, setBookingType] = useState<BookingsFilterType>(
    (qs.parse(location.search, { ignoreQueryPrefix: true })[
      "bookingType"
    ] as BookingsFilterType) ?? "UPCOMING",
  );
  const {
    data: upcomingBookings,
    fetchNextPage: fetchNextPageUpcomingBookings,
    isFetching: isFetchingUpcomingBookings,
    isLoading: isLoadingUpcomingBookings,
  } = useInfiniteQueryUpcomingMemberBookingList({
    api,
    tz: settings.timezone,
    opts: {
      memberId: userMemberId,
      limit: 25,
    },
    disabled: bookingType !== "UPCOMING",
  });

  const {
    data: pastBookings,
    fetchNextPage: fetchNextPagePastBookings,
    isFetching: isFetchingPastBookings,
    isLoading: isLoadingPastBookings,
  } = useInfiniteQueryPastMemberBookingList({
    api,
    tz: settings.timezone,
    opts: {
      memberId: userMemberId,
      limit: 25,
    },
    disabled: bookingType !== "PAST",
  });

  const columnHelper = createColumnHelper<MemberBookingDTO>();
  const viewColumn = columnHelper.display({
    id: "link",
    header: "Actions",
    cell: (column) => {
      return (
        <TextButton
          className="text-secondary-500 p-0"
          onClick={() => {
            if ("eventOccurrenceId" in column.row.original) {
              history.push({
                pathname: createClubLink(
                  RouteLayout.Staff,
                  RouteFeature.EventOccurrence.replace(
                    ":eventId",
                    column.row.original.eventOccurrenceId.toString(),
                  ),
                ),
              });
            } else {
              history.push({
                pathname: createClubLink(
                  RouteLayout.Staff,
                  RouteFeature.Calendar,
                ),
                search: qs.stringify({
                  date: moment(
                    column.row.original.startDate,
                    PARAMETER_DATE_FORMAT_WITHOUT_TZ,
                  ).format(DATE_FORMAT),
                  marker: moment(
                    column.row.original.startDate,
                    PARAMETER_DATE_FORMAT_WITHOUT_TZ,
                  ).format("HH:mm"),
                  appointmentId: column.row.original.appointmentId,
                }),
              });
              history.push({
                pathname: createClubLink(
                  RouteLayout.Staff,
                  RouteFeature.Calendar,
                ),
                search: qs.stringify({
                  date: column.row.original.startDate,
                  appointmentId: column.row.original.appointmentId,
                }),
              });
            }
          }}
        >
          View
        </TextButton>
      );
    },
    enableSorting: false,
  });
  const smallSizeColumns = [
    columnHelper.accessor(
      (e) => ({
        startDate: e.startDate,
        endDate: e.endDate,
        bookingName: e.bookingName,
        hostName: e.hostName,
        status:
          "eventOccurrenceId" in e ? e.eventRsvpStatus : e.appointmentStatus,
      }),
      {
        header: "Name",
        cell: (column) => {
          const value = column.getValue();
          const status =
            "eventOccurrenceId" in column.row.original
              ? column.row.original.eventRsvpStatus ?? "CANCELLED"
              : column.row.original.appointmentStatus;
          return (
            <div className="flex flex-col gap-2 pl-3 text-gray-600">
              <div> {value.bookingName}</div>
              <div>{`${moment(
                column.row.original.startDate,
                PARAMETER_DATE_FORMAT_WITHOUT_TZ,
              ).format(settings.date_format)} @ ${moment(
                column.row.original.startDate,
                PARAMETER_DATE_FORMAT_WITHOUT_TZ,
              ).format(TIME_FORMAT)}`}</div>
              <div>
                <Badge intent={rsvpColorMap[status]}>
                  {rsvpStatusFormatMap[status]}
                </Badge>
              </div>
            </div>
          );
        },
        enableSorting: false,
      },
    ),
    viewColumn,
  ];
  const fullSizeColumns = [
    columnHelper.accessor("startDate", {
      header: "Date",
      cell: (column) => {
        return (
          <div className="pl-3 text-gray-600">
            {`${moment(
              column.row.original.startDate,
              PARAMETER_DATE_FORMAT_WITHOUT_TZ,
            ).format(settings.date_format)} @ ${moment(
              column.row.original.startDate,
              PARAMETER_DATE_FORMAT_WITHOUT_TZ,
            ).format(TIME_FORMAT)}`}
          </div>
        );
      },
      enableSorting: false,
    }),
    columnHelper.display({
      header: "Duration",
      cell: (column) => {
        return (
          <div className="text-gray-600">
            {humanizeTimeInterval(
              column.row.original.startDate,
              column.row.original.endDate,
            )}
          </div>
        );
      },
      enableSorting: false,
    }),
    columnHelper.accessor("bookingName", {
      header: "Name",
      cell: (column) => {
        return (
          <div className="text-gray-600">{column.row.original.bookingName}</div>
        );
      },
      enableSorting: false,
    }),

    columnHelper.accessor("hostName", {
      header: "Host",
      cell: (column) => {
        return (
          <div className="text-gray-600">{column.row.original.hostName}</div>
        );
      },
      enableSorting: false,
    }),
    columnHelper.display({
      header: "Booking Status",
      cell: (column) => {
        const status =
          "eventOccurrenceId" in column.row.original
            ? column.row.original.eventRsvpStatus ?? "CANCELLED"
            : column.row.original.appointmentStatus;
        return (
          <Badge intent={rsvpColorMap[status]}>
            {rsvpStatusFormatMap[status]}
          </Badge>
        );
      },
      enableSorting: false,
    }),
    viewColumn,
  ];

  const upcomingFlatPages = useMemo(
    () => upcomingBookings?.pages.flatMap((e) => e.content) ?? [],
    [upcomingBookings?.pages],
  );

  const pastFlatPages = useMemo(
    () => pastBookings?.pages.flatMap((e) => e.content) ?? [],
    [pastBookings?.pages],
  );
  return (
    <div className="relative flex h-full w-full">
      <div className="bg-gray-0 absolute inset-0 flex flex-1 flex-col overflow-y-auto rounded-xl border border-gray-200 shadow">
        <div className="flex items-center justify-between p-5">
          <div>
            <div className="text-lg font-bold">Bookings</div>
          </div>
          <div>
            <div className="flex items-center">
              <PaginatedSelect
                className="!h-8 min-h-0"
                loadOptions={async () => ({
                  options: bookingTypeOptions,
                })}
                value={bookingTypeOptions.find(
                  ({ value }) => bookingType === value,
                )}
                onChange={(v: any) => {
                  setBookingType(v.value);
                  history.push({
                    search: qs.stringify({ bookingType: v.value }),
                  });
                }}
              />
            </div>
          </div>
        </div>

        {bookingType === "UPCOMING" && (
          <>
            <div className="flex h-full w-full lg:hidden">
              <InfiniteTable
                data={upcomingFlatPages}
                columns={smallSizeColumns}
                isFetching={isFetchingUpcomingBookings}
                isLoading={isLoadingUpcomingBookings}
                fetchNextPage={fetchNextPageUpcomingBookings}
                hasMoreData={!!upcomingBookings?.pages.at(-1)?.nextPageToken}
                rowHeight={112}
              />
            </div>
            <div className="hidden h-full w-full lg:flex">
              <InfiniteTable
                data={upcomingFlatPages}
                columns={fullSizeColumns}
                isFetching={isFetchingUpcomingBookings}
                isLoading={isLoadingUpcomingBookings}
                fetchNextPage={fetchNextPageUpcomingBookings}
                hasMoreData={!!upcomingBookings?.pages.at(-1)?.nextPageToken}
              />
            </div>
          </>
        )}
        {bookingType === "PAST" && (
          <>
            <div className="flex h-full w-full lg:hidden">
              <InfiniteTable
                data={pastFlatPages}
                columns={smallSizeColumns}
                isFetching={isFetchingPastBookings}
                isLoading={isLoadingPastBookings}
                fetchNextPage={fetchNextPagePastBookings}
                hasMoreData={!!pastBookings?.pages.at(-1)?.nextPageToken}
                rowHeight={112}
              />
            </div>
            <div className="hidden h-full w-full lg:flex">
              <InfiniteTable
                data={pastFlatPages}
                columns={fullSizeColumns}
                isFetching={isFetchingPastBookings}
                isLoading={isLoadingPastBookings}
                fetchNextPage={fetchNextPagePastBookings}
                hasMoreData={!!pastBookings?.pages.at(-1)?.nextPageToken}
              />
            </div>
          </>
        )}
      </div>
    </div>
  );
}

type BookingsFilterType = "UPCOMING" | "PAST";

const bookingTypeOptions = [
  {
    label: "Upcoming",
    value: "UPCOMING",
  },
  {
    label: "Past",
    value: "PAST",
  },
];

const rsvpColorMap = {
  CONFIRMED: "success",
  WAITING: "primary",
  BOOKED: "primary",
  ATTENDED: "success",
  CANCELLED: "warning",
  LATE_CANCELLED: "warning",
  NO_SHOW: "error",
} as const;
const rsvpStatusFormatMap = {
  CONFIRMED: "Confirmed",
  WAITING: "Waiting list",
  BOOKED: "Booked",
  ATTENDED: "Attended",
  CANCELLED: "Cancellation",
  LATE_CANCELLED: "Late Cancellation",
  NO_SHOW: "No Show",
} as const;
