import { faQuestionCircle } from "@fortawesome/free-regular-svg-icons";
import { faClose } from "@fortawesome/free-solid-svg-icons";
import { FontAwesomeIcon } from "@fortawesome/react-fontawesome";
import {
  clubQueryKeys,
  clubStaleTime,
  useClub,
  useClubFeatureFlags,
  useMembership,
  useMembershipCreate,
  useMutationKisiUpdate,
  useMutationMembershipEdit,
  useMutationMembershipTermsConditions,
  useProductList,
  useQueryKisiGroupList,
  useQueryKisiMemberships,
  useSessionPackList,
} from "@gymflow/api";
import {
  DurationType,
  FormikInput,
  onlyNumbersProps,
  PARAMETER_DATE_FORMAT_WITHOUT_TZ,
  useRecordForm,
} from "@gymflow/common";
import {
  cn,
  formatCurrency,
  LUXON_DATE_FORMAT,
  utcToZonedTime,
} from "@gymflow/helpers";
import { MembershipBean } from "@gymflow/types";
import { useQueryClient } from "@tanstack/react-query";
import { ToastContext } from "apps/portal/src/providers/ToastProvider/context";
import { useFormik } from "formik";
import { DateTime } from "luxon";
import moment from "moment-timezone";
import {
  createContext,
  ReactElement,
  useCallback,
  useContext,
  useEffect,
  useState,
} from "react";
import { Tooltip } from "react-tooltip";
import { toFormikValidate, toFormikValidationSchema } from "zod-formik-adapter";

import { getDefaultsFromZodSchema } from "../../../helpers/zod";
import { useClubSettings } from "../../../providers";
import useGymflowModels from "../../../store";
import { Button, PaginatedSelect, SlideSideBar, Spinner } from "../../atoms";
import { FileInputLabel } from "../../forms/FileInputLabel";
import { CollapsibleSections, DatePicker } from "../../molecules";
import Switch from "../../Switch";
import { ProductFormSidebarProviderContext } from "../Product/ProductFormSidebarProvider";
import { CreditPackFormSidebarProviderContext } from "../Sessions/CreditPackFormSidebarProvider";
import { AddonItem } from "./AddonItem";
import Mapper from "./MembershipFormMapper";
import {
  PrepaidMembershipSchema,
  PrepaidMembershipSchemaKeys,
  PrepaidMembershipType,
} from "./PrepaidMembershipSchema";

const TypedFormikInput = FormikInput as React.FC<{ [key: string]: any }>;
export const PrepaidMembershipFormSidebarProviderContext = createContext<{
  showPrepaidMembershipForm: ({
    currentPrepaidMembership,
    onClose,
  }: {
    currentPrepaidMembership?: MembershipBean;
    onClose?: (newMembership?: MembershipBean) => void;
  }) => void;
}>({} as any);

export const PrepaidMembershipFormSidebarProvider: React.FC<{
  children: ReactElement | ReactElement[];
}> = ({ children }) => {
  const [sidebarState, setSidebarState] = useState<
    | {
        currentMembership?: MembershipBean;
        isVisible: boolean;
        onClose: (newMembership?: MembershipBean) => void;
      }
    | undefined
  >({
    isVisible: false,
    onClose: () => {},
  });
  const { api } = useGymflowModels();
  const settings = useClubSettings();
  const clubId = settings.clubId;
  const { data: club } = useClub(
    { api, clubId: clubId },
    { staleTime: clubStaleTime },
  );
  const { initialValues, getPatchedValues, getValues } = useRecordForm({
    record: sidebarState?.currentMembership ?? {
      "is-trial": false,
      "service-type": "PREPAID",
    },
    fields: getDefaultsFromZodSchema(PrepaidMembershipSchema),
    mapper: new Mapper(),
  });
  const creditPacks = useSessionPackList({
    api,
    opts: { extraParams: { unpaged: true } },
  });
  const creditPackOptions = creditPacks?.data.content.map((e) => ({
    label: e.name,
    value: { type: "creditPack", ...e },
  }));
  const products = useProductList({
    api,
    opts: { extraParams: { unpaged: true } },
  });
  const productOptions =
    products?.data?.content.map((e) => ({
      label: e.name,
      value: { type: "product", ...e },
    })) ?? [];
  const formik = useFormik<PrepaidMembershipType>({
    enableReinitialize: true,
    initialValues,
    onSubmit: async (values) => {
      if (sidebarState?.currentMembership) {
        await onSubmit({
          id: sidebarState?.currentMembership.id,
          patchedFields: getPatchedValues(values),
        });
      } else {
        await onSubmit(getValues(values));
      }
      if (sidebarState) {
        hide();
      }
    },
    validationSchema: toFormikValidationSchema(PrepaidMembershipSchema),
    validate: toFormikValidate(PrepaidMembershipSchema),
  });

  const hide = useCallback(
    (newMembership?: MembershipBean) => {
      sidebarState?.onClose(newMembership);
      formik.resetForm();
      setSidebarState(undefined);
      setOpenSectionIdx(0);
    },
    [formik, sidebarState],
  );
  const changeMembershipEdit = useMutationMembershipEdit({ api });
  const updateTermsConditions = useMutationMembershipTermsConditions({ api });
  const createMembershipEdit = useMembershipCreate({ api });
  const { notifyDanger } = useContext(ToastContext);

  const { data: kisiGroups } = useQueryKisiGroupList(
    { api, clubId },
    {
      enabled: !!club && club.kisiStatus !== "DISABLED",
    },
  );

  const { data: kisiData } = useQueryKisiMemberships(
    { clubId, api },
    {
      enabled: !!club && club.kisiStatus !== "DISABLED",
    },
  );
  const [kisiGroupId, setKisiGroupId] = useState<string>();
  useEffect(() => {
    setKisiGroupId(
      kisiData?.content.find(
        (e) => e.id === sidebarState?.currentMembership?.id,
      )?.kisiGroupId,
    );
  }, [kisiData?.content, sidebarState?.currentMembership?.id]);
  const kisiUpdateMutation = useMutationKisiUpdate({ api });
  const queryClient = useQueryClient();
  const kisiUpdateMutationWithTimeout = useCallback(
    async ({
      clubId,
      membershipId,
      kisiGroupId,
    }: Parameters<typeof kisiUpdateMutation.mutateAsync>[0]) => {
      // This timeout updates club query keys after 1s because the /modify does not respond when the update starts.
      setTimeout(
        () =>
          queryClient.invalidateQueries({
            queryKey: clubQueryKeys.all(),
          }),
        1000,
      );
      await kisiUpdateMutation.mutateAsync({
        clubId,
        membershipId,
        kisiGroupId,
      });
      queryClient.invalidateQueries({
        queryKey: clubQueryKeys.all(),
      });
    },
    [kisiUpdateMutation, queryClient],
  );

  const showCancelDates =
    formik.values["duration-type"] &&
    formik.values["duration-type"] !== "DAILY" &&
    formik.values["duration"];

  const onSubmit = useCallback(
    async (values: any) => {
      try {
        if (sidebarState?.currentMembership) {
          const {
            patchedFields: { termsConditions, fixedStartDate, ...restFields },
          } = values;

          if (fixedStartDate) {
            restFields.fixedStartDate = { date: fixedStartDate };
          } else if (sidebarState.currentMembership.fixedStartDate) {
            restFields.fixedStartDate = { date: null };
          }
          if (Object.keys(restFields).length > 0) {
            await changeMembershipEdit.mutateAsync({
              membershipId: values.id,
              patchedFields: restFields,
            });
          }
          if (termsConditions) {
            await updateTermsConditions.mutateAsync({
              membershipId: values.id,
              patchedFields: { termsConditions },
            });
          }

          const originalKisiGroupId = kisiData?.content.find(
            (e) => e.id === sidebarState?.currentMembership?.id,
          )?.kisiGroupId;
          if (originalKisiGroupId !== kisiGroupId) {
            kisiUpdateMutationWithTimeout({
              clubId,
              membershipId: sidebarState?.currentMembership.id,
              kisiGroupId: kisiGroupId ?? null,
            });
          }
          hide();
        } else {
          const newMembership = await createMembershipEdit.mutateAsync({
            ...values,
            taxRate: club?.defaultTaxRate,
          });
          if (kisiGroupId) {
            kisiUpdateMutationWithTimeout({
              clubId,
              membershipId: newMembership.data.id,
              kisiGroupId: kisiGroupId,
            });
          }
          hide(newMembership.data);
        }
      } catch (e) {
        notifyDanger(e as any);
      }
    },
    [
      sidebarState?.currentMembership,
      kisiData?.content,
      kisiGroupId,
      hide,
      updateTermsConditions,
      changeMembershipEdit,
      kisiUpdateMutationWithTimeout,
      clubId,
      createMembershipEdit,
      club?.defaultTaxRate,
      notifyDanger,
    ],
  );

  const { showProductForm } = useContext(ProductFormSidebarProviderContext);
  const { showCreditPackForm } = useContext(
    CreditPackFormSidebarProviderContext,
  );
  const { data: membership } = useMembership({
    api,
    membershipId: sidebarState?.currentMembership?.id,
  });

  let maxPeriod = 36;
  const periodOptions = new Array(maxPeriod)
    .fill(0)
    .map((_, e) => ({ value: e + 1, label: `${e + 1}` }));

  const fixedStartDateString =
    formik.values["fixed-start-date"] &&
    DateTime.fromISO(formik.values["fixed-start-date"], {
      setZone: true,
    })
      .setZone(settings.timezone)
      .toISO({ includeOffset: false });
  const fixedStartDateJS =
    fixedStartDateString && DateTime.fromISO(fixedStartDateString).toJSDate();

  const { data: featureFlags } = useClubFeatureFlags({
    api,
    clubId: settings.clubId,
  });
  const sections = [
    {
      title: "Details",
      body: (
        <>
          <div className="flex flex-col gap-y-1">
            <label className="!m-0 text-gray-700" htmlFor={"name"}>
              Name*
            </label>
            <TypedFormikInput
              placeholder="Enter a membership name."
              autoComplete="off"
              data-testid={"name"}
              maxLength="128"
              name={"name"}
              type="text"
              formikProps={formik}
              className="!p-3 text-gray-700"
            />
          </div>
          <div className="flex h-32 flex-col gap-y-1">
            <label className="!m-0 text-gray-700" htmlFor={"description"}>
              Description
            </label>
            <TypedFormikInput
              placeholder="Write a nice description for customer to read before purchasing."
              autoComplete="off"
              data-testid={"description"}
              maxLength="500"
              name={"description"}
              id={"description"}
              formikProps={formik}
              type="textarea"
              className="flex !h-full !max-h-full !p-3 !text-base text-gray-700"
            />
          </div>
          <div className="flex flex-row items-center justify-between">
            <label
              className="!m-0 flex flex-row items-center gap-x-2 text-gray-700"
              htmlFor={"is-public"}
            >
              Available Online
              <FontAwesomeIcon
                data-tooltip-id="available-online-tooltip"
                data-tooltip-content="Make the membership available for purchase via your website integration and app."
                className="cursor-pointer"
                icon={faQuestionCircle}
              />
              <Tooltip
                className="!bg-primary-700 flex max-w-sm flex-col items-center rounded-lg text-center text-xs"
                id="available-online-tooltip"
              />
            </label>
            <Switch
              checked={formik.values["is-public"]}
              onChange={(value) => {
                formik.setFieldValue("is-public", value);
              }}
            />
          </div>
          {featureFlags?.featureFlags.FE_MEMBERS_ONLY && (
            <div className="flex flex-row items-center justify-between">
              <label
                className="!m-0 flex flex-row items-center gap-x-2 text-gray-700"
                htmlFor={"for-members-only"}
              >
                Available For Members Only
              </label>
              <Switch
                checked={formik.values["for-members-only"]}
                onChange={(value) => {
                  formik.setFieldValue("for-members-only", value);
                }}
              />
            </div>
          )}
          <div className="flex flex-col">
            <div className="flex flex-row items-center justify-between">
              <label
                className="!m-0 flex flex-row items-center gap-x-2 text-gray-700"
                htmlFor={"terms-conditions"}
              >
                Terms & Conditions*
                <FontAwesomeIcon
                  data-tooltip-id="terms-tooltip"
                  data-tooltip-content="Typically includes your cancellation, refund and booking terms."
                  className="cursor-pointer"
                  icon={faQuestionCircle}
                />
                <Tooltip
                  className="!bg-primary-700 z-10 flex max-w-xs flex-col items-center rounded-lg text-center text-xs"
                  id="terms-tooltip"
                />
              </label>

              <FileInputLabel
                accept="application/pdf"
                value={formik.values["terms-conditions"]}
                onChange={(file) =>
                  formik.setFieldValue("terms-conditions", file)
                }
              />
            </div>
            <label className="text-error-500">
              {formik.touched["terms-conditions"] &&
                (formik.errors["terms-conditions"] as any)}
            </label>
          </div>
        </>
      ),
    },
    ...(!membership?.hasSubscriptions ||
    formik.values["upfront-addons"]?.length > 0
      ? [
          {
            title: "Joining Fees & Inclusions",
            body: (
              <div className="flex flex-col gap-y-2">
                {!membership?.hasSubscriptions && (
                  <>
                    <label
                      className="!m-0 flex flex-row items-center gap-x-2 text-gray-700"
                      htmlFor={"upfront-addons"}
                    >
                      Select Items
                      <FontAwesomeIcon
                        data-tooltip-id="joining-fees-tooltip"
                        data-tooltip-content="Any items added will be charged once at the time of purchase (unless free)."
                        className="cursor-pointer"
                        icon={faQuestionCircle}
                      />
                      <Tooltip
                        className="!bg-primary-700 z-10 flex max-w-xs flex-col items-center rounded-lg text-center text-xs"
                        id="joining-fees-tooltip"
                      />
                    </label>
                    <div className="flex flex-row gap-x-2">
                      <PaginatedSelect
                        className="w-full"
                        isSearchable
                        cacheUniqs={[formik.values["upfront-addons"]]}
                        loadOptions={async (inputValue) => ({
                          options: [
                            ...creditPackOptions.filter(
                              (e) =>
                                !formik.values["upfront-addons"]
                                  .filter((e) => e.type === "creditPack")
                                  ?.some((f) => f.id === e.value.id),
                            ),
                            ...productOptions,
                          ].filter((e) =>
                            e.label
                              .toLowerCase()
                              .includes(inputValue.toLowerCase()),
                          ),
                        })}
                        value={{ label: "Search", value: null }}
                        onChange={({
                          value,
                        }:
                          | (typeof productOptions)[number]
                          | (typeof creditPackOptions)[number]) => {
                          formik.setFieldValue("upfront-addons", [
                            ...(formik.values["upfront-addons"] ?? []),
                            { ...value },
                          ]);
                        }}
                      />
                      <PaginatedSelect
                        placeholder="Create"
                        loadOptions={async () => ({
                          options: [
                            { label: "Credit Pack", value: "Session Packs" },
                            { label: "Product", value: "Product" },
                          ],
                        })}
                        value={{ label: "Create", value: "Create" }}
                        onChange={({ value }) => {
                          if (value === "Session Packs") {
                            setSidebarState((e) => {
                              if (!e) return e;
                              return {
                                ...e,
                                isVisible: false,
                              };
                            });
                            showCreditPackForm({
                              onClose: (newSessionPack) => {
                                if (newSessionPack) {
                                  formik.setFieldValue("upfront-addons", [
                                    ...(formik.values["upfront-addons"] ?? []),
                                    {
                                      type: "creditPack",
                                      id: newSessionPack.id,
                                      name: newSessionPack.name,
                                      price: newSessionPack.price,
                                      sessionsIncluded:
                                        newSessionPack.sessionsIncluded,
                                      sessionsUnlimited:
                                        newSessionPack.sessionsUnlimited,
                                    },
                                  ]);
                                }
                                setSidebarState((e) => {
                                  if (!e) return e;
                                  return {
                                    ...e,
                                    isVisible: true,
                                  };
                                });
                              },
                            });
                          } else {
                            setSidebarState((e) => {
                              if (!e) return e;
                              return {
                                ...e,
                                isVisible: false,
                              };
                            });
                            showProductForm({
                              onClose: (newProduct) => {
                                if (newProduct) {
                                  formik.setFieldValue("upfront-addons", [
                                    ...(formik.values["upfront-addons"] ?? []),
                                    {
                                      type: "product",
                                      id: newProduct.id,
                                      name: newProduct.name,
                                      price: newProduct.price,
                                      quantity: newProduct.stockQuantity,
                                    },
                                  ]);
                                }
                                setSidebarState((e) => {
                                  if (!e) return e;
                                  return {
                                    ...e,
                                    isVisible: true,
                                  };
                                });
                              },
                            });
                          }
                        }}
                      />
                    </div>
                  </>
                )}
                <div>
                  {formik.values["upfront-addons"]?.map(
                    ({ id, name, price }, idx: number) => {
                      return (
                        <AddonItem
                          key={`${id}-${idx}`}
                          name={name}
                          canBeEdited={!membership?.hasSubscriptions}
                          formattedPrice={formatCurrency(
                            price,
                            club?.defaultCurrency ?? "USD",
                          )}
                          onClick={() => {
                            formik.setFieldValue(
                              "upfront-addons",
                              formik.values["upfront-addons"].filter(
                                (_, i) => i !== idx,
                              ),
                            );
                          }}
                        />
                      );
                    },
                  )}
                </div>
                {formik.values["upfront-addons"]?.length > 0 && (
                  <div className="flex flex-row items-center justify-end text-sm font-bold">
                    <div className="flex w-full">Total Joining Fees</div>
                    <div className="flex w-20">
                      {formatCurrency(
                        formik.values["upfront-addons"].reduce(
                          (acc: number, next: { price: number }) =>
                            acc + next.price,
                          0,
                        ),
                        club?.defaultCurrency ?? "USD",
                      )}
                    </div>
                    <div className="flex min-w-[80px] px-4">&nbsp;</div>
                  </div>
                )}
              </div>
            ),
          },
        ]
      : []),
    {
      title: "Pricing",
      body: (
        <>
          <div className="flex flex-col gap-y-2">
            <label className="!m-0 text-gray-700" htmlFor={"default-price"}>
              Price*
            </label>
            <TypedFormikInput
              placeholder="Enter the price"
              autoComplete="off"
              data-testid={"default-price"}
              name={"default-price"}
              type="text"
              formikProps={formik}
              className="flex !h-full !max-h-full !p-3 !text-base text-gray-700"
              {...onlyNumbersProps}
            />
          </div>

          <div className="flex flex-col gap-y-2">
            <label className="!m-0 text-gray-700" htmlFor={"period"}>
              Period*
            </label>
            <div className="flex flex-row gap-x-2">
              <PaginatedSelect
                isSearchable
                className="w-full"
                loadOptions={async () => ({
                  options: periodOptions,
                })}
                onChange={({ value }) => {
                  formik.setFieldValue("duration", value);
                }}
                value={periodOptions.find((d) => {
                  return d.value === formik.values["duration"];
                })}
                cacheUniqs={[maxPeriod]}
              />
              <PaginatedSelect
                isSearchable
                className="w-full"
                loadOptions={async () => ({
                  options: [
                    {
                      label: "Week",
                      value: DurationType.Weekly,
                    },
                    {
                      label: "Month",
                      value: DurationType.Monthly,
                    },
                    {
                      label: "Day",
                      value: DurationType.Daily,
                    },
                  ],
                })}
                onChange={({ value }) => {
                  formik.setFieldValue("duration-type", value);
                }}
                value={[
                  {
                    label: "Week",
                    value: DurationType.Weekly,
                  },
                  {
                    label: "Month",
                    value: DurationType.Monthly,
                  },
                  {
                    label: "Day",
                    value: DurationType.Daily,
                  },
                ].find((d) => {
                  return d.value === formik.values["duration-type"];
                })}
              />
            </div>
            <label className="text-error-500">
              {(formik.touched["duration"] && formik.errors["duration"]) ??
                (formik.touched["duration-type"] &&
                  formik.errors["duration-type"])}
            </label>
          </div>
        </>
      ),
    },
    ...(!!club && club.kisiStatus !== "DISABLED"
      ? [
          {
            title: "Access",
            body: (
              <div className="flex flex-col gap-y-2">
                <label
                  className="!m-0 flex flex-row items-center gap-x-2 text-gray-700"
                  htmlFor={"period"}
                >
                  Grant Access
                  <FontAwesomeIcon
                    data-tooltip-id="grant-access-tooltip"
                    data-tooltip-content="Select an access group and we’ll automatically grant anyone that buys this membership access."
                    className="cursor-pointer"
                    icon={faQuestionCircle}
                  />
                  <Tooltip
                    className="!bg-primary-700 z-10 flex max-w-xs flex-col items-center rounded-lg text-center text-xs"
                    id="grant-access-tooltip"
                  />
                </label>
                <div className="flex flex-row gap-x-2">
                  {club?.kisiStatus !== "ENABLED" && (
                    <>
                      <Spinner className="!h-11 !w-11" /> Kisi is processing an
                      update, you can come back later to update this membership.
                    </>
                  )}
                  {club?.kisiStatus === "ENABLED" && kisiGroups && (
                    <PaginatedSelect
                      isSearchable
                      isClearable
                      className="w-full"
                      loadOptions={async () => {
                        return {
                          options: kisiGroups.map(({ name, id }) => ({
                            label: name,
                            value: id,
                          })),
                        };
                      }}
                      onChange={(newValue) => {
                        setKisiGroupId(newValue?.value);
                      }}
                      value={kisiGroups
                        .map(({ name, id }) => ({
                          label: name,
                          value: id,
                        }))
                        .find((e) => {
                          return e.value === kisiGroupId;
                        })}
                    />
                  )}
                </div>
              </div>
            ),
          },
        ]
      : []),
    ...(formik.values["duration-type"] !== "DAILY"
      ? [
          {
            title: "Fixed Term",
            body: (
              <>
                <div
                  className={cn("text-sm font-medium text-gray-600", {
                    hidden: showCancelDates,
                  })}
                >
                  Before setting a fixed term, the pricing period has to be set
                  up.
                </div>
                <div className="flex flex-col gap-y-1">
                  <label className="!m-0 flex flex-row items-center gap-x-2 text-gray-700">
                    <div>Membership Starts</div>
                    <FontAwesomeIcon
                      data-tooltip-id="membership-starts"
                      data-tooltip-content="All memberships sold will start on this date, once this date has past the membership will not be able to be purchased."
                      className="cursor-pointer"
                      icon={faQuestionCircle}
                    />
                    <Tooltip
                      className="!bg-primary-700 z-10 flex max-w-xs flex-col items-center rounded-lg text-center text-xs"
                      id="membership-starts"
                    />
                  </label>
                  <DatePicker
                    inputClassnames="h-11"
                    disabled={!showCancelDates}
                    modifiers={{
                      disabled: (d) => {
                        return DateTime.fromJSDate(d) < DateTime.now();
                      },
                    }}
                    isClearable
                    label={
                      formik.values["fixed-start-date"]
                        ? moment(
                            utcToZonedTime(
                              formik.values["fixed-start-date"],
                              settings.timezone,
                            ),
                            PARAMETER_DATE_FORMAT_WITHOUT_TZ,
                          ).format(settings.date_format)
                        : "Select Date..."
                    }
                    mode="single"
                    selected={fixedStartDateJS ? fixedStartDateJS : undefined}
                    handleDateSave={(date) => {
                      formik.setFieldTouched("fixed-start-date");
                      if (!date) {
                        formik.setFieldValue("fixed-start-date", undefined);
                        return;
                      }
                      const dateOnly =
                        DateTime.fromJSDate(date).toFormat(LUXON_DATE_FORMAT);
                      const gymDate =
                        DateTime.fromFormat(dateOnly, LUXON_DATE_FORMAT, {
                          zone: settings.timezone,
                        })
                          .toUTC()
                          .set({ millisecond: 0 })
                          .toISO({ suppressMilliseconds: true }) ?? "";
                      formik.setFieldValue("fixed-start-date", gymDate);
                    }}
                  />
                </div>
                <div className="flex flex-col gap-y-1">
                  <label className="text-error-500">
                    {formik.touched["fixed-start-date"] &&
                      (formik.errors["fixed-start-date"] as any)}
                  </label>
                </div>
              </>
            ),
          },
        ]
      : []),
  ];
  const [openSectionIdx, setOpenSectionIdx] = useState(0);

  const showPrepaidMembershipForm = useCallback(
    (params: {
      currentPrepaidMembership?: MembershipBean;
      onClose?: (newMembership?: MembershipBean) => void;
    }) => {
      if (
        params.currentPrepaidMembership?.id !==
          sidebarState?.currentMembership?.id ||
        (!params.currentPrepaidMembership && !sidebarState?.isVisible)
      ) {
        setSidebarState({
          isVisible: true,
          currentMembership: params?.currentPrepaidMembership,
          onClose: (newMembership) => {
            params?.onClose?.(newMembership);
          },
        });
        if (params.currentPrepaidMembership) {
          formik.setValues(params.currentPrepaidMembership as any);
        }
      }
    },
    // eslint-disable-next-line react-hooks/exhaustive-deps
    [sidebarState?.currentMembership?.id, sidebarState?.isVisible],
  );

  return (
    <PrepaidMembershipFormSidebarProviderContext.Provider
      value={{
        showPrepaidMembershipForm,
      }}
    >
      {children}
      <SlideSideBar
        isOpen={!!sidebarState?.isVisible}
        hide={hide}
        className="!w-[32rem] max-w-full"
        isLoading={false}
      >
        <div className="flex h-full max-h-full flex-col overflow-hidden">
          <div className="flex flex-col justify-between border-b border-gray-200 p-8">
            <div className="mb-1 flex flex-row items-center justify-between">
              <div className="text-xl font-semibold text-gray-900">
                {sidebarState?.currentMembership
                  ? "Update Pre-Paid Membership"
                  : "Create Pre-Paid Membership"}
              </div>

              <FontAwesomeIcon
                onClick={() => {
                  hide();
                }}
                className="cursor-pointer text-xl text-gray-600"
                icon={faClose}
              />
            </div>
            <div className="text-sm font-medium text-gray-600">
              {sidebarState?.currentMembership
                ? "Update a membership that bills once and expires automatically at the end of the period."
                : "Create a membership that bills once and expires automatically at the end of the period."}
            </div>
          </div>
          <div className="flex h-full flex-col overflow-y-auto overflow-x-hidden">
            <CollapsibleSections
              sections={sections}
              onChangeSection={(_, sectionIdx) => {
                setOpenSectionIdx(sectionIdx);
              }}
              openSectionIndex={openSectionIdx}
            />
          </div>
          <div className="flex h-20 w-full flex-row items-center justify-end gap-x-2 border-t border-gray-200 bg-gray-50 px-6 py-4">
            <Button
              className="bg-gray-25 flex h-11 w-full cursor-pointer items-center justify-center !rounded-lg border-gray-300 px-4 font-semibold capitalize text-gray-500 ring-1 ring-gray-300 hover:bg-gray-100"
              onClick={() => {
                hide();
              }}
            >
              Cancel
            </Button>
            <Button
              intent="primary"
              className="bg-primary-600 text-gray-25 hover:bg-primary-300 flex h-11 w-full cursor-pointer items-center justify-center rounded-lg px-4 font-semibold capitalize"
              onClick={() => {
                try {
                  const errors = PrepaidMembershipSchema.safeParse(
                    formik.values,
                  );
                  if (!errors.success) {
                    const flatErrors = Object.keys(
                      errors.error.flatten().fieldErrors,
                    );
                    let fieldsPerSection = [
                      ["name", "description", "is-public", "terms-conditions"],
                      ["upfront-addons"],
                      ["default-price", "duration", "duration-type"],
                      ["kisi-group-id"],
                    ] as PrepaidMembershipSchemaKeys[][];
                    for (const [idx, fields] of fieldsPerSection.entries()) {
                      if (fields.some((e) => flatErrors.includes(e))) {
                        setOpenSectionIdx(idx);
                        break;
                      }
                    }
                  }
                  formik.handleSubmit();
                } catch (err) {
                  notifyDanger(err as any);
                }
              }}
            >
              {sidebarState?.currentMembership ? "Update" : "Create"}
            </Button>
          </div>
        </div>
      </SlideSideBar>
    </PrepaidMembershipFormSidebarProviderContext.Provider>
  );
};
