import { cn } from "@gymflow/helpers";
import { Field, FieldProps, FormikProps } from "formik";

import { DatePicker, DatePickerProps } from "../../../molecules/DatePicker";
import { ErrorMessage } from "../..";
import Label from "../../base/Label";
import { formattedDate } from "./utils/formattedDate";

type FormSingleDatePickerProps<T> = {
  name: keyof T;
  label?: string;
  isClearable?: boolean;
  isRequired?: boolean;
  isDisabled?: boolean;
  containerClassName?: string;
} & Pick<
  DatePickerProps<boolean>,
  | "modifiers"
  | "disabled"
  | "hidden"
  | "showQuickNavigation"
  | "hideNavigation"
  | "captionLayout"
>;

export const FormSingleDatePicker = <T,>({
  name,
  label,
  isRequired,
  containerClassName,
  ...props
}: FormSingleDatePickerProps<T>) => (
  <Field name={name}>
    {({
      field,
      meta: { touched, error },
    }: FieldProps<Date, FormikProps<T>>) => (
      <div className={cn("flex flex-col gap-1.5", containerClassName)}>
        <Label label={label} isRequired={isRequired} />
        <DatePicker
          {...props}
          label={formattedDate(field.value)}
          mode="single"
          selected={field.value}
          handleDateSave={(value) =>
            field.onChange({
              target: {
                name,
                value,
              },
            })
          }
        />
        {touched && error && <ErrorMessage text={error} />}
      </div>
    )}
  </Field>
);
