import { cn } from "@gymflow/helpers";
import { cva, VariantProps } from "class-variance-authority";
import { ReactNode } from "react";
import { NavLink as ReactRouterNavLink } from "react-router-dom";

export const navLinkVariants = cva(
  "dark:!text-gray-0 whitespace-nowrap text-center text-sm font-medium !text-gray-950",
  {
    variants: {
      intent: {
        primary: [
          "hover:!text-primary-600 active:!text-primary-600 focus:!text-primary-600",
          "dark:hover:!text-primary-600 dark:active:!text-primary-600 dark:focus:!text-primary-600",
        ],
        secondary: [
          "hover:!text-secondary-600 active:!text-secondary-600 focus:!text-secondary-600",
          "dark:hover:!text-secondary-600 dark:active:!text-secondary-600 dark:focus:!text-secondary-600",
        ],
      },
    },
    defaultVariants: {
      intent: "secondary",
    },
  },
);

export const navLinkActiveVariants = cva("", {
  variants: {
    intent: {
      primary: ["!text-primary-600", "dark:!text-primary-600"],
      secondary: ["!text-secondary-600", "dark:!text-secondary-600"],
    },
  },
  defaultVariants: {
    intent: "secondary",
  },
});

interface NavLinkProps extends VariantProps<typeof navLinkVariants> {
  to: string;
  children: ReactNode;
  className?: string;
}

export function NavLink({
  children,
  className,
  intent,
  ...props
}: NavLinkProps) {
  return (
    <ReactRouterNavLink
      className={cn(navLinkVariants({ intent }), className)}
      activeClassName={navLinkActiveVariants({ intent })}
      to={props.to}
    >
      {children}
    </ReactRouterNavLink>
  );
}
