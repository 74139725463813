import { ApiType } from "@gymflow/common/lib/api/ApiType";
import { useMutation, useQueryClient } from "@tanstack/react-query";

import { ruleQueryKeys } from "./ruleQueryKeys";

export function useMutationRuleGroupEdit({ api }: { api: ApiType }) {
  const queryClient = useQueryClient();

  return useMutation({
    mutationFn: ({
      ruleGroupId,
      patchedFields,
    }: {
      ruleGroupId: Parameters<ApiType["ruleApi"]["editRuleGroup"]>[0];
      patchedFields: Parameters<ApiType["ruleApi"]["editRuleGroup"]>[1];
    }) => api.ruleApi.editRuleGroup(ruleGroupId, patchedFields),
    onSuccess: () => {
      queryClient.invalidateQueries({ queryKey: ruleQueryKeys.all() });
    },
  });
}
