import { faClose } from "@fortawesome/free-solid-svg-icons";
import { FontAwesomeIcon } from "@fortawesome/react-fontawesome";
import {
  useMutationFacilityAvailabilityEdit,
  useMutationFacilityAvailabilityOverrideCreate,
  useQueryFacilityAvailability,
} from "@gymflow/api";
import { FacilityAvailabilitySlot } from "@gymflow/types";
import classNames from "classnames";
import qs from "qs";
import { useContext, useState } from "react";
import { useHistory, useParams } from "react-router";

import { ModalContext } from "../../providers";
import { ToastContext } from "../../providers/ToastProvider/context";
import useGymflowModels from "../../store";
import { Button, PlusCircleIcon, Switch, TabButtonGroup } from "../atoms";
import {
  AvailabilityTable,
  FacilityAvailabilityOverrideFormModal,
  FacilityBlockedTimeTable,
} from "../organisms";

export function FacilityAvailability() {
  const { api } = useGymflowModels();
  const history = useHistory();
  const { facilityId } = useParams<{ facilityId: string }>();
  const { data: facilityAvailability, isFetching } =
    useQueryFacilityAvailability({
      facilityId: !!facilityId ? +facilityId : undefined,
      api,
    });

  const { section } = qs.parse(history.location.search, {
    ignoreQueryPrefix: true,
  });

  const [selectedTabIdx, setSelectedTabIdx] = useState(
    section === "blocked" ? 1 : 0,
  );

  const createOverrideMutation = useMutationFacilityAvailabilityOverrideCreate({
    api,
  });
  const { setModal, hide } = useContext(ModalContext);
  const { notifyDanger } = useContext(ToastContext);
  const editAvailabilityMutation = useMutationFacilityAvailabilityEdit({ api });

  return (
    <div className="bg-gray-0 flex h-screen flex-col justify-between">
      <div className="flex flex-col gap-y-4 p-8">
        <div className="flex flex-row items-center justify-between">
          <div className="flex flex-1 flex-row flex-wrap items-center justify-between">
            <div className="mr-4 flex max-w-[800px] flex-col">
              <div className="text-lg font-semibold">Availability</div>
              <div className="text-sm text-gray-600">
                Manage this facility's regular weekly availability for
                appointments bookings and block time for unavailable times.
              </div>
            </div>
            <div className="flex flex-wrap items-center"></div>
          </div>
          <div className="flex items-center justify-center gap-x-6 pl-4">
            {selectedTabIdx === 1 && (
              <Button
                intent="primary"
                onClick={() => {
                  setModal(
                    <FacilityAvailabilityOverrideFormModal
                      facilityId={+facilityId}
                      onCancel={() => {
                        hide();
                      }}
                      onConfirm={async (newValues) => {
                        try {
                          await createOverrideMutation.mutateAsync({
                            facilityId: +facilityId,
                            newOverride: newValues,
                          });
                        } catch (e) {
                          notifyDanger(e as any);
                        }
                        hide();
                      }}
                      value={null}
                    />,
                  );
                }}
              >
                <div className="flex">
                  <PlusCircleIcon
                    className="mr-2 self-center"
                    pathClassName="stroke-white"
                  />
                  Block time
                </div>
              </Button>
            )}
            <FontAwesomeIcon
              onClick={() => {
                history.goBack();
              }}
              className="cursor-pointer text-xl text-gray-500"
              icon={faClose}
            />
          </div>
        </div>
        <div className="flex justify-between text-sm">
          <div>Available for appointment bookings?</div>
          <div>
            <Switch
              value={facilityAvailability?.availableForAppointments || false}
              disabled={!facilityAvailability}
              onChange={(checked) => {
                editAvailabilityMutation.mutateAsync({
                  facilityId: +facilityId,
                  params: {
                    availableForAppointments: checked,
                    availabilitySlotList:
                      facilityAvailability!.availabilitySlotList,
                  },
                });
              }}
            />
          </div>
        </div>
        <TabButtonGroup
          className={classNames({
            hidden: !facilityAvailability?.availableForAppointments,
          })}
          tabs={[
            {
              title: "Regular Hours",
              onClick: (newTabIdx) => {
                setSelectedTabIdx(newTabIdx);
              },
            },
            {
              title: "Blocked Time",
              onClick: (newTabIdx) => {
                setSelectedTabIdx(newTabIdx);
              },
            },
          ]}
          selectedIdx={selectedTabIdx}
        />
      </div>

      <div
        className={classNames(
          "relative flex h-full max-h-full w-full max-w-full flex-col",
          {
            hidden: !facilityAvailability?.availableForAppointments,
          },
        )}
      >
        {selectedTabIdx === 0 && (
          <AvailabilityTable
            availabilityType="FACILITY"
            value={facilityAvailability || null}
            isLoading={isFetching}
            isDisabled={
              !facilityAvailability?.availableForAppointments || isFetching
            }
            onChange={async (newSlots) => {
              await editAvailabilityMutation.mutateAsync({
                facilityId: +facilityId,
                params: {
                  availableForAppointments: true,
                  availabilitySlotList: newSlots as FacilityAvailabilitySlot[],
                },
              });
            }}
          />
        )}
        {selectedTabIdx === 1 && <FacilityBlockedTimeTable />}
      </div>
    </div>
  );
}
