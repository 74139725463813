import { useAutoAnimate } from "@formkit/auto-animate/react";
import {
  useClubFeatureFlags,
  useMutationRuleGroupEdit,
  useMutationRuleGroupNew,
} from "@gymflow/api";
import { pluralize, range } from "@gymflow/helpers";
import { RuleGroup, RuleType } from "@gymflow/types";
import { useClubSettings } from "apps/portal/src/providers";
import { useFormik } from "formik";
import { forwardRef, useImperativeHandle, useMemo } from "react";
import { useTranslation } from "react-i18next";
import { useHistory, useParams } from "react-router";
import { toFormikValidationSchema } from "zod-formik-adapter";

import useGymflowModels from "../../../store";
import { PaginatedSelect } from "../../atoms";
import {
  MembershipRuleFormType,
  MembershipRuleMapper,
} from "./MembershipRuleMapper";
import { RuleFormWrapper } from "./RuleFormWrapper";
import { RuleMapper } from "./RuleMapper";
import { RulesComponents } from "./RulesComponents";
import { YesNoRadioButtons } from "./YesNoRadioButtons";

export function MembershipRuleForm() {
  const { t } = useTranslation();
  const { ruleGroupId } = useParams<{ ruleGroupId: string }>();
  const isEditing = ruleGroupId !== undefined && ruleGroupId !== "";
  return (
    <RuleFormWrapper
      title={
        isEditing
          ? t("page.rules.membershipForm.headerEdit")
          : t("page.rules.membershipForm.headerNew")
      }
      description={
        isEditing
          ? t("page.rules.membershipForm.explanationEdit")
          : t("page.rules.membershipForm.explanationNew")
      }
      Form={Form}
    />
  );
}

const Form = forwardRef<
  { submitForm: () => Promise<any> },
  | {
      isEditing: true;
      ruleTypeToIdMap: Record<RuleType, number>;
      initialValues: RuleGroup;
    }
  | {
      isEditing: false;
    }
>(function Form(props, ref) {
  const { api } = useGymflowModels();
  const { clubId } = useClubSettings();
  const { data: featureFlags } = useClubFeatureFlags({ clubId, api });
  const initialValues = useMemo(() => {
    return "initialValues" in props
      ? MembershipRuleMapper.mapBEtoFE(
          props.initialValues,
          featureFlags?.featureFlags.PORTAL_NEW_MEMBERSHIP_CHANGE_RULE,
        )
      : MembershipRuleMapper.defaultValues(
          featureFlags?.featureFlags.PORTAL_NEW_MEMBERSHIP_CHANGE_RULE,
        );
  }, [featureFlags?.featureFlags.PORTAL_NEW_MEMBERSHIP_CHANGE_RULE, props]);
  const { ruleGroupId } = useParams<{ ruleGroupId: string }>();
  const history = useHistory();
  const { t } = useTranslation();
  const { mutateAsync: newGroup } = useMutationRuleGroupNew({ api });
  const { mutateAsync: editGroup } = useMutationRuleGroupEdit({ api });

  const formik = useFormik<MembershipRuleFormType>({
    initialValues,
    validateOnBlur: true,
    enableReinitialize: true,
    validationSchema: toFormikValidationSchema(MembershipRuleMapper.schema),
    onSubmit: async (values) => {
      if (props.isEditing) {
        await editGroup({
          ruleGroupId: +ruleGroupId,
          patchedFields: RuleMapper.mapPostToPatch(
            MembershipRuleMapper.mapFEtoBE(
              values,
              featureFlags?.featureFlags.PORTAL_NEW_MEMBERSHIP_CHANGE_RULE,
            ),
            props.ruleTypeToIdMap,
          ),
        });
      } else {
        await newGroup({
          fields: MembershipRuleMapper.mapFEtoBE(
            values,
            featureFlags?.featureFlags.PORTAL_NEW_MEMBERSHIP_CHANGE_RULE,
          ),
        });
      }
      history.goBack();
    },
  });
  const { values, setFieldValue, submitForm } = formik;
  useImperativeHandle(ref, () => ({
    submitForm,
  }));
  const billingOptions = [
    {
      label: "No Notice Period",
      value: 0,
    },
    ...range(1, 64).map((i) => ({
      label: `${i} ${pluralize("Billing Period", "Billing Periods", i)}`,
      value: i,
    })),
  ];
  const [parent] = useAutoAnimate();
  return (
    <>
      <RulesComponents.FormRow
        label={t("page.rules.membershipForm.cancellationLabel")}
      >
        <div className="flex w-full flex-col gap-2" ref={parent}>
          <RulesComponents.CardLabel
            label={t("page.rules.membershipForm.cancellationExplanation")}
          >
            <YesNoRadioButtons
              value={values.cancelMembership.enabled}
              onChange={(newValue) => {
                setFieldValue("cancelMembership.enabled", newValue);
                setFieldValue("cancelMembership.minimumPeriodNotice", 1);
              }}
            />
          </RulesComponents.CardLabel>
          {values.cancelMembership.enabled && (
            <RulesComponents.CardLabel
              label={t("page.rules.membershipForm.noticePeriod")}
            >
              <PaginatedSelect
                className="w-48"
                value={billingOptions.find((e) =>
                  "minimumPeriodNotice" in values.cancelMembership
                    ? e.value === values.cancelMembership.minimumPeriodNotice
                    : true,
                )}
                onChange={(newValue) => {
                  setFieldValue(
                    "cancelMembership.minimumPeriodNotice",
                    newValue.value,
                  );
                }}
                loadOptions={async () => {
                  return {
                    options: billingOptions,
                  };
                }}
              />
            </RulesComponents.CardLabel>
          )}
        </div>
      </RulesComponents.FormRow>
      {featureFlags?.featureFlags.PORTAL_NEW_MEMBERSHIP_CHANGE_RULE &&
        values.changeMembership && (
          <RulesComponents.FormRow
            label={t("page.rules.membershipForm.membershipChangeLabel")}
          >
            <div className="flex w-full flex-col gap-2" ref={parent}>
              <RulesComponents.CardLabel
                label={t("page.rules.membershipForm.changeExplanation")}
              >
                <YesNoRadioButtons
                  value={values.changeMembership.enabled}
                  onChange={(newValue) => {
                    setFieldValue("changeMembership.enabled", newValue);
                    setFieldValue("changeMembership.minimumPeriodNotice", 1);
                  }}
                />
              </RulesComponents.CardLabel>
              {values.changeMembership.enabled && (
                <RulesComponents.CardLabel
                  label={t("page.rules.membershipForm.noticePeriod")}
                >
                  <PaginatedSelect
                    className="w-48"
                    value={billingOptions.find((e) =>
                      values.changeMembership &&
                      "minimumPeriodNotice" in values.changeMembership
                        ? e.value ===
                          values.changeMembership.minimumPeriodNotice
                        : true,
                    )}
                    onChange={(newValue) => {
                      setFieldValue(
                        "changeMembership.minimumPeriodNotice",
                        newValue.value,
                      );
                    }}
                    loadOptions={async () => {
                      return {
                        options: billingOptions,
                      };
                    }}
                  />
                </RulesComponents.CardLabel>
              )}
            </div>
          </RulesComponents.FormRow>
        )}
    </>
  );
});
