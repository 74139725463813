import { useAutoAnimate } from "@formkit/auto-animate/react";
import { useQueryMembershipAsPublic } from "@gymflow/api";
import { cn, formatCurrency, membershipHelper } from "@gymflow/helpers";
import { usePortalRoutes } from "apps/portal/src/hooks";
import {
  useAuthenticatedUser,
  useClubSettings,
} from "apps/portal/src/providers";
import { LoginOverlayContext } from "apps/portal/src/providers/authentication/loginOverlay/context";
import { Duration } from "luxon";
import React, { useContext, useState } from "react";
import { useTranslation } from "react-i18next";
import { useHistory, useParams } from "react-router";

import useGymflowModels from "../../../store";
import {
  Button,
  CheckboxInput,
  CheckIcon,
  CreditsIcon,
  InfinityIcon,
  Spinner,
} from "../../atoms";
import ContractIcon from "../../atoms/icons/ContractIcon";
import { HostedPagesBackHeader } from "../components/HostedPagesBackHeader";
import { HostedPagesCard } from "../components/HostedPagesCard";
import HostedPagesOverviewCard from "../components/HostedPagesOverviewCard";

export type HostedPagesMembershipOverviewProps = {
  onClose?: () => void;
};

export const HostedPagesMembershipOverview: React.FC<
  HostedPagesMembershipOverviewProps
> = ({ onClose }) => {
  const { t } = useTranslation();
  const { isEmbed, isQuickPurchase } = usePortalRoutes();
  const history = useHistory();
  const auth = useAuthenticatedUser();
  const { showSignUpOverlay } = useContext(LoginOverlayContext);
  const { api } = useGymflowModels();
  const settings = useClubSettings();
  const { membershipId } = useParams<{ membershipId: string }>();
  const { data, isLoading } = useQueryMembershipAsPublic({
    api,
    membershipId: parseInt(membershipId),
  });
  const recurringAddons = data?.membershipAddonList?.filter((e) => e.recurring);
  const upfrontAddons = data?.membershipAddonList?.filter((e) => !e.recurring);
  const [termsAgreed, setTermsAgreed] = useState(false);
  const [parent] = useAutoAnimate();
  return (
    <div
      className={cn("track-height flex w-full flex-col overflow-y-auto", {
        "track-height": !isQuickPurchase,
        "h-fit": isEmbed,
        "h-full": !isEmbed,
      })}
    >
      <HostedPagesBackHeader label="Membership" onClose={onClose} />
      <div
        ref={parent}
        className={cn(
          "flex flex-col items-center bg-gray-50 dark:bg-gray-800",
          {
            "h-fit": isEmbed,
            "h-full": !isEmbed,
          },
        )}
      >
        {isLoading && <Spinner />}
        {!isLoading && data && (
          <HostedPagesOverviewCard.Container>
            <div className="flex w-full flex-col items-center gap-4">
              <HostedPagesOverviewCard.Header>
                <HostedPagesOverviewCard.HeaderTitle>
                  {data.name}
                </HostedPagesOverviewCard.HeaderTitle>
                {data.description && (
                  <HostedPagesOverviewCard.HeaderDescription>
                    {data.description}
                  </HostedPagesOverviewCard.HeaderDescription>
                )}
              </HostedPagesOverviewCard.Header>
              {data.type === "RECURRING" && (
                <HostedPagesOverviewCard.InnerCard>
                  <div className="flex flex-row items-center justify-between">
                    <div className="flex flex-row items-center gap-1 text-sm font-medium text-gray-500">
                      <ContractIcon
                        className="h-5 w-5"
                        pathClassName="stroke-gray-500"
                      />
                      {t("page.hostedPagesMembership.contract")}
                    </div>
                    <div className="dark:text-gray-0 font-semibold text-gray-950">
                      {data.duration > 0
                        ? Duration.fromObject({
                            [{
                              DAILY: "days",
                              WEEKLY: "weeks",
                              MONTHLY: "months",
                              NO_CONTRACT: "",
                            }[data.durationType]]: data.duration,
                          }).toHuman({
                            unitDisplay: "long",
                            listStyle: "long",
                          })
                        : t("page.hostedPagesMembership.noContract")}
                    </div>
                  </div>
                  <div className="flex flex-row items-center gap-1 text-sm font-medium text-gray-500">
                    {t("page.hostedPagesMembership.contractInfo")}
                  </div>
                </HostedPagesOverviewCard.InnerCard>
              )}
              {data.type === "PREPAID" && (
                <HostedPagesOverviewCard.InnerCard>
                  <div className="flex flex-row items-center justify-between">
                    <div className="flex flex-row items-center gap-1 text-sm font-medium text-gray-500">
                      <ContractIcon
                        className="h-5 w-5"
                        pathClassName="stroke-gray-500"
                      />
                      {t("page.hostedPagesMembership.membershipContract")}
                    </div>
                    <div className="dark:text-gray-0 font-semibold text-gray-950">
                      {Duration.fromObject({
                        [{
                          DAILY: "days",
                          WEEKLY: "weeks",
                          MONTHLY: "months",
                          NO_CONTRACT: "",
                        }[data.durationType]]: data.duration,
                      }).toHuman({
                        unitDisplay: "long",
                        listStyle: "long",
                      })}
                    </div>
                  </div>
                  <div className="flex flex-row items-center gap-1 text-sm font-medium text-gray-500">
                    {t("page.hostedPagesMembership.prepaidExpires")}
                  </div>
                </HostedPagesOverviewCard.InnerCard>
              )}
              {recurringAddons?.length !== undefined &&
                recurringAddons?.length > 0 && (
                  <HostedPagesOverviewCard.InnerCard>
                    {recurringAddons?.map((addon) => (
                      <div
                        className="dark:text-gray-0 flex flex-row items-center gap-2 font-medium text-gray-950"
                        key={addon.id}
                      >
                        {(addon.credits !== undefined ||
                          addon.sessionsUnlimited) && (
                          <div className="dark:text-gray-0 flex items-center gap-1 font-bold text-gray-950">
                            {addon.credits !== undefined &&
                              !addon.sessionsUnlimited && (
                                <div>{addon.credits}</div>
                              )}
                            {addon.sessionsUnlimited && (
                              <InfinityIcon className="dark:stroke-gray-0 h-4 w-4 stroke-gray-950" />
                            )}
                            <CreditsIcon pathClassName="stroke-secondary-500" />
                          </div>
                        )}
                        {addon.credits === undefined &&
                          !addon.sessionsUnlimited && (
                            <CheckIcon
                              className="h-4 w-4"
                              pathClassName="stroke-secondary-500"
                            />
                          )}
                        {addon.name}
                      </div>
                    ))}
                  </HostedPagesOverviewCard.InnerCard>
                )}
              <div className="flex w-full flex-col gap-1">
                <HostedPagesCard.Price
                  price={
                    data.defaultPrice === 0
                      ? t("common.free")
                      : formatCurrency(
                          data.defaultPrice,
                          settings.defaultCurrency,
                        )
                  }
                  description={membershipHelper.getBillingRecurrence(data)}
                />
                {upfrontAddons?.map((addon) => (
                  <div
                    className="flex flex-row items-center gap-1"
                    key={addon.id}
                  >
                    <div className="dark:text-gray-0 font-semibold text-gray-950">
                      +{formatCurrency(addon.price, settings.defaultCurrency)}
                    </div>
                    <div className="text-xs font-medium text-gray-500">
                      {addon.name}
                    </div>
                  </div>
                ))}
              </div>
            </div>
            <div className="flex w-full flex-col items-center gap-4">
              <CheckboxInput
                containerClassName="flex w-full gap-2"
                onChange={setTermsAgreed}
                checked={termsAgreed}
                label={
                  <div className="text-sm font-semibold">
                    {t("page.hostedPagesMembership.termsAndConditionsP1")}
                    <a
                      href={data.termsConditions}
                      target="_blank"
                      rel="noreferrer"
                      className="text-secondary-400 hover:text-secondary-300 underline hover:no-underline"
                    >
                      {t("page.hostedPagesMembership.termsAndConditionsP2")}
                    </a>
                  </div>
                }
              />

              <Button
                {...(auth.id
                  ? {
                      link: `${document.location.pathname}/checkout`,
                    }
                  : {
                      onClick: async () => {
                        const { isLoggedIn } = await showSignUpOverlay();
                        if (isLoggedIn) {
                          history.push(
                            `${document.location.pathname}/checkout`,
                          );
                        }
                      },
                    })}
                disabled={!termsAgreed}
                intent="secondary"
                className="w-fit px-16"
              >
                {t("common.goToCheckout")}
              </Button>
            </div>
          </HostedPagesOverviewCard.Container>
        )}
      </div>
    </div>
  );
};
