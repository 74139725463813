import { useAutoAnimate } from "@formkit/auto-animate/react";
import { CustomPaymentCategoryDTO, UserMemberBean } from "@gymflow/types";
import { FormikContextType, FormikProvider } from "formik";
import { useCallback } from "react";

import { useClubSettings } from "../../providers";
import {
  FormCustomPaymentCategorySelectInput,
  FormPaymentMethodPicker,
  SelectInputOptionType,
  Tabs,
} from "../atoms";
import { TABS, TabsType } from "./ShopSidebar/ShopSidebarProvider";

export type ShopCheckoutPaymentProps = {
  selectedUser: UserMemberBean;
  formik: FormikContextType<{
    priceOverride: number;
    inPersonPaymentMethod: SelectInputOptionType<CustomPaymentCategoryDTO>;
    paymentMethodId?: string;
  }>;
  setActiveTabId: (value: TabsType) => void;
  activeTabId: TabsType;
};

export const ShopCheckoutPayment = ({
  selectedUser,
  formik,
  setActiveTabId,
  activeTabId,
}: ShopCheckoutPaymentProps) => {
  const { customPaymentsEnabled } = useClubSettings();
  const [parent] = useAutoAnimate();

  // TODO: refactor it (after redesign the sidebar)
  const onPressTab = useCallback(
    (value: TabsType) => {
      setActiveTabId(value);
      formik.setFieldValue("inPersonPaymentMethod", undefined);
    },
    [formik, setActiveTabId],
  );

  return customPaymentsEnabled ? (
    <FormikProvider value={formik}>
      <div className="shadow-xs flex flex-col gap-5 rounded-2xl border border-gray-200 bg-gray-50 p-3">
        <Tabs
          tabs={TABS}
          activeTabId={activeTabId}
          setActiveTabId={(id) => onPressTab(id)}
          className="justify-evenly gap-3 border-b-gray-300 pb-5"
          tabClassName="py-2.5 px-3 rounded-xl max-w-none w-full items-center justify-center bg-gray-0 border border-gray-200"
          activeTabClassName="bg-secondary-25 border border-secondary-700"
          activeTabLabelClassName="text-secondary-700"
        />
        <div ref={parent}>
          {activeTabId === "ONLINE" ? (
            <FormPaymentMethodPicker
              name="paymentMethodId"
              userMember={selectedUser}
              customLabel="Select payment method"
            />
          ) : (
            <FormCustomPaymentCategorySelectInput
              name="inPersonPaymentMethod"
              label="Select payment method"
              labelClassName="text-base"
            />
          )}
        </div>
      </div>
    </FormikProvider>
  ) : (
    <FormPaymentMethodPicker name="paymentMethodId" userMember={selectedUser} />
  );
};
