export type EmailIconProps = {
  className?: string;
  pathClassName?: string;
};

export const EmailIcon = ({ className, pathClassName }: EmailIconProps) => {
  return (
    <svg
      xmlns="http://www.w3.org/2000/svg"
      width="20"
      height="20"
      viewBox="0 0 20 20"
      fill="none"
      className={className}
    >
      <path
        d="M1.66699 5.83337L8.47109 10.5962C9.02207 10.9819 9.29756 11.1748 9.59721 11.2495C9.8619 11.3154 10.1387 11.3154 10.4034 11.2495C10.7031 11.1748 10.9786 10.9819 11.5296 10.5962L18.3337 5.83337M5.66699 16.6667H14.3337C15.7338 16.6667 16.4339 16.6667 16.9686 16.3942C17.439 16.1545 17.8215 15.7721 18.0612 15.3017C18.3337 14.7669 18.3337 14.0668 18.3337 12.6667V7.33337C18.3337 5.93324 18.3337 5.23318 18.0612 4.6984C17.8215 4.22799 17.439 3.84554 16.9686 3.60586C16.4339 3.33337 15.7338 3.33337 14.3337 3.33337H5.66699C4.26686 3.33337 3.5668 3.33337 3.03202 3.60586C2.56161 3.84554 2.17916 4.22799 1.93948 4.6984C1.66699 5.23318 1.66699 5.93324 1.66699 7.33337V12.6667C1.66699 14.0668 1.66699 14.7669 1.93948 15.3017C2.17916 15.7721 2.56161 16.1545 3.03202 16.3942C3.5668 16.6667 4.26686 16.6667 5.66699 16.6667Z"
        stroke="#0C111D"
        strokeWidth="1.66667"
        strokeLinecap="round"
        strokeLinejoin="round"
        className={pathClassName}
      />
    </svg>
  );
};
