import { CustomPaymentCategoryDTO } from "@gymflow/types";
import { useQuery } from "@tanstack/react-query";

import { clubQueryKeys } from "./clubQueryKeys";

type CustomPaymentCategoryProps = {
  api: {
    clubApi: {
      customPaymentCategory: (
        clubId: number,
      ) => Promise<{ data: CustomPaymentCategoryDTO[] }>;
    };
  };
  clubId: number;
};

export const customPaymentCategoryQueryFn = async ({
  api,
  clubId,
}: CustomPaymentCategoryProps) =>
  (await api.clubApi.customPaymentCategory(clubId)).data;

export const useQueryCustomPaymentCategory = ({
  api,
  clubId,
}: CustomPaymentCategoryProps) => {
  const result = useQuery({
    queryKey: clubQueryKeys.customPaymentCategory(clubId),
    queryFn: async () => (await api.clubApi.customPaymentCategory(clubId)).data,
  });

  return result;
};
