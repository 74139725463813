import {
  useQueryCalendarEventOccurrencesAsMember,
  useQueryCalendarEventOccurrencesAsPublic,
} from "@gymflow/api";
import { cn } from "@gymflow/helpers";
import { CalendarEventOccurrenceAsMember } from "@gymflow/types";
import { usePortalRoutes } from "apps/portal/src/hooks";
import { useAuthenticatedUser } from "apps/portal/src/providers";
import useGymflowModels from "apps/portal/src/store";
import { DateTime } from "luxon";
import { AnimatePresence, usePresenceData } from "motion/react";
import * as motion from "motion/react-client";
import { forwardRef, useContext, useMemo, useRef } from "react";
import { useTranslation } from "react-i18next";
import { useResizeObserver } from "usehooks-ts";

import { Button, Spinner } from "../../atoms";
import { EmptyPage } from "../../atoms/EmptyPage";
import HostedPagesClassCard from "./components/HostedPagesClassCard";
import { HostedPagesClassesFilterPopoverProps } from "./components/HostedPagesClassesFilterPopover";
import { WeekPickerContext } from "./components/WeekPicker/WeekPickerContext";

export type HostedPagesClassesDayCalendarProps = {
  filter: HostedPagesClassesFilterPopoverProps["filter"];
  setFilter: (filter: HostedPagesClassesFilterPopoverProps["filter"]) => void;
};

const HostedPagesClassesDayCalendar = ({
  filter,
  setFilter,
}: HostedPagesClassesDayCalendarProps) => {
  const { currentDay, setAmountOfDays, amountOfDays, direction } =
    useContext(WeekPickerContext);
  const { t } = useTranslation();
  const auth = useAuthenticatedUser();
  const { api } = useGymflowModels();
  const queryParams: Parameters<
    typeof useQueryCalendarEventOccurrencesAsMember
  >[0]["filters"] = {
    dateFrom: currentDay.toUTC().toISO({ suppressMilliseconds: true })!,
    dateTo: currentDay.toUTC().plus({ days: amountOfDays }).toISO()!,
    includeWaitingCounts: true,
    includeBookedCounts: true,
    ...filter,
  };
  const publicQuery = useQueryCalendarEventOccurrencesAsPublic({
    api,
    filters: queryParams,
    enabled: !auth.id,
  });
  const customerQuery = useQueryCalendarEventOccurrencesAsMember({
    api,
    filters: queryParams,
    enabled: !!auth.id,
  });
  const { data: calendarClasses, isLoading } = auth.id
    ? customerQuery
    : publicQuery;
  const classesPerDay = useMemo(() => {
    const upcomingClasses = calendarClasses
      ?.flatMap((e) => e.content)
      ?.filter((e) => DateTime.now() < DateTime.fromISO(e.startDate));
    const result: CalendarEventOccurrenceAsMember[][] = new Array(amountOfDays)
      .fill(0)
      .map((e) => []);
    upcomingClasses?.forEach((e) => {
      const indexInDays = Math.floor(
        DateTime.fromISO(e.startDate).diff(currentDay, "days").days,
      );
      result[indexInDays].push(e);
    });
    return result;
  }, [amountOfDays, calendarClasses, currentDay]);
  const hasFiltersApplied =
    filter.activityCategoryId.length > 0 ||
    filter.activityId.length > 0 ||
    filter.eventHostId.length > 0 ||
    filter.facilityId.length > 0;
  const hasClasses = classesPerDay.some((e) => e.length > 0);
  const { isEmbed } = usePortalRoutes();

  const calendarContainerRef = useRef<HTMLDivElement>(null);
  useResizeObserver({
    ref: calendarContainerRef,
    onResize: (size) => {
      if (!size.width) return;
      const newAmountOfDays = Math.floor(size.width / 384) || 1;
      setAmountOfDays(newAmountOfDays);
    },
  });
  return (
    <motion.div
      className={cn(
        "flex p-4 lg:px-28 w-full gap-6 flex-row overflow-y-auto max-h-full h-full",
        {
          "h-full": !isEmbed,
          "items-center justify-center":
            (!isLoading && !hasClasses) || isLoading,
        },
      )}
      ref={calendarContainerRef}
    >
      {isLoading && (
        <div className="flex p-8">
          <Spinner />
        </div>
      )}
      {!isLoading && !hasClasses && (
        <EmptyPage
          textOverride={t("pages.hostedPagesClasses.noClasses")}
          action={
            hasFiltersApplied ? (
              <Button
                onClick={() => {
                  setFilter({
                    activityCategoryId: [],
                    activityId: [],
                    eventHostId: [],
                    facilityId: [],
                  });
                }}
              >
                Clear Filters
              </Button>
            ) : undefined
          }
        />
      )}
      <AnimatePresence mode="popLayout" custom={direction}>
        {hasClasses &&
          classesPerDay?.map((eventOccurrences, i) => (
            <ClassLane
              eventOccurrences={eventOccurrences}
              isLoading={isLoading}
              currentDay={currentDay.plus({ days: i })}
              key={currentDay.plus({ days: i }).toISODate()}
            />
          ))}
      </AnimatePresence>
    </motion.div>
  );
};

const ClassLane = forwardRef(function ClassLane(
  {
    eventOccurrences,
    isLoading,
    currentDay,
  }: {
    eventOccurrences: CalendarEventOccurrenceAsMember[];
    isLoading: boolean;
    currentDay: DateTime;
  },
  ref: React.Ref<HTMLDivElement>,
) {
  const { isEmbed } = usePortalRoutes();
  const direction = usePresenceData();

  return (
    <motion.div
      ref={ref}
      initial={{ opacity: 0, x: direction * 50 }}
      animate={{
        opacity: 1,
        x: 0,
      }}
      exit={{ opacity: 0, x: direction * -50 }}
      layoutId={`calendar-lane-${currentDay.toISODate()}`}
      className={cn(
        "flex min-w-[min(calc(100vw-2rem),24rem)] flex-1 flex-col gap-4 rounded-3xl border border-gray-200 bg-gray-100 py-4 dark:border-gray-800 dark:bg-gray-900 overflow-y-auto",
        {
          "max-h-full": !isEmbed,
        },
      )}
    >
      <div className="flex flex-row items-center justify-between px-4">
        <div className="dark:text-gray-0 text-lg font-semibold text-gray-950">
          {currentDay.toLocaleString({ weekday: "long" })}
        </div>
        <div className="font-medium text-gray-500">
          {currentDay.toLocaleString({
            month: "short",
            day: "numeric",
          })}
        </div>
      </div>
      <div className="track-height-calendar-lane flex min-h-[4rem] flex-col gap-4 overflow-y-auto overflow-x-hidden px-4">
        <AnimatePresence>
          {isLoading && <Spinner />}
          {!isLoading &&
            eventOccurrences.map((e, i) => (
              <motion.div
                key={e.eventOccurrenceId}
                initial={{ opacity: 0, x: 50 * direction }}
                animate={{
                  opacity: 1,
                  x: 0,
                  transition: {
                    delay: 0.1 * (i + 3),
                  },
                }}
              >
                <HostedPagesClassCard calendarEventOccurrence={e} />
              </motion.div>
            ))}
        </AnimatePresence>
      </div>
    </motion.div>
  );
});

export default HostedPagesClassesDayCalendar;
