import { ApiType } from "@gymflow/common/lib/api/ApiType";
import { useQuery, UseQueryOptions } from "@tanstack/react-query";

import { appointmentAsMemberQueryKeys } from "./appointmentAsMemberQueryKeys";

export async function appointmentSummaryAsMemberQueryFn({
  api,
  filter,
}: {
  api: ApiType;
  filter: Parameters<ApiType["customerAppointmentApi"]["summary"]>[0];
}) {
  const result = await api.customerAppointmentApi.summary(filter);
  return result.data;
}

/*** @deprecated - use useQueryAppointmentSummaryAsMember instead */
export function useAppointmentSummaryAsMember(
  {
    api,
    opts,
  }: {
    api: ApiType;
    opts: Parameters<ApiType["customerAppointmentApi"]["summary"]>[0];
  },
  queryOpts?: UseQueryOptions<
    Awaited<ReturnType<ApiType["customerAppointmentApi"]["summary"]>>["data"]
  >,
) {
  const result = useQuery({
    queryKey: appointmentAsMemberQueryKeys.summary(opts),
    queryFn: () => appointmentSummaryAsMemberQueryFn({ api, filter: opts }),
    ...queryOpts,
  });
  return result;
}
