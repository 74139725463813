import { useAutoAnimate } from "@formkit/auto-animate/react";
import {
  useMutationAppointmentCreateAsMember,
  useQueryAppointableAsPublic,
} from "@gymflow/api";
import { cn, formatCurrency } from "@gymflow/helpers";
import { usePortalRoutes, useRedirectUrl } from "apps/portal/src/hooks";
import { useClubSettings } from "apps/portal/src/providers";
import { ToastContext } from "apps/portal/src/providers/ToastProvider/context";
import { RouteFeature } from "apps/portal/src/routes";
import { isAxiosError } from "axios";
import { DateTime, Duration, Interval } from "luxon";
import React, { useContext } from "react";
import { useTranslation } from "react-i18next";
import { useParams } from "react-router";

import useGymflowModels from "../../../store";
import {
  Button,
  CalendarIcon,
  ClockIcon,
  CreditsIcon,
  Spinner,
} from "../../atoms";
import { FacilityAvatar } from "../../molecules/FacilityAvatar";
import { StaffAvatar } from "../../molecules/StaffAvatar";
import { HostedPagesBackHeader } from "../components/HostedPagesBackHeader";
import HostedPagesOverviewCard from "../components/HostedPagesOverviewCard";
import useRedirectIfNotAuthenticated from "../useRedirectIfNotAuthenticated";

export type HostedPagesAppointmentReviewProps = {};

export const HostedPagesAppointmentReview: React.FC<
  HostedPagesAppointmentReviewProps
> = ({}) => {
  const { appointableId, staffOrFacilityId, timestamp } = useParams<{
    appointableId: string;
    staffOrFacilityId: string;
    timestamp: string;
  }>();
  const settings = useClubSettings();
  const startTimestamp = DateTime.fromISO(timestamp).setZone(settings.timezone);
  const { createSiteOrEmbedLink, isEmbed } = usePortalRoutes();
  useRedirectIfNotAuthenticated(
    createSiteOrEmbedLink(RouteFeature.Appointables),
  );
  const { t } = useTranslation();
  const { api } = useGymflowModels();
  const { data: appointable, isLoading } = useQueryAppointableAsPublic({
    api,
    id: parseInt(appointableId),
  });
  const createAppointmentMutation = useMutationAppointmentCreateAsMember({
    api,
  });
  const { toast, notifyDanger } = useContext(ToastContext);
  const { redirectToCustomizedWebsiteOr } = useRedirectUrl();
  const [parent] = useAutoAnimate();
  return (
    <div
      className={cn("track-height flex w-full flex-col overflow-y-auto", {
        "h-fit": isEmbed,
        "h-full": !isEmbed,
      })}
    >
      <HostedPagesBackHeader
        label={t("pages.hostedPagesAppointableOverView.header")}
      />
      <div
        ref={parent}
        className={cn(
          "flex flex-col items-center bg-gray-50 dark:bg-gray-800",
          {
            "h-fit": isEmbed,
            "h-full": !isEmbed,
          },
        )}
      >
        {isLoading && <Spinner />}
        {!isLoading && appointable && (
          <HostedPagesOverviewCard.Container>
            <div className="flex w-full flex-col items-center gap-6">
              <HostedPagesOverviewCard.Header>
                <HostedPagesOverviewCard.HeaderTitle>
                  <div className="flex w-full text-xl font-bold text-gray-950 dark:text-gray-0">
                    {appointable.name}
                  </div>
                  <div className="flex flex-row items-center gap-1">
                    <ClockIcon
                      pathClassName="stroke-gray-500 stroke-1"
                      className="h-5 w-5"
                    />
                    <div className="text-base font-semibold text-gray-950 dark:text-gray-0">
                      {Duration.fromObject({
                        minutes: appointable.duration,
                      })
                        .rescale()
                        .toHuman({
                          unitDisplay: "narrow",
                          listStyle: "short",
                        })}
                    </div>
                  </div>
                </HostedPagesOverviewCard.HeaderTitle>
                {appointable.description && (
                  <HostedPagesOverviewCard.HeaderDescription>
                    {appointable.description}
                  </HostedPagesOverviewCard.HeaderDescription>
                )}
              </HostedPagesOverviewCard.Header>
              <div className="flex w-full flex-col gap-1">
                {appointable.availabilityType === "STAFF" && (
                  <StaffAvatar
                    userStaff={
                      appointable.appointableHosts.find(
                        (e) => e.id === staffOrFacilityId,
                      ) ?? {
                        firstName: "No",
                        lastName: "Preference",
                      }
                    }
                  />
                )}
                {appointable.availabilityType === "FACILITY" && (
                  <FacilityAvatar
                    facility={
                      appointable.appointableFacilities.find(
                        (e) => e.id === Number(staffOrFacilityId),
                      ) ?? {
                        name: "No Preference",
                      }
                    }
                  />
                )}
                <div className="flex flex-row items-center gap-2">
                  <CalendarIcon
                    pathClassName="stroke-gray-500"
                    className="h-5 w-5"
                  />
                  <div className="line-clamp-1 text-sm font-semibold text-gray-950 dark:text-gray-0">
                    {startTimestamp.toLocaleString({
                      weekday: "long",
                      month: "long",
                      day: "numeric",
                      year: "numeric",
                    })}
                  </div>
                </div>
                <div className="flex flex-row items-center gap-2">
                  <ClockIcon
                    pathClassName="stroke-gray-500"
                    className="h-5 w-5"
                  />
                  <div className="line-clamp-1 text-sm font-semibold text-gray-950 dark:text-gray-0">
                    {Interval.fromDateTimes(
                      startTimestamp,
                      startTimestamp.plus({ minutes: appointable.duration }),
                    ).toLocaleString(DateTime.TIME_SIMPLE)}
                  </div>
                </div>
              </div>
              <div
                className="flex w-full border border-gray-300 dark:border-gray-700 "
                style={{
                  borderImage:
                    "repeating-linear-gradient(to right, rgb(203, 213, 225) 0px, rgb(203, 213, 225) 10px, transparent 5px, transparent 15px) 1",
                }}
              />
              <div className="flex w-full flex-row justify-between">
                <div className="text-xl font-bold text-gray-950 dark:text-gray-0">
                  {t("page.hostedPagesAppointableReview.amountDue")}
                </div>
                <div className="flex flex-row items-center justify-start gap-2">
                  {appointable.price !== undefined && (
                    <div className="text-lg font-bold text-gray-950 dark:text-gray-0">
                      {formatCurrency(
                        appointable.price,
                        settings.defaultCurrency,
                      )}
                    </div>
                  )}
                  {appointable.price !== undefined &&
                    appointable.sessionCost !== undefined && (
                      <div className="text-gray-500">
                        {t("pages.hostedPagesAppointables.or")}
                      </div>
                    )}
                  {appointable.sessionCost !== undefined && (
                    <div className="flex flex-row items-center gap-1 text-lg font-bold text-gray-950 dark:text-gray-0">
                      {appointable.sessionCost}
                      <CreditsIcon pathClassName="stroke-secondary-500" />
                    </div>
                  )}
                </div>
              </div>
            </div>
            {(appointable.pricingModel === "FREE" ||
              appointable.price === 0 ||
              !appointable.paymentUpfront) && (
              <Button
                className="w-full"
                onClick={async () => {
                  try {
                    await createAppointmentMutation.mutateAsync({
                      appointableId: appointable.id,
                      startDate:
                        startTimestamp
                          .toUTC()
                          .toISO({ suppressMilliseconds: true }) ?? "",
                      status: "BOOKED",
                      ...(staffOrFacilityId === "no-preference"
                        ? {}
                        : appointable.availabilityType === "STAFF"
                        ? {
                            appointmentHostId: staffOrFacilityId,
                          }
                        : {
                            appointmentFacilityId: Number(staffOrFacilityId),
                          }),
                    });
                    toast({
                      message: t("pages.hostedPagesAppointableReview.booked"),
                      description: t(
                        "pages.hostedPagesCheckout.invoicePaidDescription",
                      ),
                      intent: "success",
                    });
                    setTimeout(() => {
                      redirectToCustomizedWebsiteOr();
                    }, 1500);
                  } catch (e) {
                    if (isAxiosError(e)) {
                      notifyDanger(e);
                    }
                  }
                }}
                intent="secondary"
              >
                {appointable.pricingModel === "FREE"
                  ? t("common.getForFree")
                  : t("page.hostedPagesAppointableReview.confirmBooking")}
              </Button>
            )}
            {!(
              appointable.pricingModel === "FREE" ||
              appointable.price === 0 ||
              !appointable.paymentUpfront
            ) && (
              <Button
                className="w-full"
                link={createSiteOrEmbedLink(
                  RouteFeature.AppointableIdCheckout.replace(
                    ":appointableId",
                    appointableId.toString(),
                  )
                    .replace(":staffOrFacilityId", staffOrFacilityId)
                    .replace(":timestamp", timestamp ?? ""),
                )}
                intent="secondary"
              >
                {t("page.hostedPagesAppointableReview.goToCheckout")}
              </Button>
            )}
          </HostedPagesOverviewCard.Container>
        )}
      </div>
    </div>
  );
};
