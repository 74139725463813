import { useRevenueBySaleTypeReport } from "@gymflow/api";
import { DATE_FORMAT, PARAMETER_DATE_FORMAT_WITHOUT_TZ } from "@gymflow/common";
import { downloadCsv, formatCurrency } from "@gymflow/helpers";
import { RevenueCategory, RevenueReportItemDTO } from "@gymflow/types";
import { createColumnHelper, SortingState } from "@tanstack/react-table";
import moment from "moment-timezone";
import qs from "qs";
import { useCallback, useEffect, useMemo, useState } from "react";
import { useHistory, useLocation } from "react-router";

import { useRevenueBySaleTypeReportCsv } from "../../../../../../libs/api/src/lib/hooks/report";
import { Badge } from "../../../components/atoms";
import { ReportPagination } from "../../../components/molecules";
import { Table } from "../../../components/organisms";
import { Report } from "../../../components/organisms/Report";
import useQueryParam from "../../../hooks/useQueryParam";
import { useClubSettings } from "../../../providers";
import useGymflowModels from "../../../store";
import { PaymentMethodColumn } from "./components/PaymentMethodColumn/PaymentMethodColumn";

export const LineCategory = {
  Membership: "MEMBERSHIP",
  CreditPack: "CREDIT_PACK",
  Product: "PRODUCT",
};

export function RevenueTotal() {
  const history = useHistory();
  const { api } = useGymflowModels();
  const settings = useClubSettings();

  const location = useLocation();
  const searchParams = qs.parse(location.search, { ignoreQueryPrefix: true });
  const [currentPage, setCurrentPage] = useState<number>(0);
  const [pageSize, setPageSize] = useState(25);
  const [currentSort, setCurrentSort] = useState<SortingState>([]);

  const [category] = useQueryParam("category", "string") as [
    RevenueCategory | undefined,
  ];
  const [dates, setDates] = useQueryParam("dates", "string") as any;
  const defaultDates = useMemo(
    () => ({
      startDate: moment().startOf("month").format(DATE_FORMAT),
      endDate: moment().format(DATE_FORMAT),
    }),
    [],
  );
  useEffect(() => {
    if (!dates) {
      setDates(defaultDates);
    }
  }, []);

  const { data, isFetching } = useRevenueBySaleTypeReport({
    api,
    dateFrom: dates?.startDate,
    dateTo: dates?.endDate,
    tz: settings.timezone,
    limit: pageSize,
    page: currentPage,
    category,
  });

  const downloadCsvMutation = useRevenueBySaleTypeReportCsv({
    api,
    tz: settings.timezone,
  });

  const columnHelper = createColumnHelper<RevenueReportItemDTO>();
  const columnsDefinition = [
    columnHelper.accessor("date", {
      cell: (info) => (
        <div className="text-gray-600">
          {moment(info.getValue(), PARAMETER_DATE_FORMAT_WITHOUT_TZ).format(
            settings.date_format,
          )}
        </div>
      ),
      header: () => <div>Invoice Date</div>,
      enableSorting: false,
    }),
    columnHelper.accessor("item", {
      cell: (info) => {
        const text =
          info.getValue() === "LATE_CANCELLATION"
            ? "Late Cancellation"
            : info.getValue();
        return <div className="text-gray-600">{text}</div>;
      },
      header: "Item",
      enableSorting: false,
    }),
    columnHelper.accessor("quantitySold", {
      cell: (info) => {
        return <div className="text-gray-600">{info.getValue()}</div>;
      },
      header: "Quantity Sold",
      enableSorting: false,
    }),
    columnHelper.accessor((row) => row, {
      header: "Payment Method",
      enableSorting: false,
      cell: (row) => <PaymentMethodColumn row={row} />,
    }),
    columnHelper.accessor(
      (row) => {
        return {
          amountIncTax: row.amountIncTax,
          latePayment: row.latePayment,
        };
      },
      {
        cell: (info) => (
          <div className="flex gap-x-2">
            <div className="text-gray-600">
              {formatCurrency(
                info.getValue().amountIncTax,
                settings.defaultCurrency,
              )}
            </div>
            {info.getValue().latePayment && (
              <Badge intent="warning">Late Payment</Badge>
            )}
          </div>
        ),
        header: "Amount Inc Tax",
        footer: () =>
          `Total: ${
            data?.total !== undefined
              ? formatCurrency(data.total, settings.defaultCurrency)
              : ""
          }`,
        enableSorting: false,
      },
    ),
    columnHelper.accessor("taxRate", {
      cell: (info) => {
        return <div className="text-gray-600">{info.getValue()}</div>;
      },
      header: "Tax Rate",
      enableSorting: false,
    }),
    columnHelper.accessor("category", {
      cell: (info) => {
        return (
          <div className="capitalize text-gray-600">
            {info.getValue().toLowerCase().replace("_", " ")}
          </div>
        );
      },
      header: "Category",
      enableSorting: false,
    }),
    columnHelper.accessor("type", {
      cell: (info) => {
        return (
          <div className="capitalize text-gray-600">
            {info.getValue().toLowerCase().replace("_", " ")}
          </div>
        );
      },
      header: "Type",
      enableSorting: false,
    }),
    columnHelper.accessor("soldTo", {
      cell: (info) => {
        return <div className="text-gray-600">{info.getValue()}</div>;
      },
      header: "Purchased By",
      enableSorting: false,
    }),
    columnHelper.accessor("soldBy", {
      cell: (info) => {
        return <div className="text-gray-600">{info.getValue()}</div>;
      },
      header: "Sold By",
      enableSorting: false,
    }),
  ];

  const handleDateRangeChange = useCallback(
    (newDate: any) => {
      if (newDate) {
        setDates({
          startDate: moment(newDate.startDate, DATE_FORMAT).format(
            PARAMETER_DATE_FORMAT_WITHOUT_TZ,
          ),
          endDate: moment(newDate.endDate, DATE_FORMAT)
            .endOf("day")
            .format(PARAMETER_DATE_FORMAT_WITHOUT_TZ),
        });
      }
    },
    [setDates],
  );

  return (
    <Report
      title={
        (
          {
            TOTAL: "Total Revenue",
            MEMBERSHIP: "Membership Revenue",
            CREDIT_PACK: "Credit Pack Revenue",
            PRODUCT: "Product Revenue",
          } as const
        )[category ?? "TOTAL"]
      }
      description={
        (
          {
            TOTAL:
              "List of all successful sales and refunds within the specified date period. Revenue counts when the transaction was made not when it was invoiced, revenue does not include overdue invoices.",
            MEMBERSHIP:
              "List of all successful membership sales and refunds within the specified date period. Revenue counts when the transaction was made not when it was invoiced, revenue does not include overdue invoices.",
            CREDIT_PACK:
              "List of all successful credit pack sales and refunds within the specified date period. Revenue counts when the transaction was made not when it was invoiced, revenue does not include overdue invoices.",
            PRODUCT:
              "List of all successful product sales and refunds within the specified date period. Revenue counts when the transaction was made not when it was invoiced, revenue does not include overdue invoices.",
          } as const
        )[category ?? "TOTAL"]
      }
      onDownloadClick={async () => {
        const data = await downloadCsvMutation.mutateAsync({
          category,
          dateFrom: dates?.startDate,
          dateTo: dates?.endDate,
        });
        downloadCsv(
          data,
          `${
            (
              {
                TOTAL: "TotalRevenue",
                MEMBERSHIP: "MembershipRevenue",
                CREDIT_PACK: "CreditPackRevenue",
                PRODUCT: "ProductRevenue",
              } as const
            )[category ?? "TOTAL"]
          }.csv`,
        );
      }}
      handleDateRangeChange={handleDateRangeChange}
      dateRange={
        dates?.startDate && dates?.endDate
          ? {
              startDate: moment(
                dates.startDate,
                PARAMETER_DATE_FORMAT_WITHOUT_TZ,
              ).format(DATE_FORMAT),
              endDate: moment(dates.endDate, PARAMETER_DATE_FORMAT_WITHOUT_TZ)
                .endOf("day")
                .format(DATE_FORMAT),
            }
          : undefined
      }
      close={() => {
        history.push({
          pathname: searchParams["backRoute"] as string,
          search: qs.stringify({
            dates: {
              startDate: moment(
                dates.startDate,
                PARAMETER_DATE_FORMAT_WITHOUT_TZ,
              ).format(DATE_FORMAT),
              endDate: moment(
                dates.endDate,
                PARAMETER_DATE_FORMAT_WITHOUT_TZ,
              ).format(DATE_FORMAT),
            },
          }),
        });
      }}
      table={
        <>
          <Table
            data={data?.data.content ?? []}
            columns={columnsDefinition}
            pageCount={data?.data.totalPages}
            pageIndex={data?.data.number}
            onSortingChange={setCurrentSort}
            sort={currentSort}
            isFetching={isFetching}
            pageSize={pageSize}
            rowClassName="h-14"
          />
          <div className="border-t-[1px] border-gray-300">
            <ReportPagination
              pageCount={data?.data.totalPages as number}
              currentPage={data?.data.number as number}
              onPageChange={(newPage) => {
                setCurrentPage(newPage);
              }}
              pageSize={pageSize}
              setPageSize={(newValue) => {
                setPageSize(newValue);
                setCurrentPage(0);
              }}
            />
          </div>
        </>
      }
    />
  );
}
