import invert from "lodash/invert";
import moment from "moment-timezone";

import { PARAMETER_DATE_ONLY_FORMAT } from "../api/eventApi";
import FormMapper from "../helpers/FormMapper/FormMapper";

const dictionary = {
  "date-of-birth": "dateBirth",
  address1: "addressLine1",
  address2: "addressLine2",
};

class UserMemberFormMapper extends FormMapper {
  constructor({ dateFormat }) {
    super({
      inKey: invert(dictionary),
      inValue: [
        {
          key: "dateBirth",
          transform: (v) =>
            v && moment(v, PARAMETER_DATE_ONLY_FORMAT, true).format(dateFormat),
        },
      ],
      outKey: dictionary,
      outValue: [
        { key: "email", transform: (v) => v.toLowerCase() },
        {
          key: "date-of-birth",
          transform: (v) =>
            v && moment(v, dateFormat, true).format(PARAMETER_DATE_ONLY_FORMAT),
        },
        { key: "post-code", transform: (v) => v.trim().toUpperCase() },
      ],
    });
  }
}

export default UserMemberFormMapper;
