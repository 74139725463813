import { ApiType } from "@gymflow/common/lib/api/ApiType";
import { Membership } from "@gymflow/types";
import { useQuery, UseQueryOptions } from "@tanstack/react-query";

import { membershipQueryKeys } from "./membershipQueryKeys";

export function useMembership(
  {
    api,
    membershipId,
  }: {
    api: ApiType;
    membershipId?: number;
  },
  opts?: UseQueryOptions<Membership | null>,
) {
  return useQuery({
    queryKey: membershipQueryKeys.details(membershipId),
    queryFn: async () => {
      return (await api.serviceApi.findById(membershipId!)).data;
    },
    enabled: !!membershipId,
    initialData: null,
    ...opts,
  });
}
