import { LUXON_DATE_FORMAT } from "@gymflow/helpers";
import { FormikProvider, useFormik } from "formik";
import { DateTime } from "luxon";
import { useCallback, useMemo } from "react";
import { useTranslation } from "react-i18next";

import {
  Button,
  CloseIcon,
  FormStaffSelectInput,
  SlideSideBar,
} from "../../../atoms";
import { FormSingleDatePicker } from "../../../atoms/form/FormSingleDatePicker/FormSingleDatePicker";
import {
  INITIAL_VALUES_TASK_FILTER_FORM,
  taskFilterFormSchema,
} from "./constants";
import { TaskFilterFormValues, TaskFilterSidebarProps } from "./types";

export const TaskFilterSidebar = ({
  value,
  isVisible,
  onClose,
  onChange,
}: TaskFilterSidebarProps) => {
  const { t } = useTranslation();

  const initialValues: TaskFilterFormValues = useMemo(() => {
    const dueDate = value.dueDate
      ? DateTime.fromISO(value.dueDate).toJSDate()
      : INITIAL_VALUES_TASK_FILTER_FORM.dueDate;

    return {
      dueDate,
      taskOwners:
        value.taskOwners ?? INITIAL_VALUES_TASK_FILTER_FORM.taskOwners,
    };
  }, [value]);

  const formik = useFormik<TaskFilterFormValues>({
    initialValues,
    validationSchema: taskFilterFormSchema,
    enableReinitialize: true,
    onSubmit: ({ dueDate, taskOwners }: TaskFilterFormValues) => {
      const dueDateISO = dueDate
        ? DateTime.fromJSDate(dueDate).toFormat(LUXON_DATE_FORMAT)
        : undefined;

      onChange({ taskOwners, dueDate: dueDateISO });
      handleSidebarClose();
    },
  });

  const handleSidebarClose = useCallback(() => {
    onClose();
    formik.resetForm();
  }, [formik, onClose]);

  return (
    <SlideSideBar isOpen={isVisible} hide={() => handleSidebarClose()}>
      <FormikProvider value={formik}>
        <div className="flex h-full flex-col overflow-hidden">
          <div className="flex-1 overflow-y-auto">
            <div className="flex flex-row justify-between border-b-2 border-gray-200 p-8">
              <div className="flex flex-col gap-1">
                <div className="text-lg font-semibold text-gray-900">
                  {t("page.tasks.taskFilterSidebar.title")}
                </div>
                <div className="text-base font-medium text-gray-600">
                  {t("page.tasks.taskFilterSidebar.subTitle")}
                </div>
              </div>
              <div
                className="cursor-pointer p-2.5"
                onClick={() => handleSidebarClose()}
              >
                <CloseIcon />
              </div>
            </div>
            <div className="flex flex-col gap-4 border-b border-gray-200 p-4 pb-8">
              <FormSingleDatePicker<TaskFilterFormValues>
                name="dueDate"
                isClearable
                label={t(
                  "page.tasks.taskFilterSidebar.form.dueDate",
                )}
              />
              <FormStaffSelectInput<TaskFilterFormValues>
                name="taskOwners"
                isMulti
                label={t("page.tasks.taskFilterSidebar.form.taskOwners")}
                dropPosition="bottom"
                withImageByDefault
                isClearable
              />
            </div>
          </div>
          <div className="flex flex-row border-t border-gray-200 bg-gray-50 p-4">
            <Button
              intent="default"
              className="w-full"
              onClick={async () => await formik.submitForm()}
            >
              {t("page.tasks.taskFilterSidebar.button.apply")}
            </Button>
          </div>
        </div>
      </FormikProvider>
    </SlideSideBar>
  );
};
