import { hasStaffRole } from "@gymflow/api";
import { cn } from "@gymflow/helpers";
import { Transition } from "@headlessui/react";
import { ChevronDownIcon, ChevronUpIcon } from "@heroicons/react/20/solid";
import classNames from "classnames";
import {
  Fragment,
  ReactNode,
  useContext,
  useEffect,
  useRef,
  useState,
} from "react";
import { NavLink, useLocation, useRouteMatch } from "react-router-dom";

import gymflowLogo from "../../../assets/img/gymflow_sidebar_logo.svg";
import logoutIcon from "../../../assets/img/logout.svg";
import { useAuthenticatedUser } from "../../providers";
import {
  ParentRouteDefinition,
  RouteCollection,
  RouteDefinition,
} from "../../routes";
import { SupportIcon } from "../atoms/icons/SupportIcon";
import { TopNavbarContents } from "../Navbars/TopNavbarContents";
import { ShopSidebarContext } from "../Shop/ShopSidebar/ShopSidebarContext";

const ACTIVE_CLASS = "active bg-primary-600";
export function Sidebar({
  closeSidebar,
  sidebarOpened,
  routes,
  userName,
  userEmail,
  avatar,
}: {
  userName?: string;
  userEmail?: string;
  avatar?: string;
  routes: RouteCollection;
  sidebarOpened: boolean;
  closeSidebar: () => void;
}) {
  const { showShop } = useContext(ShopSidebarContext);
  const { roles, logout } = useAuthenticatedUser();
  const isStaff = hasStaffRole(roles);
  const [openParent, setOpenParent] = useState<string>();

  const sidebarRef = useRef(null);

  const openActiveParentRoute = (routes: RouteCollection) => {
    const found = routes.reduce((acc, route) => {
      if ("children" in route) {
        const activeParent = route.children.find(
          (childRoute) => activeRoute(childRoute.path) !== "",
        );
        if (activeParent) {
          setOpenParent(route.name);
          return true;
        }
      }
      return acc;
    }, false);
    if (!found) {
      setOpenParent(undefined);
    }
  };

  useEffect(() => {
    openActiveParentRoute(routes);
  }, [routes]);

  const match = useRouteMatch();
  const createLinkItem = (prop: RouteDefinition) => {
    const SidebarComponentItem = prop.sidebarComponent;
    return (
      <div
        className={classNames(
          activeRoute(match.url + prop.path),
          "hover:bg-primary-600 mx-2 flex items-center justify-start rounded-[5px] p-2 text-[#fff]",
          prop.menuItemClassName,
        )}
      >
        {prop.icon ? (
          <img className="h-5 w-5" src={prop.icon} alt="" title={prop.name} />
        ) : (
          <div className="h-5 w-5" title={prop.name} />
        )}
        <div className="w-100 ml-2 block font-semibold">
          {SidebarComponentItem ? <SidebarComponentItem /> : prop.name}
        </div>
      </div>
    );
  };

  const createParentItem = (
    parentRoute: ParentRouteDefinition,
    key: number,
  ): ReactNode => {
    const isOpen = openParent === parentRoute.name;

    return (
      <Fragment key={key}>
        <div
          className={classNames("flex flex-col", parentRoute.menuItemClassName)}
        >
          <div
            className="hover:bg-primary-600 text-gray-0 mx-2 flex cursor-pointer items-center justify-start rounded-[5px] p-2"
            onClick={() => {
              if (isOpen) {
                setOpenParent(undefined);
              } else {
                setOpenParent(parentRoute.name);
              }
            }}
          >
            {parentRoute.icon ? (
              <img
                className="h-5 w-5"
                src={parentRoute.icon}
                alt=""
                title={parentRoute.name}
              />
            ) : (
              <div className="h-5 w-5" title={parentRoute.name} />
            )}

            <div className="ml-2 block flex-1 font-semibold">
              {parentRoute.name}
            </div>
            <div className="block">
              {isOpen ? (
                <ChevronUpIcon className="stroke-primary-200 fill-primary-200 h-5 w-5" />
              ) : (
                <ChevronDownIcon className="stroke-primary-200 fill-primary-200 h-5 w-5" />
              )}
            </div>
          </div>

          <Transition
            className="block"
            show={openParent === parentRoute.name}
            enter="transition-all ease-linear duration-75 overflow-hidden"
            enterFrom="opacity-0 -translate-y-6 max-h-0"
            enterTo="opacity-100 translate-y-0"
            leave="transition-all ease-linear duration-75 overflow-hidden"
            leaveFrom="opacity-100 translate-y-0"
            leaveTo="opacity-0 -translate-y-6"
          >
            {createLinks(parentRoute.children)}
          </Transition>
        </div>
      </Fragment>
    );
  };

  // this function creates the links and collapses that appear in the sidebar (left menu)
  const createLinks = (routes: RouteCollection): ReactNode => {
    return routes.map((prop, key) => {
      if ("children" in prop) {
        return createParentItem(prop, key);
      }
      return (
        <li key={key}>
          <NavLink
            to={match.url + prop.path}
            onClick={(event) => {
              if (prop.isShowShopSidebar) {
                showShop();
                event.preventDefault();
                return;
              }
              closeSidebar();
            }}
          >
            {createLinkItem(prop)}
          </NavLink>
        </li>
      );
    });
  };

  const location = useLocation();
  const activeRoute = (routePath: string) => {
    return location.pathname.indexOf(routePath) > -1 ? ACTIVE_CLASS : "";
  };

  return (
    <div className="bg-primary-700 flex w-0 transition-all duration-500 ease-in-out lg:w-[280px]">
      <div
        className={classNames(
          "bg-primary-700 absolute bottom-0 left-0 top-14 z-[2000] transition-all duration-500 ease-in-out lg:top-0 lg:w-[280px]",
          {
            "w-0": !sidebarOpened,
            "w-full": sidebarOpened,
          },
        )}
      >
        <div
          className="flex h-full max-h-full min-w-full flex-col justify-between overflow-y-auto overflow-x-hidden"
          ref={sidebarRef}
        >
          <div className="flex flex-col">
            <div className="hidden h-14 w-full items-center justify-start px-4 lg:flex">
              <img
                src={gymflowLogo}
                className="h-[40px] w-[160px]"
                alt="Gymflow Logo"
              />
            </div>
            {isStaff && (
              <div className="bg-gray-0 mb-4 flex h-14 w-full flex-row lg:hidden">
                <TopNavbarContents navigationIconOnly />
              </div>
            )}
            <ul className="block">
              {createLinks(routes.filter((r) => r.align !== "bottom"))}
            </ul>
          </div>
          <div className="flex flex-col">
            <ul className="mb-4 flex flex-1 flex-col justify-end">
              {isStaff && (
                <a
                  href="https://support.gymflow.io/en/"
                  target="_blank"
                  className="hover:bg-primary-600 mx-2 flex items-center justify-start rounded-[5px] p-2 text-white"
                  rel="noreferrer"
                >
                  <div className="h-5 w-5">
                    <SupportIcon />
                  </div>
                  <div className="w-100 ml-2 block font-semibold text-white">Support</div>
                </a>
              )}
              {createLinks(routes.filter((r) => r.align === "bottom"))}
            </ul>
            <div className="border-t-primary-600 mx-4 border-t border-solid"></div>
            <div className="flex items-center justify-between p-4">
              <div className="flex items-center">
                {avatar && (
                  <div className="align-items-center relative flex h-10 w-10">
                    <div className="cursor-pointer overflow-hidden rounded-[50%]">
                      <img alt="User Profile" src={avatar} id="avatar" />
                    </div>
                  </div>
                )}

                <div
                  className={cn("ml-2 block text-sm", {
                    invisible: !userEmail || !userName,
                  })}
                >
                  <div
                    title={userName}
                    className="text-gray-25 max-w-[180px] overflow-hidden text-ellipsis font-semibold"
                  >
                    {userName}
                  </div>
                  <div
                    title={userEmail}
                    className="text-primary-200 max-w-[180px] overflow-hidden text-ellipsis"
                  >
                    {userEmail}
                  </div>
                </div>
              </div>
              <img
                alt="Logout"
                className="mr-16 cursor-pointer lg:mr-0"
                src={logoutIcon}
                onClick={logout}
              />
            </div>
          </div>
        </div>
      </div>
    </div>
  );
}
