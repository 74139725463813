import { QueryKey } from "@tanstack/react-query";

export const membershipAsMemberQueryKeys = {
  all: () => ["membership-as-member"] as const,
  list: (filters: Readonly<{ [k: string]: any }>) =>
    [...membershipAsMemberQueryKeys.all(), { filters }] as QueryKey,
  getById: (id?: number) =>
    [...membershipAsMemberQueryKeys.all(), id] as QueryKey,
  summary: (params?: Readonly<{ [k: string]: any }>) =>
    [...membershipAsMemberQueryKeys.all(), "summary", params] as QueryKey,
};
