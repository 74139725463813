import { Membership } from "@gymflow/types";
import { z } from "zod";
import { toFormikValidationSchema } from "zod-formik-adapter";

import { ChangeMembershipFormValues } from "./types";

export const INITIAL_VALUES_CHANGE_MEMBERSHIP_FORM: ChangeMembershipFormValues =
  {
    newMembership: undefined,
    isCollectUpgradeCost: true,
    isChangeImmediately: false,
    date: undefined,
  };

export const changeMembershipFormSchema = toFormikValidationSchema(
  z
    .object({
      newMembership: z
        .object(
          {
            id: z.union([z.string(), z.number()]),
            label: z.string(),
            subLabel: z.string().optional(),
            value: z.custom<Membership>(),
          },
          { required_error: "Select Membership is required" },
        )
        .nullable(),
      isCollectUpgradeCost: z.boolean(),
      isChangeImmediately: z.boolean(),
      date: z
        .object({
          id: z.union([z.string(), z.number()]),
          label: z.string(),
          value: z.custom<string>(),
        })
        .nullable()
        .optional(),
    })
    .superRefine((data, ctx) => {
      if (!data.isChangeImmediately && !data.date) {
        ctx.addIssue({
          code: "custom",
          message: "Scheduled Date is required",
          path: ["date"],
        });
      }
    }),
);
