import { ApiType } from "@gymflow/common/lib/api/ApiType";
import { useMutation, useQueryClient } from "@tanstack/react-query";

import { clubAsPublicQueryKeys } from "../clubAsPublic";
import { clubQueryKeys } from "./clubQueryKeys";

export function useMutationClubEditSettings({ api }: { api: ApiType }) {
  const queryClient = useQueryClient();

  const mutation = useMutation({
    mutationFn: (
      params: Parameters<ApiType["hostedClubApi"]["updateNew"]>[0],
    ) => api.hostedClubApi.updateNew(params),
    onSuccess: () => {
      queryClient.invalidateQueries({ queryKey: clubQueryKeys.all() });
      queryClient.invalidateQueries({ queryKey: clubAsPublicQueryKeys.all() });
    },
  });
  return mutation;
}
