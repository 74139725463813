import { useMutationMemberEditAsMemberNew } from "@gymflow/api";
import {
  getIsDisableEditFieldBecauseOfActiveMembership,
  getPatch,
} from "@gymflow/helpers";
import { Gender, RuleClubDTO, UserMemberBean } from "@gymflow/types";
import {
  Button,
  CloseIcon,
  FormInput,
  FormRadioButtonGroup,
  ImageInputWithModal,
  Trash2Icon,
} from "apps/portal/src/components/atoms";
import { FormSingleDatePicker } from "apps/portal/src/components/atoms/form/FormSingleDatePicker/FormSingleDatePicker";
import { FormikProvider, useFormik } from "formik";
import { DateTime } from "luxon";
import { useCallback, useContext, useMemo } from "react";
import { useTranslation } from "react-i18next";
import { z } from "zod";
import { toFormikValidationSchema } from "zod-formik-adapter";

import {
  ModalContext,
  useAuthenticatedUser,
  useClubSettings,
} from "../../../../../../providers";
import { ToastContext } from "../../../../../../providers/ToastProvider/context";
import useGymflowModels from "../../../../../../store";
import { FormPhoneNumberInput } from "../../../../../atoms/form/FormPhoneNumberInput";
import { DeleteMemberModal } from "../../../../../templates/DeleteMemberModal";
import { generateMemberSchema } from "./MemberSchema";

type MemberSchema = ReturnType<typeof generateMemberSchema>;
type MemberFormValues = z.infer<MemberSchema>;

export type EditMemberFormProps = {
  originalMember: UserMemberBean;
  rules: RuleClubDTO[];
  onClose: () => void;
};

export const EditMemberForm = ({
  originalMember,
  rules,
  onClose,
}: EditMemberFormProps) => {
  const member = useAuthenticatedUser();
  const { t } = useTranslation();
  const settings = useClubSettings();
  const { api } = useGymflowModels();
  const { mutateAsync: updateUser } = useMutationMemberEditAsMemberNew({ api });
  const userFormRule = rules.find(
    (e) => e.ruleType === "USER_FORM_REQUIREMENT_CONFIG",
  )!.userFormRule!;
  const MemberSchema = useMemo(() => {
    return generateMemberSchema({
      rules: userFormRule,
      postcodeCountry: settings.postal_code_country,
    });
  }, [settings, userFormRule]);
  const initialValues = useMemo(() => {
    return {
      ...originalMember,
      dateBirth: originalMember.dateBirth
        ? DateTime.fromISO(originalMember.dateBirth).toJSDate()
        : undefined,
      gender: originalMember.gender ?? "PREFER_NOT_TO_SAY",
      picture: originalMember.picture
        ? {
            url: originalMember.picture,
          }
        : undefined,
    };
  }, [originalMember]);
  const getIsDisableEditField = useCallback(
    (fieldId: keyof MemberFormValues) => {
      return getIsDisableEditFieldBecauseOfActiveMembership(
        false,
        originalMember.subscriptions,
        fieldId,
        initialValues,
      );
    },
    [initialValues, originalMember.subscriptions],
  );
  const formik = useFormik<MemberFormValues>({
    initialValues,
    validationSchema: toFormikValidationSchema(MemberSchema),
    onSubmit: async (values: MemberFormValues) => {
      const transformedValues = await MemberSchema.parseAsync(values);
      const toPost = {
        ...transformedValues,
        picture: undefined,
        dateBirth: transformedValues.dateBirth
          ? DateTime.fromJSDate(transformedValues.dateBirth).toISODate()!
          : undefined,
      };
      const toPatch = getPatch(originalMember, {
        ...toPost,
      });
      if (transformedValues.picture?.url !== originalMember.picture) {
        await updateUser({
          picture: transformedValues.picture?.blob,
        });
      }
      await updateUser({
        patchedFields: toPatch,
      });
      onClose();
    },
  });
  const { setModal, hide } = useContext(ModalContext);
  const { toast } = useContext(ToastContext);
  return (
    <FormikProvider value={formik}>
      <div className="flex h-full flex-col overflow-hidden">
        <div className="flex flex-col justify-between border-b border-gray-200 p-6 dark:border-gray-800">
          <div className="flex flex-row items-center justify-between">
            <div className="dark:text-gray-0 text-lg font-semibold text-gray-950">
              {t("pages.hostedPagesProfile.editMember.title")}
            </div>
            <Button
              intent="transparent"
              className="h-fit min-w-0 p-0"
              onClick={onClose}
            >
              <CloseIcon />
            </Button>
          </div>
        </div>
        <div className="flex-1 overflow-y-auto p-6">
          <div className="flex w-full items-center justify-center pb-4">
            <ImageInputWithModal
              value={formik.values["picture"]}
              onChange={(blob) => {
                const url = URL.createObjectURL(blob);
                if (blob) {
                  formik.setFieldValue("picture", { url, blob });
                } else {
                  formik.setFieldValue("picture", undefined);
                }
              }}
              modalTitle={t("pages.hostedPagesProfile.editMember.image")}
              disabled={getIsDisableEditField("picture")}
              placeholder={`${formik.values.firstName.charAt(0)}${formik.values.lastName.charAt(
                0,
              )}`}
            />
          </div>
          <div className="flex flex-col gap-4">
            <div className="flex flex-row gap-4">
              <FormInput<MemberFormValues>
                name="firstName"
                label={t("common.userMemberForm.firstName")}
                placeholder={t("common.userMemberForm.firstName")}
                isRequired
                disabled={getIsDisableEditField("firstName")}
              />
              <FormInput<MemberFormValues>
                name="lastName"
                label={t("common.userMemberForm.lastName")}
                placeholder={t("common.userMemberForm.lastName")}
                isRequired
                disabled={getIsDisableEditField("lastName")}
              />
            </div>
            <FormInput<MemberFormValues>
              name="email"
              label={t("common.userMemberForm.email")}
              placeholder={t("common.userMemberForm.email")}
              isRequired
              disabled={getIsDisableEditField("email")}
            />
            <FormPhoneNumberInput<MemberFormValues>
              name="mobileNumber"
              label={t("common.userMemberForm.mobileNumber")}
              placeholder={t("common.userMemberForm.mobileNumber")}
              disabled={getIsDisableEditField("mobileNumber")}
              isRequired={userFormRule.mobileNumber.isRequired}
            />
            <FormSingleDatePicker<MemberFormValues>
              name="dateBirth"
              label={t("common.userMemberForm.dateOfBirth")}
              isDisabled={getIsDisableEditField("dateBirth")}
              isRequired={userFormRule.dateOfBirth.isRequired}
            />

            <FormRadioButtonGroup<MemberFormValues, Gender>
              name="gender"
              label="Gender"
              options={[
                {
                  label: t("common.forms.genderOptions.male"),
                  value: "MALE",
                },
                {
                  label: t("common.forms.genderOptions.female"),
                  value: "FEMALE",
                },
                {
                  label: t("common.forms.genderOptions.other"),
                  value: "PREFER_NOT_TO_SAY",
                },
              ]}
              disabled={getIsDisableEditField("gender")}
            />
            <FormInput<MemberFormValues>
              name="addressLine1"
              label={t("common.userMemberForm.addressLine1")}
              placeholder={t("common.userMemberForm.addressLine1")}
              disabled={getIsDisableEditField("addressLine1")}
              isRequired={userFormRule.addressLine.isRequired}
            />
            <FormInput<MemberFormValues>
              name="addressLine2"
              label={t("common.userMemberForm.addressLine2")}
              placeholder={t("common.userMemberForm.addressLine2")}
              disabled={getIsDisableEditField("addressLine2")}
              isRequired={userFormRule.addressLine.isRequired}
            />

            <div className="flex flex-row gap-4">
              <FormInput<MemberFormValues>
                name="city"
                label={t("common.userMemberForm.city")}
                placeholder={t("common.userMemberForm.city")}
                disabled={getIsDisableEditField("city")}
                isRequired={userFormRule.city.isRequired}
              />
              <FormInput<MemberFormValues>
                name="postCode"
                label={t("common.userMemberForm.postCode")}
                placeholder={t("common.userMemberForm.postCode")}
                disabled={getIsDisableEditField("postCode")}
                isRequired={userFormRule.postCode.isRequired}
              />
            </div>
            <FormInput<MemberFormValues>
              name="personalNumber"
              label={t("common.userMemberForm.personalNumber")}
              placeholder={t("common.userMemberForm.personalNumber")}
              disabled={getIsDisableEditField("personalNumber")}
              isRequired={userFormRule.personalNumber.isRequired}
            />
            <div className="shadow-xs flex flex-col gap-4 rounded-2xl border border-gray-200 p-4 dark:border-gray-800">
              <div className="dark:text-gray-0 font-semibold text-gray-950">
                {t("common.userMemberForm.emergencyContact")}
              </div>
              <FormInput<MemberFormValues>
                name="emergencyContactName"
                label={t("common.userMemberForm.emergencyContactName")}
                placeholder={t("common.userMemberForm.emergencyContactName")}
                disabled={getIsDisableEditField("emergencyContactName")}
                isRequired={userFormRule.emergencyContact.isRequired}
              />
              <FormPhoneNumberInput<MemberFormValues>
                name="emergencyContact"
                label={t("common.userMemberForm.emergencyContact")}
                placeholder={t("common.userMemberForm.emergencyContact")}
                disabled={getIsDisableEditField("emergencyContact")}
                isRequired={userFormRule.emergencyContact.isRequired}
              />
            </div>
            <Button
              intent="error-outline"
              size="small"
              className="w-fit gap-1.5 self-center"
              onClick={() => {
                setModal(
                  <DeleteMemberModal
                    asMember
                    onConfirm={() => {
                      hide();
                      toast({
                        message: t(
                          "page.hostedPagesProfile.editMember.deletedMember",
                        ),
                      });
                      member.logout();
                    }}
                    onClose={() => {
                      hide();
                    }}
                  />,
                );
              }}
            >
              <Trash2Icon />
              {t("pages.hostedPagesProfile.editMember.delete")}
            </Button>
          </div>
        </div>
        <div className="flex items-center justify-between gap-4 border-t border-gray-200 bg-gray-50 p-4 dark:border-gray-800 dark:bg-gray-900">
          <Button intent="default" className="w-full" onClick={onClose}>
            Cancel
          </Button>
          <Button
            intent="secondary"
            className="w-full"
            onClick={async () => await formik.submitForm()}
          >
            {t("common.save")}
          </Button>
        </div>
      </div>
    </FormikProvider>
  );
};
