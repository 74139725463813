export const UserSquareIcon = ({
  className,
  pathClassName,
}: {
  className?: string;
  pathClassName?: string;
}) => (
  <svg
    width="20"
    height="20"
    viewBox="0 0 20 20"
    fill="none"
    xmlns="http://www.w3.org/2000/svg"
    className={className}
  >
    <g clipPath="url(#clip0_1725_18800)">
      <path
        d="M3.33337 18.1812C3.83552 18.3334 4.51376 18.3334 5.66669 18.3334H14.3334C15.4863 18.3334 16.1645 18.3334 16.6667 18.1812M3.33337 18.1812C3.2257 18.1486 3.12613 18.109 3.03171 18.0609C2.56131 17.8212 2.17885 17.4387 1.93917 16.9683C1.66669 16.4336 1.66669 15.7335 1.66669 14.3334V5.66669C1.66669 4.26656 1.66669 3.56649 1.93917 3.03171C2.17885 2.56131 2.56131 2.17885 3.03171 1.93917C3.56649 1.66669 4.26656 1.66669 5.66669 1.66669H14.3334C15.7335 1.66669 16.4336 1.66669 16.9683 1.93917C17.4387 2.17885 17.8212 2.56131 18.0609 3.03171C18.3334 3.56649 18.3334 4.26656 18.3334 5.66669V14.3334C18.3334 15.7335 18.3334 16.4336 18.0609 16.9683C17.8212 17.4387 17.4387 17.8212 16.9683 18.0609C16.8739 18.109 16.7743 18.1486 16.6667 18.1812M3.33337 18.1812C3.33365 17.5068 3.33769 17.1499 3.3974 16.8497C3.66043 15.5274 4.69408 14.4938 6.01639 14.2307C6.33838 14.1667 6.7256 14.1667 7.50002 14.1667H12.5C13.2744 14.1667 13.6617 14.1667 13.9837 14.2307C15.306 14.4938 16.3396 15.5274 16.6026 16.8497C16.6623 17.1499 16.6664 17.5068 16.6667 18.1812M13.3334 7.91669C13.3334 9.75764 11.841 11.25 10 11.25C8.15907 11.25 6.66669 9.75764 6.66669 7.91669C6.66669 6.07574 8.15907 4.58335 10 4.58335C11.841 4.58335 13.3334 6.07574 13.3334 7.91669Z"
        stroke="#667085"
        strokeWidth="1.5"
        strokeLinecap="round"
        strokeLinejoin="round"
        className={pathClassName}
      />
    </g>
    <defs>
      <clipPath id="clip0_1725_18800">
        <rect width="20" height="20" fill="white" />
      </clipPath>
    </defs>
  </svg>
);
