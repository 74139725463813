export default {
  Container: ({ children }: { children: React.ReactNode }) => {
    return (
      <div className="flex h-fit w-[32rem] max-w-full overflow-y-auto overflow-x-hidden p-6">
        <div className="bg-gray-0 flex h-full min-h-fit w-full flex-col items-center justify-between gap-16 rounded-2xl border border-gray-300 p-6 shadow-sm dark:border-gray-700 dark:bg-gray-950">
          {children}
        </div>
      </div>
    );
  },
  Header: ({ children }: { children: React.ReactNode }) => {
    return <div className="flex w-full flex-col gap-1">{children}</div>;
  },
  HeaderTitle: ({ children }: { children: React.ReactNode }) => {
    return (
      <div className="dark:text-gray-0 flex w-full text-xl font-bold text-gray-950">
        {children}
      </div>
    );
  },
  HeaderDescription: ({ children }: { children: React.ReactNode }) => {
    return (
      <div className="flex w-full text-sm text-gray-600 dark:text-gray-400">
        {children}
      </div>
    );
  },
  InnerCard: ({ children }: { children: React.ReactNode }) => {
    return (
      <div className="flex w-full flex-col gap-1 rounded-xl border border-gray-300 p-3 dark:border-gray-700">
        {children}
      </div>
    );
  },
};
