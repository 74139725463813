import { useAutoAnimate } from "@formkit/auto-animate/react";
import { useQueryClubFeatureFlagsAsPublic } from "@gymflow/api";
import { useEffect } from "react";
import { Route, Switch, useRouteMatch } from "react-router-dom";

import { HostedPagesFooter } from "../../components/site/components/HostedPagesFooter";
import {
  useHtmlNodeDarkMode,
  useInjectWebsiteCustomizations,
} from "../../hooks";
import useGoogleTagManager from "../../hooks/useGoogleTagManager";
import { LoginOverlayProvider, useClubSettings } from "../../providers";
import { generateSiteWithAuthRoutes, RouteDefinition } from "../../routes";
import useGymflowModels from "../../store";

export function EmbedWithAuth() {
  const match = useRouteMatch();

  const getRoutes = (routes: RouteDefinition[]) => {
    const nodes = routes.map((prop) => {
      return (
        <Route path={match.path + prop.path} key={prop.path}>
          {(props) => <prop.component {...props} />}
        </Route>
      );
    });
    return nodes;
  };

  useGoogleTagManager();

  const { api } = useGymflowModels();
  const settings = useClubSettings();

  const { data: featureFlags } = useQueryClubFeatureFlagsAsPublic({
    api,
    clubId: settings.clubId,
  });
  const routesWithAuth = generateSiteWithAuthRoutes(featureFlags);

  useHtmlNodeDarkMode({ supportDarkMode: true });
  useInjectWebsiteCustomizations();
  const [parent] = useAutoAnimate();
  useEffect(() => {
    const resizeCallback = () => {
      const trackedHeightsOverlay = Array.from(
        document.querySelectorAll(".track-height-overlay"),
      ).map((e) => e.scrollHeight);
      if (trackedHeightsOverlay.length > 0) {
        window.top?.postMessage({ height: window.screen.height * 0.8 }, "*");
        return;
      }
      const calendarLaneHeight = Array.from(
        document.querySelectorAll(".track-height-calendar-lane"),
      )
        .map((e) => e.scrollHeight)
        .reduce((acc, next) => Math.max(acc, next), 0);
      const calendarLaneHeightWithHeader = calendarLaneHeight
        ? calendarLaneHeight + 92
        : 0;
      const trackedHeights = Array.from(
        document.querySelectorAll(".track-height"),
      ).map((e) => e.scrollHeight);

      window.top?.postMessage(
        {
          height: Math.max(
            calendarLaneHeightWithHeader +
              trackedHeights.reduce((acc, entry) => acc + entry, 56),
            256,
          ),
        },
        "*",
      );
    };
    const resizeObserver = new ResizeObserver(resizeCallback);

    const mutationObserver = new MutationObserver((mutations) => {
      for (const mutation of mutations) {
        for (const _added of Array.from(mutation?.addedNodes)) {
          const added = _added as HTMLElement;
          if (
            added.classList?.contains("track-height") ||
            added.classList?.contains("track-height-overlay") ||
            added.classList?.contains("track-height-calendar-lane")
          ) {
            resizeObserver.observe(added);
          }
        }
        for (const _removed of Array.from(mutation?.removedNodes)) {
          const removed = _removed as HTMLElement;
          if (
            removed.classList?.contains("track-height") ||
            removed.classList?.contains("track-height-overlay") ||
            removed.classList?.contains("track-height-calendar-lane")
          ) {
            resizeObserver.unobserve(removed);
          }
        }
      }
      resizeCallback();
    });
    resizeCallback();
    mutationObserver.observe(document.body, {
      childList: true,
      subtree: true,
    });
  }, []);
  return (
    <LoginOverlayProvider>
      <div className="bg-gray-0 flex w-full flex-col justify-between overflow-hidden">
        <div
          ref={parent}
          className="flex h-full w-full flex-col overflow-hidden bg-gray-50 dark:bg-gray-800"
        >
          <Switch>{getRoutes(routesWithAuth)}</Switch>
        </div>
        <HostedPagesFooter />
      </div>
    </LoginOverlayProvider>
  );
}
