import { faClose } from "@fortawesome/free-solid-svg-icons";
import { FontAwesomeIcon } from "@fortawesome/react-fontawesome";
import { memberQueryFn, useMember } from "@gymflow/api";
import { cn, LUXON_DATE_FORMAT } from "@gymflow/helpers";
import { CustomPaymentCategoryDTO, UserMemberBean } from "@gymflow/types";
import useScaModal from "apps/portal/src/hooks/useScaModal";
import { isAxiosError } from "axios";
import { Formik, useFormikContext } from "formik";
import { DateTime } from "luxon";
import React, {
  ReactElement,
  useCallback,
  useContext,
  useMemo,
  useState,
} from "react";
import { useTranslation } from "react-i18next";
import { useHistory } from "react-router";
import { v4 as uuidv4 } from "uuid";
import { z } from "zod";
import { toFormikValidationSchema } from "zod-formik-adapter";

import { usePortalRoutes, useValidateMemberDetails } from "../../../hooks";
import { useClubSettings } from "../../../providers";
import { ToastContext } from "../../../providers/ToastProvider/context";
import useGymflowModels from "../../../store";
import { UserMemberPage } from "../../../views/UserMember";
import {
  Button,
  SelectInputOptionType,
  SlideSideBar,
  Spinner,
  TabType,
  UsersSearchInput,
  UsersSearchInputOptionsType,
  UsersSearchInputOptionType,
} from "../../atoms";
import CashIcon from "../../atoms/icons/CashIcon";
import { CreditCard02Icon } from "../../atoms/icons/CreditCard02Icon";
import { CollapsibleSections, SideBarUserForm } from "../../molecules";
import { ShopCheckout } from "../ShopCheckout";
import { ShopItemPicker } from "../ShopItemPicker";
import { ShopCartType } from "../ShopTypes";
import { useProcessCheckout } from "./hooks/useProcessCheckout";
import { ShopSidebarContext } from "./ShopSidebarContext";
import { defaultValuesNormalizer } from "./utils/defaultValuesNormalizer";

export type TabsType = "ONLINE" | "IN_PERSON";

export const TABS: TabType<TabsType>[] = [
  {
    id: "ONLINE",
    label: (isActive) => (
      <>
        <CreditCard02Icon
          className="h-7 w-7"
          pathClassName={cn("stroke-1", { "stroke-secondary-700": isActive })}
        />
        Online
      </>
    ),
  },
  {
    id: "IN_PERSON",
    label: (isActive) => (
      <>
        <CashIcon
          className="h-7 w-7"
          pathClassName={cn({ "fill-secondary-700": isActive })}
        />
        In Person
      </>
    ),
  },
];

export const ShopSidebarSidebarProvider: React.FC<{
  children: ReactElement | ReactElement[];
}> = ({ children }) => {
  const { t } = useTranslation();
  const [sidebarState, setSidebarState] = useState<
    | {
        isVisible: boolean;
        currentShopTab?: number;
      }
    | undefined
  >();
  const [isUserFormShown, setIsUserFormShown] = useState(false);
  const [activeTabId, setActiveTabId] = useState<TabsType>(TABS[0].id);
  const [orderKey, setOrderKey] = useState<string>();
  const { api } = useGymflowModels();
  const clubSettings = useClubSettings();
  const hide = useCallback(() => {
    setSidebarState(undefined);
    setIsUserFormShown(false);
    setSelectedUser(undefined);
    setCart(undefined);
    setPromotionCode(undefined);
    setStartDate(
      DateTime.now()
        .setZone(clubSettings.timezone)
        .startOf("day")
        .toFormat(LUXON_DATE_FORMAT),
    );
    setAllowChangingStartDate(true);
    setOpenSectionIdx(0);
    setOrderKey(undefined);
    setActiveTabId(TABS[0].id);
  }, [clubSettings.timezone]);
  const [selectedUser, setSelectedUser] =
    useState<UsersSearchInputOptionType>();
  const selectedUserId = selectedUser?.value.id;
  const selectedUserProfileType = selectedUser?.value.profileType;
  const [cart, setCart] = useState<ShopCartType>();

  const cartHasSomething =
    cart?.type === "MEMBERSHIP" ||
    cart?.type === "CREDIT_PACK" ||
    cart?.payload?.some((e) => e.quantity > 0);
  const [promotionCode, setPromotionCode] = useState<string>();
  const [startDate, setStartDate] = useState<string>(
    DateTime.now()
      .setZone(clubSettings.timezone)
      .startOf("day")
      .toFormat(LUXON_DATE_FORMAT),
  );
  const [allowChangingStartDate, setAllowChangingStartDate] =
    useState<boolean>(true);

  const { processCheckout } = useProcessCheckout({ selectedUserId });

  const { data: userMember, isFetching: isFetchingUserMember } = useMember({
    api,
    memberId: selectedUserProfileType === "USER" ? selectedUserId : undefined,
    tz: clubSettings.timezone,
  });
  const userHasActiveMembershipSubscription =
    userMember?.user.subscriptions.some(
      (e) =>
        !!e.membershipBean &&
        (e.active || e.status === "OVERDUE" || e.status === "PENDING"),
    );

  const { requiredFields, isLoadingRequiredFields } = useValidateMemberDetails({
    sellerUserType: "STAFF",
  });

  // just "copy-paste"; will change it with new design
  const onMemberCreated = useCallback(
    async (newMemberId: string) => {
      if (!newMemberId) return;

      const newMember = await memberQueryFn({
        memberId: newMemberId,
        api,
      });
      setSelectedUser({
        id: newMember.id,
        label: `${newMember.firstName} ${newMember.lastName}`,
        subLabel: newMember.email,
        value: newMember,
        imageUrl: newMember.picture,
      });

      setOpenSectionIdx(1);
      setCart(undefined);
      setIsUserFormShown(false);
    },
    [api],
  );

  const onChangeUsersSearchInput = useCallback(
    async (selected: UsersSearchInputOptionsType) => {
      const selectedLocal = selected[0];
      setSelectedUser(selectedLocal);
      setCart(undefined);
      setOpenSectionIdx(0);
      setIsUserFormShown(false);

      if (selectedLocal?.value.profileType === "LEAD") {
        return;
      } else if (
        typeof selectedLocal?.value.id === "string" &&
        selectedLocal?.value.profileType === "USER"
      ) {
        // if user id is string type -> it's UserMemberBean (we check it above)
        setOpenSectionIdx(1);
      }
    },
    [],
  );

  const buttonLabel = useMemo(() => {
    if (selectedUserProfileType === "LEAD") {
      return t("components.shopSidebarProvider.button.setupAccount");
    } else {
      return t("components.shopSidebarProvider.button.newUser");
    }
  }, [selectedUserProfileType, t]);

  const infoLabel = useMemo(() => {
    if (selectedUserProfileType === "LEAD") {
      return t("components.shopSidebarProvider.info.lead");
    } else {
      return "";
    }
  }, [selectedUserProfileType, t]);

  const sections = [
    {
      title: "Select User",
      body: (
        <div className="flex flex-col gap-2">
          <div className="flex flex-row items-center gap-2">
            <div className="flex-1">
              <UsersSearchInput
                withImageByDefault
                value={selectedUser ? [selectedUser] : []}
                onChange={onChangeUsersSearchInput}
                isMulti={false}
              />
            </div>
            <Button
              className="min-w-fit"
              onClick={() => setIsUserFormShown(true)}
            >
              {buttonLabel}
            </Button>
          </div>
          <div className="text-sm text-gray-600">{infoLabel}</div>
          {/* Temporary solution (just have the same form as in NewUserSideBar) before a new design */}
          {isUserFormShown && !isLoadingRequiredFields && (
            <SideBarUserForm
              className="px-0"
              hide={() => setIsUserFormShown(false)}
              onMemberCreated={onMemberCreated}
              userBeforeUpdate={
                selectedUserProfileType === "USER" &&
                typeof selectedUserId === "string"
                  ? (selectedUser?.value as UserMemberBean) // if user id is string type -> it's UserMemberBean (we check it above)
                  : undefined
              }
              onChange={() => {
                // nothing
              }}
              defaultValues={defaultValuesNormalizer(
                selectedUser,
                selectedUserProfileType,
              )}
              requiredFields={requiredFields}
            />
          )}
        </div>
      ),
    },
    {
      title: "Select Item",
      body: (
        <div className="flex w-full flex-row items-center gap-2">
          {isFetchingUserMember && <Spinner />}
          {!isFetchingUserMember && (
            <ShopItemPicker
              userHasActiveMembershipSubscription={
                !!userHasActiveMembershipSubscription
              }
              cart={cart}
              setCart={(newCart, stayInSection) => {
                setCart(newCart);
                if (!stayInSection) {
                  setOpenSectionIdx(2);
                }
                if (
                  newCart.type === "MEMBERSHIP" &&
                  newCart.payload.fixedStartDate
                ) {
                  setStartDate(
                    DateTime.fromISO(newCart.payload.fixedStartDate, {
                      setZone: true,
                    })
                      .plus({ seconds: 1 })
                      .setZone(clubSettings.timezone)
                      .toFormat(LUXON_DATE_FORMAT),
                  );
                  setAllowChangingStartDate(false);
                } else {
                  setStartDate(
                    DateTime.now()
                      .setZone(clubSettings.timezone)
                      .startOf("day")
                      .toFormat(LUXON_DATE_FORMAT),
                  );
                  setAllowChangingStartDate(true);
                }
              }}
              hideShopSidebar={() => {
                setSidebarState((e) => ({
                  ...e,
                  isVisible: false,
                }));
              }}
              showShopSidebar={() => {
                setSidebarState((e) => ({
                  ...e,
                  isVisible: true,
                }));
              }}
              sidebarState={sidebarState}
              setSidebarState={setSidebarState}
            />
          )}{" "}
        </div>
      ),
      className: "p-0",
      disabled: !selectedUser || selectedUserProfileType === "LEAD",
    },
    {
      title: "Checkout",
      body: !!cart &&
        selectedUser &&
        typeof selectedUserId === "string" &&
        selectedUserProfileType === "USER" && (
          <ShopCheckout
            cart={cart}
            // if user id is string type -> it's UserMemberBean (we check it above)
            selectedUser={selectedUser.value as UserMemberBean}
            promotionCode={promotionCode}
            setPromotionCode={setPromotionCode}
            startDate={startDate}
            setStartDate={setStartDate}
            allowChangingStartDate={allowChangingStartDate}
            activeTabId={activeTabId}
            setActiveTabId={setActiveTabId}
          />
        ),
      disabled: !cart || !cartHasSomething,
    },
  ];
  const [openSectionIdx, setOpenSectionIdx] = useState<number>(0);

  const history = useHistory();
  const { createMemberLink } = usePortalRoutes();
  const showScaModal = useScaModal({
    asMember: false,
  });
  const { notifyDanger } = useContext(ToastContext);

  return (
    <ShopSidebarContext.Provider
      value={{
        showShop: async (user) => {
          if (user) {
            setSelectedUser({
              id: user.id,
              label: `${user.firstName} ${user.lastName}`,
              subLabel: user.email,
              value: user,
            });
            if (user.profileType === "USER" && typeof user.id === "string") {
              // if user id is string type -> it's UserMemberBean (we check it above)
              setOpenSectionIdx(1);
            }
          }
          setSidebarState({
            isVisible: true,
          });
          const newOrderKey = uuidv4();
          setOrderKey(newOrderKey);
        },
      }}
    >
      {children}
      <SlideSideBar
        isOpen={!!sidebarState?.isVisible}
        hide={hide}
        className="!w-full lg:!w-1/2"
        isLoading={false}
      >
        {/* TODO: refactor this form (after redesign the sidebar) */}
        <Formik<{
          priceOverride?: number;
          inPersonPaymentMethod?: SelectInputOptionType<CustomPaymentCategoryDTO>;
          paymentMethodId?: string;
        }>
          initialValues={{
            priceOverride: undefined,
            inPersonPaymentMethod: undefined,
            paymentMethodId: undefined,
          }}
          validationSchema={toFormikValidationSchema(
            z
              .object({
                priceOverride: z.coerce.number({
                  invalid_type_error: "Must include price",
                  required_error: "Must include price",
                }),
                inPersonPaymentMethod: z.custom((e) => e).optional(),
                paymentMethodId: z.string().optional(),
              })
              .superRefine(
                (
                  { paymentMethodId, priceOverride, inPersonPaymentMethod },
                  ctx,
                ) => {
                  if (activeTabId === "IN_PERSON" && !inPersonPaymentMethod) {
                    ctx.addIssue({
                      path: ["inPersonPaymentMethod"],
                      code: z.ZodIssueCode.custom,
                      message: "This field is required",
                    });
                  }

                  if (
                    activeTabId === "ONLINE" &&
                    !paymentMethodId &&
                    priceOverride !== 0
                  ) {
                    ctx.addIssue({
                      path: ["paymentMethodId"],
                      code: z.ZodIssueCode.custom,
                      message: "Add payment method",
                    });
                  }
                },
              ),
          )}
          onSubmit={async (values) => {
            if (!cart || !orderKey) return;
            try {
              const priceOverride = values.priceOverride
                ? +values.priceOverride
                : undefined;
              // Checkout only for members (id is string)
              const userMemberId = selectedUserId as string;
              // Change it after refactoring to invoiceNumber only
              // invoice: InvoiceDTO - for old requests; invoiceNumber: string - for new requests
              const result = await processCheckout({
                cart,
                activeTabId,
                orderKey,
                values,
                startDate,
                userMemberId,
                promotionCode,
                paymentMethodId: values.paymentMethodId,
                priceOverride,
              });
              hide();
              if (typeof selectedUserId === "string") {
                history.push(
                  createMemberLink(
                    selectedUserId,
                    cart.type === "MEMBERSHIP"
                      ? UserMemberPage.Account
                      : cart.type === "CREDIT_PACK"
                      ? UserMemberPage.Sessions
                      : UserMemberPage.Payments,
                  ),
                );
                showScaModal({
                  // Change it after refactoring to invoiceNumber only
                  // invoice: InvoiceDTO - for old requests; invoiceNumber: string - for new requests
                  invoiceNumber:
                    result?.invoice?.number || result?.invoiceNumber || "",
                  userMemberId: selectedUserId,
                });
              }
            } catch (e) {
              if (isAxiosError(e)) {
                notifyDanger(e);
              }
            }
          }}
        >
          <div className="flex h-full max-h-full flex-col overflow-hidden">
            <div className="flex flex-col justify-between border-b border-gray-200 p-8">
              <div className="mb-1 flex flex-row items-center justify-between">
                <div className="text-xl font-semibold text-gray-900">
                  Sell Something
                </div>

                <FontAwesomeIcon
                  onClick={() => {
                    hide();
                  }}
                  className="cursor-pointer text-xl text-gray-600"
                  icon={faClose}
                />
              </div>
              <div className="text-sm font-medium text-gray-600">
                Sell a membership, credit pack or products.
              </div>
              {selectedUser && (
                <div className="text-sm font-medium text-gray-600">
                  Sale For:{" "}
                  <b>
                    {selectedUser.value.firstName} {selectedUser.value.lastName}
                  </b>
                  {cartHasSomething && cart
                    ? `, ${
                        cart.type === "MEMBERSHIP"
                          ? cart.payload.name
                          : cart.type === "CREDIT_PACK"
                          ? cart.payload.name
                          : cart.payload
                              .filter((e) => e.quantity > 0)
                              .map(
                                (e) =>
                                  `${e.quantity > 1 ? `${e.quantity} x ` : ""}${
                                    e.product.name
                                  }`,
                              )
                              .join(", ")
                      }`
                    : ""}
                </div>
              )}
            </div>
            <CollapsibleSections
              sections={sections}
              onChangeSection={(_, sectionIdx) => {
                setOpenSectionIdx(sectionIdx);
              }}
              openSectionIndex={openSectionIdx}
            />
            <div className="mt-auto flex h-20 w-full flex-row items-center justify-end gap-x-2 border-t border-gray-200 bg-gray-50 px-6 py-4">
              <Button
                className="flex-1"
                onClick={() => {
                  hide();
                }}
              >
                Cancel
              </Button>
              {cart && openSectionIdx === 2 && <TakePaymentButton />}
            </div>
          </div>
        </Formik>
      </SlideSideBar>
    </ShopSidebarContext.Provider>
  );
};
const TakePaymentButton: React.FC = () => {
  const formik = useFormikContext();
  return (
    <Button
      className="flex-1"
      intent="primary"
      onClick={async () => await formik.submitForm()}
    >
      Take Payment
    </Button>
  );
};
