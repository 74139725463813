import { cn } from "@gymflow/helpers";

import { usePortalRoutes } from "../../hooks";
import { SignUpFormLoader } from "../../providers";

export function SiteRegistration() {
  const { isEmbed } = usePortalRoutes();
  return (
    <div
      className={cn(
        "track-height flex justify-center overflow-y-auto bg-gray-0 dark:bg-gray-950",
        {
          "h-fit": isEmbed,
          "h-full": !isEmbed,
        },
      )}
    >
      <div className="flex h-full w-[calc(100vw-2rem)] max-w-[25rem]">
        <SignUpFormLoader />
      </div>
    </div>
  );
}
