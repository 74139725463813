import { CardBrandType, PaymentMethodWalletType } from "@gymflow/types";
import { PMAmexIcon } from "apps/portal/src/components/atoms/icons/PMAmexIcon";
import PMApplePayIcon from "apps/portal/src/components/atoms/icons/PMApplePayIcon";
import PMGooglePayIcon from "apps/portal/src/components/atoms/icons/PMGooglePayIcon";
import { PMMasterCardIcon } from "apps/portal/src/components/atoms/icons/PMMasterCardIcon";
import PMUnknownCardBrandIcon from "apps/portal/src/components/atoms/icons/PMUnknownCardBrandIcon";
import { PMVisaIcon } from "apps/portal/src/components/atoms/icons/PMVisaIcon";

export const WALLET_ICONS: Record<PaymentMethodWalletType, JSX.Element> = {
  GOOGLE_PAY: <PMGooglePayIcon />,
  APPLE_PAY: <PMApplePayIcon />,
  LINK: <PMUnknownCardBrandIcon />,
  UNSUPPORTED: <PMUnknownCardBrandIcon />,
};

export const CARD_BRAND_ICONS: Record<CardBrandType, JSX.Element> = {
  mastercard: <PMMasterCardIcon />,
  visa: <PMVisaIcon />,
  amex: <PMAmexIcon />,
  diners: <PMUnknownCardBrandIcon />,
  discover: <PMUnknownCardBrandIcon />,
  eftpos_au: <PMUnknownCardBrandIcon />,
  jcb: <PMUnknownCardBrandIcon />,
  unionpay: <PMUnknownCardBrandIcon />,
  unknown: <PMUnknownCardBrandIcon />,
};
