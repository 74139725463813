import { ApiType } from "@gymflow/common/lib/api/ApiType";
import { useMutation } from "@tanstack/react-query";

export function useMutationRulesAsPublic({ api }: { api: ApiType }) {
  return useMutation({
    mutationFn: async (
      params: Parameters<ApiType["public"]["ruleApi"]["getRulesFor"]>[0],
    ) => {
      return (await api.public.ruleApi.getRulesFor(params!)).data;
    },
  });
}
