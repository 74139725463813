import { Field, FieldProps } from "formik";

import { Tabs, TabsProps } from "../base";

type FormTabsProps<TForm, TTab extends string> = {
  name: keyof TForm;
} & Omit<TabsProps<TTab>, "activeTabId" | "setActiveTabId">;

export const FormTabs = <TForm, TTab extends string>({
  name,
  tabs,
  className,
  tabClassName,
  activeTabClassName,
  activeTabLabelClassName,
}: FormTabsProps<TForm, TTab>) => (
  <Field name={name}>
    {({ field, form }: FieldProps) => (
      <Tabs<TTab>
        tabs={tabs}
        activeTabId={field.value}
        setActiveTabId={(id: TTab) => form.setFieldValue(name as string, id)}
        className={className}
        tabClassName={tabClassName}
        activeTabClassName={activeTabClassName}
        activeTabLabelClassName={activeTabLabelClassName}
      />
    )}
  </Field>
);
