import { cn } from "@gymflow/helpers";

export function ToggleModeButton({
  className,
  value,
  onChange,
  left,
  right,
}: {
  className?: string;
  value: "LEFT" | "RIGHT";
  onChange: (v: "LEFT" | "RIGHT") => void;
  left: React.ReactNode;
  right: React.ReactNode;
}) {
  return (
    <div
      className={cn(
        "relative flex h-11 cursor-pointer items-center !rounded-lg border border-gray-300 dark:border-gray-700 bg-gray-0 dark:bg-gray-950 w-full",
        className,
      )}
      onClick={() => {
        onChange(value === "LEFT" ? "RIGHT" : "LEFT");
      }}
    >
      <div
        className={cn(
          "dark:bg-gray-900 absolute bottom-1 top-1 flex w-[calc(50%-0.5rem)] !rounded-lg shadow-sm transition-all duration-500 ease-in-out bg-gray-100 border border-gray-300 dark:border-gray-700",
          {
            "left-1": value === "LEFT",
            "left-[calc(50%+.25rem)]": value === "RIGHT",
          },
        )}
      />
      <div className="z-10 flex w-full flex-row">
        {left}
        {right}
      </div>
    </div>
  );
}
