import {
  clubAsPublicQueryKeys,
  clubStaleTime,
  useClubPropertiesSettingsAsPublic,
} from "@gymflow/api";
import { ClubPropertiesSettings } from "@gymflow/types";
import { useQueryClient } from "@tanstack/react-query";
import hexToRgba from "hex-to-rgba";
import { ReactNode, useEffect, useState } from "react";
import shadesOf from "tailwind-shades";

import { LoadingPortal } from "../../components/pages";
import useGymflowModels from "../../store";
import { ClubSettingsContext } from "./ClubSettingsProvider";
import { resetTailwindColors } from "./resetTailwindColors";

function hexToTwRgba(hex: string) {
  const result = hexToRgba(hex);
  return result.replace("rgba(", "").replace(")", "");
}

function getShades(color: string) {
  const result = shadesOf(color);
  for (const key in result) {
    result[key] = hexToTwRgba(result[key]);
  }
  return result;
}

function updateColorDefinition(
  shades: { [key: string]: string },
  colorName: string,
) {
  const root = document.querySelector(":root");
  const setProperty = (root as HTMLElement).style.setProperty.bind(
    (root as HTMLElement).style,
  );
  setProperty(`--${colorName}-color-950`, shades["950"]);
  setProperty(`--${colorName}-color-900`, shades["900"]);
  setProperty(`--${colorName}-color-800`, shades["800"]);
  setProperty(`--${colorName}-color-700`, shades["700"]);
  setProperty(`--${colorName}-color-600`, shades["600"]);
  setProperty(`--${colorName}-color-500`, shades["500"]);
  setProperty(`--${colorName}-color-400`, shades["400"]);
  setProperty(`--${colorName}-color-300`, shades["300"]);
  setProperty(`--${colorName}-color-200`, shades["200"]);
  setProperty(`--${colorName}-color-100`, shades["100"]);
  setProperty(`--${colorName}-color-50`, shades["50"]);
  setProperty(`--${colorName}-color-25`, shades["25"]);
  setProperty(`--${colorName}-color-0`, shades["0"]);
}

export function ClubSettingsAsPublicProvider({
  clubId,
  children,
  disableClubColorCustomization = false,
}: {
  clubId: number;
  children:
    | ((clubPropertiesSettings: ClubPropertiesSettings) => ReactNode)
    | ReactNode;
  disableClubColorCustomization?: boolean;
}) {
  const { api } = useGymflowModels();
  const { data: propertiesSettings } = useClubPropertiesSettingsAsPublic(
    { api, clubId },
    { staleTime: clubStaleTime },
  );

  const [isCacheReady, setIsCacheReady] = useState(false);
  const queryClient = useQueryClient();
  useEffect(() => {
    if (propertiesSettings) {
      queryClient.setQueryData(
        clubAsPublicQueryKeys.details(clubId),
        propertiesSettings.club,
      );
      queryClient.setQueryData(clubAsPublicQueryKeys.featureFlags(clubId), {
        featureFlags: propertiesSettings.featureFlags,
      });
      queryClient.setQueryData(
        clubAsPublicQueryKeys.properties(clubId),
        propertiesSettings.properties,
      );
      if (propertiesSettings) {
        queryClient.setQueryData(
          clubAsPublicQueryKeys.hostedPagesSettings(clubId),
          { settings: propertiesSettings.settings },
        );

        const root = document.querySelector(":root");
        if (root) {
          if (propertiesSettings.settings && !disableClubColorCustomization) {
            const primaryColor = propertiesSettings.settings.primaryColor;
            const grayShades = Object.keys(
              propertiesSettings.settings.grayShades ?? {},
            ).reduce(
              (acc, key) => ({
                ...acc,
                [key]: hexToTwRgba(
                  propertiesSettings.settings!.grayShades?.[
                    key as keyof typeof propertiesSettings.settings.grayShades
                  ],
                ),
              }),
              {},
            );
            const primaryShades = getShades(primaryColor);
            updateColorDefinition(primaryShades, "primary");
            updateColorDefinition(primaryShades, "secondary");
            updateColorDefinition(grayShades, "gray");
            const setProperty = (root as HTMLElement).style.setProperty.bind(
              (root as HTMLElement).style,
            );

            setProperty("--ring-color", primaryShades["500"]);
          } else {
            resetTailwindColors();
          }
        }
      }
    }
    setIsCacheReady(true);
  }, [clubId, disableClubColorCustomization, propertiesSettings, queryClient]);

  if (!propertiesSettings) {
    return <LoadingPortal message="Processing club settings ..." />;
  }
  if (!isCacheReady) {
    return <LoadingPortal message="Caching club settings and properties..." />;
  }

  return (
    <ClubSettingsContext.Provider
      value={{
        clubId,

        stripe_account_type: propertiesSettings.club.stripeAccountType,

        default_nationality: propertiesSettings.properties.defaultNationality,
        phone_number_country: propertiesSettings.properties.phoneNumberCountry,
        postal_code_country: propertiesSettings.properties.postalCodeCountry,

        date_format: propertiesSettings.properties.dateFormat,
        short_date_format: propertiesSettings.properties.shortDateFormat,
        long_date_format: propertiesSettings.properties.longDateFormat,

        stripe_public_key: propertiesSettings.properties.stripePublicKey,
        stripe_onboard_public_key:
          propertiesSettings.properties.stripeOnboardPublicKey,
        minimum_charge: propertiesSettings.properties.minimumCharge,

        max_image_upload_width: propertiesSettings.properties.maxImgUploadWidth,
        max_image_upload_height:
          propertiesSettings.properties.maxImgUploadHeight,

        allow_phone_country_selection:
          propertiesSettings.properties.allowPhoneCountrySelection,
        allow_customer_pausing_memberships:
          propertiesSettings.properties.allowPausingMembershipsCustomer,
        allow_customer_cancel_memberships:
          propertiesSettings.properties.allowCustomerCancelMemberships,
        allow_customer_selecting_start_date:
          propertiesSettings.properties.allowCustomerSelectingStartDate,

        gym_opening_date: propertiesSettings.properties?.gymOpeningDate,

        defaultCurrency: propertiesSettings.club.defaultCurrency,
        timezone: propertiesSettings.club.timezone,
        primaryColor: propertiesSettings.settings?.primaryColor,
        customPaymentsEnabled: propertiesSettings.club.customPaymentsEnabled,
      }}
    >
      {typeof children === "function" ? children(propertiesSettings) : children}
    </ClubSettingsContext.Provider>
  );
}
