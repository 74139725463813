type PMUnknownCardBrandIconProps = {
  className?: string;
};

const PMUnknownCardBrandIcon = ({ className }: PMUnknownCardBrandIconProps) => (
  <svg
    width="34"
    height="24"
    viewBox="0 0 34 24"
    fill="none"
    xmlns="http://www.w3.org/2000/svg"
    className={className}
  >
    <path
      d="M0.5 4C0.5 2.067 2.067 0.5 4 0.5H30C31.933 0.5 33.5 2.067 33.5 4V20C33.5 21.933 31.933 23.5 30 23.5H4C2.067 23.5 0.5 21.933 0.5 20V4Z"
      fill="white"
    />
    <path
      d="M0.5 4C0.5 2.067 2.067 0.5 4 0.5H30C31.933 0.5 33.5 2.067 33.5 4V20C33.5 21.933 31.933 23.5 30 23.5H4C2.067 23.5 0.5 21.933 0.5 20V4Z"
      stroke="#E4E7EC"
    />
    <path
      d="M23.6668 10.6667H10.3335M16.3335 13.3334H13.0002M10.3335 9.46671L10.3335 14.5334C10.3335 15.2801 10.3335 15.6535 10.4788 15.9387C10.6067 16.1896 10.8106 16.3935 11.0615 16.5214C11.3467 16.6667 11.7201 16.6667 12.4668 16.6667L21.5335 16.6667C22.2802 16.6667 22.6536 16.6667 22.9388 16.5214C23.1897 16.3936 23.3937 16.1896 23.5215 15.9387C23.6668 15.6535 23.6668 15.2801 23.6668 14.5334V9.46671C23.6668 8.71997 23.6668 8.3466 23.5215 8.06139C23.3937 7.8105 23.1897 7.60653 22.9388 7.4787C22.6536 7.33337 22.2802 7.33337 21.5335 7.33337L12.4668 7.33337C11.7201 7.33337 11.3467 7.33337 11.0615 7.4787C10.8106 7.60653 10.6067 7.8105 10.4788 8.06139C10.3335 8.3466 10.3335 8.71997 10.3335 9.46671Z"
      stroke="#0BA5EC"
      strokeWidth="1.2"
      strokeLinecap="round"
      strokeLinejoin="round"
    />
  </svg>
);

export default PMUnknownCardBrandIcon;
