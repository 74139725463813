export function StripeReadyForConnect() {
  return (
    <div className="flex h-full flex-col gap-4">
      <div className="mb-32 flex h-full flex-col overflow-y-auto pr-8">
        <div className="relative rounded-2xl border border-gray-200 bg-gray-0 p-4 shadow-sm">
          Your Stripe account is now setup. Please contact&nbsp;
          <a
            className="text-primary-600 hover:text-primary-400"
            href="mailto:support@gymflow.io"
          >
            support@gymflow.io
          </a>
          &nbsp;to finish the process.
        </div>
      </div>
    </div>
  );
}
