import React from "react";

export type PMVisaIconProps = {
  className?: string;
};

export const PMVisaIcon: React.FC<PMVisaIconProps> = ({ className }) => {
  return (
    <svg
      xmlns="http://www.w3.org/2000/svg"
      width="34"
      height="24"
      viewBox="0 0 34 24"
      fill="none"
      className={className}
    >
      <path
        d="M0.5 4C0.5 2.067 2.067 0.5 4 0.5H30C31.933 0.5 33.5 2.067 33.5 4V20C33.5 21.933 31.933 23.5 30 23.5H4C2.067 23.5 0.5 21.933 0.5 20V4Z"
        fill="white"
      />
      <path
        d="M0.5 4C0.5 2.067 2.067 0.5 4 0.5H30C31.933 0.5 33.5 2.067 33.5 4V20C33.5 21.933 31.933 23.5 30 23.5H4C2.067 23.5 0.5 21.933 0.5 20V4Z"
        stroke="#E4E7EC"
      />
      <path
        fillRule="evenodd"
        clipRule="evenodd"
        d="M10.7502 15.8583H8.6905L7.14594 9.79247C7.07263 9.51344 6.91697 9.26676 6.688 9.1505C6.11658 8.85833 5.4869 8.6258 4.79999 8.50853V8.27499H8.11807C8.57601 8.27499 8.91947 8.6258 8.97671 9.03323L9.77811 13.4087L11.8368 8.27499H13.8393L10.7502 15.8583ZM14.9842 15.8583H13.039L14.6408 8.27499H16.586L14.9842 15.8583ZM19.1027 10.3758C19.1599 9.96738 19.5034 9.73384 19.9041 9.73384C20.5338 9.6752 21.2197 9.79248 21.7921 10.0836L22.1356 8.45091C21.5631 8.21737 20.9335 8.1001 20.362 8.1001C18.474 8.1001 17.1002 9.1505 17.1002 10.6083C17.1002 11.7174 18.0733 12.2997 18.7602 12.6505C19.5034 13.0003 19.7896 13.2338 19.7324 13.5836C19.7324 14.1083 19.1599 14.3419 18.5885 14.3419C17.9016 14.3419 17.2147 14.167 16.586 13.8748L16.2426 15.5086C16.9295 15.7997 17.6726 15.917 18.3595 15.917C20.4765 15.9746 21.7921 14.9252 21.7921 13.3501C21.7921 11.3666 19.1027 11.2503 19.1027 10.3758ZM28.6 15.8583L27.0554 8.27499H25.3964C25.0529 8.27499 24.7095 8.50853 24.595 8.85833L21.7349 15.8583H23.7374L24.137 14.7503H26.5975L26.8265 15.8583H28.6ZM25.6826 10.3172L26.254 13.1752H24.6522L25.6826 10.3172Z"
        fill="#172B85"
      />
    </svg>
  );
};
