import { ApiType } from "@gymflow/common/lib/api/ApiType";
import { SessionPackSignupPaymentMethodRequestBody } from "@gymflow/types";
import { useMutation, useQueryClient } from "@tanstack/react-query";

import { memberQueryKeys } from "../member";
import { productQueryKeys } from "../product";
import { sales } from "./sales";

export function useMutationSessionPackSignupPaymentMethod({
  api,
}: {
  api: ApiType;
}) {
  const queryClient = useQueryClient();
  return useMutation({
    mutationFn: async (body: SessionPackSignupPaymentMethodRequestBody) =>
      (await api.salesApi.sessionPackSignupPaymentMethod(body)).data,
    onSuccess: (data, { userMemberId }) => {
      queryClient.invalidateQueries({
        queryKey: sales.all(),
      });
      queryClient.invalidateQueries({
        queryKey: memberQueryKeys.details(userMemberId),
      });
      queryClient.invalidateQueries({
        queryKey: productQueryKeys.all(),
      });
    },
  });
}
