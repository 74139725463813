import moment from "moment-timezone";

import { TimeLimitSelect } from "./TimeLimitSelect";

type TimeIntervalProps = {
  startTime: string;
  endTime: string;
  minTime?: string;
  onChange: ({
    startTime,
    endTime,
  }: {
    startTime: string;
    endTime: string;
  }) => void;
  isDisabled?: boolean;
};

const MAX_MENU_HEIGHT = 240;

export function TimeInterval({
  startTime,
  endTime,
  minTime = "00:00",
  onChange,
  isDisabled = false,
}: TimeIntervalProps) {
  return (
    <div className="flex items-center gap-2">
      <div>
        <TimeLimitSelect
          value={startTime}
          menuPortalTargetDocumentBody
          maxMenuHeight={MAX_MENU_HEIGHT}
          menuPlacement="top"
          minTime={minTime}
          onChange={(newTime) => {
            const startMoment = moment(newTime, "HH:mm");
            const endMoment = moment(endTime, "HH:mm");
            if (startMoment.isBefore(endMoment)) {
              onChange({ startTime: newTime, endTime: endTime });
            } else {
              onChange({
                startTime: newTime,
                endTime: startMoment.add(15, "minutes").format("HH:mm"),
              });
            }
          }}
          isDisabled={isDisabled}
        />
      </div>
      <div className="h-px w-4 bg-gray-200"></div>
      <div>
        <TimeLimitSelect
          value={endTime}
          menuPortalTargetDocumentBody
          maxMenuHeight={MAX_MENU_HEIGHT}
          menuPlacement="top"
          minTime={moment(startTime, "HH:mm")
            .add(15, "minutes")
            .format("HH:mm")}
          onChange={(newTime) => {
            onChange({ startTime: startTime, endTime: newTime });
          }}
          isDisabled={isDisabled}
        />
      </div>
    </div>
  );
}
