import { cn } from "@gymflow/helpers";
import { ReactNode } from "react";

export type LabelProps = {
  label?: string | ReactNode;
  isRequired?: boolean;
  className?: string;
};

const Label = ({ label, isRequired, className }: LabelProps) => {
  if (!label) return undefined;
  return (
    <div
      className={cn(
        "flex text-sm font-medium text-gray-950 dark:text-gray-0",
        className,
      )}
    >
      {label}
      {isRequired && <div className="text-error-600 ml-0.5">*</div>}
    </div>
  );
};

export default Label;
