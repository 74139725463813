import React, { useContext } from "react";
import { useHistory, useLocation } from "react-router";

import { RouteFeature } from "../routes/feature";
import { RouteLayout, RouteLayoutValue } from "../routes/layout";

export interface PortalRoutesContextConfig {
  routeId: string;
  brand: string;
  company: string;
  location: string;
}
export const PortalRoutesContext =
  React.createContext<PortalRoutesContextConfig>({
    routeId: "",
    brand: "",
    company: "",
    location: "",
  });

export const usePortalRoutes = () => {
  const location = useLocation();
  const history = useHistory();
  const {
    routeId,
    company,
    brand,
    location: locationId,
  } = useContext(PortalRoutesContext);
  const website = `${window.location.protocol}//${window.location.host}`;

  const createNewClubLink = (
    newRouteId: string,
    layout = "",
    ...feature: string[]
  ) =>
    `/${newRouteId}${layout}${feature.reduce(
      (acc, v) => acc + v,
      "",
    )}` as const;

  const createClubLink = (layout: RouteLayoutValue, ...feature: string[]) =>
    `/${routeId}${layout}${feature.reduce((acc, v) => acc + v, "")}` as const;

  const createAbsoluteClubLink = (
    layout: RouteLayoutValue,
    ...feature: string[]
  ) =>
    `${website}/${routeId}${layout}${feature.reduce(
      (acc, v) => acc + v,
      "",
    )}` as const;

  const createMemberLink = (id: string, page?: string) => {
    const base = createClubLink(
      RouteLayout.Staff,
      RouteFeature.UserMember.replace(":id", id),
    );
    if (page) {
      return base + page;
    }
    return base;
  };

  const createLeadLink = (id: number, page?: string) => {
    const base = createClubLink(
      RouteLayout.Staff,
      RouteFeature.LeadProfile.replace(":id", id.toString()),
    );
    if (page) {
      return base + page;
    }
    return base;
  };

  const createSiteOrEmbedLink = (...feature: string[]) => {
    const isEmbed = isPathInLayout(location.pathname, RouteLayout.Embed);
    const layout = isEmbed ? RouteLayout.Embed : RouteLayout.Site;

    return createClubLink(layout, ...feature);
  };

  const isPathInLayout = (path: string, layout: RouteLayoutValue) =>
    path.startsWith(`/${routeId}${layout}`);

  const createLoginLink = (routeIdParam?: string) => {
    return `${website}?routeId=${routeIdParam || routeId}`;
  };

  const createLoginWithRedirectHistoryState = () => {
    // CAREFUL: window.location is used on purpose here instead of useLocation
    // because the latter won't have the correct pathname
    const feature = window.location.pathname.replace(`/${routeId}/`, "");
    const urlParams = window.btoa(history?.location?.search.substring(1));

    return {
      pathname: "/",
      search: `redirect=${feature}&routeId=${routeId}&redirectQuery=${urlParams}`,
    } as const;
  };

  return {
    createNewClubLink,
    createClubLink,
    createMemberLink,
    createLeadLink,
    createAbsoluteClubLink,
    createSiteOrEmbedLink,
    createLoginLink,
    createLoginWithRedirectHistoryState,
    isPathInLayout,
    routeId,
    brand,
    company,
    location: locationId,
    isEmbed: isPathInLayout(location.pathname, RouteLayout.Embed),
    isQuickPurchase: location.pathname.includes("quick-purchase"),
  };
};
