import { ApiType } from "@gymflow/common/lib/api/ApiType";
import { MembershipCalculateCancelDatesResponse } from "@gymflow/types";
import { useQuery, UseQueryOptions } from "@tanstack/react-query";

import { membershipQueryKeys } from "./membershipQueryKeys";

export function useQueryMembershipCalculateCancelDates(
  {
    api,
    opts,
  }: {
    api: ApiType;
    opts?: Parameters<ApiType["serviceApi"]["calculateCancelDates"]>[0];
  },
  queryOptions?: UseQueryOptions<
    MembershipCalculateCancelDatesResponse | undefined
  >,
) {
  return useQuery({
    queryKey: membershipQueryKeys.calculateCancelDates(opts),
    queryFn: () => {
      return membershipCalculateCancelDatesQueryFn({ api, filter: opts! });
    },
    enabled: !!opts?.startDate && !!opts?.billingType && !!opts?.billingPeriod,
    ...queryOptions,
  });
}

export async function membershipCalculateCancelDatesQueryFn({
  api,
  filter,
}: {
  api: ApiType;
  filter: Parameters<ApiType["serviceApi"]["calculateCancelDates"]>[0];
}) {
  return (await api.serviceApi.calculateCancelDates(filter)).data;
}
