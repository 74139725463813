import { cn } from "@gymflow/helpers";
import { forwardRef } from "react";
import { createPortal } from "react-dom";

import { usePortalRoutes } from "../../../hooks";
import { HostedPagesFooter } from "./HostedPagesFooter";

export type HostedPagesOverlayProps = {
  isOpen: boolean;
  children: React.ReactNode;
};

const HostedPagesOverlay = forwardRef<
  React.ElementRef<"div">,
  HostedPagesOverlayProps
>(function HostedPagesOverlay({ isOpen, children }, ref) {
  const { isEmbed } = usePortalRoutes();
  return createPortal(
    <div
      className={cn(
        "absolute inset-0 h-[100dvh] z-[10002] w-full overflow-hidden overflow-y-auto bg-gray-0 transition-all duration-300 dark:bg-gray-950",
        {
          "top-0 opacity-100": isOpen,
          "pointer-events-none top-full opacity-0": !isOpen,
          "pb-6": isEmbed,
        },
      )}
    >
      <div
        ref={ref}
        className={cn("flex h-full max-h-full flex-col items-center w-full", {
          "justify-start": isEmbed,
          "justify-between": !isEmbed,
        })}
      >
        {children}
        <HostedPagesFooter />
      </div>
    </div>,
    document.body,
  );
});

export default HostedPagesOverlay;
