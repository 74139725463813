import QueryString from "qs";
import { useEffect } from "react";
import { useRouteMatch } from "react-router";

import { HostedClassesUrlFiltersType } from "../HostedPagesClasses";

export default function useTimetableQueryParamsStore() {
  const match = useRouteMatch();
  useEffect(() => {
    localStorage.setItem(
      "lastTimetableQueryParams",
      JSON.stringify(
        QueryString.parse(window.location.search, {
          ignoreQueryPrefix: true,
        }) as HostedClassesUrlFiltersType,
      ),
    );
  }, [match]);
}
export function getTimetableQueryParams() {
  return QueryString.stringify(
    JSON.parse(localStorage.getItem("lastTimetableQueryParams") || "{}"),
  );
}
