import { z } from "zod";

export const defaultShades = {
  "0": "#FFFFFF",
  "25": "#fcfcfd",
  "50": "#f9fafb",
  "100": "#f2f4f7",
  "200": "#E4E7EC",
  "300": "#d0d5dd",
  "400": "#98a2b3",
  "500": "#667085",
  "600": "#475467",
  "700": "#344054",
  "800": "#1d2939",
  "900": "#101828",
  "950": "#0C111D",
};
export const fontOptions = [
  "Manrope",
  "LeagueSpartan",
  "Poppins",
  "Potta One",
  "Raleway",
  "Circular Std Bold",
  "Montserrat",
  "Futura Regular",
  "Futura Hv Bt",
  "Gotham Condensed",
  "Nunito Sans",
  "Barlow",
  "NTR",
].map((e) => ({
  id: e,
  value: e,
  label: e,
}));

export const BrandingZodSchema = z.object({
  logo: z.object({
    name: z.string(),
    blob: z.string().optional(),
    file: z.any().optional(),
  }),
  primaryColor: z.string(),
  font: z.object({
    id: z.string(),
    value: z.string(),
    label: z.string(),
  }),
  darkMode: z.boolean(),
  advancedSettings: z.boolean(),
  grayShades: z.object({
    "0": z.string(),
    "25": z.string(),
    "50": z.string(),
    "100": z.string(),
    "200": z.string(),
    "300": z.string(),
    "400": z.string(),
    "500": z.string(),
    "600": z.string(),
    "700": z.string(),
    "800": z.string(),
    "900": z.string(),
    "950": z.string(),
  }),
});
export type BrandingValues = z.infer<typeof BrandingZodSchema>;
