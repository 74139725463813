import {
  useClubFeatureFlags,
  useMemberSubscription,
  useQueryMembershipLinkedMembers,
} from "@gymflow/api";
import { permissions, useParseErrors } from "@gymflow/common";
import { formatCurrency, membershipHelper, pluralize } from "@gymflow/helpers";
import {
  UserMemberSubscriptionBean,
  UserMemberSubscriptionBeanWithMembership,
  UserMemberSubscriptionStatus,
} from "@gymflow/types";
import { usePortalRoutes } from "apps/portal/src/hooks/usePortalRoutes";
import { ModalContext, useClubSettings } from "apps/portal/src/providers";
import useGymflowModels from "apps/portal/src/store";
import { capitalize } from "lodash";
import { DateTime } from "luxon";
import moment from "moment";
import React, { Fragment, useCallback, useContext } from "react";
import { useTranslation } from "react-i18next";
import { Link } from "react-router-dom";
import { Tooltip } from "react-tooltip";

import {
  Badge,
  BadgeProps,
  Button,
  PlaceholderAvatar,
  PlusCircleIcon,
} from "../../atoms";
import { PromoIcon } from "../../atoms/icons/PromoIcon";
import { ConfirmModal } from "../../templates";
import { MembershipInformationLines } from "./MembershipInformationLines";
import { CancelMembershipModal } from "./MembershipModal/CancelMembershipModal";
import { ChangeMembershipModal } from "./MembershipModal/ChangeMembershipModal";
import { ChangeMembershipModalNew } from "./MembershipModal/ChangeMembershipModal/ChangeMembershipModalNew";
import { LinkMemberToMembershipModal } from "./MembershipModal/LinkMemberToMembershipModal";
import { PauseMembershipModal } from "./MembershipModal/PauseMembershipModal";
import { ResumeMembershipModal } from "./MembershipModal/ResumeMembershipModal";
import { NotPrimaryMembershipWarning } from "./NotPrimaryMembershipWarning";

export interface MembershipCardProps {
  membership?: UserMemberSubscriptionBean;
  memberId: string;
}

const SCHEDULED_CHANGE_DATE_FORMAT = "Do MMMM YYYY";

export const MembershipCard: React.FC<MembershipCardProps> = ({
  membership,
  memberId,
}) => {
  const isCurrentMembership =
    membershipHelper.isCurrentSubscription(membership);
  const { api } = useGymflowModels();
  const { t } = useTranslation();
  const { timezone, clubId } = useClubSettings();
  const parseError = useParseErrors();
  const { data: featureFlags } = useClubFeatureFlags({ clubId, api });

  const { setModal, popModal } = useContext(ModalContext);
  const {
    cancelPauseSubscriptionMutation: {
      mutateAsync: cancelPauseSubscriptionMutation,
    },
    changeSubscriptionUnscheduleMutation: {
      mutateAsync: changeSubscriptionUnscheduleMutation,
    },
    revokeCancellationMutation: { mutateAsync: revokeCancellation },
  } = useMemberSubscription(
    { api, tz: timezone },
    {
      onError: (error) => {
        popModal();
        parseError((error as any)?.response);
      },
    },
  );
  const { data: linkedMembers } = useQueryMembershipLinkedMembers(
    {
      api,
      primarySubscriptionId: membership?.primarySubscriptionId,
    },
    {
      enabled: !!isCurrentMembership,
    },
  );

  const clubSettings = useClubSettings();
  const { createMemberLink } = usePortalRoutes();

  const onClickChange = useCallback(() => {
    if (
      featureFlags?.featureFlags.PORTAL_NEW_SCHEDULED_MEMBERSHIP_CHANGE_MODAL
    ) {
      setModal(
        <ChangeMembershipModalNew
          memberId={memberId}
          activeMembership={
            membership as UserMemberSubscriptionBeanWithMembership
          }
          linkedMembers={linkedMembers}
          hide={() => popModal()}
        />,
      );
    } else {
      setModal(
        <ChangeMembershipModal
          memberId={memberId}
          membership={membership as UserMemberSubscriptionBeanWithMembership}
        />,
      );
    }
  }, [
    featureFlags?.featureFlags.PORTAL_NEW_SCHEDULED_MEMBERSHIP_CHANGE_MODAL,
    popModal,
    linkedMembers,
    memberId,
    membership,
    setModal,
  ]);

  const onClickUndoScheduledChange = useCallback(() => {
    if (!membership) {
      return;
    }
    setModal(
      <ConfirmModal
        title={t(
          "page.userMember.tab.userMemberAccount.userMemberMembership.membershipCard.modals.undoScheduledChange.title",
        )}
        onConfirm={async () => {
          await changeSubscriptionUnscheduleMutation({
            memberId,
            subscriptionId: membership.id,
          });
          popModal();
        }}
        onCancel={() => popModal()}
        type="warning"
      >
        {t(
          "page.userMember.tab.userMemberAccount.userMemberMembership.membershipCard.modals.undoScheduledChange.description",
        )}
      </ConfirmModal>,
    );
  }, [
    changeSubscriptionUnscheduleMutation,
    popModal,
    memberId,
    membership,
    setModal,
    t,
  ]);

  if (!membership) return null;
  if (!membership.membershipBean) return null;
  const promoCodeText = (() => {
    if (!("promotionCode" in membership)) return undefined;
    if (!membership.promotionCode || membership.promotionIsExpired)
      return undefined;
    const amount = formatCurrency(
      membership.discountedAmount,
      clubSettings.defaultCurrency,
    );
    const isAllPayments = membership.discountedPaymentsUnlimited;
    return `${amount} off ${
      isAllPayments
        ? `all payments`
        : `next ${membership.discountedPaymentsLeft} ${pluralize(
            "payment",
            "payments",
            membership.discountedPaymentsLeft,
          )}`
    }`;
  })();

  const isScheduledChange =
    membership.scheduledChangeDate &&
    membership.scheduledChangeToMembershipName;

  return (
    <div className="bg-gray-0 flex h-fit w-full flex-col rounded-xl border border-gray-200">
      <div className="flex flex-row flex-wrap justify-between gap-4 p-4">
        <div className="flex flex-col gap-y-2">
          <div className="text-lg font-semibold text-gray-900">
            {membership.name}
          </div>
          <div className="text-sm font-medium text-gray-600">
            {`${
              membership.membershipBean.type === "RECURRING"
                ? "Recurring"
                : membership.membershipBean.type === "PREPAID" &&
                  !membership.membershipBean.isTrial
                ? "Pre-Paid"
                : "Trial"
            } Membership`}
          </div>
          {membership.status && (
            <Badge
              intent={
                (
                  {
                    ACTIVE: "success",
                    PAUSED: "warning",
                    PENDING: "primary",
                    OVERDUE: "error",
                    CANCELLED: "default",
                    DELETED: "error",
                    EXPIRED: "default",
                  } as {
                    [key in UserMemberSubscriptionStatus]: BadgeProps["intent"];
                  }
                )[membership.status]
              }
            >
              {capitalize(membership.status)}
            </Badge>
          )}
          {membership.status !== "CANCELLED" && membership.cancellationDate && (
            <div className="text-error-500 text-sm font-medium">
              Cancelling on{" "}
              {DateTime.fromISO(membership.cancellationDate).toLocaleString(
                DateTime.DATE_MED,
              )}
            </div>
          )}

          {membership.status !== "PAUSED" && membership.pauseStartDate && (
            <div className="text-warning-500 text-sm font-medium">
              Pausing on{" "}
              {DateTime.fromISO(membership.pauseStartDate).toLocaleString(
                DateTime.DATE_MED,
              )}
            </div>
          )}

          {membership.status === "PAUSED" && membership.pauseEndDate && (
            <div className="text-sm font-medium text-gray-500">
              Resumes on{" "}
              {DateTime.fromISO(membership.pauseEndDate).toLocaleString(
                DateTime.DATE_MED,
              )}
            </div>
          )}
          {membership.status === "PENDING" && membership.startDate && (
            <div className="text-sm font-medium text-gray-500">
              Starts on{" "}
              {DateTime.fromISO(membership.startDate).toLocaleString(
                DateTime.DATE_MED,
              )}
            </div>
          )}
          {isScheduledChange && (
            <div className="text-warning-600 text-sm font-medium">
              {t(
                "page.userMember.tab.userMemberAccount.userMemberMembership.membershipCard.statusDescription.scheduledChange",
                {
                  scheduledChangeDate: moment(membership.scheduledChangeDate)
                    .tz(timezone)
                    .format(SCHEDULED_CHANGE_DATE_FORMAT),
                  scheduledChangeToMembershipName:
                    membership.scheduledChangeToMembershipName,
                },
              )}
            </div>
          )}
        </div>
        {membership.price !== undefined && (
          <div className="flex flex-col items-end">
            <div className="text-4xl font-bold text-gray-900">
              {formatCurrency(membership.price, clubSettings.defaultCurrency)}
            </div>
            <div className="text-base font-semibold text-gray-600 ">
              {membershipHelper.getSubscriptionBillingRecurrence(
                membership as UserMemberSubscriptionBeanWithMembership,
              )}
            </div>
            {!!promoCodeText && (
              <div className="flex flex-row items-center gap-1 text-sm font-semibold text-gray-950">
                <PromoIcon className="h-4 w-4" />
                {promoCodeText}
              </div>
            )}
          </div>
        )}
      </div>
      <div className="flex flex-col border-b border-gray-200" />
      <div className="flex flex-col gap-y-4 p-4">
        <div className="text-sm font-semibold text-gray-900">
          Membership Information
        </div>
        {membership && (
          <div className="flex w-full flex-col gap-y-2">
            <MembershipInformationLines
              memberId={memberId}
              membership={
                membership as UserMemberSubscriptionBeanWithMembership
              }
            />
            <a
              target="_blank"
              rel="noreferrer"
              href={membership.membershipBean.termsConditions}
              className="inline-flex w-fit flex-row text-sm font-semibold text-gray-600 underline hover:!text-gray-800 hover:no-underline"
            >
              Terms & Conditions
            </a>
          </div>
        )}

        {isCurrentMembership && (
          <div className="flex flex-col gap-y-2">
            <div className="flex flex-row items-center justify-between">
              <div className="text-sm font-semibold text-gray-900">
                Share Membership
              </div>
              <Badge intent="default">
                {membership.id === membership.primarySubscriptionId
                  ? "Primary"
                  : "Secondary"}
              </Badge>
            </div>
            <div className="flex flex-row flex-wrap gap-2">
              {linkedMembers
                ?.sort((a, b) => {
                  return a.subscriptions.find((s) =>
                    membershipHelper.isCurrentSubscription(s),
                  )?.id === membership.primarySubscriptionId
                    ? -1
                    : 1;
                })
                ?.map((member) => {
                  const membersCurrentSubscription = member.subscriptions.find(
                    (s) => membershipHelper.isCurrentSubscription(s),
                  );
                  const isPrimaryMembershipOwner =
                    membersCurrentSubscription?.id ===
                    membership.primarySubscriptionId;
                  return (
                    <Fragment key={member.id}>
                      <Link
                        to={createMemberLink(member.id, "/account")}
                        data-tooltip-id={`${member.id}-tooltip`}
                        data-tooltip-content={
                          isPrimaryMembershipOwner
                            ? "Primary Member"
                            : "Secondary Member"
                        }
                      >
                        <PlaceholderAvatar
                          variant={
                            isPrimaryMembershipOwner ? "secondary" : "default"
                          }
                          className="text-lg"
                          name={`${member.firstName} ${member.lastName}`}
                        />
                      </Link>
                      <Tooltip
                        className="!bg-primary-700 flex max-w-sm flex-col items-center rounded-lg text-center text-xs"
                        id={`${member.id}-tooltip`}
                      />
                    </Fragment>
                  );
                })}
              <Button
                onClick={() => {
                  setModal(
                    <LinkMemberToMembershipModal
                      memberId={memberId}
                      membership={
                        membership as UserMemberSubscriptionBeanWithMembership
                      }
                    />,
                  );
                }}
                className="m-0 h-11 w-11 p-0"
              >
                <PlusCircleIcon pathClassName="stroke-gray-500" />
              </Button>
            </div>
          </div>
        )}
      </div>
      {(
        [
          "ACTIVE",
          "PAUSED",
          "PENDING",
          "OVERDUE",
        ] as UserMemberSubscriptionStatus[]
      ).includes(membership.status) && (
        <>
          <div className="flex flex-col border-b border-gray-200" />
          <div className="flex w-full flex-row flex-wrap items-center justify-center gap-2 p-4 lg:justify-end">
            {isScheduledChange ? (
              <Button onClick={() => onClickUndoScheduledChange()} size="small">
                {t(
                  "page.userMember.tab.userMemberAccount.userMemberMembership.membershipCard.buttons.undoScheduledChange",
                )}
              </Button>
            ) : (
              <>
                {membership.staffCancellable &&
                  membership.membershipBean.type === "RECURRING" && (
                    <Button
                      onClick={() => {
                        setModal(
                          <CancelMembershipModal
                            memberId={memberId}
                            membership={
                              membership as UserMemberSubscriptionBeanWithMembership
                            }
                          />,
                        );
                      }}
                      size="small"
                    >
                      Cancel
                    </Button>
                  )}
                {permissions.membership.isPausable(
                  membership.membershipBean.type,
                  membership.cancellationDate,
                  membership.status,
                  membership.pauseStartDate,
                ) && (
                  <Button
                    onClick={() => {
                      setModal(
                        <PauseMembershipModal
                          memberId={memberId}
                          membership={
                            membership as UserMemberSubscriptionBeanWithMembership
                          }
                        />,
                      );
                    }}
                    size="small"
                  >
                    Pause
                  </Button>
                )}
                {membership.status === "PAUSED" && (
                  <Button
                    onClick={() => {
                      setModal(
                        <ResumeMembershipModal
                          memberId={memberId}
                          membership={
                            membership as UserMemberSubscriptionBeanWithMembership
                          }
                        />,
                      );
                    }}
                    size="small"
                  >
                    Resume
                  </Button>
                )}
                {membership &&
                  membership.membershipBean.type !== "PREPAID" &&
                  membership.status === "ACTIVE" &&
                  !permissions.membership.isPendingPause(
                    membership.status,
                    membership.pauseStartDate,
                  ) &&
                  !permissions.membership.isPendingCancellation(
                    membership.membershipBean.type,
                    membership.cancellationDate,
                  ) && (
                    <Button size="small" onClick={() => onClickChange()}>
                      Change
                    </Button>
                  )}

                {permissions.membership.isPendingPause(
                  membership.status,
                  membership.pauseStartDate,
                ) && (
                  <Button
                    onClick={() =>
                      setModal(
                        <ConfirmModal
                          title="Undo Pause"
                          onConfirm={async () => {
                            await cancelPauseSubscriptionMutation({
                              memberId,
                              subscriptionId: membership.id,
                            });
                            popModal();
                          }}
                          onCancel={() => {
                            popModal();
                          }}
                          type="warning"
                        >
                          Membership will remain active and continue to bill.
                          <NotPrimaryMembershipWarning
                            membership={membership}
                          />
                        </ConfirmModal>,
                      )
                    }
                    size="small"
                  >
                    Undo Pause
                  </Button>
                )}
                {permissions.membership.isPendingCancellation(
                  membership.membershipBean.type,
                  membership.cancellationDate,
                ) && (
                  <Button
                    onClick={() => {
                      setModal(
                        <ConfirmModal
                          title="Undo Cancellation"
                          onConfirm={async () => {
                            await revokeCancellation({
                              memberId,
                              subscriptionId: membership.id,
                            });
                            popModal();
                          }}
                          onCancel={() => {
                            popModal();
                          }}
                          type="warning"
                        >
                          Membership will remain active and continue to bill
                          until cancelled.
                          <NotPrimaryMembershipWarning
                            membership={membership}
                          />
                        </ConfirmModal>,
                      );
                    }}
                    size="small"
                  >
                    Undo Cancellation
                  </Button>
                )}
              </>
            )}
          </div>
        </>
      )}
    </div>
  );
};
