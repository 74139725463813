import { cn } from "@gymflow/helpers";
import { useTranslation } from "react-i18next";

import { AppStoreButton, PlayStoreButton } from "../../../atoms";
import { getMobileOperatingSystem } from "../../../pages/Migration/MigrationSuccess";

export const HostedPagesAppsBanner = () => {
  const { t } = useTranslation();

  const system = getMobileOperatingSystem();
  const isDesktop = system !== "Android" && system !== "iOS";
  return (
    <div className="shadow-xs bg-gray-0 flex flex-col justify-between gap-3 rounded-2xl border border-gray-300 p-4 dark:border-gray-700 dark:bg-gray-950">
      <div className="flex flex-col gap-0 max-lg:items-center">
        <div className="text-md dark:text-gray-0 font-semibold text-gray-950">
          {t("pages.hostedPagesAppsBanner.pleaseDownloadOurApp")}
        </div>
        <div className="text-md text-secondary-500 font-semibold">
          {t("pages.hostedPagesAppsBanner.toAccessFunctionality")}
        </div>
      </div>
      <div className="flex w-full flex-row items-center justify-center gap-4">
        {isDesktop && (
          <div className="shadow-xs rounded-xl border border-gray-300 bg-[#ffffff] p-1.5 dark:border-gray-700">
            <img
              src={require(`./StoresQRCode.png`)}
              alt="StoresQRCode"
              className="w-24"
            />
          </div>
        )}
        <div className="flex flex-col gap-2 lg:mr-auto">
          <div className="dark:text-gray-0 text-sm font-semibold text-gray-950">
            {t("pages.hostedPagesAppsBanner.downloadOurApp")}
          </div>
          <div className="flex w-full flex-row items-center justify-center gap-2">
            <PlayStoreButton
              className={cn("hidden h-10", {
                block: system === "Android" || isDesktop,
              })}
            />
            <AppStoreButton
              className={cn("hidden h-10", {
                block: system === "iOS" || isDesktop,
              })}
            />
          </div>
        </div>
      </div>
    </div>
  );
};
