import { ApiType } from "@gymflow/common/lib/api/ApiType";
import { useMutation, useQueryClient } from "@tanstack/react-query";

import { memberAsMemberQueryKeys } from "./memberAsMemberQueryKeys";

export function useMutationAttachAuthorizedSetupIntentAsMember({
  api,
}: {
  api: ApiType;
}) {
  const queryClient = useQueryClient();

  return useMutation({
    mutationFn: async ({ setupIntentId }: { setupIntentId: string }) => {
      await api.profileApi.attachAuthorizedSetupIntent(setupIntentId);
    },
    onSuccess: () => {
      queryClient.invalidateQueries({
        queryKey: memberAsMemberQueryKeys.all(),
      });
    },
  });
}
