import { faAdd, faClose } from "@fortawesome/free-solid-svg-icons";
import { FontAwesomeIcon } from "@fortawesome/react-fontawesome";
import { useAddPaymentMethodAlert } from "apps/portal/src/hooks/useAddPaymentMethodAlert";

import {
  useMemberPaymentMethodListAsMember,
  useQueryMemberPaymentMethodListNew
} from "../../../../../../libs/api/src";
import {
  UserMemberBean
} from "../../../../../../libs/types/src";
import { useClubSettings } from "../../../providers";
import useGymflowModels from "../../../store";
import { Button, SlideSideBar, Spinner } from "../../atoms";
import { PaymentMethodList } from "./PaymentMethodList";

export type UserMemberPaymentMethodSidebarProps = {
  userMember: UserMemberBean;
  hide: () => void;
  isOpen: boolean;
  asMember?: boolean;
};

export const UserMemberPaymentMethodSidebar = ({
  userMember,
  hide,
  isOpen,
  asMember,
}: UserMemberPaymentMethodSidebarProps) => {
  const { api } = useGymflowModels();
  const settings = useClubSettings();
  const paymentMethodsQueryAsStaff = useQueryMemberPaymentMethodListNew({
    api,
    memberId: userMember.id,
    clubId: settings.clubId,
    enabled: !asMember,
  });
  const paymentMethodsQueryAsMember = useMemberPaymentMethodListAsMember({
    api,
    memberId: userMember.id,
    clubId: settings.clubId,
    enabled: !!asMember,
  });
  const { data: paymentMethods, isLoading: isLoadingPaymentMethods } = asMember
    ? paymentMethodsQueryAsMember
    : paymentMethodsQueryAsStaff;

  const showAddPaymentMethodAlert = useAddPaymentMethodAlert({
    userMember,
    asMember,
  });

  return (
    <SlideSideBar
      unmount={false}
      className="w-[28rem]"
      isOpen={isOpen}
      hide={hide}
    >
      <div className="flex flex-col justify-between border-b border-gray-200 p-8">
        <div className="flex flex-row items-center justify-between">
          <div className="text-xl font-semibold text-gray-900">
            Payment Methods
          </div>

          <FontAwesomeIcon
            onClick={() => {
              hide();
            }}
            className="cursor-pointer text-xl text-gray-600"
            icon={faClose}
          />
        </div>
        <div className="text-sm font-medium text-gray-600">
          Add or remove payment methods.
        </div>
      </div>
      <div className="flex h-full max-h-full flex-col gap-3 p-8 pt-4">
        <Button
          onClick={() => {
            showAddPaymentMethodAlert();
          }}
          intent="secondary-outline"
          className="flex flex-row gap-1"
        >
          <FontAwesomeIcon icon={faAdd} className="text-secondary-500" />
          Add Card
        </Button>
        <div className="relative flex h-full max-h-full w-full">
          {isLoadingPaymentMethods && <Spinner />}
          <PaymentMethodList
            paymentMethods={paymentMethods}
            userMember={userMember}
            asMember={asMember}
          />
        </div>
      </div>
    </SlideSideBar>
  );
};
