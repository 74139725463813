import { PaymentMethodDTO } from "@gymflow/types";

import { PMAmexIcon } from "../atoms/icons/PMAmexIcon";
import { PMLinkIcon } from "../atoms/icons/PMLinkIcon";
import { PMMasterCardIcon } from "../atoms/icons/PMMasterCardIcon";
import { PMVisaIcon } from "../atoms/icons/PMVisaIcon";

export function PaymentMethodDetails({
  paymentMethod,
}: {
  paymentMethod?: PaymentMethodDTO;
}) {
  if (!paymentMethod) {
    return (
      <div className="flex h-full w-full flex-row items-center justify-between"></div>
    );
  }
  if (paymentMethod.linkEmail) {
    return (
      <div className="flex h-full w-full flex-row items-center justify-between">
        <div className="text-sm font-bold text-gray-950 dark:text-gray-0">
          {paymentMethod.linkEmail}
        </div>
        <PMLinkIcon />
      </div>
    );
  }
  return (
    <div className="flex h-full w-full flex-row items-center justify-between">
      <div className="flex w-full flex-col items-start">
        <div className="text-sm font-bold text-gray-950 dark:text-gray-0">
          Card ending in {paymentMethod.last4Digits}
        </div>
        <div className="text-sm font-normal text-gray-500">
          Expiry {paymentMethod.expMonth}/{paymentMethod.expYear}
        </div>
      </div>
      {paymentMethod.cardBrand in brandToIconMap ? (
        brandToIconMap[paymentMethod.cardBrand as keyof typeof brandToIconMap]
      ) : (
        <div />
      )}
    </div>
  );
}

const brandToIconMap = {
  visa: <PMVisaIcon />,
  amex: <PMAmexIcon />,
  mastercard: <PMMasterCardIcon />,
};