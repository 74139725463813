import {
  useMemberAsMemberNew,
  useMemberDelete,
  useMutationDeleteMemberAsMember,
  useQueryMember,
} from "@gymflow/api";
import { useParseErrors } from "@gymflow/common";
import { UserMemberSubscriptionBean } from "@gymflow/types";
import { useMemo } from "react";
import { useTranslation } from "react-i18next";

import { useClubSettings } from "../../providers";
import useGymflowModels from "../../store";
import { ConfirmModal } from "./ConfirmModal";

export type DeleteMemberModalProps = {
  onConfirm: () => void;
  onClose: () => void;
} & (
  | {
      asMember: true;
    }
  | {
      asMember?: false;
      memberId: string;
    }
);
export function DeleteMemberModal({
  onClose,
  onConfirm,
  ...rest
}: DeleteMemberModalProps) {
  const { t } = useTranslation();
  const { api } = useGymflowModels();
  const settings = useClubSettings();
  const clubId = settings.clubId as number;

  const parseError = useParseErrors();
  const mutationOpts = useMemo(
    () => ({
      onError: (error: any) => {
        parseError(error.response);
      },
    }),
    [parseError],
  );
  const deleteMutationAsStaff = useMemberDelete({ api }, mutationOpts);
  const deleteMutationAsMember = useMutationDeleteMemberAsMember(
    { api },
    mutationOpts,
  );
  const memberQueryAsStaff = useQueryMember({
    api,
    memberId: rest.asMember ? undefined : rest.memberId,
  });
  const memberQueryAsMember = useMemberAsMemberNew(
    {
      api,
    },
    {
      enabled: rest.asMember,
    },
  );
  const member = rest.asMember
    ? memberQueryAsMember.data
    : memberQueryAsStaff.data;
  const hasActiveMembership = !!member?.subscriptions?.find(
    (e: UserMemberSubscriptionBean) => e.active,
  );
  return !hasActiveMembership ? (
    <ConfirmModal
      onConfirm={async () => {
        try {
          if (rest.asMember) {
            await deleteMutationAsMember.mutateAsync({
              memberId: member.id,
              clubId,
            });
          } else {
            await deleteMutationAsStaff.mutateAsync({
              memberId: member.id,
              clubId,
            });
          }
          onConfirm();
        } catch {
          onClose();
        }
      }}
      onCancel={onClose}
      title={t("pages.deleteAccountModal.title")}
      type="danger"
    >
      {t("pages.deleteAccountModal.description")}
    </ConfirmModal>
  ) : (
    <ConfirmModal
      onCancel={onClose}
      title={t("pages.cantDeleteAccountModal.title")}
      type="warning"
    >
      {t("pages.cantDeleteAccountModal.description")}
    </ConfirmModal>
  );
}
