import { ApiType } from "@gymflow/common/lib/api/ApiType";
import { LUXON_DATE_FORMAT } from "@gymflow/helpers";
import { ProductOrderDetailDTO } from "@gymflow/types";
import { useMutation, useQueryClient } from "@tanstack/react-query";
import { DateTime } from "luxon";

import { appointmentAsMemberQueryKeys } from "../appointmentAsMember";
import { productAsMemberQueryKeys } from "../productAsMember";
import { memberAsMemberQueryKeys } from "./memberAsMemberQueryKeys";

export function useMutationMemberSaleAsMember({
  api,
  tz,
}: {
  api: ApiType;
  tz: string;
  clubId: number;
}) {
  const queryClient = useQueryClient();
  const result = useMutation({
    mutationFn: async (
      params: {
        paymentMethod?: string;
        promotionCode?: string;
      } & (
        | {
            startDate: string;
            membershipId: number;
          }
        | {
            startDate: string;
            sessionPackId: number;
          }
        | {
            products: ProductOrderDetailDTO[];
          }
      ),
    ) => {
      if ("products" in params) {
        return (
          await api.customerProductApi.order({
            paymentMethodId: params.paymentMethod,
            promotionCode: params.promotionCode,
            productOrderList: params.products,
          })
        ).data;
      } else {
        return (
          await api.profileApi.checkout({
            paymentMethod: params.paymentMethod,
            subscriptionSignUpBean: {
              ...("membershipId" in params
                ? {
                    membershipId: params.membershipId,
                  }
                : {
                    sessionPackId: params.sessionPackId,
                  }),
              promotionCode: params.promotionCode,
              startDate:
                DateTime.fromFormat(params.startDate, LUXON_DATE_FORMAT, {
                  zone: tz,
                })
                  .startOf("day")
                  .toUTC()
                  .set({ millisecond: 0 })
                  .toISO({ suppressMilliseconds: true }) ?? "",
            },
          })
        ).data;
      }
    },
    onSuccess: () => {
      queryClient.invalidateQueries({
        queryKey: memberAsMemberQueryKeys.all(),
      });
      queryClient.invalidateQueries({
        queryKey: productAsMemberQueryKeys.all(),
      });
      queryClient.invalidateQueries({
        queryKey: appointmentAsMemberQueryKeys.all(),
      });
    },
  });

  return result;
}
