import { AxiosInstance } from "axios";

export type StrongCustomerAuthorizationApiType = ReturnType<
  typeof strongCustomerAuthorizationApi
>;

const strongCustomerAuthorizationApi = (
  axiosInstance: AxiosInstance,
  clubId: number,
) => {
  const clubPrefix = `club/${clubId}/`;

  return {
    confirmPayment(paymentIntent: string) {
      return axiosInstance.get(`${clubPrefix}sca/confirm`, {
        params: {
          payment_intent: paymentIntent,
        },
      });
    },
    sendEmail(invoiceNumber: string) {
      return axiosInstance.get(`${clubPrefix}sca/send-email`, {
        params: {
          invoice_number: invoiceNumber,
        },
      });
    },
    generateScaRedirectUrl(invoiceNumber: string) {
      return `${axiosInstance.defaults.baseURL}/club/${clubId}/sca/invoice/${invoiceNumber}`;
    },
  };
};

export default strongCustomerAuthorizationApi;
