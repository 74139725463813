import {
  facilityListQueryFn,
  staffListQueryFn,
  useClubFeatureFlags,
} from "@gymflow/api";
import {
  FormMapper,
  renderErrorRowOnTouch,
  useRecordForm,
} from "@gymflow/common";
import { cn, generateDurationOptions } from "@gymflow/helpers";
import {
  AppointableDtoAvailabilityType,
  FacilityDTO,
  UserStaffPublicDTO,
} from "@gymflow/types";
import { useClubSettings } from "apps/portal/src/providers";
import { Formik, useFormikContext } from "formik";
import noop from "lodash/noop";
import { useContext, useEffect, useMemo } from "react";
import * as Yup from "yup";

import useGymflowModels from "../../../store";
import {
  Avatar,
  CheckCircleIcon,
  eagerSelectAll,
  PaginatedSelect,
  renderMultiSelectPlaceholder,
  Select,
  Switch,
} from "../../atoms";
import { WizardContext, WizardState } from "../../organisms/StepWizard";

export function AppointableWizardStep3({
  submitError,
}: {
  submitError: string;
}) {
  const { wizardState } = useContext(WizardContext);
  const { initialValues, getValues } = useRecordForm({
    record: wizardState["availability"] || null,
    fields: schema.getDefault(),
    mapper,
  });

  return (
    <div>
      <Formik
        initialValues={initialValues}
        enableReinitialize
        onSubmit={noop}
        validationSchema={schema}
      >
        <Step3Form getValues={getValues} />
      </Formik>

      {submitError && (
        <div className="border-error-700 bg-error-50 text-error-700 flex rounded border p-5">
          <div className="mt-0.5">
            <CheckCircleIcon pathClassName="stroke-error-700" />
          </div>
          <div className="ml-2">
            <div className="font-semibold">Error</div>
            <div>{submitError}</div>
          </div>
        </div>
      )}
    </div>
  );
}

function Step3Form({ getValues }: { getValues: (formValues: any) => any }) {
  const formikProps = useFormikContext();
  const values = formikProps.values as { [k: string]: any };
  const { setFieldValue, setFieldTouched, validateForm, submitForm } =
    formikProps;
  const availabilityOptions: {
    label: string;
    value: AppointableDtoAvailabilityType;
  }[] = [
    { label: "Staff", value: "STAFF" },
    { label: "Facility", value: "FACILITY" },
  ];
  const { api } = useGymflowModels();

  const blockOptions = useMemo(() => {
    return [
      { value: 0, label: "No" },

      ...generateDurationOptions({
        intervalsInMinutes: 15,
        hours: 4,
      }),
    ];
  }, []);

  const { setWizardState, wizardState } = useContext(WizardContext);
  useEffect(() => {
    async function updateWizardState() {
      const errors = await validateForm(values);
      const isValid = Object.keys(errors).length === 0;
      setWizardState((prev: WizardState) => ({
        ...prev,
        availability: getValues(values),
        isValid,
      }));
    }
    updateWizardState();
  }, [values]);

  const availabilityType = wizardState?.["availability"]?.availabilityType;
  useEffect(() => {
    async function touchFieldsWithErrors() {
      const errors = await validateForm();
      Object.keys(errors).forEach((key) => {
        setFieldTouched(key);
      });
    }
    if (wizardState.isWizardStepValid !== undefined) {
      touchFieldsWithErrors();
      submitForm();
    }
  }, [
    setFieldTouched,
    submitForm,
    validateForm,
    wizardState.isWizardStepValid,
    availabilityType,
  ]);
  const settings = useClubSettings();

  const { data: featureFlags } = useClubFeatureFlags({
    api,
    clubId: settings.clubId,
  });
  return (
    <div className="p-2">
      <div>
        <div>Availability type</div>
        <div className="mt-2">
          <Select
            options={availabilityOptions}
            value={availabilityOptions.find(
              (v) => v.value === values[AVAILABILITY_TYPE],
            )}
            onChange={({
              value,
            }: {
              value: AppointableDtoAvailabilityType;
            }) => {
              formikProps.setFieldValue(AVAILABILITY_TYPE, value);
            }}
          />
        </div>
        <div className="mt-1 text-gray-600">
          Determines if your appointment is booked with staff or at a facility
          e.g. Massage with Susan (Staff) vs Squash on Court 4 (Facility).
        </div>
      </div>

      <div
        className={cn("mt-4", {
          hidden: values[AVAILABILITY_TYPE] !== "STAFF",
        })}
      >
        <div>Appointment Hosts</div>
        <div className="mt-2">
          <PaginatedSelect
            isMulti
            showSelectAll
            selectAllClick={eagerSelectAll}
            value={values[APPOINTABLE_HOSTS]}
            onChange={(newValue) => {
              setFieldValue(APPOINTABLE_HOSTS, newValue);
            }}
            loadOptions={async (_, __, additional) => {
              const staffList = await staffListQueryFn({
                api,
                opts: {
                  extraParams: {
                    activeUser: true,
                    availableForAppointments: true,
                  },
                  page: additional.page,
                },
              });
              return {
                options: staffList.content.map(createStaffOption),
                hasMore: !staffList.last,
                additional: { page: additional.page + 1 },
              };
            }}
            placeholder={renderMultiSelectPlaceholder({
              value: values[APPOINTABLE_HOSTS],
            })}
          />
        </div>
        <div className="mt-2">
          {renderErrorRowOnTouch(
            APPOINTABLE_HOSTS,
            formikProps.touched,
            formikProps.errors,
          )}
        </div>
      </div>

      <div
        className={cn("mt-4", {
          hidden: values[AVAILABILITY_TYPE] !== "FACILITY",
        })}
      >
        <div>Appointment Facilities</div>
        <div className="mt-2">
          <PaginatedSelect
            isMulti
            value={values[APPOINTABLE_FACILITIES]}
            onChange={(newValue) => {
              setFieldValue(APPOINTABLE_FACILITIES, newValue);
            }}
            loadOptions={async (_, __, additional) => {
              const facilityList = await facilityListQueryFn({
                api,
                opts: {
                  extraParams: {
                    activeUser: true,
                    availableForAppointments: true,
                  },
                  page: additional.page,
                },
              });
              return {
                options: facilityList.content.map(createFacilityOption),
                hasMore: !facilityList.last,
                additional: { page: additional.page + 1 },
              };
            }}
          />
        </div>
        <div className="mt-2">
          {renderErrorRowOnTouch(
            APPOINTABLE_FACILITIES,
            formikProps.touched,
            formikProps.errors,
          )}
        </div>
      </div>

      <div className="mt-4">
        <div>Block Extra Time</div>
        <div className="mt-2">
          <Select
            options={blockOptions}
            value={
              values?.[BLOCKED_TIME] &&
              blockOptions.find(
                (duration) => duration.value === values[BLOCKED_TIME],
              )
            }
            onChange={({ value }: { value: number }) => {
              setFieldValue(BLOCKED_TIME, value);
            }}
          />
        </div>
        <div className="mt-1 text-gray-600">
          Blocks additional time after the appointment in the host&apos;s
          schedule to prevent back to back bookings where processing time may be
          required.
        </div>
      </div>

      <div className="mt-4 flex content-stretch">
        <div className="flex-auto">
          <div>Web Bookings</div>
          <div className="mt-1 text-gray-600">
            Make this appointment bookable on your website pages.
          </div>
        </div>
        <div className="flex justify-end">
          <Switch
            value={values[ALLOW_ONLINE_BOOKINGS]}
            onChange={(value) => {
              setFieldValue(ALLOW_ONLINE_BOOKINGS, value);
            }}
          />
        </div>
      </div>

      <div className="mt-4 flex content-stretch">
        <div className="flex-auto">
          <div>App Bookings</div>
          <div className="mt-1 text-gray-600">
            Make this appointment bookable on your members mobile app.
          </div>
        </div>
        <div className="flex justify-end">
          <Switch
            value={values[ALLOW_APP_BOOKINGS]}
            onChange={(value) => {
              setFieldValue(ALLOW_APP_BOOKINGS, value);
            }}
          />
        </div>
      </div>

      {featureFlags?.featureFlags.FE_MEMBERS_ONLY && (
        <div className="mt-4 flex content-stretch">
          <div className="flex-auto">
            <div>Available For Members Only</div>
            <div className="mt-1 text-gray-600">
              Make this appointment bookable for member only.
            </div>
          </div>
          <div className="flex justify-end">
            <Switch
              value={values[FOR_MEMBERS_ONLY]}
              onChange={(value) => {
                setFieldValue(FOR_MEMBERS_ONLY, value);
              }}
            />
          </div>
        </div>
      )}
    </div>
  );
}

const AVAILABILITY_TYPE = "availability-type";
const APPOINTABLE_HOSTS = "appointable-hosts-id-list";
const APPOINTABLE_FACILITIES = "appointable-facilities-id-list";
const BLOCKED_TIME = "blocked-time";
const ALLOW_ONLINE_BOOKINGS = "allow-online-bookings";
const ALLOW_APP_BOOKINGS = "allow-app-bookings";
const FOR_MEMBERS_ONLY = "for-members-only";

const schema = Yup.object().shape({
  [AVAILABILITY_TYPE]: Yup.string().required().default("STAFF"),
  [APPOINTABLE_HOSTS]: Yup.array()
    .test(function (value) {
      const { createError, parent } = this;
      if (
        parent[AVAILABILITY_TYPE] === "STAFF" &&
        (!value || value.length === 0)
      ) {
        return createError({ message: "Choose at least one host." });
      }
      return true;
    })
    .default([]),
  [APPOINTABLE_FACILITIES]: Yup.array()
    .test(function (value) {
      const { createError, parent } = this;
      if (
        parent[AVAILABILITY_TYPE] === "FACILITY" &&
        (!value || value.length === 0)
      ) {
        return createError({ message: "Choose at least one facility." });
      }
      return true;
    })
    .default([]),
  [BLOCKED_TIME]: Yup.number().min(0),
  [ALLOW_ONLINE_BOOKINGS]: Yup.boolean().required().default(true),
  [ALLOW_APP_BOOKINGS]: Yup.boolean().required().default(true),
  [FOR_MEMBERS_ONLY]: Yup.boolean().required().default(false),
});

export function createStaffOption(staff: UserStaffPublicDTO) {
  return {
    label: (
      <div className="flex items-center">
        <Avatar url={staff.picture} className="mr-2 !h-[18px] !w-[18px]" />
        <div>{`${staff.firstName} ${staff.lastName}`}</div>
      </div>
    ),
    value: staff.id,
  };
}

export function createFacilityOption(facility: FacilityDTO) {
  return {
    label: (
      <div className="flex items-center">
        <Avatar url={facility.picture} className="mr-2 !h-[18px] !w-[18px]" />
        <div>{facility.name}</div>
      </div>
    ),
    value: facility.id,
  };
}

const mapper = new FormMapper();
