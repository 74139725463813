import {
  useClubAsPublic,
  useClubFeatureFlags,
  useMutationSendScaEmail,
  useMutationSendSMSToMember,
  useQueryInvoice,
} from "@gymflow/api";
import { ApiType } from "@gymflow/common/lib/api/ApiType";
import { cn, formatCurrency } from "@gymflow/helpers";
import { isAxiosError } from "axios";
import { useContext } from "react";
import { useTranslation } from "react-i18next";

import { ModalContext, ModalWrapper, useClubSettings } from "../../providers";
import { ToastContext } from "../../providers/ToastProvider/context";
import useGymflowModels from "../../store";
import {
  Button,
  CheckIcon,
  CopyIcon,
  LayeredCircles,
  ReactiveButton,
  SMSIcon,
  Spinner,
} from "../atoms";
import CheckmarkIcon from "../atoms/icons/CheckmarkIcon";
import { EmailIcon } from "../atoms/icons/EmailIcon";
import { ShieldIcon } from "../atoms/icons/ShieldIcon";
import { XIcon } from "../atoms/icons/XIcon";

export type ScaModalAsStaffProps = {
  invoiceNumber: string;
  userMemberId: string;
  onComplete?: () => void;
};

export const ScaModalAsStaff = ({
  invoiceNumber,
  userMemberId,
  onComplete,
}: ScaModalAsStaffProps) => {
  const { toast, notifyDanger } = useContext(ToastContext);
  const { t } = useTranslation();
  const { api } = useGymflowModels();
  const { popModal } = useContext(ModalContext);
  const { data: invoice, isLoading } = useQueryInvoice(
    {
      api,
      invoiceNumber,
      userMemberId,
    },
    {
      refetchInterval: (invoice) =>
        invoice?.status === "AWAITING_AUTHORIZATION" ? 5000 : false,
      refetchOnWindowFocus: true,
    },
  );

  const sendEmailMutation = useMutationSendScaEmail({
    api,
  });
  const sendSms = useMutationSendSMSToMember({ api });
  const settings = useClubSettings();
  const { data: club } = useClubAsPublic({
    api,
    clubId: settings.clubId,
  });
  const scaUrl = (
    api as ApiType
  ).strongCustomerAuthorizationApi.generateScaRedirectUrl(invoiceNumber);
  const { data: featureFlags } = useClubFeatureFlags({
    api,
    clubId: settings.clubId,
  });
  const hideModal = () => {
    popModal();
    onComplete?.();
  };
  return (
    <ModalWrapper onCancel={hideModal} className="!max-w-lg">
      <div className="flex flex-col">
        <div className="flex flex-row justify-between">
          <LayeredCircles
            className={cn("h-12 w-12", {
              invisible: invoice?.status !== "AWAITING_AUTHORIZATION",
            })}
            intent="secondary"
          >
            <ShieldIcon
              className="h-5 w-5"
              pathClassName="stroke-secondary-600"
            />
          </LayeredCircles>
          <Button
            intent="transparent"
            className="z-10 aspect-square p-1"
            onClick={hideModal}
          >
            <XIcon className="h-6 w-6" pathClassName="stroke-gray-600" />
          </Button>
        </div>
        {isLoading && <Spinner className="py-4" />}

        {invoice?.status === "PAID" && (
          <div className="-mt-8 flex w-full flex-col items-center gap-4">
            <LayeredCircles className="h-14 w-14" intent="success">
              <CheckmarkIcon
                className="h-5 w-5"
                pathClassName="stroke-success-600"
              />
            </LayeredCircles>
            <div className="flex flex-col items-center">
              <div className="text-center text-xl font-bold text-gray-950">
                {t("pages.scaModal.successTitle")}
              </div>
              <div className="text-center font-medium text-gray-500">
                {t("pages.scaModal.successDescription")}
              </div>
            </div>
          </div>
        )}
        {invoice?.status === "PAST_DUE" && (
          <div className="-mt-8 flex w-full flex-col items-center gap-4">
            <LayeredCircles className="h-14 w-14" intent="error">
              <CheckmarkIcon
                className="h-5 w-5"
                pathClassName="stroke-error-600"
              />
            </LayeredCircles>
            <div className="flex flex-col items-center">
              <div className="text-center text-xl font-bold text-gray-950">
                {t("pages.scaModal.errorTitle")}
              </div>
              <div className="text-center font-medium text-gray-500">
                {t("pages.scaModal.errorDescription")}
              </div>
            </div>
          </div>
        )}
        {invoice?.status === "AWAITING_AUTHORIZATION" && (
          <div className="flex w-full flex-col gap-6">
            <div className="text-lg font-semibold">
              {t("pages.scaModal.title")}
            </div>
            <div className="flex flex-col items-center gap-6">
              <div className="flex flex-col items-center gap-4 rounded-xl border border-gray-200 p-3">
                <div className="flex flex-col items-center gap-1">
                  <div className="font-medium">
                    {t("pages.scaModal.paymentStatus")}
                  </div>
                  <div className="bg-warning-100 flex flex-row gap-1 rounded-full px-4 py-2">
                    <ShieldIcon
                      className="h-5 w-5"
                      pathClassName="stroke-warning-700"
                    />
                    <div className="text-warning-700 text-sm font-semibold">
                      {t("pages.scaModal.paymentStatusAuthNeeded")}
                    </div>
                  </div>
                </div>
                <div className="text-warning-600 text-center text-sm font-medium">
                  {t("pages.scaModal.paymentStatusAuthNeededDescription")}
                </div>
              </div>
            </div>
            <div className="flex flex-col gap-3">
              <div className="flex flex-col gap-1">
                <div className="font-medium">
                  {t("pages.scaModal.sendAuthVia")}
                </div>
                <div className="flex flex-row gap-2">
                  {featureFlags?.featureFlags.PORTAL_SEND_SMS && (
                    <Button
                      className="flex flex-1 flex-row gap-2 text-gray-950"
                      onClick={async () => {
                        if (!invoice || !club) {
                          toast({
                            message: t("common.error"),
                            intent: "error",
                            type: "small",
                          });
                          return;
                        }
                        try {
                          await sendSms.mutateAsync({
                            body: `Authorize purchase for ${formatCurrency(
                              invoice.chargeableAmount,
                              settings.defaultCurrency,
                            )} at ${club.name}. ${scaUrl}`,
                            userMemberId: userMemberId,
                          });
                          toast({
                            message: t("pages.scaModal.SMSSent"),
                            description: t("pages.scaModal.SMSSentDescription"),
                          });
                        } catch (e) {
                          if (isAxiosError(e)) {
                            notifyDanger(e);
                          }
                        }
                      }}
                    >
                      <SMSIcon
                        className="h-5 w-5"
                        pathClassName="stroke-gray-950"
                      />
                      {t("common.SMS")}
                    </Button>
                  )}
                  <Button
                    className="flex flex-1 flex-row gap-2 text-gray-950"
                    onClick={async () => {
                      await sendEmailMutation.mutateAsync({
                        invoiceNumber,
                      });
                      toast({
                        message: t("pages.scaModal.emailSent"),
                        description: t("pages.scaModal.emailSentDescription"),
                      });
                    }}
                  >
                    <EmailIcon
                      className="h-5 w-5"
                      pathClassName="stroke-gray-950"
                    />
                    {t("common.email")}
                  </Button>
                </div>
              </div>
              <div className="flex flex-row items-center gap-1 font-medium text-gray-500">
                <div className="mt-px h-px w-full bg-gray-300" />
                {t("common.or")}
                <div className="mt-px h-px w-full bg-gray-300" />
              </div>
              <div className="flex flex-col gap-1">
                <div className="font-medium">
                  {t("pages.scaModal.copyLink")}
                </div>
                <div className="flex h-11 flex-row items-center justify-between rounded-xl bg-gray-100 pl-3 pr-1">
                  <div className="line-clamp-1 font-medium text-gray-950">
                    {scaUrl}
                  </div>
                  <ReactiveButton
                    size="small"
                    className="aspect-square p-0"
                    onClick={() => {
                      navigator.clipboard.writeText(scaUrl);
                      toast({
                        message: t("common.linkCopied"),
                        type: "small",
                      });
                    }}
                    reactiveContent={
                      <CheckIcon pathClassName="stroke-gray-950" />
                    }
                  >
                    <CopyIcon pathClassName="stroke-gray-950" />
                  </ReactiveButton>
                </div>
              </div>
            </div>
          </div>
        )}
      </div>
    </ModalWrapper>
  );
};
