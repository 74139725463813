import PropTypes from "prop-types";
import Select from "react-select";
import classNames from "classnames";
import { FontAwesomeIcon } from "@fortawesome/react-fontawesome";
import { faAngleDown, faAngleUp } from "@fortawesome/free-solid-svg-icons";

export const dropdownFilterClasses =
  "flex h-11  cursor-pointer items-center rounded-md border border-gray-300 bg-gray-25 text-center hover:bg-gray-100";

/**
 * @deprecated replace by PaginatedSelect
 */
const DropdownFilter = ({ value, onChange, className, ...props }) => (
  <Select
    components={{
      DropdownIndicator: ({ selectProps: { menuIsOpen } }) => {
        if (menuIsOpen) {
          return <FontAwesomeIcon icon={faAngleUp} className="ml-2 mr-4 text-gray-500" />;
        }
        return <FontAwesomeIcon icon={faAngleDown} className="ml-2 mr-4 text-gray-500" />;
      },
      IndicatorSeparator: () => {
        return null;
      },
      ValueContainer: ({ children }) => {
        return (
          <div className="ml-4 flex max-w-[70%] overflow-x-hidden font-semibold [&>div]:!text-gray-500">{children}</div>
        );
      },
    }}
    isSearchable={false}
    classNames={{
      control: () =>
        "!border-none bg-transparent !outline-0 !shadow-none !flex !flex-row justify-between w-full !flex-nowrap",
      menu: () => "!z-[9999] !rounded-lg",
      option: () => classNames(`!cursor-pointer hover:!bg-primary-50 !bg-gray-0 !text-gray-800`),
      valueContainer: () => "border-none rounded-xl shadow-none h-[calc(2.75rem-2px)] cursor-pointer",
    }}
    className={classNames(dropdownFilterClasses, className)}
    value={value}
    onChange={onChange}
    {...props}
  />
);

DropdownFilter.propTypes = {
  value: PropTypes.oneOfType([PropTypes.object, PropTypes.instanceOf(null)]),
  onChange: PropTypes.func.isRequired,
  styles: PropTypes.object,
};

export default DropdownFilter;
