import { ApiType } from "@gymflow/common/lib/api/ApiType";
import { useQuery } from "@tanstack/react-query";

import { appointableAsPublicQueryKeys } from "./appointableAsPublicQueryKeys";

export function useQueryAppointableAsPublic({
  api,
  id,
}: {
  api: ApiType;
  id?: Parameters<ApiType["public"]["appointableApi"]["findById"]>[0];
}) {
  return useQuery({
    queryKey: appointableAsPublicQueryKeys.id(id),
    queryFn: async () => (await api.public.appointableApi.findById(id!)).data,
    enabled: !!id,
  });
}
