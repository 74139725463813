import { ApiType } from "@gymflow/common/lib/api/ApiType";
import { useQuery } from "@tanstack/react-query";

import { memberQueryKeys } from "./memberQueryKeys";

export function useQueryMember({
  api,
  memberId,
}: {
  api: ApiType;
  memberId?: Parameters<ApiType["memberApi"]["findById"]>[0];
}) {
  return useQuery({
    queryKey: memberQueryKeys.detailsNew(memberId),
    queryFn: () => api.memberApi.findById(memberId),
    enabled: !!memberId,
  });
}
