import check, { assert } from "check-types";

import endpoints from "./endpoints";
import { canFindById, canTrimFields, canUpdate } from "./features/apiResource";

const clubApi = (axiosInstance, apiUrl, clubId) => {
  const state = {
    fieldsToTrim: ["name", "addressLine1", "addressLine2", "postCode", "city"],
    baseUrl: endpoints.clubs,
    http: axiosInstance,
    apiUrl,
  };

  return Object.assign(
    state,
    canTrimFields(state),
    canFindById(state),
    canUpdate(state),
    {
      properties() {
        return state.http.get(`${state.baseUrl}/${clubId}/properties`);
      },
      update(id, { patchedFields, termsAndConditionsBlob }) {
        assert(
          check.any([check.string(id), check.number(id)]),
          "id must be number or string",
        );
        const formData = state.transformIntoFormData({
          file: termsAndConditionsBlob,
          club: patchedFields,
        });

        return state.http.patch(`${state.baseUrl}/${id}`, formData, {
          headers: {
            "Content-Type": "multipart/form-data",
          },
        });
      },
      transformIntoFormData({ file, club }) {
        const formData = new FormData();
        const trimmedData = state.trimFields(club);
        if (file) {
          formData.append("file", file, file.name);
        }

        if (club) {
          formData.append(
            "club",
            new Blob([JSON.stringify(trimmedData)], {
              type: "application/json",
            }),
          );
        }

        return formData;
      },
      connectStatus(clubId) {
        return state.http.get(`${state.baseUrl}/${clubId}/connect`);
      },
      updateStripeAuthCode(clubId, authCode) {
        return state.http.post(`${state.baseUrl}/${clubId}/connect`, authCode, {
          headers: { "Content-Type": "text/plain" },
        });
      },
      featureFlags(clubId) {
        return state.http.get(`${state.baseUrl}/${clubId}/feature-flags`);
      },
      details(clubId) {
        return state.http.get(`${state.baseUrl}/${clubId}/details`);
      },
      updateDetails(clubId, payload) {
        return state.http.patch(`${state.baseUrl}/${clubId}/details`, payload);
      },
      updateTerms(clubId, payload) {
        return state.http.post(
          `${state.baseUrl}/${clubId}/details/terms-conditions`,
          payload,
          {
            headers: {
              "Content-Type": "multipart/form-data",
            },
          },
        );
      },
      stripeOnboardAccount(clubId) {
        return state.http.put(
          `${state.baseUrl}/${clubId}/account/onboard-account`,
        );
      },
      stripeSession(clubId) {
        return state.http.get(`${state.baseUrl}/${clubId}/account/session`);
      },
      stripeCompleteOnboard() {
        return state.http.put(
          `${state.baseUrl}/${clubId}/account/onboard-account/status`,
        );
      },
      customPaymentCategory(clubId) {
        return state.http.get(`${state.baseUrl}/${clubId}/custom-payment-category`);
      },
    },
  );
};

export default clubApi;
