export const File02Icon = ({
  className,
  pathClassName,
}: {
  className?: string;
  pathClassName?: string;
}) => (
  <svg
    width="20"
    height="20"
    viewBox="0 0 20 20"
    fill="none"
    xmlns="http://www.w3.org/2000/svg"
    className={className}
  >
    <path
      d="M11.6667 9.16663H6.66671M8.33337 12.5H6.66671M13.3334 5.83329H6.66671M16.6667 5.66663V14.3333C16.6667 15.7334 16.6667 16.4335 16.3942 16.9683C16.1545 17.4387 15.7721 17.8211 15.3017 18.0608C14.7669 18.3333 14.0668 18.3333 12.6667 18.3333H7.33337C5.93324 18.3333 5.23318 18.3333 4.6984 18.0608C4.22799 17.8211 3.84554 17.4387 3.60586 16.9683C3.33337 16.4335 3.33337 15.7334 3.33337 14.3333V5.66663C3.33337 4.26649 3.33337 3.56643 3.60586 3.03165C3.84554 2.56124 4.22799 2.17879 4.6984 1.93911C5.23318 1.66663 5.93324 1.66663 7.33337 1.66663H12.6667C14.0668 1.66663 14.7669 1.66663 15.3017 1.93911C15.7721 2.17879 16.1545 2.56124 16.3942 3.03165C16.6667 3.56643 16.6667 4.26649 16.6667 5.66663Z"
      stroke="#667085"
      strokeWidth="1.67"
      strokeLinecap="round"
      strokeLinejoin="round"
      className={pathClassName}
    />
  </svg>
);
