import { utcToZonedTime, zonedTimeToUtc } from "@gymflow/helpers";
import { AvailabilityCalculationResult } from "@gymflow/types";
import { useQuery, UseQueryOptions } from "@tanstack/react-query";

import { availabilityAsMemberQueryKeys } from "./availabilityAsMemberQueryKeys";
/*** @deprecated - use useQueryHostAvailabilityCalculateAsMemberNew instead */
export function useQueryHostAvailabilityCalculateAsMember(
  {
    api,
    appointableId,
    dateFrom,
    dateTo,
    appointableHostsIdList,
    tz,
  }: {
    api: {
      customerAvailabilityApi: {
        hostCalculateAvailability: (filter: {
          appointableId: number;
          dateFrom: string;
          dateTo: string;
          appointableHostsIdList?: string[];
        }) => Promise<{ data: AvailabilityCalculationResult[] }>;
      };
    };
    tz?: string;
    appointableId?: number;
    dateFrom?: string;
    dateTo?: string;
    appointableHostsIdList?: string[];
  },
  opts?: UseQueryOptions<AvailabilityCalculationResult[] | undefined | null>,
) {
  const result = useQuery({
    queryKey: availabilityAsMemberQueryKeys.hostCalculateAvailability({
      appointableId,
      dateFrom,
      dateTo,
      appointableHostsIdList,
    }),
    queryFn: async () => {
      const utcDateFrom = zonedTimeToUtc(dateFrom as string, tz as string);
      const utcDateTo = zonedTimeToUtc(dateTo as string, tz as string);

      const result =
        await api.customerAvailabilityApi.hostCalculateAvailability({
          appointableId: appointableId as number,
          dateFrom: utcDateFrom,
          dateTo: utcDateTo,
          appointableHostsIdList,
        });
      return result.data;
    },
    select: (data) =>
      hostAvailabilityCalculateAsMemberSelectFn({ data, tz: tz as string }),
    enabled: !!tz && !!appointableId && !!dateFrom && !!dateTo,
    initialData: null,
    ...opts,
  });

  return result;
}

export function hostAvailabilityCalculateAsMemberSelectFn({
  data,
  tz,
}: {
  data?: AvailabilityCalculationResult[] | null;
  tz: string;
}) {
  if (!data) {
    return data;
  }
  return [...data].map((availabilityLine) => {
    return {
      ...availabilityLine,
      startTime: utcToZonedTime(availabilityLine.startTime, tz),
      endTime: utcToZonedTime(availabilityLine.endTime, tz),
    };
  });
}
