import { useAutoAnimate } from "@formkit/auto-animate/react";
import { FormikInput, onlyNumbersProps, useRecordForm } from "@gymflow/common";
import { cn, currencies } from "@gymflow/helpers";
import { DiscountType } from "@gymflow/types";
import { useFormik } from "formik";
import { useCallback, useContext, useEffect } from "react";
import { toFormikValidationSchema } from "zod-formik-adapter";

import { getDefaultsFromZodSchema } from "../../../helpers";
import { useClubSettings } from "../../../providers";
import { PaginatedSelect, RadioButtonGroup } from "../../atoms";
import { WizardContext, WizardState } from "../../organisms/StepWizard";
import { PromotionFormMapper } from "./mapper";
import { promotionStep1Schema } from "./schema";

const mapper = new PromotionFormMapper();

export function PromotionWizardStep1() {
  const { setWizardState, setOnValidate, wizardState } =
    useContext(WizardContext);
  const { initialValues, getValues } = useRecordForm({
    mapper,
    fields: getDefaultsFromZodSchema(promotionStep1Schema),
    record: wizardState["details"] || null,
  });

  const formik = useFormik<{
    name: string;
    code: string;
    trial: boolean;
    upfrontDiscount: boolean;
    upfrontDiscountAmount?: number;
    upfrontDiscountType?: DiscountType;
    recurringDiscount: boolean;
    recurringDiscountAmount?: number;
    recurringDiscountType?: DiscountType;
    recurringDiscountDurationType: "FOREVER" | "MULTIPLE_CYCLES";
    recurringDiscountDuration?: number;
  }>({
    enableReinitialize: false,
    validateOnBlur: true,
    initialValues,
    validationSchema: toFormikValidationSchema(promotionStep1Schema),
    onSubmit: () => {},
  });
  const { values, setFieldValue, validateForm, submitForm } = formik;

  const { defaultCurrency } = useClubSettings();
  const discountTypeToOptionMap = {
    CURRENCY: {
      label: currencies[defaultCurrency].symbol,
      value: "CURRENCY",
    },
    PERCENTAGE: {
      label: "%",
      value: "PERCENTAGE",
    },
  };
  useEffect(() => {
    function updateWizardState() {
      setWizardState((prev: WizardState) => ({
        ...prev,
        details: getValues(values),
      }));
    }
    updateWizardState();
  }, [getValues, setWizardState, values]);

  useEffect(() => {
    setOnValidate(async () => {
      submitForm();
      const result = await validateForm();
      return Object.keys(result).length === 0;
    });
  }, [setOnValidate, submitForm, validateForm]);

  const hasPromotionBeenUsed = !!wizardState["hasPromotionBeenUsed"];

  const getDiscountTypeRadioValue = useCallback(() => {
    if (values.recurringDiscount && values.upfrontDiscount) {
      return "BOTH";
    }
    if (values.recurringDiscount) {
      return "RECURRING";
    }
    if (values.upfrontDiscount) {
      return "UPFRONT";
    }
    return "UPFRONT";
  }, [values.recurringDiscount, values.upfrontDiscount]);

  const [parent] = useAutoAnimate();
  return (
    <div className="flex flex-col gap-8 p-1">
      <div className="flex flex-wrap gap-8">
        <div className="flex w-full flex-col gap-6 sm:w-[calc(50%-1.5rem)]">
          <div className="flex flex-col gap-2">
            <div className="text-sm font-medium text-gray-950">
              Promotion Name <span className="text-error-600">*</span>
            </div>
            <div className="flex flex-col gap-2">
              <FormikInput
                name="name"
                placeholder="Enter Name"
                formikProps={formik}
                readOnly={hasPromotionBeenUsed}
                disabled={hasPromotionBeenUsed}
              />
            </div>
          </div>
          <div className="flex flex-col gap-2">
            <div className="text-sm font-medium text-gray-950">
              Promotional Code <span className="text-error-600">*</span>
            </div>
            <div className="flex flex-col gap-1.5">
              <FormikInput
                name="code"
                className="uppercase"
                placeholder="e.g 50%OFF21"
                formikProps={formik}
                readOnly={hasPromotionBeenUsed}
                disabled={hasPromotionBeenUsed}
              />
              <div className="text-sm font-normal text-gray-500">
                Once a code has been used it cannot be changed.
              </div>
            </div>
          </div>
        </div>
        <div className="w-full sm:w-[calc(50%-1.5rem)]">
          <div className="flex flex-col gap-2">
            <div className="text-base font-semibold text-gray-950">
              Trial Offer?
            </div>
            <div className="text-sm font-normal text-gray-500">
              User redeeming trial offers will be put in the “Trial”
              <br /> column of your lead pipeline.
            </div>
            <RadioButtonGroup
              options={[
                {
                  label: "Yes",
                  value: "YES",
                },
                {
                  label: "No",
                  value: "NO",
                },
              ]}
              value={values.trial ? "YES" : "NO"}
              onChange={({ target: { value } }) => {
                setFieldValue("trial", value === "YES");
              }}
            />
          </div>
        </div>
      </div>
      <div className="justify-between border-t border-t-gray-300"></div>
      <div className="flex flex-wrap gap-8">
        <div className="flex w-full flex-col gap-4 sm:w-[calc(50%-2rem)]">
          <div className="text-lg font-semibold text-gray-950">
            Apply discount for:
          </div>
          <RadioButtonGroup
            disabled={hasPromotionBeenUsed}
            verticalLayout
            name="name"
            options={[
              {
                label: (
                  <div>
                    <div className="text-base font-medium text-gray-950">
                      For the first payment
                    </div>
                    <div className="text-sm font-normal text-gray-500">
                      The discount is applied to first payment only during
                      checkout.
                    </div>
                  </div>
                ),
                value: "UPFRONT",
              },
              {
                label: (
                  <div>
                    <div className="text-base font-medium text-gray-950">
                      For subsequent payments
                    </div>
                    <div className="text-sm font-normal text-gray-500">
                      The discount applies only to future scheduled payments
                      such
                      <br /> as recurring membership payments.
                    </div>
                  </div>
                ),
                value: "RECURRING",
              },
              {
                label: (
                  <div>
                    <div className="text-base font-medium text-gray-950">
                      Both
                    </div>
                    <div className="text-sm font-normal text-gray-500">
                      Provide an discount on the first payment
                      <br />
                      and subsequent payments.
                    </div>
                  </div>
                ),
                value: "BOTH",
              },
            ]}
            value={getDiscountTypeRadioValue()}
            onChange={({ target: { value } }) => {
              switch (value) {
                case "UPFRONT":
                  setFieldValue("upfrontDiscount", true);
                  setFieldValue("recurringDiscount", false);
                  break;
                case "RECURRING":
                  setFieldValue("upfrontDiscount", false);
                  setFieldValue("recurringDiscount", true);
                  break;
                case "BOTH":
                  setFieldValue("upfrontDiscount", true);
                  setFieldValue("recurringDiscount", true);
                  break;
              }
            }}
          />
        </div>
        <div
          className="flex w-full flex-col gap-3 sm:w-[calc(50%-2rem)]"
          ref={parent}
        >
          {values["upfrontDiscount"] && (
            <div className="flex flex-col gap-4 rounded-2xl border border-gray-200 p-5">
              <div className="text-base font-semibold text-gray-950">
                For the first payment
              </div>
              <div className="flex flex-col gap-1.5">
                <div className="text-sm font-medium text-gray-950">
                  Discount Amount <span className="text-error-600">*</span>
                </div>
                <div className="flex gap-2">
                  <div className="flex flex-1 flex-col">
                    <FormikInput
                      name="upfrontDiscountAmount"
                      placeholder="Enter Amount"
                      formikProps={formik}
                      readOnly={hasPromotionBeenUsed}
                      disabled={hasPromotionBeenUsed}
                      {...onlyNumbersProps}
                    />
                  </div>

                  <PaginatedSelect
                    className="max-h-10"
                    value={
                      values["upfrontDiscountType"] &&
                      discountTypeToOptionMap[values["upfrontDiscountType"]]
                    }
                    onChange={(newValue) => {
                      setFieldValue("upfrontDiscountType", newValue.value);
                    }}
                    loadOptions={() => {
                      return Promise.resolve({
                        options: Object.values(discountTypeToOptionMap),
                      });
                    }}
                    isDisabled={hasPromotionBeenUsed}
                  />
                </div>
              </div>
            </div>
          )}

          {values["recurringDiscount"] && (
            <div
              className="flex flex-col gap-4 rounded-2xl border border-gray-200 p-5"
              ref={parent}
            >
              <div className="text-base font-semibold text-gray-950">
                For subsequent payments
              </div>
              <div className="flex flex-wrap gap-5">
                <div className="text-sm font-medium text-gray-950">
                  Duration
                </div>
                <RadioButtonGroup
                  disabled={hasPromotionBeenUsed}
                  options={[
                    {
                      label: "Multiple Cycles",
                      value: "MULTIPLE_CYCLES",
                    },
                    {
                      label: "Forever",
                      value: "FOREVER",
                    },
                  ]}
                  value={values["recurringDiscountDurationType"]}
                  onChange={({ target: { value } }) => {
                    setFieldValue("recurringDiscountDurationType", value);
                    setFieldValue(
                      "recurringDiscountDuration",
                      value === "FOREVER" ? undefined : "3",
                    );
                  }}
                />
              </div>
              <div className="flex flex-wrap gap-3">
                <div
                  className={cn("flex flex-col gap-2", {
                    hidden:
                      values["recurringDiscountDurationType"] === "FOREVER",
                  })}
                >
                  <div className="text-sm font-medium text-gray-950">
                    How many cycles <span className="text-error-600">*</span>
                  </div>
                  <div className="flex flex-col gap-1.5">
                    <FormikInput
                      name="recurringDiscountDuration"
                      formikProps={formik}
                      readOnly={hasPromotionBeenUsed}
                      disabled={hasPromotionBeenUsed}
                      {...onlyNumbersProps}
                    />
                  </div>
                </div>
                <div className="flex flex-col gap-2">
                  <div className="text-sm font-medium text-gray-950">
                    Discount Amount <span className="text-error-600">*</span>
                  </div>
                  <div className="flex gap-1.5">
                    <div>
                      <FormikInput
                        name="recurringDiscountAmount"
                        formikProps={formik}
                        readOnly={hasPromotionBeenUsed}
                        disabled={hasPromotionBeenUsed}
                        {...onlyNumbersProps}
                      />
                    </div>
                    <PaginatedSelect
                      className="max-h-10"
                      value={
                        values["recurringDiscountType"] &&
                        discountTypeToOptionMap[values["recurringDiscountType"]]
                      }
                      onChange={(newValue) => {
                        setFieldValue("recurringDiscountType", newValue.value);
                      }}
                      loadOptions={() => {
                        return Promise.resolve({
                          options: Object.values(discountTypeToOptionMap),
                        });
                      }}
                      isDisabled={hasPromotionBeenUsed}
                    />
                  </div>
                </div>
              </div>
            </div>
          )}
        </div>
      </div>
    </div>
  );
}
