export function LinkIcon({
  className,
  pathClassName,
}: {
  className?: string;
  pathClassName?: string;
}) {
  return (
    <svg
      xmlns="http://www.w3.org/2000/svg"
      width="18"
      height="18"
      viewBox="0 0 18 18"
      fill="none"
    >
      <path
        d="M9.58955 14.3033L8.41104 15.4818C6.78386 17.109 4.14567 17.109 2.51848 15.4818C0.891299 13.8546 0.8913 11.2164 2.51848 9.58925L3.697 8.41074M14.3036 9.58925L15.4821 8.41074C17.1093 6.78355 17.1093 4.14536 15.4821 2.51818C13.8549 0.890994 11.2167 0.890994 9.58955 2.51818L8.41104 3.69669M6.08363 11.9166L11.917 6.08331"
        stroke="#0C111D"
        strokeWidth="1.66667"
        strokeLinecap="round"
        strokeLinejoin="round"
      />
    </svg>
  );
}
