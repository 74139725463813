import { cn } from "@gymflow/helpers";
import { UseInfiniteQueryOptions } from "@tanstack/react-query";
import { Field, FieldProps, FormikProps } from "formik";

import {
  SelectInput,
  SelectInputOptionType,
  SelectInputProps,
} from "../base/SelectInput";
import { ErrorMessage } from "../ErrorMessage";

type FormSelectInputProps<T, V> = {
  name: keyof T;
  containerClassName?: string;
} & (
  | (Omit<SelectInputProps<V>, "value" | "onChange" | "dataFetchingQuery"> & {
      options: SelectInputOptionType<V>[];
    })
  | (Omit<SelectInputProps<V>, "value" | "onChange" | "options"> & {
      dataFetchingQuery: () => UseInfiniteQueryOptions<
        unknown,
        unknown,
        SelectInputOptionType<V>[]
      >;
    })
);

export const FormSelectInput = <T, V>({
  name,
  className,
  containerClassName,
  ...props
}: FormSelectInputProps<T, V>) => (
  <Field name={name}>
    {({
      field,
      form: { setFieldValue, errors },
      meta: { touched, error },
    }: FieldProps<
      SelectInputOptionType<V>[] | SelectInputOptionType<V>,
      FormikProps<T>
    >) => {
      const isError = touched && error;
      return (
        <div className={cn("flex flex-col gap-1.5", containerClassName)}>
          <SelectInput
            {...props}
            {...(props.isMulti
              ? {
                  isMulti: true,
                  value: (field.value as SelectInputOptionType<V>[]) ?? [],
                }
              : {
                  isMulti: false,
                  value: field.value as SelectInputOptionType<V> | undefined,
                })}
            onChange={(
              selected?: SelectInputOptionType<V>[] | SelectInputOptionType<V>,
            ) => setFieldValue(String(name), selected)}
            className={cn(className, {
              "outline-error-300 border-error-200": isError,
            })}
          />
          {isError && <ErrorMessage text={error} />}
        </div>
      );
    }}
  </Field>
);
