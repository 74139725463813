import { cn } from "@gymflow/helpers";
import { Field, FieldProps, FormikProps } from "formik";

import { Input, InputProps } from "../base/Input/Input";
import { ErrorMessage } from "../ErrorMessage";
import { AlertCircleIcon } from "../icons";

type FormInputProps<T> = { name: keyof T; containerClassName?: string } & Pick<
  InputProps,
  "placeholder" | "label" | "className" | "variant" | "isRequired" | "disabled"
>;

export const FormInput = <T,>({
  name,
  className,
  containerClassName,
  ...props
}: FormInputProps<T>) => {
  return (
    <Field name={name}>
      {({
        field,
        meta: { touched, error },
      }: FieldProps<string, FormikProps<T>>) => {
        const isError = touched && error;
        return (
          <div className={cn("flex flex-col gap-1.5", containerClassName)}>
            <Input
              {...field}
              {...props}
              className={cn(className, {
                "outline-error-300 border-error-200 dark:!outline-none focus:dark:ring-1 dark:ring-error-600":
                  isError && props.variant !== "unstyled",
              })}
              endIcon={isError && <AlertCircleIcon className="h-5 w-5" />}
            />
            {isError && <ErrorMessage text={error} />}
          </div>
        );
      }}
    </Field>
  );
};
