import { ApiType } from "@gymflow/common/lib/api/ApiType";
import { useMutation, useQueryClient } from "@tanstack/react-query";

import { appointmentAsMemberQueryKeys } from "./appointmentAsMemberQueryKeys";

export function useMutationAppointmentCreateAsMember({
  api,
}: {
  api: ApiType;
}) {
  const queryClient = useQueryClient();
  const mutation = useMutation({
    mutationFn: async (
      newAppointment: Parameters<
        ApiType["customerAppointmentApi"]["create"]
      >[0],
    ) => {
      return (await api.customerAppointmentApi.create(newAppointment)).data;
    },
    onSuccess: () => {
      queryClient.invalidateQueries({
        queryKey: appointmentAsMemberQueryKeys.all(),
      });
    },
  });

  return mutation;
}
