import {
  MembershipSignupCustomPaymentRequestBody,
  MembershipSignupPaymentMethodRequestBody,
  ProductOrderCheckoutCustomPaymentRequestBody,
  ProductOrderCheckoutPaymentMethodRequestBody,
  ProductOrderInvoiceDTO,
  SessionPackSignupCustomPaymentRequestBody,
  SessionPackSignupPaymentMethodRequestBody,
  SubscriptionInvoiceDTO,
} from "@gymflow/types";
import { AxiosInstance } from "axios";

export type SalesApiType = ReturnType<typeof salesApi>;

const salesApi = (axiosInstance: AxiosInstance, clubId: number) => {
  const clubPrefix = `club/${clubId}/`;

  return {
    productOrderCheckoutPaymentMethod({
      orderKey,
      paymentMethodId,
      userMemberId,
      orderItems,
      promotionCode,
      priceOverride,
    }: ProductOrderCheckoutPaymentMethodRequestBody) {
      return axiosInstance.put<ProductOrderInvoiceDTO>(
        `${clubPrefix}product-order-checkout/${orderKey}/payment-method/${paymentMethodId}`,
        {
          userMemberId,
          orderItems,
          promotionCode,
          priceOverride,
        },
      );
    },
    productOrderCheckoutCustomPayment({
      orderKey,
      customPaymentCategoryId,
      userMemberId,
      orderItems,
      promotionCode,
      priceOverride,
    }: ProductOrderCheckoutCustomPaymentRequestBody) {
      return axiosInstance.put<ProductOrderInvoiceDTO>(
        `${clubPrefix}product-order-checkout/${orderKey}/custom-payment/${customPaymentCategoryId}`,
        {
          userMemberId,
          orderItems,
          promotionCode,
          priceOverride,
        },
      );
    },
    membershipSignupPaymentMethod({
      orderKey,
      paymentMethodId,
      userMemberId,
      membershipId,
      startDate,
      promotionCode,
      priceOverride,
    }: MembershipSignupPaymentMethodRequestBody) {
      return axiosInstance.put<SubscriptionInvoiceDTO>(
        `${clubPrefix}membership-signup/${orderKey}/payment-method/${paymentMethodId}`,
        {
          userMemberId,
          membershipId,
          startDate,
          promotionCode,
          priceOverride,
        },
      );
    },
    membershipSignupCustomPayment({
      orderKey,
      customPaymentCategoryId,
      userMemberId,
      membershipId,
      startDate,
      promotionCode,
      priceOverride,
    }: MembershipSignupCustomPaymentRequestBody) {
      return axiosInstance.put<SubscriptionInvoiceDTO>(
        `${clubPrefix}membership-signup/${orderKey}/custom-payment/${customPaymentCategoryId}`,
        {
          userMemberId,
          membershipId,
          startDate,
          promotionCode,
          priceOverride,
        },
      );
    },
    sessionPackSignupPaymentMethod({
      orderKey,
      paymentMethodId,
      userMemberId,
      sessionPackId,
      startDate,
      promotionCode,
      priceOverride,
    }: SessionPackSignupPaymentMethodRequestBody) {
      return axiosInstance.put<SubscriptionInvoiceDTO>(
        `${clubPrefix}session-pack-signup/${orderKey}/payment-method/${paymentMethodId}`,
        {
          userMemberId,
          sessionPackId,
          startDate,
          promotionCode,
          priceOverride,
        },
      );
    },
    sessionPackSignupCustomPayment({
      orderKey,
      customPaymentCategoryId,
      userMemberId,
      sessionPackId,
      startDate,
      promotionCode,
      priceOverride,
    }: SessionPackSignupCustomPaymentRequestBody) {
      return axiosInstance.put<SubscriptionInvoiceDTO>(
        `${clubPrefix}session-pack-signup/${orderKey}/custom-payment/${customPaymentCategoryId}`,
        {
          userMemberId,
          sessionPackId,
          startDate,
          promotionCode,
          priceOverride,
        },
      );
    },
  };
};

export default salesApi;
