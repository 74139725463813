import {
  useClubAsPublic,
  useMutationMigrationPaymentMethodSetupIntentAuthorized,
  useQueryMigrationSetupIntent,
} from "@gymflow/api";
import { PaymentMethodSetupIntent } from "@gymflow/types";
import {
  Elements,
  PaymentElement,
  useElements,
  useStripe,
} from "@stripe/react-stripe-js";
import { loadStripe } from "@stripe/stripe-js";
import { ToastContext } from "apps/portal/src/providers/ToastProvider/context";
import { isAxiosError } from "axios";
import { ReactNode, useContext, useState } from "react";

import { useClubSettings } from "../../../providers";
import useGymflowModels from "../../../store";
import {
  Button,
  CardIcon,
  CheckCircleIcon,
  DangerIcon,
  ErrorMessage,
  Spinner,
} from "../../atoms";
import { Breadcrumbs } from "./Breadcrumbs";

export function MigrationSetPaymentMethod({
  needsPasswordSet,
  invitationToken,
  onSuccess,
}: {
  needsPasswordSet: boolean;
  invitationToken: string;
  onSuccess: () => void;
}) {
  const pages: {
    name: string;
    icon: (props: { className?: string }) => ReactNode;
  }[] = [];

  if (needsPasswordSet) {
    pages.push({
      name: "Password",
      icon: ({ className }) => <CheckCircleIcon pathClassName={className} />,
    });
  }

  pages.push({
    name: "Card",
    icon: ({ className }) => <CardIcon pathClassName={className} />,
  });

  const settings = useClubSettings();
  const { api } = useGymflowModels();
  const { data: club } = useClubAsPublic({ api, clubId: settings.clubId });
  const { data: paymentMethodSetupIntent } = useQueryMigrationSetupIntent({
    api,
    invitationToken,
  });
  const [stripePromise] = useState(
    loadStripe(settings.stripe_public_key, {
      stripeAccount: club?.stripeApplicationId,
    }),
  );
  const isDarkMode = document.querySelector("html")!.classList.contains("dark");
  return (
    <div className="flex flex-col">
      <div className="bg-gray-0 flex justify-center border-b border-b-gray-300 py-6 dark:border-b-gray-700 dark:bg-gray-950">
        <Breadcrumbs pages={pages} activeIdx={needsPasswordSet ? 1 : 0} />
      </div>
      <div className="flex justify-center">
        {!paymentMethodSetupIntent ? (
          <Spinner />
        ) : (
          <Elements
            stripe={stripePromise}
            options={{
              clientSecret: paymentMethodSetupIntent.setupIntentClientSecret,
              loader: "auto",
              appearance: {
                variables: {
                  colorPrimary: settings.primaryColor ?? "#8746ec",
                },
                theme: isDarkMode ? "night" : "stripe",
              },
            }}
          >
            <Form
              paymentMethodSetupIntent={paymentMethodSetupIntent}
              invitationToken={invitationToken}
              onSuccess={onSuccess}
            />
          </Elements>
        )}
      </div>
    </div>
  );
}

function Form({
  invitationToken,
  onSuccess,
  paymentMethodSetupIntent,
}: {
  invitationToken: string;
  onSuccess: () => void;
  paymentMethodSetupIntent: PaymentMethodSetupIntent;
}) {
  const stripe = useStripe();
  const elements = useElements();
  const { api } = useGymflowModels();
  const attachAuthorizedPaymentMethodIntentMutation =
    useMutationMigrationPaymentMethodSetupIntentAuthorized({
      api,
    });

  const { notifyDanger, toast } = useContext(ToastContext);
  const [stripeError, setStripeError] = useState<string>();
  const onSubmit = async () => {
    try {
      if (!stripe || !elements) return;
      await elements.submit();
      const paymentMethodResult = await stripe.confirmSetup({
        elements,
        clientSecret: paymentMethodSetupIntent.setupIntentClientSecret,
        confirmParams: {
          return_url: window.location.href,
        },
        redirect: "if_required",
      });
      if (paymentMethodResult.error) {
        setStripeError(paymentMethodResult.error.message);
        return;
      }
      await attachAuthorizedPaymentMethodIntentMutation.mutateAsync({
        setupIntentId: paymentMethodResult.setupIntent.id,
        invitationToken,
      });

      onSuccess();
      toast({ message: "Payment method added." });
    } catch (e) {
      if (isAxiosError(e)) {
        notifyDanger(e);
      }
    }
  };
  const [completed, setCompleted] = useState(false);
  return (
    <div className="flex max-w-sm flex-col items-center justify-center gap-6 p-2">
      <div className="flex flex-col items-center gap-1.5">
        <div className="dark:text-gray-25 text-xl font-bold text-gray-950">
          Add Card Details
        </div>
        <div className="text-center text-base font-medium text-gray-500 dark:text-gray-400">
          We couldn&apos;t find a card on your account,
          <br />
          please add one.
        </div>
      </div>
      <div className="w-100 flex items-center justify-center gap-2 rounded-xl bg-gray-100 px-3 py-1.5 dark:border dark:border-gray-600 dark:bg-gray-700">
        <div>
          <div className="rounded-3xl border-4 border-gray-200 bg-gray-300 p-2 dark:border-gray-950 dark:bg-gray-950">
            <DangerIcon
              className="h-4 w-4"
              pathClassName="stroke-gray-500 dark:stroke-gray-400"
            />
          </div>
        </div>
        <div className="dark:text-gray-25 text-base font-semibold text-gray-950">
          Your card will not be charged
        </div>
      </div>
      <div className="w-100 flex flex-col gap-4">
        <div className="flex flex-col gap-1.5">
          <label
            className="dark:text-gray-25 !m-0 text-sm font-medium text-gray-950"
            htmlFor="cardholdersName"
          >
            Card details
          </label>
          <PaymentElement
            onChange={(paymentMethod) => {
              setStripeError(undefined);
              setCompleted(paymentMethod.complete);
            }}
          />
          {stripeError && <ErrorMessage text={stripeError} />}
        </div>
      </div>
      <div className="w-100 flex">
        <Button
          intent="secondary"
          className="w-full"
          disabled={!!stripeError || !completed}
          onClick={async () => {
            await onSubmit();
          }}
        >
          Add
        </Button>
      </div>
    </div>
  );
}
