import { ApiType } from "@gymflow/common/lib/api/ApiType";
import { useQuery } from "@tanstack/react-query";

import { ruleQueryKeys } from "./ruleQueryKeys";

export function useQueryRuleGroupSearch({
  api,
  filter,
}: {
  api: ApiType;
  filter: Parameters<ApiType["ruleApi"]["ruleGroupSearch"]>[0];
}) {
  return useQuery({
    queryKey: ruleQueryKeys.ruleGroupSearch(filter ?? {}),
    queryFn: async () => {
      return (await api.ruleApi.ruleGroupSearch({ ...filter })).data;
    },
  });
}
