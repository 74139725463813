import { DurationType } from "@gymflow/common";
import { Trans } from "react-i18next";

export const generateDurationOptions = (maxMonths: number) => [
  {
    label: (
      <Trans i18nKey="components.recurringMembershipFormSidebarProvider.durationOptions.noContract" />
    ),
    value: { value: 0, interval: DurationType.NoContract },
  },
  ...Array.from({ length: maxMonths }, (_, i) => ({
    label: (
      <Trans
        i18nKey={`components.recurringMembershipFormSidebarProvider.durationOptions.${
          i === 0 ? "month" : "months"
        }`}
        values={{ number: i + 1 }}
      />
    ),
    value: { value: i + 1, interval: DurationType.Monthly },
  })),
];
